import {
  CarColor,
  CarColorInternalSelection,
  InteriorCarColor,
} from '@carsayo/types';
import { cn } from 'utils';

export default function OrderDetailColorSquare({
  color,
}: {
  color?: CarColor | InteriorCarColor | CarColorInternalSelection | any;
}) {
  if (!color) return null;

  // 내장가니시일 경우
  if (color.imageUrl) {
    return (
      <div
        className={cn(
          'flex-none w-6 h-6 flex items-center justify-center rounded-lg border border-[#E5E5EC]',
        )}
      >
        <img
          src={color.imageUrl}
          className={cn('w-full h-full rounded-lg object-cover')}
        />
      </div>
    );
  }

  if (
    !(
      'color_code_main' in color &&
      'color_code_sub' in color &&
      'image_url' in color
    )
  )
    return null;

  return (
    <div
      className={cn(
        'flex-none w-6 h-6 flex items-center justify-center rounded-lg border border-[#E5E5EC]',
      )}
    >
      {color?.color_code_main ? (
        <div className='w-full h-full rounded-lg flex flex-col'>
          <div
            className={cn(
              'w-full flex-auto rounded-lg',
              color.color_code_sub && 'rounded-b-none',
            )}
            style={{
              background: color.color_code_main,
            }}
          ></div>

          {color.color_code_sub && (
            <div
              className='w-full flex-auto rounded-b-lg'
              style={{
                background: color.color_code_sub,
              }}
            ></div>
          )}
        </div>
      ) : (color as CarColor)?.image_url ? (
        <img
          src={(color as CarColor).image_url}
          className={cn('w-full h-full rounded-lg object-cover')}
        />
      ) : null}
    </div>
  );
}
