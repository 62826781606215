import GoTopBtn from 'components_v3/common/GoTop';
import { ReactNode, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';

/** App ViewPoint
 * @notice 뒤로가기로 페이지가 전환되었을 때 스크롤 위치를 기억하여 복구합니다
 */
const ViewPoint = ({ children }: { children: ReactNode }) => {
  const location = useLocation();
  const { isLoadingDim, setLoadingDim } = useSystemStore();
  const { setViewRef } = useSystemStore();
  const viewRef = useRef<HTMLDivElement | null>(null);

  const [currentScroll, setCurrentScroll] = useState(0);
  const [scrollHistory, setScrollHistory] = useState<
    { key: string; pathname: string; scroll?: number }[]
  >([{ key: location.key, pathname: location.pathname }]);

  useLayoutEffect(() => {
    // 페이지 전환 시 기존 DIM이 존재할 경우 제거
    if (isLoadingDim) setLoadingDim(false);

    if (scrollHistory.length === 1 && scrollHistory[0].key === location.key) {
      return;
    }

    // 뒤로가기로 들어온 경우
    if (location.key === scrollHistory?.[scrollHistory.length - 2]?.key) {
      const beforeScrollHeight =
        scrollHistory?.[scrollHistory.length - 2]?.scroll;
      scrollHistory.pop();
      setScrollHistory(scrollHistory);

      if (beforeScrollHeight !== undefined)
        viewRef.current?.scrollTo({
          top: beforeScrollHeight,
        });
      return;
    }
    // 다음 페이지로 넘어간 경우
    else if (scrollHistory.length > 0) {
      scrollHistory[scrollHistory.length - 1].scroll = currentScroll;
      setScrollHistory([
        ...scrollHistory,
        { key: location.key, pathname: location.pathname },
      ]);
      setCurrentScroll(0);
      viewRef.current?.scrollTo({
        top: 0,
      });
      return;
    }
  }, [location.pathname]);

  // 쿼리스트링만 변동했을 경우, 바로 직전 페이지의 pathname과 같은지 확인
  // 같으면 히스토리 내 키를 전환합니다.
  useEffect(() => {
    if (scrollHistory.length < 2) return;

    if (
      location.pathname === scrollHistory[scrollHistory.length - 1].pathname
    ) {
      scrollHistory[scrollHistory.length - 1].key = location.key;
    }
  }, [location.key]);

  useEffect(() => {
    viewRef.current && setViewRef(viewRef.current);
    const handleScroll = () => {
      if (viewRef.current) {
        setCurrentScroll(viewRef.current.scrollTop);
      }
    };

    const viewDiv = viewRef.current;
    if (viewDiv) {
      viewDiv.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (viewDiv) {
        viewDiv.removeEventListener('scroll', handleScroll);
      }
    };
  }, []);

  // scrollHistory 고장좀 납니다 확인요함

  return (
    <div
      id={'viewPoint'}
      ref={viewRef}
      className={cn('flex-auto h-auto w-full overflow-y-auto relative')}
    >
      {children}

      {location.pathname === '/main' && (
        <GoTopBtn
          option={{
            isHiddenInBottom: true,
          }}
        />
      )}

      {(location.pathname === '/dealer/request' ||
        location.pathname === '/dealer/quote') && <GoTopBtn />}
    </div>
  );
};
export default ViewPoint;
