/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { Star, X } from 'lucide-react';
import { useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { getDealerInfo, getManagerReviewList } from './api';
import { CircularProgress } from '@mui/material';
import { FileInfo, Member } from '@carsayo/types';
import StarIcon from '../orderDetailTemp/components/info/images/Star';
import DealerOrderDetailTabSelector from '../orderDetailTemp/components/info/components/tabSelector';
import dayjs from 'dayjs';
import MultiImageViewer from 'components_v3/dialog/MultiImageViewer';

const tabs = ['소개', '구매 후기'];

function DealerInfoHeader({ member }: { member: Member }) {
  const bidderType: '신차 매니저' | '중고차 매니저' | '리스/렌트 매니저' =
    member.dealerInfo?.type === 'newcar'
      ? '신차 매니저'
      : member.dealerInfo?.type === 'usedcar'
        ? '중고차 매니저'
        : '리스/렌트 매니저';

  return (
    <div className='mt-[40px] mb-[50px] flex flex-col items-center justify-center'>
      <div className='w-[100px] h-[100px] rounded-full bg-white mb-6'>
        <img
          src={member.profileImage_url}
          alt='profile'
          className='w-full h-full object-cover rounded-full'
        />
      </div>

      <div className='mb-3 text-[#222] text-[18px] font-semibold leading-[18px] tracking-[-0.36px]'>
        {member.name_nick}
      </div>

      <div className='flex items-center'>
        <StarIcon />
        <div className='text-[#555] text-center text-[14px] font-medium leading-[14px] tracking-[-0.28px] ml-0.5'>
          {Number(member.dealerInfo?.rating).toFixed(1)}
        </div>

        {/* Dot */}
        <div className='flex flex-col w-4 items-center justify-center'>
          <div className='w-[3px] h-[3px] bg-[#D5DADF] rounded-full'></div>
        </div>

        <div className='overflow-hidden text-[#555] text-[14px] font-medium leading-[14px] tracking-[-0.28px] truncate'>
          {bidderType}
        </div>
      </div>
    </div>
  );
}

function DealerInfoDescription({ member }: { member: Member }) {
  return (
    <div className='pt-[30px] px-5 space-y-5 pb-[30px]'>
      <div className='rounded-[10px] bg-[#F3F6F9] px-4 py-5 space-y-4'>
        <div className='grid grid-cols-4'>
          <div className='text-[#555] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
            입찰건수
          </div>
          <div className='pl-4 col-span-3 text-[#222] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
            {member.dealerInfo?.info.bidCount ?? 0}건
          </div>
        </div>
        <div className='grid grid-cols-4'>
          <div className='text-[#555] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
            성사건수
          </div>
          <div className='pl-4 col-span-3 text-[#222] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
            {member.dealerInfo?.info.acceptCount ?? 0}건 (
            {(
              ((member.dealerInfo?.info.acceptCount ?? 0) /
                (member.dealerInfo?.info.bidCount ?? 0)) *
              100
            ).toFixed(1)}
            %)
          </div>
        </div>
        <div className='grid grid-cols-4'>
          <div className='text-[#555] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
            가입일자
          </div>
          <div className='pl-4 col-span-3 text-[#222] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
            {dayjs(member.dealerInfo?.info.created_at).format('YYYY.MM.DD')}
          </div>
        </div>
      </div>

      {/* Divider */}
      <div className='w-full h-[1px] bg-[#E5EAEE]'></div>

      {member.description ? (
        <div className='rounded-[10px] bg-[#F3F6F9] px-4 py-5 min-h-[120px] text-[#222] text-[16px] font-normal leading-[25.6px] tracking-[-0.32px]'>
          {member.description}
        </div>
      ) : (
        <div className='flex items-center justify-center rounded-[10px] bg-[#F3F6F9] px-4 py-5 min-h-[120px] text-[#999] text-center text-[16px] font-normal leading-[25.6px] tracking-[-0.32px]'>
          등록된 소개 정보가 없습니다.
        </div>
      )}
    </div>
  );
}

function DealerInfoReview({ member }: { member: Member }) {
  const { data: reviewList, isFetching } = useQuery({
    queryKey: ['dealerInfo/review', member.id],
    queryFn: () => getManagerReviewList({ memberId: member.id }),
    // 5분 동안 캐시 유지
    staleTime: 1000 * 60 * 5,
  });

  const [viewerFiles, setViewerFiles] = useState<FileInfo[]>([]);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [imageViewerIndex, setImageViewerIndex] = useState(0);

  return (
    <div className='py-6 px-5'>
      {(isFetching || reviewList?.length === 0) && (
        <div className='bg-[#F3F6F9] rounded-[10px] flex items-center justify-center h-[160px]'>
          {isFetching ? (
            <CircularProgress />
          ) : (
            <>
              <div className='text-[#999] text-center text-[16px] font-normal leading-[25.6px] tracking-[-0.32px]'>
                업데이트 예정입니다.
              </div>
            </>
          )}
        </div>
      )}

      <div className='divide-y divide-[#E5EAEE]'>
        {reviewList?.map((review) => {
          const attachedFiles = [
            review?.file_1,
            review?.file_2,
            review?.file_3,
            review?.file_4,
            review?.file_5,
          ].filter((file): file is FileInfo => Boolean(file));

          return (
            <div key={review.orderId} className='py-3'>
              {/* 상단 영역 */}
              <div className='flex items-center justify-between gap-2.5'>
                <div className='flex items-center gap-2.5'>
                  <img
                    src={review.writer.imageUrl}
                    alt='profile'
                    className='w-[36px] h-[36px] rounded-full'
                  />
                  <div>{review.writer.name}</div>
                </div>

                {/* 별점 영역 */}
                <div className='flex justify-start items-center'>
                  {[...Array(review.rating)].map((_, index) => (
                    <Star
                      key={index}
                      fill={'#FFC93E'}
                      width='16'
                      height='16'
                      stroke='0'
                    />
                  ))}
                  {[...Array(5 - (review.rating ?? 0))].map((_, index) => (
                    <Star
                      key={index}
                      fill={'#DDDDDD'}
                      width='16'
                      height='16'
                      stroke='0'
                    />
                  ))}
                </div>
              </div>

              {/* 제목 영역 */}
              <div className='mt-5 text-[#222] text-[15px] font-medium leading-[15px] tracking-[-0.3px] whitespace-pre-line break-keep'>
                {review.title}
              </div>

              {/* 내용 영역 */}
              <div className='mt-1.5 text-[#222] text-[15px] font-normal leading-[22.5px] tracking-[-0.3px] whitespace-pre-line break-keep'>
                {review.content}
              </div>

              {/* 사진 존재 시 */}
              {attachedFiles.length > 0 && (
                <div className='pt-5 overflow-x-auto flex gap-2 scroll-hidden'>
                  {attachedFiles.map((file, index) => (
                    <div
                      key={file.id}
                      className='h-[107px] aspect-square rounded-[6px]'
                      onClick={() => {
                        setViewerFiles(attachedFiles);
                        setImageViewerIndex(index);
                        setIsImageViewerOpen(true);
                      }}
                    >
                      <img
                        src={file.url}
                        alt='review'
                        className='w-full h-full object-cover rounded-[6px]'
                      />
                    </div>
                  ))}
                </div>
              )}

              {/* 리뷰 작성일시 */}
              <div className='mt-5 text-[#555] text-[13px] font-medium leading-[13px] tracking-[-0.26px]'>
                {dayjs(review.created_at).format('YYYY.MM.DD')} 작성
              </div>
            </div>
          );
        })}
      </div>

      <MultiImageViewer
        isOpen={isImageViewerOpen}
        setIsOpen={setIsImageViewerOpen}
        initialIndex={imageViewerIndex}
        fileUrl={viewerFiles.map((file) => file.url)}
      />
    </div>
  );
}

export default function DealerInfoPage() {
  const { id } = useParams();

  const navigate = useNavigate();
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      rightElement: (
        <button onClick={() => navigate(-1)}>
          <X />
        </button>
      ),
      title: '카 매니저 소개',
    });
    setIsBottomTabbar(false);
  }, []);

  const { data: dealerInfo, isFetching } = useQuery({
    queryKey: ['dealerInfo', id],
    queryFn: () => getDealerInfo({ memberId: id as string }),
    enabled: !!id,
  });

  const [tab, setTab] = useState<string>(tabs[0]);

  return (
    <div className='relative flex flex-col flex-auto w-full h-full'>
      {isFetching || !dealerInfo ? (
        <div className='w-full h-full flex justify-center items-center'>
          <CircularProgress />
        </div>
      ) : (
        <div className='flex-auto flex flex-col'>
          <DealerInfoHeader member={dealerInfo} />
          <DealerOrderDetailTabSelector tabs={tabs} tab={tab} setTab={setTab} />

          {tab === '소개' && <DealerInfoDescription member={dealerInfo} />}
          {tab === '구매 후기' && <DealerInfoReview member={dealerInfo} />}
        </div>
      )}
    </div>
  );
}
