import { cn } from 'utils';
import { OrderSellingForm } from '../../../form';
import FileInput, { UploadedFileInfo } from '../../FileInput';
import { SellingOrderDTO } from '@carsayo/types';

const OrderSelling_Photo = ({
  form,
  sellingOrderData,
}: {
  form: OrderSellingForm;
  sellingOrderData: SellingOrderDTO;
}) => {
  return (
    <div>
      <div className='flex flex-col gap-4'>
        <div>필수 사진</div>
        <div className='flex flex-col gap-[10px]'>
          {['전면', '후면', '계기판'].map((item, index) => {
            return (
              <FileInput
                fileName={item}
                key={`file_${index + 1}` as keyof UploadedFileInfo}
                fileKey={`file_${index + 1}` as keyof UploadedFileInfo}
                form={form}
                sellingOrderData={sellingOrderData}
              />
            );
          })}
        </div>
      </div>

      <div className='mt-6 flex flex-col gap-4'>
        <div>추가 사진</div>
        <div className='grid grid-cols-3 gap-[7px]'>
          {['좌측면', '우측면', '실내'].map((item, index) => {
            return (
              <FileInput
                fileName={item}
                type='mini'
                key={`file_${index + 4}` as keyof UploadedFileInfo}
                fileKey={`file_${index + 4}` as keyof UploadedFileInfo}
                form={form}
                sellingOrderData={sellingOrderData}
              />
            );
          })}
        </div>
      </div>

      <div className='w-full rounded-[10px] bg-[#F3F6F9] p-4 mt-[30px]'>
        <div className='border-b border-[#E5E5EC] pb-4 text-[15px] font-[500] leading-[22.5px]'>
          확인해 주세요
        </div>
        <ul className='mt-5 flex w-full list-none flex-col items-start justify-start gap-3'>
          <li
            className={cn(
              'relative break-keep pl-5 text-[14px] leading-[21px] text-[#555555]',
              'after:absolute after:left-[9px] after:top-[10px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
            )}
          >
            잘 찍은 사진이라면 더 정확한 견적을 받을 수 있어요.
          </li>
          <li
            className={cn(
              'relative break-keep pl-5 text-[14px] leading-[21px] text-[#555555]',
              'after:absolute after:left-[9px] after:top-[10px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
            )}
          >
            거래가 불가능한 차량을 등록한 경우 계정 정지 및 법률적인 책임을 져야
            합니다.
          </li>
        </ul>

        <div className='w-full rounded-[10px] bg-white p-4 mt-[17.5px] text-[14px] leading-[150%] text-[#555555]'>
          <div className='pb-[14px] font-[500]'>[거래 불가 차량]</div>
          <ol className='list-decimal pl-4 text-[14px] font-[400] leading-[22.4px]'>
            <li>
              차량 원본 사진이 아닌 포토샵 또는 이미지 수정을 통해 견적을 요청한
              경우
            </li>
            <li>
              차량 정보(성능, 옵션, 이용 내역)을 미제공하여 견적을 요청한 경우
            </li>
            <li>
              거래가 불가능한 대포 차량, 허위 매물, 법적 문제가 있는 차량 등의
              견적을 요청한 경우
            </li>
          </ol>
        </div>
      </div>
    </div>
  );
};

export default OrderSelling_Photo;
