/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useMemo, useState } from 'react';
import { SelectColorProps } from '../type';
import { ChevronDown, X } from 'lucide-react';
import { motion } from 'framer-motion';
import ColorSquare from './ColorSquare';
import SelectColorDrawer from '../drawer/SelectColorDrawer';
import { useNavigate } from 'react-router-dom';
import { KEY_INTERIOR_CAR_DIRECTINPUT } from 'pages/v3/order/purchase/constants';

type SelectColorPartProps = SelectColorProps & {
  mode: 'exterior' | 'interior';
};

type SelectColorMessages = {
  inCompatibleColors: string[];
  nessaryOptions: string[];
  warningMessage?: string;
};

export default function SelectColorPart({
  form,
  mode,
  wholeSellingCar,
  selectedExteriorColor,
  selectedInteriorColor,
  sortedCarColor,
  cachedCarOptions,
}: SelectColorPartProps) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const selectedItem =
    mode === 'exterior' ? selectedExteriorColor : selectedInteriorColor;

  /**
   * 선택 조건
   * 1. 현재 선택 색상의 제약조건
   * 2. 현재 선택 색상을 선택하지 못하게 하는 색상
   * 3. 현재 색상 목록 내에 같은 색상명이 있는 값
   */
  const selectRestrictions = useMemo(() => {
    if (!selectedItem) return [];

    // 현재 선택 색상의 제약조건
    const currentColorRestrictions =
      selectedItem.restrictions?.incompatible?.color ?? [];

    // 현재 선택 색상을 선택하지 못하게 하는 색상
    const currentColorInCompatible: string[] = [];

    const targetColorList =
      mode === 'exterior'
        ? Object.values(sortedCarColor.interior).flat()
        : Object.values(sortedCarColor.exterior).flat();

    targetColorList.forEach((color) => {
      if (!color.restrictions?.incompatible?.color) {
        return;
      }

      if (color.restrictions?.incompatible?.color.includes(selectedItem.name)) {
        currentColorInCompatible.push(color.name);
      }
    });

    const set = new Set([
      ...currentColorRestrictions,
      ...currentColorInCompatible,
    ]);

    // 현재 선택 색상 목록 내에 같은 색상명이 있는 값
    const filteredColorList = Array.from(set).filter((colorName) => {
      return targetColorList.some((color) => {
        return color.name === colorName;
      });
    });

    return filteredColorList;
  }, [selectedInteriorColor, selectedExteriorColor, mode, selectedItem]);

  const messages: SelectColorMessages = useMemo(() => {
    if (!selectedItem)
      return {
        inCompatibleColors: [],
        nessaryOptions: [],
        warningMessage: undefined,
      };

    const nessaryOptions = selectedItem.restrictions?.nessary?.option ?? [];
    const warningMessage = selectedItem.warning_text ?? undefined;

    return {
      inCompatibleColors: selectRestrictions,
      nessaryOptions: nessaryOptions.filter((option) => {
        return cachedCarOptions?.some((el) => {
          return el.name === option;
        });
      }),
      warningMessage: warningMessage,
    };
  }, [selectedItem, sortedCarColor, cachedCarOptions, selectRestrictions]);

  /** 선택 제한 조건 */

  /** Drawer 안드로이드 백버튼으로 닫히도록 */
  useEffect(() => {
    if (!window.native) return;

    if (isOpen) {
      window.native.onBackPressed = () => {
        setIsOpen(false);
      };
    } else {
      window.native.onBackPressed = () => {
        navigate(-1);
      };
    }

    return () => {
      if (window.native) {
        window.native.onBackPressed = () => {
          navigate(-1);
        };
      }
    };
  }, [isOpen]);

  return (
    <>
      <div>
        {/* 색상 선택 Drawer 여는 영역 */}
        <div
          className='cursor-pointer w-full flex h-[74px] gap-4 justify-between border border-[#E5E5EC] rounded-[8px] items-center pl-4 py-4 pr-[12px] text-sm text-[#555555]'
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <div className='flex items-center gap-4'>
            <ColorSquare color={selectedItem} />
            <div className='flex flex-col gap-1.5 h-[44px] items-start justify-center'>
              <div className='text-[#222] text-[16px] font-semibold leading-[18px] tracking-[-0.32px] text-left'>
                {selectedItem?.id === KEY_INTERIOR_CAR_DIRECTINPUT
                  ? '직접 입력'
                  : selectedItem?.name ?? '미선택'}
              </div>
              {selectedItem && selectedItem?.price !== 0 && (
                <div className='text-[#222] text-[14px] font-medium leading-[14px] tracking-[-0.28px]'>
                  {`${selectedItem.price > 0 ? '+' : ''}${selectedItem.price.toLocaleString()}`}
                  원
                </div>
              )}
            </div>
          </div>

          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className='ml-auto'
          >
            <ChevronDown
              width={24}
              height={24}
              strokeWidth={1.5}
              color={'#666666'}
              className='ml-auto'
            />
          </motion.div>
        </div>

        {/* 추가 설명 문구 */}
        <>
          {messages.inCompatibleColors.length > 0 && (
            <div className='flex gap-4 my-4'>
              <div className='flex w-0.5 bg-[#FF4747]'></div>
              <div className='flex flex-col gap-2'>
                {/* 내장 색상을 선택할 수 없어요., 외장 색상을 선택할 수 없어요. */}
                <div className='text-gray-600 text-[13px] font-medium leading-[13px] tracking-[-0.26px]'>
                  다음 {mode === 'exterior' ? '내장' : '외장'} 색상을 선택할 수
                  없어요.
                </div>
                <div className='text-gray-800 text-[14px] font-semibold leading-[21px] tracking-[-0.28px]'>
                  {messages.inCompatibleColors.join(', ')}
                </div>
              </div>
            </div>
          )}
          {messages.nessaryOptions.length > 0 && (
            <div className='flex gap-4 my-4'>
              <div className='flex w-0.5 bg-[#FF4747]'></div>
              <div className='flex flex-col gap-2'>
                <div className='text-gray-600 text-[13px] font-medium leading-[13px] tracking-[-0.26px]'>
                  다음 옵션이 필수입니다.
                </div>
                <div className='text-gray-800 text-[14px] font-semibold leading-[21px] tracking-[-0.28px]'>
                  {messages.nessaryOptions.join(', ')}
                </div>
              </div>
            </div>
          )}
          {messages.warningMessage && (
            <div className='flex gap-4 my-4'>
              <div className='flex w-0.5 bg-[#FF4747]'></div>
              <div className='flex flex-col gap-2'>
                <div className='text-gray-800 text-[14px] font-semibold leading-[21px] tracking-[-0.28px] whitespace-pre-line'>
                  {messages.warningMessage}
                </div>
              </div>
            </div>
          )}
        </>
      </div>

      <SelectColorDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        form={form}
        mode={mode}
        wholeSellingCar={wholeSellingCar}
        selectedExteriorColor={selectedExteriorColor}
        selectedInteriorColor={selectedInteriorColor}
        sortedCarColor={sortedCarColor}
        cachedCarOptions={cachedCarOptions}
      />
    </>
  );
}
