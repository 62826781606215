export default function HoldLetter() {
  return (
    <svg
      width='51'
      height='51'
      viewBox='0 0 51 51'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <g clipPath='url(#clip0_3418_70511)'>
        <path
          d='M30.97 2.19C28.05 -0.73 23.28 -0.73 20.36 2.19L0.5 21.6L24.75 42.76L50.82 21.6L30.97 2.19Z'
          fill='#D2D7E2'
        />
        <path
          d='M35.674 6H15.326C11.28 6 8 9.27997 8 13.326V33.674C8 37.72 11.28 41 15.326 41H35.674C39.72 41 43 37.72 43 33.674V13.326C43 9.27997 39.72 6 35.674 6Z'
          fill='#F8F9FC'
        />
        <circle cx='18.5' cy='21' r='2' fill='#ABAEB1' />
        <circle cx='25.5' cy='21' r='2' fill='#ABAEB1' />
        <circle cx='32.5' cy='21' r='2' fill='#ABAEB1' />
        <path
          d='M29.89 35.5396C26.99 37.1396 24.11 37.1396 21.22 35.5396L0.5 21.5996V42.8296C0.5 46.9196 3.85 50.2796 7.95 50.2796H43.37C47.47 50.2796 50.82 46.9296 50.82 42.8296V21.5996L29.89 35.5396Z'
          fill='#E8ECF2'
        />
        <path
          d='M4.28964 46.9606C4.11964 46.9606 3.94964 46.8706 3.85964 46.7206C3.71964 46.4806 3.78964 46.1806 4.02964 46.0306L18.3596 37.4306C18.5996 37.2906 18.8996 37.3706 19.0496 37.6006C19.1896 37.8406 19.1196 38.1406 18.8796 38.2906L4.54964 46.8906C4.46964 46.9406 4.37964 46.9606 4.28964 46.9606Z'
          fill='#BAC3D6'
        />
        <path
          d='M46.7603 46.9609C46.6703 46.9609 46.5803 46.9409 46.5003 46.8909L32.1703 38.2909C31.9303 38.1509 31.8603 37.8409 32.0003 37.6009C32.1403 37.3609 32.4503 37.2909 32.6903 37.4309L47.0203 46.0309C47.2603 46.1709 47.3303 46.4809 47.1903 46.7209C47.1003 46.8809 46.9303 46.9609 46.7603 46.9609Z'
          fill='#BAC3D6'
        />
      </g>
      <defs>
        <clipPath id='clip0_3418_70511'>
          <rect
            width='50.32'
            height='50.28'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
