/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

const selectImage = {
  checked: '/assets/images/png/check.png',
  unchecked: '/assets/images/png/unCheck.png',
};

const StyledCheck = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== 'type',
})<CheckboxProps>(() => ({
  width: 28,
  height: 28,
  borderRadius: '50%',
  border: '1px solid #E5E5EC',
  '&.Mui-checked': {
    borderColor: 'transparent',
    color: 'transparent',
    backgroundImage: `url(${selectImage.checked})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  '&.MuiCheckbox-root:not(.Mui-checked)': {
    borderColor: 'transparent',
    color: 'transparent',
    backgroundImage: `url(${selectImage.unchecked})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  '&.Mui-disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
}));

interface CustomCheckProps extends CheckboxProps {
  className?: string;
}

const MemoCheckbox: React.FC<CustomCheckProps> = ({ className, ...props }) => {
  return (
    <FormControlLabel
      sx={{
        marginRight: '0px',
        marginLeft: '0px',
      }}
      control={<StyledCheck {...props} />}
      label={''}
    />
  );
};
export { MemoCheckbox };
