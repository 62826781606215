import { useQueryClient } from '@tanstack/react-query';
import { MyOrderListTab } from '../components/myOrderList';
import { BiddingOrderListTab } from '../components/biddingOrderList';

export default function useInvalidateDealerSearch() {
  const queryClient = useQueryClient();

  const InvalidateBiddingOrderList = () => {
    Object.keys(BiddingOrderListTab).forEach((key) => {
      queryClient.invalidateQueries({
        queryKey: ['orderList', key],
      });
    });
  };

  const InvalidateMyOrderList = () => {
    Object.keys(MyOrderListTab).forEach((key) => {
      queryClient.invalidateQueries({
        queryKey: ['orderList', key],
      });
    });
  };

  const InvalidateWholeDealerSearch = () => {
    InvalidateBiddingOrderList();
    InvalidateMyOrderList();
  };

  return {
    InvalidateBiddingOrderList,
    InvalidateMyOrderList,
    InvalidateWholeDealerSearch,
  };
}
