/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useLayoutEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import useMemberStore from 'store/useMemberStore';
import { Link, useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { isLogin, logoutApi, setFcmToken } from 'apis/authentication';
import {
  PopularCar,
  PopularCarCategory,
  UpdateInterestCarDTO,
  InterestCarInfo,
  PopularCarCategoryId,
} from '@carsayo/types';
import HeartIcon from 'components/common/global/svg/HeartIcon';
import {
  getInterestCarList,
  getPopularCarList,
  selectInterestCar,
} from 'apis/car';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { cn } from 'utils';
import { ChevronLeft, ChevronRight } from 'lucide-react';
import SwiperCore from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import MainSwiperSection from './components/MainSwiperSection';
import MainNavSection from './components/MainNavSection';
import MainInsuranceSection from './components/MainInsuranceSection';
import ShortNavSection from './components/ShortNavSection';
import MainFooter from './components/MainFooter';
import { useQueryClient } from '@tanstack/react-query';

interface InterestTemplateProps {
  data: InterestCarInfo;
  setInterestCarList: () => void;
}

function InterestTemplate({ data, setInterestCarList }: InterestTemplateProps) {
  const [isInterestCar, setIsInterestCar] = useState(data.isInterest);
  const carCategoryColors: Record<number, string> = {
    1: 'bg-[#0EBB86]', // 경차
    2: 'bg-[#333333]', // 소형차
    3: 'bg-[#17A279]', // 준중형차
    4: 'bg-[#077F5B]', // 중형차
    5: 'bg-[#6841DA]', // 대형차
    6: 'bg-[#4363EC]', // 스포츠카
    7: 'bg-[#C2255C]', // RV
    8: 'bg-[#333333]', // SUV
    9: 'bg-[#09869C]', // 승합차
    10: 'bg-[#333333]', // 화물차
    11: 'bg-[#333333]', // 버스
    21: 'bg-[#333333]', // 특장차
    20: 'bg-[#333333]', // 기타
    24: 'bg-[#333333]', // 하이브리드
    25: 'bg-[#333333]', // 전기차
  };

  function getColorById(id: number): string {
    return carCategoryColors[id] || 'bg-[#333333]';
  }
  const handleClickEvents = {
    selecteInterestCar: async () => {
      try {
        const result: UpdateInterestCarDTO = await selectInterestCar(
          data.id ?? 0,
          !isInterestCar,
        );
        if (result) {
          setInterestCarList();
          if (result.isInterest) {
            CarsayoToast.text('관심차량을 등록했습니다.');
          } else {
            CarsayoToast.text('관심차량을 해제했습니다.');
          }
          setIsInterestCar(result.isInterest);
          return;
        }
      } catch (error: any) {
        console.error(error);
        return;
      }
    },
  };
  return (
    <>
      <div className='relative mt-6 flex min-h-[220px] py-[1.84vh] items-center justify-center bg-[rgba(0,0,0,.03)]'>
        <img
          src={data.image_url}
          alt={data.name}
          className='w-full object-contain  lg:py-[10px]  md:py-[10px] px-[20vw] py-[1.84vh]'
        />
        <span
          className={cn(
            'absolute right-[10px] top-0 text-[13px] leading-[13px] text-white font-[600] py-[8px] px-[11px] rounded-b-[4px] h-[29px] flex justify-center items-center',
            data.car_category?.id
              ? getColorById(data.car_category.id)
              : 'bg-[#333]',
          )}
        >
          {data.car_category?.name}
        </span>
      </div>
      <div className='mt-[30px] px-[20px]'>
        <div className='font-semibold flex justify-between items-center h-[14px] mb-[10px]'>
          <span className='text-[14px] leading-[14px] font-[500] text-[#555]'>
            {data.carMaker.name ?? '제조사명 없음'}
          </span>
          <button
            className='w-[30px] h-[30px] text-transparent indent-[-9999px]'
            onClick={() => handleClickEvents.selecteInterestCar()}
          >
            <HeartIcon
              fill={isInterestCar ? '#FF6A6A' : undefined}
              stroke={isInterestCar ? '#FF6A6A' : undefined}
              width={'30px'}
              height={'30px'}
            />
            관심차량버튼
          </button>
        </div>
        <div className='text-[16px] font-semibold leading-[25.6px] text-[#222222] mb-1'>
          {data.name ?? '모델명 없음'}
        </div>
        <div className='text-[15px] font-[500] leading-[24px] text-[#555555]'>
          {data.name ?? '세부 모델명명 없음'}
        </div>
        <div className='text-[18px] leading-[22px] font-semibold text-[#222222] text-right'>
          {/* {data.price
            ? `${Math.floor((data.price.min + data.price.max) / 20000).toLocaleString()} 만원`
            : '금액없음'} */}
          {data.price
            ? `${Math.floor(data.price.min / 10000).toLocaleString()} 만원~`
            : '금액없음'}
        </div>
        <Link
          to={
            process.env.REACT_APP_NODE_ENV !== 'development'
              ? `/purchase/brand?carModelId=${data.id}`
              : '/order/purchase?mode=rent'
          }
          className='mt-6 flex h-[50px] items-center justify-center rounded-[8px] bg-[#222222] text-white text-[15px] leading-[22.5px]'
        >
          견적 확인하기
        </Link>
      </div>
    </>
  );
}

// 이용후기

// AD형 보험 이동 banner

function CustomerMainPage() {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [interestCarList, setInterestCarList] = useState<InterestCarInfo[]>();
  const [popularCarDomesticList, setPopularCarDomesticList] =
    useState<PopularCar[]>();
  const [popularCarImportedList, setPopularCarImportedList] =
    useState<PopularCar[]>();
  const [popularCarSuvList, setPopularCarSuvList] = useState<PopularCar[]>();

  const { loginMember, resetLoginMember } = useMemberStore();
  const { setIsBottomTabbar, setLoadingDim } = useSystemStore();

  const logout = async () => {
    setLoadingDim(true);
    try {
      await logoutApi();

      queryClient.resetQueries();

      resetLoginMember();
      navigate('/auth/signup', { replace: true });
    } catch (e) {
      CarsayoToast.error(`${e}`);
      setLoadingDim(false);
    }
  };

  const handleVoidEvents = {
    checkLogin: async () => {
      if (!loginMember) {
        const {
          data: { isLogined },
        } = await isLogin();
        if (!isLogined) {
          navigate('/auth/signup', { replace: true });
          return;
        }
      }
    },

    getPopularCarList: async () => {
      const carListArray: PopularCarCategory[] = await getPopularCarList();
      if (carListArray) {
        setPopularCarDomesticList(
          carListArray
            .filter((item) => item.id === 'domestic')
            .map((item) => item.carList)
            .flat(),
        );
        setPopularCarImportedList(
          carListArray
            .filter((item) => item.id === 'imported')
            .map((item) => item.carList)
            .flat(),
        );
        setPopularCarSuvList(
          carListArray
            .filter((item) => item.id === 'suv')
            .map((item) => item.carList)
            .flat(),
        );
      }
      return;
    },
    getInterestCarList: async () => {
      if (!loginMember) {
        CarsayoToast.error('로그인을 진행해주세요!');
        navigate('/auth/login');
      } else {
        const result: InterestCarInfo[] = await getInterestCarList();
        if (result) {
          setInterestCarList(result);
        }
      }
    },
  };

  useLayoutEffect(() => {
    setIsBottomTabbar(true);
    if (loginMember) setFcmToken();
  }, []);

  useLayoutEffect(() => {
    handleVoidEvents.getPopularCarList();
  }, []);

  useLayoutEffect(() => {
    if (loginMember) handleVoidEvents.getInterestCarList();
  }, [loginMember]);

  // 내 관심차량 리스트
  function MyInterestCar() {
    const [interestSwiper, setInterestSwiper] = useState<SwiperClass | null>(
      null,
    );
    const [interestPage, setInterestPage] = useState<number>(1);
    const [isAnimating, setIsAnimating] = useState(false);
    const handleSetInterestCarList = async () => {
      const carListArray: PopularCarCategory[] = await getPopularCarList();
      if (carListArray) {
        setPopularCarDomesticList(
          carListArray
            .filter((item) => item.id === 'domestic')
            .map((item) => item.carList)
            .flat(),
        );
        setPopularCarImportedList(
          carListArray
            .filter((item) => item.id === 'imported')
            .map((item) => item.carList)
            .flat(),
        );
        setPopularCarSuvList(
          carListArray
            .filter((item) => item.id === 'suv')
            .map((item) => item.carList)
            .flat(),
        );
      }
      const result: InterestCarInfo[] = await getInterestCarList();
      if (result) {
        setInterestCarList(result);
      }
    };
    if (interestCarList && interestCarList.length > 0) {
      return (
        <div className='relative'>
          <Swiper
            modules={[Pagination]}
            spaceBetween={50}
            slidesPerView={1}
            pagination={{ clickable: true }}
            className=' w-full h-full'
            onRealIndexChange={(swiper: SwiperClass) =>
              setInterestPage(swiper.realIndex + 1)
            }
            onTransitionStart={() => {
              setIsAnimating(true);
            }}
            onTransitionEnd={() => {
              setIsAnimating(false);
            }}
            onSwiper={(swiper: SwiperClass) => setInterestSwiper(swiper)}
          >
            {interestCarList.map((item, index) => (
              <SwiperSlide key={`interest-${index}`}>
                <InterestTemplate
                  data={item}
                  setInterestCarList={handleSetInterestCarList}
                />
              </SwiperSlide>
            ))}
          </Swiper>
          <ul className='mt-[30px] flex justify-center items-center gap-[6px]'>
            {interestCarList.map((item, index) => (
              <li
                key={`interestCarListPagination_${item.carMaker.id}`}
                className={cn(
                  'w-2 h-2 rounded-full bg-[#00000033] transition-all',
                  index === interestPage - 1 && 'w-[22px] bg-[#111]',
                )}
              ></li>
            ))}
          </ul>
          <div
            onClick={() => interestSwiper?.slidePrev()}
            className={cn(
              'absolute left-[10px] top-[calc(27%-20px)] flex justify-center items-center w-[40px] h-[40px] bg-[#00000033] rounded-full z-[5]',
              isAnimating && 'hidden',
            )}
          >
            <ChevronLeft
              className='-ml-[2px]'
              width={24}
              height={24}
              strokeWidth={1.5}
              stroke={'#fff'}
            />
          </div>
          <div
            onClick={() => interestSwiper?.slideNext()}
            className={cn(
              'absolute right-[10px] top-[calc(27%-20px)] flex justify-center items-center w-[40px] h-[40px] bg-[#00000033] rounded-full z-[5]',
              isAnimating && 'hidden',
            )}
          >
            <ChevronRight
              className='-mr-[2px]'
              width={24}
              height={24}
              strokeWidth={1.5}
              stroke={'#fff'}
            />
          </div>
        </div>
      );
    } else {
      return (
        <div
          className={cn(
            'w-full bg-[#F3F6F9] mt-6 flex flex-col justify-center items-center min-h-[220px] gap-6 py-[31px]',
          )}
        >
          <div className='flex flex-col items-center'>
            <div className='flex justify-center items-center bg-[#E5EAEE] rounded-full w-[80px] h-[80px]'>
              <img
                src='/assets/images/png/gray_car.png'
                alt='회색차'
                className='w-[45px] object-contain'
              />
            </div>
          </div>
          <p className='text-[#A1A7AC] text-[18px] leading-[27px] font-[600] text-center'>
            견적 요청하면서
            <br />
            관심 차량을 등록해 보세요.
          </p>
        </div>
      );
    }
  }

  return (
    <main className='bg-[#F3F6F9] text-sm'>
      {loginMember?.type === 'admin' && (
        <div
          onClick={(e) => {
            e.stopPropagation();
            e.preventDefault();
          }}
          className='w-full h-full fixed flex items-center justify-center bg-black/50 z-50 top-0 left-0'
        >
          <div className='flex flex-col items-center gap-4 justify-center bg-white px-5 max-w-[500px] pt-10 pb-4 rounded-lg'>
            <div className='text-center text-[16px] font-medium leading-[25.6px] tracking-[-0.32px]'>
              <img
                src='/assets/images/v2/casayo-logo.svg'
                alt=''
                className='h-20 w-20 mb-4'
              />
            </div>
            <div className='text-center text-[16px] font-medium leading-[25.6px] tracking-[-0.32px]'>
              현재 관리자 계정으로 로그인되어 <br /> 카사요 앱을 정상적으로
              이용할 수 없습니다.
            </div>
            <div className='px-5'>
              <div className='mt-4 w-full p-4'>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    logout();
                  }}
                  className='bg-[#7273F9] w-full text-white p-2 px-4 text-sm rounded-lg'
                >
                  로그아웃
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      <section className='main-swiper bg-white !min-h-[200px]'>
        <MainSwiperSection />
      </section>
      {/* nav Section */}
      <section className='bg-white pt-[40px] px-[20px] mb-2'>
        <MainNavSection />
      </section>
      <section className='bg-white pt-[40px] pb-[40px] px-[20px] mb-2'>
        <MainInsuranceSection />
      </section>
      {/* 내 관심 차량 */}
      <div className='mt-2 bg-white pt-[40px] pb-[50px]'>
        <div className='w-full bg-white lg:max-w-[600px] lg:mx-auto md:max-w-[600px] md:mx-auto'>
          <div className='flex items-center justify-between  px-5'>
            <div className='text-[22px] leading-[33px] font-[600] max-w-[178px] break-keep'>
              내 관심 차량에 대한 견적을 비교해 드려요
            </div>
          </div>
          <MyInterestCar></MyInterestCar>
        </div>
      </div>
      {/* 간편 메뉴 영역 */}
      <ShortNavSection
        isInterestCarList={
          interestCarList && interestCarList.length > 0 ? true : false
        }
      />
      <MainFooter />
    </main>
  );
}

export default CustomerMainPage;
