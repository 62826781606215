import { api } from 'apis';
import { TossPaymentStatus } from 'pages/v3/order/payment/api/tosspayment';
import { toast } from 'sonner';

export interface RefundPaymentProps {
  paymentId: string;
  reason: string;
  virtual?: {
    refundAcctNo: string;
    refundBankCd: string;
    refundAcctNm: string;
  };
}

export type CancelPaymentResponse = {
  data: {
    /** 실패 시 정의 */
    code?: string;
    message?: string;

    /** 성공 시 정의 */
    paymentKey?: string;
    status?: TossPaymentStatus;
  };
  message: string;
  status: string;
};
export const cancelPayment = async (reqData: {
  paymentKey: string;
  cancelReason: string;
  method: string;
  bankCode: string;
  accountNumber: string;
  holderName: string;
}): Promise<CancelPaymentResponse> => {
  try {
    const response = await api.post('tosspayment/cancel', reqData);
    return response.data;
  } catch (error: any) {
    if (error.isAxiosError && error.response) {
      toast.error(error.response.data.message);
    } else {
      toast.error(error.message);
    }
    throw error;
  }
};
