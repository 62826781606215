/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from 'components_v3/ui/Button';
import { X } from 'lucide-react';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';
import { AVALIABLE_ORDER_PRICE, banks } from '../constants';
import { useMutation } from '@tanstack/react-query';
import {
  tosspaymentConfirm,
  TossPaymentConfirmDTO,
  TossPaymentConfirmResponse,
  TossPaymentMethod,
  TossPaymentMethodMap,
  TossPaymentStatus,
} from '../api/tosspayment';
import { CircularProgress } from '@mui/material';
import dayjs from 'dayjs';
import useMemberStore from 'store/useMemberStore';

export default function AvaliableOrderPurchaseSuccessPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type') as 'purchase' | 'selling' | null;

  const { loginMember, refreshLoginMember } = useMemberStore();

  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();

  const [paymentResult, setPaymentResult] =
    useState<TossPaymentConfirmResponse | null>(null);

  const virtualAccountInfo: {
    accountNumber: string;
    bankName: string;
    customerName: string;
    dueDate: Date;
  } | null = useMemo(() => {
    if (!paymentResult?.data.virtual_account) {
      return null;
    }
    const virtualAccount = JSON.parse(paymentResult.data.virtual_account);
    return {
      accountNumber: virtualAccount.accountNumber,
      bankName:
        banks.find((bank) => bank.code === virtualAccount.bankCode)?.bank || '',
      customerName: virtualAccount.customerName,
      dueDate: virtualAccount.dueDate,
    };
  }, [paymentResult]);

  const { mutate: confirmPayment, isPending } = useMutation({
    mutationFn: (reqData: TossPaymentConfirmDTO) => tosspaymentConfirm(reqData),
    onSuccess: (data) => {
      setPaymentResult(data);
      refreshLoginMember();
    },
    onError: (error) => {
      return navigate(`/order/payment/failure?${searchParams.toString()}`, {
        replace: true,
      });
    },
  });

  useEffect(() => {
    confirmPayment({
      orderId: searchParams.get('orderId') as string,
      paymentKey: searchParams.get('paymentKey') as string,
      amount: Number(searchParams.get('amount')),
      purchaseItemId: type === 'purchase' ? 1000100 : 1000200,
    });
  }, []);

  useEffect(() => {
    if (!window.native) return;
    window.native.onBackPressed = () => {
      navigate('/main', {
        replace: true,
      });
    };
    return () => {
      if (!window.native) return;
      window.native.onBackPressed = () => {};
    };
  }, []);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '견적 요청권 구매',
      rightElement: (
        <div className='min-w-[28px] cursor-pointer'>
          <X
            onClick={() => {
              navigate('/main', {
                replace: true,
              });
            }}
            width={28}
            height={28}
            strokeWidth={1.5}
          />
        </div>
      ),
    });
    setIsBottomTabbar(false);
  }, []);

  return (
    <div className='w-full flex flex-col justify-between gap-[50px] items-start min-h-[calc(100dvh-60px)]'>
      {isPending && (
        <div className='w-full flex-auto flex justify-center items-center h-full'>
          <CircularProgress />
        </div>
      )}

      {!isPending && paymentResult && (
        <>
          {/* 결제 완료 시 */}
          {paymentResult.data.status === TossPaymentStatus.DONE && (
            <div className={cn('w-full', 'mb-[50px]')}>
              <div className='pt-[55px] pb-[106px] flex justify-center items-center'>
                <div className='w-full flex flex-col justify-center items-center gap-1.5'>
                  <div className='mb-[18px] flex justify-center items-center w-[60px] h-[60px]'>
                    <img
                      src='/assets/images/png/purchase_done.png'
                      alt='결제 완료 이미지'
                      className='h-full object-contain'
                    />
                  </div>
                  <strong className='text-[22px] leading-[33px] font-[600]'>
                    결제가 완료되었습니다.
                  </strong>
                  <p className='text-[16px] leading-[25.6px] font-[500]'>
                    카 매니저들의 견적서를 받아보세요.
                  </p>
                </div>
              </div>
              <div>
                {/* 구매 서비스 */}
                <div className='w-full pb-[30px] px-5 border-b-[8px] border-[#F3F6F9]'>
                  <div className='mb-6 text-[18px] leading-[18px] font-[600]'>
                    구매 서비스
                  </div>
                  <div className='flex justify-start items-center gap-4'>
                    <div className='flex justify-center items-center p-2.5 bg-[#F3F6F9] rounded-[10px]'>
                      <img
                        src='/assets/images/png/carsayo_card.png'
                        alt='카사요 기프트카드 이미지'
                        className='w-[70px] object-contain'
                      />
                    </div>
                    <div className='flex flex-col justify-start items-start gap-[14px]'>
                      <div className='flex flex-col justify-start items-start gap-0.5'>
                        <strong className='text-[15px] leading-[24px] font-[600]'>
                          견적 요청권
                        </strong>
                        <p className='text-[14px] leading-[22.4px] font-[500] text-[#555555]'>
                          {type === 'purchase' ? '신차 구매' : '내 차 팔기'}
                        </p>
                      </div>
                      <strong className='text-[16px] leading-[16px] font-[600]'>
                        {AVALIABLE_ORDER_PRICE.toLocaleString()}원
                      </strong>
                    </div>
                  </div>
                </div>
                {/* 주문 정보 */}
                <div className='w-full pt-[30px] pb-[30px] px-5 border-b-[8px] border-[#F3F6F9]'>
                  <div className='mb-6 text-[18px] leading-[18px] font-[600]'>
                    주문 정보
                  </div>
                  <ul className='py-5 px-4 bg-[#F3F6F9] rounded-[10px] text-[15px] leading-[15px] font-[500] flex flex-col justify-start items-start gap-5'>
                    <li className='w-full flex justify-between items-center gap-5'>
                      <span className='text-[#7D848A]'>주문일시</span>
                      <strong className='font-[500]'>
                        {dayjs(paymentResult.data.created_at).format(
                          'YYYY.MM.DD HH:mm',
                        )}
                      </strong>
                    </li>
                    <li className='w-full flex justify-between items-center gap-5'>
                      <span className='text-[#7D848A]'>주문자</span>
                      <strong className='font-[500]'>
                        {loginMember?.name_real}
                      </strong>
                    </li>
                    <li className='w-full flex justify-between items-center gap-5'>
                      <span className='text-[#7D848A]'>연락처</span>
                      <strong className='font-[500]'>
                        {loginMember?.phoneNumber}
                      </strong>
                    </li>
                  </ul>
                </div>
                {/* 결제 정보 */}
                <div className='w-full pt-[30px] px-5'>
                  <div className='mb-6 text-[18px] leading-[18px] font-[600]'>
                    결제 정보
                  </div>
                  <ul className='py-5 px-4 bg-[#F3F6F9] rounded-[10px] text-[15px] leading-[15px] font-[500] flex flex-col justify-start items-start gap-4'>
                    <li className='w-full flex justify-between items-center gap-5'>
                      <span className='text-[#7D848A]'>결제일시</span>
                      <strong className='font-[500] leading-[22.5px]'>
                        {dayjs(paymentResult.data.approved_at).format(
                          'YYYY.MM.DD HH:mm',
                        )}
                      </strong>
                    </li>

                    <li className='w-full flex justify-between items-center gap-5 h-[15px]'>
                      <span className='text-[#7D848A]'>결제금액</span>
                      <strong className='font-[500] leading-[22.5px]'>
                        {paymentResult.data.amount.toLocaleString()}원
                      </strong>
                    </li>
                    {/* 결제방법 - 결제 완료시 */}
                    {
                      <li className='w-full flex justify-between items-center gap-5'>
                        <span className='text-[#7D848A]'>결제방법</span>
                        <strong className='font-[500] leading-[22.5px]'>
                          {TossPaymentMethodMap[paymentResult.data.method]}
                        </strong>
                      </li>
                    }

                    <li className='w-full flex justify-between items-center gap-5 h-[15px]'>
                      <span className='text-[#7D848A]'>결제상태</span>
                      <strong className='font-[600] text-[#4860FF] leading-[22.5px]'>
                        결제완료
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}

          {/* 가상계좌 입금 대기 시 */}
          {paymentResult.data.status ===
            TossPaymentStatus.WAITING_FOR_DEPOSIT && (
            <div className={cn('w-full', 'mb-[50px]')}>
              <div className='pt-[55px] pb-[106px] flex justify-center items-center'>
                <div className='w-full flex flex-col justify-center items-center gap-1.5'>
                  <div className='mb-[18px] flex justify-center items-center w-[60px] h-[60px]'>
                    <img
                      src='/assets/images/png/purchase_wait.png'
                      alt='결제 대기 이미지'
                      className='h-full object-contain'
                    />
                  </div>
                  <strong className='text-[22px] leading-[33px] font-[600]'>
                    가상계좌 입금을 기다리고 있습니다.
                  </strong>
                  <p className='text-[16px] leading-[25.6px] font-[500]'>
                    입금 후 서비스를 이용해 보세요.
                  </p>
                </div>
              </div>
              <div>
                {/* 구매 서비스 */}
                <div className='w-full pb-[30px] px-5 border-b-[8px] border-[#F3F6F9]'>
                  <div className='mb-6 text-[18px] leading-[18px] font-[600]'>
                    구매 서비스
                  </div>
                  <div className='flex justify-start items-center gap-4'>
                    <div className='flex justify-center items-center p-2.5 bg-[#F3F6F9] rounded-[10px]'>
                      <img
                        src='/assets/images/png/carsayo_card.png'
                        alt='카사요 기프트카드 이미지'
                        className='w-[70px] object-contain'
                      />
                    </div>
                    <div className='flex flex-col justify-start items-start gap-[14px]'>
                      <div className='flex flex-col justify-start items-start gap-0.5'>
                        <strong className='text-[15px] leading-[24px] font-[600]'>
                          견적 요청권
                        </strong>
                        <p className='text-[14px] leading-[22.4px] font-[500] text-[#555555]'>
                          {type === 'purchase' ? '신차 구매' : '내 차 팔기'}
                        </p>
                      </div>
                      <strong className='text-[16px] leading-[16px] font-[600]'>
                        {AVALIABLE_ORDER_PRICE.toLocaleString()}원
                      </strong>
                    </div>
                  </div>
                </div>
                {/* 주문 정보 */}
                <div className='w-full pt-[30px] pb-[30px] px-5 border-b-[8px] border-[#F3F6F9]'>
                  <div className='mb-6 text-[18px] leading-[18px] font-[600]'>
                    주문 정보
                  </div>
                  <ul className='py-5 px-4 bg-[#F3F6F9] rounded-[10px] text-[15px] leading-[15px] font-[500] flex flex-col justify-start items-start gap-5'>
                    <li className='w-full flex justify-between items-center gap-5'>
                      <span className='text-[#7D848A]'>주문일시</span>
                      <strong className='font-[500]'>
                        {dayjs(paymentResult.data.created_at).format(
                          'YYYY.MM.DD HH:mm',
                        )}
                      </strong>
                    </li>
                    <li className='w-full flex justify-between items-center gap-5'>
                      <span className='text-[#7D848A]'>주문자</span>
                      <strong className='font-[500]'>
                        {loginMember?.name_real}
                      </strong>
                    </li>
                    <li className='w-full flex justify-between items-center gap-5'>
                      <span className='text-[#7D848A]'>연락처</span>
                      <strong className='font-[500]'>
                        {loginMember?.phoneNumber}
                      </strong>
                    </li>
                  </ul>
                </div>
                {/* 결제 정보 */}
                <div className='w-full pt-[30px] px-5'>
                  <div className='mb-6 text-[18px] leading-[18px] font-[600]'>
                    결제 정보
                  </div>
                  <ul className='py-5 px-4 bg-[#F3F6F9] rounded-[10px] text-[15px] leading-[15px] font-[500] flex flex-col justify-start items-start gap-4'>
                    <li className='w-full flex justify-between items-center gap-5 h-[15px]'>
                      <span className='text-[#7D848A]'>결제금액</span>
                      <strong className='font-[500] leading-[22.5px]'>
                        {paymentResult.data.amount.toLocaleString()}원
                      </strong>
                    </li>
                    {/* 결제방법 - 결제 대기시 */}
                    <li className='w-full flex justify-between items-start gap-5'>
                      <span className='text-[#7D848A] mt-[4px] shrink-0'>
                        결제방법
                      </span>
                      <div className='flex flex-col justify-start items-end gap-1 leading-[22.5px]'>
                        <p className='text-right break-keep'>
                          가상계좌 : {virtualAccountInfo?.bankName}{' '}
                          {virtualAccountInfo?.accountNumber}
                        </p>
                        <p className='text-right break-keep'>
                          입금기한 :{' '}
                          {dayjs(virtualAccountInfo?.dueDate).format(
                            'YYYY.MM.DD',
                          )}{' '}
                          (
                          {dayjs(virtualAccountInfo?.dueDate).diff(
                            dayjs(),
                            'day',
                          ) + 1}{' '}
                          일)
                        </p>
                        <p className='text-right break-keep'>
                          예금주 : {virtualAccountInfo?.customerName}
                        </p>
                      </div>
                    </li>

                    <li className='w-full flex justify-between items-center gap-5 h-[15px]'>
                      <span className='text-[#7D848A]'>결제상태</span>
                      <strong className='font-[600] text-[#CE8D00] leading-[22.5px]'>
                        입금 대기중
                      </strong>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          )}

          {paymentResult.data.status === TossPaymentStatus.DONE && (
            <div className='w-full p-5'>
              <Button
                theme='primary'
                onClick={() => {
                  if (type === 'purchase') {
                    navigate('/order/purchase?mode=lease', {
                      replace: true,
                    });
                  } else {
                    navigate('/order/selling', {
                      replace: true,
                    });
                  }
                }}
              >
                견적 요청 화면으로 이동
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  );
}
