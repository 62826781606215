/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import useSellingStore from './store';
import useFileUploader from 'store/useFileUploader';
import { encodeFileToBase64 } from 'utils/convertImage';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { submitSelling } from 'apis/selling';
import { ga4_event } from 'utils/ga4';
import { FileInfo } from '@carsayo/types';

const fileList: { title: string; isEssential: boolean }[] = [
  {
    title: '전면',
    isEssential: true,
  },
  {
    title: '후면',
    isEssential: true,
  },
  {
    title: '계기판',
    isEssential: true,
  },
  {
    title: '좌측면',
    isEssential: false,
  },
  {
    title: '우측면',
    isEssential: false,
  },
  {
    title: '실내',
    isEssential: false,
  },
];

type UploadedFileInfo = {
  file_1: FileInfo | null;
  file_2: FileInfo | null;
  file_3: FileInfo | null;
  file_4: FileInfo | null;
  file_5: FileInfo | null;
  file_6: FileInfo | null;
};

function FileInput({
  fileName,
  isEssential,
  key,
}: {
  fileName: string;
  isEssential: boolean;
  key: keyof UploadedFileInfo;
}) {
  const [imageSrc, setImageSrc] = useState<string>();
  const { fileUpload } = useFileUploader();
  const { sellingOrderDTO, setSellingOrderDTO } = useSellingStore();

  return (
    <label key={key} className='relative text-[#9EA3B2]'>
      <div
        onClick={() => {
          fileUpload(
            {
              acceptFile: ['image'],
              fileType: 'usedCarImage',
              fileName: fileName,
              isPublic: true,
            },
            async (fileInfo, file) => {
              if (fileInfo.extension !== 'pdf' && file) {
                const base64String = await encodeFileToBase64(file);
                setImageSrc(base64String);
              }

              if (sellingOrderDTO) {
                setSellingOrderDTO({
                  ...sellingOrderDTO,
                  update: {
                    [`${key}_id`]: fileInfo.id,
                  },
                });
              }
            },
          );
        }}
        className='flex h-[100px] items-center justify-center rounded border border-[#35383F] bg-[#1F222A]'
      >
        {imageSrc || sellingOrderDTO?.orderData.file[key]?.url ? (
          <img
            className='w-full h-full object-cover'
            src={
              imageSrc ? imageSrc : sellingOrderDTO?.orderData.file[key]?.url
            }
            alt=''
          />
        ) : (
          <img src='/assets/images/v2/camera-black.svg' alt='' />
        )}
      </div>
      <div className='mt-2 flex h-9 items-center justify-center border border-[#35383F]'>
        {fileName} {isEssential && '(필수)'}
      </div>
    </label>
  );
}

export default function SellingPhoto() {
  const navigate = useNavigate();
  const { topTabberState, setTopTabbarState, setLoadingDim } = useSystemStore();
  const { sellingOrderDTO, setSellingOrderDTO } = useSellingStore();

  const goBack = () => {
    navigate(-1);
  };

  const submit = async () => {
    if (!sellingOrderDTO) return;

    let fileNumber: number = 0;

    Object.keys(sellingOrderDTO.orderData.file).forEach((key) => {
      if (sellingOrderDTO.orderData.file[key as keyof UploadedFileInfo])
        fileNumber = fileNumber + 1;
    });

    for (let i = 0; i < fileList.length; i++) {
      const fileKey = `file_${i + 1}` as keyof UploadedFileInfo;
      if (fileList[i].isEssential && !sellingOrderDTO.orderData.file[fileKey]) {
        CarsayoToast.error(
          `${fileList[i].title} 사진이 업로드되지 않았습니다.`,
        );
        return;
      }
    }

    setLoadingDim(true);
    setTimeout(async () => {
      try {
        const submittedSelling = await submitSelling(sellingOrderDTO);
        if (submittedSelling.orderData.isSubmitted) {
          ga4_event({
            category: 'order_request',
            action: '내차팔기 신청 접수',
            label: submittedSelling.orderData.id,
          });
          CarsayoToast.error('내차팔기 주문이 성공했습니다');
          navigate('/', { replace: true });
          navigate('/quote/customer');
        } else {
          CarsayoToast.error(
            '주문에 실패했습니다\n잠시 후 다시 시도해 주세요.',
          );
          setLoadingDim(false);
        }
      } catch (e) {
        CarsayoToast.error(`${e}`);
        setLoadingDim(false);
      }
    }, 3000);
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      ...topTabberState,
      className: 'bg-[#0075FF] text-white',
    });
    window.native.onBackPressed = goBack;
    return () => {
      setLoadingDim(false);
    };
  }, []);

  return (
    <div className='min-h-full overflow-y-scroll'>
      <div className='h-[328px] bg-[#0075FF] text-center'>
        <div className='py-8 text-white'>
          <div className='text-[32px] font-semibold leading-[44px]'>
            사진만 올리면 준비됩니다
          </div>
          <div className='mt-3'>
            전국 카 매니저님의
            <br />
            수많은 견적을 확인해 보세요
          </div>
        </div>
      </div>

      <div className='-mt-[180px] p-4 pb-[120px]'>
        <div className='grid grid-cols-3 gap-x-[5.5px] gap-y-6 rounded-lg bg-[#181A20] px-4 py-8'>
          {fileList.map((item, index) =>
            FileInput({
              fileName: item.title,
              isEssential: item.isEssential,
              key: `file_${index + 1}` as keyof UploadedFileInfo,
            }),
          )}
        </div>

        <ul className='pt-4 text-[13px] list-disc pl-5 text-[#767676]'>
          <li className='mb-2'>필수 사진은 모두 업로드 해 주세요.</li>
          <li className='mb-2'>
            잘 찍은 사진이라면 더 정확한 견적을 받을 수 있어요.
          </li>
          <li className='mb-2'>
            거래가 불가능한 차량을 등록하셨을 경우 계정 정지 및 법률적인 책임을
            져야 합니다.
            <br />
            [거래 불가 차량]
            <br />
            1) 차량 원본 사진이 아닌 포토샵 또는 이미지 수정을 통해 견적을
            요청한 경우
            <br />
            2) 차량 정보(성능, 옵션, 이용 내역)을 미제공하여 견적을 요청한 경우
            <br />
            3) 거래가 불가능한 대포 차량, 허위 매물, 법적 문제가 있는 차량 등의
            견적을 요청한 경우
          </li>
        </ul>

        <div className='relative mt-[30px] rounded-lg bg-[#111111] px-4 py-8'>
          <div className='flex h-10 w-[140px] mb-6 items-center gap-2.5 rounded-[100px] bg-white px-1'>
            <div className='flex h-8 w-8 items-center justify-center rounded-full bg-[#181A20]'>
              <img
                src={sellingOrderDTO?.orderData.usedCarInfo.carInfo.modelimage}
                alt=''
                className='h-6 w-6'
              />
            </div>
            <div>{sellingOrderDTO?.orderData.usedCarInfo.carName}</div>
          </div>
          {sellingOrderDTO?.orderData.usedCarInfo.ownerName && (
            <div className='text-base font-semibold text-white'>
              {sellingOrderDTO?.orderData.usedCarInfo.ownerName}님
            </div>
          )}
          <div className='mt-1.5 whitespace-pre-line text-[#DDDDDD]'>{`계약하실 때 불편함 없이 최상의 조건을 
선택하실 수 있도록 카사요가 항상 함께하겠습니다.`}</div>
          <div className='absolute -top-[27px] right-3'>
            <img src='/assets/images/v2/member.svg' alt='' />
          </div>
        </div>
      </div>

      <div className='fixed inset-0 top-auto grid grid-cols-2 bg-white px-4 py-6'>
        <Button
          onClick={goBack}
          className='rounded-r-none border bg-white text-sm font-semibold text-[#111111]'
        >
          이전
        </Button>
        <Button
          className='rounded-l-none border-0 bg-[#0075FF] text-sm font-semibold text-white'
          onClick={submit}
        >
          내 차 견적 받기
        </Button>
      </div>
    </div>
  );
}
