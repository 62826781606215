'use client';

import { CircularProgress } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { TransitionProps } from '@mui/material/transitions';
import { X } from 'lucide-react';
import * as React from 'react';
import { TransformComponent, TransformWrapper } from 'react-zoom-pan-pinch';

import { FileInfo } from '@carsayo/types';

import { cn } from 'utils';
import DialogWrapper from 'components_v3/dialog';
import download from 'utils/download';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<unknown>;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction='right' ref={ref} {...props} />;
});

function FileIcon() {
  return (
    <svg
      width='70'
      height='70'
      viewBox='0 0 70 70'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <circle cx='35' cy='35' r='35' fill='#333333' />
      <g clipPath='url(#clip0_777_10966)'>
        <path
          d='M35.0001 18.3335L35.1951 18.3452C35.5671 18.389 35.9135 18.557 36.1784 18.8218C36.4433 19.0867 36.6112 19.4331 36.6551 19.8052L36.6667 20.0002V26.6668L36.6751 26.9168C36.7347 27.7104 37.0763 28.4565 37.6382 29.0202C38.2 29.5839 38.945 29.9279 39.7384 29.9902L40.0001 30.0002H46.6667L46.8617 30.0118C47.2338 30.0557 47.5802 30.2236 47.8451 30.4885C48.1099 30.7534 48.2779 31.0998 48.3217 31.4718L48.3334 31.6668V46.6668C48.3335 47.9422 47.8462 49.1694 46.9713 50.0973C46.0963 51.0252 44.8999 51.5837 43.6267 51.6585L43.3334 51.6668H26.6667C25.3914 51.6669 24.1642 51.1796 23.2363 50.3047C22.3084 49.4298 21.7499 48.2333 21.6751 46.9602L21.6667 46.6668V23.3335C21.6667 22.0581 22.154 20.831 23.0289 19.9031C23.9038 18.9752 25.1003 18.4167 26.3734 18.3418L26.6667 18.3335H35.0001Z'
          fill='white'
        />
        <path
          d='M46.6666 26.6669H40L39.9983 19.9985L46.6666 26.6669Z'
          fill='white'
        />
      </g>
      <defs>
        <clipPath id='clip0_777_10966'>
          <rect
            width='40'
            height='40'
            fill='white'
            transform='translate(15 15)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
function DownloadIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
    >
      <path
        d='M5.33325 22.6665V25.3332C5.33325 26.0404 5.6142 26.7187 6.1143 27.2188C6.6144 27.7189 7.29267 27.9998 7.99992 27.9998H23.9999C24.7072 27.9998 25.3854 27.7189 25.8855 27.2188C26.3856 26.7187 26.6666 26.0404 26.6666 25.3332V22.6665'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M9.33325 14.6665L15.9999 21.3332L22.6666 14.6665'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path
        d='M16 5.3335V21.3335'
        stroke='white'
        strokeWidth='1.5'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
    </svg>
  );
}
function CloseIcon() {
  return <X strokeWidth={1.2} className='h-8 w-8 text-white' />;
}

export default function FilePreviewer({
  isOpen,
  setIsOpen,
  fileInfo,
}: {
  isOpen: boolean;
  setIsOpen: (open: boolean) => void;
  fileInfo: FileInfo;
}) {
  const handleClose = () => {
    setIsOpen(false);
  };

  const [downloading, setIsDownloading] = React.useState(false);
  const runDownload = async () => {
    if (downloading) return;
    setIsDownloading(true);
    await download(fileInfo);
    setIsDownloading(false);
  };

  return (
    <DialogWrapper isOpen={isOpen} setIsOpen={setIsOpen}>
      <Dialog
        fullScreen
        open={isOpen}
        onClose={handleClose}
        TransitionComponent={Transition}
        PaperProps={{
          style: {
            backgroundColor: 'rgba(0, 0, 0)',
          },
        }}
        sx={{
          zIndex: 6000,
        }}
      >
        <div className='flex h-14 w-full flex-none items-center justify-between gap-4 pl-5 pr-4'>
          <div className='overflow-hidden truncate text-ellipsis text-[16px] font-medium leading-[16px] tracking-[-0.32px] text-white'>
            {fileInfo.name}.{fileInfo.extension}
          </div>
          <div className='flex h-14 flex-none items-center justify-between gap-1.5'>
            <button
              className='flex h-8 w-8 items-center justify-center text-white'
              disabled={downloading}
              onClick={runDownload}
            >
              {downloading === false ? (
                <DownloadIcon />
              ) : (
                <CircularProgress size={24} color='inherit' />
              )}
            </button>
            <button onClick={handleClose}>
              <CloseIcon />
            </button>
          </div>
        </div>

        <div className='flex flex-auto items-center justify-center overflow-hidden'>
          {/* 이미지 파일일 경우 */}
          {fileInfo.contentType.startsWith('image/') && (
            <TransformWrapper
              wheel={{ disabled: true }} // disables wheel zoom
              pinch={{ disabled: false }} // enables pinch-zoom
              doubleClick={{ disabled: true }} // disables double-click zoom
              limitToBounds={true}
            >
              <TransformComponent
                wrapperStyle={{ width: '100%', height: '100%' }}
                contentStyle={{ width: '100%', height: '100%' }}
              >
                <div
                  className={cn(
                    'relative flex h-[calc(100dvh-56px)] w-full items-center justify-center text-white',
                  )}
                >
                  <img src={fileInfo.url} className='max-h-full w-full' />
                </div>
              </TransformComponent>
            </TransformWrapper>
          )}

          {/* 이미지 파일이 아닐 경우 */}
          {!fileInfo.contentType.startsWith('image/') && (
            <div className='flex flex-col items-center gap-6 text-white'>
              <FileIcon />
              <span className='text-center text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                미리보기가 불가능한 파일입니다.
              </span>
            </div>
          )}
        </div>
      </Dialog>
    </DialogWrapper>
  );
}
