export default function NoColorSelectable() {
  return (
    <div className='flex flex-col w-full h-full justify-between p-5'>
      <div className='flex-auto flex flex-col gap-6 items-center justify-center'>
        <div>
          <img
            src={'/assets/images/v2/car/no_color_1.png'}
            alt='옵션이 없습니다.'
            width={80}
            height={80}
          />
        </div>
        <div className='text-[#A1A7AC] text-center text-[18px] font-semibold leading-[18px] tracking-[-0.36px]'>
          색상 선택이 불가한 차량이에요
        </div>
      </div>
    </div>
  );
}
