import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';
import TopTabbarContents from './contents';

export default function TopTabbar() {
  const { topTabberState } = useSystemStore();

  return (
    <div
      className={cn(
        'bg-transparent flex-none w-full',
        topTabberState.className,
      )}
    >
      {topTabberState.mode === 'main' && TopTabbarContents().main()}
      {topTabberState.mode === 'my' && TopTabbarContents().my()}
      {topTabberState.mode === 'mainType_2' && TopTabbarContents().mainType_2()}
      {topTabberState.mode === 'subpage' && TopTabbarContents().subpage()}
    </div>
  );
}
