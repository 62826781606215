import { CarColor } from '@carsayo/types';

/**
 * 색상 정렬 (카테고리별)
 *
 * 카테고리 없는것들 먼저 출력 - 있는것들 출력
 * Key의 순서도 의미를 가집니다
 *
 * 같은 함수를 앱과 관리자 클라이언트에서 공유하고 있습니다.
 */
export default function sortColor<T extends CarColor>(
  carColorList: T[],
): Record<string, T[]> {
  const returnData: Record<string, T[]> = { '': [] };
  if (!carColorList) return { '': [] };

  for (let i = 0; i < carColorList.length; i++) {
    const color = carColorList[i];
    if (!color.category) {
      returnData[''].push(color);
      continue;
    }

    // 키 없으면 빈Array 생성
    if (!returnData[color.category]) returnData[color.category] = [];
    returnData[color.category].push(color);
  }

  return returnData;
}
