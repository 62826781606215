import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import { Textarea } from 'components/ui/textarea';
import { ChevronRight } from 'lucide-react';
import { OrderSellingForm } from '../../../form';

const OrderSelling_Accident = ({
  form,
  setShowList,
}: {
  form: OrderSellingForm;
  setShowList: (isShowList: boolean) => void;
}) => {
  return (
    <div className='flex flex-col gap-[30px]'>
      <div
        onClick={() => setShowList(true)}
        className='items-center justify-between cursor-pointer rounded-[10px] bg-[#222222] text-white flex p-[13px] font-[500] text-[15px] leading-[150%]'
      >
        보험 처리 내역
        <ChevronRight strokeWidth={1.5} color='white' className='h-6 w-6' />
      </div>

      <div className='flex flex-col gap-6'>
        <div className='flex flex-col gap-3'>
          <div>사고 내역</div>
          <div>
            <SelectV3
              placeholder='선택해 주세요.'
              value={
                Object.entries(
                  form.watch('additionalInfo.accident') || {},
                ).find(([_, data]) => data.selected)?.[0] || ''
              }
              onValueChange={(value) => {
                const options = ['판금교환', '사고없음', '모름'] as const;

                options.forEach((option) => {
                  form.setValue(
                    `additionalInfo.accident.${option}.selected` as const,
                    value === option,
                  );
                });
              }}
            >
              <SelectV3Item value='사고없음'>사고 없음</SelectV3Item>
              <SelectV3Item value='판금교환'>
                판금 / 교환 부위 있음
              </SelectV3Item>
              <SelectV3Item value='모름'>잘 모르겠어요</SelectV3Item>
            </SelectV3>
          </div>

          {form.watch('additionalInfo.accident.판금교환.selected') && (
            <div className='relative'>
              <Textarea
                className='rounded-[8px]'
                placeholder='내용을 입력해 주세요.'
                maxLength={1000}
                onChange={(e) => {
                  form.setValue(
                    'additionalInfo.accident.판금교환.content',
                    e.target.value,
                  );
                }}
              ></Textarea>
              <span className='text-[#999999] font-[500] text-[14px] leading-[160%] absolute bottom-3 right-4'>{`${form.watch('additionalInfo.accident.판금교환.content')?.length ?? 0}/1,000`}</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default OrderSelling_Accident;
