import { cn } from 'utils';

export default function RefreshButton({
  onClick,
  onLoading,
}: {
  onClick: () => void;
  onLoading: boolean;
}) {
  return (
    <button
      className='flex items-center justify-center rounded-[8px] border border-[#E5EAEE] bg-white p-1.5'
      onClick={onClick}
    >
      <div className={cn(onLoading && 'animate-spin')}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          width='16'
          height='16'
          viewBox='0 0 16 16'
          fill='none'
        >
          <g clipPath='url(#clip0_3487_42919)'>
            <path
              d='M13.2894 8.69313C13.1635 9.6531 12.7786 10.5607 12.1761 11.3186C11.5736 12.0765 10.7762 12.656 9.86933 12.9952C8.96247 13.3343 7.98041 13.4201 7.02846 13.2435C6.07652 13.0669 5.1906 12.6345 4.46573 11.9927C3.74085 11.3508 3.20437 10.5238 2.91381 9.60023C2.62325 8.67667 2.58957 7.69144 2.81639 6.75019C3.04322 5.80895 3.52198 4.9472 4.20134 4.25737C4.8807 3.56754 5.73503 3.07566 6.67269 2.83447C9.27203 2.1678 11.9627 3.5058 12.956 5.99913'
              stroke='#222222'
              strokeWidth='1.33333'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
            <path
              d='M13.3333 2.66602V5.99935H10'
              stroke='#222222'
              strokeWidth='1.33333'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
          <defs>
            <clipPath id='clip0_3487_42919'>
              <rect width='16' height='16' fill='white' />
            </clipPath>
          </defs>
        </svg>
      </div>
    </button>
  );
}
