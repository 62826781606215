/* eslint-disable @typescript-eslint/no-unused-vars */
import RemainTime from 'pages/main/dealer/ui/remainTime';
import { DealerOrderDetailProps } from '../type';
import { ReactNode, useMemo, useState } from 'react';
import V3_Button from 'components_v3/ui/Button';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import dayjs from 'dayjs';
import { MemberShort, OrderDetail } from '@carsayo/types';
import DealerOrderBid from './features/bid';
import useMemberStore from 'store/useMemberStore';
import V3_Confirm from 'components_v3/dialog/Confirm';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import useInvalidateDealerSearch from 'pages/main/dealer/hook/invalidateDealerSearch';
import { reStartQuoteApi } from 'apis/quote';
import { CircularProgress } from '@mui/material';
import DealerOrderDetailCancelSubpage from './subpage/cancel';
import DealerOrderDetailFinishSubpage from './subpage/finish';
import DealerOrderDatePicker from '../ui/DatePicker';
import { useNavigate } from 'react-router-dom';
import DealerOrderDetailReviewSubpage from './subpage/review';

const typeText = (
  orderDetail: OrderDetail,
): { order: '매입' | '출고'; customer: '구매자' | '판매자' } => {
  if (
    orderDetail.type === 'newCar' ||
    orderDetail.type === 'lease' ||
    orderDetail.type === 'rent'
  ) {
    return { order: '출고', customer: '구매자' };
  } else {
    return { order: '매입', customer: '판매자' };
  }
};

const Container = ({ children }: { children: ReactNode }) => {
  return <div className='pt-5 px-5 pb-[30px]'>{children}</div>;
};

const MemberProfile = ({ member }: { member: MemberShort }) => {
  return (
    <div className='mt-5 p-4 flex items-center gap-4 rounded-[16px] border-[1px] border-[#E5EAEE] bg-white'>
      {/* 프로필 이미지 */}
      <div className='flex-none flex items-center justify-center w-[56px] h-[56px] rounded-full bg-white'>
        <img
          src={member.profileImage_url}
          className='rounded-full object-cover'
          alt='프로필 이미지'
        />
      </div>
      <div className='flex flex-col gap-2'>
        <div className='text-[#222] font-semibold text-[15px] leading-[21px] tracking-[-0.3px]'>
          {member.name_nick}
        </div>
        <div className='overflow-hidden text-[#555] text-[14px] font-medium leading-[14px] tracking-[-0.28px]'>
          {member.phoneNumber}
        </div>
      </div>
    </div>
  );
};

/**
 * @case 입찰중인 주문 && 내가(카매니저) 아직 입찰하지 않음
 * @feature "입찰 참여하기" 버튼
 */
function NoBidHeader({ orderDetail }: DealerOrderDetailProps) {
  const [isBidOpen, setIsBidOpen] = useState(false);

  return (
    <>
      <Container>
        {orderDetail.bid_ended_at && (
          <div className='flex justify-between items-center gap-1 mb-4'>
            <RemainTime bid_ended_at={orderDetail.bid_ended_at} />
          </div>
        )}

        <div className='text-[#222] text-[20px] font-semibold leading-[32px] tracking-[-0.4px] whitespace-pre-line break-keep'>
          {/* 내차팔기일 경우 차량번호 노출 */}
          {orderDetail.selling?.usedCarInfo.carName && (
            <div className='text-[#494AF1]'>
              [{orderDetail.selling.usedCarInfo.carName}]
            </div>
          )}
          견적 요청을 받았어요.
          <br />
          남은 시간 내 입찰에 참여해 주세요.
        </div>

        <div className='text-[#555] text-[14px] font-medium leading-[14px] tracking-[-0.28px] mt-2.5'>
          주문번호 {orderDetail.code}
        </div>

        {/* 기능 영역 */}
        <div className='pt-6 flex items-center gap-[7px]'>
          <V3_Button
            theme='primary'
            className='!h-[50px]'
            onClick={() => {
              setIsBidOpen(true);
            }}
          >
            입찰 참여하기
          </V3_Button>
        </div>
      </Container>
      <DealerOrderBid
        isOpen={isBidOpen}
        setIsOpen={setIsBidOpen}
        orderDetail={orderDetail}
      />
    </>
  );
}

/**
 * @case 입찰중인 주문 && 내가(카매니저) 입찰중
 * @feature "작성한 견적서 보기" 버튼
 */
function BiddingHeader({ orderDetail }: DealerOrderDetailProps) {
  const [isBidOpen, setIsBidOpen] = useState(false);

  return (
    <>
      <Container>
        {orderDetail.bid_ended_at && (
          <div className='flex justify-between items-center gap-1 mb-4'>
            <RemainTime bid_ended_at={orderDetail.bid_ended_at} />
            <div className='text-[#999] text-[13px] font-medium leading-[19.5px] tracking-[-0.26px]'>
              (입찰 중) {/* 견적 제출일시 */}
              {dayjs(orderDetail.bid?.created_at).format('YY.MM.DD HH:mm')}
            </div>
          </div>
        )}

        <div className='text-[#222] text-[20px] font-semibold leading-[32px] tracking-[-0.4px] whitespace-pre-line break-keep'>
          {/* 내차팔기일 경우 차량번호 노출 */}
          {orderDetail.selling?.usedCarInfo.carName && (
            <div className='text-[#494AF1]'>
              [{orderDetail.selling.usedCarInfo.carName}]
            </div>
          )}
          {typeText(orderDetail).customer}님이 카 매니저님들의
          <br />
          견적서를 선택하고 있어요.
        </div>

        <div className='text-[#555] text-[14px] font-medium leading-[14px] tracking-[-0.28px] mt-2.5'>
          주문번호 {orderDetail.code}
        </div>

        {/* 기능 영역 */}
        <div className='pt-6 flex items-center gap-[7px]'>
          <V3_Button
            className='!h-[50px]'
            theme='gray'
            onClick={() => {
              setIsBidOpen(true);
            }}
          >
            작성한 견적서 보기
          </V3_Button>
        </div>
      </Container>
      <DealerOrderBid
        isOpen={isBidOpen}
        setIsOpen={setIsBidOpen}
        orderDetail={orderDetail}
      />
    </>
  );
}

/**
 * @case 내가 유찰되었음
 * @note 카매니저 입장에서 유찰: 입찰하였는데, 입찰 종료 or 다른 카매니저가 입찰됨
 */
function FallBidHeader({ orderDetail }: DealerOrderDetailProps) {
  return (
    <Container>
      <div className='text-[#222] text-[20px] font-semibold leading-[32px] tracking-[-0.4px] whitespace-pre-line break-keep'>
        {/* 내차팔기일 경우 차량번호 노출 */}
        {orderDetail.selling?.usedCarInfo.carName && (
          <div className='text-[#494AF1]'>
            [{orderDetail.selling.usedCarInfo.carName}]
          </div>
        )}
        구매자님이 카 매니저의 견적서를
        <br />
        선택하지 않아 종료되었습니다.
      </div>

      <div className='text-[#555] text-[14px] font-medium leading-[14px] tracking-[-0.28px] mt-2.5'>
        주문번호 {orderDetail.code}
      </div>
    </Container>
  );
}

/**
 * @case 내가 입찰자이며, 진행중인 주문
 * @note 신차 구매일 때 "고객님과 통화", "계약서 업로드" 버튼
 * @note 내차팔기일 때
 * 1. "미팅 일정 등록" 버튼
 * 2. 미팅 일정 등록 시 "이전 등록증 업로드" 버튼 출력
 * @note 재진행시 텍스트가 달라집니다.
 */
function ProgressingHeader({ orderDetail }: DealerOrderDetailProps) {
  const type: 'purchase' | 'selling' = useMemo(() => {
    return orderDetail.type === 'newCar' ||
      orderDetail.type === 'lease' ||
      orderDetail.type === 'rent'
      ? 'purchase'
      : 'selling';
  }, [orderDetail]);

  const [isFinishSubpageOpen, setIsFinishSubpageOpen] = useState(false);
  const [isMeetingDatePickerOpen, setIsMeetingDatePickerOpen] = useState(false);

  return (
    <Container>
      <div className='flex justify-between items-center gap-1 mb-4'>
        <div className='h-[28px] flex items-center gap-1 rounded-[6px] bg-[#E0F3EB] text-[#009E59] text-[14px] font-semibold leading-[14px] tracking-[-0.28px] py-[5px] px-[6px]'>
          진행 중
        </div>
        <div className='text-[#999] text-[13px] font-medium leading-[19.5px] tracking-[-0.26px]'>
          <span className='text-[#009E59]'>(낙찰)</span>{' '}
          {/* 진행중으로 바뀐 일시 */}
          {dayjs(orderDetail.progressed_at).format('YY.MM.DD HH:mm')}
        </div>
      </div>

      <div className='text-[#222] text-[20px] font-semibold leading-[32px] tracking-[-0.4px] whitespace-pre-line break-keep'>
        {/* 내차팔기일 경우 차량번호 노출 */}
        {orderDetail.selling?.usedCarInfo.carName && (
          <div className='text-[#494AF1]'>
            [{orderDetail.selling.usedCarInfo.carName}]
          </div>
        )}
        {/* 보류되었던 적이 있는지 여부 */}
        {!orderDetail.holded_at ? (
          <>
            견적서가 낙찰됐어요.
            <br />
            {typeText(orderDetail).customer}님과의 {typeText(orderDetail).order}{' '}
            여정을 진행해 주세요.
          </>
        ) : (
          <>
            재진행되어 {typeText(orderDetail).customer}님과의
            <br />
            {typeText(orderDetail).order} 여정이 다시 시작됩니다.
          </>
        )}
      </div>

      <div className='text-[#555] text-[14px] font-medium leading-[14px] tracking-[-0.28px] mt-2.5'>
        주문번호 {orderDetail.code}
      </div>

      {/* 주문자 정보 영역 */}
      {orderDetail.member && <MemberProfile member={orderDetail.member} />}

      {/* 기능 영역 */}
      <div className='pt-6 flex items-center gap-[7px]'>
        <V3_Button
          className='!h-[50px]'
          theme='gray'
          onClick={() => {
            window.location.href = `tel:${orderDetail.member.phoneNumber}`;
          }}
        >
          고객님과 통화
        </V3_Button>

        {/* 신차, 리스렌트일 때 계약서 업로드 노출 */}
        {type === 'purchase' && (
          <V3_Button
            className='!h-[50px] !text-[#494AF1] !bg-[#F0F0FD]'
            theme='gray'
            onClick={() => {
              setIsFinishSubpageOpen(true);
            }}
          >
            계약서 업로드
          </V3_Button>
        )}

        {/* 내차팔기일 때 계약서 업로드 노출 */}
        {type === 'selling' && (
          <>
            {/* 미팅 날짜 설정 여부로 출력 버튼 토글 */}
            {orderDetail.meeting_date ? (
              <V3_Button
                className='!h-[50px] !text-[#494AF1] !bg-[#F0F0FD]'
                theme='gray'
                onClick={() => {
                  setIsFinishSubpageOpen(true);
                }}
              >
                이전 등록증 업로드
              </V3_Button>
            ) : (
              <>
                <V3_Button
                  className='!h-[50px] !text-[#494AF1] !bg-[#F0F0FD]'
                  theme='gray'
                  onClick={() => {
                    setIsMeetingDatePickerOpen(true);
                  }}
                >
                  미팅 일정 등록
                </V3_Button>
                <DealerOrderDatePicker
                  open={isMeetingDatePickerOpen}
                  setOpen={setIsMeetingDatePickerOpen}
                  orderDetail={orderDetail}
                />
              </>
            )}
          </>
        )}
      </div>

      {/* 미팅 일정 등록 Drawer */}

      {/* 주문완료 서브페이지 */}
      <DealerOrderDetailFinishSubpage
        orderListData={orderDetail}
        isOpen={isFinishSubpageOpen}
        setIsOpen={setIsFinishSubpageOpen}
      />
    </Container>
  );
}

/**
 * @case 내가 입찰자이며, 보류중인 주문
 * @feature "주문 취소", "재진행" 버튼
 * @note 기능은(취소, 재진행) "자기 자신이 보류했을 때"에만 보여집니다.
 */
function HoldedHeader({ orderDetail }: DealerOrderDetailProps) {
  const { loginMember } = useMemberStore();

  const queryClient = useQueryClient();

  const { InvalidateMyOrderList } = useInvalidateDealerSearch();
  const [isReStartConfirmOpen, setIsReStartConfirmOpen] = useState(false);
  const [isCancelSubpageOpen, setIsCancelSubpageOpen] = useState(false);
  // 재진행 기능 (재주문과 동일)
  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      await reStartQuoteApi(orderDetail.id);
    },
    onSuccess: async () => {
      CarsayoToast.success('주문이 재개되었습니다.');
      queryClient.invalidateQueries({
        queryKey: ['orderDetail', orderDetail.id],
      });
      InvalidateMyOrderList();
      await new Promise((resolve) => setTimeout(resolve, 1000));
      setIsReStartConfirmOpen(false);
    },
    onError: async (error: any) => {
      const errorMessage: string =
        error?.data?.message ?? '잠시 후 다시 시도해 주세요.';

      return CarsayoToast.error(errorMessage);
    },
  });

  return (
    <Container>
      <div className='flex justify-between items-center gap-1 mb-4'>
        <div className='h-[28px] flex items-center gap-1 rounded-[6px] bg-[#FFF3EC] text-[#FF7B2E] text-[14px] font-semibold leading-[14px] tracking-[-0.28px] py-[5px] px-[6px]'>
          보류
        </div>
      </div>

      <div className='text-[#222] text-[20px] font-semibold leading-[32px] tracking-[-0.4px] whitespace-pre-line break-keep'>
        {/* 내차팔기일 경우 차량번호 노출 */}
        {orderDetail.selling?.usedCarInfo.carName && (
          <div className='text-[#494AF1]'>
            [{orderDetail.selling.usedCarInfo.carName}]
          </div>
        )}
        {typeText(orderDetail).customer}님과의 {typeText(orderDetail).order}{' '}
        여정이
        <br />
        보류되었습니다.
      </div>

      <div className='text-[#555] text-[14px] font-medium leading-[14px] tracking-[-0.28px] mt-2.5'>
        주문번호 {orderDetail.code}
      </div>

      {/* 주문자 정보 영역 */}
      {orderDetail.member && <MemberProfile member={orderDetail.member} />}

      {/* 기능 영역
      @note 본인이 해당 주문을 보류했을 때만 보여집니다.
       */}
      {orderDetail.lastUpdateMemberId === loginMember?.id && (
        <>
          <div className='pt-6 flex items-center gap-[7px]'>
            <V3_Button
              className='!h-[50px]'
              theme='gray'
              onClick={() => {
                setIsCancelSubpageOpen(true);
              }}
            >
              주문 취소
            </V3_Button>
            <V3_Button
              className='!h-[50px] !text-[#494AF1] !bg-[#F0F0FD]'
              theme='gray'
              onClick={() => {
                setIsReStartConfirmOpen(true);
              }}
            >
              재진행
            </V3_Button>
          </div>

          {/* 재진행(재개) */}
          <V3_Confirm
            isOpen={isReStartConfirmOpen}
            setIsOpen={setIsReStartConfirmOpen}
            onConfirm={() => {
              mutate();
            }}
            onClose={() => {
              setIsReStartConfirmOpen(false);
            }}
            title=''
            agreeText={
              isPending ? <CircularProgress size={20} /> : <div>재진행</div>
            }
          >
            <div className='flex flex-col items-center justify-center gap-5'>
              <div className='flex flex-col items-center justify-center gap-1.5'>
                <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
                  주문 재개
                </div>
                <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
                  주문을 재개하시겠습니까?
                </div>
              </div>
            </div>
          </V3_Confirm>
          {/* 주문취소 서브페이지 */}
          <DealerOrderDetailCancelSubpage
            orderListData={orderDetail}
            isOpen={isCancelSubpageOpen}
            setIsOpen={setIsCancelSubpageOpen}
          />
        </>
      )}
    </Container>
  );
}

/**
 * @case 내가 입찰자이며, 취소된 주문
 */
function CanceledHeader({ orderDetail }: DealerOrderDetailProps) {
  return (
    <Container>
      <div className='flex justify-between items-center gap-1 mb-4'>
        <div className='h-[28px] flex items-center gap-1 rounded-[6px] bg-[#FFECEC] text-[#FF4747] text-[14px] font-semibold leading-[14px] tracking-[-0.28px] py-[5px] px-[6px]'>
          취소
        </div>
      </div>

      <div className='text-[#222] text-[20px] font-semibold leading-[32px] tracking-[-0.4px] whitespace-pre-line break-keep'>
        {/* 내차팔기일 경우 차량번호 노출 */}
        {orderDetail.selling?.usedCarInfo.carName && (
          <div className='text-[#494AF1]'>
            [{orderDetail.selling.usedCarInfo.carName}]
          </div>
        )}
        {typeText(orderDetail).customer}님과의 {typeText(orderDetail).order}{' '}
        여정이
        <br />
        취소되었습니다.
      </div>

      <div className='text-[#555] text-[14px] font-medium leading-[14px] tracking-[-0.28px] mt-2.5'>
        주문번호 {orderDetail.code}
      </div>

      {/* 주문자 정보 영역 */}
      {orderDetail.member && <MemberProfile member={orderDetail.member} />}
    </Container>
  );
}

/**
 * @case 내가 입찰자이며, 정상 종료된 주문
 * @feature "받은 리뷰 보기", "수수료 정산" 버튼
 * @note "받은 리뷰 보기" 기능은 리뷰가 있을 때에만 나타납니다.
 * @note "수수료 정산" 버튼은 수수료 정산 완료 시 "수수료 정산 내역" 버튼으로 변경됩니다.
 */
function FinishedHeader({ orderDetail }: DealerOrderDetailProps) {
  const navigate = useNavigate();

  const [isReviewSubpageOpen, setIsReviewSubpageOpen] = useState(false);

  return (
    <Container>
      <div className='flex justify-between items-center gap-1 mb-4'>
        {/* 주문 상태 라벨 */}
        <div className='h-[28px] flex items-center gap-1 rounded-[6px] bg-[#DEEBFF] text-[#005CE6] text-[14px] font-semibold leading-[14px] tracking-[-0.28px] py-[5px] px-[6px]'>
          계약 완료
        </div>
      </div>

      <div className='text-[#222] text-[20px] font-semibold leading-[32px] tracking-[-0.4px] whitespace-pre-line break-keep'>
        {/* 내차팔기일 경우 차량번호 노출 */}
        {orderDetail.selling?.usedCarInfo.carName && (
          <div className='text-[#494AF1]'>
            [{orderDetail.selling.usedCarInfo.carName}]
          </div>
        )}
        {typeText(orderDetail).customer}님과의 {typeText(orderDetail).order}{' '}
        여정이
        <br />
        종료되었습니다.
      </div>

      <div className='text-[#555] text-[14px] font-medium leading-[14px] tracking-[-0.28px] mt-2.5'>
        주문번호 {orderDetail.code}
      </div>

      {/* 주문자 정보 영역 */}
      {orderDetail.member && <MemberProfile member={orderDetail.member} />}

      {/* 기능 영역 */}
      <div className='pt-6 flex items-center gap-[7px]'>
        {/* 받은 리뷰 보기는 리뷰 존재 여부에따라 숨김/나타남 */}
        {orderDetail.review && (
          <V3_Button
            className='!h-[50px]'
            theme='gray'
            onClick={() => {
              setIsReviewSubpageOpen(true);
            }}
          >
            받은 리뷰 보기
          </V3_Button>
        )}
        {/* 수수료 정산 버튼 */}
        {orderDetail.commission && (
          <>
            {orderDetail.commission.state === 'waiting' && (
              <V3_Button className='!h-[50px]' disabled={true}>
                수수료 산정중
              </V3_Button>
            )}
            {orderDetail.commission.state === 'sended' && (
              <V3_Button
                className='!h-[50px] !text-[#494AF1] !bg-[#F0F0FD]'
                theme='gray'
                onClick={() => {
                  navigate('/mypage/commission');
                }}
              >
                수수료 정산
              </V3_Button>
            )}
            {(orderDetail.commission.state === 'paid' ||
              orderDetail.commission.state === 'canceled') && (
              <V3_Button
                className='!h-[50px]'
                theme='gray'
                onClick={() => {
                  navigate('/mypage/commission');
                }}
              >
                수수료 정산 내역
              </V3_Button>
            )}
          </>
        )}
      </div>

      <DealerOrderDetailReviewSubpage
        orderListData={orderDetail}
        isOpen={isReviewSubpageOpen}
        setIsOpen={setIsReviewSubpageOpen}
      />
    </Container>
  );
}

export default function DealerOrderDetailHeader({
  orderDetail,
}: DealerOrderDetailProps) {
  if (orderDetail.state === 'submitted') {
    if (!orderDetail.bid) {
      return <NoBidHeader orderDetail={orderDetail} />;
    } else {
      return <BiddingHeader orderDetail={orderDetail} />;
    }
  }

  if (orderDetail.state === 'progressing') {
    return <ProgressingHeader orderDetail={orderDetail} />;
  }

  if (orderDetail.state === 'holded') {
    return <HoldedHeader orderDetail={orderDetail} />;
  }

  if (orderDetail.state === 'canceled') {
    return <CanceledHeader orderDetail={orderDetail} />;
  }

  if (orderDetail.state === 'finished') {
    return <FinishedHeader orderDetail={orderDetail} />;
  }

  if (orderDetail.state === 'failBid') {
    return <FallBidHeader orderDetail={orderDetail} />;
  }

  return <div>OrderDetailHeader</div>;
}
