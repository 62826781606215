import { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';

export const OrderSellingTab = {
  selling: {
    next: 'rank',
    prev: null,
    nextText: null,
    progress: 10,
    title: '',
  },
  info: {
    next: 'rank',
    prev: null,
    nextText: null,
    progress: 10,
    title: '',
  },
  rank: {
    next: 'drivenDistance',
    prev: 'info',
    nextText: null,
    progress: 20,
    title: '차량 등급을 선택해 주세요.',
  },
  drivenDistance: {
    next: 'option',
    prev: 'selling',
    nextText: null,
    progress: 30,
    title: '주행거리를 입력해 주세요.',
  },
  option: {
    next: 'status',
    prev: 'drivenDistance',
    nextText: null,
    progress: 40,
    title: '차량에 장착된 추가 옵션을<br/>선택해 주세요.',
  },
  status: {
    next: 'accident',
    prev: 'option',
    nextText: null,
    progress: 50,
    title: '차량 상태를 알려주세요.',
  },
  accident: {
    next: 'sellRegion',
    prev: 'status',
    nextText: null,
    progress: 60,
    title: '사고 내역을 알려주세요.',
  },
  sellRegion: {
    next: 'purchaseMethod',
    prev: 'accident',
    nextText: null,
    progress: 70,
    title: '판매 지역을 알려주세요.',
  },
  purchaseMethod: {
    next: 'photo',
    prev: 'sellRegion',
    nextText: null,
    progress: 80,
    title: '구매 방식을 알려주세요.',
  },
  // quote: {
  //   next: 'photo',
  //   prev: 'purchaseMethod',
  //   nextText: null,
  //   progress: 90,
  //   title: '차량 시세를 확인해 주세요.',
  // },
  photo: {
    next: null,
    prev: 'purchaseMethod',
    nextText: '견적 요청',
    progress: 100,
    title: '마지막으로 사진을 등록해 주세요.',
  },
} as const;

export type OrderSellingTab = keyof typeof OrderSellingTab;

const useTabHandler = (initialTab: OrderSellingTab) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const [currentTab, setCurrentTab] = useState<OrderSellingTab>(initialTab);

  const updateTabInURL = (tab: OrderSellingTab) => {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set('tab', tab);
    navigate(`${location.pathname}?${newSearchParams.toString()}`, {
      preventScrollReset: true,
    });
  };

  const goToNextTab = () => {
    const nextTab = OrderSellingTab[currentTab].next;
    if (nextTab) updateTabInURL(nextTab);
  };

  const goToPrevTab = () => {
    const prevTab = OrderSellingTab[currentTab].prev;
    if (prevTab) {
      if (prevTab === 'selling') {
        navigate(location.pathname, { replace: true });
      } else {
        updateTabInURL(prevTab);
      }
    } else {
      navigate(-1);
    }
  };

  useEffect(() => {
    const data = searchParams.get('tab');

    if (data === currentTab) return;

    if (data && data !== currentTab) {
      setCurrentTab(data as OrderSellingTab);
    } else {
      setCurrentTab(initialTab);
    }
  }, [searchParams]);

  return { currentTab, goToNextTab, goToPrevTab };
};

export default useTabHandler;
