/* eslint-disable @typescript-eslint/no-unused-vars */
import { ChevronLeft, ChevronRight } from 'lucide-react';
import { OrderSellingForm } from '../../../form';
import { useEffect, useState } from 'react';
import { SidoData, SidoId, SigunguData, SigunguId } from '@carsayo/types';
import { cn } from 'utils';

const SelectRegion = ({
  form,
  setShowRegion,
}: {
  form: OrderSellingForm;
  setShowRegion: (isShowRegion: boolean) => void;
}) => {
  const [selected, setSelected] = useState<SidoId | null>(null);
  const [region, setRegion] = useState<{
    id: SigunguId | null;
    name: string | null;
  }>({ id: null, name: null });

  const handleSelected = (
    item: { sigunguId: SigunguId; name: string } | SidoId,
  ) => {
    if (typeof item === 'number') {
      setSelected(item);
      if (item === 8) {
        // 세종시
        setRegion({ id: 76, name: '세종시' });
      }
    } else {
      setRegion({ id: item.sigunguId, name: item.name });
    }
  };

  useEffect(() => {
    if (!region.id) return;
    form.setValue('sellRegionId', region.id);
    setShowRegion(false);
  }, [region]);

  return (
    <div className='absolute min-h-[100dvh] bg-white w-full'>
      <header>
        <div className='w-full h-[60px] flex items-center justify-between bg-white/50 sticky top-0 px-4'>
          <button onClick={() => setShowRegion(false)}>
            <ChevronLeft className='stroke-[1.4px]' size={32} />
          </button>
          <div className='text-[#222] text-center font-Pretendard text-[20px] font-semibold leading-[20px] tracking-[-0.4px]'>
            지역 선택
          </div>
          <div className='w-[32px]'></div>
        </div>
      </header>

      <div className='flex-1 flex flex-col overflow-y-auto gap-6 p-5'>
        <div>
          {selected && (
            <div className='flex h-[54px] items-center bg-[#F3F6F9] rounded-[10px] py-[13px] px-4'>
              <div className='font-[600] text-[16px] leading-[150%]'>
                {
                  SidoData.find((el) => {
                    return el.id === selected;
                  })?.name
                }
              </div>
              <div
                className='ml-auto flex items-center'
                onClick={() => setSelected(null)}
              >
                초기화
                <ChevronRight className='h-4 w-4' strokeWidth='1.5' />
              </div>
            </div>
          )}
        </div>

        <div className='grid grid-cols-3 gap-[7px] overflow-y-auto'>
          {selected && selected !== 8
            ? SigunguData.filter((el) => {
                return el.sidoId === selected;
              }).map((item, index) => (
                <div
                  key={index}
                  className={cn(
                    'relative flex items-center justify-center rounded-lg border py-3 text-sm font-semibold has-[:checked]:border-primary',
                    region.id === item.id &&
                      'border-[#484AF5] text-[#484AF5] bg-[#F6F6FC]',
                  )}
                  onClick={() =>
                    handleSelected({ sigunguId: item.id, name: item.name })
                  }
                >
                  <input
                    type='radio'
                    name='region'
                    value={index}
                    className='w-0 appearance-none'
                  />
                  {item.name}
                </div>
              ))
            : SidoData.map((item, index) => (
                <div
                  key={item.name}
                  className={cn(
                    'relative flex items-center justify-center rounded-lg border py-3 text-sm font-semibold has-[:checked]:border-primary',
                    selected === item.id &&
                      'border-[#484AF5] text-[#484AF5] bg-[#F6F6FC]',
                  )}
                  onClick={() => handleSelected(item.id)}
                >
                  <input
                    type='radio'
                    name='region'
                    value={item.name}
                    className='w-0 appearance-none'
                  />
                  {item.name}
                </div>
              ))}
        </div>
      </div>
    </div>
  );
};

export default SelectRegion;
