/* eslint-disable @typescript-eslint/no-unused-vars */
import { BuyerType, DealerOrderListData, SigunguId } from '@carsayo/types';
import { Heart } from 'lucide-react';
import PingCircle from '../ui/pingCircle';
import { Fragment, useMemo } from 'react';
import dayjs from 'dayjs';
import parseSigungu from 'pages/v3/order/purchase/utils/parseSigungu';
import { useNavigate } from 'react-router-dom';
import Person from '../image/Person';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { setDealerBookMark } from 'apis/quote';
import { BiddingOrderListTab } from './biddingOrderList';
import OrderCardFeature from './orderCardFeature';
import RemainTime from '../ui/remainTime';

export type OrderCardProps = {
  order: DealerOrderListData;

  /** 메인페이지에서는 기능이 비활성화(숨김처리)됩니다
   * @note 기능: 받은 리뷰 보기, 재주문, 주문 취소 등 동작
   */
  disableAction?: boolean;

  /** 새로고침 함수 */
  refresh: () => void;
};

/** 주문 상태에 따라 주문 카드 상단이 변화합니다. */
function CardHeadItem({ order, disableAction }: OrderCardProps) {
  const queryClient = useQueryClient();

  const { mutate } = useMutation({
    mutationFn: () => {
      return setDealerBookMark({
        orderId: order.id,
        isBookmarked: !order.isBookmarked,
      });
    },
    onSuccess: (data) => {
      Object.keys(BiddingOrderListTab).forEach((key) => {
        queryClient.invalidateQueries({
          queryKey: ['orderList', key],
        });
      });
    },
  });

  // 입찰중인 주문이며, 내가 입찰하지 않음
  if (order.state === 'submitted' && order.isBidded === false) {
    return (
      <>
        <div className='relative text-black font-semibold text-[16px] leading-[16px] tracking-[-0.32px]'>
          참여하기
          {/* 미확인 주문에 대하여 핑 추가 */}
          {!order.isReaded && (
            <PingCircle className='absolute top-0 right-[-6px]' />
          )}
        </div>
        <button
          onClick={(e) => {
            e.stopPropagation();
            mutate();
          }}
        >
          <Heart
            fill={order.isBookmarked ? '#FF6A6A' : '#B2B7BB'}
            stroke={order.isBookmarked ? '#FF6A6A' : '#B2B7BB'}
            strokeWidth={1}
            width={20}
            height={20}
          />
        </button>
      </>
    );
  }

  // 입찰중인 상태이며, 내가 입찰했음
  if (order.state === 'submitted' && order.isBidded === true) {
    return (
      <>
        <div className='relative text-black font-semibold text-[16px] leading-[16px] tracking-[-0.32px]'>
          입찰 중
        </div>
        <div className='text-[#999] text-[13px] font-medium leading-[19.5px] tracking-[-0.26px]'>
          {dayjs(order.created_at).format('YYYY.MM.DD HH:mm')}
        </div>
      </>
    );
  }

  if (order.state === 'progressing')
    return (
      <>
        <div className='text-[#009E59] text-[16px] font-semibold leading-[16px] tracking-[-0.32px]'>
          진행 중
        </div>
        <div className='text-[#999] text-[13px] font-medium leading-[19.5px] tracking-[-0.26px]'>
          <span className='text-[#009E59]'>(낙찰)</span>{' '}
          {dayjs(order.progressed_at).format('YYYY.MM.DD HH:mm')}
        </div>
      </>
    );

  if (order.state === 'finished')
    return (
      <>
        <div className='text-[#005CE6] text-[16px] font-semibold leading-[16px] tracking-[-0.32px]'>
          계약 완료
        </div>
        <div className='text-[#999] text-[13px] font-medium leading-[19.5px] tracking-[-0.26px]'>
          {dayjs(order.finished_at).format('YYYY.MM.DD HH:mm')}
        </div>
      </>
    );

  if (order.state === 'holded')
    return (
      <>
        <div className='text-[#FF7B2E] text-[16px] font-semibold leading-[16px] tracking-[-0.32px]'>
          보류
        </div>
        <div className='text-[#999] text-[13px] font-medium leading-[19.5px] tracking-[-0.26px]'>
          {dayjs(order.holded_at).format('YYYY.MM.DD HH:mm')}
        </div>
      </>
    );

  if (order.state === 'canceled')
    return (
      <>
        <div className='text-[#FF4747] text-[16px] font-semibold leading-[16px] tracking-[-0.32px]'>
          취소
        </div>
        <div className='text-[#999] text-[13px] font-medium leading-[19.5px] tracking-[-0.26px]'>
          {dayjs(order.canceled_at).format('YYYY.MM.DD HH:mm')}
        </div>
      </>
    );

  if (order.state === 'failBid')
    return (
      <>
        <div className='text-[#999999] text-[16px] font-semibold leading-[16px] tracking-[-0.32px]'>
          유찰
        </div>
        <div className='text-[#999] text-[13px] font-medium leading-[19.5px] tracking-[-0.26px]'>
          {dayjs(order.bid_ended_at).format('YYYY.MM.DD HH:mm')}
        </div>
      </>
    );

  return <div>현재 주문 상태에 대해 상단바 설정 필요 (작업중)</div>;
}

function OrderInfo({ order }: OrderCardProps) {
  const infoList: string[] = useMemo(() => {
    if (!order) return [];

    const infoList: string[] = [];

    // 신차 > 구입주체
    if (order.newCar?.buyerType !== undefined) {
      infoList.push(BuyerType[order.newCar.buyerType]);
    }

    // 신차 > 구입시기
    if (order.newCar?.purchaseDate !== undefined) {
      infoList.push(
        order.newCar.purchaseDate === null
          ? '1개월 이내'
          : dayjs(order.newCar.purchaseDate).format('YYYY.MM.DD'),
      );
    }

    // 신차 > 할부 기간으로 일시불/할부 구분
    if (order.newCar?.installmentTerm !== undefined) {
      infoList.push(
        order.newCar.installmentTerm === 0
          ? '일시불'
          : `${order.newCar.installmentTerm}개월`,
      );
    }

    // 신차 > 인수지역
    if (order.newCar?.deliveryRegion !== undefined) {
      infoList.push(
        parseSigungu(Number(order.newCar.deliveryRegion) as SigunguId)?.name,
      );
    }

    // 리스렌트 > 구입주체
    if (order.leaseRent?.buyerType !== undefined) {
      infoList.push(BuyerType[order.leaseRent.buyerType]);
    }

    // 리스렌트 > 구매 방법
    if (order.type === 'lease') {
      infoList.push('리스');
    } else if (order.type === 'rent') {
      infoList.push('장기렌트');
    }

    // 리스렌트 > 약정기간
    if (order.leaseRent?.contractTerm) {
      infoList.push(`${order.leaseRent.contractTerm}개월`);
    }

    // 리스렌트 > 인수지역
    if (order.leaseRent?.deliveryRegion !== undefined) {
      infoList.push(
        parseSigungu(Number(order.leaseRent.deliveryRegion) as SigunguId)?.name,
      );
    }

    // 중고차 > 년형
    if (order.sellCar?.year) {
      infoList.push(order.sellCar.year + '년형');
    }

    // 중고차 > 주행거리
    if (order.sellCar?.drivenDistance) {
      infoList.push(order.sellCar.drivenDistance.toLocaleString() + 'km');
    }

    // 중고차 > 판매지역
    if (order.sellCar?.sellRegion) {
      infoList.push(
        parseSigungu(Number(order.sellCar.sellRegion) as SigunguId)?.name,
      );
    }

    return infoList;
  }, [order]);

  return (
    <div className='whitespace-pre-line text-[#555] font-medium text-[14px] leading-[14px] tracking-[-0.28px]'>
      {infoList.map((el, index) => {
        return (
          <Fragment key={index}>
            <span>{el}</span>
            {index !== infoList.length - 1 && (
              <span className='text-[#8aa2bb] px-0.5'>•</span>
            )}
          </Fragment>
        );
      })}
    </div>
  );
}

/** 주문에 따라 주문 카드 중단이 변화합니다. */
function CardBodyItem({ order, disableAction, refresh }: OrderCardProps) {
  return (
    <div className='space-y-4'>
      {/* 주문 제목 */}
      <div className='text-[#222] font-semibold text-[16px] leading-[16px] tracking-[-0.32px]'>
        {order.brand} {order.title}
      </div>

      {/* 주문 정보 영역
      신차: 개인/법인, 구매시점, 구매방식, 인수지역
      중고차: 년형, 주행거리, 판매지역
       */}
      <OrderInfo order={order} refresh={refresh} />

      {/* 입찰중인 주문에서만 뜨는 영역
      입찰 종료까지 남은 시간 / 현재 입찰 인원 수
      */}
      {order.state === 'submitted' && order.bid_ended_at && (
        <div className='flex items-center gap-1'>
          <RemainTime bid_ended_at={order.bid_ended_at} />
          <div className='h-[28px] flex items-center gap-1 rounded-[6px] bg-[#F3F6F9] py-[5px] px-[6px]'>
            <Person />
            <div className='text-center text-[#222] text-[13px] font-medium leading-[13px] tracking-[-0.26px]'>
              {order.bidNumber}명
            </div>
          </div>
        </div>
      )}

      {/* 차량 이미지 */}
      {/* 신차, 리스렌트일 경우 (우하단 작은 차량 이미지) */}
      {typeof order.imageUrl === 'string' && (
        <div className='flex items-center justify-end w-full mt-[-10px]'>
          <div className='max-w-[110px] h-[66px]'>
            <img
              src={order.imageUrl}
              alt='차량 이미지'
              className='w-full h-full object-cover'
            />
          </div>
        </div>
      )}
      {/* 중고차일 경우 (신청시 첨부한 첫번째 사진 크게 출력) */}
      {typeof order.imageUrl !== 'string' && typeof order.imageUrl?.[0] && (
        <div className='relative flex items-center justify-end w-full mt-[-10px]'>
          <div className='w-full h-[156px]'>
            <img
              src={order.imageUrl?.[0]}
              alt='차량 이미지'
              className='w-full h-full object-cover rounded-[6px]'
            />
          </div>

          {/* 차량 정보 영역 */}
          <div className='absolute bottom-0 left-0 right-0 rounded-b-[6px] bg-[rgba(34,34,34,0.5)] backdrop-blur-[2px] p-[8px_10px]'>
            <div className='flex items-center justify-between'>
              <div className='text-white text-[14px] font-semibold leading-[14px] tracking-[-0.28px]'>
                {order.sellCar?.carName}
              </div>
              <div className='flex gap-2 text-[#FFF] text-[13px] font-medium leading-[13px] tracking-[-0.26px]'>
                {order.sellCar?.tag.ownerShip === 'noChanged' && (
                  <div>#1인소유</div>
                )}
                {order.sellCar?.tag.carState === 'fullNoAccident' && (
                  <div>#완무</div>
                )}
                {order.sellCar?.tag.carState === 'noAccident' && (
                  <div>#무사고</div>
                )}
                {order.sellCar?.tag.carState === 'justExchange' && (
                  <div>#단순교환</div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default function OrderCard({
  order,
  disableAction,
  refresh,
}: OrderCardProps) {
  const navigate = useNavigate();

  return (
    <div
      onClick={() => {
        navigate(`/dealer/order/detail/${order.id}`);
      }}
      className='bg-white rounded-[16px] cursor-pointer'
    >
      {/* Head */}
      <div className='flex items-center justify-between p-[14px_16px] rounded-tl-[16px] rounded-tr-[16px] rounded-b-none border-b border-[#E5EAEE] bg-white'>
        <CardHeadItem
          order={order}
          disableAction={disableAction}
          refresh={refresh}
        />
      </div>

      {/* Body */}
      <div className='relative p-[20px_16px]'>
        <CardBodyItem
          order={order}
          disableAction={disableAction}
          refresh={refresh}
        />
      </div>

      {/* 동작 영역 */}
      <OrderCardFeature
        order={order}
        disableAction={disableAction}
        refresh={refresh}
      />
    </div>
  );
}
