import { ChevronDown, ChevronRight } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';
import { motion } from 'framer-motion';
import { cn } from 'utils';

enum DealerType {
  newcar = '신차 매니저',
  usedcar = '중고차 매니저',
  leaseRent = '리스/장기렌트 매니저',
}
const DealerStatus = {
  submitted: {
    label: '승인 대기',
    image: '/assets/images/png/wait.png',
  },
  rejected: {
    label: '승인 거부',
    image: '/assets/images/png/cancel.png',
  },
  accepted: {
    label: '승인 완료',
    image: '/assets/images/png/check.png',
  },
};

// 자기소개 텍스트 - 더보기/닫기 기능 추가
const ClampedText = ({ text }: { text: string }) => {
  const textRef = useRef<HTMLDivElement>(null);
  const [isExpanded, setIsExpanded] = useState(false);
  const [showToggle, setShowToggle] = useState(false); // 더보기 버튼 보여줄지

  useEffect(() => {
    const el = textRef.current;
    if (!el || !text) return;

    const computedStyle = getComputedStyle(el);
    const lineHeight = parseFloat(computedStyle.lineHeight);

    // 클램프 제거 후 높이 측정
    const originalClass = el.className;
    el.classList.remove('line-clamp-3');

    // 리렌더링 보장 후 측정
    requestAnimationFrame(() => {
      const height = el.clientHeight;
      const lineCount = Math.round(height / lineHeight);

      // 줄 수 3 이상이면 더보기 버튼 보여주기
      if (lineCount > 3) {
        setShowToggle(true);
      } else {
        setShowToggle(false);
      }

      // 다시 클램프 복구
      el.className = originalClass;
    });
  }, [text]);

  return (
    <div className='w-full'>
      <div
        ref={textRef}
        className={cn(
          'whitespace-pre-line text-[#222] text-[14px] font-medium leading-[21px] tracking-[-0.28px]',
          isExpanded ? '' : 'line-clamp-3',
        )}
      >
        {text}
      </div>

      {/* 더보기 / 접기 영역 */}
      {showToggle && (
        <div className='flex justify-end items-center mt-3'>
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className='flex justify-start items-center gap-0.5'
          >
            <motion.div
              initial={{ color: '#666' }}
              animate={{ color: isExpanded ? '#666' : '#494AF1' }}
              transition={{ duration: 0.3 }}
              className='text-[14px] font-medium leading-[14px] tracking-[-0.28px]'
            >
              {isExpanded ? '접기' : '더보기'}
            </motion.div>
            <motion.div
              initial={{ rotate: 0, color: '#666' }}
              animate={{
                rotate: isExpanded ? 180 : 0,
                color: isExpanded ? '#666' : '#494AF1',
              }}
              transition={{ duration: 0.3 }}
            >
              <ChevronDown width={20} height={20} strokeWidth={1.5} />
            </motion.div>
          </button>
        </div>
      )}
    </div>
  );
};

export default function DealerProfile() {
  const { loginMember } = useMemberStore();
  const navigate = useNavigate();

  if (!loginMember) return null;

  return (
    <section className='p-5 pb-0'>
      <div className='px-4 py-5 rounded-[16px] bg-white flex flex-col justify-between items-start gap-6 shadow-[0px_6px_20px_#0000000D]'>
        <div className='w-full'>
          <div className='w-full flex justify-start items-center gap-4'>
            <div className='w-[56px] h-[56px] rounded-full flex justify-center items-center overflow-hidden'>
              {loginMember.profileImage_url && (
                <img
                  src={loginMember.profileImage_url}
                  alt='예시 이미지'
                  className='w-full h-full object-cover'
                />
              )}
            </div>
            <div className='flex flex-col justify-start items-start gap-2'>
              <strong className='text-[16px] leading-[16px] font-[600]'>
                {loginMember.name_nick}
              </strong>
              {loginMember.dealerInfo && (
                <ul className='flex justify-start items-center gap-1'>
                  <li className='text-[14px] leading-[14px] font-[500]'>
                    {DealerType[loginMember.dealerInfo.type]}
                  </li>

                  {/* Dot */}
                  <li className='flex justify-center items-center'>
                    <span className='w-[3px] h-[3px] rounded-full bg-[#D5DADF]'></span>
                  </li>

                  {/* 승인 완료 / 승인 대기 / 승인 거부 */}
                  <li className='flex justify-start items-center gap-0.5'>
                    <img
                      src={
                        DealerStatus[loginMember.dealerInfo.signupState].image
                      }
                      alt='체크이미지'
                      className='w-4 object-contain'
                    />
                    <span className='text-[13px] leading-[13px] font-[500]'>
                      {DealerStatus[loginMember.dealerInfo.signupState].label}
                    </span>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </div>

        {/* 자기소개 영역 */}
        <ClampedText text={loginMember.description ?? ''} />

        {/* 미입금 수수료 영역 */}
        <button
          onClick={() => {
            navigate('/mypage/commission');
          }}
          className='w-full p-[15px] pr-2.5 bg-[#F3F6F9] rounded-[8px] flex justify-between items-center'
        >
          <span className='text-[14px] font-[500] inline-block pl-[1px]'>
            미입금 수수료
          </span>
          <div className='flex justify-center items-center'>
            <span className='text-[14px] font-[500]'>
              {(
                loginMember.dealerInfo?.amount.nonPaidCommission ?? 0
              )?.toLocaleString()}
              원
            </span>
            <ChevronRight
              width={20}
              height={20}
              color='#999999'
              strokeWidth={1.5}
            />
          </div>
        </button>
      </div>
    </section>
  );
}
