/* eslint-disable @typescript-eslint/no-unused-vars */
import { getMember } from 'apis/authentication';
import { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { ChargeInformationFee } from './components/ChargeInformationFee';
import {
  getSelling,
  initSelling,
  submitSelling,
  updateSelling,
} from 'apis/selling';
import {
  SearchedUsedCarInfo,
  SellingOrderDTO,
  SellingOrderUpdateDTO,
} from '@carsayo/types';
import { cn } from 'utils';
import { Button } from 'components/ui/button';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import useTabHandler, { OrderSellingTab } from './hooks/useTabHandler';
import { CircularProgress } from '@mui/material';
import { AnimatePresence } from 'framer-motion';
import OrderSelling_Info from './components/tab/info';
import { InputValue } from './util/types';
import { getCooconCarInfo } from 'apis/car';
import OrderSelling_Rank from './components/tab/rank';
import OrderSelling_Distance from './components/tab/drivenDistance';
import CarInfo from './components/CarInfo';
import OrderSelling_Option from './components/tab/option';
import OrderSelling_Status from './components/tab/status';
import OrderSelling_Accident from './components/tab/accident';
import OrderSelling_SellRegion from './components/tab/region';
import OrderSelling_PurchaseMethod from './components/tab/method';
import OrderSelling_Photo from './components/tab/photo';
import { useQuery } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { OrderSellingFormData, OrderSellingSchema } from './form';
import { zodResolver } from '@hookform/resolvers/zod';
import UsedCarList from './components/page/used';
import SelectRegion from './components/page/region';
import OrderHeader from './components/OrderHeader';
import OrderSelling_process from './components/tab/process';
import CarPrices from './components/page/price';

const OrderSellingPage = () => {
  const { setTopTabbarState, setIsBottomTabbar, setLoadingDim } =
    useSystemStore();
  const [isOpenInformationFeeDrawer, setIsOpenInformationFeeDrawer] =
    useState(false); // 견적 요청권 drawer

  const navigate = useNavigate();
  const [isFetching, setIsFetching] = useState<boolean>(false);

  const [sellingOrderDTOList, setSellingOrderDTOList] =
    useState<SellingOrderDTO[]>();
  const [searchMoreData, setSearchMoreData] =
    useState<SearchedUsedCarInfo | null>(null);

  const { currentTab, goToNextTab, goToPrevTab } = useTabHandler('selling');
  const [isShowList, setShowList] = useState(false); // 보험 처리 내역 노출 여부
  const [isShowRegion, setShowRegion] = useState(false); // 지역선택 노출 여부
  const [isShowPrice, setShowPrice] = useState(false); // 예상 시세 노출 여부
  const [inputValue, setInputValue] = useState<InputValue>({});
  const [sellingOrderData, setSellingOrderData] = useState<SellingOrderDTO>(
    {} as SellingOrderDTO,
  );

  const form = useForm<OrderSellingFormData>({
    resolver: zodResolver(OrderSellingSchema),
    defaultValues: {
      progress: 10,
      currentStep: 1,
      drivenDistance: null,
      sellRegionId: null,
      boughtMethod: null,
      additionalRequest: null,
      additionalInfo: null,
      file_1_id: null,
      file_2_id: null,
      file_3_id: null,
      file_4_id: null,
      file_5_id: null,
      file_6_id: null,
    },
  });

  const {
    data: cooconCarInfo,
    isFetching: isFetchingCooconCarInfo,
    refetch,
  } = useQuery({
    queryKey: [
      'cooconCarInfo',
      inputValue.carName,
      inputValue.ownerName,
      inputValue.selectedSeries,
      searchMoreData?.searchDetailInfo?.ts_key,
    ],
    queryFn: () =>
      getCooconCarInfo({
        carName: inputValue.carName || '',
        ownerName: inputValue.ownerName || '',
        seriesno: inputValue.selectedSeries,
        ts_key: searchMoreData?.searchDetailInfo?.ts_key,
      }),
    enabled: false,
  });

  const getWritingOrderList = async () => {
    setIsFetching(true);
    const writingOrderList = await getSelling();
    setSellingOrderDTOList(writingOrderList);
    setIsFetching(false);
  };

  const handleChangeEvents = {
    ownerName: (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue({
        ...inputValue,
        ownerName: e.target.value,
      });
    },
    carName: (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue({
        ...inputValue,
        carName: e.target.value,
      });
    },
  };

  const checkAvaliableOrder = async () => {
    const member = await getMember();

    if (
      member?.customerInfo &&
      member.customerInfo.count.avaliableOrder.selling <= 0
    ) {
      return false;
    }
    return true;
  };

  const fetchCooconCarInfo = async () => {
    if (!inputValue.carName || !inputValue.ownerName) {
      CarsayoToast.error('차량 정보를 모두 입력해 주세요.');
      return;
    }

    try {
      const { data: searchResult } = await refetch();

      if (searchResult?.result === 'success' && searchResult?.usedCarInfo) {
        const createdOrder = await initSelling({
          usedCarInfoId: searchResult.usedCarInfo.id,
          accidentHistoryId: searchResult.accidentHistory?.id,
        });

        setSellingOrderData(createdOrder);
        navigate(`${location.pathname}?tab=drivenDistance`);
      } else if (searchResult?.result === 'searchMore') {
        setSearchMoreData(searchResult);
        navigate(`${location.pathname}?tab=rank`);
      } else {
        CarsayoToast.error(
          searchResult?.message || '데이터를 가져오는 중 오류가 발생했습니다.',
        );
      }
    } catch (e) {
      CarsayoToast.error(`${e}`);
    } finally {
      setLoadingDim(false);
    }
  };

  const handleNext = async () => {
    if (currentTab === 'selling' && (sellingOrderDTOList?.length ?? 0) > 0) {
      navigate(`${location.pathname}?tab=info`);
      return;
    }

    if (inputValue.carName && inputValue.ownerName) {
      setLoadingDim(true);
      await fetchCooconCarInfo();
    } else {
      CarsayoToast.error('차량 정보를 모두 입력해 주세요.');
    }
  };

  // 차량 등급 선택 후 '다음'
  const handleNextRank = async () => {
    if (!searchMoreData || !inputValue.carName || !inputValue.ownerName) {
      setSearchMoreData(null);
      setInputValue({ ...inputValue, selectedSeries: undefined });
      return;
    }

    if (!inputValue.selectedSeries) {
      CarsayoToast.error('차량 등급을 선택해 주세요.');
      return;
    }

    setLoadingDim(true);
    await fetchCooconCarInfo();
  };

  const updateSellingInfo = async () => {
    if (Object.keys(sellingOrderData).length === 0) {
      return;
    }
    const res = await updateSelling({
      orderData: sellingOrderData.orderData,
      update: form.getValues() as SellingOrderUpdateDTO,
    });
    setSellingOrderData(res);
  };

  const submitSellingInfo = async () => {
    // 요청권 체크
    const isAvailable = await checkAvaliableOrder();
    if (!isAvailable) {
      setIsOpenInformationFeeDrawer(true);
      return;
    }

    setLoadingDim(true);
    const res = await submitSelling({
      orderData: sellingOrderData.orderData,
      update: form.getValues() as SellingOrderUpdateDTO,
    });
    if (res.orderData.isSubmitted) {
      CarsayoToast.error('내차팔기 주문이 성공했습니다');
      navigate(`${location.pathname}/finish?key=init`);
    } else {
      CarsayoToast.error('주문에 실패했습니다\n잠시 후 다시 시도해 주세요.');
      setLoadingDim(false);
    }
    setLoadingDim(false);
  };

  const isDisabled = () => {
    if (!['selling', 'info', 'rank'].includes(currentTab)) {
      if (Object.keys(sellingOrderData).length === 0) {
        navigate(location.pathname, { replace: true });
        return;
      }
    } else {
      if (!searchMoreData && currentTab === 'rank') {
        navigate(location.pathname, { replace: true });
        return;
      }
    }

    switch (currentTab) {
      case 'info':
        return !inputValue.carName || !inputValue.ownerName;

      case 'rank':
        return !inputValue.selectedSeries;

      case 'drivenDistance':
        return (
          !form.watch('drivenDistance') ||
          (form.watch('drivenDistance') ?? 0) <=
            Number(sellingOrderData.orderData.accidentHistory?.LAST_MILEAGE)
        );

      case 'option': {
        const isOption =
          form.watch('additionalInfo.optionSelect.옵션없음') === false;
        const isWrite = form.watch(
          'additionalInfo.optionSelect.직접입력.selected',
        );
        const writeTxt = form.watch(
          'additionalInfo.optionSelect.직접입력.content',
        );
        const optionLng =
          form.watch('additionalInfo.optionSelect.optionList')?.length || 0;

        return (
          isOption && ((!isWrite && optionLng === 0) || (isWrite && !writeTxt))
        );
      }
      case 'status': {
        const content_타이어 = form.watch(
          'additionalInfo.carState.타이어교체.content',
        );
        const content_외판 = form.watch(
          'additionalInfo.carState.외관스크래치.content',
        );
        const content_정비 = form.watch(
          'additionalInfo.carState.정비수리필요.content',
        );
        const content_정비내용 = form.watch(
          'additionalInfo.carState.정비수리필요.additional',
        );

        return (
          !content_타이어 ||
          !content_외판 ||
          !content_정비 ||
          (content_정비 === '정비/수리 필요' &&
            (content_정비내용 === undefined ||
              content_정비내용 === null ||
              content_정비내용 === ''))
        );
      }
      case 'accident': {
        const accident_모름 = form.watch(
          'additionalInfo.accident.모름.selected',
        );
        const accident_사고없음 = form.watch(
          'additionalInfo.accident.사고없음.selected',
        );
        const accident_판금교환 = form.watch(
          'additionalInfo.accident.판금교환.selected',
        );
        const content_판금교환 = form.watch(
          'additionalInfo.accident.판금교환.content',
        );

        const accidentData = form.watch('additionalInfo.accident');

        if (!accidentData || Object.keys(accidentData).length === 0) {
          return true;
        }

        if (!accident_모름 && !accident_사고없음 && !accident_판금교환) {
          return true;
        }

        if (
          accident_판금교환 &&
          (!content_판금교환 || content_판금교환 === '')
        ) {
          return true;
        }
        return false;
      }
      case 'sellRegion': {
        return !form.watch('sellRegionId');
      }
      case 'purchaseMethod': {
        return !form.watch('boughtMethod');
      }
      case 'photo': {
        const files = ['file_1_id', 'file_2_id', 'file_3_id'].map((file) =>
          form.watch(file as keyof OrderSellingFormData),
        );
        return files.some((file) => !file);
      }

      default:
        return !sellingOrderDTOList || sellingOrderDTOList.length === 0
          ? !inputValue.carName || !inputValue.ownerName
          : false;
    }
  };

  useEffect(() => {
    const subscription = form.watch((values) => {
      updateSellingInfo();
    });

    return () => {
      subscription.unsubscribe();
    };
  }, [form, sellingOrderData]);

  useEffect(() => {
    if (sellingOrderDTOList === undefined) return;
    if (
      currentTab === 'info' ||
      (currentTab === 'selling' &&
        sellingOrderDTOList &&
        sellingOrderDTOList.length === 0)
    ) {
      (async () => {
        const isAvailable = await checkAvaliableOrder();
        if (!isAvailable) {
          setIsOpenInformationFeeDrawer(true);
          return;
        }
      })();
    }
    form.setValue('progress', OrderSellingTab[currentTab].progress);
  }, [currentTab]);

  /** Android: 이 페이지 안에서 백버튼 클릭 시 window.native.onBackPressed가 실행됩니다. */
  useEffect(() => {
    getWritingOrderList();

    if (!window.native) return;
    window.native.onBackPressed = () => {
      goToPrevTab();
    };
    return () => {
      if (!window.native) return;
      window.native.onBackPressed = () => {};
    };
  }, []);

  /** 상단, 하단 숨김 */
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'hidden',
      mode: 'subpage',
      title: ' ',
      rightElement: undefined,
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
  }, []);
  return (
    <div className='h-full w-full flex flex-col relative justify-between'>
      <OrderHeader currentTab={currentTab} />
      <div
        className={cn(
          'relative flex flex-col flex-auto justify-start overflow-y-auto pb-[30px]',
          currentTab === 'selling' &&
            sellingOrderDTOList &&
            sellingOrderDTOList.length > 0
            ? 'bg-[#F3F6F9] pt-[50px]'
            : ['info', 'rank'].includes(currentTab)
              ? 'pt-[48px]'
              : 'pt-[18px]',
        )}
      >
        {!isFetching ? (
          <AnimatePresence>
            {currentTab === 'selling' && (
              <>
                {sellingOrderDTOList && sellingOrderDTOList.length > 0 ? (
                  <OrderSelling_process
                    sellingOrderDTOList={sellingOrderDTOList}
                    setSellingOrderData={setSellingOrderData}
                    form={form}
                  />
                ) : (
                  <OrderSelling_Info
                    inputValue={inputValue}
                    handleChangeEvents={handleChangeEvents}
                  />
                )}
              </>
            )}

            {currentTab === 'info' && (
              <OrderSelling_Info
                inputValue={inputValue}
                handleChangeEvents={handleChangeEvents}
              />
            )}

            {!['selling', 'info', 'rank'].includes(currentTab) && (
              <CarInfo
                key={`info_${currentTab}`}
                currentTab={currentTab}
                setShowList={setShowList}
                setShowPrice={setShowPrice}
                sellingOrderData={sellingOrderData}
              />
            )}

            {!['selling', 'info'].includes(currentTab) && (
              <div
                className={cn(
                  'flex flex-col gap-5 px-5',
                  currentTab !== 'rank' &&
                    'border-t-[8px] border-[#F3F6F9] pt-[30px]',
                )}
              >
                {OrderSellingTab[currentTab].title && (
                  <div
                    className='font-[600] text-[20px] leading-[160%]'
                    dangerouslySetInnerHTML={{
                      __html: OrderSellingTab[currentTab].title,
                    }}
                  ></div>
                )}

                {currentTab === 'rank' && (
                  <OrderSelling_Rank
                    searchMoreData={searchMoreData}
                    inputValue={inputValue}
                    setInputValue={setInputValue}
                  />
                )}
                {currentTab === 'drivenDistance' && (
                  <OrderSelling_Distance
                    sellingOrderData={sellingOrderData}
                    form={form}
                  />
                )}
                {currentTab === 'option' && (
                  <OrderSelling_Option
                    sellingOrderData={sellingOrderData}
                    form={form}
                  />
                )}
                {currentTab === 'status' && (
                  <OrderSelling_Status
                    sellingOrderData={sellingOrderData}
                    form={form}
                    setShowList={setShowList}
                  />
                )}
                {currentTab === 'accident' && (
                  <OrderSelling_Accident
                    form={form}
                    setShowList={setShowList}
                  />
                )}
                {currentTab === 'sellRegion' && (
                  <OrderSelling_SellRegion
                    form={form}
                    setShowRegion={setShowRegion}
                  />
                )}
                {currentTab === 'purchaseMethod' && (
                  <OrderSelling_PurchaseMethod form={form} />
                )}
                {currentTab === 'photo' && (
                  <OrderSelling_Photo
                    form={form}
                    sellingOrderData={sellingOrderData}
                  />
                )}
              </div>
            )}
          </AnimatePresence>
        ) : (
          <div className='flex w-full h-full items-center justify-center'>
            <CircularProgress />
          </div>
        )}
      </div>
      <ChargeInformationFee
        isOpen={isOpenInformationFeeDrawer}
        setIsOpen={setIsOpenInformationFeeDrawer}
        orderType={'selling'}
      />
      <div
        className={cn(
          'w-full bg-white/50 px-5 pb-2.5 pt-5 flex items-center justify-center gap-[7px]',
        )}
      >
        {!['selling', 'info', 'rank'].includes(currentTab) && (
          <Button
            variant='outline'
            className='bg-white'
            onClick={() => goToPrevTab()}
          >
            이전
          </Button>
        )}
        <Button
          onClick={() => {
            if (['selling', 'info'].includes(currentTab)) {
              handleNext();
            } else if (currentTab === 'rank') {
              handleNextRank();
            } else if (currentTab === 'photo') {
              submitSellingInfo();
            } else {
              goToNextTab();
            }
          }}
          disabled={isDisabled()}
        >
          {currentTab === 'selling' &&
          sellingOrderDTOList &&
          sellingOrderDTOList.length > 0
            ? '내 차 새로 등록하기'
            : OrderSellingTab[currentTab].nextText || '다음'}
        </Button>
      </div>

      {sellingOrderData?.orderData?.accidentHistory && (
        <UsedCarList
          isShowList={isShowList}
          setShowList={setShowList}
          accidentHistory={sellingOrderData.orderData.accidentHistory}
        />
      )}

      {sellingOrderData.orderData && (
        <CarPrices
          isOpen={isShowPrice}
          setIsOpen={setShowPrice}
          sellingOrderData={sellingOrderData}
        />
      )}

      {isShowRegion && (
        // TODO 시군구 id 상단으로 가져와서 선택한거 표시
        <SelectRegion setShowRegion={setShowRegion} form={form} />
      )}
    </div>
  );
};

export default OrderSellingPage;
