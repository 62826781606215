import CarsayoLogo from 'components/common/v2/logo';
import { Link } from 'react-router-dom';

export const LinkUrl = {
  한국소비자원: 'https://www.kca.go.kr/',
  공정거래위원회: 'https://www.ftc.go.kr/',
  가족친화지원사업: 'https://www.ffsb.kr/',
  소비자중심경영소개: 'https://www.kca.go.kr/ccm/certSystemOutlineView.do',
  지식재산경영인증: 'https://www.ripc.org/ipcert/introduce',
  벤처확인기업: 'https://www.smes.go.kr/venturein/institution/ventureGuide',

  앱: {
    플레이스토어:
      'https://play.google.com/store/apps/details?id=com.ksapp.carsayo',
    앱스토어:
      'https://apps.apple.com/kr/app/카사요-리스-장기렌트-신차구매-중고차팔기-비교견적/id1546852990',
  },
};

export default function MainFooter() {
  return (
    <footer className='w-full bg-[#F8F9FA] border-t border-[#EEEEEE] flex-none'>
      <div className='h-[70px] flex justify-between items-center px-5 border-b border-[#EEEEEE]'>
        <Link to='/' className='shrink-0 flex justify-center items-center'>
          <CarsayoLogo width='120' />
        </Link>
      </div>
      <div className='px-5 pt-6 pb-[31px] space-y-6'>
        {/* 텍스트 영역 */}
        <div className='space-y-[14px] text-[#555] font-normal text-[13px] leading-[13px] tracking-[-0.325px]'>
          <div>대표이사 : 최용민</div>
          <div>주소 : (04790) 서울특별시 성동구 아차산로 49, 1403호</div>
          <div>벤처기업 20240514030044 (혁신성장형)</div>
          <div>사업자등록번호 : 142-87-01847</div>
          <div>통신판매업신고 : 제 2021-서울성동-00533</div>
          <div>제휴 · 문의 메일 : info@carsayo.net</div>
        </div>

        {/* 전화번호 및 앱 배너 */}
        {/* <div className='flex justify-between gap-4'>
          <div className='min-w-[180px] bg-[#F2F4F6] rounded-[6px] p-3.5 px-4 space-y-3'>
            <strong className='mb-0.5 text-[#222] font-semibold text-[15px] leading-[15px] tracking-[-0.375px]'>
              1668-1571
            </strong>
            <p className='flex justify-between items-center text-[#222] font-normal text-[13px] leading-[13px] tracking-[-0.325px]'>
              <span>평일</span>
              <span className='font-[500]'>10:00~18:00</span>
            </p>
            <p className='flex justify-between items-center text-[#222] font-normal text-[13px] leading-[13px] tracking-[-0.325px]'>
              <span>점심시간</span>
              <span className='font-[500]'>13:00~14:00</span>
            </p>
            <p className='flex justify-between items-center text-[#222] font-normal text-[13px] leading-[13px] tracking-[-0.325px]'>
              <span>휴무</span>
              <span className='font-[500]'>주말 및 공휴일</span>
            </p>
          </div>

          <div className='w-full grid grid-rows-2 gap-4'>
            <Link
              to={LinkUrl.앱.앱스토어}
              target='_blank'
              className='flex justify-center items-center gap-1 rounded-[6px] border border-[var(--Line-Regular_Color,#E5E5EC)] bg-[#FFF] px-4'
            >
              <AppleIcon fill='#111111' />
              <div className='text-[14px] font-normal leading-[14px] tracking-[-0.35px] tiny:hidden'>
                App Store
              </div>
            </Link>

            <Link
              to={LinkUrl.앱.플레이스토어}
              target='_blank'
              className='flex justify-center items-center gap-1 rounded-[6px] border border-[var(--Line-Regular_Color,#E5E5EC)] bg-[#FFF] px-4'
            >
              <GoogleIcon />
              <div className='text-[14px] font-normal leading-[14px] tracking-[-0.35px] tiny:hidden'>
                Play Store
              </div>
            </Link>
          </div>
        </div> */}
        <div className='flex justify-between items-stretch gap-[20px] bg-[#F2F4F6] rounded-[6px] py-[14px] pl-4 pr-[20px]'>
          <div className='flex justify-center items-center bg-white w-[40%] min-w-[130px] min-h-[83px] rounded-[4px] text-[15px] leading-[15px] font-[600]'>
            1668-1571
          </div>
          <div className='w-[60%] flex flex-col justify-start items-start gap-[12px] my-auto'>
            <p className='flex flex-wrap gap-y-[4px] justify-between items-center gap-x-[33px] text-[#222] font-normal text-[13px] leading-[13px] tracking-[-0.325px]'>
              <span className='min-w-[44px] inline-block'>평일</span>
              <span className='font-[500]'>10:00~18:00</span>
            </p>
            <p className='flex flex-wrap gap-y-[4px] justify-between items-center gap-x-[33px] text-[#222] font-normal text-[13px] leading-[13px] tracking-[-0.325px]'>
              <span className='min-w-[44px] inline-block'>점심시간</span>
              <span className='font-[500]'>13:00~14:00</span>
            </p>
            <p className='flex flex-wrap gap-y-[4px] justify-between items-center gap-x-[33px] text-[#222] font-normal text-[13px] leading-[13px] tracking-[-0.325px]'>
              <span className='min-w-[44px] inline-block'>휴무</span>
              <span className='font-[500]'>주말 및 공휴일</span>
            </p>
          </div>
        </div>
      </div>

      <div className='h-[60px] flex items-center px-5 border-t border-[#EEEEEE] text-[#555] text-[13px] font-normal leading-[18px] tracking-[-0.325px]'>
        Copyright ⓒ 카사요(주) ALL RIGHTS RESERVED.
      </div>
      <div className='h-[70px] w-full flex justify-end items-center border-t border-[#EEEEEE] px-[14px]'>
        <div className='flex justify-center items-center gap-[6px] py-[14px]'>
          <Link
            to={LinkUrl.벤처확인기업}
            target='_blank'
            rel='noopener noreferrer'
            className='w-[70px] h-[20px] flex justify-center items-center mr-[3px]'
          >
            <img
              src='/assets/images/png/venture_mark.png'
              alt='벤처확인기업마크'
              className='w-full object-contain'
            />
          </Link>
          <Link
            to={LinkUrl.지식재산경영인증}
            target='_blank'
            rel='noopener noreferrer'
            className='w-[102px] h-[20px] flex justify-center items-center mr-[3px]'
          >
            <img
              src='/assets/images/png/cert_mark.png'
              alt='지식재산경영인증마크'
              className='w-full object-contain'
            />
          </Link>
          <Link
            to={LinkUrl.소비자중심경영소개}
            target='_blank'
            rel='noopener noreferrer'
            className='w-[42px] h-[42px] flex justify-center items-center'
          >
            <img
              src='/assets/images/png/ccm_mark.png'
              alt='소비자중심경영인증마크'
              className='w-full object-contain'
            />
          </Link>
          <Link
            to={LinkUrl.가족친화지원사업}
            target='_blank'
            className='w-[42px] h-[42px] flex justify-center items-center'
          >
            <img
              src='/assets/images/png/family_friendly_mark.png'
              alt='가족친화인증마크'
              className='w-full object-contain'
            />
          </Link>
        </div>
      </div>
    </footer>
  );
}
