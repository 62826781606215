import { api } from 'apis';
import SentryErrorReturns from 'utils/sentryErrorReturns';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import {
  PurchaseOrderDTO,
  PurchaseInitDTO,
  GetCarOptionDescriptionDTO,
  CarOptionDescription,
  PurchaseOrderDTOV1,
} from '@carsayo/types';

/** 옵션 설명 가져오기 */
export const getCarOptionDescription = async (
  params: GetCarOptionDescriptionDTO,
): Promise<CarOptionDescription> => {
  try {
    const response = await api.get('car/data/optionDescription', {
      params: params,
    });
    const data: CarOptionDescription = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

/** 임시로 진행중인 주문 삭제하는 기능
 * @notice 나중에 제거하세요
 */
export const removePurchasingOrder = async () => {
  try {
    await api.get('order/purchase/remove');
    return;
  } catch (error: any) {
    throw new Error(error);
  }
};

/** 구매 진행중인 주문 정보 가져오기
 * @notice null: 구매 진행중인 정보가 없음
 */
export const getPurchase = async (): Promise<PurchaseOrderDTO[]> => {
  try {
    const response = await api.get('order/purchase');
    const data: PurchaseOrderDTO[] = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

/** 구매 새로 시작
 * @notice 기존의 작성중인 구매 주문을 제거합니다
 */
export const initPurchase = async (
  reqData: PurchaseInitDTO,
): Promise<PurchaseOrderDTO> => {
  try {
    const response = await api.get('order/purchase/init', {
      params: reqData,
    });
    const data: PurchaseOrderDTO = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

/** 구매 진행 업데이트
 */
export const updatePurchase = async (
  reqData: PurchaseOrderDTO,
): Promise<PurchaseOrderDTO> => {
  try {
    const response = await api.post('order/purchase/update', reqData);
    const data: PurchaseOrderDTO = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

/** 구매 진행 최종 제출 */
export const submitPurchase = async (
  reqData: PurchaseOrderDTO,
): Promise<{ orderId: string } & PurchaseOrderDTO> => {
  try {
    const response = await api.post('order/purchase/submit', reqData);
    const data: { orderId: string } & PurchaseOrderDTO = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    CarsayoToast.error(error.response.data.message);
    throw new Error(error);
  }
};

/** 구매 진행 최종 제출 */
export const submitPurchaseV1 = async (
  reqData: PurchaseOrderDTOV1,
): Promise<void> => {
  try {
    await api.post('v1/order/purchase/submit', reqData);
    return;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    CarsayoToast.error(error.response.data.message);
    throw new Error(error);
  }
};
