/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import Button from 'components_v3/ui/Button';
import { CustomCheckbox } from 'components_v3/ui/Checkbox';
import { ChevronRight, X } from 'lucide-react';
import { useLayoutEffect, useRef, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';
import ServiceTermSubpage from '../subpage/serviceTerm';
import TossPaymentsWidget, {
  TossPaymentsWidgetRef,
} from '../component/tosspaymentWidget';
import { AVALIABLE_ORDER_PRICE } from '../constants';

export default function AvaliableOrderPurchasePage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const PurchaseType: 'purchase' | 'selling' =
    searchParams.get('type') === 'purchase' ? 'purchase' : 'selling';

  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const { loginMember } = useMemberStore();

  // 동의 여부
  const [isAgree, setIsAgree] = useState(false);
  // 서비스 이용약관 모달
  const [isServiceTermOpen, setIsServiceTermOpen] = useState(false);

  // 토스페이먼츠 컴포넌트 진행중 상태
  const [isPending, setIsPending] = useState(false);
  const paymentWidgetRef = useRef<TossPaymentsWidgetRef>(null);
  const handleExternalPayment = () => {
    paymentWidgetRef.current?.handlePaymentRequest(); // 👈 외부에서 실행
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '견적 요청권 구매',
      rightElement: (
        <div className='min-w-[28px] cursor-pointer'>
          <X
            onClick={() => {
              navigate('/main');
            }}
            width={28}
            height={28}
            strokeWidth={1.5}
          />
        </div>
      ),
    });
    setIsBottomTabbar(false);
  }, []);
  return (
    <div className='w-full flex flex-col justify-between gap-[50px] items-start min-h-[calc(100dvh-60px)]'>
      <div className='h-full w-full'>
        <div className='w-full py-[30px] px-5 border-b-[8px] border-[#F3F6F9]'>
          <div className='flex justify-start items-center gap-4'>
            <div className='flex justify-center items-center p-2.5 bg-[#F3F6F9] rounded-[10px]'>
              <img
                src='/assets/images/png/carsayo_card.png'
                alt='카사요 기프트카드 이미지'
                className='w-[70px] object-contain'
              />
            </div>
            <div className='flex flex-col justify-start items-start gap-[14px]'>
              <div className='flex flex-col justify-start items-start gap-0.5'>
                <strong className='text-[15px] leading-[24px] font-[600]'>
                  견적 요청권
                </strong>
                <p className='text-[14px] leading-[22.4px] font-[500] text-[#555555]'>
                  {PurchaseType === 'purchase' ? '신차 구매' : '내 차 팔기'}
                </p>
              </div>
              <strong className='text-[16px] leading-[16px] font-[600]'>
                {AVALIABLE_ORDER_PRICE.toLocaleString()}원
              </strong>
            </div>
          </div>
          <div className='w-full rounded-[10px] bg-[#F3F6F9] p-4 mt-[40px]'>
            <div className='border-b border-[#E5E5EC] pb-4 text-[15px] font-[500] leading-[22.5px]'>
              무료 견적 요청 안내 사항
            </div>
            <ul className='mt-5 flex w-full list-none flex-col items-start justify-start gap-3'>
              <li
                className={cn(
                  'relative break-keep pl-5 text-[14px] leading-[21px] text-[#555555]',
                  'after:absolute after:left-[9px] after:top-[8px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
                )}
              >
                무료 견적 요청은 신차 구매, 내 차 팔기 각 2건으로 제한됩니다.
              </li>
              <li
                className={cn(
                  'relative break-keep pl-5 text-[14px] leading-[21px] text-[#555555]',
                  'after:absolute after:left-[9px] after:top-[8px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
                )}
              >
                2대 초과 견적 요청 시, 건당 1만원의 정보 이용료가 발생합니다.
              </li>
              <li
                className={cn(
                  'relative break-keep pl-5 text-[14px] leading-[21px] text-[#555555]',
                  'after:absolute after:left-[9px] after:top-[8px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
                )}
              >
                <span className='text-[#484AF5]'>
                  카사요를 통해 주문을 진행한 경우
                </span>{' '}
                결제한 견적 요청권 구매금액은{' '}
                <span className='text-[#484AF5]'>100% 환불</span>해 드립니다.
              </li>
              <li
                className={cn(
                  'relative break-keep pl-5 text-[14px] leading-[21px] text-[#555555]',
                  'after:absolute after:left-[9px] after:top-[8px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
                )}
              >
                결제가 완료되면 무료 견적 신청이 가능하며 마이 페이지 {'>'}{' '}
                결제내역 페이지에서 확인할 수 있습니다.
              </li>
            </ul>
          </div>
        </div>
        {/* 구매자 정보 */}
        <div className='w-full pt-[22px] pb-[30px] px-5 border-b-[8px] border-[#F3F6F9]'>
          <div className='mb-6 text-[18px] leading-[18px] font-[600]'>
            구매자 정보
          </div>
          <ul className='py-5 px-4 bg-[#F3F6F9] rounded-[10px] text-[15px] leading-[15px] font-[500] flex flex-col justify-start items-start gap-5'>
            <li className='w-full flex justify-between items-center gap-5'>
              <span className='text-[#7D848A]'>주문자</span>
              <strong className='font-[500]'>{loginMember?.name_real}</strong>
            </li>
            <li className='w-full flex justify-between items-center gap-5'>
              <span className='text-[#7D848A]'>연락처</span>
              <strong className='font-[500]'>{loginMember?.phoneNumber}</strong>
            </li>
          </ul>
        </div>
        {/* 결제 수단 */}
        <div className='w-full pt-[30px] pb-[30px] border-b-[8px] border-[#F3F6F9]'>
          <div className='mb-6 text-[18px] leading-[18px] font-[600] px-5'>
            결제 수단
          </div>
          <TossPaymentsWidget
            ref={paymentWidgetRef}
            type={PurchaseType}
            setIsPending={setIsPending}
          />
        </div>
        {/* 주문 내역 */}
        <div className='w-full pt-[30px] px-5'>
          <div className='text-[18px] leading-[18px] font-[600] pb-6 border-b border-[#E5EAEE]'>
            주문 내역
          </div>
          <div className='py-6'>
            <div className='w-full flex justify-between items-center gap-4 pb-6 border-b border-[#E5EAEE] text-[14px] leading-[19.6px]'>
              <span className='text-[#7D848A]'>견적 요청권</span>
              <strong className='font-[500]'>
                {AVALIABLE_ORDER_PRICE.toLocaleString()}원
              </strong>
            </div>
            <div className='w-full flex justify-between items-center gap-4 pt-6'>
              <span className='text-[16px] leading-[16px] font-[500]'>
                총 결제 금액
              </span>
              <strong className='text-[18px] leading-[18px] font-[600]'>
                {AVALIABLE_ORDER_PRICE.toLocaleString()}원
              </strong>
            </div>
          </div>
          <div className='w-full'>
            <div
              className='w-full cursor-pointer flex justify-between items-center gap-4 py-[14px] px-4 rounded-[10px] bg-[#F3F6F9] mb-4'
              onClick={() => setIsServiceTermOpen(true)}
            >
              <span className='text-[15px] leading-[22.5px] font-[500]'>
                서비스 이용약관
              </span>
              <ChevronRight
                width={24}
                height={24}
                color='#666'
                strokeWidth={1.5}
              />
            </div>
            <CustomCheckbox
              value={''}
              label=' 위의 내용을 모두 확인하였으며, 결제에 동의합니다.'
              className='!text-[14px]'
              onChange={() => setIsAgree(!isAgree)}
            />
          </div>
        </div>
      </div>
      <div className='w-full flex justify-center items-center px-5 pt-[12px] pb-[10px]'>
        <Button
          theme='primary'
          isLoading={isPending}
          disabled={!isAgree}
          onClick={() => {
            handleExternalPayment();
          }}
        >
          {AVALIABLE_ORDER_PRICE.toLocaleString()}원 결제하기
        </Button>
      </div>

      <ServiceTermSubpage
        open={isServiceTermOpen}
        setOpen={setIsServiceTermOpen}
      />
    </div>
  );
}
