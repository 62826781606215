import { CarColor } from '@carsayo/types';
import { X } from 'lucide-react';
import { KEY_INTERIOR_CAR_DIRECTINPUT } from 'pages/v3/order/purchase/constants';
import { cn } from 'utils';

export default function ColorSquare({
  color,
  imageUrl,
}: {
  color?: CarColor;
  imageUrl?: string;
}) {
  const isColorImage = !!color?.image_url || !!imageUrl;
  const isColorCode = !!color?.color_code_main;

  const isDirectInput = color?.id === KEY_INTERIOR_CAR_DIRECTINPUT;

  return (
    <div
      className={cn(
        'flex-none w-10 h-10 flex items-center justify-center rounded-lg border border-[#E5E5EC]',
        !isColorImage && !isColorCode && 'border-dashed',
      )}
    >
      {isDirectInput ? (
        // 직접 입력이 선택되었을 경우 펜 아이콘
        <div>
          {/* 펜 아이콘 */}
          <svg
            xmlns='http://www.w3.org/2000/svg'
            width='24'
            height='24'
            viewBox='0 0 24 24'
            fill='none'
          >
            <g clipPath='url(#clip0_3324_36360)'>
              <path
                d='M4 19.9998H8L18.5 9.49981C18.7626 9.23717 18.971 8.92537 19.1131 8.58221C19.2553 8.23905 19.3284 7.87125 19.3284 7.49981C19.3284 7.12838 19.2553 6.76058 19.1131 6.41742C18.971 6.07426 18.7626 5.76246 18.5 5.49981C18.2374 5.23717 17.9256 5.02883 17.5824 4.88669C17.2392 4.74455 16.8714 4.67139 16.5 4.67139C16.1286 4.67139 15.7608 4.74455 15.4176 4.88669C15.0744 5.02883 14.7626 5.23717 14.5 5.49981L4 15.9998V19.9998Z'
                fill='#B2B7BB'
                stroke='#B2B7BB'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
              <path
                d='M12 5L18.5 11.5'
                stroke='white'
                strokeWidth='2'
                strokeLinecap='round'
                strokeLinejoin='round'
              />
            </g>
            <defs>
              <clipPath id='clip0_3324_36360'>
                <rect width='24' height='24' fill='white' />
              </clipPath>
            </defs>
          </svg>
          {/* 펜 아이콘 */}
        </div>
      ) : isColorImage || isColorCode ? (
        // 색상 이미지 또는 색상 코드가 선택되었을 경우
        <>
          {color?.color_code_main ? (
            <div className='w-full h-full rounded-lg flex flex-col'>
              <div
                className={cn(
                  'w-full flex-auto rounded-lg',
                  color.color_code_sub && 'rounded-b-none',
                )}
                style={{
                  background: color.color_code_main,
                }}
              ></div>

              {color.color_code_sub && (
                <div
                  className='w-full flex-auto rounded-b-lg'
                  style={{
                    background: color.color_code_sub,
                  }}
                ></div>
              )}
            </div>
          ) : color?.image_url ? (
            <img
              src={color.image_url}
              className={cn('w-full h-full rounded-lg object-cover')}
            />
          ) : imageUrl ? (
            <img
              src={imageUrl}
              className={cn('w-full h-full rounded-lg object-cover')}
            />
          ) : (
            <div className='w-full h-full rounded-lg'></div>
          )}
        </>
      ) : (
        // 색상 이미지 또는 색상 코드가 선택되지 않았을 경우
        <X color='#B2B7BB' />
      )}
    </div>
  );
}
