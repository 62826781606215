import { OrderPurchaseDefaultProps } from '../../../interface';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import { BuyerType, DutyFreeCondition, SigunguId } from '@carsayo/types';
import { Controller } from 'react-hook-form';
import { Calendar, ChevronDown } from 'lucide-react';
import { cn } from 'utils';
import { SelectSigungu } from 'components/view/buy/selectSigungu';
import { useCallback, useState } from 'react';
import parseSigungu from '../../../utils/parseSigungu';
import dayjs from 'dayjs';
import OrderPurchaseDatePicker from './ui/DatePicker';
import { motion } from 'framer-motion';

interface OrderPurchase_Method_CashProps extends OrderPurchaseDefaultProps {
  totalPrice: number;
}

export default function OrderPurchase_Method_Cash({
  form,
  wholeSellingCar,
  totalPrice,
}: OrderPurchase_Method_CashProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [drawerTarget, setDrawerTarget] = useState<
    'delivery' | 'residence' | null
  >(null);
  const handleResidenceRegionChange = useCallback(
    (value: SigunguId) => {
      if (drawerTarget === 'delivery') {
        form.setValue('purchaseInfo.일시불.deliveryRegion', value.toString(), {
          shouldValidate: true,
        });
      } else {
        form.setValue('purchaseInfo.일시불.residenceRegion', value.toString(), {
          shouldValidate: true,
        });
      }
    },
    [drawerTarget],
  );

  return (
    <div className='flex flex-col gap-6'>
      {/* 구입주체 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          구입주체
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.일시불.buyerType'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value}
                onValueChange={(value) => {
                  form.setValue(field.name, value as BuyerType, {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {Object.keys(BuyerType).map((key) => (
                  <SelectV3Item key={key} value={key}>
                    <div className='flex items-center'>
                      {BuyerType[key as keyof typeof BuyerType]}
                    </div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 구입시기 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          구입시기
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.일시불.purchaseDate'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => {
            const [open, setOpen] = useState(false);

            return (
              <div>
                <div
                  /** 에러 발생시 포커스 영역 처리 */
                  role='button'
                  ref={(ref) => {
                    if (fieldState.error && ref) {
                      ref.focus();
                    }
                  }}
                  tabIndex={0}
                  className='flex gap-2'
                >
                  <div className='w-[40%] min-w-[120px] flex-none'>
                    <SelectV3
                      placeholder='선택해 주세요.'
                      value={field.value === null ? 'null' : '0'}
                      onValueChange={(value) => {
                        form.setValue(
                          field.name,
                          /** 타입 에러 방지 */
                          value === 'null' ? null : (undefined as any),
                          {
                            shouldValidate: value === 'null' ? true : undefined,
                          },
                        );
                      }}
                    >
                      {[
                        { label: '1개월 이내', value: 'null' },
                        { label: '직접 선택', value: '0' },
                      ].map((key) => (
                        <SelectV3Item key={key.value} value={key.value}>
                          <div className='flex items-center'>{key.label}</div>
                        </SelectV3Item>
                      ))}
                    </SelectV3>
                  </div>

                  <div className='flex-auto'>
                    <button
                      disabled={field.value === null}
                      className={cn(
                        'w-full flex gap-1 h-[50px] border border-[#E5E5EC] rounded-[8px] items-center justify-start px-4 tiny:px-2 pr-[12px] text-sm text-[#555555]',
                        field.value === null && 'bg-[#F3F6F9]',
                        fieldState.error &&
                          'border-[#FF4D4D] focus:border-[#FF4D4D] border-solid border',
                      )}
                      onClick={() => {
                        setOpen(true);
                      }}
                    >
                      <>
                        <Calendar className='w-[16px] h-[16px]' />
                        {field.value === null ? (
                          <div className='text-[#222] text-sm font-medium leading-none tracking-[-0.3px]'>
                            미선택
                          </div>
                        ) : field.value === '0' || !field.value ? (
                          <div className='inline-flex items-center gap-2 text-[#222] text-sm font-medium leading-none tracking-[-0.3px]'>
                            날짜 선택
                          </div>
                        ) : (
                          <div className='text-[#222] text-sm font-medium leading-none tracking-[-0.3px]'>
                            {dayjs(field.value, 'YYYYMMDD').format(
                              'YYYY년 MM월 DD일',
                            )}
                          </div>
                        )}
                      </>
                    </button>
                    {fieldState.error && fieldState.error.message && (
                      <div className='text-[12px] leading-[12px] p-1 font-[400] text-red-500'>
                        {fieldState.error.message}
                      </div>
                    )}
                  </div>

                  <OrderPurchaseDatePicker
                    open={open}
                    setOpen={setOpen}
                    value={
                      field.value === null
                        ? null
                        : dayjs(field.value, 'YYYYMMDD').toDate()
                    }
                    onChange={(date) => {
                      form.setValue(
                        field.name,
                        dayjs(date).format('YYYYMMDD'),
                        {
                          shouldValidate: true,
                        },
                      );
                    }}
                  />
                </div>
              </div>
            );
          }}
        />
      </div>

      {/* 인수(탁송)지역 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          인수(탁송)지역
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.일시불.deliveryRegion'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div>
              <div
                role='button'
                ref={(ref) => {
                  if (fieldState.error && ref) {
                    ref.focus();
                  }
                }}
                tabIndex={0}
                className={cn(
                  'w-full flex h-[50px] border border-[#E5E5EC] rounded-[8px] items-center justify-center px-4 pr-[12px] text-sm text-[#555555]',
                  fieldState.error &&
                    'border-[#FF4D4D] focus:border-[#FF4D4D] border-solid border',
                )}
                onClick={() => {
                  setDrawerTarget('delivery');
                  setIsOpen(true);
                }}
              >
                <div>
                  {field.value ? (
                    <div className='text-[16px] leading-[16px] font-[400] text-[#222] tracking-[0.00938em]'>
                      {parseSigungu(Number(field.value) as SigunguId)?.name}
                    </div>
                  ) : (
                    <div className='text-[16px] leading-[16px] font-[400] text-gray-500 tracking-[0.00938em]'>
                      선택해 주세요.
                    </div>
                  )}
                </div>
                <motion.div
                  animate={{ rotate: isOpen ? 180 : 0 }}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                  className='ml-auto'
                >
                  <ChevronDown
                    width={24}
                    height={24}
                    strokeWidth={1.5}
                    color={'#666666'}
                    className='ml-auto'
                  />
                </motion.div>
              </div>
              {fieldState.error && fieldState.error.message && (
                <div className='text-[12px] leading-[12px] p-1 font-[400] text-red-500'>
                  {fieldState.error.message}
                </div>
              )}
            </div>
          )}
        />
      </div>

      {/* 거주(등록)지역 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          거주(등록)지역
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.일시불.residenceRegion'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div>
              <div
                role='button'
                ref={(ref) => {
                  if (fieldState.error && ref) {
                    ref.focus();
                  }
                }}
                tabIndex={0}
                className={cn(
                  'w-full flex h-[50px] border border-[#E5E5EC] rounded-[8px] items-center justify-center px-4 pr-[12px] text-sm text-[#555555]',
                  fieldState.error &&
                    'border-[#FF4D4D] focus:border-[#FF4D4D] border-solid border',
                )}
                onClick={() => {
                  setDrawerTarget('residence');
                  setIsOpen(true);
                }}
              >
                <div>
                  {field.value ? (
                    <div className='text-[16px] leading-[16px] font-[400] text-[#222] tracking-[0.00938em]'>
                      {parseSigungu(Number(field.value) as SigunguId)?.name}
                    </div>
                  ) : (
                    <div className='text-[16px] leading-[16px] font-[400] text-gray-500 tracking-[0.00938em]'>
                      선택해 주세요.
                    </div>
                  )}
                </div>
                <motion.div
                  animate={{ rotate: isOpen ? 180 : 0 }}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                  className='ml-auto'
                >
                  <ChevronDown
                    width={24}
                    height={24}
                    strokeWidth={1.5}
                    color={'#666666'}
                    className='ml-auto'
                  />
                </motion.div>
              </div>
              {fieldState.error && fieldState.error.message && (
                <div className='text-[12px] leading-[12px] p-1 font-[400] text-red-500'>
                  {fieldState.error.message}
                </div>
              )}
            </div>
          )}
        />
      </div>

      {/* 면세조건 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          면세조건
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.일시불.dutyFreeCondition'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value}
                onValueChange={(value) => {
                  form.setValue(field.name, value as DutyFreeCondition, {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {Object.keys(DutyFreeCondition).map((key) => (
                  <SelectV3Item key={key} value={key}>
                    <div className='flex items-center'>
                      {DutyFreeCondition[key as keyof typeof DutyFreeCondition]}
                    </div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 지역 선택 Drawer */}
      <SelectSigungu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setRegion={handleResidenceRegionChange}
      />
    </div>
  );
}
