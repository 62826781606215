import Lottie from 'lottie-react';
import circleLoading from '../../../lottie/circleLoading.json';
import useSystemStore from 'store/useSystemStore';

// TODO: 드래그 이벤트 추가
const LoadingDim = () => {
  const { isLoadingDim } = useSystemStore();

  return (
    <>
      {isLoadingDim && (
        <div className='fixed flex top-0 left-0 z-[10000] w-full h-full'>
          <div className='absolute flex w-full h-full bg-black opacity-30'></div>
          <div className='absolute flex items-center justify-center w-full h-full'>
            <Lottie
              animationData={circleLoading}
              loop={true}
              className='absolute w-[75px] h-[75px]'
            />
          </div>
        </div>
      )}
    </>
  );
};
export default LoadingDim;
