import { useNavigate, useLocation, useSearchParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

export const OrderPurchaseTab = {
  brand: {
    title: '브랜드 선택',
    progress: 15,
  },
  model: {
    title: '모델 선택',
    progress: 25,
  },
  trimGrade: {
    title: '세부 모델 선택',
    progress: 40,
  },
  color: {
    title: '색상 선택',
    progress: 50,
  },
  option: {
    title: '옵션 선택',
    progress: 60,
  },
  method: {
    title: '구매 방법 선택',
    progress: 80,
  },
  submit: {
    title: '견적 요청',
    progress: 100,
  },
};
export type OrderPurchaseTab = keyof typeof OrderPurchaseTab;

const useTabHandler = (initialTab: OrderPurchaseTab) => {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const [currentTab, setCurrentTab] = useState<OrderPurchaseTab>(initialTab);

  const goToNextTab = (tab: OrderPurchaseTab) => {
    const newSearchParams = new URLSearchParams(location.search);
    newSearchParams.set('tab', tab);

    navigate(`${location.pathname}?${newSearchParams.toString()}`, {
      preventScrollReset: true,
    });
  };

  const goToPrevTab = () => {
    navigate(-1);
  };

  useEffect(() => {
    const data = searchParams.get('tab');

    if (data === currentTab) return;

    if (data && data !== currentTab) {
      setCurrentTab(data as OrderPurchaseTab);
    } else {
      setCurrentTab(initialTab);
    }
  }, [searchParams]);

  return { currentTab, goToNextTab, goToPrevTab };
};

export default useTabHandler;
