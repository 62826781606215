/* eslint-disable @typescript-eslint/no-unused-vars */
import SubPage from 'components/view/SubPage';
import { ChevronLeft, Star, X } from 'lucide-react';
import { cn } from 'utils';
import { DealerOrderSubpageProps } from 'pages/v3/orderDetailTemp/type';
import dayjs from 'dayjs';
import MultiImageViewer from 'components_v3/dialog/MultiImageViewer';
import { FileInfo } from '@carsayo/types';
import { useState } from 'react';

/** 리뷰 확인 서브페이지 */
export default function DealerOrderDetailReviewSubpage({
  orderListData,
  isOpen,
  setIsOpen,
}: DealerOrderSubpageProps) {
  const attachedFiles = [
    orderListData.review?.file_1,
    orderListData.review?.file_2,
    orderListData.review?.file_3,
    orderListData.review?.file_4,
    orderListData.review?.file_5,
  ].filter((file): file is FileInfo => Boolean(file)) as FileInfo[];

  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [imageViewerIndex, setImageViewerIndex] = useState(0);

  return (
    <SubPage
      isOpen={isOpen}
      setIsOpen={(isOpen) => {
        setIsOpen(isOpen);
      }}
    >
      {/* Header */}
      <div className='flex flex-none h-14 items-center justify-between bg-white px-5'>
        <div
          className='cursor-pointer'
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <ChevronLeft className='w-8 h-8' strokeWidth={1.5} />
        </div>
        <div className='pr-4 grow text-center text-[#222] text-[20px] font-semibold leading-[20px] tracking-[-0.4px]'>
          받은 리뷰
        </div>
      </div>

      {/* 주문 정보 */}
      <div className='flex gap-4 px-5 mt-[30px]'>
        <div className='flex-none w-[120px] h-[90px] rounded-[10px] overflow-hidden'>
          <img
            src={
              typeof orderListData.imageUrl === 'string'
                ? orderListData.imageUrl
                : orderListData.imageUrl?.[0]
            }
            alt='예시 이미지'
            className={cn(
              'w-full h-full object-contain rounded-[10px] border border-gray-200',
              typeof orderListData.imageUrl === 'string' && 'p-2',
            )}
          />
        </div>
        <div className='flex flex-col gap-1 justify-center'>
          <div className='overflow-hidden text-[#555] text-[14px] font-medium leading-[14px] tracking-[-0.28px]'>
            {orderListData.type === 'sellCar'
              ? '내 차 팔기'
              : orderListData.type === 'newCar'
                ? '신차 구매'
                : orderListData.type === 'lease'
                  ? '리스'
                  : '장기렌트'}
          </div>
          <div className='overflow-hidden text-[#222] text-[14px] font-semibold leading-[22.4px] tracking-[-0.28px] whitespace-pre-line break-keep'>
            {orderListData.title}
          </div>
        </div>
      </div>

      {/* 주문자 정보 */}
      <div className='px-5 mt-4'>
        <div className='flex items-center justify-between gap-2.5  bg-[#F3F6F9] rounded-[16px] w-full h-[60px] p-3'>
          <div className='flex items-center gap-2.5'>
            <img
              src={orderListData.review?.writer.imageUrl}
              alt='profile'
              className='w-[36px] h-[36px] rounded-full'
            />
            <div className='text-[#222] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
              {orderListData.review?.writer.name}
            </div>
          </div>
        </div>
      </div>

      {/* Divider */}
      <div className='px-5'>
        <div className='w-full h-[1px] bg-[#E5EAEE] my-5' />
      </div>

      {/* 리뷰 상세정보 영역 */}
      <div className='px-5'>
        <div className='flex justify-between items-center gap-2.5'>
          {/* 별점 영역 */}
          <div className='flex justify-start items-center'>
            {[...Array(orderListData.review?.rating)].map((_, index) => (
              <Star
                key={index}
                fill={'#FFC93E'}
                width='16'
                height='16'
                stroke='0'
              />
            ))}
            {[...Array(5 - (orderListData.review?.rating ?? 0))].map(
              (_, index) => (
                <Star
                  key={index}
                  fill={'#DDDDDD'}
                  width='16'
                  height='16'
                  stroke='0'
                />
              ),
            )}
          </div>

          <div className='text-gray-500 text-[13px] font-medium leading-[13px] tracking-[-0.26px]'>
            {dayjs(orderListData.review?.created_at).format('YYYY.MM.DD')} 작성
          </div>
        </div>

        <div className='flex flex-col space-y-3'>
          {/* 제목 영역 */}
          <div className='mt-5 text-[#222] text-[15px] font-medium leading-[15px] tracking-[-0.3px] whitespace-pre-line break-keep'>
            {orderListData.review?.title}
          </div>

          {/* 내용 영역 */}
          <div className='mt-1.5 text-[#222] text-[15px] font-normal leading-[22.5px] tracking-[-0.3px] whitespace-pre-line break-keep'>
            {orderListData.review?.content}
          </div>

          {/* 사진 존재 시 */}
          {attachedFiles.length > 0 && (
            <div className='pt-3 overflow-x-auto flex gap-2 scroll-hidden'>
              {attachedFiles.map((file, index) => (
                <div
                  key={file.id}
                  className='h-[107px] aspect-square rounded-[6px]'
                  onClick={() => {
                    setImageViewerIndex(index);
                    setIsImageViewerOpen(true);
                  }}
                >
                  <img
                    src={file.url}
                    alt='review'
                    className='w-full h-full object-cover rounded-[6px]'
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      <MultiImageViewer
        isOpen={isImageViewerOpen}
        setIsOpen={setIsImageViewerOpen}
        initialIndex={imageViewerIndex}
        fileUrl={attachedFiles.map((file) => file.url)}
      />
    </SubPage>
  );
}
