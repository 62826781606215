import { CircularProgress, LinearProgress } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { getCarColor, getCarOption, getWholeSellingCar } from 'apis/car';
import { X } from 'lucide-react';
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import OrderPurchase_Brand from './components/tab/brand';
import LeaseRentNotice from './components/notice/leaseRent';
import { isValidOrderPurchaseMode, OrderPurchaseMode } from './interface';
import { useForm } from 'react-hook-form';
import { OrderPurchaseFormData, OrderPurchaseSchema } from './form';
import { zodResolver } from '@hookform/resolvers/zod';
import OrderPurchase_Button from './components/button';
import useTabHandler, { OrderPurchaseTab } from './hooks/useTabHandler';
import { AnimatePresence, motion } from 'framer-motion';
import OrderPurchase_Model from './components/tab/model/index';
import { cn } from 'utils';
import OrderPurchase_TrimGrade from './components/tab/trimGrade';
import OrderPurchase_Color from './components/tab/color/index';
import ModelInfo from './components/modelInfo';
import OrderPurchase_Option from './components/tab/option';
import OrderPurchase_Method from './components/tab/method';
import OrderPurchase_Submit from './components/tab/submit';
import ConfirmV2 from 'components/common/v3/confirm';
import useMemberStore from 'store/useMemberStore';
import MisteryShopperDrawer from './components/drawer/misteryShopper';
import V3_Confirm from 'components_v3/dialog/Confirm';
import { SelectedCarData } from '@carsayo/types';
import calculateTotalOrderPrice from './utils/calculateTotalOrderPrice';

const OrderPurchasePage = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const mode = searchParams.get('mode');

  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const { loginMember } = useMemberStore();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [orderPurchaseMode, setOrderPurchaseMode] = useState<OrderPurchaseMode>(
    isValidOrderPurchaseMode(mode) ? mode : 'newcar',
  );

  /** CloseConfirm */
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  /** 견적 요청권 구매 필요 */
  const [isPurchaseConfirmOpen, setIsPurchaseConfirmOpen] = useState(false);
  /** 서비스 이용 규정 Drawer 노출 */
  const [isServiceNoticeDrawerOpen, setIsServiceNoticeDrawerOpen] =
    useState(false);

  const { currentTab, goToNextTab, goToPrevTab } = useTabHandler('brand');

  const initPurchaseMethod =
    orderPurchaseMode === 'rent'
      ? '장기렌트'
      : orderPurchaseMode === 'lease'
        ? '리스'
        : '일시불';

  const form = useForm<OrderPurchaseFormData>({
    resolver: zodResolver(OrderPurchaseSchema),
    defaultValues: {
      optionList: [],
      purchaseMethod: initPurchaseMethod,
    },
    shouldFocusError: true,
  });

  const { data: wholeSellingCar, isFetching } = useQuery({
    queryKey: ['WholeSellingCar'],
    queryFn: getWholeSellingCar,
    /** 10분간 유효 */
    staleTime: 1000 * 60 * 10,
    gcTime: 1000 * 60 * 10,
  });

  const { data: carColorData, isFetching: isFetchingCarColor } = useQuery({
    queryKey: ['carColor', form.getValues('carGradeId')],
    queryFn: () => getCarColor(form.getValues('carGradeId')!.toString()),
    staleTime: 1000 * 60 * 10,
    gcTime: 1000 * 60 * 10,
    enabled: Boolean(form.getValues('carGradeId')),
  });

  /** 이 데이터는 carColor에서 조작될 수 있습니다.
   * @note 특정 색상 선택 시, 특정 옵션이 "필수 조건"이 될 수 있습니다.
   */
  const { data: carOptionList, isFetching: isFetchingCarOption } = useQuery({
    queryKey: ['carOption', form.getValues('carGradeId')],
    queryFn: () => getCarOption(form.getValues('carGradeId')!.toString()),
    staleTime: 1000 * 60 * 10,
    gcTime: 1000 * 60 * 10,
    enabled: Boolean(form.getValues('carGradeId')),
  });

  const handleClickEvent = {
    openClosePopup: () => {
      setIsConfirmOpen(true);
    },
  };

  /** ProgressBar */
  const isLoading = isFetching;

  /** 상단, 하단 숨김 */
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'hidden',
      mode: 'subpage',
      title: ' ',
      rightElement: undefined,
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
  }, []);

  const [flag, setFlag] = useState(false);
  useEffect(() => {
    if (!loginMember || flag) return;

    if (loginMember.customerInfo?.count.avaliableOrder.purchase === 0) {
      setIsPurchaseConfirmOpen(true);
    } else {
      setIsServiceNoticeDrawerOpen(true);
    }
    setFlag(true);
  }, [loginMember]);

  /** 페이지 상태 확인 */
  useEffect(() => {
    if (currentTab === 'model' && !form.getValues('carMakerId')) {
      return goToPrevTab();
    }
    if (currentTab === 'trimGrade' && !form.getValues('carModelId')) {
      return goToPrevTab();
    }
    if (
      (currentTab === 'color' ||
        currentTab === 'option' ||
        currentTab === 'method') &&
      !form.getValues('carGradeId')
    ) {
      return goToPrevTab();
    }

    if (currentTab === 'submit' && !form.getValues('purchaseMethod')) {
      return goToPrevTab();
    }
  }, [currentTab]);

  /** Android: 이 페이지 안에서 백버튼 클릭 시 window.native.onBackPressed가 실행됩니다. */
  useEffect(() => {
    if (!window.native) return;
    window.native.onBackPressed = () => {
      navigate(-1);
    };
    return () => {
      if (!window.native) return;
      window.native.onBackPressed = () => {};
    };
  }, []);

  /** 선택 차량 정보
   * @note 차량 정보가 모두 선택되지 않았을 경우 null 반환
   */
  const selectedCarData: SelectedCarData | null = useMemo(() => {
    if (!wholeSellingCar || !carColorData || !carOptionList) return null;

    const carMakerId = form.getValues('carMakerId');
    const carModelId = form.getValues('carModelId');
    const carTrimId = form.getValues('carTrimId');
    const carGradeId = form.getValues('carGradeId');
    const carColorId = form.getValues('carColorId');
    const optionList = form.getValues('optionList');
    const innerCarColor = form.getValues('interiorCarColor');

    const targetBrand = wholeSellingCar.find((el) => el.id === carMakerId);
    const targetModel = targetBrand?.car_model?.find(
      (el) => el.id === carModelId,
    );
    const targetTrim = targetModel?.car_trim?.find((el) => el.id === carTrimId);
    const targetGrade = targetTrim?.car_grade?.find(
      (el) => el.id === carGradeId,
    );
    const targetColor = carColorData.find((el) => el.id === carColorId);
    const targetOptionList = carOptionList.filter((el) =>
      optionList.includes(el.id),
    );

    // 찾은 값 중 하나라도 undefined이면 null 반환
    // note: color는 nullable이므로 예외
    if (
      !targetBrand ||
      !targetModel ||
      !targetTrim ||
      !targetGrade ||
      !targetOptionList
    ) {
      return null;
    }

    return {
      carMaker: (({ car_model, ...rest }) => rest)(targetBrand),
      carModel: (({ car_trim, ...rest }) => rest)(targetModel),
      carTrim: (({ car_grade, ...rest }) => rest)(targetTrim),
      carGrade: targetGrade,
      carColor: targetColor ?? undefined,
      isNotSelectColor: targetColor ? false : true,
      carOptionList: targetOptionList,
      interiorCarColor: innerCarColor
        ? {
            id: innerCarColor.id,
            name: innerCarColor.name,
            price: innerCarColor.price,
            category: innerCarColor.category ?? null,
            imageUrl: innerCarColor.imageUrl ?? null,
            color_code_main: innerCarColor.color_code_main ?? null,
            color_code_sub: innerCarColor.color_code_sub ?? null,
            warning_text: innerCarColor.warning_text ?? null,
            internalSelection: innerCarColor.internalSelection ?? null,
          }
        : undefined,
    };
  }, [
    form.watch([
      'carMakerId',
      'carModelId',
      'carTrimId',
      'carGradeId',
      'optionList',
      'carColorId',
    ]),
    wholeSellingCar,
    carColorData,
    carOptionList,
  ]);
  /** selectedCarData로 계산한 예상 가격 정보
   * @note 총 가격 계산 여기서
   */
  const totalPrice = useMemo(() => {
    if (!selectedCarData) return 0;

    return calculateTotalOrderPrice(selectedCarData);
  }, [selectedCarData]);

  return (
    <div className='h-full w-full flex flex-col relative justify-between'>
      {/* Header Part */}
      <div>
        <div className='w-full h-[60px] flex items-center justify-between bg-white/50 sticky top-0 px-4'>
          <div className='w-[32px]'></div>
          <div className='text-[#222] text-center font-Pretendard text-[20px] font-semibold leading-[20px] tracking-[-0.4px]'>
            {OrderPurchaseTab[currentTab].title}
          </div>
          <button onClick={handleClickEvent.openClosePopup}>
            <X className='stroke-[1.4px]' size={32} />
          </button>
        </div>
        <div className='px-5'>
          <LinearProgress
            value={OrderPurchaseTab[currentTab].progress}
            variant={isLoading ? 'indeterminate' : 'determinate'}
            className='!h-0.5 !bg-[#E5E5EC] [&>*]:!bg-[#7273F9]'
          />
        </div>
      </div>

      {/* Content Part */}
      <div className='relative flex flex-col flex-auto justify-start overflow-y-auto'>
        {isFetching && (
          <div className='flex w-full h-full items-center justify-center'>
            <CircularProgress />
          </div>
        )}
        {wholeSellingCar && (
          <>
            <AnimatePresence>
              {currentTab === 'brand' && (
                <motion.div
                  className='h-full w-full'
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0, visibility: 'visible' }}
                  transition={{ duration: 0.3 }}
                >
                  <OrderPurchase_Brand
                    form={form}
                    wholeSellingCar={wholeSellingCar}
                    orderPurchaseMode={orderPurchaseMode}
                  />
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {currentTab === 'model' && (
                <motion.div
                  className='flex-auto bg-[#F3F6F9] flex flex-col'
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0, visibility: 'visible' }}
                  transition={{ duration: 0.3 }}
                >
                  <OrderPurchase_Model
                    form={form}
                    wholeSellingCar={wholeSellingCar}
                  />
                </motion.div>
              )}
            </AnimatePresence>

            {/* 상단에 선택한 모델 정보 나타나는 부분
             @note trimGrade, color, option, method일 때 나타납니다.
            */}
            <AnimatePresence>
              {(currentTab === 'trimGrade' ||
                currentTab === 'color' ||
                currentTab === 'option' ||
                currentTab === 'method') && (
                <motion.div
                  className='bg-[#F3F6F9] flex flex-col'
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0, visibility: 'visible' }}
                  transition={{ duration: 0.3 }}
                >
                  <ModelInfo form={form} wholeSellingCar={wholeSellingCar} />
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {currentTab === 'trimGrade' && (
                <motion.div
                  className='w-full flex flex-col'
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0, visibility: 'visible' }}
                  transition={{ duration: 0.3 }}
                >
                  <OrderPurchase_TrimGrade
                    form={form}
                    wholeSellingCar={wholeSellingCar}
                  />
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {currentTab === 'color' && carColorData && (
                <motion.div
                  className='flex-auto w-full flex flex-col'
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0, visibility: 'visible' }}
                  transition={{ duration: 0.3 }}
                >
                  <OrderPurchase_Color
                    form={form}
                    carColor={carColorData}
                    isFetching={isFetchingCarColor}
                    wholeSellingCar={wholeSellingCar}
                  />
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {currentTab === 'option' && carOptionList && (
                <motion.div
                  className='flex-auto w-full flex flex-col'
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0, visibility: 'visible' }}
                  transition={{ duration: 0.3 }}
                >
                  <OrderPurchase_Option
                    form={form}
                    wholeSellingCar={wholeSellingCar}
                    carGrade={selectedCarData?.carGrade}
                    carOptionList={carOptionList}
                    isFetching={isFetchingCarOption}
                  />
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {currentTab === 'method' && (
                <motion.div
                  className='flex-auto w-full flex flex-col'
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0, visibility: 'visible' }}
                  transition={{ duration: 0.3 }}
                >
                  <OrderPurchase_Method
                    form={form}
                    wholeSellingCar={wholeSellingCar}
                    orderPurchaseMode={orderPurchaseMode}
                    selectedCarData={selectedCarData}
                    totalPrice={totalPrice}
                  />
                </motion.div>
              )}
            </AnimatePresence>

            <AnimatePresence>
              {currentTab === 'submit' && (
                <motion.div
                  className='flex-auto w-full flex flex-col'
                  initial={{ opacity: 0, y: 10 }}
                  animate={{ opacity: 1, y: 0, visibility: 'visible' }}
                  transition={{ duration: 0.3 }}
                >
                  <OrderPurchase_Submit
                    form={form}
                    wholeSellingCar={wholeSellingCar}
                    selectedCarData={selectedCarData}
                    totalPrice={totalPrice}
                  />
                </motion.div>
              )}
            </AnimatePresence>

            {currentTab === 'brand' && (
              <LeaseRentNotice orderPurchaseMode={orderPurchaseMode} />
            )}
          </>
        )}
      </div>

      {/* Footer Part (Button) */}
      {wholeSellingCar && (
        <div
          className={cn(
            'w-full bg-white/50 px-5 pb-2.5 pt-5 flex items-center justify-center gap-[7px]',
            currentTab === 'model' && 'bg-[#F3F6F9]',
          )}
        >
          <OrderPurchase_Button
            form={form}
            wholeSellingCar={wholeSellingCar}
            selectedCarData={selectedCarData}
            totalPrice={totalPrice}
            orderPurchaseTab={currentTab}
            goToNextTab={goToNextTab}
            goToPrevTab={goToPrevTab}
          />
        </div>
      )}

      <ConfirmV2
        title={'진행중인 상담 중단'}
        desc={`상담을 중단하면 진행중인 내용이 사라져요.
정말 중단하시겠습니까?`}
        confirmText={'중단'}
        cancelText={'취소'}
        confirmColor={
          'text-[#222] text-center text-[16px] font-medium leading-[15px] tracking-[-0.3px]'
        }
        isCloseButton={true}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
        onConfirm={() => {
          navigate('/main');
        }}
      />

      <V3_Confirm
        onClose={() => {
          navigate('/main');
        }}
        onConfirm={() => {
          navigate('/order/payment/avaliableOrder?type=purchase');
        }}
        isOpen={isPurchaseConfirmOpen}
        setIsOpen={setIsPurchaseConfirmOpen}
        disableBackdropClose={true}
        title={''}
        agreeText={'견적 요청권 구매'}
        disagreeText='취소'
        className='!pb-[40px] !pt-[10px]'
      >
        <div className='flex flex-col items-center justify-center gap-5'>
          <div className='flex flex-col items-center justify-center gap-1.5'>
            <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
              차량 삭제
            </div>
            <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
              보유하신 견적 요청권을 모두 사용했어요.
              <br />
              구매 후 견적을 요청해 보세요.
            </div>
          </div>
        </div>
      </V3_Confirm>

      <MisteryShopperDrawer
        isOpen={isServiceNoticeDrawerOpen}
        setIsOpen={setIsServiceNoticeDrawerOpen}
      />
    </div>
  );
};

export default OrderPurchasePage;
