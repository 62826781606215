/* eslint-disable @typescript-eslint/no-unused-vars */
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { ChevronRight } from 'lucide-react';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';

export default function DealerOrderCountPanal() {
  const { loginMember } = useMemberStore();
  const navigate = useNavigate();

  if (!loginMember) return null;

  const orderCounts = useMemo(() => {
    return {
      canceled: loginMember.dealerInfo?.count.order?.canceled ?? 0,
      holded: loginMember.dealerInfo?.count.order?.holded ?? 0,
      failBid: loginMember.dealerInfo?.count.order?.failBid ?? 0,
      finished: loginMember.dealerInfo?.count.order?.finished ?? 0,
      progressing: loginMember.dealerInfo?.count.order?.progressing ?? 0,
      bidding: loginMember.dealerInfo?.count.order?.bidding ?? 0,
    };
  }, [loginMember.dealerInfo?.count.order]);

  return (
    <div className='px-5 space-y-4'>
      <div className='flex justify-between items-center'>
        <div className='text-[#222] text-[18px] font-semibold leading-[27px] tracking-[-0.36px]'>
          진행 현황
        </div>
        <button
          onClick={() => {
            navigate('/dealer/quote');
          }}
        >
          <ChevronRight width={24} height={24} color='#666' strokeWidth={1.5} />
        </button>
      </div>
      <div className='flex flex-col gap-2.5'>
        <div className='py-2.5 rounded-[10px] border-[1.5px] border-[#7273F9] bg-white'>
          <div className='grid grid-cols-3 divide-x divide-[#B2B7BB]'>
            <button
              onClick={() => {
                navigate('/dealer/quote?tab_myOrder=my_bidding');
              }}
              className='flex flex-col gap-1 items-center justify-center py-3.5'
            >
              <div className='text-[#B2B7BB] text-center font-semibold text-[20px] leading-[20px] tracking-[-0.4px]'>
                {orderCounts.bidding}
              </div>
              <div className='text-[#222] text-center text-[13px] font-normal leading-[16.9px] tracking-[-0.26px]'>
                입찰 중
              </div>
            </button>
            <button
              onClick={() => {
                navigate('/dealer/quote?tab_myOrder=my_progressing');
              }}
              className='flex flex-col gap-1 items-center justify-center py-3.5'
            >
              <div className='text-[#B2B7BB] text-center font-semibold text-[20px] leading-[20px] tracking-[-0.4px]'>
                {orderCounts.progressing}
              </div>
              <div className='text-[#222] text-center text-[13px] font-normal leading-[16.9px] tracking-[-0.26px]'>
                낙찰 후 진행 중
              </div>
            </button>
            <button
              onClick={() => {
                navigate('/dealer/quote?tab_myOrder=my_finished');
              }}
              className='flex flex-col gap-1 items-center justify-center py-3.5'
            >
              <div className='text-[#B2B7BB] text-center font-semibold text-[20px] leading-[20px] tracking-[-0.4px]'>
                {orderCounts.finished}
              </div>
              <div className='text-[#222] text-center text-[13px] font-normal leading-[16.9px] tracking-[-0.26px]'>
                계약(주문) 완료
              </div>
            </button>
          </div>
        </div>
        <div className='grid grid-cols-3 gap-2.5'>
          <button
            onClick={() => {
              navigate('/dealer/quote?tab_myOrder=my_canceled');
            }}
            className='rounded-[10px] bg-[#E8ECF0] px-3 py-3.5 flex items-center justify-between'
          >
            <div className='text-[#222] text-[13px] font-normal leading-[13px] tracking-[-0.26px]'>
              취소
            </div>
            <div className='text-[#B2B7BB] text-right text-[14px] font-semibold leading-[14px] tracking-[-0.28px]'>
              {orderCounts.canceled}
            </div>
          </button>
          <button
            onClick={() => {
              navigate('/dealer/quote?tab_myOrder=my_holded');
            }}
            className='rounded-[10px] bg-[#E8ECF0] px-3 py-3.5 flex items-center justify-between'
          >
            <div className='text-[#222] text-[13px] font-normal leading-[13px] tracking-[-0.26px]'>
              보류
            </div>
            <div className='text-[#B2B7BB] text-right text-[14px] font-semibold leading-[14px] tracking-[-0.28px]'>
              {orderCounts.holded}
            </div>
          </button>
          <button
            onClick={() => {
              navigate('/dealer/quote?tab_myOrder=my_failBid');
            }}
            className='rounded-[10px] bg-[#E8ECF0] px-3 py-3.5 flex items-center justify-between'
          >
            <div className='text-[#222] text-[13px] font-normal leading-[13px] tracking-[-0.26px]'>
              유찰
            </div>
            <div className='text-[#B2B7BB] text-right text-[14px] font-semibold leading-[14px] tracking-[-0.28px]'>
              {orderCounts.failBid}
            </div>
          </button>
        </div>
      </div>
    </div>
  );
}
