/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { X } from 'lucide-react';
import { SelectColorProps } from '../type';
import { useMemo } from 'react';
import { CarColorInternalSelection } from '@carsayo/types';
import ColorSquare from '../components/ColorSquare';
import { cn } from 'utils';

interface SelectInternalSelectionDrawerProps extends SelectColorProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}

export default function SelectInternalSelectionDrawer({
  form,
  wholeSellingCar,
  selectedExteriorColor,
  selectedInteriorColor,
  sortedCarColor,
  isOpen,
  setIsOpen,
}: SelectInternalSelectionDrawerProps) {
  const internalSelectionList: CarColorInternalSelection[] = useMemo(() => {
    return selectedInteriorColor?.internalSelection ?? [];
  }, [selectedInteriorColor]);

  return (
    <Drawer
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) setIsOpen(false);
      }}
    >
      {
        <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
          <DrawerHeader className='relative h-[60px]'>
            <DrawerTitle>내장 가니쉬 선택</DrawerTitle>
            <DrawerClose asChild>
              <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
                <X className='h-7 w-7' />
              </Button>
            </DrawerClose>
          </DrawerHeader>

          <div className='px-5 pb-5 overflow-y-auto'>
            {internalSelectionList.map((data, index) => {
              const isSelected =
                data.name === selectedInteriorColor?.name &&
                data.price === selectedInteriorColor?.price;

              return (
                <button
                  key={`${data.name}-${data.price}`}
                  className={cn(
                    'flex w-full gap-4 items-center justify-start',
                    'px-4 py-[15px] rounded-[8px]',
                    isSelected ? 'bg-[#F3F6F9]' : 'bg-white',
                  )}
                  onClick={() => {
                    form.setValue('interiorCarColor.internalSelection', {
                      name: data.name,
                      price: data.price,
                      imageUrl: data.imageUrl,
                    });

                    setIsOpen(false);
                  }}
                >
                  <ColorSquare imageUrl={data.imageUrl ?? undefined} />
                  <div className='flex flex-col gap-1.5 h-[44px] items-start justify-center'>
                    <div className='text-[#222] text-[16px] font-semibold leading-[18px] tracking-[-0.32px] text-left'>
                      {data.name}
                    </div>
                    {data.price !== 0 && (
                      <div className='text-[#222] text-[14px] font-medium leading-[14px] tracking-[-0.28px]'>
                        {`${data.price > 0 ? '+' : ''}${data.price.toLocaleString()}`}
                        원
                      </div>
                    )}
                  </div>
                </button>
              );
            })}

            {/* Category divider */}
            <div className='flex flex-col px-5 mt-2.5'></div>
          </div>
        </DrawerContent>
      }
    </Drawer>
  );
}
