/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { Divider } from '@mui/material';
import { addHistoryInsuranceCooconClick } from 'apis/history';
import { Check } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { cooconUrl } from 'utils/cooconUrl';

export default function MainInsuranceSection() {
  const navigate = useNavigate();
  const handleClickEvents = {
    openCooconInsurance: () => {
      addHistoryInsuranceCooconClick({
        from: `app${location.pathname}`,
        target: '쿠콘',
      });

      window.open(cooconUrl());
    },
    moveToInsurance: () => {
      navigate('/static/insurance');
    },
  };

  return (
    <div>
      <strong className='inline-block text-[22px] leading-[33px] font-[600] max-w-[262px] break-keep mb-6'>
        카사요니깐, 비교에 혜택을 더한 자동차 보험 가입하기
      </strong>

      <div className='flex flex-col gap-4 rounded-[10px] bg-[#FFE3F0] p-4'>
        <div className='flex items-center gap-2 text-[15px] font-[600]'>
          <img
            src='/assets/images/insurance/exc_icon.png'
            className='h-6 w-6'
            alt='보험사'
          />
          꼭 확인하세요
        </div>
        <div className='flex flex-col gap-2'>
          {[
            {
              text: '[STEP1 : 보험료 조회 및 비교]',
              subText: '[STEP2 : 직접 가입하기]',
              additionalText: '보다 보험료가 조금 높습니다.',
            },
            {
              text: '[STEP1 : 보험료 조회 및 비교]',
              subText: '[STEP2 : 직접 가입하기]',
              additionalText:
                '의 보험사를 직접 선택하시어 국내 최저가 다이렉트 자동차 보험을 계약하세요.',
            },
          ].map(({ text, subText, additionalText }, index) => (
            <div
              key={index}
              className='flex items-start gap-3 text-[13px] font-[500] leading-[150%]'
            >
              <div className='mt-[1px] h-4 w-4'>
                <Check
                  width={16}
                  height={16}
                  strokeWidth={1.33}
                  color='#FF257C'
                />
              </div>
              <div>
                <span className='font-[700]'>{text}</span>
                {index === 0 ? '는' : '에서 비교만 하시고'}{' '}
                <span className='font-[700] text-[#FF257C]'>{subText}</span>
                <span
                  dangerouslySetInnerHTML={{
                    __html: additionalText,
                  }}
                ></span>
              </div>
            </div>
          ))}
        </div>
      </div>

      <div className='flex flex-col justify-start items-start mt-4'>
        {/* Section 1 */}
        <div className='bg-[#F3F6F9] rounded-t-[10px] w-full'>
          <div className='flex items-center gap-[12px] px-5 py-4'>
            <div className='rounded-[8px] bg-[#E8ECF0] px-[8px] py-[7px] text-sm font-medium leading-[14px]'>
              STEP 1
            </div>
            <div className='text-lg font-[500] leading-[25.2px]'>
              보험료 조회 및 비교
            </div>
          </div>
          <div
            onClick={handleClickEvents.openCooconInsurance}
            className='relative m-5 my-0 flex flex-col max-h-[335px] rounded-[16px] bg-white p-5 tiny:p-4'
          >
            <div className='w-full flex justify-end wide:absolute wide:top-5 wide:right-5 mb-4'>
              <img
                className='w-[128px] h-[32px] wide:w-[164px] wide:h-[44px]'
                src='/assets/images/insurance/direct_insurances_1.png'
              />
            </div>

            <div className='flex flex-col gap-2.5 wide:gap-[36px]'>
              <div className='text-[#222] text-[18px] font-semibold leading-[27px] tracking-[-0.36px]'>
                <div>다이렉트 자동차보험 7개 보험사</div>
                <div className='flex items-center justify-start gap-1'>
                  <div className='text-[#0079FC]'>한 번에 비교하기</div>
                  <div>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                    >
                      <g clipPath='url(#clip0_2539_13771)'>
                        <path
                          d='M6.66406 10.8333V3.75C6.66406 3.41848 6.79576 3.10054 7.03018 2.86612C7.2646 2.6317 7.58254 2.5 7.91406 2.5C8.24558 2.5 8.56353 2.6317 8.79795 2.86612C9.03237 3.10054 9.16406 3.41848 9.16406 3.75V10'
                          stroke='#0079FC'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M9.16406 9.58366V7.91699C9.16406 7.58547 9.29576 7.26753 9.53018 7.03311C9.7646 6.79869 10.0825 6.66699 10.4141 6.66699C10.7456 6.66699 11.0635 6.79869 11.2979 7.03311C11.5324 7.26753 11.6641 7.58547 11.6641 7.91699V10.0003'
                          stroke='#0079FC'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M11.6641 8.75C11.6641 8.41848 11.7958 8.10054 12.0302 7.86612C12.2646 7.6317 12.5825 7.5 12.9141 7.5C13.2456 7.5 13.5635 7.6317 13.7979 7.86612C14.0324 8.10054 14.1641 8.41848 14.1641 8.75V10'
                          stroke='#0079FC'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M14.1658 9.58301C14.1658 9.25149 14.2975 8.93354 14.532 8.69912C14.7664 8.4647 15.0843 8.33301 15.4158 8.33301C15.7474 8.33301 16.0653 8.4647 16.2997 8.69912C16.5341 8.93354 16.6658 9.25149 16.6658 9.58301V13.333C16.6658 14.6591 16.1391 15.9309 15.2014 16.8685C14.2637 17.8062 12.9919 18.333 11.6658 18.333H9.99918H10.1725C9.34446 18.3331 8.52933 18.1276 7.80033 17.7349C7.07133 17.3422 6.45127 16.7746 5.99584 16.083L5.83251 15.833C5.57251 15.4338 4.66001 13.843 3.09418 11.0597C2.93455 10.776 2.89191 10.4411 2.97533 10.1265C3.05874 9.81179 3.26163 9.54207 3.54084 9.37467C3.83824 9.19623 4.18674 9.12227 4.53098 9.16455C4.87522 9.20682 5.19546 9.36291 5.44084 9.60801L6.66584 10.833'
                          stroke='#0079FC'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M4.16927 2.50033L3.33594 1.66699'
                          stroke='#0079FC'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M3.33333 5.83301H2.5'
                          stroke='#0079FC'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M11.6641 2.50033L12.4974 1.66699'
                          stroke='#0079FC'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M12.5 5H13.3333'
                          stroke='#0079FC'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_2539_13771'>
                          <rect width='20' height='20' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>

              <div className='flex flex-col justify-between gap-[10px]'>
                <div className='flex flex-wrap items-center justify-between'>
                  <div className='text-xs font-[400] leading-[16.5px] text-[#555] tiny:text-[11px]'>
                    손해보험협회 심의필 제129622호
                    <br />
                    (2024.08.01 ~ 2025.07.31)
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className='p-4'>
            <button
              type='button'
              className='rounded-[8px] bg-[#222222] px-4 py-[13px] flex justify-center items-center w-full text-white font-semibold text-[15px] leading-[22.5px] tracking-[-0.3px]'
              onClick={handleClickEvents.openCooconInsurance}
            >
              비교하고 나오기
            </button>
          </div>
        </div>

        {/* Divider */}
        <Divider
          sx={{ width: '100%', height: '1px', backgroundColor: '#E5E5EC' }}
        />

        {/* Section 2 */}
        <div className='bg-[#F3F6F9] rounded-b-[10px] w-full'>
          <div className='flex items-center gap-[12px] px-5 py-4'>
            <div className='rounded-[8px] text-[#FF257C] bg-[#FFE3F0] px-[8px] py-[7px] text-sm font-medium leading-[14px]'>
              STEP 2
            </div>
            <div className='text-[#FF257C] font-bold text-[18px] leading-[25.2px] tracking-[-0.45px] '>
              직접 가입하기 <br className='hidden tiny:block' />
              <span className='font-bold text-[14px] leading-[19.6px] tracking-[-0.35px]'>
                (국내 최저 보험료)
              </span>
            </div>
          </div>
          <div
            onClick={handleClickEvents.moveToInsurance}
            className='relative m-5 my-0 flex flex-col max-h-[335px] rounded-[16px] bg-white p-5 tiny:p-4'
          >
            <img
              src='/assets/images/png/main_insurance_bg02.png'
              alt=''
              className='relative z-[1] w-full object-contain mb-[15px] max-h-[335px]'
            />
            <div className='w-full bg-[#E2E4F5] h-[56px] absolute z-0 bottom-0 left-0'></div>
          </div>

          <div className='p-4'>
            <button
              onClick={handleClickEvents.moveToInsurance}
              className='whitespace-nowrap text-[15px] leading-[22.5px] text-white font-[600] w-full flex justify-center items-center p-[13px] bg-[#7273F9] rounded-[8px] h-[50px]'
            >
              카사요를 통해 최저가 보험 가입해 보세요
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
