/* eslint-disable @typescript-eslint/no-unused-vars */
import { DealerOrderDetailProps } from 'pages/v3/orderDetailTemp/type';

import NoDataTab from '../item/NoDataTab';
import { FileInfo, OrderProcessCard } from '@carsayo/types';
import dayjs from 'dayjs';
import { Fragment, useMemo, useState } from 'react';
import FilePreviewer from 'components/common/v3/fileInput/FilePreviewer';
import V3_Button from 'components_v3/ui/Button';
import DealerOrderDetailFinishSubpage from '../../../subpage/finish';
import { useNavigate } from 'react-router-dom';
import DealerOrderDatePicker from 'pages/v3/orderDetailTemp/ui/DatePicker';

/** 특수한 케이스 알림
 * @case 주문 종료 후, 수수료 정산하기 기능 활성화 시 카키 "수수료를 정산해 주세요"
 * @case 신차/리스렌트: 견적서 낙찰 후 카키 "진행 방법을 알려드려요." (케이스 다름)
 * @case 내차팔기: 견적서 낙찰 후 카키 "진행 방법을 알려드려요." (케이스 다름)
 */
function SpecialCaseProcessCard({ orderDetail }: DealerOrderDetailProps) {
  const navigate = useNavigate();

  const [isFinishSubpageOpen, setIsFinishSubpageOpen] = useState(false);
  const [isMeetingDatePickerOpen, setIsMeetingDatePickerOpen] = useState(false);

  /** 내차팔기일 경우 */
  if (orderDetail.state === 'progressing' && orderDetail.type === 'sellCar') {
    return (
      <div className='bg-[#DDEAE4] rounded-[16px] p-4'>
        <div className='pb-4 border-b border-[#C8D9D1] flex items-center'>
          {/* 카키 */}
          <div className='flex items-center justify-center w-[36px] h-[36px] rounded-full bg-[#E3E7EA] text-[#222] text-center text-[14px] font-semibold leading-[14px] tracking-[-0.28px]'>
            <img src='/assets/images/v2/chat-carkey-icon.png' alt='카키' />
          </div>
          <div className='ml-2 text-[#222] text-[15px] font-semibold leading-[15px] tracking-[-0.3px] border-r border-[#C8D9D1] pr-3.5'>
            카키
          </div>

          {/* 제목 */}
          <div className='ml-2.5 text-[#222] text-[16px] font-semibold leading-[16px] tracking-[-0.32px]'>
            진행 방법을 알려드려요.
          </div>
        </div>

        {/* Content */}
        <div className='mt-4 flex flex-col gap-4'>
          <div className='overflow-hidden break-keep text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
            첫 번째,{' '}
            <span className='text-[#2A7450] font-semibold'>
              즉시(24시간 이내) 고객님께 연락
            </span>
            하여 정확한 내용 기반으로 계약을 진행해 주세요.
          </div>

          {/* 고객님과 통화 버튼 */}
          <V3_Button
            className='!h-[50px]'
            theme='gray'
            onClick={() => {
              window.location.href = `tel:${orderDetail.member.phoneNumber}`;
            }}
          >
            고객님과 통화
          </V3_Button>

          <div className='overflow-hidden break-keep text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
            두 번째, 중고차 매입은 고객님과 대면 미팅이 필수입니다.{' '}
            <span className='text-[#2A7450] font-semibold'>
              미팅 일정을 등록
            </span>
            해 주세요.
          </div>

          <V3_Button
            className='!h-[50px]'
            theme={orderDetail.meeting_date ? 'gray' : 'primary'}
            onClick={() => {
              setIsMeetingDatePickerOpen(true);
            }}
          >
            {orderDetail.meeting_date ? '미팅 일정 수정' : '미팅 일정 등록'}
          </V3_Button>

          {/** 미팅 일정 등록 후에만 이전 등록증 파트 노출 */}
          {orderDetail.meeting_date && (
            <>
              <div className='overflow-hidden break-keep text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
                세 번째, 거래 체결 후{' '}
                <span className='text-[#2A7450] font-semibold'>
                  24시간 이내 이전 등록증을 업로드
                </span>{' '}
                하면 계약이 완료돼요.
              </div>

              <V3_Button
                className='!h-[50px]'
                theme='primary'
                onClick={() => {
                  setIsFinishSubpageOpen(true);
                }}
              >
                이전 등록증 업로드
              </V3_Button>
            </>
          )}
        </div>

        {/* 미팅 일정 등록 */}
        <DealerOrderDatePicker
          open={isMeetingDatePickerOpen}
          setOpen={setIsMeetingDatePickerOpen}
          orderDetail={orderDetail}
        />

        {/* 주문완료 서브페이지 */}
        <DealerOrderDetailFinishSubpage
          orderListData={orderDetail}
          isOpen={isFinishSubpageOpen}
          setIsOpen={setIsFinishSubpageOpen}
        />
      </div>
    );
  }

  /** 신차, 리스, 장기렌트일 경우 */
  if (orderDetail.state === 'progressing' && orderDetail.type !== 'sellCar') {
    return (
      <div className='bg-[#DDEAE4] rounded-[16px] p-4'>
        <div className='pb-4 border-b border-[#C8D9D1] flex items-center'>
          {/* 카키 */}
          <div className='flex items-center justify-center w-[36px] h-[36px] rounded-full bg-[#E3E7EA] text-[#222] text-center text-[14px] font-semibold leading-[14px] tracking-[-0.28px]'>
            <img src='/assets/images/v2/chat-carkey-icon.png' alt='카키' />
          </div>
          <div className='ml-2 text-[#222] text-[15px] font-semibold leading-[15px] tracking-[-0.3px] border-r border-[#C8D9D1] pr-3.5'>
            카키
          </div>

          {/* 제목 */}
          <div className='ml-2.5 text-[#222] text-[16px] font-semibold leading-[16px] tracking-[-0.32px]'>
            진행 방법을 알려드려요.
          </div>
        </div>

        {/* Content */}
        <div className='mt-4 flex flex-col gap-4'>
          <div className='overflow-hidden break-keep text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
            첫 번째,{' '}
            <span className='text-[#2A7450] font-semibold'>
              즉시(24시간 이내) 고객님께 연락
            </span>
            하여 정확한 내용 기반으로 계약을 진행해 주세요.
          </div>

          {/* 고객님과 통화 버튼 */}
          <V3_Button
            className='!h-[50px]'
            theme='gray'
            onClick={() => {
              window.location.href = `tel:${orderDetail.member.phoneNumber}`;
            }}
          >
            고객님과 통화
          </V3_Button>

          <div className='overflow-hidden break-keep text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
            두 번째, 거래 체결 후{' '}
            <span className='text-[#2A7450] font-semibold'>
              24시간 이내 계약서를 업로드
            </span>{' '}
            하면 계약이 완료돼요.
          </div>

          <V3_Button
            className='!h-[50px]'
            theme='primary'
            onClick={() => {
              setIsFinishSubpageOpen(true);
            }}
          >
            계약서 업로드
          </V3_Button>
        </div>

        {/* 주문완료 서브페이지 */}
        <DealerOrderDetailFinishSubpage
          orderListData={orderDetail}
          isOpen={isFinishSubpageOpen}
          setIsOpen={setIsFinishSubpageOpen}
        />
      </div>
    );
  }

  // 수수료 정산 안내
  if (orderDetail.state === 'finished' && orderDetail.commission) {
    return (
      <div className='bg-[#DDEAE4] rounded-[16px] p-4'>
        <div className='pb-4 border-b border-[#C8D9D1] flex items-center'>
          {/* 카키 */}
          <div className='flex items-center justify-center w-[36px] h-[36px] rounded-full bg-[#E3E7EA] text-[#222] text-center text-[14px] font-semibold leading-[14px] tracking-[-0.28px]'>
            <img src='/assets/images/v2/chat-carkey-icon.png' alt='카키' />
          </div>
          <div className='ml-2 text-[#222] text-[15px] font-semibold leading-[15px] tracking-[-0.3px] border-r border-[#C8D9D1] pr-3.5'>
            카키
          </div>

          {/* 제목 */}
          <div className='ml-2.5 text-[#222] text-[16px] font-semibold leading-[16px] tracking-[-0.32px]'>
            수수료를 정산해 주세요.
          </div>
        </div>

        {/* Content */}
        <div className='mt-4 flex flex-col gap-4'>
          <div className='overflow-hidden break-keep text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
            주문완료되어 48시간 내 카사요(주) 계좌에 수수료를 입금해 주세요.
            최초 견적가 기준으로 매매정보 제공 수수료를 받습니다.
          </div>
          <>
            {orderDetail.commission.state === 'waiting' && (
              <V3_Button className='!h-[50px]' disabled={true}>
                수수료 산정중
              </V3_Button>
            )}
            {orderDetail.commission.state === 'sended' && (
              <V3_Button
                className='!h-[50px] !text-[#494AF1] !bg-[#F0F0FD]'
                theme='gray'
                onClick={() => {
                  navigate('/mypage/commission');
                }}
              >
                수수료 정산
              </V3_Button>
            )}
            {(orderDetail.commission.state === 'paid' ||
              orderDetail.commission.state === 'canceled') && (
              <V3_Button
                className='!h-[50px] !bg-[#F3F6F9] !text-[#999]'
                theme='gray'
                disabled={true}
                onClick={() => {
                  navigate('/mypage/commission');
                }}
              >
                수수료 정산 완료
              </V3_Button>
            )}
          </>
        </div>
      </div>
    );
  }

  return null;

  return <div>123</div>;
}

function OrderProcessCardElement({
  orderProcessCard,
  reverseIndex,
}: {
  orderProcessCard: OrderProcessCard;
  // 거꾸로한 index
  reverseIndex: number;
}) {
  const additionalInfo: {
    title: string;
    content: string;
  }[] = useMemo(() => {
    const returnArray: {
      title: string;
      content: string;
    }[] = [];

    if (orderProcessCard.additionalInfo_1)
      returnArray.push(orderProcessCard.additionalInfo_1);
    if (orderProcessCard.additionalInfo_2)
      returnArray.push(orderProcessCard.additionalInfo_2);
    if (orderProcessCard.additionalInfo_3)
      returnArray.push(orderProcessCard.additionalInfo_3);

    return returnArray;
  }, [orderProcessCard]);

  const additionalFileInfo: FileInfo[] = useMemo(() => {
    const returnArray: FileInfo[] = [];

    if (orderProcessCard.file_1) returnArray.push(orderProcessCard.file_1);
    if (orderProcessCard.file_2) returnArray.push(orderProcessCard.file_2);
    if (orderProcessCard.file_3) returnArray.push(orderProcessCard.file_3);

    return returnArray;
  }, [orderProcessCard]);

  const tag: {
    title: string;
    bg: string;
    color: string;
  } | null = useMemo(() => {
    if (orderProcessCard.state === 'accepted')
      return {
        title: '낙찰',
        bg: '#E0F3EB',
        color: '#009E59',
      };

    return null;
  }, [orderProcessCard]);

  return (
    <div className='bg-white rounded-[16px] p-4'>
      {/* Head */}
      <div className='pb-4 border-b border-[#E5EAEE] flex items-center'>
        {/* 번호 */}
        <div className='flex items-center justify-center w-[32px] h-[32px] rounded-full bg-[#E3E7EA] text-[#222] text-center text-[14px] font-semibold leading-[14px] tracking-[-0.28px]'>
          {reverseIndex + 1}
        </div>
        {/* 제목 */}
        <div className='ml-2.5 text-[#222] text-[16px] font-semibold leading-[16px] tracking-[-0.32px]'>
          {orderProcessCard.title}
        </div>
        {/* 태그
        @note 특수 케이스에만 적용
         */}
        {tag && (
          <div
            className='ml-1.5 rounded-[6px] py-0.5 px-1.5'
            style={{ backgroundColor: tag.bg, color: tag.color }}
          >
            <span className='text-[13px] font-medium leading-[19.5px] tracking-[-0.26px]'>
              {tag.title}
            </span>
          </div>
        )}
      </div>

      {/* Body */}
      <div className='mt-5 mb-1'>
        {/* 콘텐츠 내용 */}
        <div className='text-[#222] text-[14px] font-medium leading-[21px] tracking-[-0.28px] break-keep whitespace-pre-line'>
          {orderProcessCard.content}
        </div>
        {/* 생성일자 */}
        <div className='mt-[13px] text-[#494AF1] text-[14px] font-medium leading-[14px] tracking-[-0.28px]'>
          {dayjs(orderProcessCard.created_at).format('YY.MM.DD HH:mm')}
        </div>
      </div>

      {/* Additional Info */}
      {(additionalInfo.length > 0 || additionalFileInfo.length > 0) && (
        <div className='mt-5 mb-1 space-y-3'>
          {/* additionalInfo */}
          {additionalInfo.map((el, index) => {
            return (
              <div
                key={el.title + index}
                className='rounded-[8px] border-[1px] border-[#E5E5EC] bg-white flex justify-between items-center px-4 py-3'
              >
                <div className='flex-none text-[#222] text-[14px] font-medium leading-[19.6px] tracking-[-0.28px]'>
                  {el.title}
                </div>
                <div className='text-right text-[#222] text-[16px] font-semibold leading-[22.4px] tracking-[-0.32px]'>
                  {el.content}
                </div>
              </div>
            );
          })}

          {/* additionalFileInfo */}
          {additionalFileInfo.map((el, index) => {
            const [isPreviewFileOpen, setIsPreviewFileOpen] = useState(false);

            return (
              <Fragment key={el.id}>
                <button
                  onClick={() => {
                    setIsPreviewFileOpen(true);
                  }}
                  className='w-full bg-[#F3F6F9] rounded-[8px] px-[14px] py-[18px] h-[50px] flex items-center justify-center'
                >
                  <span className='text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.32px]'>
                    첨부파일 보기
                  </span>
                </button>

                <FilePreviewer
                  isOpen={isPreviewFileOpen}
                  setIsOpen={setIsPreviewFileOpen}
                  fileInfo={el}
                />
              </Fragment>
            );
          })}
        </div>
      )}
    </div>
  );
}

/** 견적 정보 탭
 * @note processCardList를 사용합니다
 */
export default function DealerOrderDetailBidInfo({
  orderDetail,
}: DealerOrderDetailProps) {
  return (
    <div className='relative flex-auto bg-[#F3F6F9] px-5 pt-6 pb-[50px] space-y-6'>
      {/* 견적 정보가 없을 떄 */}
      {orderDetail.processCardList.length === 0 && (
        <div className='absolute top-0 left-0 py-5 w-full h-full flex items-center justify-center'>
          <NoDataTab
            title='견적 정보가 없어요.'
            content='낙찰되면 계약을 진행할 수 있어요.'
          />
        </div>
      )}

      {/* 견적 정보가 있을 떄 */}
      {orderDetail.processCardList.length > 0 && (
        <div className='flex flex-col gap-5'>
          <SpecialCaseProcessCard orderDetail={orderDetail} />

          {/* 그 외 진행 내역 */}
          {orderDetail.processCardList.map((el, index) => {
            return (
              <OrderProcessCardElement
                key={orderDetail.processCardList.length - index - 1}
                orderProcessCard={el}
                reverseIndex={orderDetail.processCardList.length - index - 1}
              />
            );
          })}
        </div>
      )}
    </div>
  );
}
