import SubPage from 'components/view/SubPage';
import { ChevronLeft } from 'lucide-react';
import Button from 'components_v3/ui/Button';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { getTerm } from 'apis/setting';

export default function ServiceTermSubpage({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const navigate = useNavigate();

  const { data: TermData } = useQuery({
    queryKey: ['termDetail', 10100],
    queryFn: async () => {
      const termData = await getTerm({
        termCategoryId: 10100,
      });
      return termData;
    },
  });

  return (
    <SubPage isOpen={open} setIsOpen={setOpen}>
      <div className='h-full flex flex-col justify-between overflow-y-auto'>
        <div>
          <div className='sticky top-0 z-10 flex flex-none h-14 items-center justify-between bg-white px-4'>
            <div
              className='cursor-pointer'
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeft className='w-8 h-8' strokeWidth={1.5} />
            </div>
            <div className='pr-8 grow text-center text-xl font-semibold'>
              서비스 이용약관
            </div>
          </div>

          {/* 견적 요청권 안내 */}
          <div className='px-5'>
            <div
              className='text-[#222] text-[16px] font-medium leading-[25.6px] tracking-[-0.32px] whitespace-pre-wrap'
              dangerouslySetInnerHTML={{
                __html: TermData?.content_html ?? '',
              }}
            ></div>
          </div>
        </div>

        <div className='px-5 flex justify-center items-center gap-[7px] pb-[10px]'>
          <Button onClick={() => navigate(-1)}>확인</Button>
        </div>
      </div>
    </SubPage>
  );
}
