/* eslint-disable @typescript-eslint/no-unused-vars */
import RemainTime from '../RemainTime';
import useSystemStore from 'store/useSystemStore';
import ConfirmV2 from 'components/common/v3/confirm';
import { useState } from 'react';
import { customerDeleteQuoteApi, customerReStartQuoteApi } from 'apis/quote';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { CustomerOrderDetail } from '@carsayo/types';
import {
  convertOrderStatus,
  convertOrderStatusStyle,
} from 'pages/v3/quote/utils/convert';
import { cn } from 'utils';
import { useNavigate } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';
import DealerInfoCard from '../DealerInfoCard';
import dayjs from 'dayjs';
import V3_Confirm from 'components_v3/dialog/Confirm';

type openType = 'delete' | 'holded' | 'newDelete';

const NewCarTopInfo = ({
  quoteDetail,
  refetch,
}: {
  quoteDetail: CustomerOrderDetail;
  refetch: () => void;
}) => {
  const { setLoadingDim } = useSystemStore();
  const [isConfirmOpen, setIsConfirmOpen] = useState({
    delete: false,
    holded: false,
    newDelete: false,
  });

  const navigate = useNavigate();
  const { loginMember } = useMemberStore();

  const handleOpen = (type: openType) => {
    setIsConfirmOpen((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const renderOrderState = () => {
    switch (quoteDetail.state) {
      case 'submitted':
        return quoteDetail?.bidList.length > 0
          ? '가장 마음에 드는 견적을 선택해 주세요.'
          : '카 매니저에게 견적을 받고 있어요.';

      case 'progressing':
        return quoteDetail.restarted_at ? (
          <>
            재진행되어 카 매니저님과의
            <br />
            출고 여정이 다시 시작됩니다.
          </>
        ) : quoteDetail.meeting_date ? (
          <>
            카 매니저님이 차량 확인 및
            <br />
            이전 등록을 진행할 예정입니다.
          </>
        ) : (
          '카 매니저님이 곧 연락드릴 예정입니다.'
        );

      case 'holded':
        return (
          <>
            카 매니저님과의 출고 여정이
            <br />
            보류되었습니다.
          </>
        );
      case 'canceled':
        return (
          <>
            카 매니저님과의 출고 여정이
            <br />
            취소되었습니다.
          </>
        );

      case 'finished':
        return (
          <>
            카 매니저님과의 출고 여정이
            <br />
            종료되었습니다.
          </>
        );

      case 'nonAccepted': // 유찰과 같은 경우
        return (
          <>
            {quoteDetail?.bidList.length > 0 ? (
              <>
                등록된 카 매니저의 견적서를
                <br />
                선택하지 않아 종료되었습니다.
              </>
            ) : (
              <>
                48시간동안 카 매니저에게
                <br />
                받은 견적이 없어 종료됐어요.
              </>
            )}
          </>
        );

      default:
        return null;
    }
  };

  const renderButtons = () => {
    if (quoteDetail.state === 'submitted') {
      return quoteDetail.bidList.length === 0 ? (
        <button
          onClick={() => handleOpen('newDelete')}
          className='bg-[#F3F6F9] rounded-[8px] py-[18px] text-center text-[0.9375rem] font-[500] leading-[100%] w-full'
        >
          견적 요청 취소
        </button>
      ) : null;
    }
    if (quoteDetail.state === 'progressing') {
      return (
        <div className='flex justify-between gap-[7px]'>
          <button
            onClick={() =>
              navigate(`/quote/reason/cancel/${quoteDetail.id}?type=newCar`)
            }
            className='bg-[#F3F6F9] rounded-[8px] py-[18px] text-center text-[0.9375rem] font-[500] leading-[100%] w-full'
          >
            주문 취소
          </button>
          <button
            onClick={() =>
              navigate(`/quote/reason/hold/${quoteDetail.id}?type=newCar`)
            }
            className='bg-[#F3F6F9] rounded-[8px] py-[18px] text-center text-[0.9375rem] font-[500] leading-[100%] w-full'
          >
            주문 보류
          </button>
        </div>
      );
    }
    if (quoteDetail.state === 'finished') {
      return quoteDetail.review ? (
        <button
          onClick={() => navigate(`/mypage/review?status=written&filter=new`)}
          className='bg-[#F3F6F9] rounded-[8px] py-[18px] text-center text-[0.9375rem] font-[500] leading-[100%] w-full'
        >
          리뷰 보기
        </button>
      ) : (
        <button
          onClick={() =>
            navigate(`/quote/review/${quoteDetail.id}?type=newCar`)
          }
          className='bg-[#F0F0FD] text-[#484AF5] rounded-[8px] py-[18px] text-center text-[0.9375rem] font-[500] leading-[100%] w-full'
        >
          리뷰 작성
        </button>
      );
    }
    if (
      quoteDetail.state === 'holded' &&
      quoteDetail.lastUpdateMemberId === loginMember?.id
    ) {
      return (
        <div className='flex justify-between gap-[7px]'>
          <button
            onClick={() => navigate(`/quote/reason/cancel/${quoteDetail.id}`)}
            className='bg-[#F3F6F9] rounded-[8px] py-[18px] text-center text-[0.9375rem] font-[500] leading-[100%] w-full'
          >
            주문 취소
          </button>
          <button
            onClick={() => handleOpen('holded')}
            className='bg-[#F0F0FD] rounded-[8px] py-[18px] text-center text-[#484AF5] text-[0.9375rem] font-[500] leading-[100%] w-full'
          >
            재진행
          </button>
        </div>
      );
    }
    return null;
  };

  const handleClickEvents = {
    // 입찰 중 견적 삭제
    delete: async () => {
      if (!quoteDetail || !quoteDetail.id) return;
      setLoadingDim(true);
      try {
        await customerDeleteQuoteApi(quoteDetail.id);
        CarsayoToast.success('삭제했습니다.');
        refetch();
        handleOpen('delete');
      } catch (error: any) {
        CarsayoToast.error(error?.message);
      }
      setLoadingDim(false);
    },
    holded: async () => {
      if (!quoteDetail || !quoteDetail.id) return;
      setLoadingDim(true);
      try {
        await customerReStartQuoteApi(quoteDetail.id);
        CarsayoToast.success('주문이 재개되었습니다.');
        refetch();
        handleOpen('holded');
      } catch (error: any) {
        CarsayoToast.error(error?.message);
      }
      setLoadingDim(false);
    },
  };

  return (
    <div className='flex flex-col gap-6 px-5 mb-[30px]'>
      <div className='flex flex-col gap-4'>
        <div>
          {quoteDetail.state === 'submitted' && quoteDetail?.bid_ended_at && (
            <RemainTime
              time={
                dayjs(quoteDetail?.bid_ended_at).isBefore(dayjs())
                  ? dayjs(quoteDetail?.bid_ended_at).add(2, 'day').toDate()
                  : new Date(quoteDetail?.bid_ended_at)
              }
              // time={quoteDetail?.bid_ended_at}
            />
          )}

          {!['submitted', 'nonAccepted'].includes(quoteDetail.state) && (
            <span
              className={cn(
                'font-[600] text-[14px] leading-[100%] rounded-[6px] py-[7px] px-[6px] inline-flex',
                convertOrderStatusStyle[quoteDetail.state],
              )}
            >
              {quoteDetail.state === 'finished'
                ? '계약 완료'
                : convertOrderStatus[quoteDetail.state]}
            </span>
          )}
        </div>

        <div className='flex flex-col gap-[10px]'>
          <div className='font-[600] text-[20px] leading-[160%]'>
            {/* 상태별 문구 */}
            <div>{renderOrderState()}</div>
          </div>
          <div className='font-[500] text-[#555555] text-[14px] leading-[100%]'>
            주문번호 {quoteDetail.code}
          </div>
        </div>
      </div>

      {/* 딜러 정보 */}
      {!['nonAccepted', 'submitted'].includes(quoteDetail.state) && (
        <DealerInfoCard
          imgUrl={quoteDetail.manager?.profileImage_url as string}
          desc={quoteDetail.manager?.description || '등록된 소개글이 없습니다.'}
          rating={quoteDetail.manager?.dealerInfo?.rating ?? 0}
          nickname={quoteDetail.manager?.name_nick as string}
          memberId={quoteDetail.manager?.id as string}
        />
      )}

      <div className='space-y-4'>
        {quoteDetail.state === 'submitted' &&
          quoteDetail.bidList.length > 0 && (
            <div className='bg-[#F3F6F9] rounded-[8px] py-[14px] px-3 text-[14px] font-[500] leading-[150%] flex gap-2 items-center'>
              <img
                alt='느낌표'
                src='/assets/images/png/bang_icon.png'
                width={24}
                height={24}
              />
              <span
                dangerouslySetInnerHTML={{
                  __html: dayjs().isBefore(quoteDetail.bid_ended_at)
                    ? `남은 입찰시간 동안 계속 견적이 등록될 수 있으니<br/>충분히 검토 후 희망하시는 딜러분을 선택해 주세요!`
                    : `딜러분의 견적서 입찰(등록)은 완료되었습니다.<br/>충분히 검토 후 희망하시는 딜러분을 선택해 주세요!`,
                }}
              />
            </div>
          )}

        {quoteDetail.state === 'nonAccepted' && (
          <div className='bg-[#F3F6F9] rounded-[8px] py-[14px] px-3 text-[14px] font-[500] leading-[150%] flex gap-2 items-center'>
            <img
              alt='느낌표'
              src='/assets/images/png/bang_icon.png'
              width={24}
              height={24}
            />
            <span
              dangerouslySetInnerHTML={{
                __html:
                  quoteDetail.bidList.length === 0
                    ? `신차 구매를 위한 견적 요청권 1회는<br/>다시 충전되었으니 안심하세요.`
                    : `최적의 견적이 제공됐지만 마음에 들지 않으셨나요?<br/>견적 요청권 1회는 차감되었습니다.`,
              }}
            />
          </div>
        )}

        {/* 상태 별 버튼 */}
        {renderButtons()}
      </div>

      {/* 신차 견적 요청 취소 */}
      <V3_Confirm
        isOpen={isConfirmOpen.newDelete}
        setIsOpen={() => handleOpen('newDelete')}
        onConfirm={handleClickEvents.delete}
        blockUsingHistory={true}
        disableBackdropClose={true}
        title=''
        agreeText='견적 요청 취소'
        disagreeText='닫기'
        agreeClassName='!text-[#222222]'
      >
        <div className='flex flex-col items-center justify-center gap-5'>
          <div className='flex flex-col items-center justify-center gap-1.5'>
            <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
              견적 요청을 취소하시겠습니까?
            </div>
            <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
              취소 시 견적 요청권
              <br />
              <span className='text-[#FF4747]'>1회 차감</span>도 같이
              진행됩니다.
            </div>
          </div>
        </div>
      </V3_Confirm>

      {/* 신차 취소 */}
      <ConfirmV2
        title={'주문 취소'}
        desc={`${quoteDetail.selling?.usedCarInfo.carName}
      신차 구매를 취소 하시겠습니까?`}
        confirmText={'취소'}
        cancelText={'닫기'}
        confirmColor={
          'text-[#484AF5] text-center text-[15px] font-[500] leading-[100%] tracking-[-0.3px]'
        }
        isCloseButton={true}
        isOpen={isConfirmOpen.delete}
        setIsOpen={() => handleOpen('delete')}
        onConfirm={handleClickEvents.delete}
      />

      {/* 신차 재진행 */}
      <ConfirmV2
        title={'재진행'}
        desc={`다시 돌아오셨군요!
이어서 진행하시겠습니까?
진행 시 카 매니저분이 다시 연락드릴 예정입니다.`}
        confirmText={'진행'}
        cancelText={'취소'}
        confirmColor={
          'text-[#484AF5] text-center text-[15px] font-[500] leading-[100%] tracking-[-0.3px]'
        }
        isCloseButton={true}
        isOpen={isConfirmOpen.holded}
        setIsOpen={() => handleOpen('holded')}
        onConfirm={handleClickEvents.holded}
      />
    </div>
  );
};

export default NewCarTopInfo;
