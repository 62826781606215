import { useNavigate } from 'react-router-dom';

export default function DealerInfoCard({
  imgUrl,
  desc,
  rating,
  nickname,
  memberId,
}: {
  imgUrl: string;
  desc: string;
  rating: number;
  nickname: string;
  memberId: string;
}) {
  const navigate = useNavigate();
  return (
    <div
      className='p-4 flex items-center gap-4 rounded-[16px] border-[1px] border-[#E5EAEE] bg-white'
      onClick={() => navigate(`/dealerInfo/${memberId}`)}
    >
      {/* 프로필 이미지 */}
      <div className='flex-none w-[56px] h-[56px] rounded-full bg-white'>
        <img
          src={imgUrl}
          className='rounded-full object-cover w-[56px] h-[56px]'
          alt='프로필 이미지'
        />
      </div>
      <div className='flex flex-col gap-2'>
        <div className='text-[#222] font-semibold text-[15px] leading-[21px] tracking-[-0.3px]'>
          {desc}
        </div>
        <div className='overflow-hidden text-[#555] text-[14px] font-medium leading-[14px] tracking-[-0.28px]'>
          <div className='flex gap-[2px] items-center'>
            <img
              src='/assets/images/png/star.png'
              alt='star'
              width={12}
              height={12}
            />
            {rating}
            <span className='text-[#D5DADF]'>•</span>
            {nickname}
          </div>
        </div>
      </div>
    </div>
  );
}
