import { X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { OrderPurchaseMode } from '../../interface';

const NOTICE_STORAGE_KEY = 'orderPurchaseLeaseRentNoticeClose';
/** 닫기 이후 하루동안 미노출 */
const HIDE_DURATION = 24 * 60 * 60 * 1000; // 1일

export default function LeaseRentNotice({
  orderPurchaseMode,
}: {
  orderPurchaseMode: OrderPurchaseMode;
}) {
  const [isShow, setIsShow] = useState(false);

  useEffect(() => {
    const closeTimestamp = localStorage.getItem(NOTICE_STORAGE_KEY);

    if (closeTimestamp) {
      const closedTime = parseInt(closeTimestamp, 10);
      const now = Date.now();

      if (now - closedTime > HIDE_DURATION) return setIsShow(true);
      return;
    }

    return setIsShow(true);
  }, []);

  const handleClose = () => {
    localStorage.setItem(NOTICE_STORAGE_KEY, Date.now().toString());
    setIsShow(false);
  };

  if (orderPurchaseMode !== 'lease' && orderPurchaseMode !== 'rent')
    return null;

  if (!isShow) return null;

  return (
    <div className='w-full absolute bottom-0 z-10 px-5'>
      <div className='flex rounded-[10px] bg-[rgba(0,0,0,0.70)] backdrop-blur-[10px] py-[9px] px-[20px] justify-center items-center gap-[10px]'>
        <div className='text-[#FFF] text-[15px] font-medium leading-[24px] tracking-[-0.3px] break-keep'>
          리스 및 장기렌트 신청은 출고 1개월 전 가능합니다.
        </div>
        <button className='text-white' onClick={handleClose}>
          <X className='stroke-[1.4px]' />
        </button>
      </div>
    </div>
  );
}
