import { ReactNode } from 'react';
import { cn } from 'utils';

/** 전체 화면 영역
 * @description inline style - padding for app safeArea
 */
export function FullScreen({
  className,
  children,
}: {
  className?: string;
  children: ReactNode;
}) {
  return (
    <div
      className={cn(
        'top-0 fixed right-0 flex flex-col w-full h-full z-[1200] truncate',
        className,
      )}
    >
      {children}
    </div>
  );
}
