import {
  AccidentReport,
  AccidentReport_Review,
  AccidentReportReviewDTO,
  RepairShop,
} from '@carsayo/types';
import { api } from 'apis';
import SentryErrorReturns from 'utils/sentryErrorReturns';

/** 전체 공업사 리스트 조회 */
export const getTotalRepairshopList = async () => {
  try {
    const response = await api.get('accidentReport/totalRepairShop');
    const data: RepairShop[] = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

/** 일반회원 - 사고접수 조회
 */
export const getMemberAccidentReport = async (params: {
  id: string;
}): Promise<AccidentReport> => {
  try {
    const response = await api.get('accidentReport/customer', {
      params,
    });
    const data = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

/** 일반회원 - 사고접수 리스트 조회
 */
export const getTotalMemberAccidentReport = async (): Promise<
  AccidentReport[]
> => {
  try {
    const response = await api.get('accidentReport/customer/list');
    const data = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

/** 일반회원 - 사고접수 취소
 */
export const cancelAccidentReport = async (params: {
  id: string;
  reason?: string;
}): Promise<AccidentReport> => {
  try {
    const response = await api.post('accidentReport/update', {
      id: params.id,
      state: 'canceled',
      info_cancel: {
        type: '고객 취소',
        reason: params.reason ?? '',
        canceled_at: new Date(),
      },
    });
    const data = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

/** 일반회원 - 사고접수 리뷰작성
 */
export const createAccidentReportReview = async (
  params: AccidentReportReviewDTO,
): Promise<AccidentReport_Review> => {
  try {
    const response = await api.post('accidentReport/review', params);
    const data = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};
