import { OrderPurchaseDefaultProps } from '../../../interface';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import { BuyerType, SigunguId } from '@carsayo/types';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller } from 'react-hook-form';
import { SelectSigungu } from 'components/view/buy/selectSigungu';
import { ChevronDown } from 'lucide-react';
import { cn } from 'utils';
import parseSigungu from '../../../utils/parseSigungu';
import { motion } from 'framer-motion';

interface OrderPurchase_Method_LeaseProps extends OrderPurchaseDefaultProps {
  totalPrice: number;
}

export default function OrderPurchase_Method_Lease({
  form,
  wholeSellingCar,
  totalPrice,
}: OrderPurchase_Method_LeaseProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [drawerTarget, setDrawerTarget] = useState<'delivery' | null>(null);
  const handleResidenceRegionChange = useCallback(
    (value: SigunguId) => {
      if (drawerTarget === 'delivery') {
        form.setValue('purchaseInfo.리스.deliveryRegion', value.toString(), {
          shouldValidate: true,
        });
      }
    },
    [drawerTarget],
  );

  /** 보증금 선택 목록 */
  const depositPriceList: {
    price: number;
    title: string;
    percent: number;
  }[] = useMemo(() => {
    return [0, 10, 15, 20, 30, 40, 50, 60].map((el) => {
      const price = Math.ceil(0.0001 * 0.01 * el * totalPrice) * 10000;
      return {
        price,
        title: `${el}%${price > 0 ? ` (${price.toLocaleString()}원)` : ''}`,
        percent: el,
      };
    });
  }, [totalPrice]);

  /** 선납금 선택 목록 */
  const advancedPaymentPriceList: {
    price: number;
    title: string;
    percent: number;
  }[] = useMemo(() => {
    return [0, 10, 20, 30, 40, 50, 60].map((el) => {
      const price = Math.ceil(0.0001 * 0.01 * el * totalPrice) * 10000;
      return {
        price,
        title: `${el}%${price > 0 ? ` (${price.toLocaleString()}원)` : ''}`,
        percent: el,
      };
    });
  }, [totalPrice]);

  /** 잔존가치 선택 목록
   * @condition [계약기간]
    1. 36개월일 때 10%, 15%, 20% 불가
    2. 48개월일 때 10%, 15% 불가
    3. 60개월일 때 10% 불가
   */
  const residualPriceList: {
    price: number;
    title: string;
    percent: number;
  }[] = useMemo(() => {
    return [10, 15, 20, 30, 40, 50, 100]
      .filter((el) => {
        const selectedTerm = form.getValues('purchaseInfo.리스.contractTerm');

        if (selectedTerm === 36) {
          return ![10, 15, 20].includes(el);
        }

        if (selectedTerm === 48) {
          return ![10, 15].includes(el);
        }

        return true;
      })
      .map((el) => {
        const price = Math.ceil(0.0001 * 0.01 * el * totalPrice) * 10000;
        const title =
          el === 100
            ? '최대'
            : `${el}%${price > 0 ? ` (${price.toLocaleString()}원)` : ''}`;
        return {
          price,
          title,
          percent: el,
        };
      });
  }, [totalPrice, form.watch('purchaseInfo.리스.contractTerm')]);

  /** 잔존가치 선택 목록이 변경되었을 때, 잔존가치 선택 목록 내에 내 선택안이 없으면 선택값 초기화 */
  useEffect(() => {
    const selectedResidualPrice = form.getValues(
      'purchaseInfo.리스.price_residual',
    );

    if (
      !residualPriceList.some((item) => item.price === selectedResidualPrice)
    ) {
      /** @any avoid form type issue */
      form.setValue('purchaseInfo.리스.price_residual', undefined as any);
    }
  }, [residualPriceList]);

  return (
    <div className='flex flex-col gap-6'>
      {/* 구입주체 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          구입주체
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.리스.buyerType'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value}
                onValueChange={(value) => {
                  form.setValue(field.name, value as BuyerType, {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {Object.keys(BuyerType).map((key) => (
                  <SelectV3Item key={key} value={key}>
                    <div className='flex items-center'>
                      {BuyerType[key as keyof typeof BuyerType]}
                    </div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 계약기간 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          계약기간
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.리스.contractTerm'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value?.toString()}
                onValueChange={(value) => {
                  form.setValue(field.name, Number(value), {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {[24, 36, 48, 60].map((key) => (
                  <SelectV3Item key={key} value={key}>
                    <div className='flex items-center'>{key}개월</div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 보증금 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          보증금
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.리스.price_deposit'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value?.toString()}
                onValueChange={(value) => {
                  form.setValue(field.name, Number(value), {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {depositPriceList.map((item) => (
                  <SelectV3Item key={item.price} value={item.percent}>
                    <div className='flex items-center'>{item.title}</div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 선납금 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          선납금
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.리스.price_advancedPayment'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value?.toString()}
                onValueChange={(value) => {
                  form.setValue(field.name, Number(value), {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {advancedPaymentPriceList.map((item) => (
                  <SelectV3Item key={item.price} value={item.percent}>
                    <div className='flex items-center'>{item.title}</div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 잔존가치 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          잔존가치
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.리스.price_residual'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value?.toString()}
                onValueChange={(value) => {
                  form.setValue(field.name, Number(value), {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {residualPriceList.map((item) => (
                  <SelectV3Item key={item.price} value={item.percent}>
                    <div className='flex items-center'>{item.title}</div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 인수(탁송)지역 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          인수(탁송)지역
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.리스.deliveryRegion'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div>
              <div
                role='button'
                ref={(ref) => {
                  if (fieldState.error && ref) {
                    ref.focus();
                  }
                }}
                tabIndex={0}
                className={cn(
                  'w-full flex h-[50px] border border-[#E5E5EC] rounded-[8px] items-center justify-center px-4 pr-[12px] text-sm text-[#555555]',
                  fieldState.error &&
                    'border-[#FF4D4D] focus:border-[#FF4D4D] border-solid border',
                )}
                onClick={() => {
                  setDrawerTarget('delivery');
                  setIsOpen(true);
                }}
              >
                <div>
                  {field.value ? (
                    <div className='text-[16px] leading-[16px] font-[400] text-[#222] tracking-[0.00938em]'>
                      {parseSigungu(Number(field.value) as SigunguId)?.name}
                    </div>
                  ) : (
                    <div className='text-[16px] leading-[16px] font-[400] text-gray-500 tracking-[0.00938em]'>
                      선택해 주세요.
                    </div>
                  )}
                </div>
                <motion.div
                  animate={{ rotate: isOpen ? 180 : 0 }}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                  className='ml-auto'
                >
                  <ChevronDown
                    width={24}
                    height={24}
                    strokeWidth={1.5}
                    color={'#666666'}
                    className='ml-auto'
                  />
                </motion.div>
              </div>
              {fieldState.error && fieldState.error.message && (
                <div className='text-[12px] leading-[12px] p-1 font-[400] text-red-500'>
                  {fieldState.error.message}
                </div>
              )}
            </div>
          )}
        />
      </div>

      {/* 약정거리 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          약정거리
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.리스.agreeDistace'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value?.toString()}
                onValueChange={(value) => {
                  form.setValue(field.name, Number(value), {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {[10000, 20000, 30000, 40000].map((el) => (
                  <SelectV3Item key={el} value={el}>
                    <div className='flex items-center'>
                      {el === 0 ? '무제한' : `${el.toLocaleString()}km`}
                    </div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 자동차세 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          자동차세
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.리스.isIncludingCarTax'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value?.toString()}
                onValueChange={(value) => {
                  form.setValue(field.name, value === 'true', {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {[true, false].map((el, index) => (
                  <SelectV3Item key={index} value={el.toString()}>
                    <div className='flex items-center'>
                      {el === true ? '포함' : '미포함'}
                    </div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 지역 선택 Drawer */}
      <SelectSigungu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setRegion={handleResidenceRegionChange}
      />
    </div>
  );
}
