export default function OrderPurchaseFinishIcon() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='60'
      height='60'
      viewBox='0 0 60 60'
      fill='none'
    >
      <path
        d='M52.66 9.27002H7C5.89543 9.27002 5 10.1654 5 11.27V55.77C5 56.8746 5.89543 57.77 7 57.77H52.66C53.7646 57.77 54.66 56.8746 54.66 55.77V11.27C54.66 10.1654 53.7646 9.27002 52.66 9.27002Z'
        fill='#CED5E2'
      />
      <path
        d='M8.12988 13.6499L8.12988 53.3999C8.12988 53.9522 8.5776 54.3999 9.12988 54.3999H50.5399C51.0922 54.3999 51.5399 53.9522 51.5399 53.3999V13.6499C51.5399 13.0976 51.0922 12.6499 50.5399 12.6499H9.12988C8.5776 12.6499 8.12988 13.0976 8.12988 13.6499Z'
        fill='white'
      />
      <path
        d='M35.6001 3H24.0601C22.9555 3 22.0601 3.89543 22.0601 5V7.94C22.0601 9.04457 22.9555 9.94 24.0601 9.94H35.6001C36.7046 9.94 37.6001 9.04457 37.6001 7.94V5C37.6001 3.89543 36.7046 3 35.6001 3Z'
        fill='#8D9DB5'
      />
      <path
        d='M37.8399 6.99023H21.8199C20.3299 6.99023 19.1099 8.21023 19.1099 9.70023V13.0502C19.1099 13.4202 19.4099 13.7302 19.7899 13.7302H39.8799C40.2499 13.7302 40.5599 13.4302 40.5599 13.0502V9.70023C40.5599 8.21023 39.3399 6.99023 37.8499 6.99023H37.8399Z'
        fill='#8D9DB5'
      />
      <path
        d='M39.0599 48.5098H11.3599C10.8076 48.5098 10.3599 48.9575 10.3599 49.5098V51.2798C10.3599 51.8321 10.8076 52.2798 11.3599 52.2798H39.0599C39.6121 52.2798 40.0599 51.8321 40.0599 51.2798V49.5098C40.0599 48.9575 39.6121 48.5098 39.0599 48.5098Z'
        fill='#D9E0FC'
      />
      <path
        d='M48.2897 48.5098H43.1797C42.6274 48.5098 42.1797 48.9575 42.1797 49.5098V51.2798C42.1797 51.8321 42.6274 52.2798 43.1797 52.2798H48.2897C48.842 52.2798 49.2897 51.8321 49.2897 51.2798V49.5098C49.2897 48.9575 48.842 48.5098 48.2897 48.5098Z'
        fill='#C5D0FA'
      />
      <path
        d='M45.41 51.6898C45.27 51.6898 45.13 51.6298 45.04 51.5298L43.73 50.0798C43.54 49.8798 43.56 49.5598 43.76 49.3698C43.96 49.1898 44.28 49.1998 44.47 49.3998L45.36 50.3798L47.36 47.5598C47.52 47.3298 47.83 47.2798 48.06 47.4398C48.29 47.5998 48.34 47.9098 48.18 48.1398L45.83 51.4698C45.74 51.5898 45.6 51.6698 45.45 51.6798C45.44 51.6798 45.43 51.6798 45.42 51.6798L45.41 51.6898Z'
        fill='#F26166'
      />
      <path
        d='M35.38 7.6499H24.29C24.01 7.6499 23.79 7.4299 23.79 7.1499C23.79 6.8699 24.01 6.6499 24.29 6.6499H35.38C35.66 6.6499 35.88 6.8699 35.88 7.1499C35.88 7.4299 35.66 7.6499 35.38 7.6499Z'
        fill='#798CA8'
      />
      <path
        d='M20.2202 37.1299H18.6402C17.4528 37.1299 16.4902 38.0925 16.4902 39.2799V43.7499C16.4902 44.9373 17.4528 45.8999 18.6402 45.8999H20.2202C21.4076 45.8999 22.3702 44.9373 22.3702 43.7499V39.2799C22.3702 38.0925 21.4076 37.1299 20.2202 37.1299Z'
        fill='#333331'
      />
      <path
        d='M41.3101 37.1299H39.7301C38.5427 37.1299 37.5801 38.0925 37.5801 39.2799V43.7499C37.5801 44.9373 38.5427 45.8999 39.7301 45.8999H41.3101C42.4975 45.8999 43.4601 44.9373 43.4601 43.7499V39.2799C43.4601 38.0925 42.4975 37.1299 41.3101 37.1299Z'
        fill='#333331'
      />
      <path
        d='M45.26 30.35C46.2 30.35 46.96 29.59 46.96 28.65C46.96 27.71 46.2 26.95 45.26 26.94H43.75C43.08 26.94 42.5 27.33 42.23 27.9C42.17 27.79 42.1 27.68 42.04 27.57C41.61 26.84 40.83 23.18 40.83 23.18C40.83 23.18 40.39 18.42 35.31 18.1C33.44 17.98 32.21 17.96 29.98 17.97C27.75 17.95 26.52 17.97 24.65 18.1C19.58 18.42 19.13 23.18 19.13 23.18C19.13 23.18 18.34 26.84 17.92 27.57C17.86 27.68 17.79 27.79 17.73 27.9C17.45 27.34 16.88 26.94 16.21 26.94H14.7C13.76 26.94 13 27.7 13 28.64C13 29.58 13.76 30.34 14.7 30.35H15.28C14.2 31.51 14.17 33.14 14.17 33.14C14.17 33.14 13.81 34.95 14.17 38.41C14.55 41.96 19.08 41.76 19.08 41.76H40.88C40.88 41.76 45.41 41.96 45.79 38.41C46.16 34.94 45.79 33.14 45.79 33.14C45.79 33.14 45.76 31.51 44.68 30.35H45.25H45.26Z'
        fill='#7273F9'
      />
      <path
        d='M38.05 25.0301C38.05 25.0301 37.44 21.0901 33.94 20.9301C32.55 20.8601 31.63 20.8101 29.97 20.8301C28.31 20.8201 27.39 20.8201 26 20.9301C22.46 21.2301 21.89 25.0301 21.89 25.0301C21.89 25.0301 21.49 26.7701 21.19 28.0201C21.08 28.5001 21.47 28.5601 21.47 28.5601C21.47 28.5601 22.2 28.6501 30.07 28.6501C37.94 28.6501 38.52 28.5601 38.52 28.5601C38.52 28.5601 38.88 28.5601 38.76 28.0301C38.51 26.9701 38.06 25.0301 38.06 25.0301H38.05Z'
        fill='white'
      />
      <path
        d='M17.0899 37.1797C16.6399 37.1797 16.2299 36.8897 16.1099 36.4397L15.6999 34.9897C15.5499 34.4497 15.8599 33.8796 16.3999 33.7296C16.9399 33.5796 17.5099 33.8897 17.6599 34.4297L18.0699 35.8796C18.2199 36.4196 17.9099 36.9896 17.3699 37.1396C17.2799 37.1696 17.1799 37.1797 17.0899 37.1797Z'
        fill='#BBBCFC'
      />
      <path
        d='M42.8597 37.1798C42.7697 37.1798 42.6697 37.1698 42.5797 37.1398C42.0397 36.9898 41.7197 36.4198 41.8797 35.8798L42.2897 34.4298C42.4397 33.8898 43.0097 33.5698 43.5497 33.7298C44.0897 33.8798 44.4097 34.4498 44.2497 34.9898L43.8397 36.4398C43.7097 36.8898 43.2997 37.1798 42.8597 37.1798Z'
        fill='#BBBCFC'
      />
      <path
        d='M34.64 36.7598H25.31C24.7024 36.7598 24.21 37.2523 24.21 37.8598V38.6698C24.21 39.2773 24.7024 39.7698 25.31 39.7698H34.64C35.2475 39.7698 35.74 39.2773 35.74 38.6698V37.8598C35.74 37.2523 35.2475 36.7598 34.64 36.7598Z'
        fill='#6162EE'
      />
    </svg>
  );
}
