/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode, useEffect, useState } from 'react';

import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { cn } from 'utils';

/** static */
const subpageKey = 'csySubpage';

/** Subpage for app
 * @notice 현재 Subpage는 하나만 존재할 수 있습니다.
 */
const SubPage = ({
  isOpen,
  setIsOpen,
  children,
}: {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  children: ReactNode;
}) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    const isSubpageParam = searchParams.get(subpageKey);
    if (!isSubpageParam && isOpen) {
      setIsOpen(false);
    }
  }, [searchParams]);

  useEffect(() => {
    if (!isOpen) {
      const isSubpageParam = searchParams.get(subpageKey);
      if (isSubpageParam) {
        navigate(-1);
      }
      return;
    }

    setSearchParams((prevParams) => {
      const newParams = new URLSearchParams(prevParams);
      newParams.set(subpageKey, 'true');
      return newParams;
    });
  }, [isOpen]);

  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          id='subPage'
          initial={{ x: '100%' }} // 시작 위치: 화면 우측 밖
          animate={{ x: '0%' }} // 애니메이션 종료 위치: 화면 안
          exit={{ x: '100%' }} // 종료 위치: 다시 화면 우측 밖
          transition={{ type: 'tween', duration: 0.3 }} // 부드러운 트랜지션 설정
          className={cn(
            'top-0 bg-white fixed right-0 flex flex-col w-[100dvw] h-[100dvh] z-[1200] truncate',
          )}
          onClick={(e) => e.stopPropagation()}
          onTouchStart={(e) => e.stopPropagation()}
          onMouseDown={(e) => e.stopPropagation()}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default SubPage;
