/* eslint-disable @typescript-eslint/no-unused-vars */
import SwiperCore from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useState } from 'react';
import dayjs from 'dayjs';
import {
  CustomerOrderDetail,
  DealerOrderDetail,
  OrderDetail,
  SigunguId,
} from '@carsayo/types';
import { convertBoughtType } from 'utils/convert';
import parseSigungu from 'pages/v3/order/purchase/utils/parseSigungu';
import { useNavigate } from 'react-router-dom';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { ChevronRight } from 'lucide-react';
import { InfoItem } from '../../../InfoItem';
import HistoryDrawer from '../../../HistoryDrawer';
import MultiImageViewer from 'components_v3/dialog/MultiImageViewer';
import useMemberStore from 'store/useMemberStore';
import NoticeIcon from 'pages/v3/order/purchase/icon/notice';
import PriceDrawer from '../../../PriceDrawer';

const CarInfoTab = ({
  quoteDetail,
  setActiveTab,
}: {
  quoteDetail: OrderDetail &
    Partial<CustomerOrderDetail> &
    Partial<DealerOrderDetail>;
  setActiveTab: (activeTab: string | null) => void;
}) => {
  const navigate = useNavigate();
  const [swiper, setSwiper] = useState<SwiperCore | null>(null);
  const [page, setPage] = useState(1);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [isHistDrawer, setIsHistDrawer] = useState(false);

  /**
   * 250402 카매니저 / 일반회원 구분
   */
  const { loginMember } = useMemberStore();
  const mode: 'customer' | 'dealer' =
    loginMember?.type === 'dealer' ? 'dealer' : 'customer';

  /** 차량 시세 Drawer */
  const [isPriceDrawer, setIsPriceDrawer] = useState(false);

  return (
    <div className='flex flex-col w-full'>
      {/* 차량 이미지 swiper */}
      <div className='relative'>
        {/* 신차는 string, 중고차 string[] */}
        {quoteDetail.imageUrl &&
          (typeof quoteDetail.imageUrl === 'string' ? (
            <div
              className='w-full my-2 relative px-[26.6vw] py-[4.06vh] h-6 overflow-hidden flex justify-center items-center'
              onClick={() => {
                setIsImageViewerOpen(true);
              }}
            >
              <img
                src={quoteDetail.imageUrl}
                alt='입찰차량이미지'
                className='object-cover'
              />
            </div>
          ) : Array.isArray(quoteDetail.imageUrl) ? (
            <>
              <Swiper
                slidesPerView={1}
                onRealIndexChange={(swiper: SwiperClass) =>
                  setPage(swiper.realIndex + 1)
                }
                onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
              >
                {quoteDetail.imageUrl.map((img, index) => (
                  <SwiperSlide
                    key={index}
                    onClick={() => {
                      setIsImageViewerOpen(true);
                    }}
                  >
                    <img
                      src={img}
                      alt={`입찰차량이미지 ${index + 1}`}
                      className='object-contain h-60 w-full'
                    />
                  </SwiperSlide>
                ))}
              </Swiper>
              <div className='absolute right-2 bottom-[10px] z-10 inline-flex h-[24px] min-w-[44px] items-center justify-center rounded-[100px] bg-[#00000080] px-[10px] py-[6.5px] text-[13px] font-[500] leading-[100%] text-white transition-all gap-[2px]'>
                {page}/{quoteDetail.imageUrl.length}
                <span className='bg-[#999999] w-[1px] h-3'></span>
                <button
                  onClick={() =>
                    navigate(`/quote/customer/img/${quoteDetail.id}`)
                  }
                >
                  전체보기
                </button>
              </div>
            </>
          ) : null)}
      </div>

      {/* 차량 정보 */}
      <div className='bg-white px-5 pt-[30px] pb-5 flex flex-col gap-5'>
        <div className='flex flex-col gap-2'>
          <div className='font-[600] text-[20px] leading-[100%]'>
            {quoteDetail.selling?.usedCarInfo.carName}
          </div>
          <div className='font-[500] text-[15px] leading-[160%] text-[#555555]'>{`${quoteDetail.brand ? `${quoteDetail.brand} ` : ''}${quoteDetail.title}`}</div>
        </div>

        {/* 구매 방식
        @note 카매니저 일 때에만 노출됩니다.
       */}
        {mode === 'dealer' && (
          <div>
            <div className='rounded-[16px] border border-[#E5EAEE] px-4 py-6 space-y-5'>
              <div className='grid grid-cols-4'>
                <div className='text-[#555] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                  판매지역
                </div>
                <div className='col-span-3 text-[#222] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                  {
                    parseSigungu(quoteDetail.selling?.sellRegionId as SigunguId)
                      ?.name
                  }
                </div>
              </div>
              <div className='grid grid-cols-4'>
                <div className='text-[#555] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                  연락처
                </div>
                {/* 낙찰자일 경우에만 노출 */}
                <div className='col-span-3 text-[#222] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                  {quoteDetail.manager?.id === loginMember?.id ? (
                    quoteDetail?.member?.phoneNumber
                  ) : (
                    <span className='text-[#999]'>낙찰 후 공개</span>
                  )}
                </div>
              </div>
              {/* 이메일이 있을 경우에만 노출 */}
              {quoteDetail?.member?.email && (
                <div className='grid grid-cols-4'>
                  <div className='text-[#555] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                    이메일
                  </div>
                  {/* 낙찰자일 경우에만 노출 */}
                  <div className='col-span-3 text-[#222] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                    {quoteDetail.manager?.id === loginMember?.id ? (
                      quoteDetail?.member?.email
                    ) : (
                      <span className='text-[#999]'>낙찰 후 공개</span>
                    )}
                  </div>
                </div>
              )}
            </div>
          </div>
        )}

        <div className='flex flex-col gap-[14px]'>
          <div className='bg-[#F3F6F9] rounded-[10px] px-4 py-[15px] flex items-center text-[14px] text-[#555555] justify-between'>
            <div className='font-[400]'>시세 조회</div>
            <div className='flex gap-[6px] items-center font-[500]'>
              {quoteDetail.price?.toLocaleString()}원
              {/* 시세조회 버튼 (Drawer가 열립니다)
                @note 카매니저 전용
               */}
              {mode === 'dealer' && (
                <>
                  <button
                    onClick={() => {
                      setIsPriceDrawer(true);
                    }}
                  >
                    <NoticeIcon />
                  </button>
                </>
              )}
            </div>
          </div>
          <div className='flex gap-1 text-[14px] leading-[150%]'>
            <span className='text-[#555555]'>•</span>시세 금액은 실제 거래
            가격과 다를 수 있습니다.
          </div>
        </div>
      </div>

      {/* 상세정보 */}
      <div className='p-5 flex flex-col gap-[10px]'>
        <div className='flex flex-col gap-[30px]'>
          <div className='flex flex-col gap-[10px]'>
            <div className='font-[600] text-[18px] leading-[100%] mb-[14px]'>
              등록 정보
            </div>
            <div className='bg-white rounded-[10px] flex flex-col divide-y-[1px] divide-[#E5E5EC]'>
              <InfoItem
                title={'연식'}
                desc={`${quoteDetail.selling?.usedCarInfo.carInfo.year}년형`}
              />
              <InfoItem
                title={'최초등록'}
                desc={dayjs(
                  quoteDetail.selling?.usedCarInfo?.carInfo?.firstdate,
                ).format('YYYY년 MM월 DD일')}
              />
            </div>
            {quoteDetail?.selling?.accidentHistory && (
              <div className='bg-white rounded-[10px] flex flex-col divide-y-[1px] divide-[#E5E5EC]'>
                {/* <Accordion type='single' className='w-full' collapsible>
                  <AccordionItem value='item-2'>
                    <AccordionTrigger className='flex h-[51px] pr-4 items-center text-start border-none font-[600] text-[18px] leading-[100%]'>
                      <InfoItem
                        title={'거래이력'}
                        desc={`소유자 변경 이력 ${Number(
                          quoteDetail?.selling.accidentHistory?.MY_CHANGE_COUNT,
                        )}건`}
                      />
                    </AccordionTrigger>
                    <AccordionContent className='pb-0 flex flex-col gap-6 pl-[115px]'>
                      <ul className='list-disc'>
                        {quoteDetail?.selling.accidentHistory.RESP_MY_CHANGE_DATA.map(
                          (el, index) => {
                            return (
                              <li
                                key={`changeHistory_${index}`}
                                className='font-[500] text-[14px] leading-[150%]'
                              >
                                {dayjs(el.MY_CHANGE_CHANGE_DATE).format(
                                  'YYYY년 MM월',
                                )}{' '}
                                {el.MY_CHANGE_CHANGE_NAME}
                              </li>
                            );
                          },
                        )}
                      </ul>
                    </AccordionContent>
                  </AccordionItem>
                </Accordion> */}
                <InfoItem
                  title={'거래이력'}
                  desc={
                    <Accordion type='single' className='w-full' collapsible>
                      <AccordionItem value='item-2'>
                        <AccordionTrigger className='text-end pr-0 w-full flex h-[23px] items-center border-none font-[500] text-[15px] leading-[100%]'>
                          <div className='w-full mr-[6px]'>
                            소유자 변경 이력
                            {Number(
                              quoteDetail?.selling.accidentHistory
                                ?.MY_CHANGE_COUNT,
                            )}
                            건
                          </div>
                        </AccordionTrigger>
                        <AccordionContent className='pb-0 flex flex-col gap-6 float-end'>
                          <ul className='list-disc list-inside inline-block bg-[#F3F6F9] rounded-[8px] p-[10px]'>
                            {quoteDetail?.selling.accidentHistory.RESP_MY_CHANGE_DATA.map(
                              (el, index) => {
                                return (
                                  <li
                                    key={`changeHistory_${index}`}
                                    className='font-[500] text-[14px] leading-[150%]'
                                  >
                                    {dayjs(el.MY_CHANGE_CHANGE_DATE).format(
                                      'YYYY년 MM월',
                                    )}{' '}
                                    {el.MY_CHANGE_CHANGE_NAME}
                                  </li>
                                );
                              },
                            )}
                          </ul>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                  }
                />
                <InfoItem
                  title={'주행거리'}
                  desc={
                    Number(quoteDetail?.selling.accidentHistory.LAST_MILEAGE) >
                    0
                      ? `${Number(
                          quoteDetail?.selling.accidentHistory.LAST_MILEAGE,
                        ).toLocaleString()}km`
                      : '정보를 불러올 수 없습니다'
                  }
                />
                <InfoItem
                  title={'렌트이력'}
                  desc={quoteDetail?.selling.accidentHistory.RENT_CAR_HISTORY}
                />

                <div className='relative'>
                  <InfoItem
                    title={'보험이력'}
                    className='cursor-pointer pr-10'
                    onClick={() => {
                      if (
                        Number(
                          quoteDetail?.selling?.accidentHistory
                            ?.MY_CHANGE_COUNT,
                        ) === 0
                      )
                        return;
                      setIsHistDrawer(true);
                      // setActiveTab('보험 이력');
                    }}
                    desc={
                      Number(
                        quoteDetail?.selling.accidentHistory?.MY_CHANGE_COUNT,
                      ) > 0
                        ? '존재'
                        : '없음'
                    }
                  />
                  {Number(
                    quoteDetail?.selling.accidentHistory?.MY_CHANGE_COUNT,
                  ) > 0 && (
                    <ChevronRight
                      strokeWidth={1.5}
                      color='#666666'
                      className='w-5 h-5 absolute right-4 bottom-[15.5px] pointer-events-none'
                    />
                  )}
                </div>
              </div>
            )}{' '}
          </div>

          <div className='flex flex-col gap-[24px]'>
            <div className='font-[600] text-[18px] leading-[100%]'>
              모델 정보
            </div>

            <div className='bg-white rounded-[10px] flex flex-col divide-y-[1px] divide-[#E5E5EC]'>
              {quoteDetail?.selling?.usedCarInfo.carInfo.seriesname && (
                <InfoItem
                  title={'세부모델'}
                  desc={quoteDetail?.selling?.usedCarInfo.carInfo.seriesname}
                />
              )}
              {quoteDetail?.selling?.usedCarInfo.carInfo.cargubun && (
                <InfoItem
                  title={'차체형태'}
                  desc={quoteDetail?.selling?.usedCarInfo.carInfo.cargubun}
                />
              )}
              {quoteDetail?.selling?.usedCarInfo.carInfo.enType && (
                <InfoItem
                  title={'엔진타입'}
                  desc={quoteDetail?.selling?.usedCarInfo.carInfo.enType}
                />
              )}
              {quoteDetail?.selling?.usedCarInfo.carInfo.color && (
                <InfoItem
                  title={'색상'}
                  desc={quoteDetail?.selling?.usedCarInfo.carInfo.color}
                />
              )}
              {quoteDetail?.selling?.usedCarInfo?.carInfo.gearbox && (
                <InfoItem
                  title={'변속기'}
                  desc={quoteDetail?.selling?.usedCarInfo?.carInfo.gearbox}
                />
              )}
              {(quoteDetail?.selling?.usedCarInfo.carInfo.displacement ?? 0) >
                0 && (
                <InfoItem
                  title={'배기량'}
                  desc={`${quoteDetail?.selling?.usedCarInfo.carInfo.displacement}cc`}
                />
              )}
              {quoteDetail?.selling?.usedCarInfo.carInfo.fuel && (
                <InfoItem
                  title={'연료'}
                  desc={quoteDetail?.selling?.usedCarInfo.carInfo.fuel}
                />
              )}
              {quoteDetail?.selling?.usedCarInfo.carInfo.stdmileage && (
                <InfoItem
                  title={'연비'}
                  desc={quoteDetail?.selling?.usedCarInfo.carInfo.stdmileage}
                />
              )}
              {quoteDetail?.selling?.usedCarInfo.carInfo.seating && (
                <InfoItem
                  title={'승차인원'}
                  desc={`${quoteDetail?.selling?.usedCarInfo.carInfo.seating}명`}
                />
              )}
              {quoteDetail.selling?.usedCarInfo.carInfo.newprice && (
                <InfoItem
                  title={'신차가격'}
                  desc={`${quoteDetail.selling?.usedCarInfo.carInfo.newprice.toLocaleString()}원`}
                />
              )}
            </div>
          </div>

          <div className='flex flex-col gap-[24px]'>
            <div className='font-[600] text-[18px] leading-[100%]'>
              차량 상태
            </div>
            <div className='bg-white rounded-[10px] flex flex-col divide-y-[1px] divide-[#E5E5EC]'>
              {quoteDetail?.selling?.boughtMethod && (
                <InfoItem
                  title={'구매방식'}
                  desc={convertBoughtType[quoteDetail.selling.boughtMethod]}
                />
              )}
              <InfoItem
                title={'판매지역'}
                desc={
                  parseSigungu(quoteDetail.selling?.sellRegionId as SigunguId)
                    ?.name || '없음'
                }
              />
              <InfoItem
                title={'사고내역'}
                desc={
                  quoteDetail.selling?.additionalInfo.accident.모름.selected
                    ? '잘 모르겠어요'
                    : quoteDetail.selling?.additionalInfo.accident.사고없음
                          .selected
                      ? '없음'
                      : quoteDetail.selling?.additionalInfo.accident.판금교환
                            .selected
                        ? '판금/교환 부위 있음'
                        : '없음'
                }
                subDesc={
                  quoteDetail.selling?.additionalInfo.accident.판금교환
                    .selected &&
                  quoteDetail.selling.additionalInfo.accident.판금교환.content
                    ? quoteDetail.selling.additionalInfo.accident.판금교환
                        .content
                    : '없음'
                }
              />
              <InfoItem
                title={'타이어 교체'}
                desc={
                  quoteDetail?.selling?.additionalInfo.carState.타이어교체
                    .selected
                    ? quoteDetail?.selling?.additionalInfo?.carState?.타이어교체
                        .content ?? '없음'
                    : '없음'
                }
              />
              <InfoItem
                title={'외판 스크래치'}
                desc={
                  quoteDetail?.selling?.additionalInfo.carState.외관스크래치
                    .selected
                    ? quoteDetail?.selling?.additionalInfo.carState.외관스크래치
                        .content ?? '없음'
                    : '없음'
                }
              />
              <InfoItem
                title={'정비 및 수리'}
                desc={
                  quoteDetail?.selling?.additionalInfo.carState.정비수리필요
                    .selected
                    ? '정비/수리 필요'
                    : '없음'
                }
                subDesc={
                  quoteDetail.selling?.additionalInfo.carState.정비수리필요
                    .selected &&
                  quoteDetail.selling?.additionalInfo.carState.정비수리필요
                    .content
                    ? quoteDetail.selling?.additionalInfo.carState.정비수리필요
                        .content
                    : '없음'
                }
              />
              <InfoItem
                title={'내 차의 장단점'}
                desc={
                  quoteDetail?.selling?.additionalInfo.carState.차량장단점
                    .selected
                    ? quoteDetail?.selling?.additionalInfo.carState.차량장단점
                        .content ?? '없음'
                    : '없음'
                }
              />
            </div>
          </div>

          <div className='flex flex-col gap-[24px]'>
            <div className='font-[600] text-[18px] leading-[100%]'>
              차량 옵션
            </div>
            <div className='bg-white rounded-[10px] flex flex-col divide-y-[1px] divide-[#E5E5EC]'>
              {quoteDetail?.selling?.additionalInfo.optionSelect.옵션없음 &&
              !quoteDetail.selling.additionalInfo.optionSelect.직접입력 ? (
                <div className='text-[#999999] font-[400] text-[14px] leading-[100%] h-[51px] text-center flex items-center justify-center'>
                  등록된 옵션 내역이 없어요
                </div>
              ) : (
                <>
                  {quoteDetail?.selling?.additionalInfo?.optionSelect
                    ?.optionList &&
                    quoteDetail?.selling?.additionalInfo.optionSelect.optionList
                      .length > 0 &&
                    quoteDetail?.selling?.additionalInfo.optionSelect.optionList.map(
                      (item, idx) => {
                        return (
                          <InfoItem
                            key={`option_${idx}`}
                            title={`옵션 ${idx + 1}`}
                            desc={
                              <>
                                {item.optname}
                                <br />+ {item.optprice.toLocaleString()}
                              </>
                            }
                          />
                        );
                      },
                    )}
                  {quoteDetail?.selling?.additionalInfo.optionSelect?.직접입력
                    ?.selected ? (
                    <InfoItem
                      key={`option_self`}
                      title={`옵션(직접 입력)`}
                      desc={`${quoteDetail?.selling?.additionalInfo.optionSelect.직접입력.content}`}
                    />
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </div>

      <PriceDrawer
        isOpen={isPriceDrawer}
        setIsOpen={setIsPriceDrawer}
        quoteDetail={quoteDetail}
      />

      <HistoryDrawer
        isOpen={isHistDrawer}
        setIsOpen={setIsHistDrawer}
        quoteDetail={quoteDetail}
      />

      {quoteDetail.imageUrl && (
        <MultiImageViewer
          isOpen={isImageViewerOpen}
          setIsOpen={setIsImageViewerOpen}
          initialIndex={page - 1}
          fileUrl={
            Array.isArray(quoteDetail.imageUrl)
              ? quoteDetail.imageUrl
              : [quoteDetail.imageUrl]
          }
        />
      )}
    </div>
  );
};

export default CarInfoTab;
