import { cn } from 'utils';

const MainTab = ({
  currentType,
  setCurrentType,
}: {
  currentType: string;
  setCurrentType: (currentType: string) => void;
}) => {
  const tabItems = [
    { key: 'newCar', label: '신차 구매' },
    { key: 'sellCar', label: '내 차 팔기' },
  ];

  return (
    <div className='grid grid-cols-2 border-b border-[#E5E5EC] bg-white w-full fixed top-[59px] z-[9]'>
      {tabItems.map(({ key, label }) => (
        <div key={key} className='relative'>
          <div
            onClick={() => setCurrentType(key)}
            className={cn(
              'relative flex h-[50px] cursor-pointer items-center justify-center text-[16px] font-medium leading-[16px] tracking-[-0.32px]',
              currentType === key ? 'text-[#222]' : 'text-[#999]',
            )}
          >
            {label}
          </div>
          {currentType === key && (
            <div className='absolute bottom-[-0.1rem] h-0.5 w-full bg-black'></div>
          )}
        </div>
      ))}
    </div>
  );
};

export default MainTab;
