/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { CustomCheckbox } from 'components_v3/ui/Checkbox';
import { X } from 'lucide-react';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

function MisteryShopperDrawer({
  isOpen,
  setIsOpen,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
}) {
  const navigate = useNavigate();

  const [isChecked, setIsChecked] = useState(false);

  // const [noMysteryShopper, setNoMysteryShopper] = useState(false);
  // const handleChangeEvents = {
  //   noMystery: () => {
  //     setNoMysteryShopper(!noMysteryShopper);
  //   },
  // };

  return (
    <Drawer open={isOpen}>
      <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col !pt-4'>
        <DrawerHeader className='relative'>
          <DrawerTitle>서비스 이용 규정</DrawerTitle>
          <DrawerClose asChild>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'
            >
              <X className='h-7 w-7' />
            </Button>
          </DrawerClose>
        </DrawerHeader>
        <div className='p-5'>
          <div className='p-4 bg-[#F3F6F9] rounded-[10px]'>
            <div className='text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
              미스터리 쇼퍼(고객으로 가장하여 정보를 캐거나 소속된 카 매니저들을
              색출하는 사람)는 견적 신청이 불가하며, 적발(계약 체결, 출고, 해지
              포함 5년간) 시 건 당 10억 원의 배상과 민형사상 책임을 져야 합니다.
            </div>

            <button
              className='bg-white w-full rounded-[10px] p-4 flex justify-between items-center mt-6'
              onClick={() => setIsChecked(!isChecked)}
            >
              <div className='text-[#222] text-[16px] font-medium leading-[24px] tracking-[-0.32px]'>
                미스터리 쇼퍼가 아닙니다
              </div>
              <CustomCheckbox
                value='미스터리 쇼퍼가 아닙니다'
                checked={isChecked}
                readOnly
              />
            </button>
          </div>

          <Button
            className='w-full mt-[50px]'
            disabled={!isChecked}
            onClick={() => {
              setIsOpen(false);
            }}
          >
            다음
          </Button>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
export default MisteryShopperDrawer;
