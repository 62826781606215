import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider, MobileDatePicker } from '@mui/x-date-pickers';
import ko from 'date-fns/locale/ko';
import dayjs from 'dayjs';
import { useEffect, useState } from 'react';
import { OrderDetail } from '@carsayo/types';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { selectedMeetingApi } from 'apis/quote';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';

/** 미팅 날짜 선택 기능도 포함 */
export default function DealerOrderDatePicker({
  open,
  setOpen,
  orderDetail,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  orderDetail: OrderDetail;
}) {
  const queryClient = useQueryClient();
  const [value, setValue] = useState<Date | null>(null);

  useEffect(() => {
    if (open) {
      setValue(null);
    }
  }, [open]);

  const { mutateAsync, isPending } = useMutation({
    mutationFn: () => {
      if (!value) throw new Error('날짜를 선택해주세요.');

      return selectedMeetingApi({
        orderId: orderDetail.id,
        meeting_date: value,
      });
    },
    onSuccess: () => {
      CarsayoToast.success('미팅 날짜가 선택되었습니다.');
      queryClient.invalidateQueries({
        queryKey: ['orderDetail', orderDetail.id],
      });
      setOpen(false);
    },
    onError: (error) => {
      console.error(error);
    },
  });

  return (
    <LocalizationProvider
      dateAdapter={AdapterDateFns}
      adapterLocale={ko}
      dateFormats={{
        normalDate: 'yyyy.MM.dd',
        year: 'yyyy년',
        month: 'MM월',
      }}
      localeText={{
        previousMonth: '이전 달',
        nextMonth: '다음 달',
        fieldClearLabel: '초기화',
        cancelButtonLabel: '닫기',
        okButtonLabel: isPending
          ? '로딩 중...'
          : value
            ? `${dayjs(value).format('YYYY년 MM월 DD일')} 선택`
            : `선택`,
        datePickerToolbarTitle: '미팅 날짜 선택',
      }}
    >
      <MobileDatePicker
        open={open}
        value={value || null}
        onChange={(newValue) => {
          setValue(newValue);
        }}
        onAccept={async () => {
          await mutateAsync();
        }}
        onClose={() => {
          setOpen(false);
        }}
        minDate={new Date()}
        slotProps={{
          calendarHeader: {
            format: 'yyyy년 MM월',
            sx: {
              '& .MuiPickersCalendarHeader-label': {
                fontFamily: 'Pretendard',
                letterSpacing: '-0.02em',
              },
            },
          },
          toolbar: {
            sx: {
              '&': {
                paddingBottom: '0px',
                alignItems: 'center',
              },
              '& .MuiTypography-root': {
                fontSize: '16px',
                fontFamily: 'Pretendard',
                letterSpacing: '-0.02em',
                color: '#222',
                fontWeight: '500',
              },
              '& .MuiPickersToolbar-content': {
                justifyContent: 'center',
                '& h4': {
                  display: 'none',
                },
              },
            },
          },
          actionBar: {
            sx: {
              '& .MuiButtonBase-root': {
                fontFamily: 'Pretendard',
                fontSize: '14px',
                whiteSpace: 'nowrap',
                fontWeight: '500',
                color: '#222',
                padding: '18px',
                height: '44px',
                borderRadius: '8px',
                '&:first-child': {
                  width: '30%',
                  border: '1px solid #ddd',
                },
                '&:last-child': {
                  width: '70%',
                  backgroundColor: '#7273F9',
                  color: '#fff',
                },
              },
            },
          },
          layout: {
            sx: {
              '.MuiPaper-root': {
                margin: '0px',
              },
              '.MuiDayCalendar-weekDayLabel': {
                fontFamily: 'Pretendard',
                fontSize: '13px',
              },
              '.MuiDayCalendar-root': {
                fontFamily: 'Pretendard',
              },
              '.MuiButtonBase-root.Mui-selected': {
                backgroundColor: '#7273F9 !important',
              },
            },
          },
        }}
        sx={{
          display: 'none',
        }}
      />
    </LocalizationProvider>
  );
}
