import { Holiday, InsuranceCompany } from '@carsayo/types';
import { api } from 'apis';
import dayjs from 'dayjs';
import { InsuranceProductsData } from 'pages/static/insurance/data/product';

import SentryErrorReturns from 'utils/sentryErrorReturns';

export const getHoliday = async () => {
  const currentYear = dayjs().year();

  try {
    const response = await api.get(`holiday?year=${currentYear}`);
    const data: Holiday[] = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

/** 보험 상품 조회 */
export const getInsuranceProducts = async () => {
  try {
    const response = await api.get('/data/insurance/products');
    const data: InsuranceCompany[] = response.data;
    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

/** 보험 상품 조회 */
export const getInsuranceProductsV1 = async () => {
  try {
    const [defaultRes, corporationRes, businessRes] = await Promise.all([
      api.get('data/insurance/products'),
      api.get('data/insurance/products/corporation'),
      api.get('data/insurance/products/business'),
    ]);

    const data: InsuranceProductsData = {
      default: defaultRes.data,
      corporation: corporationRes.data,
      business: businessRes.data,
    };

    return data;
  } catch (error: any) {
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};
