/* eslint-disable @typescript-eslint/no-unused-vars */
import SubPage from 'components/view/SubPage';
import { DealerOrderSubpageProps } from 'pages/v3/orderDetailTemp/type';
import { X } from 'lucide-react';
import V3_Button from 'components_v3/ui/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { OrderFinishDTO } from '@carsayo/types';
import { Controller, useForm } from 'react-hook-form';
import { useEffect, useMemo, useState } from 'react';
import { doneQuoteApi } from 'apis/quote';
import V3_Confirm from 'components_v3/dialog/Confirm';
import useInvalidateDealerSearch from 'pages/main/dealer/hook/invalidateDealerSearch';
import FileInput from 'components/common/v3/fileInput';

const TEXT = {
  title: '계약 완료',

  description: {
    purchase: `계약서 업로드 시
    계약 완료 처리됩니다.`,
    selling: `이전 등록증 업로드 시
    계약 완료 처리됩니다.`,
  },

  submitButton: '계약 완료',
  confirm: {
    title: '계약 완료',
    description: `계약이 완료되었습니다.
더 나은 서비스 제공을 위해 노력하겠습니다.`,
  },
};

/** 계약 완료 서브페이지 */
export default function DealerOrderDetailFinishSubpage({
  orderListData,
  isOpen,
  setIsOpen,
}: DealerOrderSubpageProps) {
  const queryClient = useQueryClient();

  const { InvalidateMyOrderList } = useInvalidateDealerSearch();

  const type: 'purchase' | 'selling' = useMemo(() => {
    return orderListData.type === 'newCar' ||
      orderListData.type === 'rent' ||
      orderListData.type === 'lease'
      ? 'purchase'
      : 'selling';
  }, [orderListData]);

  const form = useForm<OrderFinishDTO>({
    defaultValues: {
      orderId: orderListData.id,
      /** 계약서
       * @note 신차, 리스/렌트 필수
       */
      contract_fileId: undefined,
      /** 이전된 자동차등록증
       * @note 중고차 필수
       */
      transferCertificate_fileId: undefined,
    },
  });

  const [isHoldSuccessConfirmOpen, setIsHoldSuccessConfirmOpen] =
    useState(false);

  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      await doneQuoteApi(form.getValues());
    },
    onSuccess: async () => {
      setIsHoldSuccessConfirmOpen(true);
    },
    onError: async (error: any) => {
      const errorMessage: string =
        error?.data?.message ?? '잠시 후 다시 시도해 주세요.';

      return CarsayoToast.error(errorMessage);
    },
  });

  useEffect(() => {
    form.reset();
  }, [open]);

  const isValid = useMemo(() => {
    const formValue = form.getValues();
    if (type === 'selling') {
      if (!formValue.transferCertificate_fileId) return false;
    }

    if (
      orderListData.type === 'lease' ||
      orderListData.type === 'rent' ||
      orderListData.type === 'newCar'
    ) {
      if (!formValue.contract_fileId) return false;
    }

    return true;
  }, [type, form.watch()]);

  return (
    <SubPage
      isOpen={isOpen}
      setIsOpen={(isOpen) => {
        setIsOpen(isOpen);
      }}
    >
      {/* Header */}
      <div className='flex flex-none h-14 items-center justify-between bg-white px-5'>
        <div className='pl-4 grow text-center text-[#222] text-[20px] font-semibold leading-[20px] tracking-[-0.4px]'>
          {TEXT.title}
        </div>
        <div
          className='cursor-pointer'
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <X className='w-8 h-8' strokeWidth={1.5} />
        </div>
      </div>

      <div className='flex flex-auto flex-col gap-[10px] justify-between overflow-y-scroll'>
        {/* Form영역 */}
        <div className='px-5 h-auto space-y-6'>
          <div className='py-[30px] text-[#222] text-[18px] font-medium leading-[28.8px] tracking-[-0.36px] whitespace-pre-line'>
            {TEXT.description[type]}
          </div>

          {/* 파일 업로드 영역 */}
          <div>
            {type === 'purchase' && (
              <div className='space-y-3'>
                <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
                  계약서
                </div>

                <Controller
                  control={form.control}
                  name='contract_fileId'
                  rules={{ required: true }}
                  render={({ field, fieldState, formState }) => (
                    <div>
                      <FileInput
                        accept='image/*'
                        isPrivate={true}
                        fileName='계약서 파일'
                        error={!!fieldState.error}
                        initialFileId={field.value ?? undefined}
                        onDelete={() => {
                          form.setValue(field.name, undefined, {
                            shouldValidate: true,
                          });
                        }}
                        onUpload={(fileInfo) => {
                          form.setValue(field.name, fileInfo.id, {
                            shouldValidate: true,
                          });
                        }}
                      />
                    </div>
                  )}
                />
              </div>
            )}

            {type === 'selling' && (
              <div className='space-y-3'>
                <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
                  이전된 등록증
                </div>

                <Controller
                  control={form.control}
                  name='transferCertificate_fileId'
                  rules={{ required: true }}
                  render={({ field, fieldState, formState }) => (
                    <div>
                      <FileInput
                        accept='image/*'
                        isPrivate={true}
                        fileName='이전된 등록증 파일'
                        error={!!fieldState.error}
                        initialFileId={field.value ?? undefined}
                        onDelete={() => {
                          form.setValue(field.name, undefined, {
                            shouldValidate: true,
                          });
                        }}
                        onUpload={(fileInfo) => {
                          form.setValue(field.name, fileInfo.id, {
                            shouldValidate: true,
                          });
                        }}
                      />
                    </div>
                  )}
                />
              </div>
            )}
          </div>

          {/* 경고문 영역 */}

          <div className='my-6 p-4 bg-[#F3F6F9] rounded-[10px] whitespace-pre-line'>
            <div className='text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
              확인해 주세요
            </div>
            {/* Divider */}
            <div className='w-full h-[1px] bg-[#E5E5EC] my-4'></div>
            <ul className='list-disc pl-5'>
              <li className='text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
                고객님께 제시한 견적 내용과 실제 내용이 다를 경우 불이익을 받을
                수 있습니다.
              </li>
            </ul>
          </div>
        </div>

        <div className='sticky bottom-0 bg-white p-5 flex flex-none gap-[7px]'>
          <V3_Button
            className='w-full'
            onClick={() => {
              setIsOpen(false);
            }}
          >
            닫기
          </V3_Button>
          <V3_Button
            className='w-full'
            theme='primary'
            disabled={!isValid}
            isLoading={isPending}
            onClick={() => {
              mutate();
            }}
          >
            {TEXT.submitButton}
          </V3_Button>
        </div>

        <V3_Confirm
          isOpen={isHoldSuccessConfirmOpen}
          setIsOpen={setIsHoldSuccessConfirmOpen}
          onConfirm={async () => {
            setIsHoldSuccessConfirmOpen(false);
            queryClient.invalidateQueries({
              queryKey: ['orderDetail', orderListData.id],
            });
            InvalidateMyOrderList();
            await new Promise((resolve) => setTimeout(resolve, 1000));
            setIsOpen(false);
          }}
          blockUsingHistory={true}
          disableBackdropClose={true}
          title=''
          agreeText='확인'
          isAlert={true}
        >
          <div className='flex flex-col items-center justify-center gap-5'>
            <div className='flex flex-col items-center justify-center gap-1.5'>
              <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
                {TEXT.confirm.title}
              </div>
              <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
                {TEXT.confirm.description}
              </div>
            </div>
          </div>
        </V3_Confirm>
      </div>
    </SubPage>
  );
}
