export default function Person() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
    >
      <g clipPath='url(#clip0_3418_63875)'>
        <path
          d='M3.66602 3.33317C3.66602 3.95196 3.91183 4.54541 4.34938 4.98296C4.78694 5.42052 5.38039 5.66633 5.99918 5.66633C6.61797 5.66633 7.21142 5.42052 7.64898 4.98296C8.08653 4.54541 8.33235 3.95196 8.33235 3.33317C8.33235 2.71437 8.08653 2.12092 7.64898 1.68337C7.21142 1.24581 6.61797 1 5.99918 1C5.38039 1 4.78694 1.24581 4.34938 1.68337C3.91183 2.12092 3.66602 2.71437 3.66602 3.33317Z'
          fill='#B2B7BB'
          stroke='#B2B7BB'
          strokeWidth='1.03697'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M2.50057 10.9997C1.85628 10.9997 1.33398 10.4775 1.33398 9.83317C1.33398 9.21437 1.66174 8.62092 2.24514 8.18337C2.82855 7.74582 3.61981 7.5 4.44487 7.5H7.55576C8.38082 7.5 9.17208 7.74582 9.75549 8.18337C10.3389 8.62092 10.6666 9.21437 10.6666 9.83317'
          fill='#B2B7BB'
        />
        <path
          d='M1.33398 9.83317V9.83317C1.33398 10.4775 1.85628 10.9997 2.50057 10.9997H9.50006C10.1443 10.9997 10.6666 10.4775 10.6666 9.83317V9.83317C10.6666 9.21437 10.3389 8.62092 9.75549 8.18337C9.17208 7.74582 8.38082 7.5 7.55576 7.5H4.44487C3.61981 7.5 2.82855 7.74582 2.24514 8.18337C1.66174 8.62092 1.33398 9.21437 1.33398 9.83317Z'
          stroke='#B2B7BB'
          strokeWidth='1.03697'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M11 9.5L10.8 10.4L8.5 11L5.5 10.5L10.6 9L11 9.5Z'
          fill='#B2B7BB'
        />
      </g>
      <defs>
        <clipPath id='clip0_3418_63875'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
