import { useMemo, useState } from 'react';
import { OrderPurchaseDefaultProps } from '../../interface';
import NoticeIcon from '../../icon/notice';
import OrderPurchasePriceInfoDrawer from '../drawer/priceInfo';
import { OrderPurchaseForm } from '../../form';
import TextArea from 'components_v3/ui/TextArea';
import FileInput from 'components/common/v3/fileInput';
import { Controller } from 'react-hook-form';
import { useQuery, useMutation } from '@tanstack/react-query';
import { getInterestCarList, selectInterestCar } from 'apis/car';
import { Heart } from 'lucide-react';
import { SelectedCarData } from '@carsayo/types';
import combineCarName from '../../utils/combineCarName';

interface OrderPurchaseSubmitProps extends OrderPurchaseDefaultProps {
  selectedCarData: SelectedCarData | null;
  totalPrice: number;
}

const Notice = ({ type }: { type: 'superCar' | 'nonSuperCar' }) => {
  if (type === 'superCar')
    return (
      <div className='my-6 p-4 bg-[#F0F0FF] rounded-[10px]'>
        <div className='text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
          구매 계약서를 업로드 해 주세요.
        </div>
        {/* Divider */}
        <div className='w-full h-[1px] bg-[#E5E5EC] my-4'></div>
        <ul className='list-disc pl-5'>
          <li className='text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
            카사요는 [대출성 금융상품 판매대리•중개업자 등록증]을 보유한
            플랫폼으로{' '}
            <span className='text-[#7273F9] font-medium'>
              리스 및 장기렌트 서비스
            </span>
            가 가능합니다.
          </li>
          <li className='text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
            구매하실 계약서를 업로드해 주시면 카사요 금융 카 매니저님이 합리적인
            금융 서비스를 제안합니다.
          </li>
        </ul>
      </div>
    );

  return (
    <div className='my-6 p-4 bg-[#F0F0FF] rounded-[10px]'>
      <div className='text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
        이미 보유하고 계신 견적서가 있으신가요?
      </div>
      {/* Divider */}
      <div className='w-full h-[1px] bg-[#E5E5EC] my-4'></div>
      <ul className='list-disc pl-5'>
        <li className='text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
          카사요는 [대출성 금융상품 판매대리·중개업자 등록증] 을 보유한
          플랫폼으로{' '}
          <span className='text-[#7273F9] font-medium'>
            더 좋은 조건의 견적
          </span>
          을 직접 제공해 드릴 수 있습니다.
        </li>
        <li className='text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
          받아보신 견적서와 혜택 내용을 함께 기재해 주시면 더욱 상세한 견적을
          제안해 드리겠습니다.
        </li>
      </ul>
    </div>
  );
};

const AdditionalInfo = ({
  form,
  selectedCarData,
}: {
  form: OrderPurchaseForm;
  selectedCarData: SelectedCarData;
}) => {
  return (
    <div className='py-[30px] px-5'>
      <div className='text-[#000] text-[16px] font-semibold leading-[16px] tracking-[-0.32px]'>
        전달 사항
      </div>
      <Notice
        type={form.getValues('isSuperCar') ? 'superCar' : 'nonSuperCar'}
      />
      {/* Form영역 */}
      <div className='pt-2 flex flex-col gap-6'>
        {/* 타사 제공 혜택 내용
          @note 슈퍼카 브랜드 제외
         */}
        {!form.getValues('isSuperCar') && (
          <div className='space-y-3'>
            <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
              타사 혜택 내용(선택)
            </div>
            <Controller
              control={form.control}
              name='additionalInfo.otherCompanyEstimateContent'
              rules={{ required: true }}
              render={({ field, fieldState, formState }) => (
                <div
                  /** 에러 발생시 포커스 영역 처리 */
                  role='button'
                  ref={(ref) => {
                    if (fieldState.error && ref) {
                      ref.focus();
                    }
                  }}
                  tabIndex={0}
                >
                  <TextArea
                    className='w-full'
                    maxLength={200}
                    placeholder='제공받기로 한 추가 혜택을 알려주세요.'
                    value={field.value}
                    onChange={(e) => {
                      form.setValue(field.name, e.target.value);
                    }}
                  />
                </div>
              )}
            />
          </div>
        )}

        {/** 견적서 / 구매 계약서
         * @note 슈퍼카: 구매 계약서
         * @note 비슈퍼카: 견적서
         * @condition 슈퍼카 주문일 경우 구매 계약서 업로드 필수
         * @condition 비슈퍼카 주문일 경우, 할부/일시불일 때에만 견적서 업로드 필수
         */}
        <div className='space-y-3'>
          <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
            {form.getValues('isSuperCar') ? '구매 계약서' : '타사 견적서'}
            {!form.getValues('isSuperCar') &&
              (form.getValues('purchaseMethod') === '리스' ||
                form.getValues('purchaseMethod') === '장기렌트') && (
                <span>(선택)</span>
              )}
          </div>

          <Controller
            control={form.control}
            name='additionalInfo.otherCompanyEstimateFileId'
            rules={{ required: true }}
            render={({ field, fieldState, formState }) => (
              <div
                /** 에러 발생시 포커스 영역 처리 */
                role='button'
                ref={(ref) => {
                  if (fieldState.error && ref) {
                    ref.focus();
                  }
                }}
                tabIndex={0}
              >
                <FileInput
                  accept='image/*'
                  error={!!fieldState.error}
                  fileName={
                    form.getValues('isSuperCar') ? '구매 계약서' : '타사 견적서'
                  }
                  onUpload={(fileInfo) => {
                    form.setValue(field.name, fileInfo.id, {
                      shouldValidate: true,
                    });
                  }}
                />
                {fieldState.error && fieldState.error.message && (
                  <div className='text-[12px] leading-[12px] p-1 font-[400] text-red-500'>
                    {fieldState.error.message}
                  </div>
                )}
              </div>
            )}
          />
        </div>

        {/* Divider */}
        <div className='w-full h-[1px] bg-[#E5EAEE]'></div>

        {/* 추가 요청사항 */}
        <div className='space-y-3'>
          <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
            요청 내용(선택)
          </div>
          <Controller
            control={form.control}
            name='additionalInfo.additionalRequest'
            rules={{ required: true }}
            render={({ field, fieldState, formState }) => (
              <div
                /** 에러 발생시 포커스 영역 처리 */
                role='button'
                ref={(ref) => {
                  if (fieldState.error && ref) {
                    ref.focus();
                  }
                }}
                tabIndex={0}
              >
                <TextArea
                  className='w-full'
                  maxLength={200}
                  placeholder='할인금액, 출고 서비스, 맞춤 조건 시 계약 여부 등 카 매니저님에게 전달할 내용을 적어주세요.'
                  value={field.value}
                  onChange={(e) => {
                    form.setValue(field.name, e.target.value);
                  }}
                />
              </div>
            )}
          />
        </div>
      </div>
    </div>
  );
};

const CarInfo = ({
  selectedCarData,
  totalPrice,
}: {
  selectedCarData: SelectedCarData;
  totalPrice: number;
}) => {
  const totalOptionPrice = useMemo(() => {
    return selectedCarData.carOptionList.reduce((acc, el) => acc + el.price, 0);
  }, [selectedCarData.carOptionList]);

  const [open, setOpen] = useState(false);

  const { data: myInterestCarList, refetch } = useQuery({
    queryKey: ['myInterestCarList'],
    queryFn: () => getInterestCarList(),
  });

  const isInterest = myInterestCarList?.some((el) => {
    return el.id === selectedCarData.carModel.id;
  });

  const { mutate: toggleInterestCar } = useMutation({
    mutationFn: () => {
      if (isInterest) {
        return selectInterestCar(selectedCarData.carModel.id, false);
      } else {
        return selectInterestCar(selectedCarData.carModel.id, true);
      }
    },
    onSuccess: () => {
      refetch();
    },
  });

  const totalColorPrice = useMemo(() => {
    return (
      (selectedCarData.carColor?.price ?? 0) +
      (selectedCarData.interiorCarColor?.price ?? 0) +
      (selectedCarData.interiorCarColor?.internalSelection?.price ?? 0)
    );
  }, [selectedCarData.carColor, selectedCarData.interiorCarColor]);

  return (
    <>
      {/* 모델 이미지 영역 */}
      <div className='flex flex-col gap-2 mt-2 bg-[#F3F6F9]'>
        <div className='flex justify-center items-center h-[220px] px-10'>
          <img
            src={selectedCarData.carModel.image_url}
            alt={selectedCarData.carModel.name}
          />
        </div>
      </div>

      <div className='flex flex-col px-5 py-[30px]'>
        <div className='w-full flex items-center justify-between overflow-hidden text-ellipsis text-[#555] font-medium text-[14px] leading-[14px] tracking-[-0.28px] whitespace-nowrap'>
          {selectedCarData.carMaker.name}

          <div className='cursor-pointer' onClick={() => toggleInterestCar()}>
            <Heart
              fill={isInterest ? '#FF6A6A' : '#B2B7BB'}
              stroke={isInterest ? '#FF6A6A' : '#B2B7BB'}
              strokeWidth={1}
              width={30}
              height={30}
            />
          </div>
        </div>
        <div className='mt-0.5 text-[#222] text-[16px] font-semibold leading-[25.6px] tracking-[-0.32px]'>
          {selectedCarData.carModel.name}
        </div>
        <div className='mt-0.5 overflow-hidden text-[#555] text-[15px] font-medium leading-[24px] tracking-[-0.3px] text-ellipsis'>
          {combineCarName({
            trimName: selectedCarData.carTrim.name,
            gradeName: selectedCarData.carGrade.name,
          })}
        </div>
      </div>

      {/* Divider */}
      <div className='w-full h-2 bg-[#F3F6F9]'></div>

      {/* 가격 영역 */}
      <div className='flex flex-col px-5 py-[30px]'>
        {/* 등급 가격 */}
        <>
          {/* 차량 기본가격 */}
          <div className='flex justify-between'>
            <div className='text-[#7D848A] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
              차량 기본가
            </div>
            <div className='text-[#222] text-right text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
              {selectedCarData.carGrade.price.toLocaleString()}원
            </div>
          </div>
          {/* 색상 가격 */}
          {totalColorPrice > 0 && (
            <div className='flex justify-between mt-5'>
              <div className='text-[#7D848A] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                색상 가격
              </div>
              <div className='text-[#222] text-right text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                {totalColorPrice.toLocaleString()}원
              </div>
            </div>
          )}
          {/* 옵션 가격 */}
          {totalOptionPrice !== 0 && (
            <div className='flex justify-between mt-5'>
              <div className='text-[#7D848A] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                옵션 가격
              </div>
              <div className='text-[#222] text-right text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                {totalOptionPrice.toLocaleString()}원
              </div>
            </div>
          )}

          <div className='mt-4 text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
            개별 소비세가 포함된 가격입니다.
          </div>
        </>

        {/* Divider */}
        <div className='w-full my-6 h-[1px] bg-[#E5EAEE]'></div>

        {/* 옵션 가격 */}
        <div className='flex justify-between items-center'>
          <div className='text-[#555] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
            총 예상 금액
          </div>
          <button
            className='flex-auto mt-[1.5px] flex justify-end items-center gap-1'
            onClick={(e) => {
              setOpen(true);
            }}
          >
            <span className='text-[#222] text-right text-[18px] font-semibold leading-[18px] tracking-[-0.36px]'>
              {totalPrice.toLocaleString()}원
            </span>
            <NoticeIcon />
          </button>
        </div>

        <div className='p-4 mt-6 bg-[#F3F6F9] rounded-[10px] text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
          자동차 할인 금액에 따라 금액은 변동될 수 있습니다.
        </div>
      </div>

      {/* Divider */}
      <div className='w-full h-2 bg-[#F3F6F9]'></div>

      <OrderPurchasePriceInfoDrawer
        open={open}
        setOpen={setOpen}
        totalPrice={totalPrice}
        selectedCarData={selectedCarData}
      />
    </>
  );
};

const OrderPurchase_Submit = ({
  form,
  wholeSellingCar,
  selectedCarData,
  totalPrice,
}: OrderPurchaseSubmitProps) => {
  if (!selectedCarData) return null;

  return (
    <>
      <CarInfo selectedCarData={selectedCarData} totalPrice={totalPrice} />
      <AdditionalInfo form={form} selectedCarData={selectedCarData} />
    </>
  );
};

export default OrderPurchase_Submit;
