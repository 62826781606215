import { cn } from 'utils';

const OrderDealerCommissionPriceData = {
  '중고차 계약 체결 이용료': [
    { title: '2백만원 미만', price: 55000 },
    { title: '2백만원 ~ 3천만원 미만', price: 143000 },
    { title: '3천만원 이상', price: 209000 },
  ],
};

const PriceTable = ({
  content,
}: {
  content: keyof typeof OrderDealerCommissionPriceData;
}) => {
  return (
    <div className='rounded-[8px] border border-[#E5E5EC] divide-y divide-[#E5E5EC]'>
      {OrderDealerCommissionPriceData[content].map((el, index) => {
        return (
          <div className='grid grid-cols-[2fr_1fr]' key={index}>
            <div
              className={cn(
                'flex items-center p-[11px_10px] bg-[#F3F6F9]',
                index === 0 && 'rounded-tl-[8px]',
                index === OrderDealerCommissionPriceData[content].length - 1 &&
                  'rounded-bl-[8px]',
              )}
            >
              <span className='text-[#222] font-medium text-[13px] leading-[1.4] tracking-[-0.26px]'>
                {el.title}
              </span>
            </div>
            <div className='flex items-center justify-end p-[11px_10px]'>
              <span className='text-[#222] text-right text-[13px] font-semibold leading-[13px] tracking-[-0.26px]'>
                {typeof el.price === 'number'
                  ? el.price.toLocaleString() + '원'
                  : el.price}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default function DealerOrderBidNotice_UsedCar() {
  return (
    <div className='flex flex-col gap-2.5 whitespace-pre-line break-keep'>
      {/* 중고차 계약 체결 이용료 */}
      <div>
        <div className='text-[#222] text-[15px] font-semibold leading-[24px] tracking-[-0.3px]'>
          중고차 계약 체결 이용료
        </div>
        <div className='mb-5 overflow-hidden text-[#555] text-[14px] font-medium leading-[22.4px] tracking-[-0.28px]'>
          최초 견적 금액 기준으로 VAT 포함 금액입니다.
        </div>

        {/* 가격표 */}
        <PriceTable content='중고차 계약 체결 이용료' />
      </div>

      {/* 안내사항 영역 */}
      <div className='mt-1'>
        <section className='flex flex-col p-4 pb-[20px] bg-[#F8F9FA] tracking-[-0.025em] w-full rounded-[10px]'>
          <ul className='list-disc pl-4 text-[#555] font-normal text-[14px] leading-[21px] tracking-[-0.28px] space-y-3'>
            <li className='pl-0.5'>카사요 가입비 또는 월 이용료는 없습니다.</li>
            <li className='pl-0.5'>
              계약 체결 시 계약 정보제공 수수료만 받습니다.
            </li>
            <li className='pl-0.5'>
              계약 파기 시 카사요에서 고객에게 내용 확인 후 반환해 드립니다.
            </li>
            <li className='pl-0.5'>
              거래 체결 후 24시간 이내 계약서를 업로드해야 하며, 수수료 입금
              안내 메시지를 받은 즉시 카사요(주) 계좌로 계약 체결
              이용료(수수료)를 입금해야 합니다.
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}
