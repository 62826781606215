/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  FileInfo,
  InquiryIdDTO,
  InquirySubmitDTO,
  InquiryUpdateDTO,
} from '@carsayo/types';
import { ChangeEvent, useEffect, useLayoutEffect, useState } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { InquiryTypeList } from '../../data';
import { Textarea } from 'components/ui/textarea';
import { getInquiryDetail, inquirySubmit, updateInquiry } from 'apis/inquiry';
import useMemberStore from 'store/useMemberStore';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import { InputV2 } from 'components/common/v3/inputv2';
import ImageUpload from 'components/common/v3/fileUploadInput';
import FilePreviewInput from 'components/common/v3/filePreviewInput';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { ChevronLeft } from 'lucide-react';

const InquiryAddPage = () => {
  const { refreshLoginMember } = useMemberStore();
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const navigate = useNavigate();
  const { id } = useParams();
  const location = useLocation();
  //  카집사 물어보기에서 넘어오면 해당 키값이 있습니다.(cargipsaInquiryAdd)
  const [actionAddress, setActionAddress] = useState<string>(
    location.state?.key ? location.state.key : '',
  );

  type InquiryFormDTO = InquirySubmitDTO | InquiryUpdateDTO;

  const [inquiryData, setInquiryData] = useState<InquiryFormDTO>(
    id
      ? {
          inquiryId: id,
          type: InquiryTypeList[0] ?? '',
          title: '',
          content: '',
          file_id_1: null,
          file_id_2: null,
        }
      : {
          type: InquiryTypeList[0] ?? '',
          title: '',
          content: '',
          file_1_id: null,
          file_2_id: null,
        },
  );

  const [files, setFiles] = useState<FileInfo[]>([]);

  const fileIdsForSubmit = {
    file_1_id: files[0]?.id || null,
    file_2_id: files[1]?.id || null,
  };

  const fileIdsForUpdate = {
    file_id_1: files[0]?.id || null,
    file_id_2: files[1]?.id || null,
  };

  // 파일 업로드 및 삭제
  const handleUpload = (item: FileInfo) => {
    setFiles((prevFiles) => [...prevFiles, item]);
  };

  const handleRemove = (id: string) => {
    setFiles(files.filter((file) => file.id !== id));
  };

  const handleSubmit = async () => {
    const submitData = {
      ...inquiryData,
      ...(id ? fileIdsForUpdate : fileIdsForSubmit),
    };

    try {
      if (id) {
        const result = await updateInquiry(submitData as InquiryUpdateDTO);
        if (result) {
          CarsayoToast.success('1:1 문의가 수정되었습니다.');
          navigate('/mypage/inquiry');
        } else {
          console.error('문의 수정 중 에러');
        }
      } else {
        const result = await inquirySubmit(submitData as InquirySubmitDTO);
        if (result) {
          refreshLoginMember();
          actionAddress.length > 0
            ? navigate('/ask/theme/cargipsa', {
                state: {
                  key: ['inquiryComplete'],
                },
              })
            : navigate('/mypage/inquiry', {
                state: undefined,
              });
        } else {
          console.error('1:1 문의 등록 중 에러');
        }
      }
    } catch (error: any) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (id) {
      const fetchData = async () => {
        try {
          const reqData: InquiryIdDTO = {
            inquiryId: id,
          };
          const data = await getInquiryDetail(reqData);
          setInquiryData({
            inquiryId: id,
            type: data.type,
            title: data.title,
            content: data.content,
            file_id_1: data.file_1?.id ?? null,
            file_id_2: data.file_2?.id ?? null,
          });

          const fileData = [];
          if (data.file_1) {
            fileData.push(data.file_1);
          }
          if (data.file_2) {
            fileData.push(data.file_2);
          }
          setFiles(fileData);
        } catch (error) {
          console.error('문의 데이터를 불러오는 중 에러:', error);
        }
      };
      fetchData();
    }
  }, [id]);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: (
        <div
          onClick={() => {
            navigate(-1);
          }}
        >
          <ChevronLeft width={32} height={32} strokeWidth={1.5} />
        </div>
      ),
      title: id ? '1:1 문의 수정' : '1:1 문의 등록',
    });
    setIsBottomTabbar(false);
  }, [id]);

  return (
    <div className='pt-[40px] pb-[10px] px-[20px]'>
      <div>
        <form className='w-full'>
          <fieldset className='flex flex-col justify-start items-start gap-6 pb-[50px]'>
            <div className='w-full'>
              <label
                htmlFor='inquiryType'
                className='block mb-[12px] text-[15px] leading-[15px]'
              >
                문의 유형
              </label>
              <SelectV3
                value={inquiryData.type ?? ''}
                onValueChange={(value) =>
                  setInquiryData((prev) => ({ ...prev, type: value }))
                }
                placeholder=''
              >
                {InquiryTypeList.map((item) => (
                  <SelectV3Item key={`inquiryType_${item}`} value={item}>
                    {item}
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
            <div className='w-full'>
              <label
                htmlFor='inquiryTitle'
                className='block mb-[12px] text-[15px] leading-[15px]'
              >
                문의 제목
              </label>
              <InputV2
                id='inquiryTitle'
                value={inquiryData.title}
                placeholder='제목을 입력해주세요.'
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  setInquiryData((prev) => ({
                    ...prev,
                    title: e.target.value,
                  }));
                }}
              />
            </div>
            <div className='w-full'>
              <label
                htmlFor='inquiryContent'
                className='block mb-[12px] text-[15px] leading-[15px]'
              >
                문의 내용
              </label>
              <div className='relative border border-[#E5E5EC] transition-all rounded-[8px] overflow-hidden focus-within:border-[#333]'>
                <Textarea
                  className='mb-[44px] min-h-[180px] border-0 text-[16px] leading-[25.6px] font-[400] placeholder:text-[#999] py-[12px] px-[16px]'
                  maxLength={1000}
                  placeholder='내용을 입력해 주세요.'
                  value={inquiryData.content}
                  onChange={(e: ChangeEvent<HTMLTextAreaElement>) => {
                    if (e.target.value.length < 501) {
                      setInquiryData((prev) => ({
                        ...prev,
                        content: e.target.value,
                      }));
                    }
                  }}
                ></Textarea>
                <span className='absolute right-4 bottom-3 text-[#999999] text-[13px] leading-[18px]'>
                  {inquiryData.content?.length ?? 0}/1000
                </span>
              </div>
            </div>
            <div className='w-full flex flex-col gap-[12px]'>
              <label
                htmlFor='inquiryContent'
                className='block text-[15px] leading-[15px]'
              >
                파일 첨부
              </label>
              {files.map((file) => (
                <FilePreviewInput
                  key={file.id}
                  currentFileInfo={file}
                  onRemove={handleRemove}
                />
              ))}
              {files.length < 2 && (
                <ImageUpload
                  placeholder='파일 첨부(선택)'
                  id={'file_1_id'}
                  onUpload={handleUpload}
                />
              )}
            </div>

            <div className='w-full rounded-[10px] bg-[#F3F6F9] p-4 pb-[20px] flex flex-col justify-start items-start gap-[20px]'>
              <h5 className='w-full text-[15px] leading-[22.5px] text-[#111] font-[500] pb-4 border-b border-[#E5E5EC]'>
                확인해 주세요
              </h5>
              <ul className='text-[#555555] flex flex-col justify-start items-start gap-[12px]'>
                <li className='flex justify-start items-center relative text-[14px] break-keep leading-[21px] pl-[22px] after:content-["•"] after:absolute after:left-1.5 after:top-0 after:bottom-0 after:my-auto'>
                  문의 내용에 대한 답변은 평일 오전 10시~오후 6시에 진행됩니다.
                </li>
                <li className='flex justify-start items-center relative text-[14px] break-keep leading-[21px] pl-[22px] after:content-["•"] after:absolute after:left-1.5 after:top-0 after:bottom-0 after:my-auto'>
                  접수 건이 많은 경우 순차적으로 처리됩니다.
                </li>
                <li className='flex justify-start items-center relative text-[14px] break-keep leading-[21px] pl-[22px] after:content-["•"] after:absolute after:left-1.5 after:top-0 after:bottom-0 after:my-auto'>
                  문의 내용과 무한한 개인정보는 기재하지 않도록 주의해 주세요.
                </li>
              </ul>
            </div>
          </fieldset>
          <div className='flex justify-start items-center gap-2.5'>
            <button
              type='button'
              className='border border-[#E5E5EC] shrink-0 bg-[#fff] w-[85px] flex justify-center items-center h-[54px] p-[19px] rounded-[10px] text-[16px] leading-[16px] font-[500]'
              onClick={() => navigate(-1)}
            >
              취소
            </button>
            <button
              className='text-white bg-[#7273F9] disabled:bg-[#B2B7BB] w-full flex justify-center items-center h-[54px] p-[20px] rounded-[10px] text-[16px] leading-[16px] font-[500]'
              disabled={
                inquiryData.title && inquiryData.type && inquiryData.content
                  ? false
                  : true
              }
              type='button'
              onClick={handleSubmit}
            >
              문의하기
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default InquiryAddPage;
