import { InsuranceCompany } from '@carsayo/types';
import { Link } from 'react-router-dom';
import { cn } from 'utils';

function InsuranceCard({
  insuranceCompany,
  option,
}: {
  insuranceCompany: InsuranceCompany;
  option?: {
    isPhone: boolean;
  };
}) {
  if (option?.isPhone) {
    return (
      <div key={insuranceCompany.id} rel='noopener noreferrer'>
        <li
          className={cn(
            'rounded-[30px] px-[20px] py-[36px] w-full h-[330px] tiny:h-[350px] relative flex flex-col justify-between gap-[24px] pb-[90px]',
          )}
          style={{ backgroundColor: insuranceCompany.theme_bgcolor }}
        >
          <div className='flex flex-col gap-[24px]'>
            <div className='flex flex-col gap-[14px]'>
              <div className='text-[16px] font-medium leading-[16px]'>
                {insuranceCompany.name}
              </div>
              <div className='text-[20px] font-semibold leading-[22px]'>
                {insuranceCompany.direct.title}
              </div>
            </div>

            <div
              className={`border-l-[2px] pl-[14px] text-[13px] leading-[20.8px] tracking-[-0.325px] font-[400] text-[#222]`}
              style={{
                borderColor: insuranceCompany.theme_color,
              }}
              dangerouslySetInnerHTML={{
                __html: insuranceCompany.direct.description,
              }}
            ></div>
          </div>

          <a
            href={`tel:${insuranceCompany.direct.signup_tel}`}
            className='bg-white rounded-full py-[19px] flex items-center justify-center gap-1.5 cursor-pointer hover:bg-gray-50'
          >
            <svg
              xmlns='http://www.w3.org/2000/svg'
              width='18'
              height='18'
              viewBox='0 0 18 18'
              fill='none'
            >
              <path
                d='M16.479 13.7475C16.479 14.0175 16.419 14.295 16.2915 14.565C16.164 14.835 15.999 15.09 15.7815 15.33C15.414 15.735 15.009 16.0275 14.5515 16.215C14.1015 16.4025 13.614 16.5 13.089 16.5C12.324 16.5 11.5065 16.32 10.644 15.9525C9.78146 15.585 8.91896 15.09 8.06396 14.4675C7.20146 13.8375 6.38396 13.14 5.60396 12.3675C4.83146 11.5875 4.13396 10.77 3.51146 9.915C2.89646 9.06 2.40146 8.205 2.04146 7.3575C1.68146 6.5025 1.50146 5.685 1.50146 4.905C1.50146 4.395 1.59146 3.9075 1.77146 3.4575C1.95146 3 2.23646 2.58 2.63396 2.205C3.11396 1.7325 3.63896 1.5 4.19396 1.5C4.40396 1.5 4.61397 1.545 4.80147 1.635C4.99646 1.725 5.16896 1.86 5.30397 2.055L7.04397 4.5075C7.17897 4.695 7.27646 4.8675 7.34396 5.0325C7.41146 5.19 7.44897 5.3475 7.44897 5.49C7.44897 5.67 7.39646 5.85 7.29146 6.0225C7.19396 6.195 7.05146 6.375 6.87146 6.555L6.30146 7.1475C6.21896 7.23 6.18146 7.3275 6.18146 7.4475C6.18146 7.5075 6.18897 7.56 6.20397 7.62C6.22647 7.68 6.24897 7.725 6.26397 7.77C6.39897 8.0175 6.63146 8.34 6.96146 8.73C7.29896 9.12 7.65896 9.5175 8.04896 9.915C8.45396 10.3125 8.84396 10.68 9.24146 11.0175C9.63146 11.3475 9.95396 11.5725 10.209 11.7075C10.2465 11.7225 10.2915 11.745 10.344 11.7675C10.404 11.79 10.464 11.7975 10.5315 11.7975C10.659 11.7975 10.7565 11.7525 10.839 11.67L11.409 11.1075C11.5965 10.92 11.7765 10.7775 11.949 10.6875C12.1215 10.5825 12.294 10.53 12.4815 10.53C12.624 10.53 12.774 10.56 12.939 10.6275C13.104 10.695 13.2765 10.7925 13.464 10.92L15.9465 12.6825C16.1415 12.8175 16.2765 12.975 16.359 13.1625C16.434 13.35 16.479 13.5375 16.479 13.7475Z'
                fill='#222222'
                stroke='#222222'
                strokeWidth='1.125'
                strokeMiterlimit='10'
              />
            </svg>
            <div className='text-[#222] text-center text-[18px] font-medium leading-[100%] tracking-[-0.36px]'>
              {insuranceCompany.direct.signup_tel}
            </div>
          </a>

          <div className='h-[28px] w-[50px] absolute right-0 top-[10%] p-[8px_15px] gap-[10px] rounded-[20px_0px_0px_20px] bg-[#22222280] text-white font-[700] text-[14px] leading-[14px]'>
            AD
          </div>
          <div className='absolute bottom-0 left-0 rounded-bl-[30px] rounded-br-[30px] bg-[#F8F9FA] px-5 py-[13px] w-full justify-between flex items-center max-h-[100px]'>
            <div className='text-[#555555] text-[12px] leading-[19.5px] font-[400]'>
              {insuranceCompany.direct.certification}
            </div>
            <img
              className='w-[44px] h-[44px]'
              alt={insuranceCompany.name}
              src={insuranceCompany.logo_url}
            />
          </div>
        </li>
      </div>
    );
  }

  return (
    <Link
      key={insuranceCompany.id}
      to={
        insuranceCompany.direct.signup_url_web ??
        insuranceCompany.direct.signup_url_mobile ??
        ''
      }
      target='_blank'
      rel='noopener noreferrer'
    >
      <li
        className={cn(
          'cursor-pointer rounded-[30px] px-[20px] py-[36px] w-full min-h-[265px] tiny:min-h-[285px] relative flex flex-col gap-[24px]',
        )}
        style={{ backgroundColor: insuranceCompany.theme_bgcolor }}
      >
        <div className='flex flex-col gap-[14px]'>
          <div className='text-[16px] font-medium leading-[16px]'>
            {insuranceCompany.name}
          </div>
          <div className='text-[20px] font-semibold leading-[22px]'>
            {insuranceCompany.direct.title}
          </div>
        </div>

        <div
          className={`border-l-[2px] pl-[14px] text-[13px] leading-[20.8px] tracking-[-0.325px] font-[400] text-[#222]`}
          style={{
            borderColor: insuranceCompany.theme_color,
          }}
          dangerouslySetInnerHTML={{
            __html: insuranceCompany.direct.description,
          }}
        ></div>

        <div className='h-[28px] w-[50px] absolute right-0 top-[10%] p-[8px_15px] gap-[10px] rounded-[20px_0px_0px_20px] bg-[#22222280] text-white font-[700] text-[14px] leading-[14px]'>
          AD
        </div>
        <div className='absolute bottom-0 left-0 rounded-bl-[30px] rounded-br-[30px] bg-[#F8F9FA] px-5 py-[13px] w-full justify-between flex items-center max-h-[100px]'>
          <div className='text-[#555555] text-[12px] leading-[19.5px] font-[400] mr-10'>
            {insuranceCompany.direct.certification}
          </div>
          <img
            className='w-[44px] h-[44px]'
            alt={insuranceCompany.name}
            src={insuranceCompany.logo_url}
          />
        </div>
      </li>
    </Link>
  );
}

export default InsuranceCard;
