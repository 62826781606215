/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { OrderPurchaseDefaultProps } from '../../../interface';
import { getCarOption } from 'apis/car';
import { CircularProgress } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import NoticeIcon from '../../../icon/notice';
import { OptionCheckInput } from './ui/OptionCheckbox';
import { cn } from 'utils';
import OptionDescription from './drawer/optionDescription';
import { optionValidation } from 'pages/v2/purchase/utils/optionValidation';
import { CarsayoToastConfirm } from 'components/common/v2/CarsayoConfirm';
import { toast } from 'sonner';
import { CarGrade, CarOption } from '@carsayo/types';
import sortOption from '../../../utils/sortOption';

interface OrderPurchaseModelProps extends OrderPurchaseDefaultProps {
  carGrade?: CarGrade;
  carOptionList: CarOption[];
  isFetching: boolean;
}

const OrderPurchase_Option = ({
  form,
  wholeSellingCar,
  carGrade,
  carOptionList,
  isFetching,
}: OrderPurchaseModelProps) => {
  const queryClient = useQueryClient();
  const currentGradeId = form.getValues('carGradeId');

  /** 옵션 상세보기 Drawer 타겟 */
  const [carOptionDescriptionTarget, setCarOptionDescriptionTarget] = useState<
    number | null
  >(null);

  if (!currentGradeId) return null;

  // 토스트 (옵션 선택시 에러 혹은 수정 제안 제시하는 토스트) 릴리즈
  useEffect(() => {
    return () => {
      const overlay = document.getElementById('toast-overlay');
      if (overlay) {
        document.body.removeChild(overlay);
        toast.dismiss();
      }
    };
  }, []);

  /**
   * isHidden이 false인 옵션만 필터링
   * @note DB에서 가져오는 데이터에서는 항상 false이지만, 색상 선택 시 옵션 선택이 불가능하게 될 수 있습니다.
   */
  const filteredOptionList: CarOption[] = useMemo(() => {
    return carOptionList.filter((el) => {
      return el.isHidden === false;
    });
  }, [carOptionList]);

  /** 필수 카테고리 목록 */
  const essentialCategoryList: string[] = useMemo(() => {
    if (!carGrade) return [];

    const set = new Set<string>();
    filteredOptionList.forEach((option) => {
      if (
        option.category &&
        carGrade.essential_category.includes(option.category)
      )
        set.add(option.category);
    });

    return Array.from(set);
  }, [filteredOptionList, carGrade]);

  const sortedOptionList: Record<string, CarOption[]> = useMemo(() => {
    return sortOption(filteredOptionList, essentialCategoryList);
  }, [filteredOptionList, essentialCategoryList]);

  /** 초기 선택 옵션 리스트 설정
   * @note 필수 옵션 선택 처리
   * @note 필수 카테고리중 첫번째 항목 선택 처리
   */
  useEffect(() => {
    if (!sortedOptionList) return;

    const currentOptionList = form.getValues('optionList');
    const selectedOptionIdSet = new Set<number>();

    // filteredOptionList에 없는 id는 제외하고, 중복을 제거
    const validOptionList = currentOptionList.filter(
      (value, index, self) =>
        self.indexOf(value) === index &&
        filteredOptionList.some((option) => option.id === value),
    );
    validOptionList.forEach((el) => {
      selectedOptionIdSet.add(el);
    });

    // @note 필수 옵션 추가
    // @note 필수 카테고리 옵션 추가 (미선택시에만)
    {
      Object.keys(sortedOptionList).map((key, index) => {
        if (key === 'essential') {
          sortedOptionList.essential.forEach((el) => {
            selectedOptionIdSet.add(el.id);
          });
          return;
        }

        if (key !== '' && sortedOptionList?.[key]) {
          const targetCategory = sortedOptionList[key];
          if (targetCategory.length === 0) return;

          const isSelected = targetCategory.some((el) =>
            selectedOptionIdSet.has(el.id),
          );
          if (isSelected) return;
          selectedOptionIdSet.add(targetCategory[0].id);
          return;
        }

        if (key === '') return;
      });
    }

    form.setValue('optionList', Array.from(selectedOptionIdSet));
  }, [filteredOptionList]);

  return (
    <>
      {isFetching && (
        <div className='flex w-full h-full items-center justify-center'>
          <CircularProgress />
        </div>
      )}
      {filteredOptionList && filteredOptionList.length > 0 && (
        <>
          <div className='p-5 space-y-[24px] pb-0'>
            <div className='text-[#222] text-[18px] font-semibold leading-[18px] tracking-[-0.36px]'>
              옵션 선택
            </div>

            {/* 필수 옵션
            @note essential은 [sortOption] method에서 정의
             */}
            {sortedOptionList.essential &&
              sortedOptionList.essential.length > 0 && (
                <div className='p-4 rounded-[10px] border-[2px] border-[rgba(114,115,249,0.30)] bg-[#F6F6FC]'>
                  <div className='flex gap-2 items-start justify-start mb-4'>
                    <div className='flex items-center justify-center p-1.5 bg-white rounded-[6px]'>
                      <span className='text-[#6A6BE3] text-[13px] font-medium leading-[13px] tracking-[-0.26px]'>
                        필수옵션
                      </span>
                    </div>
                  </div>

                  <div className='space-y-2.5'>
                    {sortedOptionList.essential.map((el, index) => {
                      const isChecked = form
                        .watch('optionList')
                        .includes(el.id);
                      return (
                        <div
                          key={el.id}
                          onClick={() => {
                            // 필수옵션은 선택 해제 불가능
                            if (isChecked) return;

                            form.setValue(
                              'optionList',
                              [...form.getValues('optionList'), el.id],
                              { shouldValidate: true },
                            );
                          }}
                          className={cn(
                            'bg-[#FFF] border-2 border-[#F6F6FC] rounded-[8px] flex justify-between items-center gap-2 p-4',
                          )}
                        >
                          <div className='flex flex-col gap-2.5'>
                            <div>
                              <div className='flex gap-1 items-start justify-start'>
                                <div className='text-[#222] text-[16px] font-medium leading-[24px] tracking-[-0.32px]'>
                                  {el.name}
                                </div>
                                {el.descriptionId && (
                                  <button
                                    className='flex-auto mt-[1.5px]'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setCarOptionDescriptionTarget(
                                        el.descriptionId,
                                      );
                                    }}
                                  >
                                    <NoticeIcon />
                                  </button>
                                )}
                              </div>
                              {el.info && (
                                <div className='text-[#555] text-[13px] font-medium leading-[19.5px] tracking-[-0.26px] mt-1'>
                                  {el.info}
                                </div>
                              )}
                            </div>
                            <div className='text-[#222] text-[14px] font-semibold leading-[14px] tracking-[-0.28px]'>
                              {`${el.price > 0 ? '+' : ''}${el.price.toLocaleString()}원`}
                            </div>
                          </div>
                          <OptionCheckInput
                            className='!text-[14px]'
                            checked={isChecked}
                            disabled={el.isEssential && isChecked}
                            type='radio'
                            readOnly
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}

            {/* 필수 카테고리 옵션 목록
            @note n개 묶음중 하나를 무조건 선택해야 합니다
            */}
            {Object.keys(sortedOptionList).map((key, index) => {
              if (key === 'essential' || key === '') return null;

              const targetOptionList = sortedOptionList[key];

              return (
                <div
                  key={key}
                  className='p-4 rounded-[10px] border-[2px] border-[#7273F9] bg-[#F6F6FC]'
                >
                  <div className='flex gap-2 items-start justify-start mb-4'>
                    <div className='flex items-center justify-center p-1.5 bg-white rounded-[6px]'>
                      <span className='text-[#222] text-[13px] font-medium leading-[13px] tracking-[-0.26px]'>
                        {key}
                      </span>
                    </div>
                    <div className='flex items-center justify-center p-1.5 bg-[#E2E2FF] rounded-[6px]'>
                      <span className='text-[#222] text-[13px] font-medium leading-[13px] tracking-[-0.26px]'>
                        {targetOptionList.length}종 택1
                      </span>
                    </div>
                  </div>

                  <div className='space-y-2.5'>
                    {targetOptionList.map((el, index) => {
                      const isChecked = form
                        .watch('optionList')
                        .includes(el.id);
                      return (
                        <div
                          key={el.id}
                          onClick={() => {
                            // 필수 카테고리 선택 해제 불가능
                            if (isChecked) return;

                            const currentOptionIdList =
                              form.getValues('optionList');
                            const currentOptionList = filteredOptionList.filter(
                              (option) => {
                                // 선택한 항목이면 통과
                                if (option.id === el.id) return true;
                                // 다른 라디오 선택항목이면 선택 제외
                                if (
                                  targetOptionList
                                    .map((el) => el.id)
                                    .includes(option.id)
                                )
                                  return false;

                                return currentOptionIdList.includes(option.id);
                              },
                            );

                            /** 옵션 유효성 검사 */
                            const optionValidity = optionValidation({
                              /**  */
                              optionList: currentOptionList.concat(el),
                              selectedOption: el,
                              isAdded: true,
                              optionData: filteredOptionList,
                              option: { isInit: true },
                            });

                            if (optionValidity.validation.error === true) {
                              if (optionValidity.suggestion) {
                                CarsayoToastConfirm.confirm(
                                  `다음 내용을 확인 후 진행해 주세요.\n• ${optionValidity.suggestion.message.join('\n• ')}`,
                                  () => {
                                    if (!optionValidity.suggestion) return;
                                    form.setValue(
                                      'optionList',
                                      optionValidity.suggestion.optionList.map(
                                        (el) => el.id,
                                      ),
                                      { shouldValidate: true },
                                    );
                                  },
                                  () => {},
                                );
                              } else {
                                CarsayoToastConfirm.alert(
                                  optionValidity.validation.errorMessage
                                    .length > 0
                                    ? '• ' +
                                        optionValidity.validation.errorMessage.join(
                                          '\n• ',
                                        )
                                    : '현재 선택할 수 없는 옵션입니다.',
                                  () => {
                                    return;
                                  },
                                );
                              }
                              return;
                            } else if (optionValidity.suggestion) {
                              CarsayoToastConfirm.confirm(
                                optionValidity.suggestion.message.join('\n'),
                                () => {
                                  if (!optionValidity.suggestion) return;
                                  form.setValue(
                                    'optionList',
                                    optionValidity.suggestion.optionList.map(
                                      (el) => el.id,
                                    ),
                                    { shouldValidate: true },
                                  );
                                },
                                () => {},
                              );
                              return;
                            }

                            form.setValue(
                              'optionList',
                              currentOptionList.map((el) => el.id),
                              { shouldValidate: true },
                            );
                          }}
                          className={cn(
                            'cursor-pointer bg-[#FFF] border-2 border-[#F6F6FC] rounded-[8px] flex justify-between items-center gap-2 p-4',
                          )}
                        >
                          <div className='flex flex-col gap-2.5'>
                            <div>
                              <div className='flex gap-1 items-start justify-start'>
                                <div className='text-[#222] text-[16px] font-medium leading-[24px] tracking-[-0.32px]'>
                                  {el.name}
                                </div>
                                {el.descriptionId && (
                                  <button
                                    className='flex-auto mt-[1.5px]'
                                    onClick={(e) => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                      setCarOptionDescriptionTarget(
                                        el.descriptionId,
                                      );
                                    }}
                                  >
                                    <NoticeIcon />
                                  </button>
                                )}
                              </div>
                              {el.info && (
                                <div className='text-[#555] text-[13px] font-medium leading-[19.5px] tracking-[-0.26px] mt-1'>
                                  {el.info}
                                </div>
                              )}
                            </div>
                            <div className='text-[#222] text-[14px] font-semibold leading-[14px] tracking-[-0.28px]'>
                              {`${el.price > 0 ? '+' : ''}${el.price.toLocaleString()}원`}
                            </div>
                          </div>
                          <OptionCheckInput
                            className='!text-[14px]'
                            checked={isChecked}
                            disabled={el.isEssential && isChecked}
                            type='radio'
                            readOnly
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              );
            })}

            {/* 그 외 선택 옵션 */}
            {sortedOptionList[''] && sortedOptionList[''].length > 0 && (
              <div className='space-y-2.5'>
                {sortedOptionList[''].map((el, index) => {
                  const isChecked = form.watch('optionList').includes(el.id);
                  return (
                    <div
                      key={index}
                      onClick={() => {
                        const currentOptionIdList =
                          form.getValues('optionList');
                        const currentOptionList = filteredOptionList.filter(
                          (option) => currentOptionIdList.includes(option.id),
                        );

                        const isAddedOption: boolean = !isChecked;

                        /** 옵션 유효성 검사 */
                        const optionValidity = optionValidation({
                          /**  */
                          optionList: isAddedOption
                            ? currentOptionList.concat(el)
                            : currentOptionList.filter(
                                (option) => option.id !== el.id,
                              ),
                          selectedOption: el,
                          isAdded: isAddedOption,
                          optionData: filteredOptionList,
                          option: { isInit: true },
                        });

                        /**
                         * 제안이 "필수 조건" 혹은 "필수 카테고리" 옵션을 변동시킬 경우 제안이 실패합니다.
                         * 제안안에 대한 유효성 검사를 또 합니다.
                         * 제안안에 에러가 있을 경우, 덮어씌웁니다.
                         */
                        const suggestionValidity: {
                          isError: boolean;
                          errorMessage: string[];
                        } = {
                          isError: false,
                          errorMessage: [],
                        };
                        if (optionValidity.suggestion) {
                          const suggestion = optionValidity.suggestion;

                          Object.keys(sortedOptionList).map((key, index) => {
                            if (key === '') return;
                            // 제안안에서 필수옵션이 모두 있는지 검사
                            if (key === 'essential') {
                              for (
                                let i = 0;
                                i < sortedOptionList[key].length;
                                i++
                              ) {
                                const targetCategory = sortedOptionList[key];
                                if (targetCategory.length === 0) break;

                                const essentialOption = targetCategory[i];
                                if (
                                  !suggestion.optionList
                                    .map((el) => el.id)
                                    .includes(essentialOption.id)
                                ) {
                                  suggestionValidity.isError = true;
                                  suggestionValidity.errorMessage.push(
                                    `[${essentialOption.name}] 필수 옵션이 선택 해제될 수 없습니다.`,
                                  );
                                  break;
                                }
                              }
                              return;
                            }

                            sortedOptionList[key];

                            let isExist = false;
                            for (
                              let i = 0;
                              i < sortedOptionList[key].length;
                              i++
                            ) {
                              const targetOption = sortedOptionList[key][i];
                              if (
                                suggestion.optionList
                                  .map((el) => el.id)
                                  .includes(targetOption.id)
                              ) {
                                if (isExist === true) {
                                  suggestionValidity.isError = true;
                                  suggestionValidity.errorMessage.push(
                                    `[${key}] 카테고리 내에서 하나의 옵션이 선택되어야 합니다.`,
                                  );
                                  break;
                                }
                                isExist = true;
                              }
                            }

                            if (isExist === false) {
                              suggestionValidity.isError = true;
                              suggestionValidity.errorMessage.push(
                                `[${key}] 카테고리 내에서 하나의 옵션이 선택되어야 합니다.`,
                              );
                            }

                            return;
                          });
                        }

                        if (optionValidity.validation.error === true) {
                          if (
                            optionValidity.suggestion &&
                            !suggestionValidity.isError
                          ) {
                            CarsayoToastConfirm.confirm(
                              `다음 내용을 확인 후 진행해 주세요.\n• ${optionValidity.suggestion.message.join('\n• ')}`,
                              () => {
                                if (!optionValidity.suggestion) return;
                                form.setValue(
                                  'optionList',
                                  optionValidity.suggestion.optionList.map(
                                    (el) => el.id,
                                  ),
                                  { shouldValidate: true },
                                );
                              },
                              () => {},
                            );
                          } else {
                            CarsayoToastConfirm.alert(
                              optionValidity.validation.errorMessage.length > 0
                                ? '• ' +
                                    optionValidity.validation.errorMessage.join(
                                      '\n• ',
                                    )
                                : '현재 선택할 수 없는 옵션입니다.',
                              () => {
                                return;
                              },
                            );
                          }
                          return;
                        } else if (
                          optionValidity.suggestion &&
                          !suggestionValidity.isError
                        ) {
                          CarsayoToastConfirm.confirm(
                            optionValidity.suggestion.message.join('\n'),
                            () => {
                              if (!optionValidity.suggestion) return;
                              form.setValue(
                                'optionList',
                                optionValidity.suggestion.optionList.map(
                                  (el) => el.id,
                                ),
                                { shouldValidate: true },
                              );
                            },
                            () => {},
                          );
                          return;
                        }

                        if (isChecked) {
                          form.setValue(
                            'optionList',
                            form
                              .getValues('optionList')
                              .filter((id) => id !== el.id),
                            { shouldValidate: true },
                          );
                        } else {
                          form.setValue(
                            'optionList',
                            [...form.getValues('optionList'), el.id],
                            { shouldValidate: true },
                          );
                        }
                      }}
                      className={cn(
                        'bg-[#F6F6FC] border-2 border-[#F6F6FC] rounded-[10px] flex justify-between items-center gap-2 p-4',
                        'cursor-pointer',
                        isChecked && 'border-[#7273F9]',
                      )}
                    >
                      <div className='flex flex-col gap-2.5'>
                        {(el.isEssential || el.category) && (
                          <div className='flex gap-2 items-start justify-start'>
                            {el.category && (
                              <div className='flex items-center justify-center p-1.5 bg-white rounded-[6px]'>
                                <span className='text-[#222] text-[13px] font-medium leading-[13px] tracking-[-0.26px]'>
                                  {el.category}
                                </span>
                              </div>
                            )}
                          </div>
                        )}
                        <div>
                          <div className='flex gap-1 items-start justify-start'>
                            <div className='text-[#222] text-[16px] font-medium leading-[24px] tracking-[-0.32px]'>
                              {el.name}
                            </div>
                            {el.descriptionId && (
                              <button
                                className='flex-auto mt-[1.5px]'
                                onClick={(e) => {
                                  e.preventDefault();
                                  e.stopPropagation();
                                  setCarOptionDescriptionTarget(
                                    el.descriptionId,
                                  );
                                }}
                              >
                                <NoticeIcon />
                              </button>
                            )}
                          </div>
                          {el.info && (
                            <div className='text-[#555] text-[13px] font-medium leading-[19.5px] tracking-[-0.26px] mt-1'>
                              {el.info}
                            </div>
                          )}
                        </div>
                        <div className='text-[#222] text-[14px] font-semibold leading-[14px] tracking-[-0.28px]'>
                          {`${el.price > 0 ? '+' : ''}${el.price.toLocaleString()}원`}
                        </div>
                      </div>
                      <OptionCheckInput
                        className='!text-[14px]'
                        checked={isChecked}
                        disabled={el.isEssential && isChecked}
                        readOnly
                      />
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </>
      )}

      {filteredOptionList && filteredOptionList.length === 0 && (
        <>
          <div className='flex flex-col w-full h-full justify-between p-5'>
            <div className='text-[#222] text-[18px] font-semibold leading-[18px] tracking-[-0.36px]'>
              옵션 선택
            </div>
            <div className='flex-auto flex flex-col gap-6 items-center justify-center'>
              <div>
                <img
                  src={'/assets/images/v2/car/no_option_1.png'}
                  alt='옵션이 없습니다.'
                  width={80}
                  height={80}
                />
              </div>
              <div className='text-[#A1A7AC] text-center text-[18px] font-semibold leading-[18px] tracking-[-0.36px]'>
                옵션이 없는 차량이에요
              </div>
            </div>
          </div>
        </>
      )}

      <OptionDescription
        carOptionDescriptionTarget={carOptionDescriptionTarget}
        setCarOptionDescriptionTarget={setCarOptionDescriptionTarget}
      />
    </>
  );
};

export default OrderPurchase_Option;
