import { Button } from 'components/ui/button';
import { useNavigate } from 'react-router-dom';

const steps = [
  { id: 1, title: '견적 비교하기', time: '48시간 내' },
  { id: 2, title: '딜러 선택하기', time: '30일 내' },
  { id: 3, title: '대면 미팅 및 계약', time: null },
];

const OrderSellingMainPage = () => {
  const navigate = useNavigate();
  return (
    <div className='h-full w-full flex flex-col relative justify-between'>
      <div className='relative flex flex-col flex-auto items-center overflow-y-auto overflow-x-hidden bg-gradient-to-b from-[#D5ECFF] to-[#D4D4FF] py-10 px-5'>
        <div className='text-[#484AF5] font-[600] text-[15px] leading-[150%]'>
          전국 카 매니저에게
        </div>
        <div className='text-center mt-[10px] font-[600] text-[22px] leading-[150%]'>
          간편하게 견적받고
          <br />내 차 팔기
        </div>
        <div className='mt-5 mb-[9px] w-full flex items-center justify-center relative'>
          <img
            src={'/assets/images/v2/car/sell_car_main_1.png'}
            alt='내 차 팔기 메인'
            className='w-[260px] h-[140px]'
          />
          <img
            src={'/assets/images/v2/car/sell_car_main_4.png'}
            alt='메인_clipboard'
            className='w-20 h-20 absolute top-[2rem] left-[-0.5rem]'
          />
          <img
            src={'/assets/images/v2/car/sell_car_main_3.png'}
            alt='메인_money'
            className='w-[4.375rem] h-[4.375rem] absolute top-[-1rem] right-[1rem]'
          />
          <img
            src={'/assets/images/v2/car/sell_car_main_2.png'}
            alt='메인_paper'
            className='w-[5.5625rem] h-[5.5625rem] absolute top-[5.5rem] right-[-1.3rem]'
          />
        </div>
        <div className='bg-white rounded-[20px] px-5 py-6 w-full flex flex-col gap-[14px] z-10'>
          {steps.map((step) => (
            <div key={step.id} className='flex items-center'>
              <div className='mr-[10px] rounded-full bg-[#7273F9] w-5 h-5 flex items-center justify-center text-white font-[600] text-[12px] leading-[100%]'>
                {step.id}
              </div>

              <div className='mr-[6px] font-[500] text-[16px] leading-[150%]'>
                {step.title}
              </div>

              {step.time && (
                <div className='rounded-[100px] bg-[#F3F6F9] py-1 px-[7px] font-[600] text-[12px] leading-[150%]'>
                  {step.time}
                </div>
              )}
            </div>
          ))}

          <Button
            className='mt-[10px]'
            onClick={() => navigate('/order/selling')}
          >
            견적 요청하기
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrderSellingMainPage;
