/* eslint-disable @typescript-eslint/no-unused-vars */
import { SellingOrderDTO, SellingOrderUpdateDTO } from '@carsayo/types';
import { NumericFormat } from 'react-number-format';
import { OrderSellingForm } from '../../../form';
import { useEffect } from 'react';

const OrderSelling_Distance = ({
  sellingOrderData,
  form,
}: {
  sellingOrderData: SellingOrderDTO;
  form: OrderSellingForm;
}) => {
  useEffect(() => {
    // form.setValue(
    //   'drivenDistance',
    //   Number(sellingOrderData?.orderData.accidentHistory?.LAST_MILEAGE) || 0,
    // );
  }, []);
  return (
    <div className='flex flex-col gap-3'>
      <div className='text-[15px] leading-[100%]'>주행거리</div>
      <div className='relative text-[16px] font-[500]'>
        <NumericFormat
          // defaultValue={Number(
          //   sellingOrderData?.orderData.accidentHistory?.LAST_MILEAGE,
          // )}
          value={form.watch('drivenDistance')}
          onValueChange={({ floatValue }) => {
            if (sellingOrderData !== undefined) {
              form.setValue('drivenDistance', floatValue || null);
            }
          }}
          allowLeadingZeros
          thousandSeparator=','
          className='h-[50px] w-full border border-input outline-none text-right pr-[44px] focus:border-[#111111] rounded-[8px]'
        ></NumericFormat>
        <div className='absolute right-0 top-0 flex h-[50px] text-[#7D848A] items-center justify-center px-4'>
          km
        </div>
      </div>
      <div className='bg-[#F3F6F9] rounded-[10px] px-4 py-[15px] flex items-center text-[14px] text-[#555555] justify-between'>
        <div className='font-[400]'>최근 기록</div>
        <div className='flex gap-[6px] items-center font-[500]'>
          {Number(
            sellingOrderData?.orderData.accidentHistory?.LAST_MILEAGE,
          ).toLocaleString()}
          <span className='text-[#7D848A]'>km</span>
        </div>
      </div>
    </div>
  );
};

export default OrderSelling_Distance;
