import { cn } from 'utils';
import { BottomTabbarProps } from './type';
import React from 'react';

export default function BottomTabbarLayout({
  isShowing,
  children,
}: BottomTabbarProps & { children: React.ReactNode }) {
  return (
    <div
      className={cn(
        'relative w-full bg-white h-[50px] z-[40] border-t border-[#EEEEEE] shadow-[0px_-2px_15px_#00000014]',
        isShowing ? 'block' : 'hidden',
      )}
    >
      {children}
    </div>
  );
}
