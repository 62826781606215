/* eslint-disable @typescript-eslint/no-unused-vars */
import { DealerOrderDetail } from '@carsayo/types';
import EllipsisVerticial from 'pages/v3/orderDetailTemp/images/EllipsisVerticial';
import { useState } from 'react';
import DealerOrderDetailCancelSubpage from '../../subpage/cancel';
import V3_Confirm from 'components_v3/dialog/Confirm';
import V3_Button from 'components_v3/ui/Button';
import DealerOrderDetailHoldSubpage from '../../subpage/hold';

/** 진행중 상태일 때 사용하는 견적 진행 상태 변경 버튼
 * @note 취소, 보류 서브페이지 포함
 */
export default function DealerOrderDetailProgressEllipsis({
  orderDetail,
}: {
  orderDetail: DealerOrderDetail;
}) {
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isCancelSubpageOpen, setIsCancelSubpageOpen] = useState(false);
  const [isHoldSubpageOpen, setIsHoldSubpageOpen] = useState(false);
  return (
    <>
      <div
        className='cursor-pointer'
        onClick={() => {
          setIsConfirmOpen(true);
        }}
      >
        <EllipsisVerticial />
      </div>

      <V3_Confirm
        onConfirm={() => {
          setIsConfirmOpen(!isConfirmOpen);
        }}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
        title={''}
        agreeText='닫기'
        isAlert={true}
        blockUsingHistory={true}
      >
        <div className='flex flex-col items-center justify-center gap-5'>
          <div className='flex flex-col items-center justify-center gap-1.5 w-full'>
            <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
              견적 진행 상태 변경
            </div>

            <div className='flex flex-col gap-2.5 w-full mt-[20px] px-4'>
              <V3_Button
                className='w-full'
                onClick={(e) => {
                  setIsCancelSubpageOpen(true);
                  setIsConfirmOpen(false);
                }}
              >
                주문 취소
              </V3_Button>

              <V3_Button
                className='w-full'
                onClick={() => {
                  setIsHoldSubpageOpen(true);
                  setIsConfirmOpen(false);
                }}
              >
                주문 보류
              </V3_Button>
            </div>
          </div>
        </div>
      </V3_Confirm>

      <DealerOrderDetailCancelSubpage
        orderListData={orderDetail}
        isOpen={isCancelSubpageOpen}
        setIsOpen={setIsCancelSubpageOpen}
      />
      <DealerOrderDetailHoldSubpage
        orderListData={orderDetail}
        isOpen={isHoldSubpageOpen}
        setIsOpen={setIsHoldSubpageOpen}
      />
    </>
  );
}
