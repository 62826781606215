import { SigunguId } from '@carsayo/types';
import { parseSigunguId } from 'utils/typeConvert';

export default function parseSigungu(value: SigunguId) {
  const sigungu = parseSigunguId(value);
  const sido = sigungu?.sido;

  return {
    name: `${sido?.name} ${sigungu?.name ? sigungu?.name : ''}`.trim(),
    sidoId: sido?.id,
    sigunguId: sigungu?.id,
    sidoName: sido?.name,
    sigunguName: sigungu?.name,
  };
}
