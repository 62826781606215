/* eslint-disable @typescript-eslint/no-unused-vars */
import SubPage from 'components/view/SubPage';
import {
  DealerOrderBidMode,
  DealerOrderDetailSubpageProps,
} from 'pages/v3/orderDetailTemp/type';
import DealerOrderBidSubpage_UPSERT from './upsert';
import DealerOrderBidSubpage_VIEW from './view';

/**
 * 견적서 작성&발성 서브페이지
 * @note 내차팔기: 가격 0원 이상
 * @note 신차, 리스렌트: 전달사항 입력 or 견적서 파일 제출
 */
export default function DealerOrderBidSubpage({
  mode,
  setMode,
  isOpen,
  setIsOpen,
  orderDetail,
}: DealerOrderDetailSubpageProps & {
  mode: DealerOrderBidMode;
  setMode: (mode: DealerOrderBidMode) => void;
}) {
  return (
    <SubPage isOpen={isOpen} setIsOpen={setIsOpen}>
      {(mode === 'create' || mode === 'update') && (
        <DealerOrderBidSubpage_UPSERT
          mode={mode}
          setMode={setMode}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          orderDetail={orderDetail}
        />
      )}
      {mode === 'view' && (
        <DealerOrderBidSubpage_VIEW
          mode={mode}
          setMode={setMode}
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          orderDetail={orderDetail}
        />
      )}
    </SubPage>
  );
}
