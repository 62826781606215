/* eslint-disable @typescript-eslint/no-unused-vars */
import { BankCodeData } from '@carsayo/types';
import { api } from 'apis';
import { Input } from 'components/ui/input';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import V3_Button from 'components_v3/ui/Button';
import dayjs from 'dayjs';
import { X } from 'lucide-react';
import { useState } from 'react';
import { toast } from 'sonner';
import { TossPaymentListData } from './pay';
import { useQueryClient } from '@tanstack/react-query';
import { TossPaymentStatus } from 'pages/v3/order/payment/api/tosspayment';
import { cancelPayment, RefundPaymentProps } from 'apis/payment';

export default function RefundInfo({
  setIsOpen,
  item,
}: {
  setIsOpen: (open: boolean) => void;
  item: TossPaymentListData;
}) {
  const queryClient = useQueryClient();

  const updatePaymentClientData = ({
    paymentKey,
    status,
  }: {
    paymentKey: string;
    status: TossPaymentStatus;
  }) => {
    queryClient.setQueryData(
      ['myPaymentList'],
      (oldData: TossPaymentListData[] | undefined) => {
        if (!oldData) return oldData; // 데이터가 없으면 그대로 유지

        const modifiedData = oldData.map((payment) => {
          return payment.payment_key === paymentKey
            ? { ...payment, status: status }
            : payment;
        });

        return modifiedData;
      },
    );
  };

  const [isLoading, setIsLoading] = useState(false);
  const [virtualData, setVirtualData] = useState({
    refundAcctNo: '',
    refundBankCd: '',
    refundAcctNm: '',
  });

  const [refundData, setRefundData] = useState<RefundPaymentProps>({
    paymentId: item.payment_key,
    reason: '',
  });

  const handleInputChange = (field: string, value: string) => {
    setVirtualData((prev) => ({ ...prev, [field]: value }));
  };

  const handleSubmit = async () => {
    setIsLoading(true);
    if (refundData.reason === '') {
      toast.error('취소 사유를 입력해 주세요.');
      setIsLoading(false);
      return;
    }

    if (
      item.method === 'VIRTUAL_ACCOUNT' &&
      item.status === TossPaymentStatus.DONE &&
      (!virtualData.refundAcctNo ||
        !virtualData.refundBankCd ||
        !virtualData.refundAcctNm)
    ) {
      toast.error('환불 정보를 입력해 주세요.');
      setIsLoading(false);
      return;
    }

    if (item.method === 'VIRTUAL_ACCOUNT') {
      refundData.virtual = virtualData;
    }

    const response = await cancelPayment({
      paymentKey: item.payment_key,
      cancelReason: refundData.reason,
      method: item.method,
      bankCode: virtualData.refundBankCd,
      accountNumber: virtualData.refundAcctNo,
      holderName: virtualData.refundAcctNm,
    });

    if (response.data.code) {
      toast.error(response.data.message ?? '결제 취소에 실패했습니다.');
      setIsOpen(false);
      setIsLoading(false);
      return;
    } else if (response.data.paymentKey && response.data.status) {
      toast.success('결제가 취소되었습니다.');
      updatePaymentClientData({
        paymentKey: response.data.paymentKey,
        status: response.data.status,
      });
      setIsOpen(false);
      setIsLoading(false);
      return;
    } else {
      toast.error('결제 취소에 실패했습니다.');
      setIsOpen(false);
      setIsLoading(false);
      return;
    }
  };

  return (
    <div className='h-[100dvh] w-full bg-white flex flex-col'>
      <div className='relative flex h-[56px] items-center justify-center px-[16px] py-[12px]'>
        <span className='flex items-center justify-center text-[20px] font-[600] leading-[20px]'>
          결제 취소
        </span>
        <button
          className='absolute right-[16px] top-[12px]'
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <X
            className='stroke-1'
            width={32}
            height={32}
            color='#222222'
            fill='#222222'
          />
        </button>
      </div>
      <div className='overflow-y-auto'>
        <div className='w-full p-[20px] border-b-[8px] border-[#F3F6F9] flex justify-start items-center gap-4'>
          <div className='w-[90px] h-[90px] shrink-0 rounded-[10px] bg-[#F3F6F9] flex justify-center items-center'>
            <img
              src={'/assets/images/v2/carsayo_card.png'}
              alt='카사요 카드 이미지'
              className='w-[70px] object-contain'
            />
          </div>
          <div className='w-full flex flex-col justify-start items-start gap-[2px]'>
            <strong className='w-full flex justify-start items-center text-[15px] leading-[24px] font-[600]'>
              {item.purchase_item.title}
            </strong>
            <p className='w-full flex justify-start items-center mb-[10px] text-[14px] leading-[22.4px] font-[500] text-[#555] break-keep'>
              {item.purchase_item.description}
            </p>
            <strong className='w-full flex justify-start items-center text-[16px] leading-[16px] font-[600]'>
              {item.amount.toLocaleString()}원
            </strong>
          </div>
        </div>
        <div className='w-full px-[20px] py-[22px] border-b-[8px] border-[#F3F6F9] flex flex-col justify-start items-start gap-[24px]'>
          <strong className='text-[18px] leading-[18px] font-[600]'>
            주문 정보
          </strong>
          <div className='w-full flex flex-col gap-[20px] bg-[#F3F6F9] px-[16px] py-[20px] rounded-[19px]'>
            <div className='flex justify-between text-[15px] font-[500]'>
              <div className='text-[#7D848A]'>결제금액</div>
              <div>{item.amount.toLocaleString()}원</div>
            </div>
            <div className='flex justify-between text-[15px] font-[500]'>
              <div className='text-[#7D848A]'>결제일시</div>
              <div>{dayjs(item.updated_at).format('YYYY.MM.DD HH:mm')}</div>
            </div>
            <div className='flex justify-between text-[15px] font-[500]'>
              <div className='text-[#7D848A]'>결제방법</div>
              <div>가상계좌</div>
            </div>
          </div>
        </div>
        <div className='w-full px-[20px] py-[22px] border-b-[8px] border-[#F3F6F9] flex flex-col justify-start items-start gap-[10px]'>
          <strong className='text-[18px] leading-[18px] font-[600]'>
            취소 정보
          </strong>

          <Input
            className='!mt-0 h-[50px] px-[16px] py-[17px] text-[16px] leading-[16px] rounded-[10px]'
            placeholder='취소 사유'
            onChange={(e) =>
              setRefundData((prev) => ({ ...prev, reason: e.target.value }))
            }
          />
        </div>
        {item.method === 'VIRTUAL_ACCOUNT' &&
          item.status === TossPaymentStatus.DONE && (
            <div className='w-full px-[20px] py-[22px] border-b-[8px] border-[#F3F6F9] flex flex-col justify-start items-start gap-[10px]'>
              <strong className='text-[18px] leading-[18px] font-[600]'>
                환불 정보
              </strong>
              <div className='w-full flex gap-[20px] bg-[#F3F6F9] px-[16px] py-[20px] rounded-[19px] justify-between text-[15px] font-[500]'>
                <div className='text-[#7D848A]'>환불금액</div>
                <div>{item.amount.toLocaleString()}원</div>
              </div>

              <Select
                onValueChange={(value) =>
                  handleInputChange('refundBankCd', value)
                }
              >
                <SelectTrigger className='rounded-[10px] text-[16px] font-[400]'>
                  <SelectValue placeholder='은행' />
                </SelectTrigger>
                <SelectContent>
                  {BankCodeData.map((bank) => (
                    <SelectItem value={bank.id} key={`bank_${bank.id}`}>
                      {bank.name}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

              <Input
                className='!mt-0 h-[50px] px-[16px] py-[17px] text-[16px] leading-[16px] rounded-[10px]'
                placeholder='예금주명'
                onChange={(e) =>
                  handleInputChange('refundAcctNm', e.target.value)
                }
              />

              <Input
                className='!mt-0 h-[50px] px-[16px] py-[17px] text-[16px] leading-[16px] rounded-[10px]'
                placeholder='환불계좌'
                onChange={(e) =>
                  handleInputChange('refundAcctNo', e.target.value)
                }
              />
            </div>
          )}
        <div className='w-full px-[20px] py-[22px]'>
          <div className='w-full rounded-[10px] bg-[#F3F6F9] p-4 pb-[20px] flex flex-col justify-start items-start gap-[20px]'>
            <h5 className='w-full text-[15px] leading-[22.5px] text-[#111] font-[500] pb-4 border-b border-[#E5E5EC]'>
              확인해 주세요
            </h5>
            <ul className='text-[#555555] flex flex-col justify-start items-start gap-[12px]'>
              <li className='flex justify-start items-center relative text-[14px] break-keep leading-[21px] pl-[22px] after:content-["•"] after:absolute after:left-1.5 after:top-0 after:bottom-0 after:my-auto'>
                견적 요청권에 대한 취소는 전액 환불됩니다.
              </li>
              <li className='flex justify-start items-center relative text-[14px] break-keep leading-[21px] pl-[22px] after:content-["•"] after:absolute after:left-1.5 after:top-0 after:bottom-0 after:my-auto'>
                단, 추가된 1회 견적 요청권을 사용하지 않은 경우 전액 환불 요청이
                가능하며 그 이외 상태에서는 불가능합니다.
              </li>
            </ul>
          </div>
        </div>
        <div className='px-[20px] py-[20px] w-full'>
          <V3_Button
            theme='primary'
            className='bg-[#7273F9] py-[19px]'
            type='button'
            isLoading={isLoading}
            disabled={!refundData.reason || isLoading}
            onClick={handleSubmit}
          >
            결제 취소
          </V3_Button>
        </div>
      </div>
    </div>
  );
}
