import { useNavigate } from 'react-router-dom';
import { Drawer, DrawerContent, DrawerHeader, DrawerTitle } from '../ui/drawer';
import { Button } from '../ui/button';
import { X } from 'lucide-react';
import { PurchaseType } from 'pages/v2/purchase';
import { AVALIABLE_ORDER_PRICE } from 'pages/v3/order/payment/constants';

export const ChargeInformationFee = ({
  isOpen,
  setIsOpen,
  orderType,
  purchaseType,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  orderType: 'purchasing' | 'selling';
  purchaseType?: PurchaseType;
}) => {
  const navigate = useNavigate();

  const handleCloseDrawer = () => {
    setIsOpen(false);
    navigate('/main');
  };
  const handlePurchaseClick = () => {
    setIsOpen(false);
    if (orderType === 'purchasing') {
      return navigate('/order/payment/avaliableOrder?type=purchase');
    } else {
      return navigate('/order/payment/avaliableOrder?type=selling');
    }
  };

  return (
    <Drawer open={isOpen}>
      <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
        <DrawerHeader className='relative'>
          <DrawerTitle className='text-left text-center text-lg font-semibold'>
            견적 요청권
          </DrawerTitle>
          <Button
            onClick={handleCloseDrawer}
            className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'
          >
            <X className='h-7 w-7' />
          </Button>
        </DrawerHeader>

        <div className='p-5'>
          <div className='flex justify-start items-center gap-4'>
            <div className='flex justify-center items-center p-2.5 bg-[#F3F6F9] rounded-[10px]'>
              <img
                src='/assets/images/png/carsayo_card.png'
                alt='카사요 기프트카드 이미지'
                className='w-[70px] object-contain'
              />
            </div>
            <div className='flex flex-col justify-start items-start gap-[14px]'>
              <div className='flex flex-col justify-start items-start gap-0.5'>
                <strong className='text-[15px] leading-[24px] font-[600]'>
                  견적 요청권
                </strong>
                <p className='text-[14px] leading-[22.4px] font-[500] text-[#555555]'>
                  {orderType === 'purchasing' ? '신차 구매' : '내 차 팔기'}
                </p>
              </div>
              <strong className='text-[16px] leading-[16px] font-[600]'>
                {AVALIABLE_ORDER_PRICE.toLocaleString()}원
              </strong>
            </div>
          </div>
        </div>

        <div className='px-5 pt-1 pb-[10px]'>
          <div className='bg-[#F3F6F9] rounded-[10px] p-4'>
            <div className='text-[#222] font-semibold text-[15px] leading-[22.5px] tracking-[-0.3px]'>
              무료 견적 요청 2회를 모두 이용했어요.
            </div>

            <div className='w-full h-[1px] bg-[#E5E5EC] my-4'></div>

            <ul className='flex flex-col gap-3 text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px] break-keep list-disc'>
              <li className='ml-5'>
                무료 견적 요청은 신차 구매, 내 차 팔기 각 2건으로 제한됩니다.
              </li>
              <li className='ml-5'>
                2대 초과 견적 요청 시, 건당 1만원의 정보 이용료가 발생합니다.
              </li>
              <li className='ml-5'>
                <span className='text-[#484AF5] font-medium'>
                  카사요를 통해 주문을 진행한 경우
                </span>{' '}
                결제한 견적 요청권 구매금액은{' '}
                <span className='text-[#484AF5] font-medium'>100% 환불</span>해
                드립니다.
              </li>
              <li className='ml-5'>
                결제가 완료되면 무료 견적 신청이 가능하며 마이 페이지 &#62;
                결제내역 페이지에서 확인할 수 있습니다.
              </li>
            </ul>
          </div>
        </div>

        <div className='grid grid-cols-2 gap-2 p-5'>
          <Button
            variant='outline'
            className='bg-white'
            onClick={handleCloseDrawer}
          >
            닫기
          </Button>

          <Button onClick={handlePurchaseClick}>견적 요청권 구매</Button>
        </div>
      </DrawerContent>
    </Drawer>
  );
};
