/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import CarInfoItem from './CarInfoItem';
import dayjs from 'dayjs';
import { SellingOrderDTO } from '@carsayo/types';
import { cn } from 'utils';
import { OrderSellingTab } from '../hooks/useTabHandler';
import { ChevronRight } from 'lucide-react';

const InfoItem = ({
  title,
  desc,
  subDesc,
  onClick,
  className,
}: {
  title: string;
  desc: string;
  subDesc?: string;
  onClick?: () => void;
  className?: string;
}) => {
  return (
    <div
      className={cn('flex items-center text-[15px] font-[500]', className)}
      onClick={onClick}
    >
      <div className='w-[100px] text-[#7D848A] leading-[100%]'>{title}</div>
      <div className='flex gap-[6px] items-center'>
        <div className='leading-[150%]'>{desc}</div>
        {subDesc && (
          <div className='text-[#555555] text-[13px] leading-[100%]'>
            {subDesc}
          </div>
        )}
      </div>
    </div>
  );
};

const CarInfo = ({
  sellingOrderData,
  setShowList,
  setShowPrice,
  currentTab,
}: {
  sellingOrderData: SellingOrderDTO;
  setShowList: (isShowList: boolean) => void;
  setShowPrice: (isShowPrice: boolean) => void;
  currentTab?: keyof typeof OrderSellingTab;
}) => {
  return (
    <>
      <div className='border-b-[1px] border-[#E5EAEE] px-5 pb-[30px]'>
        <CarInfoItem
          img={sellingOrderData?.orderData.usedCarInfo.carInfo.modelimage}
          modelname={`${sellingOrderData?.orderData.usedCarInfo.carInfo.makername} ${sellingOrderData?.orderData.usedCarInfo.carInfo.modelname}`}
          carName={sellingOrderData?.orderData.usedCarInfo.carName}
          type='center'
          setShowPrice={setShowPrice}
        />
      </div>

      <div className='px-5'>
        <Accordion
          type='single'
          defaultValue={currentTab === 'drivenDistance' ? 'item-1' : undefined}
          // defaultValue='item-1'
          collapsible
          className='w-full'
        >
          <AccordionItem value='item-1'>
            <AccordionTrigger className='flex h-[60px] items-center gap-4 rounded-md border-none font-[600] text-[18px] leading-[100%]'>
              차량 정보
            </AccordionTrigger>
            <AccordionContent className='pb-[30px] flex flex-col gap-6'>
              <div className='flex flex-col gap-[10px]'>
                <div className='bg-[#F3F6F9] rounded-[10px] px-4 py-5 flex flex-col gap-3'>
                  <InfoItem
                    title={'연식'}
                    desc={`${sellingOrderData?.orderData.usedCarInfo.carInfo.year}년형`}
                  />
                  <InfoItem
                    title={'최초등록'}
                    desc={dayjs(
                      sellingOrderData?.orderData.usedCarInfo.carInfo.firstdate,
                    ).format('YYYY년 MM월 DD일')}
                  />
                </div>
                <div className='bg-[#F3F6F9] rounded-[10px] px-4 py-5 flex flex-col gap-3'>
                  {sellingOrderData?.orderData.usedCarInfo.carInfo
                    .seriesname && (
                    <InfoItem
                      title={'세부모델'}
                      desc={
                        sellingOrderData?.orderData.usedCarInfo.carInfo
                          .seriesname
                      }
                    />
                  )}
                  {sellingOrderData?.orderData.usedCarInfo.carInfo.cargubun && (
                    <InfoItem
                      title={'차체형태'}
                      desc={
                        sellingOrderData?.orderData.usedCarInfo.carInfo.cargubun
                      }
                    />
                  )}
                  {sellingOrderData?.orderData.usedCarInfo.carInfo.enType && (
                    <InfoItem
                      title={'엔진타입'}
                      desc={
                        sellingOrderData?.orderData.usedCarInfo.carInfo.enType
                      }
                    />
                  )}
                  {sellingOrderData?.orderData.usedCarInfo.carInfo.gearbox && (
                    <InfoItem
                      title={'변속기'}
                      desc={
                        sellingOrderData?.orderData.usedCarInfo.carInfo.gearbox
                      }
                    />
                  )}
                  {sellingOrderData?.orderData.usedCarInfo.carInfo
                    .displacement > 0 && (
                    <InfoItem
                      title={'배기량'}
                      desc={`${sellingOrderData?.orderData.usedCarInfo.carInfo.displacement.toLocaleString()}cc`}
                    />
                  )}
                  {sellingOrderData?.orderData.usedCarInfo.carInfo.fuel && (
                    <InfoItem
                      title={'연료'}
                      desc={
                        sellingOrderData?.orderData.usedCarInfo.carInfo.fuel
                      }
                    />
                  )}
                  {sellingOrderData?.orderData.usedCarInfo.carInfo
                    .stdmileage && (
                    <InfoItem
                      title={'연비'}
                      desc={
                        sellingOrderData?.orderData.usedCarInfo.carInfo
                          .stdmileage
                      }
                    />
                  )}
                  {sellingOrderData?.orderData.usedCarInfo.carInfo.seating && (
                    <InfoItem
                      title={'승차인원'}
                      desc={`${sellingOrderData?.orderData.usedCarInfo.carInfo.seating}명`}
                    />
                  )}
                </div>

                {sellingOrderData?.orderData.accidentHistory && (
                  <div className='bg-[#F3F6F9] rounded-[10px] px-4 py-5 flex flex-col gap-3'>
                    <Accordion type='single' className='w-full' collapsible>
                      <AccordionItem value='item-2'>
                        <AccordionTrigger className='flex h-[23px] items-center text-start border-none font-[600] text-[18px] leading-[100%]'>
                          <InfoItem
                            title={'거래이력'}
                            desc={`소유자 변경 이력 ${Number(
                              sellingOrderData?.orderData.accidentHistory
                                ?.MY_CHANGE_COUNT,
                            )}건`}
                          />
                        </AccordionTrigger>
                        <AccordionContent className='pb-0 flex flex-col gap-6 pl-[115px]'>
                          <ul className='list-disc'>
                            {sellingOrderData?.orderData.accidentHistory.RESP_MY_CHANGE_DATA.map(
                              (el, index) => {
                                return (
                                  <li
                                    key={`changeHistory_${index}`}
                                    className='font-[500] text-[14px] leading-[150%]'
                                  >
                                    {dayjs(el.MY_CHANGE_CHANGE_DATE).format(
                                      'YYYY년 MM월',
                                    )}{' '}
                                    {el.MY_CHANGE_CHANGE_NAME}
                                  </li>
                                );
                              },
                            )}
                          </ul>
                        </AccordionContent>
                      </AccordionItem>
                    </Accordion>
                    <InfoItem
                      title={'주행거리'}
                      desc={
                        Number(
                          sellingOrderData.orderData.accidentHistory
                            .LAST_MILEAGE,
                        ) > 0
                          ? `${Number(
                              sellingOrderData?.orderData.accidentHistory
                                .LAST_MILEAGE,
                            ).toLocaleString()}km`
                          : '정보를 불러올 수 없습니다'
                      }
                      subDesc={
                        sellingOrderData?.orderData.accidentHistory
                          .LAST_MILEAGE_DATE
                          ? `(${dayjs(
                              sellingOrderData?.orderData.accidentHistory
                                .LAST_MILEAGE_DATE,
                            ).format('YYYY년 MM월 기준')})`
                          : ''
                      }
                    />
                    <InfoItem
                      title={'렌트이력'}
                      desc={
                        sellingOrderData.orderData.accidentHistory
                          .RENT_CAR_HISTORY
                      }
                    />

                    <div className='relative'>
                      <InfoItem
                        title={'보험이력'}
                        className='cursor-pointer'
                        onClick={() => {
                          if (
                            Number(
                              sellingOrderData?.orderData.accidentHistory
                                ?.MY_CHANGE_COUNT,
                            ) === 0
                          )
                            return;
                          setShowList(true);
                        }}
                        desc={
                          Number(
                            sellingOrderData?.orderData.accidentHistory
                              ?.MY_CHANGE_COUNT,
                          ) > 0
                            ? dayjs(
                                sellingOrderData.orderData.accidentHistory
                                  .SEARCH_DATE,
                              ).format('YYYY년 MM월 DD일')
                            : '없음'
                        }
                      />
                      {Number(
                        sellingOrderData?.orderData.accidentHistory
                          ?.MY_CHANGE_COUNT,
                      ) > 0 && (
                        <ChevronRight
                          strokeWidth={1.5}
                          color='#666666'
                          className='w-5 h-5 absolute right-0 bottom-0 pointer-events-none'
                        />
                      )}
                    </div>
                  </div>
                )}
              </div>
            </AccordionContent>
          </AccordionItem>
        </Accordion>
      </div>
    </>
  );
};

export default CarInfo;
