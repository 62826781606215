import { useNavigate } from 'react-router-dom';
import SwiperClass from 'swiper/types/swiper-class';
import 'swiper/css';
import 'swiper/css/effect-fade';
import { Swiper, SwiperSlide } from 'swiper/react';
import { addHistoryInsuranceCooconClick } from 'apis/history';
import { cn } from 'utils';
import { useState } from 'react';
import { cooconUrl } from 'utils/cooconUrl';

export default function MainSwiperSection() {
  const [page, setPage] = useState<number>(1);
  const navigate = useNavigate();
  const handleClickEvents = {
    moveToInsurance: () => {
      navigate('/static/insurance');
    },
    moveToRepairList: () => {
      navigate('/ask/repair');
    },
    moveToCharacter: () => {
      navigate('/static/character');
    },
    moveToPromotion: () => {
      navigate('/static/promotion');
      // navigate('/ask/main');
    },
    moveToKodeal: () => {
      window.open('https://kodeal.fks.co.kr/m/carsayoGift/pay');
    },
    openCooconInsurance: () => {
      addHistoryInsuranceCooconClick({
        from: `app${location.pathname}`,
        target: '쿠콘',
      });

      window.open(cooconUrl());
    },
  };

  const slideContents = [
    {
      title: `무이자로 가입하는
      자동차 다이렉트 보험`,
      bgImg: null,
      bgColor: 'bg-gradient-to-t from-[#1A88FF] to-[#4AA1FF]',
      bgIcon: (
        <img
          src='/assets/images/png/main_swiper_Bg01_icon.png'
          alt=''
          className='absolute right-[0px] bottom-[0px] w-[176px] object-contain tiny:right-[-5vw]'
        />
      ),
      actionBtn: (
        <button className='inline-flex justify-center items-center h-[38px] bg-[#FFFFFF26] text-white font-[500] py-2 px-[10px] rounded-[5.6px] text-[14px] leading-[22.4px] backdrop-blur-[11.36px]'>
          여러 보험사 모두 비교 가능
        </button>
      ),
      actionLink: handleClickEvents.moveToInsurance,
    },
    {
      title_sm: '카사요가 엄선한',
      title: `최고의 경험과 기술로 
      차별화된 1급 공업사`,
      bgImg: null,
      bgColor: 'bg-gradient-to-t from-[#008E6F] to-[#239A80]',
      bgIcon: (
        <img
          src='/assets/images/png/main_swiper_Bg04_icon.png'
          alt=''
          className='absolute right-[0px] bottom-[0px] w-[140px] object-contain tiny:right-[-4vw]'
        />
      ),
      actionBtn: (
        <button className='inline-flex justify-center items-center h-[38px] bg-[#FFFFFF26] text-white font-[500] py-2 px-[10px] rounded-[5.6px] text-[14px] leading-[22.4px] backdrop-blur-[11.36px]'>
          가까운 공업사 확인하기
        </button>
      ),
      actionLink: handleClickEvents.moveToRepairList,
    },
    //     {
    //       title: `카사요 GIFT
    // 현금 120만원 캐시백 이벤트`,
    //       bgImg: null,
    //       bgColor: 'bg-gradient-to-t from-[#5555FF] to-[#6F6FFF]',
    //       bgIcon: (
    //         <img
    //           src='/assets/images/png/main_swiper_Bg02_icon.png'
    //           alt=''
    //           className='absolute right-[12px] bottom-[12px] w-[120px] object-contain tiny:right-[-9vw]'
    //         />
    //       ),
    //       actionBtn: (
    //         <button className='inline-flex justify-center items-center h-[38px] bg-[#FFFFFF26] text-white font-[500] py-2 px-[10px] rounded-[5.6px] text-[14px] leading-[22.4px] backdrop-blur-[11.36px]'>
    //           한국영업경제인협회에서 구매하기
    //         </button>
    //       ),
    //       actionLink: handleClickEvents.moveToKodeal,
    //     },
    {
      title: `카사요만의 
      CAR HEROES`,
      bgImg: null,
      bgColor: 'bg-gradient-to-t from-[#605041] to-[#8B7969]',
      bgIcon: (
        <img
          src='/assets/images/png/main_swiper_Bg03_icon.png'
          alt=''
          className='absolute right-[10px] bottom-[0px] w-[140px] object-contain tiny:right-[-4vw]'
        />
      ),
      actionBtn: (
        <button className='inline-flex justify-center items-center h-[38px] bg-[#FFFFFF26] text-white font-[500] py-2 px-[10px] rounded-[5.6px] text-[14px] leading-[22.4px] backdrop-blur-[11.36px]'>
          캐릭터 스토리 보러가기
        </button>
      ),
      actionLink: handleClickEvents.moveToCharacter,
    },
  ];

  const repeatSlidesIfNeeded = (
    contents: typeof slideContents,
    minSlides = 4,
  ) => {
    if (contents.length >= minSlides) return contents;

    return [...contents, ...contents];
  };

  const slidesToShow = repeatSlidesIfNeeded(slideContents);

  return (
    <div className='relative'>
      <Swiper
        onSlideChange={(swiper: SwiperClass) => {
          setTimeout(() => {
            setPage(swiper.realIndex + 1);
          }, swiper.params.speed);
        }}
        speed={300}
        className='w-full'
        fadeEffect={{ crossFade: true }}
        slidesPerView={1.12}
        spaceBetween={10}
        centeredSlides={true}
        loop={true}
      >
        {slidesToShow.map((item, index) => (
          <SwiperSlide
            onClick={item.actionLink}
            key={item.title + index}
            className='w-full !h-auto rounded-[12px] overflow-hidden relative'
          >
            <div
              style={{
                backgroundImage: item.bgImg ? `url(${item.bgImg})` : undefined,
              }}
              className={cn(
                'w-full h-full min-h-[200px] bg-no-repeat bg-right bg-cover p-[20px] relative overflow-hidden',
                item.bgColor && item.bgColor,
              )}
            >
              <div className='flex flex-col justify-start items-start h-full gap-[12px] text-white'>
                <div className='pt-[4px]'>
                  {item.title_sm && (
                    <strong className='text-[#9DF3E1] text-[13px] font-semibold leading-[160%] mb-[-2px]'>
                      {item.title_sm}
                    </strong>
                  )}
                  <div className='flex flex-col justify-start items-start gap-[10px]'>
                    <strong className='text-[20px] leading-[32px] font-[600] whitespace-pre-line'>
                      {item.title}
                    </strong>
                  </div>
                </div>
                {item.actionBtn}
              </div>
              {item.bgIcon && item.bgIcon}
            </div>
            <div className='absolute right-[10px] top-[10px] inline-flex justify-center items-center bg-[#00000080] text-[13px] leading-[13px] font-[500] text-white z-10 py-[7px] px-[10px] min-w-[44px] rounded-[100px] transition-all'>
              {slideContents.length < 4
                ? page > slideContents.length
                  ? page - slideContents.length
                  : page
                : page}
              /{slideContents.length}
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}
