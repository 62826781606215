/* eslint-disable @typescript-eslint/no-unused-vars */
import { OrderPurchaseDefaultProps } from '../interface';
import { Button } from 'components/ui/button';
import { OrderPurchaseTab } from '../hooks/useTabHandler';
import { useNavigate } from 'react-router-dom';
import ConfirmV2 from 'components/common/v3/confirm';
import { useState } from 'react';
import { submitPurchaseV1 } from 'apis/purchase';
import { useMutation } from '@tanstack/react-query';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import useSystemStore from 'store/useSystemStore';
import { CarMakerId, SelectedCarData, SigunguId } from '@carsayo/types';

interface OrderPurchase_Button extends OrderPurchaseDefaultProps {
  orderPurchaseTab: OrderPurchaseTab;
  goToNextTab: (tab: OrderPurchaseTab) => void;
  goToPrevTab: () => void;
  selectedCarData: SelectedCarData | null;
  totalPrice: number;
}

export default function OrderPurchase_Button({
  form,
  wholeSellingCar,
  selectedCarData,
  orderPurchaseTab,
  totalPrice,
  goToNextTab,
  goToPrevTab,
}: OrderPurchase_Button) {
  const navigate = useNavigate();

  const { setLoadingDim } = useSystemStore();
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const { mutate: submitPurchaseMutation, isPending } = useMutation({
    mutationFn: () => {
      setLoadingDim(true);
      setIsConfirmOpen(false);

      const purchaseInfo = form.getValues('purchaseInfo');

      return submitPurchaseV1({
        carMakerId: form.getValues('carMakerId') as CarMakerId,
        carModelId: form.getValues('carModelId') as number,
        carTrimId: form.getValues('carTrimId') as number,
        carGradeId: form.getValues('carGradeId') as number,
        carColorId: form.getValues('carColorId') ?? null,
        optionList: form.getValues('optionList'),
        purchaseMethod: form.getValues('purchaseMethod'),
        additionalRequest: form.getValues('additionalInfo.additionalRequest'),
        otherCompanyEstimateContent: form.getValues(
          'additionalInfo.otherCompanyEstimateContent',
        ),
        otherCompanyEstimateFileId: form.getValues(
          'additionalInfo.otherCompanyEstimateFileId',
        ),
        purchaseInfo: {
          렌트: {
            ...purchaseInfo.장기렌트,
            deliveryRegion: purchaseInfo?.장기렌트?.deliveryRegion
              ? (Number(purchaseInfo?.장기렌트?.deliveryRegion) as SigunguId)
              : undefined,
          },
          일시불: {
            ...purchaseInfo.일시불,
            residenceRegion: purchaseInfo?.일시불?.residenceRegion
              ? (Number(purchaseInfo?.일시불?.residenceRegion) as SigunguId)
              : undefined,
            deliveryRegion: purchaseInfo?.일시불?.deliveryRegion
              ? (Number(purchaseInfo?.일시불?.deliveryRegion) as SigunguId)
              : undefined,
          },
          할부: {
            ...purchaseInfo.할부,
            residenceRegion: purchaseInfo?.할부?.residenceRegion
              ? (Number(purchaseInfo?.할부?.residenceRegion) as SigunguId)
              : undefined,
            deliveryRegion: purchaseInfo?.할부?.deliveryRegion
              ? (Number(purchaseInfo?.할부?.deliveryRegion) as SigunguId)
              : undefined,
          },
          리스: {
            ...purchaseInfo.리스,
            deliveryRegion: purchaseInfo?.리스?.deliveryRegion
              ? (Number(purchaseInfo?.리스?.deliveryRegion) as SigunguId)
              : undefined,
          },
        },
        selectedCarData: selectedCarData as SelectedCarData,
        totalPrice: totalPrice,
      });
    },
    onSuccess: () => {
      navigate('/order/purchase/finish?key=init');
    },
    onError: (e) => {
      CarsayoToast.error('잠시 후 다시 시도해 주세요.');
    },
    onSettled: () => {
      setLoadingDim(false);
    },
  });

  if (orderPurchaseTab === 'brand') {
    return (
      <Button
        onClick={() => {
          goToNextTab('model');
        }}
        disabled={!form.watch('carMakerId')}
      >
        다음
      </Button>
    );
  }

  if (orderPurchaseTab === 'model') {
    return (
      <>
        <Button variant='outline' className='bg-white' onClick={goToPrevTab}>
          이전
        </Button>
        <Button
          onClick={() => {
            goToNextTab('trimGrade');
          }}
          disabled={!form.watch('carMakerId') || !form.watch('carModelId')}
        >
          다음
        </Button>
      </>
    );
  }

  if (orderPurchaseTab === 'trimGrade') {
    return (
      <>
        <Button variant='outline' className='bg-white' onClick={goToPrevTab}>
          이전
        </Button>
        <Button
          onClick={() => {
            goToNextTab('color');
          }}
          disabled={
            !form.watch('carMakerId') ||
            !form.watch('carModelId') ||
            !form.watch('carTrimId') ||
            !form.watch('carGradeId')
          }
        >
          다음
        </Button>
      </>
    );
  }

  if (orderPurchaseTab === 'color') {
    return (
      <>
        <Button variant='outline' className='bg-white' onClick={goToPrevTab}>
          이전
        </Button>
        <Button
          onClick={() => {
            goToNextTab('option');
          }}
          disabled={
            !form.watch('carMakerId') ||
            !form.watch('carModelId') ||
            !form.watch('carTrimId') ||
            !form.watch('carGradeId') ||
            !!form.formState.errors.carColorId ||
            !!form.formState.errors.interiorCarColor
          }
        >
          다음
        </Button>
      </>
    );
  }

  if (orderPurchaseTab === 'option') {
    return (
      <>
        <Button variant='outline' className='bg-white' onClick={goToPrevTab}>
          이전
        </Button>
        <Button
          onClick={() => {
            goToNextTab('method');
          }}
          disabled={
            !form.watch('carMakerId') ||
            !form.watch('carModelId') ||
            !form.watch('carTrimId') ||
            !form.watch('carGradeId')
          }
        >
          다음
        </Button>
      </>
    );
  }

  if (orderPurchaseTab === 'method') {
    return (
      <>
        <Button variant='outline' className='bg-white' onClick={goToPrevTab}>
          이전
        </Button>
        <Button
          onClick={async () => {
            const currentPurchaseMethod = form.getValues('purchaseMethod');

            await form.clearErrors();
            await form.trigger(`purchaseInfo.${currentPurchaseMethod}`); // 유효성 검사 후 상태 업데이트 대기

            const currentPurchaseInfoError = form.getFieldState(
              `purchaseInfo.${currentPurchaseMethod}`,
            ).error; // 최신 상태 가져오기

            if (!currentPurchaseInfoError) {
              goToNextTab('submit');
            }
          }}
          disabled={
            !form.watch('carMakerId') ||
            !form.watch('carModelId') ||
            !form.watch('carTrimId') ||
            !form.watch('carGradeId') ||
            !form.watch('purchaseInfo')
          }
        >
          다음
        </Button>
      </>
    );
  }

  if (orderPurchaseTab === 'submit') {
    return (
      <>
        <Button variant='outline' className='bg-white' onClick={goToPrevTab}>
          이전
        </Button>
        <Button
          onClick={() => {
            const currentValue = form.getValues();
            const isFileRequired =
              currentValue.isSuperCar ||
              (currentValue.purchaseMethod !== '리스' &&
                currentValue.purchaseMethod !== '장기렌트');

            const isFileUploaded =
              currentValue.additionalInfo.otherCompanyEstimateFileId;

            if (isFileRequired && !isFileUploaded) {
              return form.setError(
                'additionalInfo.otherCompanyEstimateFileId',
                {
                  message: '파일을 업로드 해 주세요.',
                },
              );
            }

            return setIsConfirmOpen(true);

            // goToNextTab('submit');
          }}
          // disabled={
          //   !form.watch('carMakerId') ||
          //   !form.watch('carModelId') ||
          //   !form.watch('carGradeId') ||
          //   !form.watch('carTrimId') ||
          //   !form.watch('purchaseInfo')
          // }
        >
          견적 요청
        </Button>
        <ConfirmV2
          title={'견적 요청'}
          desc={`선택하신 차량정보에 대해
견적 요청을 진행하시겠습니까?`}
          confirmText={'견적 요청'}
          cancelText={'취소'}
          confirmColor={
            'text-[#484AF5] text-center text-[15px] font-medium leading-[15px] tracking-[-0.3px]'
          }
          isCloseButton={true}
          isOpen={isConfirmOpen}
          setIsOpen={setIsConfirmOpen}
          onConfirm={() => {
            submitPurchaseMutation();
          }}
        />
      </>
    );
  }

  return (
    <>
      <Button variant='outline' className='bg-white' onClick={goToPrevTab}>
        이전
      </Button>
      <Button>다음</Button>
    </>
  );
}
