import { Input } from 'components/ui/input';
import { HandleChangeEvents, InputValue } from '../../../util/types';

const OrderSelling_Info = ({
  inputValue,
  handleChangeEvents,
}: {
  inputValue: InputValue;
  handleChangeEvents: HandleChangeEvents;
}) => {
  return (
    <div className='flex flex-col px-5'>
      <div className='font-[600] text-[20px] leading-[160%]'>
        차량 정보를 입력해 주세요.
      </div>
      <div className='flex flex-col gap-6 mt-[50px]'>
        <div className='flex flex-col gap-3'>
          <div>차량 번호</div>
          <Input
            placeholder='차량번호를 입력해 주세요.'
            className='rounded-[8px]'
            value={inputValue.carName}
            onChange={handleChangeEvents.carName}
          />
        </div>
        <div className='flex flex-col gap-3'>
          <div>소유자명</div>
          <Input
            placeholder='이름을 입력해 주세요.'
            className='rounded-[8px]'
            value={inputValue.ownerName}
            onChange={handleChangeEvents.ownerName}
          />
        </div>
      </div>
      <div className='mt-[10px] bg-[#F3F6F9] rounded-[10px] flex items-center py-[14px] px-4 text-[#555555] text-[14px] leading-[150%]'>
        자동차등록증에 기재된 소유자명을 입력해 주세요.
      </div>
    </div>
  );
};

export default OrderSelling_Info;
