/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { X } from 'lucide-react';
import { useQuery } from '@tanstack/react-query';
import { getCarOptionDescription } from 'apis/purchase';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

export default function OptionDescription({
  carOptionDescriptionTarget,
  setCarOptionDescriptionTarget,
}: {
  carOptionDescriptionTarget: number | null;
  setCarOptionDescriptionTarget: (target: number | null) => void;
}) {
  const navigate = useNavigate();
  const { data: carOptionDescription, isFetching } = useQuery({
    queryKey: ['carOptionDescription', carOptionDescriptionTarget],
    queryFn: async () => {
      if (!carOptionDescriptionTarget) return undefined;
      return await getCarOptionDescription({
        carOptionDescriptionId: carOptionDescriptionTarget,
      });
    },
    enabled: carOptionDescriptionTarget !== null,
  });

  useEffect(() => {
    if (!window.native) return;

    if (carOptionDescriptionTarget !== null) {
      window.native.onBackPressed = () => {
        setCarOptionDescriptionTarget(null);
      };
    } else {
      window.native.onBackPressed = () => {
        navigate(-1);
      };
    }

    return () => {
      if (window.native) {
        window.native.onBackPressed = () => {
          navigate(-1);
        };
      }
    };
  }, [carOptionDescriptionTarget]);

  return (
    <Drawer
      open={carOptionDescriptionTarget !== null}
      onOpenChange={(open) => {
        if (!open) setCarOptionDescriptionTarget(null);
      }}
    >
      {
        <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
          <DrawerHeader className='relative'>
            <DrawerTitle>옵션 설명</DrawerTitle>
            <DrawerClose asChild>
              <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
                <X className='h-7 w-7' />
              </Button>
            </DrawerClose>
          </DrawerHeader>
          {carOptionDescription && (
            <div className='grow overflow-y-auto px-4'>
              <div className='py-4'>
                <div className='text-[#222] text-[16px] font-semibold leading-[25.6px] tracking-[-0.32px]'>
                  {carOptionDescription.name}
                </div>
                {carOptionDescription.content !== '' && (
                  <div className='py-2 whitespace-pre-line text-[#222] text-[15px] font-normal leading-[24px] tracking-[-0.3px]'>
                    {carOptionDescription.content}
                  </div>
                )}
                {carOptionDescription.imgSrc && (
                  <img
                    className='mt-2 border border-gray-100 rounded-[10px] w-full h-[180px] object-cover'
                    src={carOptionDescription.imgSrc}
                  ></img>
                )}
              </div>

              {carOptionDescription.include.length > 0 && (
                <div className='border-t py-4 flex flex-col gap-8'>
                  {carOptionDescription.include.map((el, index) => {
                    return (
                      <div key={index}>
                        <div className='py-2 flex flex-col gap-2 whitespace-pre-line'>
                          <div className='text-[#222] text-[16px] font-semibold leading-[25.6px] tracking-[-0.32px]'>
                            {el.name}
                          </div>
                          {el.content !== '' && (
                            <div className='text-[#222] text-[15px] font-normal leading-[24px] tracking-[-0.3px]'>
                              {el.content}
                            </div>
                          )}
                        </div>

                        {el.imgSrc && (
                          <img
                            className='border border-gray-100 rounded-[10px] w-full h-[180px] object-cover'
                            src={el.imgSrc}
                          ></img>
                        )}
                      </div>
                    );
                  })}
                </div>
              )}

              <div className='pb-8 pt-4 '>
                <Button
                  onClick={() => {
                    setCarOptionDescriptionTarget(null);
                  }}
                  className='rounded'
                  variant='default'
                  size='lg'
                >
                  확인
                </Button>
              </div>
            </div>
          )}
        </DrawerContent>
      }
    </Drawer>
  );
}
