import { useState } from 'react';
import TabbarPen from '../../../images/TabbarPen';
import DealerOrderDetailMemoSubpage from './subpage/memo';

/** 메모 서브페이지 실행 버튼과 세트 */
export default function DealerOrderDetailMemo({
  orderId,
}: {
  orderId: string;
}) {
  if (!orderId) return null;

  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <button onClick={() => setIsOpen(true)}>
        <TabbarPen />
      </button>
      <DealerOrderDetailMemoSubpage
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        orderId={orderId}
      />
    </>
  );
}
