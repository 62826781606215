import Button from 'components_v3/ui/Button';
import { X } from 'lucide-react';
import { useEffect, useLayoutEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';
import { AVALIABLE_ORDER_PRICE } from '../constants';
import dayjs from 'dayjs';
import useMemberStore from 'store/useMemberStore';

export default function AvaliableOrderPurchaseFailurePage() {
  const [searchParams] = useSearchParams();
  const message = searchParams.get('message');
  const type = searchParams.get('type') as 'purchase' | 'selling' | null;

  const navigate = useNavigate();

  const { loginMember } = useMemberStore();

  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();

  useEffect(() => {
    if (!window.native) return;
    window.native.onBackPressed = () => {
      navigate(`/order/payment/avaliableOrder?type=${type}`);
    };
    return () => {
      if (!window.native) return;
      window.native.onBackPressed = () => {};
    };
  }, []);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '견적 요청권 구매',
      rightElement: (
        <div className='min-w-[28px] cursor-pointer'>
          <X
            onClick={() => {
              navigate('/main');
            }}
            width={28}
            height={28}
            strokeWidth={1.5}
          />
        </div>
      ),
    });
    setIsBottomTabbar(false);
  }, []);
  return (
    <div className='w-full flex flex-col justify-between gap-[50px] items-start min-h-[calc(100dvh-60px)]'>
      <div className={cn('w-full', 'mb-[50px]')}>
        <div className='pt-[55px] pb-[106px] flex justify-center items-center'>
          <div className='w-full flex flex-col justify-center items-center gap-1.5'>
            <div className='mb-[18px] flex justify-center items-center w-[60px] h-[60px]'>
              <img
                src='/assets/images/png/purchase_cancel.png'
                alt='결제 실패 이미지'
                className='h-full object-contain'
              />
            </div>
            <strong className='text-[22px] leading-[33px] font-[600]'>
              결제가 실패되었습니다.
            </strong>
            <p className='text-[16px] leading-[25.6px] font-[500]'>{message}</p>
          </div>
        </div>
        <div>
          {/* 구매 서비스 */}
          <div className='w-full pb-[30px] px-5 border-b-[8px] border-[#F3F6F9]'>
            <div className='mb-6 text-[18px] leading-[18px] font-[600]'>
              구매 서비스
            </div>
            <div className='flex justify-start items-center gap-4'>
              <div className='flex justify-center items-center p-2.5 bg-[#F3F6F9] rounded-[10px]'>
                <img
                  src='/assets/images/png/carsayo_card.png'
                  alt='카사요 기프트카드 이미지'
                  className='w-[70px] object-contain'
                />
              </div>
              <div className='flex flex-col justify-start items-start gap-[14px]'>
                <div className='flex flex-col justify-start items-start gap-0.5'>
                  <strong className='text-[15px] leading-[24px] font-[600]'>
                    견적 요청권
                  </strong>
                  <p className='text-[14px] leading-[22.4px] font-[500] text-[#555555]'>
                    {type === 'purchase' ? '신차 구매' : '내 차 팔기'}
                  </p>
                </div>
                <strong className='text-[16px] leading-[16px] font-[600]'>
                  {AVALIABLE_ORDER_PRICE.toLocaleString()}원
                </strong>
              </div>
            </div>
          </div>
          {/* 주문 정보 */}
          <div className='w-full pt-[30px] pb-[30px] px-5 border-b-[8px] border-[#F3F6F9]'>
            <div className='mb-6 text-[18px] leading-[18px] font-[600]'>
              주문 정보
            </div>
            <ul className='py-5 px-4 bg-[#F3F6F9] rounded-[10px] text-[15px] leading-[15px] font-[500] flex flex-col justify-start items-start gap-5'>
              <li className='w-full flex justify-between items-center gap-5'>
                <span className='text-[#7D848A]'>주문일시</span>
                <strong className='font-[500]'>
                  {dayjs().format('YYYY.MM.DD HH:mm')}
                </strong>
              </li>
              <li className='w-full flex justify-between items-center gap-5'>
                <span className='text-[#7D848A]'>주문자</span>
                <strong className='font-[500]'>{loginMember?.name_real}</strong>
              </li>
              <li className='w-full flex justify-between items-center gap-5'>
                <span className='text-[#7D848A]'>연락처</span>
                <strong className='font-[500]'>
                  {loginMember?.phoneNumber}
                </strong>
              </li>
            </ul>
          </div>
          {/* 결제 정보 */}
          <div className='w-full pt-[30px] px-5'>
            <div className='mb-6 text-[18px] leading-[18px] font-[600]'>
              결제 정보
            </div>
            <ul className='py-5 px-4 bg-[#F3F6F9] rounded-[10px] text-[15px] leading-[15px] font-[500] flex flex-col justify-start items-start gap-4'>
              <li className='w-full flex justify-between items-center gap-5 h-[15px]'>
                <span className='text-[#7D848A]'>결제금액</span>
                <strong className='font-[500] leading-[22.5px]'>
                  {AVALIABLE_ORDER_PRICE.toLocaleString()}원
                </strong>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className='w-full p-5'>
        <Button
          theme='primary'
          onClick={() => navigate(`/order/payment/avaliableOrder?type=${type}`)}
        >
          다시 결제하기
        </Button>
      </div>
    </div>
  );
}
