/* eslint-disable @typescript-eslint/no-unused-vars */
import { ReactNode } from 'react';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { Button } from 'components/ui/button';
import { X } from 'lucide-react';
import V3_Button from 'components_v3/ui/Button';
import { DealerOrderDetailSubpageProps } from 'pages/v3/orderDetailTemp/type';
import DealerOrderBidNotice_NewCar from './notice/newcar';
import DealerOrderBidNotice_UsedCar from './notice/usedcar';
import DealerOrderBidNotice_LeaseRent from './notice/leaseRent';
import { OrderType } from '@carsayo/types';

type DealerOrderBidDrawerProps = {
  setSubpageOpen: (open: boolean) => void;
} & DealerOrderDetailSubpageProps;

const Notice: Record<OrderType, { title: string; content: ReactNode }> = {
  newCar: {
    title: '신차 이용료',
    content: <DealerOrderBidNotice_NewCar />,
  },
  sellCar: {
    title: '중고차 이용료',
    content: <DealerOrderBidNotice_UsedCar />,
  },
  lease: {
    title: '리스 이용료',
    content: <DealerOrderBidNotice_LeaseRent />,
  },
  rent: {
    title: '리스 이용료',
    content: <DealerOrderBidNotice_LeaseRent />,
  },
};

export default function DealerOrderBidDrawer({
  isOpen,
  setIsOpen,
  orderDetail,
  setSubpageOpen,
}: DealerOrderBidDrawerProps) {
  return (
    <Drawer
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) setIsOpen(false);
      }}
    >
      {
        <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
          <DrawerHeader className='relative'>
            <DrawerTitle>{Notice[orderDetail.type].title}</DrawerTitle>
            <DrawerClose asChild>
              <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
                <X className='h-7 w-7' />
              </Button>
            </DrawerClose>
          </DrawerHeader>

          <div className='pt-2.5 p-5 pb-[20px] overflow-y-auto'>
            {Notice[orderDetail.type].content}
          </div>

          <div className='p-5'>
            <V3_Button
              className='w-full'
              theme='primary'
              onClick={() => {
                setSubpageOpen(true);
                setIsOpen(false);
              }}
            >
              모두 확인했어요
            </V3_Button>
          </div>
        </DrawerContent>
      }
    </Drawer>
  );
}
