import { OrderPurchaseDefaultProps } from '../../../interface';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import { BuyerType, SigunguId } from '@carsayo/types';
import { useCallback, useMemo, useState } from 'react';
import { OrderPurchaseFormData } from '../../../form';
import { Controller } from 'react-hook-form';
import { ChevronDown } from 'lucide-react';
import { cn } from 'utils';
import parseSigungu from '../../../utils/parseSigungu';
import { SelectSigungu } from 'components/view/buy/selectSigungu';
import { motion } from 'framer-motion';

interface OrderPurchase_Method_RentProps extends OrderPurchaseDefaultProps {
  totalPrice: number;
}

export default function OrderPurchase_Method_Rent({
  form,
  wholeSellingCar,
  totalPrice,
}: OrderPurchase_Method_RentProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [drawerTarget, setDrawerTarget] = useState<'delivery' | null>(null);
  const handleResidenceRegionChange = useCallback(
    (value: SigunguId) => {
      if (drawerTarget === 'delivery') {
        form.setValue(
          'purchaseInfo.장기렌트.deliveryRegion',
          value.toString(),
          {
            shouldValidate: true,
          },
        );
      }
    },
    [drawerTarget],
  );

  /** 보증금 선택 목록 */
  const depositPriceList: {
    price: number;
    title: string;
    percent: number;
  }[] = useMemo(() => {
    return [0, 10, 15, 20, 30, 40, 50, 60].map((el) => {
      const price = Math.ceil(0.0001 * 0.01 * el * totalPrice) * 10000;
      return {
        price,
        title: `${el}%${price > 0 ? ` (${price.toLocaleString()}원)` : ''}`,
        percent: el,
      };
    });
  }, [totalPrice]);

  /** 선납금 선택 목록 */
  const advancedPaymentPriceList: {
    price: number;
    title: string;
    percent: number;
  }[] = useMemo(() => {
    return [0, 10, 20, 30, 40, 50, 60].map((el) => {
      const price = Math.ceil(0.0001 * 0.01 * el * totalPrice) * 10000;
      return {
        price,
        title: `${el}%${price > 0 ? ` (${price.toLocaleString()}원)` : ''}`,
        percent: el,
      };
    });
  }, [totalPrice]);

  /** 잔존가치 선택 목록 */
  const residualPriceList: {
    price: number;
    title: string;
    percent: number;
  }[] = useMemo(() => {
    return [10, 15, 20, 30, 40, 50, 100].map((el) => {
      const price = Math.ceil(0.0001 * 0.01 * el * totalPrice) * 10000;
      const title =
        el === 100
          ? '최대'
          : `${el}%${price > 0 ? ` (${price.toLocaleString()}원)` : ''}`;
      return {
        price,
        title,
        percent: el,
      };
    });
  }, [totalPrice]);

  /** 약정거리 선택 목록
   * @note 0일 때는 무제한
   * @condition [수입차]
    1. 수입차는 무제한 약정거리 선택 불가
   */
  const agreeDistaceList: {
    distance: number;
    title: string;
  }[] = useMemo(() => {
    const carMaker = wholeSellingCar.find(
      (item) => item.id === form.getValues('carMakerId'),
    );

    return [10000, 20000, 30000, 40000, 0]
      .filter((el) => {
        if (carMaker?.type === 'imported' || carMaker?.type === 'etc') {
          return el !== 0;
        }
        return true;
      })
      .map((el) => {
        const distance = el;
        const title = el === 0 ? '무제한' : `${distance.toLocaleString()}km`;

        return {
          distance,
          title,
        };
      });
  }, [form.watch('carMakerId')]);

  /** 정비 방법 목록
   * @condition [수입차]
    1. 고급형, 최고급형 불가 (일반형만 가능)
   */
  const maintenancePlanList: {
    value: OrderPurchaseFormData['purchaseInfo']['장기렌트']['maintenancePlan'];
    title: string;
  }[] = useMemo(() => {
    const carMaker = wholeSellingCar.find(
      (item) => item.id === form.getValues('carMakerId'),
    );

    if (carMaker?.type === 'imported' || carMaker?.type === 'etc') {
      return [
        {
          value: '일반형',
          title: '일반형 (셀프 정비)',
        },
      ];
    } else {
      return [
        {
          value: '일반형',
          title: '일반형 (셀프 정비)',
        },
        {
          value: '고급형',
          title: '고급형 (입고 정비)',
        },
        {
          value: '최고급형',
          title: '최고급형 (순회 정비)',
        },
      ];
    }
  }, [form.watch('carMakerId')]);

  return (
    <div className='flex flex-col gap-6'>
      {/* 구입주체 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          구입주체
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.장기렌트.buyerType'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value}
                onValueChange={(value) => {
                  form.setValue(field.name, value as BuyerType, {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {Object.keys(BuyerType).map((key) => (
                  <SelectV3Item key={key} value={key}>
                    <div className='flex items-center'>
                      {BuyerType[key as keyof typeof BuyerType]}
                    </div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 계약기간 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          계약기간
        </div>

        <Controller
          control={form.control}
          name='purchaseInfo.장기렌트.contractTerm'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value?.toString()}
                onValueChange={(value) => {
                  form.setValue(field.name, Number(value), {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {[24, 36, 48, 60].map((key) => (
                  <SelectV3Item key={key} value={key}>
                    <div className='flex items-center'>{key}개월</div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 보증금 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          보증금
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.장기렌트.price_deposit'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value?.toString()}
                onValueChange={(value) => {
                  form.setValue(field.name, Number(value), {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {depositPriceList.map((item) => (
                  <SelectV3Item key={item.price} value={item.percent}>
                    <div className='flex items-center'>{item.title}</div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 선납금 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          선납금
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.장기렌트.price_advancedPayment'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value?.toString()}
                onValueChange={(value) => {
                  form.setValue(field.name, Number(value), {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {advancedPaymentPriceList.map((item) => (
                  <SelectV3Item key={item.price} value={item.percent}>
                    <div className='flex items-center'>{item.title}</div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 잔존가치 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          잔존가치
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.장기렌트.price_residual'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value?.toString()}
                onValueChange={(value) => {
                  form.setValue(field.name, Number(value), {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {residualPriceList.map((item) => (
                  <SelectV3Item key={item.price} value={item.percent}>
                    <div className='flex items-center'>{item.title}</div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 인수(탁송)지역 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          인수(탁송)지역
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.장기렌트.deliveryRegion'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div>
              <div
                role='button'
                ref={(ref) => {
                  if (fieldState.error && ref) {
                    ref.focus();
                  }
                }}
                tabIndex={0}
                className={cn(
                  'w-full flex h-[50px] border border-[#E5E5EC] rounded-[8px] items-center justify-center px-4 pr-[12px] text-sm text-[#555555]',
                  fieldState.error &&
                    'border-[#FF4D4D] focus:border-[#FF4D4D] border-solid border',
                )}
                onClick={() => {
                  setDrawerTarget('delivery');
                  setIsOpen(true);
                }}
              >
                <div>
                  {field.value ? (
                    <div className='text-[16px] leading-[16px] font-[400] text-[#222] tracking-[0.00938em]'>
                      {parseSigungu(Number(field.value) as SigunguId)?.name}
                    </div>
                  ) : (
                    <div className='text-[16px] leading-[16px] font-[400] text-gray-500 tracking-[0.00938em]'>
                      선택해 주세요.
                    </div>
                  )}
                </div>
                <motion.div
                  animate={{ rotate: isOpen ? 180 : 0 }}
                  transition={{ duration: 0.3, ease: 'easeInOut' }}
                  className='ml-auto'
                >
                  <ChevronDown
                    width={24}
                    height={24}
                    strokeWidth={1.5}
                    color={'#666666'}
                    className='ml-auto'
                  />
                </motion.div>
              </div>
              {fieldState.error && fieldState.error.message && (
                <div className='text-[12px] leading-[12px] p-1 font-[400] text-red-500'>
                  {fieldState.error.message}
                </div>
              )}
            </div>
          )}
        />
      </div>

      {/* 약정거리 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          약정거리
        </div>

        <Controller
          control={form.control}
          name='purchaseInfo.장기렌트.agreeDistace'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value?.toString()}
                onValueChange={(value) => {
                  form.setValue(field.name, Number(value), {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {agreeDistaceList.map((item) => (
                  <SelectV3Item key={item.distance} value={item.distance}>
                    <div className='flex items-center'>{item.title}</div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 정비 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          정비
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.장기렌트.maintenancePlan'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value?.toString()}
                onValueChange={(value) => {
                  form.setValue(
                    field.name,
                    value as OrderPurchaseFormData['purchaseInfo']['장기렌트']['maintenancePlan'],
                    {
                      shouldValidate: true,
                    },
                  );
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {maintenancePlanList.map((item, index) => (
                  <SelectV3Item key={index} value={item.value}>
                    <div className='flex items-center'>{item.title}</div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 운전자 연령 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          운전자 연령
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.장기렌트.driverMiniumAge'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value?.toString()}
                onValueChange={(value) => {
                  form.setValue(field.name, Number(value), {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {[21, 26].map((item) => (
                  <SelectV3Item key={item} value={item}>
                    <div className='flex items-center'>{item}세 이상</div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 면책금 */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          면책금
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.장기렌트.indemnityAmount'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value?.toString()}
                onValueChange={(value) => {
                  form.setValue(field.name, Number(value), {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {[100000, 200000, 300000].map((item) => (
                  <SelectV3Item key={item} value={item}>
                    <div className='flex items-center'>
                      {item.toLocaleString()}원
                    </div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 보험담보 (자손) */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          보험담보 (자손)
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.장기렌트.personalInjuryCoverage'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value?.toString()}
                onValueChange={(value) => {
                  form.setValue(field.name, Number(value), {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {[30000000, 50000000, 100000000].map((item) => (
                  <SelectV3Item key={item} value={item}>
                    <div className='flex items-center'>
                      {item === 30000000
                        ? '3,000만원'
                        : item === 50000000
                          ? '5,000만원'
                          : item === 100000000
                            ? '1억 원'
                            : `${item.toLocaleString()}원`}
                    </div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 보험담보 (대물) */}
      <div className='space-y-3'>
        <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
          보험담보 (대물)
        </div>
        <Controller
          control={form.control}
          name='purchaseInfo.장기렌트.propertyDamageCoverage'
          rules={{ required: true }}
          render={({ field, fieldState, formState }) => (
            <div
              /** 에러 발생시 포커스 영역 처리 */
              role='button'
              ref={(ref) => {
                if (fieldState.error && ref) {
                  ref.focus();
                }
              }}
              tabIndex={0}
            >
              <SelectV3
                placeholder='선택해 주세요.'
                value={field.value?.toString()}
                onValueChange={(value) => {
                  form.setValue(field.name, Number(value), {
                    shouldValidate: true,
                  });
                }}
                error={!!fieldState.error}
                errorMessage={fieldState.error?.message}
              >
                {[100000000, 200000000, 300000000].map((item) => (
                  <SelectV3Item key={item} value={item}>
                    <div className='flex items-center'>
                      {item === 100000000
                        ? '1억 원'
                        : item === 200000000
                          ? '2억 원'
                          : item === 300000000
                            ? '3억 원'
                            : `${item.toLocaleString()}원`}
                    </div>
                  </SelectV3Item>
                ))}
              </SelectV3>
            </div>
          )}
        />
      </div>

      {/* 지역 선택 Drawer */}
      <SelectSigungu
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        setRegion={handleResidenceRegionChange}
      />
    </div>
  );
}
