/* eslint-disable @typescript-eslint/no-unused-vars */
import SubPage from 'components/view/SubPage';
import { DealerOrderSubpageProps } from 'pages/v3/orderDetailTemp/type';
import { X } from 'lucide-react';
import V3_Button from 'components_v3/ui/Button';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { OrderCancelDTO } from '@carsayo/types';
import { Controller, useForm } from 'react-hook-form';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import { useEffect, useState } from 'react';
import TextArea from 'components_v3/ui/TextArea';
import { holdQuoteApi } from 'apis/quote';
import V3_Confirm from 'components_v3/dialog/Confirm';
import HoldLetter from './images/HoldLetter';
import useInvalidateDealerSearch from 'pages/main/dealer/hook/invalidateDealerSearch';

const TEXT = {
  title: '주문 보류',
  description: `주문을 보류할까요?
고객님께 전달할 내용을 입력해 주세요.`,
  submitButton: '주문 보류',

  select: {
    title: '보류 사유',
    placeholder: '취소 사유를 선택해 주세요.',
  },
  confirm: {
    title: '주문 보류 완료',
    description: `주문이 보류되었습니다.
더 나은 서비스 제공을 위해 노력하겠습니다.`,
  },
};

const SELECT = ['고객 요청', '가격 변동', '내부 정책 변경', '기타'];

/** 주문 취소 서브페이지 */
export default function DealerOrderDetailHoldSubpage({
  orderListData,
  isOpen,
  setIsOpen,
}: DealerOrderSubpageProps) {
  const queryClient = useQueryClient();

  const { InvalidateMyOrderList } = useInvalidateDealerSearch();

  const form = useForm<OrderCancelDTO>({
    defaultValues: {
      orderId: orderListData.id,
      title: SELECT[0],
      reason: '',
    },
  });

  const [isHoldSuccessConfirmOpen, setIsHoldSuccessConfirmOpen] =
    useState(false);

  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      await holdQuoteApi(form.getValues());
    },
    onSuccess: async () => {
      setIsHoldSuccessConfirmOpen(true);
    },
    onError: async (error: any) => {
      const errorMessage: string =
        error?.data?.message ?? '잠시 후 다시 시도해 주세요.';

      return CarsayoToast.error(errorMessage);
    },
  });

  useEffect(() => {
    form.reset();
  }, [open]);

  return (
    <SubPage
      isOpen={isOpen}
      setIsOpen={(isOpen) => {
        setIsOpen(isOpen);
      }}
    >
      {/* Header */}
      <div className='flex flex-none h-14 items-center justify-between bg-white px-5'>
        <div className='pl-4 grow text-center text-[#222] text-[20px] font-semibold leading-[20px] tracking-[-0.4px]'>
          {TEXT.title}
        </div>
        <div
          className='cursor-pointer'
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <X className='w-8 h-8' strokeWidth={1.5} />
        </div>
      </div>

      <div className='flex flex-auto flex-col gap-[10px] justify-between overflow-y-scroll'>
        {/* Form영역 */}
        <div className='px-5 h-auto space-y-6'>
          <div className='py-[30px] text-[#222] text-[18px] font-medium leading-[28.8px] tracking-[-0.36px] whitespace-pre-line'>
            {TEXT.description}
          </div>

          {/* 제목 선택 영역 */}
          <div className='space-y-3'>
            <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
              {TEXT.select.title}
            </div>
            <Controller
              control={form.control}
              name='title'
              rules={{ required: true }}
              render={({ field, fieldState, formState }) => (
                <div
                  /** 에러 발생시 포커스 영역 처리 */
                  role='button'
                  ref={(ref) => {
                    if (fieldState.error && ref) {
                      ref.focus();
                    }
                  }}
                  tabIndex={0}
                >
                  <SelectV3
                    placeholder='선택해 주세요.'
                    value={field.value}
                    onValueChange={(value) => {
                      form.setValue(field.name, value, {
                        shouldValidate: true,
                      });
                    }}
                    error={!!fieldState.error}
                    errorMessage={fieldState.error?.message}
                  >
                    {SELECT.map((item, index) => (
                      <SelectV3Item key={index} value={item}>
                        <div className='flex items-center'>{item}</div>
                      </SelectV3Item>
                    ))}
                  </SelectV3>
                </div>
              )}
            />

            <Controller
              control={form.control}
              name='reason'
              rules={{ required: true }}
              render={({ field, fieldState, formState }) => (
                <div
                  /** 에러 발생시 포커스 영역 처리 */
                  role='button'
                  ref={(ref) => {
                    if (fieldState.error && ref) {
                      ref.focus();
                    }
                  }}
                  tabIndex={0}
                >
                  <TextArea
                    className='w-full'
                    maxLength={1000}
                    minRows={8}
                    placeholder='내용을 입력해 주세요.'
                    value={field.value}
                    onChange={(e) => {
                      form.setValue(field.name, e.target.value);
                    }}
                  />
                </div>
              )}
            />
          </div>
        </div>

        <div className='sticky bottom-0 bg-white p-5 flex flex-none gap-[7px]'>
          <V3_Button
            className='w-full'
            onClick={() => {
              setIsOpen(false);
            }}
          >
            닫기
          </V3_Button>
          <V3_Button
            className='w-full'
            theme='primary'
            disabled={!form.watch().reason}
            isLoading={isPending}
            onClick={() => {
              mutate();
            }}
          >
            {TEXT.submitButton}
          </V3_Button>
        </div>

        <V3_Confirm
          isOpen={isHoldSuccessConfirmOpen}
          setIsOpen={setIsHoldSuccessConfirmOpen}
          onConfirm={async () => {
            setIsHoldSuccessConfirmOpen(false);
            queryClient.invalidateQueries({
              queryKey: ['orderDetail', orderListData.id],
            });
            InvalidateMyOrderList();
            await new Promise((resolve) => setTimeout(resolve, 1000));
            setIsOpen(false);
          }}
          blockUsingHistory={true}
          disableBackdropClose={true}
          title=''
          agreeText='확인'
          isAlert={true}
        >
          <div className='flex flex-col items-center justify-center gap-5'>
            <HoldLetter />
            <div className='flex flex-col items-center justify-center gap-1.5'>
              <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
                {TEXT.confirm.title}
              </div>
              <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
                {TEXT.confirm.description}
              </div>
            </div>
          </div>
        </V3_Confirm>
      </div>
    </SubPage>
  );
}
