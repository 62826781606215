import { formatTimeRemaining } from '../../utils/common';

const RemainTime = ({ time }: { time: Date }) => {
  return (
    <span className='text-[#FF4747] font-[600] text-[13px] px-[6px] h-7 rounded-[6px] bg-[#FFE7E7] inline-flex items-center justify-center gap-1'>
      <img src='/assets/images/png/red_fill_clock.png' className='w-4 h-4' />
      {formatTimeRemaining(String(time))}
    </span>
  );
};

export default RemainTime;
