import dayjs, { Dayjs } from 'dayjs';

/** 특정 시간까지 남은 일시분 텍스트 출력
 * @note 입찰 종료 시간이 지나면 "입찰 종료" 텍스트 출력
 */
export default function getBidEndDiff(bidEndedAt: Dayjs): string | '입찰 종료' {
  const diff = dayjs(bidEndedAt).diff(dayjs(), 'seconds');

  if (diff <= 0) return '입찰 종료';

  const days = Math.floor(diff / (60 * 60 * 24));
  const hours = Math.floor((diff % (60 * 60 * 24)) / (60 * 60));
  const minutes = Math.floor((diff % (60 * 60)) / 60);

  const hoursStr = String(hours).padStart(2, '0');
  const minutesStr = String(minutes).padStart(2, '0');

  return days > 0
    ? `${days}일 ${hoursStr}:${minutesStr}`
    : `${hoursStr}:${minutesStr}`;
}
