/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { CarGrade, CarModel, CarTrim } from '@carsayo/types';
import { OrderPurchaseDefaultProps } from '../../interface';
import { OrderPurchaseForm } from '../../form';
import { useState } from 'react';
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from 'components/ui/accordion';
import { cn } from 'utils';

interface OrderPurchaseModelProps extends OrderPurchaseDefaultProps {}

const TrimGradeList = ({
  form,
  targetModel,
}: {
  form: OrderPurchaseForm;
  targetModel: CarModel & {
    car_trim: (CarTrim & {
      car_grade: CarGrade[];
    })[];
  };
}) => {
  const [openAccordionTrimId, setOpenAccordionTrimId] = useState<string>(); // 열려있는 아코디언 ID 추적

  const handleAccordionChange = (value: string) => {
    setOpenAccordionTrimId(value); // 열려있는 아코디언 ID 상태 업데이트

    /** 아코디언 열릴 때 */
    if (Number(value)) {
      form.setValue('carTrimId', Number(value));
    } else {
      /** 아코디언 닫힐 때 */
      form.setValue('carTrimId', undefined);
      form.setValue('carGradeId', undefined);
    }
  };

  const releaseSelectedOptionColor = () => {
    form.setValue('carColorId', undefined);
    form.setValue('interiorCarColor', undefined);
    form.setValue('optionList', []);
  };

  return (
    <div className='relative flex-auto h-full overflow-y-scroll bg-white p-5'>
      <Accordion
        type='single'
        collapsible
        className='w-full space-y-4'
        defaultValue={
          form.getValues('carTrimId')
            ? `${form.getValues('carTrimId')}`
            : undefined
        }
        value={openAccordionTrimId}
        onValueChange={handleAccordionChange}
      >
        {targetModel.car_trim.map((trim, index1) => (
          <AccordionItem
            key={index1}
            value={`${trim.id}`}
            className='rounded-[10px] bg-[#F3F6F9]'
          >
            <AccordionTrigger className={cn('min-h-[64px] p-4')}>
              <div className='text-[#222] text-[16px] font-semibold leading-[24px] tracking-[-0.32px] text-left'>
                {trim.name}
              </div>
            </AccordionTrigger>
            <AccordionContent className='rounded-b-md pb-0'>
              <div className='space-y-2.5 px-4 pb-4'>
                {trim.car_grade.map((grade, index2) => {
                  const seatCount: string | null = Number(grade.seatingCapacity)
                    ? `${grade.seatingCapacity}인승`
                    : null;

                  const fuelType: string | null = grade.fuel
                    ? grade.fuel
                    : null;

                  const displacement: string | null = Number(grade.displace)
                    ? `${grade.displace}cc`
                    : null;

                  return (
                    <div
                      key={index2}
                      className={cn(
                        'cursor-pointer flex flex-col px-4 py-5 rounded-[8px] border border-[#E5E5EC] bg-white',
                        form.watch('carGradeId') === grade.id &&
                          'border-2 border-[#7273F9] bg-[#F6F6FC]',
                      )}
                      onClick={() => {
                        if (form.watch('carGradeId') === grade.id) {
                          form.setValue('carGradeId', undefined);
                          releaseSelectedOptionColor();
                        } else {
                          form.setValue('carGradeId', grade.id);
                          releaseSelectedOptionColor();
                        }
                      }}
                    >
                      {(seatCount || fuelType || displacement) && (
                        <div className='mb-2 text-[#555] text-[14px] font-semibold leading-[14px] tracking-[-0.28px] flex gap-1'>
                          {seatCount && <div>{seatCount}</div>}
                          {fuelType && <div>{fuelType}</div>}
                          {displacement && <div>{displacement}</div>}
                        </div>
                      )}
                      <div className='mb-2.5 text-[#222] text-[16px] font-medium leading-[24px] tracking-[-0.32px]'>
                        {grade.name}
                      </div>
                      {grade.price > 0 ? (
                        <div className='text-[#222] text-[14px] font-semibold leading-[14px] tracking-[-0.28px]'>
                          {grade.price.toLocaleString()}원
                        </div>
                      ) : (
                        <div></div>
                      )}
                    </div>
                  );
                })}
              </div>
            </AccordionContent>
          </AccordionItem>
        ))}
      </Accordion>
    </div>
  );
};

const OrderPurchase_TrimGrade = ({
  form,
  wholeSellingCar,
}: OrderPurchaseModelProps) => {
  const targetBrand = wholeSellingCar.find((el) => {
    return el.id === form.getValues('carMakerId');
  });
  const targetModel = targetBrand?.car_model?.find((el) => {
    return el.id === form.getValues('carModelId');
  });

  return (
    <>
      {targetModel && <TrimGradeList form={form} targetModel={targetModel} />}
    </>
  );
};

export default OrderPurchase_TrimGrade;
