/** App Main File */
import 'swiper/css';
import 'swiper/css/pagination';
import './styles/swiperCustom.css';
import './global.scss';

import 'react-tooltip/dist/react-tooltip.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useLayoutEffect, useState } from 'react';
import { RouterProvider } from 'react-router-dom';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';
import useDeviceStore from 'store/useDeviceStore';
import { routes } from 'router/browserRouter';

import { enableMapSet } from 'immer';
enableMapSet();

function App() {
  const [queryClient] = useState(() => new QueryClient());

  const { setDeviceType } = useDeviceStore();

  useLayoutEffect(() => {
    dayjs.locale('ko');
    dayjs.extend(relativeTime);
    setDeviceType();
  }, []);

  return (
    <QueryClientProvider client={queryClient}>
      <RouterProvider router={routes} />
    </QueryClientProvider>
  );
}

export default App;
