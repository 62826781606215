import { Member, MemberIdDTO, OrderReview } from '@carsayo/types';
import { api } from 'apis';

/** 카매니저 소개 조회 */
export const getDealerInfo = async (reqData: MemberIdDTO): Promise<Member> => {
  try {
    const response = await api.get('member/dealerInfo', {
      params: reqData,
    });
    const data = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

/** 카매니저 소개 > 받은 리뷰 전체 조회 */
export const getManagerReviewList = async (
  reqData: MemberIdDTO,
): Promise<OrderReview[]> => {
  try {
    const response = await api.get('member/dealerInfo/review', {
      params: reqData,
    });
    const data = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};
