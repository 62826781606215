import { RefObject, useEffect, useState } from 'react';

// (현재 미사용중)
function useKakaoMap<T>(
  type: 'fixed' | 'basic',
  containerRef: RefObject<T extends HTMLElement ? T : HTMLElement>,
  locationX: number,
  locationY: number,
) {
  const [map, setMap] = useState<any>();
  const [markerPosition, setMarkerPosition] = useState<{
    lat: number;
    lng: number;
  }>({
    lat: locationX,
    lng: locationY,
  });
  const [targetAddress, setTargetAddress] = useState<any | null>();

  const moveCenter = (lat: number, lng: number) => {
    if (map) {
      const centerPosition = new (window as any).kakao.maps.LatLng(lat, lng);
      map.panTo(centerPosition);
      setMarkerPosition({ lat, lng });
    }
  };

  const loadKakaoMapScript = () => {
    return new Promise((resolve, reject) => {
      if (typeof window.kakao !== 'undefined') {
        resolve(window.kakao);
        return;
      }

      const script = document.createElement('script');
      script.src = `//dapi.kakao.com/v2/maps/sdk.js?appkey=04f05fca58621dad00aa5c62abdbee8f&libraries=services&autoload=false`;
      script.async = true;
      document.head.appendChild(script);

      script.onload = () => {
        window.kakao.maps.load(() => {
          resolve(window.kakao);
        });
      };

      script.onerror = (e) => {
        reject(new Error('Kakao map script failed to load'));
      };
    });
  };

  const displayMarker = (defaultLocation: any, basicMap: any, kakao: any) => {
    const imageSrc = '/assets/images/v2/marker.svg',
      imageSize = new kakao.maps.Size(40, 40),
      imageOption = { offset: new kakao.maps.Point(20, 35) };

    const markerImage = new kakao.maps.MarkerImage(
      imageSrc,
      imageSize,
      imageOption,
    );

    // 마커를 생성합니다
    const marker = new kakao.maps.Marker({
      map: basicMap,
      position: defaultLocation,
      draggable: type === 'fixed' ? false : true,
      image: markerImage,
    });
    marker.setMap(basicMap);

    return marker;
  };

  useEffect(() => {
    const initializeMap = async () => {
      try {
        const kakao: any = await loadKakaoMapScript();

        if (containerRef.current && kakao) {
          const defaultLocation = new kakao.maps.LatLng(
            markerPosition.lat,
            markerPosition.lng,
          );

          const basicMap = new kakao.maps.Map(containerRef.current, {
            center: defaultLocation,
            level: 3,
          });
          basicMap.setDraggable(true);
          const geocoder = new kakao.maps.services.Geocoder();

          const getLocation = (
            lat: number,
            lng: number,
            kakao: any,
            geocoder: any,
          ) => {
            if (lat && lng && kakao && geocoder) {
              const callback = (
                result: { [key: string]: any },
                status: any,
              ) => {
                if (status === kakao.maps.services.Status.OK) {
                  setTargetAddress(result[0]);
                } else {
                  setTargetAddress(null);
                }
              };
              geocoder.coord2Address(lat, lng, callback);
            }
          };
          const marker = displayMarker(defaultLocation, basicMap, kakao);
          const currentPosition = basicMap.getCenter();
          getLocation(
            currentPosition.getLng(),
            currentPosition.getLat(),
            kakao,
            geocoder,
          );
          // 마커 드래그 이벤트 등록
          kakao.maps.event.addListener(marker, 'dragend', function () {
            const newPosition = marker.getPosition();
            const lat = newPosition.getLat();
            const lng = newPosition.getLng();
            setMarkerPosition({ lat, lng });
            getLocation(lng, lat, kakao, geocoder);
          });

          setMap(basicMap);
        }
      } catch (error) {
        console.error('Kakao 지도 로드 실패:', error);
      }
    };

    initializeMap();
  }, [markerPosition]);

  return { map, moveCenter, markerPosition, targetAddress };
}

export default useKakaoMap;
