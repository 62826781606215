/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { CustomerOrderDetail, DealerOrderDetail } from '@carsayo/types';
import { cn } from 'utils';
import {
  convertBoughtType,
  convertBuyType,
  DutyFreeConditionType,
  slicePrice,
  stringDateAddDot,
} from 'utils/convert';
import { parseSigunguId } from 'utils/typeConvert';

import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import { NavigationOptions } from 'swiper/types/modules/navigation';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useMemo, useState } from 'react';
import download from 'utils/download';

import SellingAccidentHistoryDetail from 'pages/v2/selling/detail/insurance';
import dayjs from 'dayjs';
import ReactHtmlParser from 'react-html-parser';

export default function OrderInfo({
  quoteDetail,
}: {
  quoteDetail: CustomerOrderDetail | DealerOrderDetail;
}) {
  const navigationOptions: NavigationOptions = useMemo(() => {
    return {
      prevEl: '.mySwiper-prev',
      nextEl: '.mySwiper-next',
      disabledClass: '!bg-[#00000033] !cursor-default',
    };
  }, []);

  SwiperCore.use([Navigation]);

  const [isUsedCarPopupOpen, setUsedCarPopupOpen] = useState(false);
  const [currentInfo, setCurrentInfo] = useState<'model' | 'buy'>('model');

  return (
    <>
      <div className='w-full'>
        {/* 상세 정보 탭 메뉴 */}
        <ul className='w-full flex justify-evenly items-center'>
          <li
            className={`w-full cursor-pointer transition-all text-[16px] font-[500] py-[0.875rem] px-4 text-center border-b-[1px] box-border ${currentInfo === 'model' ? 'border-[#111111] text-[#111111]' : 'border-[#E5E5EC] text-[#767676]'}`}
            onClick={() => setCurrentInfo('model')}
          >
            <span>모델정보</span>
          </li>
          <li
            className={`w-full cursor-pointer transition-all text-[16px] font-[500] py-[0.875rem] px-4 text-center border-b-[1px] box-border ${currentInfo === 'buy' ? 'border-[#111111] text-[#111111]' : 'border-[#E5E5EC] text-[#767676]'}`}
            onClick={() => setCurrentInfo('buy')}
          >
            <span>구매정보</span>
          </li>
        </ul>
        {/* 상세 정보 - 모델정보 박스 */}
        <div
          className={cn(
            'transition-all py-[24px]',
            currentInfo === 'model'
              ? 'h-auto opacity-100 translate-y-0 z-[0] pointer-events-auto'
              : 'h-0 opacity-0 translate-y-[20px] z-[-100] pointer-events-none fixed',
          )}
        >
          <ul className='w-full flex flex-col justify-start items-stretch gap-2'>
            <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
              <p className='text-[#767676] min-w-[23vw]'>브랜드</p>
              <p className='text-[#111111]'>{quoteDetail?.brand ?? '없음'}</p>
            </li>
            <li className='w-full flex flex-row gap-[5.5vw] justify-start items-stretch py-2'>
              <p className='text-[#767676] min-w-[23vw]'>모델</p>
              <p className='text-[#111111]'>
                {quoteDetail?.title ?? '모델정보 없음'}
              </p>
            </li>
            <li className='w-full flex flex-row gap-[5.5vw] justify-start items-stretch py-2 pr-2'>
              <p className='text-[#767676] min-w-[23vw]'>트림</p>
              <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                {quoteDetail?.purchase?.selectedCar?.carTrim?.name ?? '없음'}
              </p>
            </li>
            <li className='w-full flex flex-row gap-[5.5vw] justify-start items-stretch py-2 pr-2'>
              <p className='text-[#767676] min-w-[23vw]'>상세등급</p>
              <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                {quoteDetail?.purchase?.selectedCar?.carGrade?.name ?? '없음'}
              </p>
            </li>
            <li className='w-full flex flex-col gap-[5.5vw] justify-start items-start py-2 border-b-[1px] border-dashed border-[#E5E5EC]'>
              <p className='text-[#767676] min-w-[23vw]'>색상</p>
              <div className='text-[#111111] flex flex-col justify-start items-start gap-[10px] pl-2 w-full'>
                {quoteDetail?.purchase?.selectedCar?.carColor ||
                quoteDetail.purchase?.selectedCar.interiorCarColor ? (
                  <ul className='w-full flex flex-col justify-start items-stretch gap-2'>
                    {quoteDetail?.purchase?.selectedCar?.carColor && (
                      <li className='w-full flex flex-row gap-[5.5vw] justify-between items-center py-2 pr-[5vw]'>
                        <p className='text-[#767676] min-w-[23vw]'>
                          {quoteDetail.purchase.selectedCar.carColor.name}
                        </p>
                        <p className='text-[#111111]'>
                          <span className='mr-[2px]'>
                            {quoteDetail.purchase.selectedCar.carColor.price > 0
                              ? `+ ${quoteDetail.purchase.selectedCar.carColor.price.toLocaleString()}`
                              : 0}
                          </span>
                          원
                        </p>
                      </li>
                    )}
                    {quoteDetail.purchase.selectedCar.interiorCarColor && (
                      <>
                        <li className='w-full flex flex-row gap-[5.5vw] justify-between items-center py-2 pr-[5vw]'>
                          <p className='text-[#767676] min-w-[23vw]'>
                            [내장]{' '}
                            {
                              quoteDetail.purchase.selectedCar.interiorCarColor
                                .name
                            }
                          </p>
                          <p className='text-[#111111]'>
                            <span className='mr-[2px]'>
                              {quoteDetail.purchase.selectedCar.interiorCarColor
                                .price > 0
                                ? `+ ${quoteDetail.purchase.selectedCar.interiorCarColor.price.toLocaleString()}`
                                : 0}
                            </span>
                            원
                          </p>
                        </li>
                        {quoteDetail.purchase.selectedCar.interiorCarColor
                          ?.internalSelection && (
                          <li className='w-full flex flex-row gap-[5.5vw] justify-between items-center py-2 pr-[5vw]'>
                            <p className='text-[#767676] min-w-[23vw]'>
                              [내장가니쉬]{' '}
                              {
                                quoteDetail.purchase.selectedCar
                                  .interiorCarColor.internalSelection.name
                              }
                            </p>
                            <p className='text-[#111111]'>
                              <span className='mr-[2px]'>
                                {quoteDetail.purchase.selectedCar
                                  .interiorCarColor.internalSelection.price > 0
                                  ? `+ ${quoteDetail.purchase.selectedCar.interiorCarColor.internalSelection.price.toLocaleString()}`
                                  : 0}
                              </span>
                              원
                            </p>
                          </li>
                        )}
                      </>
                    )}
                  </ul>
                ) : (
                  <label
                    htmlFor='mystery_shopper_check'
                    className={cn(
                      'relative block pl-[30px] pb-4',
                      'after:absolute after:left-0 after:top-0',
                      'after:w-[20px] after:h-[20px] after:border after:text-center',
                      `${!quoteDetail?.purchase?.selectedCar?.carColor ? 'after:content-["✓"] after:text-[#CACAD7] text-[15px] after:border-[#E5E5EC] after:bg-[#F1F1F5]' : 'after:content-[""] after:border-[#E5E5EC] after:bg-[#F1F1F5]'}`,
                    )}
                  >
                    <input
                      type='checkbox'
                      id='mystery_shopper_check'
                      className='hidden disabled:pointer-events-none'
                      checked={!quoteDetail?.purchase?.selectedCar?.carColor}
                      onChange={() => {}}
                      disabled={true}
                    />
                    <span className='w-full text-[14px] text-[#111111]'>
                      색상은 상담 시 결정합니다.
                    </span>
                  </label>
                )}
              </div>
            </li>
            <li className='w-full flex flex-col gap-[5.5vw] justify-start items-start py-2  '>
              <p className='text-[#767676] min-w-[23vw]'>추가옵션</p>
              <div className='text-[#111111] flex flex-col justify-start items-start gap-[10px] w-full pb-[5vh]'>
                {quoteDetail?.purchase?.selectedCar?.carOptionList &&
                quoteDetail.purchase.selectedCar.carOptionList.length > 0 ? (
                  <ul className='w-full flex flex-col justify-start items-start'>
                    {quoteDetail.purchase.selectedCar.carOptionList.map(
                      (item, index) => {
                        return (
                          <li
                            key={`${item.id}_${index}`}
                            className={`w-full flex flex-row gap-[5vw] pl-2 justify-between items-center border-b-[1px] border-dashed border-[#E5E5EC] ${index === 0 ? 'pb-4' : 'py-4'}`}
                          >
                            <p className='text-[#767676]'>
                              {item.name ?? '없음'}
                            </p>
                            <p className='text-[#111111] min-w-[33%] text-right pr-[5vw]'>
                              {item.price
                                ? `+ ${item.price.toLocaleString()}원`
                                : '0원'}
                            </p>
                          </li>
                        );
                      },
                    )}
                  </ul>
                ) : (
                  <span className='w-full text-[#767676] pl-2 pt-2 pb-4 border-b-[1px] border-dashed border-[#E5E5EC]'>
                    추가된 옵션이 없습니다!
                  </span>
                )}
              </div>
            </li>
          </ul>
        </div>
        {/* 상세 정보 - 구매정보 박스 */}
        <div
          className={cn(
            'transition-all py-[24px] pb-8',
            currentInfo === 'buy'
              ? 'h-auto opacity-100 translate-y-0 z-[0] pointer-events-auto'
              : 'h-0 opacity-0 translate-y-[20px] z-[-100] pointer-events-none fixed',
          )}
        >
          <ul className='w-full flex flex-col justify-start items-start gap-2'>
            <li className='w-full flex flex-row gap-[5.5vw] justify-start items-stretch py-2'>
              <p className='text-[#767676] min-w-[23vw]'>유형</p>
              <p className='text-[#111111] flex flex-row grow justify-between items-center'>
                <span>
                  {quoteDetail ? (
                    quoteDetail?.purchase &&
                    quoteDetail.purchase?.purchaseInfo?.method ? (
                      quoteDetail.purchase.purchaseInfo.method
                    ) : (
                      '없음'
                    )
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  )}
                </span>
              </p>
            </li>
            <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
              <p className='text-[#767676] min-w-[23vw]'>구입주체</p>
              <p className='text-[#111111]'>
                {quoteDetail?.purchase &&
                  (quoteDetail.purchase.purchaseInfo?.리스 ? (
                    `${convertBuyType[quoteDetail.purchase.purchaseInfo.리스.buyerType]}`
                  ) : quoteDetail.purchase.purchaseInfo?.렌트 ? (
                    `${convertBuyType[quoteDetail.purchase.purchaseInfo.렌트.buyerType]}`
                  ) : quoteDetail.purchase.purchaseInfo?.할부 ? (
                    `${convertBuyType[quoteDetail.purchase.purchaseInfo.할부.buyerType]}`
                  ) : quoteDetail.purchase.purchaseInfo?.일시불 ? (
                    `${convertBuyType[quoteDetail.purchase.purchaseInfo.일시불.buyerType]}`
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  ))}
              </p>
            </li>
            {quoteDetail?.purchase?.purchaseInfo.method &&
              quoteDetail.purchase.purchaseInfo.method !== '일시불' && (
                <>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                    <p className='text-[#767676] min-w-[23vw]'>
                      {quoteDetail.purchase.purchaseInfo?.할부
                        ? '할부기간'
                        : '계약기간'}
                    </p>
                    <p className='text-[#111111]'>
                      {quoteDetail.purchase.purchaseInfo?.리스 ? (
                        `${quoteDetail.purchase.purchaseInfo.리스.contractTerm}개월`
                      ) : quoteDetail.purchase.purchaseInfo?.렌트 ? (
                        `${quoteDetail.purchase.purchaseInfo.렌트.contractTerm}개월`
                      ) : quoteDetail.purchase.purchaseInfo?.할부 ? (
                        `${quoteDetail.purchase.purchaseInfo.할부.installmentTerm}개월`
                      ) : (
                        <span className='text-[#999999]'>없음</span>
                      )}
                    </p>
                  </li>
                </>
              )}
            {quoteDetail?.purchase?.purchaseInfo.method &&
              quoteDetail.purchase.purchaseInfo.method === '할부' && (
                <>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>
                      선수금(인도금)
                    </p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      {quoteDetail.purchase.purchaseInfo.할부
                        ?.prepaidPrincipalAmount
                        ? `${quoteDetail.purchase.purchaseInfo.할부.prepaidPrincipalAmount.toLocaleString()}원`
                        : '없음'}
                      {quoteDetail.purchase.purchaseInfo.할부
                        ?.isRegistrationFee &&
                      quoteDetail.purchase.purchaseInfo.할부
                        ?.isRegistrationFee === true
                        ? ' (등록비 포함)'
                        : ' (등록비 별도)'}
                    </p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>할부원금</p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      {quoteDetail.purchase.purchaseInfo.할부
                        ?.installmentPrincipal
                        ? `${quoteDetail.purchase.purchaseInfo.할부.installmentPrincipal.toLocaleString()}원`
                        : quoteDetail.purchase.purchaseInfo.할부
                              ?.installmentPrincipal === null
                          ? '생략'
                          : '없음'}
                    </p>
                  </li>
                </>
              )}
            {quoteDetail?.purchase?.purchaseInfo.method &&
              (quoteDetail.purchase.purchaseInfo.method == '할부' ||
                quoteDetail.purchase.purchaseInfo.method == '일시불') && (
                <>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>구입 시기</p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      {quoteDetail.purchase.purchaseInfo.method === '일시불'
                        ? quoteDetail.purchase.purchaseInfo.일시불?.purchaseDate
                          ? stringDateAddDot(
                              quoteDetail.purchase.purchaseInfo.일시불
                                .purchaseDate,
                            )
                          : quoteDetail.purchase.purchaseInfo.일시불
                                ?.purchaseDate === null
                            ? '1개월 이내'
                            : '없음'
                        : quoteDetail.purchase.purchaseInfo.할부?.purchaseDate
                          ? stringDateAddDot(
                              quoteDetail.purchase.purchaseInfo.할부
                                .purchaseDate,
                            )
                          : quoteDetail.purchase.purchaseInfo.할부
                                ?.purchaseDate === null
                            ? '1개월 이내'
                            : '없음'}
                    </p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>거주지역</p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      {quoteDetail.purchase.purchaseInfo.method === '일시불'
                        ? quoteDetail.purchase.purchaseInfo.일시불
                            ?.residenceRegion
                          ? `${
                              parseSigunguId(
                                quoteDetail.purchase.purchaseInfo.일시불
                                  .residenceRegion,
                              )?.sido.name ?? ''
                            } ${
                              parseSigunguId(
                                quoteDetail.purchase.purchaseInfo.일시불
                                  .residenceRegion,
                              )?.name ?? ''
                            }`
                          : '없음'
                        : quoteDetail.purchase.purchaseInfo.method === '할부' &&
                            quoteDetail.purchase.purchaseInfo.할부
                              ?.residenceRegion
                          ? `${
                              parseSigunguId(
                                quoteDetail.purchase.purchaseInfo.할부
                                  .residenceRegion,
                              )?.sido.name ?? ''
                            } ${
                              parseSigunguId(
                                quoteDetail.purchase.purchaseInfo.할부
                                  .residenceRegion,
                              )?.name ?? ''
                            }`
                          : '없음'}
                    </p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>인수지역</p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      {quoteDetail.purchase.purchaseInfo.method === '일시불'
                        ? quoteDetail.purchase.purchaseInfo.일시불
                            ?.deliveryRegion
                          ? `${
                              parseSigunguId(
                                quoteDetail.purchase.purchaseInfo.일시불
                                  .deliveryRegion,
                              )?.sido.name ?? ''
                            } ${
                              parseSigunguId(
                                quoteDetail.purchase.purchaseInfo.일시불
                                  .deliveryRegion,
                              )?.name ?? ''
                            }`
                          : '없음'
                        : quoteDetail.purchase.purchaseInfo.method === '할부' &&
                            quoteDetail.purchase.purchaseInfo.할부
                              ?.deliveryRegion
                          ? `${
                              parseSigunguId(
                                quoteDetail.purchase.purchaseInfo.할부
                                  .deliveryRegion,
                              )?.sido.name ?? ''
                            } ${
                              parseSigunguId(
                                quoteDetail.purchase.purchaseInfo.할부
                                  .deliveryRegion,
                              )?.name ?? ''
                            }`
                          : '없음'}
                    </p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>면세조건</p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      {quoteDetail.purchase.purchaseInfo.method === '일시불'
                        ? quoteDetail.purchase.purchaseInfo.일시불
                            ?.dutyFreeCondition
                          ? `${
                              DutyFreeConditionType[
                                quoteDetail.purchase.purchaseInfo.일시불
                                  .dutyFreeCondition
                              ] ?? ''
                            }`
                          : '없음'
                        : quoteDetail.purchase.purchaseInfo.할부
                              ?.dutyFreeCondition
                          ? `${
                              DutyFreeConditionType[
                                quoteDetail.purchase.purchaseInfo.할부
                                  .dutyFreeCondition
                              ] ?? ''
                            }`
                          : '없음'}
                    </p>
                  </li>
                </>
              )}
            {quoteDetail?.purchase?.purchaseInfo.method &&
              (quoteDetail.purchase.purchaseInfo.method == '리스' ||
                quoteDetail.purchase.purchaseInfo.method == '렌트') && (
                <>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>보증금</p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      {quoteDetail.purchase.purchaseInfo.리스?.price_deposit
                        ? `${quoteDetail.purchase.purchaseInfo.리스.price_deposit}%`
                        : quoteDetail.purchase.purchaseInfo.렌트?.price_deposit
                          ? `${quoteDetail.purchase.purchaseInfo.렌트.price_deposit}%`
                          : '없음'}
                    </p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>선납금</p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      {quoteDetail.purchase.purchaseInfo.리스
                        ?.price_advancedPayment
                        ? `${quoteDetail.purchase.purchaseInfo.리스.price_advancedPayment}%`
                        : quoteDetail.purchase.purchaseInfo.렌트
                              ?.price_advancedPayment
                          ? `${quoteDetail.purchase.purchaseInfo.렌트.price_advancedPayment}%`
                          : '없음'}
                    </p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>잔존가치</p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      {quoteDetail.purchase.purchaseInfo.리스?.price_residual
                        ? quoteDetail.purchase.purchaseInfo.리스
                            ?.price_residual === 100
                          ? '최대'
                          : `${quoteDetail.purchase.purchaseInfo.리스.price_residual}%`
                        : quoteDetail.purchase.purchaseInfo.렌트?.price_residual
                          ? quoteDetail.purchase.purchaseInfo.렌트
                              ?.price_residual === 100
                            ? '최대'
                            : `${quoteDetail.purchase.purchaseInfo.렌트.price_residual}%`
                          : '없음'}
                    </p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>약정거리</p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      {quoteDetail.purchase.purchaseInfo.리스?.agreeDistace
                        ? `${quoteDetail.purchase.purchaseInfo.리스.agreeDistace.toLocaleString()}km`
                        : quoteDetail.purchase.purchaseInfo.렌트?.agreeDistace
                          ? `${quoteDetail.purchase.purchaseInfo.렌트.agreeDistace.toLocaleString()}km`
                          : '무제한'}
                    </p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>인수지역</p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      {quoteDetail.purchase.purchaseInfo.method === '리스'
                        ? quoteDetail.purchase.purchaseInfo.리스?.deliveryRegion
                          ? `${
                              parseSigunguId(
                                quoteDetail.purchase.purchaseInfo.리스
                                  .deliveryRegion,
                              )?.sido.name ?? ''
                            } ${
                              parseSigunguId(
                                quoteDetail.purchase.purchaseInfo.리스
                                  .deliveryRegion,
                              )?.name ?? ''
                            }`
                          : '없음'
                        : quoteDetail.purchase.purchaseInfo.method === '렌트' &&
                            quoteDetail.purchase.purchaseInfo.렌트
                              ?.deliveryRegion
                          ? `${
                              parseSigunguId(
                                quoteDetail.purchase.purchaseInfo.렌트
                                  .deliveryRegion,
                              )?.sido.name ?? ''
                            } ${
                              parseSigunguId(
                                quoteDetail.purchase.purchaseInfo.렌트
                                  .deliveryRegion,
                              )?.name ?? ''
                            }`
                          : '없음'}
                    </p>
                  </li>
                </>
              )}
            {quoteDetail?.purchase?.purchaseInfo.method &&
              quoteDetail.purchase.purchaseInfo.method == '리스' && (
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                  <p className='text-[#767676] min-w-[23vw]'>자동차세</p>
                  <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                    {quoteDetail.purchase.purchaseInfo.리스
                      ?.isIncludingCarTax === true
                      ? '포함'
                      : '미포함'}
                  </p>
                </li>
              )}
            {quoteDetail?.purchase?.purchaseInfo.method &&
              quoteDetail.purchase.purchaseInfo.method == '렌트' && (
                <>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>정비</p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      {quoteDetail.purchase.purchaseInfo.렌트
                        ?.maintenancePlan ?? '정비 선택 없음'}
                    </p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>운전자 연령</p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      {quoteDetail.purchase.purchaseInfo.렌트?.isOver26Age
                        ? '26세 이상'
                        : '21세 이상'}
                    </p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>면책금</p>
                    <p className='text-[#111111] flex justify-start items-center gap-[5px] w-full'>
                      {quoteDetail.purchase.purchaseInfo.렌트?.indemnityAmount
                        ? slicePrice(
                            quoteDetail.purchase.purchaseInfo.렌트
                              .indemnityAmount,
                          )
                        : '없음'}
                    </p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>
                      보험담보 (자손)
                    </p>
                    <p className='text-[#111111] flex justify-start items-center gap-[5px] w-full'>
                      {quoteDetail.purchase.purchaseInfo.렌트
                        ?.personalInjuryCoverage
                        ? slicePrice(
                            quoteDetail.purchase.purchaseInfo.렌트
                              .personalInjuryCoverage,
                          )
                        : '없음'}
                    </p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>
                      보험담보 (대물)
                    </p>
                    <p className='text-[#111111] flex justify-start items-center gap-[5px] w-full'>
                      {quoteDetail.purchase.purchaseInfo.렌트
                        ?.propertyDamageCoverage
                        ? slicePrice(
                            quoteDetail.purchase.purchaseInfo.렌트
                              .propertyDamageCoverage,
                          )
                        : '없음'}
                    </p>
                  </li>
                </>
              )}
            {/* <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                  <p className='text-[#767676] min-w-[23vw]'>만기처리</p>
                  <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                    반납
                  </p>
                </li> */}
          </ul>
        </div>
      </div>

      {/* 팔기유형 상세 정보 박스 */}
      {quoteDetail?.type === 'sellCar' && (
        <div className='w-full'>
          {/* 중고차 정보 박스 */}
          <div className='w-full py-[14px] text-[16px] font-[500] border-b-[1px] border-[#111111]'>
            차량 정보 [{quoteDetail.selling?.usedCarInfo.carName ?? ''}]
          </div>
          {/* 사진 슬라이드 */}
          {quoteDetail?.selling?.file &&
          !Object.values(quoteDetail.selling.file).every(
            (value) => value === null,
          ) ? (
            <div className='w-full h-[37.6vh] relative mt-[24px] z-0'>
              <Swiper
                slidesPerView={1}
                spaceBetween={30}
                navigation={navigationOptions}
                modules={[Navigation]}
                className='h-full'
                centeredSlides={true}
                loop={true}
                slidesPerGroup={1}
              >
                {Object.entries(quoteDetail.selling.file).map(
                  ([key, value], index) => {
                    if (key !== null && value !== null) {
                      return (
                        <SwiperSlide key={`file_${key}`} className='relative'>
                          <img
                            src={value.url}
                            alt={value.name}
                            className='w-full h-full object-cover'
                          />
                          <button
                            className='w-10 h-10 absolute bottom-4 right-4 p-2 rounded-full bg-[#11111199] flex justify-center items-center'
                            onClick={() => download(value)}
                          >
                            <svg
                              xmlns='http://www.w3.org/2000/svg'
                              height='2.25rem'
                              viewBox='0 -960 960 960'
                              width='2.25rem'
                              fill='#e8eaed'
                            >
                              <path d='M480-320 280-520l56-58 104 104v-326h80v326l104-104 56 58-200 200ZM240-160q-33 0-56.5-23.5T160-240v-120h80v120h480v-120h80v120q0 33-23.5 56.5T720-160H240Z' />
                            </svg>
                          </button>
                        </SwiperSlide>
                      );
                    }
                  },
                )}
              </Swiper>
              <ul className='z-[10] absolute bottom-0 top-0 my-auto left-0 w-full flex justify-between items-center h-[72px]'>
                <li className='mySwiper-prev cursor-pointer   bg-[#00000099] py-[26px] px-[8px] w-[36px] h-full justify-center items-center'>
                  <img
                    src={'/assets/images/import/arrow-left.svg'}
                    alt='이전 버튼'
                  />
                </li>
                <li className='mySwiper-next cursor-pointer bg-[#00000099]  py-[26px] px-[8px] w-[36px] h-full justify-center items-center'>
                  <img
                    src={'/assets/images/import/arrow-right.svg'}
                    alt='다음 버튼'
                  />
                </li>
              </ul>
            </div>
          ) : null}
          {/* 상세정보 */}
          <button
            className='text-white font-[600] bg-[#111111] py-[14px] px-[16px] w-full mt-[8px] z-10'
            onClick={() => setUsedCarPopupOpen(!isUsedCarPopupOpen)}
          >
            보험 처리 이력 확인
          </button>
          <div
            className={cn(
              'transition-all py-[16px] h-auto opacity-100 translate-y-0 z-[0] pointer-events-auto',
            )}
          >
            <ul className='w-full flex flex-col justify-start items-start gap-2'>
              <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                <p className='text-[#767676] min-w-[23vw]'>모델</p>
                <p className='text-[#111111]'>
                  {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                    `${quoteDetail.selling.usedCarInfo.carInfo.makername} ${quoteDetail.selling.usedCarInfo.carInfo.modelname}`
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  )}
                </p>
              </li>
              <li className='w-full flex flex-row gap-[5.5vw] justify-start items-stretch py-2 pr-2'>
                <p className='text-[#767676] min-w-[23vw]'>트림</p>
                <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                  {quoteDetail?.purchase?.selectedCar?.carTrim?.name ?? '없음'}
                </p>
              </li>
              <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                <p className='text-[#767676] min-w-[23vw]'>상세등급</p>
                <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                  {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                    `${quoteDetail.selling.usedCarInfo.carInfo.seriesname}`
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  )}
                </p>
              </li>
              <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                <p className='text-[#767676] min-w-[23vw]'>연식</p>
                <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                  {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                    `${quoteDetail.selling.usedCarInfo.carInfo.year} 년식`
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  )}
                </p>
              </li>
              <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                <p className='text-[#767676] min-w-[23vw]'> 최초등록</p>
                <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                  {quoteDetail.selling?.usedCarInfo?.carInfo?.firstdate ? (
                    dayjs(
                      quoteDetail.selling.usedCarInfo.carInfo.firstdate,
                    ).format('YYYY년 MM월 DD일')
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  )}
                </p>
              </li>
              <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                <p className='text-[#767676] min-w-[23vw]'>차체형태</p>
                <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                  {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                    `${quoteDetail.selling.usedCarInfo.carInfo.cargubun}`
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  )}
                </p>
              </li>
              <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                <p className='text-[#767676] min-w-[23vw]'>엔진타입</p>
                <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                  {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                    `${quoteDetail.selling.usedCarInfo.carInfo.enType}`
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  )}
                </p>
              </li>
              <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                <p className='text-[#767676] min-w-[23vw]'>변속기</p>
                <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                  {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                    `${quoteDetail.selling.usedCarInfo.carInfo.gearbox}`
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  )}
                </p>
              </li>
              <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                <p className='text-[#767676] min-w-[23vw]'>배기량</p>
                <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                  {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                    `${quoteDetail.selling.usedCarInfo.carInfo.displacement.toLocaleString()} cc`
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  )}
                </p>
              </li>
              <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                <p className='text-[#767676] min-w-[23vw]'>연료</p>
                <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                  {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                    `${quoteDetail.selling.usedCarInfo.carInfo.fuel}`
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  )}
                </p>
              </li>
              <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                <p className='text-[#767676] min-w-[23vw]'>연비</p>
                <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                  {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                    `${quoteDetail.selling.usedCarInfo.carInfo.stdmileage}`
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  )}
                </p>
              </li>
              <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                <p className='text-[#767676] min-w-[23vw]'>승차인원</p>
                <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                  {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                    `${quoteDetail.selling.usedCarInfo.carInfo.seating} 명`
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  )}
                </p>
              </li>
              <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                <p className='text-[#767676] min-w-[23vw]'>색상</p>
                <p className='text-[#111111]'>
                  {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                    quoteDetail.selling.usedCarInfo.carInfo.color
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  )}
                </p>
              </li>
              <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                <p className='text-[#767676] min-w-[23vw]'>신차가격</p>
                <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                  {quoteDetail.selling?.usedCarInfo?.carInfo ? (
                    `${quoteDetail.selling.usedCarInfo.carInfo.newprice.toLocaleString()} 원`
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  )}
                </p>
              </li>
              <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                <p className='text-[#767676] min-w-[23vw]'>구매방식</p>
                <p className='text-[#111111]'>
                  {quoteDetail.selling?.boughtMethod ? (
                    convertBoughtType[quoteDetail.selling.boughtMethod]
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  )}
                </p>
              </li>
              <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                <p className='text-[#767676] min-w-[23vw]'>주행거리</p>
                <p className='text-[#111111]'>
                  {quoteDetail.selling?.drivenDistance ? (
                    `${quoteDetail.selling.drivenDistance.toLocaleString()} km`
                  ) : (
                    <span className='text-[#999999]'>없음</span>
                  )}
                </p>
              </li>
              <li className='w-full flex flex-col gap-[3vw] justify-start items-start py-2'>
                <p className='text-[#767676] min-w-[23vw]'>옵션</p>
                <div className='text-[#111111] flex flex-col justify-start items-start pl-2 w-full'>
                  {/* 옵션 없을 때 */}
                  {quoteDetail.selling?.additionalInfo.optionSelect
                    .옵션없음 && (
                    <span className='w-full text-[#111111]  py-4 '>
                      옵션없음
                    </span>
                  )}

                  {/* 옵션 선택으로 있을 때 */}
                  {quoteDetail.selling?.additionalInfo.optionSelect
                    ?.optionList &&
                    quoteDetail.selling?.additionalInfo.optionSelect.optionList
                      .length > 0 &&
                    quoteDetail.selling.additionalInfo.optionSelect.optionList.map(
                      (item, index) => {
                        return (
                          <div
                            className='w-full text-[#111111] py-4 flex justify-between items-center border-b-[1px] border-dashed border-[#E5E5EC]'
                            key={`${item.optname}_${index}`}
                          >
                            <span className='text-[#767676]'>
                              {item.optname}
                            </span>
                            <span className='mr-[2px] pr-[10px]'>
                              + {item.optprice.toLocaleString()} 원
                            </span>
                          </div>
                        );
                      },
                    )}
                  {/* 옵션 직접입력으로 할 때 */}
                  {quoteDetail.selling?.additionalInfo.optionSelect.직접입력
                    ?.selected && (
                    <div className='w-full text-[#111111] bg-[#F7F7FB] py-4 px-2 flex flex-col gap-[12px] justify-start items-start'>
                      <span className='font-[600]'>직접입력</span>
                      <p className='text-[13px] whitespace-pre-wrap'>
                        {quoteDetail.selling?.additionalInfo.optionSelect
                          .직접입력.content
                          ? ReactHtmlParser(
                              quoteDetail.selling?.additionalInfo.optionSelect
                                .직접입력.content,
                            )
                          : '없음'}
                      </p>
                    </div>
                  )}
                </div>
              </li>
              <li className='w-full flex flex-col gap-[5.5vw] justify-start items-start py-2'>
                <p className='text-[#767676] min-w-[23vw]'>사고 내역</p>
                <div className='text-[#111111] flex flex-col justify-start items-start gap-[10px] pb-4 w-full'>
                  {/* 사고 없음 */}
                  {quoteDetail.selling?.additionalInfo.accident.사고없음
                    .selected && (
                    <div className='w-full text-[#111111] bg-[#F7F7FB] py-4 px-2 flex flex-col gap-[12px] justify-start items-start'>
                      <span className='font-[600]'>사고 없음</span>
                      {quoteDetail.selling.additionalInfo.accident.사고없음
                        ?.content && (
                        <p className='text-[13px] whitespace-pre-wrap'>
                          {quoteDetail.selling.additionalInfo.accident.사고없음
                            .content
                            ? ReactHtmlParser(
                                quoteDetail.selling.additionalInfo.accident
                                  .사고없음.content,
                              )
                            : '없음'}
                        </p>
                      )}
                    </div>
                  )}
                  {/* 사고 선택 */}
                  {quoteDetail.selling?.additionalInfo.accident.판금교환
                    .selected && (
                    <div className='w-full text-[#111111] bg-[#F7F7FB] py-4 px-2 flex flex-col gap-[12px] justify-start items-start'>
                      <span className='font-[600]'>
                        판금 / 교환 부위가 있습니다
                      </span>
                      {quoteDetail.selling.additionalInfo.accident.판금교환
                        ?.content && (
                        <p className='text-[13px] whitespace-pre-wrap'>
                          {quoteDetail.selling.additionalInfo.accident.판금교환
                            .content
                            ? ReactHtmlParser(
                                quoteDetail.selling.additionalInfo.accident
                                  .판금교환.content,
                              )
                            : '없음'}
                        </p>
                      )}
                    </div>
                  )}
                  {/* 사고 모르겠음 */}
                  {quoteDetail.selling?.additionalInfo.accident.모름
                    .selected && (
                    <div className='w-full text-[#111111] bg-[#F7F7FB] py-4 px-2 flex flex-col gap-[12px] justify-start items-start'>
                      <span className='font-[600]'>잘 모르겠습니다.</span>
                      {quoteDetail.selling.additionalInfo.accident.모름
                        ?.content && (
                        <p className='text-[13px] whitespace-pre-wrap'>
                          {quoteDetail.selling.additionalInfo.accident.모름
                            .content
                            ? ReactHtmlParser(
                                quoteDetail.selling.additionalInfo.accident.모름
                                  .content,
                              )
                            : '없음'}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </li>
              <li className='w-full flex flex-col gap-[5.5vw] justify-start items-start py-2 pb-10 '>
                <p className='text-[#767676] min-w-[23vw]'>차량의 상태</p>
                <div className='text-[#111111] flex flex-col justify-start items-start gap-[10px] pb-4 w-full'>
                  {/* 타이어 교체 필요 */}
                  {quoteDetail.selling?.additionalInfo.carState.타이어교체
                    .selected && (
                    <div className='w-full text-[#111111] bg-[#F7F7FB] py-4 px-2 flex flex-col gap-[12px] justify-start items-start'>
                      <span className='font-[600]'>타이어 교체 필요</span>
                      {quoteDetail.selling.additionalInfo.carState.타이어교체
                        ?.content && (
                        <p className='text-[13px] whitespace-pre-wrap'>
                          {quoteDetail.selling.additionalInfo.carState
                            .타이어교체.content
                            ? ReactHtmlParser(
                                quoteDetail.selling.additionalInfo.carState
                                  .타이어교체.content,
                              )
                            : '없음'}
                        </p>
                      )}
                    </div>
                  )}
                  {/* 외판 스크래치 */}
                  {quoteDetail.selling?.additionalInfo.carState.외관스크래치
                    .selected && (
                    <div className='w-full text-[#111111] bg-[#F7F7FB] py-4 px-2 flex flex-col gap-[12px] justify-start items-start'>
                      <span className='font-[600]'>외판 스크래치</span>
                      {quoteDetail.selling.additionalInfo.carState.외관스크래치
                        ?.content && (
                        <p className='text-[13px] whitespace-pre-wrap'>
                          {quoteDetail.selling.additionalInfo.carState
                            .외관스크래치.content
                            ? ReactHtmlParser(
                                quoteDetail.selling.additionalInfo.carState
                                  .외관스크래치.content,
                              )
                            : '없음'}
                        </p>
                      )}
                    </div>
                  )}
                  {/* 정비 및 수리 필요 */}
                  {quoteDetail.selling?.additionalInfo.carState.외관스크래치
                    .selected && (
                    <div className='w-full text-[#111111] bg-[#F7F7FB] py-4 px-2 flex flex-col gap-[12px] justify-start items-start'>
                      <span className='font-[600]'>정비 및 수리 필요</span>
                      {quoteDetail.selling.additionalInfo.carState.정비수리필요
                        ?.content && (
                        <p className='text-[13px] whitespace-pre-wrap'>
                          {quoteDetail.selling.additionalInfo.carState
                            .정비수리필요.content
                            ? ReactHtmlParser(
                                quoteDetail.selling.additionalInfo.carState
                                  .정비수리필요.content,
                              )
                            : '없음'}
                        </p>
                      )}
                    </div>
                  )}
                  {/* 내 차의 장단점 */}
                  {quoteDetail.selling?.additionalInfo.carState.차량장단점
                    ?.selected === true && (
                    <div className='w-full text-[#111111] bg-[#F7F7FB] py-4 px-2 flex flex-col gap-[12px] justify-start items-start'>
                      <span className='font-[600]'>내 차의 장단점</span>
                      {quoteDetail.selling.additionalInfo.carState.차량장단점
                        ?.content && (
                        <p className='whitespace-pre-wrap text-[13px]'>
                          {ReactHtmlParser(
                            quoteDetail.selling.additionalInfo.carState
                              .차량장단점.content,
                          )}
                        </p>
                      )}
                    </div>
                  )}
                </div>
              </li>
            </ul>
          </div>
        </div>
      )}

      {/* 내차팔기 팝업 */}
      {quoteDetail?.selling?.accidentHistory && (
        <SellingAccidentHistoryDetail
          isOpen={isUsedCarPopupOpen}
          setIsOpen={setUsedCarPopupOpen}
          accidentHistory={quoteDetail.selling.accidentHistory}
        />
      )}
    </>
  );
}
