import { OrderState, OrderType } from '@carsayo/types';

// 견적함 주문상태
export const convertOrderStatus: {
  [key in OrderState | 'undefined']: string;
} = {
  submitted: '입찰 중',
  progressing: '진행 중',
  nonAccepted: '입찰 종료',
  holded: '보류',
  canceled: '취소',
  deleted: '삭제', // 견적0. 본인 취소
  finished: '판매 완료', // 신차는 주문완료
  failBid: '유찰',
  undefined: '알수없음',
} as const;

export const convertOrderStatusStyle: {
  [key: string]: string;
} = {
  submitted: 'text-[#000000]',
  progressing: 'text-[#009E59] bg-[#E0F3EB]',
  nonAccepted: 'text-[#999999] bg-[#F3F6F9]',
  holded: 'text-[#FF7B2E] bg-[#FFF3EC]',
  canceled: 'text-[#FF4747] bg-[#FFECEC]',
  deleted: 'text-[#FF4747]',
  finished: 'text-[#005CE6] bg-[#DEEBFF]',
  failBid: 'text-[#999999]',
  undefined: '',
} as const;

// 견적 주문 타입
export const convertDealerType: {
  [key in OrderType | 'undefined']: string;
} = {
  lease: '리스',
  rent: '장기렌트',
  newCar: '신차 구매',
  sellCar: '내 차 팔기',
  undefined: '알 수 없음',
} as const;

// 상태별 날짜 매칭
export const statusDateMap: { [key in OrderState]: string } = {
  submitted: 'submitted_at',
  progressing: 'progressed_at',
  canceled: 'canceled_at',
  holded: 'holded_at',
  failBid: 'submitted_at',
  finished: 'finished_at',
  deleted: 'deleted_at',
  nonAccepted: 'bid_ended_at',
} as const;
