/* eslint-disable @typescript-eslint/no-unused-vars */
import { InputV2 } from 'components/common/v3/inputv2';
import {
  ChangeEvent,
  useEffect,
  useLayoutEffect,
  useMemo,
  useState,
} from 'react';
import useInsuranceOriginDataStore from '../store/dataStore';
import Button from 'components_v3/ui/Button';
import {
  RegisterCar,
  RegisterCarCreateDTO,
  RegisterCarUpdateDTO,
} from '@carsayo/types';
import useMemberStore from 'store/useMemberStore';
import { addRegisterCarApi, updateRegisterCarApi } from '../api';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { useLocation, useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import useSystemStore from 'store/useSystemStore';
import CustomDatePicker from 'components/common/v3/customDatePicker';

export default function CarManagementAdd() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const { originInsuranceData } = useInsuranceOriginDataStore();
  const { loginMember, refreshLoginMember } = useMemberStore();
  const navigate = useNavigate();
  const location = useLocation();
  //   카키 물어보기, 카집사 물어보기기에서 넘어오면 해당 키값이 있습니다.(carkeyInsuranceAdd / cargipsaInsuranceAdd)
  const [actionAddress, setActionAddress] = useState<string>(
    location.state?.key ? location.state.key : '',
  );
  const [selectedMethod, setSelectedMethod] = useState<string>('new');
  const [insuranceCarNumber, setInsuranceCarNumber] = useState('');
  const [insuranceCompany, setInsuranceCompany] = useState<number | null>();

  const selectedInsuranceCompany = useMemo(() => {
    return (
      originInsuranceData.find((item) => item.id === insuranceCompany) ?? null
    );
  }, [insuranceCompany]);

  const [insuranceDate, setInsuranceDate] = useState<Date | null>(new Date());
  const carData: RegisterCar[] | undefined = useMemo(() => {
    return loginMember?.customerInfo?.registerCar.list;
  }, [loginMember]);
  const insuranceCarNumberChange = (event: ChangeEvent<HTMLInputElement>) => {
    setInsuranceCarNumber(event.target.value);
  };
  const insuranceDataAdd = async () => {
    if (insuranceCarNumber && insuranceCompany && insuranceDate) {
      const reqData: RegisterCarCreateDTO = {
        carName: insuranceCarNumber,
        insurance_start_date: insuranceDate,
        insurance_company_id: insuranceCompany,
        isRepresentative: carData && carData?.length > 0 ? false : true,
      };
      try {
        const result = await addRegisterCarApi(reqData);
        if (result) {
          CarsayoToast.success(
            actionAddress.length > 0
              ? '자동차 보험 만기일 등록이 완료되었습니다.'
              : '차량이 정상적으로 등록되었습니다.',
          );
          refreshLoginMember();
          actionAddress === 'carkeyInsuranceAdd'
            ? //   카키에게 물어보기로 돌아갔을 때, 답변을 띄우기 위해 아래와 같이 key값을 보냅니다.
              navigate('/ask/theme/carkey', {
                state: {
                  key: ['insuranceAddComplete'],
                },
              })
            : actionAddress === 'cargipsaInsuranceAdd'
              ? //   카집사에게 물어보기로 돌아갔을 때, 답변을 띄우기 위해 아래와 같이 key값을 보냅니다.
                navigate('/ask/theme/cargipsa', {
                  state: {
                    key: ['insuranceAddComplete'],
                  },
                })
              : navigate('/ask/carManagement/main');
        } else {
          CarsayoToast.error('차량 등록을 실패했습니다. 다시 시도해주세요.');
          refreshLoginMember();
        }
      } catch (error: any) {
        refreshLoginMember();
        CarsayoToast.error(error);
      }
    }
  };
  const insuranceDataEdit = async () => {
    if (
      insuranceCarNumber &&
      insuranceCompany &&
      insuranceDate &&
      selectedMethod
    ) {
      const reqData: RegisterCarUpdateDTO = {
        id: selectedMethod,
        carName: insuranceCarNumber,
        insurance_start_date: insuranceDate,
        insurance_company_id: insuranceCompany,
        isRepresentative:
          carData?.find((item) => item.id === selectedMethod)
            ?.isRepresentative ?? false,
      };
      try {
        const result = await updateRegisterCarApi(reqData);
        if (result) {
          CarsayoToast.success('차량이 정상적으로 수정되었습니다.');
          refreshLoginMember();
          actionAddress === 'carkeyInsuranceAdd'
            ? //   카키에게 물어보기로 돌아갔을 때, 답변을 띄우기 위해 아래와 같이 key값을 보냅니다.
              navigate('/ask/theme/carkey', {
                state: {
                  key: ['insuranceAddComplete'],
                },
              })
            : actionAddress === 'cargipsaInsuranceAdd'
              ? //   카집사에게 물어보기로 돌아갔을 때, 답변을 띄우기 위해 아래와 같이 key값을 보냅니다.
                navigate('/ask/theme/cargipsa', {
                  state: {
                    key: ['insuranceAddComplete'],
                  },
                })
              : navigate('/ask/carManagement/main');
        } else {
          CarsayoToast.error('차량 수정을 실패했습니다. 다시 시도해주세요.');
          refreshLoginMember();
        }
      } catch (error: any) {
        refreshLoginMember();
        CarsayoToast.error(error);
      }
    }
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: 'back',
      title:
        actionAddress.length > 0 ? '자동차 보험 만기일 등록' : '차량 정보 등록',
      rightElement: undefined,
    });
    setIsBottomTabbar(false);
  }, []);

  useEffect(() => {
    if (selectedMethod !== 'new') {
      const selectedCar = carData?.find((item) => item.id === selectedMethod);
      selectedCar?.carName && setInsuranceCarNumber(selectedCar.carName);
      selectedCar?.insuranceCompany?.id &&
        setInsuranceCompany(selectedCar.insuranceCompany.id);
      selectedCar?.insurance_start_date &&
        setInsuranceDate(new Date(selectedCar.insurance_start_date));
    } else {
      setInsuranceCarNumber('');
      setInsuranceCompany(null);
      setInsuranceDate(new Date());
    }
  }, [selectedMethod]);

  return (
    <div className='px-5 pt-[50px] pb-[10px] min-h-[calc(100dvh-60px)] flex flex-col justify-between items-start gap-10'>
      <div className='w-full flex flex-col justify-start items-start gap-6'>
        {actionAddress === 'carkeyInsuranceAdd' ? (
          carData && carData?.length > 0 ? (
            <>
              <div className='w-full'>
                <label
                  htmlFor='insuranceCarNumber'
                  className='block text-[15px] leading-[15px] mb-[12px]'
                >
                  차량번호
                </label>
                <SelectV3
                  onValueChange={(value) => {
                    setSelectedMethod(value);
                  }}
                  value={selectedMethod ?? ''}
                >
                  <SelectV3Item value={'new'}>신규 등록</SelectV3Item>
                  {carData.map((item) => (
                    <SelectV3Item value={item.id}>{item.carName}</SelectV3Item>
                  ))}
                </SelectV3>
              </div>

              {selectedMethod === 'new' && (
                <div className='w-full'>
                  <InputV2
                    id='insuranceCarNumber'
                    placeholder='차량번호를 입력해주세요.'
                    onChange={insuranceCarNumberChange}
                    value={insuranceCarNumber}
                  />
                </div>
              )}
            </>
          ) : (
            <div className='w-full'>
              <label
                htmlFor='insuranceCarNumber'
                className='block text-[15px] leading-[15px] mb-[12px]'
              >
                차량번호
              </label>
              <InputV2
                id='insuranceCarNumber'
                placeholder='차량번호를 입력해주세요.'
                onChange={insuranceCarNumberChange}
                value={insuranceCarNumber}
              />
            </div>
          )
        ) : (
          <div className='w-full'>
            <label
              htmlFor='insuranceCarNumber'
              className='block text-[15px] leading-[15px] mb-[12px]'
            >
              차량번호
            </label>
            <InputV2
              id='insuranceCarNumber'
              placeholder='차량번호를 입력해주세요.'
              onChange={insuranceCarNumberChange}
              value={insuranceCarNumber}
            />
          </div>
        )}

        <div className='w-full'>
          <label
            htmlFor='insuranceCompany'
            className='block text-[15px] leading-[15px] mb-[12px]'
          >
            보험사
          </label>
          <SelectV3
            onValueChange={(value) => {
              setInsuranceCompany(Number(value));
            }}
            value={selectedInsuranceCompany?.name ?? ''}
            placeholder='보험사를 선택해주세요.'
            renderValue={() => (
              <div className='flex justify-start items-center'>
                <img
                  src={selectedInsuranceCompany?.logo_url ?? ''}
                  alt={selectedInsuranceCompany?.name ?? ''}
                  className='w-[28px] h-[28px] mr-2.5'
                />
                <span className='text-[16px] leading-[16px] font-[400]'>
                  {originInsuranceData.find(
                    (item) => item.id === insuranceCompany,
                  )?.name ?? ''}
                </span>
              </div>
            )}
          >
            {originInsuranceData.map((item) => (
              <SelectV3Item value={item.id}>
                <div className='flex items-center'>
                  <img
                    src={item.logo_url ?? ''}
                    alt={item.name ?? ''}
                    className='w-[28px] h-[28px] mr-2.5'
                  />
                  <span className='text-[16px] leading-[16px]'>
                    {item?.name ?? ''}
                  </span>
                </div>
              </SelectV3Item>
            ))}
          </SelectV3>
        </div>
        <div className='w-full'>
          <label
            htmlFor='insuranceDate'
            className='block text-[15px] leading-[15px] mb-[12px]'
          >
            보험 가입일
          </label>
          <div className='relative'>
            <CustomDatePicker
              type='white'
              value={insuranceDate}
              onChange={setInsuranceDate}
            />
          </div>
        </div>
      </div>
      <Button
        theme='primary'
        disabled={!insuranceCarNumber || !insuranceCompany || !insuranceDate}
        onClick={
          selectedMethod === 'new' ? insuranceDataAdd : insuranceDataEdit
        }
      >
        {selectedMethod === 'new' ? '  등록하기' : '수정하기'}
      </Button>
    </div>
  );
}
