/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery } from '@tanstack/react-query';
import { getCustomerQuoteImageslApi } from 'apis/quote';
import MultiImageViewer from 'components_v3/dialog/MultiImageViewer';
import { useLayoutEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';
import useSystemStore from 'store/useSystemStore';

const QuoteImagesPage = () => {
  const { id } = useParams();
  const { setTopTabbarState, setIsBottomTabbar, setTopTabbarTitle } =
    useSystemStore();

  /**
   * 250402 카매니저 / 일반회원 구분
   */
  const { loginMember } = useMemberStore();
  const mode: 'customer' | 'dealer' =
    loginMember?.type === 'dealer' ? 'dealer' : 'customer';

  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [imageViewerIndex, setImageViewerIndex] = useState(0);

  const { data: imageList } = useQuery({
    queryKey: ['imageList'],
    queryFn: () => getCustomerQuoteImageslApi(id!, mode),
    enabled: !!id,
    staleTime: 1000 * 60 * 10,
    gcTime: 1000 * 60 * 10,
  });

  /** 상단, 하단 숨김 */
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
    setTopTabbarTitle('');
  }, []);

  if (!imageList) return;
  return (
    <div className='flex flex-col gap-1'>
      {imageList.images.length > 0 &&
        imageList.images.map((item: string, index: number) => {
          return (
            <div
              key={`img_${index}`}
              className='cursor-pointer'
              onClick={() => {
                setImageViewerIndex(index);
                setIsImageViewerOpen(true);
              }}
            >
              <img
                src={item}
                alt='차량 이미지'
                className='h-60 w-full object-contain'
              />
            </div>
          );
        })}

      <MultiImageViewer
        isOpen={isImageViewerOpen}
        setIsOpen={setIsImageViewerOpen}
        initialIndex={imageViewerIndex}
        fileUrl={imageList.images}
      />
    </div>
  );
};

export default QuoteImagesPage;
