/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import { Input } from 'components/ui/input';
import { Check, CornerDownRight } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import useSellingStore from './store';
import useSystemStore from 'store/useSystemStore';
import {
  ChangeEvent,
  Dispatch,
  SetStateAction,
  useEffect,
  useLayoutEffect,
  useState,
} from 'react';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { getCooconCarInfo } from 'apis/car';
import { initSelling } from 'apis/selling';
import { Dialog, DialogTrigger } from 'components/ui/dialog';
import { cn } from 'utils';
import { SearchedUsedCarInfo } from '@carsayo/types';

type InputValue = {
  ownerName?: string;
  carName?: string;
  selectedSeries?: string;
};

export function SelectTrim({
  inputValue,
  searchMoreData,
  setInputValue,
  setSearchMoreData,
}: {
  inputValue: InputValue;
  searchMoreData: SearchedUsedCarInfo;
  setInputValue: Dispatch<SetStateAction<InputValue>>;
  setSearchMoreData: Dispatch<SetStateAction<SearchedUsedCarInfo | null>>;
}) {
  const navigate = useNavigate();

  const { setLoadingDim } = useSystemStore();
  const { setSellingOrderDTO } = useSellingStore();

  // 상세등급 선택을 종료합니다
  const cancel = () => {
    setSearchMoreData(null);
    setInputValue({
      ...inputValue,
      selectedSeries: undefined,
    });
  };

  // 상세등급을 선택하여 다시 주문을 시작합니다
  const detailSubmit = async () => {
    if (!searchMoreData || !inputValue.carName || !inputValue.ownerName) {
      cancel();
      return;
    }

    if (!inputValue.selectedSeries) {
      CarsayoToast.error('상세등급을 선택해 주세요');
      return;
    }

    setLoadingDim(true);

    try {
      const searchResult = await getCooconCarInfo({
        carName: inputValue.carName,
        ownerName: inputValue.ownerName,
        seriesno: inputValue.selectedSeries,
        ts_key: searchMoreData.searchDetailInfo?.ts_key,
      });
      // 조회 성공, 바로 주문 생성하고 넘어갑니다
      if (searchResult.result === 'success' && searchResult.usedCarInfo) {
        const createdOrder = await initSelling({
          usedCarInfoId: searchResult.usedCarInfo?.id,
          accidentHistoryId: searchResult.accidentHistory?.id,
        });
        setSellingOrderDTO(createdOrder);
        setLoadingDim(false);
        navigate('/selling/detail');
      }
      // 조회 결과 트림 선택이 필요한 경우
      else if (searchResult.result === 'searchMore') {
        setSearchMoreData(searchResult);
        setLoadingDim(false);
      } else {
        CarsayoToast.error(searchResult.message);
        setLoadingDim(false);
      }
    } catch (e) {
      CarsayoToast.error(`${e}`);
      setLoadingDim(false);
    }
  };

  return (
    <>
      <div className='font-semibold text-[#9EA3B2]'>
        판매하실 차량 등급을 선택해 주세요
      </div>
      <div className='my-6 mx-4 space-y-4'>
        <div>
          <div className='font-normal text-[#9EA3B2]'>
            알려주신 내용을 바탕으로 예상 사고 기준을 예상하고 진행합니다.
          </div>
        </div>

        {searchMoreData.searchDetailInfo?.modelList &&
          searchMoreData.searchDetailInfo?.modelList.map((el, index) => {
            return (
              <div
                key={`model_${index}`}
                className='rounded border border-[#0075FF] bg-[#0075FF]/10'
              >
                <div className='flex h-[70px] items-center border-b border-[#0075FF] px-4 text-title font-semibold text-white'>
                  {el.modelname}
                </div>
                <div className='p-4'>
                  <Dialog>
                    {el.serieslist.map((el2) => {
                      return (
                        <DialogTrigger asChild>
                          <div className='flex h-[72px] items-center gap-2 border border-none px-4'>
                            <CornerDownRight className='h-4 w-4 text-white mr-4' />
                            <div
                              className={cn(
                                'text-base font-medium text-[#9EA3B2]',
                                true && 'text-white',
                              )}
                            >
                              <div className='font-medium tracking-[-0.35px] leading-5'>
                                {el2.seriesname}
                              </div>
                              <div className='text-[12px] text-[#9EA3B2] font-normal tracking-[-0.3px] leading-5'>{`${Number(el2.seriesprice).toLocaleString()}원`}</div>
                            </div>
                            <div className='flex-auto'></div>
                            <div className='relative h-6 w-6'>
                              <input
                                type='radio'
                                name='true'
                                onClick={() => {
                                  setInputValue({
                                    ...inputValue,
                                    selectedSeries: el2.seriesno,
                                  });
                                }}
                                className={cn(
                                  'flex h-6 w-6 appearance-none items-center justify-center rounded-full bg-[#0075FF]/10',
                                  true && 'text-white',
                                )}
                              />
                              {inputValue.selectedSeries === el2.seriesno && (
                                <Check className='absolute left-1.5 top-1.5 h-3 w-3 text-white' />
                              )}
                            </div>
                          </div>
                        </DialogTrigger>
                      );
                    })}
                  </Dialog>
                </div>
              </div>
            );
          })}
      </div>

      <div className='mt-auto grid grid-cols-2 py-6'>
        <Button
          onClick={cancel}
          className='rounded-r-none border-0 bg-white text-sm font-semibold text-[#111111]'
        >
          취소
        </Button>
        <Button
          className='rounded-l-none border-0 bg-[#0075FF] text-sm font-semibold text-white'
          onClick={detailSubmit}
        >
          선택하기
        </Button>
      </div>
    </>
  );
}

export default function Main() {
  const navigate = useNavigate();

  const { topTabberState, setTopTabbarState, setLoadingDim } = useSystemStore();
  const { sellingOrderDTOList, setSellingOrderDTO } = useSellingStore();

  const [searchMoreData, setSearchMoreData] =
    useState<SearchedUsedCarInfo | null>(null);

  const [inputValue, setInputValue] = useState<InputValue>({});

  const goBack = () => {
    navigate(-1);
  };

  const submit = async () => {
    if (inputValue.carName && inputValue.ownerName) {
      setLoadingDim(true);

      try {
        const searchResult = await getCooconCarInfo({
          carName: inputValue.carName,
          ownerName: inputValue.ownerName,
        });
        // 조회 성공, 바로 주문 생성하고 넘어갑니다
        if (searchResult.result === 'success' && searchResult.usedCarInfo) {
          const createdOrder = await initSelling({
            usedCarInfoId: searchResult.usedCarInfo?.id,
            accidentHistoryId: searchResult.accidentHistory?.id,
          });
          setSellingOrderDTO(createdOrder);
          setLoadingDim(false);
          navigate('/selling/detail');
        }
        // 조회 결과 트림 선택이 필요한 경우
        else if (searchResult.result === 'searchMore') {
          setSearchMoreData(searchResult);
          setLoadingDim(false);
        } else {
          CarsayoToast.error(searchResult.message);
          setLoadingDim(false);
        }
      } catch (e) {
        CarsayoToast.error(`${e}`);
        setLoadingDim(false);
      }
      return;
    } else if (sellingOrderDTOList && sellingOrderDTOList.length) {
      setSellingOrderDTO(sellingOrderDTOList[0]);
      navigate('/selling/detail');
      return;
    }

    CarsayoToast.error('차량 정보를 모두 입력해 주세요');
    return;
  };

  const handleChangeEvents = {
    ownerName: (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue({
        ...inputValue,
        ownerName: e.target.value,
      });
    },
    carName: (e: ChangeEvent<HTMLInputElement>) => {
      setInputValue({
        ...inputValue,
        carName: e.target.value,
      });
    },
  };

  useEffect(() => {
    window.native.onBackPressed = goBack;
  }, []);

  useLayoutEffect(() => {
    setTopTabbarState({
      ...topTabberState,
      className: 'bg-[#181A20] text-white',
    });
    return () => {
      setLoadingDim(false);
    };
  }, []);

  return (
    <div className='min-h-full bg-[#181A20] flex flex-col px-4 pt-8'>
      {!searchMoreData ? (
        sellingOrderDTOList && sellingOrderDTOList.length > 0 ? (
          <>
            <div className='font-semibold text-[#9EA3B2]'>
              진행중인 내차팔기 주문이 존재합니다
            </div>

            <div className='flex-auto mt-6'>
              <div>
                <div className='font-normal text-[#9EA3B2]'>
                  내차팔기 주문을 완료하시면 카매니저님과의 매칭이 시작됩니다
                </div>
              </div>
              {sellingOrderDTOList &&
                sellingOrderDTOList.map((el, index) => {
                  return (
                    <div
                      key={index}
                      className='flex justify-between rounded-[36px] border border-[#0075FF] bg-[#0066FF]/10 px-4 py-2 my-4'
                    >
                      <div className='flex items-center gap-2'>
                        <div className='flex h-14 w-14 items-center justify-center rounded-full bg-[#181A20]'>
                          <img
                            src={el.orderData.usedCarInfo.carInfo.modelimage}
                            alt=''
                            className='h-10 w-10'
                          />
                        </div>
                        <div>
                          <div className='text-title font-semibold text-white pr-2'>
                            {el.orderData.usedCarInfo.carInfo.modelname}
                          </div>
                          <div className='mt-0.5 text-desc text-[#CCCCCC]'>
                            {el.orderData.usedCarInfo.carName}
                          </div>
                        </div>
                      </div>
                      <div className='flex items-center gap-4'>
                        <div className='text-desc text-[#FFC830]'>
                          {el.orderData.progress}% 진행
                        </div>
                        <div
                          onClick={() => {
                            setSellingOrderDTO(el);
                            navigate('/selling/detail');
                            if (el.orderData.progress >= 83) {
                              navigate('/selling/photo');
                            }
                            return;
                          }}
                          className='flex h-10 w-10 items-center justify-center rounded-xl bg-[rgba(68,68,68,.3)] shadow-[0_4px_20px_rgba(0,0,0,.06)]'
                        >
                          <img src='/assets/images/v2/share.svg' alt='' />
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
            <div className='flex-none mt-auto grid grid-cols-2 py-6'>
              <Button
                onClick={goBack}
                className='rounded-r-none border-0 bg-white text-sm font-semibold text-[#111111]'
              >
                이전
              </Button>
              <Button
                className='rounded-l-none border-0 bg-[#0075FF] text-sm font-semibold text-white'
                onClick={submit}
              >
                다음
              </Button>
            </div>
          </>
        ) : (
          <>
            <div className='font-semibold text-[#9EA3B2]'>
              판매하실 차량 정보를 입력해 주세요
            </div>
            <div className='mt-4 space-y-4'>
              <div>
                <div className='text-[#9EA3B2]'>소유자명</div>
                <div className='mt-2'>
                  <Input
                    className='h-[48px] border-[#35383F] bg-[#252932] text-sm text-white placeholder:text-[#D9D9D9] focus:border-[#35383F]'
                    value={inputValue.ownerName}
                    onChange={handleChangeEvents.ownerName}
                  ></Input>
                </div>
              </div>
              <div>
                <div className='text-[#9EA3B2]'>차량번호</div>
                <div className='mt-2'>
                  <Input
                    className='h-[48px] border-[#35383F] bg-[#252932] text-sm text-white placeholder:text-[#D9D9D9] focus:border-[#35383F]'
                    value={inputValue.carName}
                    onChange={handleChangeEvents.carName}
                  ></Input>
                </div>
              </div>
            </div>
            <div className='mt-2 text-[#9EA3B2]'>
              <span className='text-[#0075FF]'>자동차 등록증</span>에 기재된
              <span className='text-[#0075FF]'>소유자명</span>을 입력해주세요.
            </div>
            <div className='flex-none mt-auto grid grid-cols-2 py-6'>
              <Button
                onClick={goBack}
                className='rounded-r-none border-0 bg-white text-sm font-semibold text-[#111111]'
              >
                이전
              </Button>
              <Button
                className='rounded-l-none border-0 bg-[#0075FF] text-sm font-semibold text-white'
                onClick={submit}
              >
                다음
              </Button>
            </div>
          </>
        )
      ) : (
        <SelectTrim
          inputValue={inputValue}
          searchMoreData={searchMoreData}
          setInputValue={setInputValue}
          setSearchMoreData={setSearchMoreData}
        ></SelectTrim>
      )}
    </div>
  );
}
