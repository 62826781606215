/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  BuyerType,
  DutyFreeCondition,
  PurchaseOrderDetailInfo,
} from '@carsayo/types';
import FilePreviewer from 'components/common/v3/fileInput/FilePreviewer';
import dayjs from 'dayjs';
import parseSigungu from 'pages/v3/order/purchase/utils/parseSigungu';
import { useState } from 'react';

const TableItem = function ({
  title,
  content,
}: {
  title: string;
  content: string;
}) {
  return (
    <div className='px-4 py-[14px] flex gap-2 justify-between'>
      <div className='w-[100px] flex items-center text-[#555] text-[14px] font-normal leading-[14px] tracking-[-0.28px]'>
        {title}
      </div>
      <div className='flex-auto relative flex items-center justify-end'>
        {content}
      </div>
    </div>
  );
};

/** 구매 정보 탭 */
export default function PurchaseAdditionalInfoContent({
  purchaseOrderDetailInfo,
}: {
  purchaseOrderDetailInfo: PurchaseOrderDetailInfo;
}) {
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  return (
    <div className='flex-auto bg-[#F3F6F9]'>
      <div className='bg-[#F3F6F9] px-5 pt-[14px] pb-[50px] space-y-2.5'>
        {/* 기본 정보 */}
        <div className='bg-white rounded-[16px] divide-y divide-[#E5E5EC]'>
          {/* 렌트 입력정보 */}
          {purchaseOrderDetailInfo.purchaseInfo.렌트 && (
            <>
              <TableItem title='유형' content='장기렌트' />
              <TableItem
                title='구입주체'
                content={
                  BuyerType[purchaseOrderDetailInfo.purchaseInfo.렌트.buyerType]
                }
              />
              <TableItem
                title='계약기간'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.렌트.contractTerm +
                  '개월'
                }
              />

              <TableItem
                title='보증금'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.렌트.price_deposit + '%'
                }
              />
              <TableItem
                title='선납금'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.렌트
                    .price_advancedPayment + '%'
                }
              />
              <TableItem
                title='잔존가치'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.렌트.price_residual + '%'
                }
              />
              <TableItem
                title='약정거리'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.렌트.agreeDistace === 0
                    ? '무제한'
                    : purchaseOrderDetailInfo.purchaseInfo.렌트.agreeDistace.toLocaleString() +
                      'km'
                }
              />
              <TableItem
                title='인수(탁송)지역'
                content={
                  parseSigungu(
                    purchaseOrderDetailInfo.purchaseInfo.렌트.deliveryRegion,
                  )?.name
                }
              />
              <TableItem
                title='정비'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.렌트.maintenancePlan
                }
              />
              <TableItem
                title='운전자 연령'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.렌트.isOver26Age
                    ? '26세 이상'
                    : '26세 미만'
                }
              />

              <TableItem
                title='면책금'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.렌트.indemnityAmount.toLocaleString() +
                  '원'
                }
              />
              <TableItem
                title='보험담보 (자손)'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.렌트.personalInjuryCoverage.toLocaleString() +
                  '원'
                }
              />
              <TableItem
                title='보험담보 (대물)'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.렌트.propertyDamageCoverage.toLocaleString() +
                  '원'
                }
              />
            </>
          )}

          {/* 리스 입력정보 */}
          {purchaseOrderDetailInfo.purchaseInfo.리스 && (
            <>
              <TableItem title='유형' content='리스' />
              <TableItem
                title='구입주체'
                content={
                  BuyerType[purchaseOrderDetailInfo.purchaseInfo.리스.buyerType]
                }
              />
              <TableItem
                title='계약기간'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.리스.contractTerm +
                  '개월'
                }
              />
              <TableItem
                title='보증금'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.리스.price_deposit + '%'
                }
              />
              <TableItem
                title='선납금'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.리스
                    .price_advancedPayment + '%'
                }
              />
              <TableItem
                title='잔존가치'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.리스.price_residual + '%'
                }
              />
              <TableItem
                title='약정거리'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.리스.agreeDistace === 0
                    ? '무제한'
                    : purchaseOrderDetailInfo.purchaseInfo.리스.agreeDistace.toLocaleString() +
                      'km'
                }
              />
              <TableItem
                title='인수(탁송)지역'
                content={
                  parseSigungu(
                    purchaseOrderDetailInfo.purchaseInfo.리스.deliveryRegion,
                  )?.name
                }
              />
              <TableItem
                title='자동차세'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.리스.isIncludingCarTax
                    ? '포함'
                    : '미포함'
                }
              />
            </>
          )}

          {/* 할부 입력정보 */}
          {purchaseOrderDetailInfo.purchaseInfo.할부 && (
            <>
              <TableItem title='유형' content='할부' />
              <TableItem
                title='구입주체'
                content={
                  BuyerType[purchaseOrderDetailInfo.purchaseInfo.할부.buyerType]
                }
              />
              <TableItem
                title='할부기간'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.할부.installmentTerm +
                  '개월'
                }
              />
              <TableItem
                title='할부원금'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.할부
                    .installmentPrincipal === null
                    ? '생략'
                    : purchaseOrderDetailInfo.purchaseInfo.할부.installmentPrincipal.toLocaleString() +
                      '원'
                }
              />

              <TableItem
                title='선수금 (인도금)'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.할부.prepaidPrincipalAmount.toLocaleString() +
                  '원 ' +
                  (purchaseOrderDetailInfo.purchaseInfo.할부.isRegistrationFee
                    ? '(등록비 포함)'
                    : '(등록비 미포함)')
                }
              />

              <TableItem
                title='구입시기'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.할부.purchaseDate
                    ? dayjs(
                        purchaseOrderDetailInfo.purchaseInfo.할부.purchaseDate,
                      ).format('YYYY.MM.DD')
                    : '1개월 이내'
                }
              />
              <TableItem
                title='인수(탁송)지역'
                content={
                  parseSigungu(
                    purchaseOrderDetailInfo.purchaseInfo.할부.deliveryRegion,
                  )?.name
                }
              />
              <TableItem
                title='거주(등록)지역'
                content={
                  parseSigungu(
                    purchaseOrderDetailInfo.purchaseInfo.할부.residenceRegion,
                  )?.name
                }
              />
              <TableItem
                title='면세조건'
                content={
                  DutyFreeCondition[
                    purchaseOrderDetailInfo.purchaseInfo.할부.dutyFreeCondition
                  ]
                }
              />
            </>
          )}
          {/* 일시불 입력정보 */}
          {purchaseOrderDetailInfo.purchaseInfo.일시불 && (
            <>
              <TableItem title='유형' content='일시불' />
              <TableItem
                title='구입주체'
                content={
                  BuyerType[
                    purchaseOrderDetailInfo.purchaseInfo.일시불.buyerType
                  ]
                }
              />
              <TableItem
                title='구입시기'
                content={
                  purchaseOrderDetailInfo.purchaseInfo.일시불.purchaseDate
                    ? dayjs(
                        purchaseOrderDetailInfo.purchaseInfo.일시불
                          .purchaseDate,
                      ).format('YYYY.MM.DD')
                    : '1개월 이내'
                }
              />
              <TableItem
                title='인수(탁송)지역'
                content={
                  parseSigungu(
                    purchaseOrderDetailInfo.purchaseInfo.일시불.deliveryRegion,
                  )?.name
                }
              />
              <TableItem
                title='거주(등록)지역'
                content={
                  parseSigungu(
                    purchaseOrderDetailInfo.purchaseInfo.일시불.residenceRegion,
                  )?.name
                }
              />
              <TableItem
                title='면세조건'
                content={
                  DutyFreeCondition[
                    purchaseOrderDetailInfo.purchaseInfo.일시불
                      .dutyFreeCondition
                  ]
                }
              />
            </>
          )}
        </div>

        {/* Divider */}
        <div className='py-3'>
          <div className='h-[1px] bg-[#E5EAEE]'></div>
        </div>

        {/* 타사 제공 내용 */}
        {}

        <div className='bg-white rounded-[16px] divide-y divide-[#E5E5EC]'>
          {/* 타사 혜택 내용 */}
          {purchaseOrderDetailInfo.otherCompanyEstimateContent && (
            <div className='px-4 py-[14px] flex flex-col gap-3'>
              <div className='w-[100px] flex items-center text-[#555] text-[14px] font-normal leading-[14px] tracking-[-0.28px]'>
                타사 혜택 내용
              </div>
              <div className='whitespace-pre-line break-keep text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
                {purchaseOrderDetailInfo.otherCompanyEstimateContent}
              </div>
            </div>
          )}

          {/* 타사 견적서 파일 내용
          @note 슈퍼카일 경우 구매 계약서로 라벨 변경 
          */}
          {purchaseOrderDetailInfo.otherCompanyEstimateFile && (
            <div className='px-4 py-[14px] flex flex-col gap-3'>
              <div className='w-[100px] flex items-center text-[#555] text-[14px] font-normal leading-[14px] tracking-[-0.28px]'>
                {purchaseOrderDetailInfo.isSuperCar
                  ? '구매 계약서'
                  : '타사 견적서'}
              </div>
              <button
                onClick={() => {
                  setIsPreviewVisible(true);
                }}
                className='bg-[#F3F6F9] rounded-[8px] px-[14px] py-[18px] h-[50px] flex items-center justify-between'
              >
                <div className='underline text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px] truncate'>
                  {purchaseOrderDetailInfo.otherCompanyEstimateFile.name}.
                  {purchaseOrderDetailInfo.otherCompanyEstimateFile.extension}
                </div>
              </button>

              <FilePreviewer
                isOpen={isPreviewVisible}
                setIsOpen={setIsPreviewVisible}
                fileInfo={purchaseOrderDetailInfo.otherCompanyEstimateFile}
              />
            </div>
          )}
        </div>

        {/* 요청 내용 */}
        {purchaseOrderDetailInfo.additionalRequest && (
          <div className='bg-white rounded-[16px] divide-y divide-[#E5E5EC]'>
            <div className='px-4 py-[14px] flex flex-col gap-3'>
              <div className='w-[100px] flex items-center text-[#555] text-[14px] font-normal leading-[14px] tracking-[-0.28px]'>
                요청 내용
              </div>
              <div className='whitespace-pre-line break-keep text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
                {purchaseOrderDetailInfo.additionalRequest}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
