/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import AnswerBubble from '../component/AnswerBubble';
import AskCardLayout from '../component/AskCardLayout';
import { AskInsuranceOrder } from '../data';
import { cn } from 'utils';
import ResetButton from '../component/ResetButton';
import { CustomerActionInterface } from '../ask';
import HelloBubble from '../component/HelloBubble';
import { useNavigate } from 'react-router-dom';
import { addHistoryInsuranceCooconClick } from 'apis/history';
import {
  InsuranceCompany,
  InsuranceCompanyData,
} from 'pages/static/insurance/data/company';
import { cooconUrl } from 'utils/cooconUrl';

interface InsuranceMenuCardInterface {
  customerAction: CustomerActionInterface | null;
  setCustomerAction: (data: CustomerActionInterface) => void;
  nowTime: Date;
  addHello?: boolean;
}

const InsuranceMenuCard = ({
  customerAction,
  setCustomerAction,
  nowTime,
  addHello,
}: InsuranceMenuCardInterface) => {
  const navigate = useNavigate();
  const handleClickEvents = {
    openCooconInsurance: () => {
      addHistoryInsuranceCooconClick({
        from: `app${location.pathname}`,
        target: '쿠콘',
      });

      window.open(cooconUrl());
    },
    moveToInsurance: () => {
      navigate('/static/insurance');
    },
  };
  return (
    <div className='w-full flex flex-col justify-start items-start gap-[20px]'>
      {!addHello && (
        <AnswerBubble
          time={new Date()}
          title={'다이렉트 자동차 보험'}
          bgColor='bg-[#2A7450]'
        />
      )}

      <AskCardLayout
        chatIcon='/assets/images/v2/chat-carkey-icon2.png'
        chatName='카키'
        time={new Date()}
        title='다이렉트 자동차 보험 가입 방법은?'
        addBubble={
          addHello && (
            <HelloBubble
              title={'안녕하세요, 카키입니다!'}
              bgColor='bg-[#fff]'
              textColor='text-[#111111]'
            />
          )
        }
      >
        <div className='w-full flex flex-col'>
          <p className='mb-[14px] text-[15px] leading-[22.5px] text-[#555555] flex flex-col justify-start items-start'>
            <span>아래의 순서대로 진행한다면 더 좋은 혜택으로</span>
            <span>가입할 수 있습니다.</span>
          </p>
          <ul className='bg-[#D4F2E3] rounded-[10px] mb-[10px]'>
            <li
              onClick={handleClickEvents.openCooconInsurance}
              className='p-[12px] border-b-[2px] border-dashed border-white cursor-pointer'
            >
              <div className='flex justify-start items-center gap-[6px] mb-[11px]'>
                <span className='font-[600] leading-[14px] text-[#2A7450] bg-white rounded-full w-[24px] h-[24px] flex justify-center items-center'>
                  1
                </span>
                <h5 className='text-[15px] leading-[15px] font-[600]'>
                  최저가 확인을 위한 보험 비교하기
                </h5>
              </div>
              <div className='bg-white p-[10px] rounded-[6px]'>
                <div className=' w-full flex justify-between items-center mb-[14px] pt-1.5'>
                  <div className='pl-1'>
                    <div className='flex justify-start items-center gap-[2px] mb-[6px] '>
                      <strong className='inline-block font-[500] text-[14px] leading-[14px]'>
                        다이렉트 자동차 보험
                      </strong>
                      <img
                        src='/assets/images/v2/shield-tick.png'
                        alt='확인 아이콘'
                        className='inline-block ml-[2px] w-3 object-contain'
                      />
                    </div>
                    <p className='text-[12px] leading-[12px] text-[#666666]'>
                      9개 보험사 한번에 비교하기
                    </p>
                  </div>
                  <ul className='relative shrink-0 min-w-[84px] flex justify-end items-center'>
                    {AskInsuranceOrder.map((el, index) => {
                      const targetCompanyName = el as InsuranceCompany;
                      const targetCompanyData =
                        InsuranceCompanyData[targetCompanyName];
                      return (
                        <li
                          className={`absolute`}
                          style={{ left: `${index * 6.4}px` }}
                        >
                          <img
                            key={targetCompanyName}
                            src={targetCompanyData.company.logo.png}
                            alt={`보험사 로고_${targetCompanyName}`}
                            className='w-[32px] h-[32px] object-contain'
                          />
                        </li>
                      );
                    })}
                  </ul>
                </div>
                <p className='w-full text-[8px] px-[2px] gap-[20px] leading-[8px] font-[300] flex justify-between items-center text-[#5B7B6B]'>
                  <span>
                    손해보험협회 심의필 제129622호(2024.08.01 ~ 2025.07.31)
                  </span>
                  <span className='shrink-0'>쿠콘</span>
                </p>
              </div>
            </li>
            <li
              onClick={handleClickEvents.moveToInsurance}
              className='p-[12px] cursor-pointer'
            >
              <div className='flex justify-start items-center gap-[6px] mb-[11px]'>
                <span className='font-[600] leading-[14px] text-[#2A7450] bg-white rounded-full w-[24px] h-[24px] flex justify-center items-center'>
                  2
                </span>
                <h5 className='text-[15px] leading-[15px] font-[600]'>
                  혜택을 더해 결정한 보험사 가입하기
                </h5>
              </div>
              <div className='bg-white py-2 pr-[12px] pl-[15px] rounded-[6px] flex justify-between items-stretch gap-[10px]'>
                <div className='pt-[5px]'>
                  <div>
                    <strong className='font-[500]'>최대 3만원 혜택</strong>
                  </div>
                  <p className='text-[12px] leading-[12px] font-[400] text-[#666666] mt-[6px]'>
                    카드사별로 제공하는 혜택 받기
                  </p>
                </div>
                <div>
                  <img
                    src='/assets/images/png/ask-insurance-cardImg.png'
                    alt='카드이미지'
                    className='h-[64px] object-contain'
                  />
                </div>
              </div>
            </li>
          </ul>
          <div className='bg-[#FEE6E5] p-[12px] pt-4 rounded-[10px] mb-[14px]'>
            <h5 className='text-[15px] leading-[15px] font-[600] mb-[8px]'>
              <strong className='text-[#FF4747] font-[600] mr-[3px]'>
                잠깐!
              </strong>
              <span>자동차 보험 만기일 등록하기</span>
            </h5>
            <p className='text-[13px] leading-[16.9px] text-[#555555] font-[400] mb-4 max-w-[227px] break-keep'>
              아래의 순서대로 진행한다면 더 좋은 혜택으로 가입할 수 있습니다.
            </p>
            <div
              className='w-full pr-7 flex justify-start items-center relative bg-white shadow-[0px_6px_10px_#1111110F] p-[10px] rounded-[6px] gap-[10px]'
              onClick={() => {
                setCustomerAction({
                  id: 'insuranceAddOpen',
                  textShow: false,
                  text: '나의 보험 만기일 등록',
                  actionReturn: 'open ',
                  time: new Date(),
                });
              }}
            >
              <div className='shrink-0 bg-[#3DCDB633] w-[28px] h-[28px] border border-[#2DA49033] rounded-full flex justify-center items-center'>
                <img
                  src='/assets/images/v2/shield.png'
                  alt='방패이미지'
                  className='w-[18px] object-contain'
                />
              </div>
              <span className='text-[15px] leading-[15px] font-[400]'>
                나의 보험 만기일 등록
              </span>
              <img
                src='/assets/images/v2/export.png'
                alt='내보내기아이콘'
                className='absolute right-[16px] bottom-0 top-0 my-auto w-4 object-contain'
              />
            </div>
          </div>
          <ResetButton
            clickEvent={() => {
              setCustomerAction({
                id: 'reset',
                textShow: false,
                text: '처음으로',
                actionReturn: 'reset',
                time: new Date(),
              });
            }}
          />
        </div>
      </AskCardLayout>
    </div>
  );
};
export default InsuranceMenuCard;
