import { UseFormReturn } from 'react-hook-form';
import { z } from 'zod';

export const OrderSellingSchema = z.object({
  progress: z.number().min(0).max(100).optional(), // 진척도: 0~100 사이의 값
  currentStep: z.number().optional(), // 주문 작성 임시저장 Step
  drivenDistance: z.number().nullable(), // 입력받은 주행거리
  sellRegionId: z.number().nullable().optional(), // 판매 지역 (SigunguId)
  boughtMethod: z
    .enum(['cash', 'capitalLease', 'operatingLease'])
    .nullable()
    .optional(), // 차량 구매 방식
  additionalRequest: z.string().nullable().optional(), // 요청내용 (선택)
  additionalInfo: z
    .object({
      optionSelect: z.object({
        optionList: z.array(
          z.object({
            optname: z.string(),
            optprice: z.number(),
          }),
        ),
        옵션없음: z.boolean(),
        직접입력: z.object({
          selected: z.boolean(),
          content: z.string().optional(),
        }),
      }),
      carState: z.object({
        타이어교체: z.object({
          selected: z.boolean(),
          content: z.string().optional(),
        }),
        외관스크래치: z.object({
          selected: z.boolean(),
          content: z.string().optional(),
        }),
        정비수리필요: z.object({
          selected: z.boolean(),
          content: z.string().optional(),
          additional: z.string().optional(),
        }),
        차량장단점: z.object({
          selected: z.boolean(),
          content: z.string().optional(),
        }),
      }),
      accident: z.object({
        사고없음: z.object({
          selected: z.boolean(),
          content: z.string().optional(),
        }),
        판금교환: z.object({
          selected: z.boolean(),
          content: z.string().optional(),
        }),
        모름: z.object({
          selected: z.boolean(),
          content: z.string().optional(),
        }),
      }),
    })
    .nullable()
    .optional(),
  file_1_id: z.string().nullable().optional(),
  file_2_id: z.string().nullable().optional(),
  file_3_id: z.string().nullable().optional(),
  file_4_id: z.string().nullable().optional(),
  file_5_id: z.string().nullable().optional(),
  file_6_id: z.string().nullable().optional(),
});

export type OrderSellingFormData = z.infer<typeof OrderSellingSchema>;
export type OrderSellingForm = UseFormReturn<OrderSellingFormData>;
