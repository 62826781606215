/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { X } from 'lucide-react';
import { PurchaseCarInfo } from '@carsayo/types';
import combineCarName from '../../utils/combineCarName';

const PriceInfoItem = ({ label, value }: { label: string; value: string }) => {
  return (
    <div className='flex justify-between items-start gap-2'>
      <div className='text-[#7D848A] text-[14px] font-medium leading-[19.6px] tracking-[-0.28px] break-keep'>
        {label}
      </div>
      <div className='flex-none text-[#222] text-right text-[14px] font-medium leading-[19.6px] tracking-[-0.28px]'>
        {value}
      </div>
    </div>
  );
};

export default function OrderPurchasePriceInfoDrawer({
  open,
  setOpen,
  selectedCarData,
  totalPrice,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedCarData: PurchaseCarInfo;
  totalPrice: number;
}) {
  return (
    <Drawer
      open={open}
      onOpenChange={(open) => {
        if (!open) setOpen(false);
      }}
    >
      {
        <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
          <DrawerHeader className='relative'>
            <DrawerTitle>총 예상 금액</DrawerTitle>
            <DrawerClose asChild>
              <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
                <X className='h-7 w-7' />
              </Button>
            </DrawerClose>
          </DrawerHeader>
          <div className='px-5 my-[30px]'>
            <div className='flex flex-col gap-3.5'>
              <PriceInfoItem
                label={
                  '[모델] ' +
                  combineCarName({
                    modelName: selectedCarData.carModel?.name,
                    trimName: selectedCarData.carTrim?.name,
                    gradeName: selectedCarData.carGrade?.name,
                  })
                }
                value={selectedCarData.carGrade?.price.toLocaleString() + '원'}
              />

              {selectedCarData.carColor && (
                <PriceInfoItem
                  label={'[외장색상] ' + selectedCarData.carColor.name}
                  value={`${selectedCarData.carColor.price > 0 ? '+' : ''}${selectedCarData.carColor.price.toLocaleString()}원`}
                />
              )}

              {selectedCarData.interiorCarColor && (
                <PriceInfoItem
                  label={'[내장색상] ' + selectedCarData.interiorCarColor.name}
                  value={`${selectedCarData.interiorCarColor.price > 0 ? '+' : ''}${selectedCarData.interiorCarColor.price.toLocaleString()}원`}
                />
              )}

              {selectedCarData.interiorCarColor?.internalSelection && (
                <PriceInfoItem
                  label={
                    '[내장가니쉬] ' +
                    selectedCarData.interiorCarColor.internalSelection.name
                  }
                  value={`${selectedCarData.interiorCarColor.internalSelection.price > 0 ? '+' : ''}${selectedCarData.interiorCarColor.internalSelection.price.toLocaleString()}원`}
                />
              )}

              {selectedCarData.carOptionList?.map((el) => {
                return (
                  <PriceInfoItem
                    key={el.id}
                    label={'[옵션] ' + el.name}
                    value={`${el.price > 0 ? '+' : ''}${el.price.toLocaleString()}원`}
                  />
                );
              })}
            </div>

            {/* Divider */}
            <div className='w-full h-[1px] my-6 bg-[#E5EAEE]'></div>

            <div className='flex justify-between items-center my-6'>
              <div className='text-[#555] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                총 예상 금액
              </div>
              <div className='flex-auto mt-[1.5px] flex justify-end items-center gap-1'>
                <span className='text-[#222] text-right text-[18px] font-semibold leading-[18px] tracking-[-0.36px]'>
                  {totalPrice.toLocaleString()}원
                </span>
              </div>
            </div>

            <div className='p-4 bg-[#F3F6F9] rounded-[10px] text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
              자동차 할인 금액에 따라 금액은 변동될 수 있습니다.
            </div>
          </div>
        </DrawerContent>
      }
    </Drawer>
  );
}
