/* eslint-disable @typescript-eslint/no-unused-vars */
'use client';

import {
  ANONYMOUS,
  loadTossPayments,
  WidgetAgreementWidget,
} from '@tosspayments/tosspayments-sdk';
import {
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';
import { toast } from 'sonner';
import useMemberStore from 'store/useMemberStore';
import { AVALIABLE_ORDER_PRICE } from '../constants';
import genOrdercode from 'utils/genOrdercode';
import { cn } from 'utils';
import { isDesktop, isMobile } from 'react-device-detect';

export type TossPaymentsWidgetRef = {
  handlePaymentRequest: () => void;
};

const TOSSPAYMENTVARIENT = 'carsayo';

const TossPaymentsWidget = forwardRef<
  TossPaymentsWidgetRef,
  {
    type: 'purchase' | 'selling';
    setIsPending: (isPending: boolean) => void;
  }
>(({ type, setIsPending }, ref) => {
  const widgetRef = useRef<any>(null);
  const { loginMember } = useMemberStore();

  const initTossPayments = useCallback(async () => {
    const tossPayments = await loadTossPayments(
      process.env.REACT_APP_TOSS_PAYMENT_WIDGET_KEY as string,
    );
    widgetRef.current = tossPayments.widgets({ customerKey: ANONYMOUS });

    await widgetRef.current.setAmount({
      currency: 'KRW',
      value: AVALIABLE_ORDER_PRICE,
    });

    await widgetRef.current.renderPaymentMethods({
      selector: '#payment-method',
      variantKey: TOSSPAYMENTVARIENT,
    });

    const agreementWidget: WidgetAgreementWidget =
      await widgetRef.current.renderAgreement({
        selector: '#payment-agreement',
        variantKey: TOSSPAYMENTVARIENT,
      });

    setIsPending(false);
  }, []);

  const handlePaymentRequest = async () => {
    setIsPending(true);
    try {
      await widgetRef.current.requestPayment({
        orderId: genOrdercode(),
        orderName: '카사요 견적 요청권 구매',
        successUrl: `${window.location.origin}/order/payment/success?type=${type}`,
        failUrl: `${window.location.origin}/order/payment/failure?type=${type}`,
        customerEmail: loginMember?.email ?? undefined,
        customerName: loginMember?.name_real ?? undefined,
        customerMobilePhone: loginMember?.phoneNumber ?? undefined,
        metadata: {
          user_id: loginMember?.id ?? undefined,
          type: type,
        },
      });
    } catch (error) {
      toast.error((error as Error).message);
    } finally {
      setIsPending(false);
    }
  };

  useEffect(() => {
    initTossPayments();
  }, [initTossPayments]);

  // 🔥 외부에서 `handlePaymentRequest`를 호출할 수 있도록 설정
  useImperativeHandle(ref, () => ({
    handlePaymentRequest,
  }));

  return (
    <>
      <div id='payment-method' />
      <div
        id='payment-agreement'
        className={cn(isDesktop && 'px-[30px]', isMobile && 'my-[-25px]')}
      />
    </>
  );
});

TossPaymentsWidget.displayName = 'TossPaymentsWidget';

export default TossPaymentsWidget;
