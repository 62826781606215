'use client';

import {
  Box,
  CircularProgress,
  FormHelperText,
  Typography,
} from '@mui/material';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import React, { useEffect, useRef, useState } from 'react';
import { isAndroid } from 'react-device-detect';

import { FileInfo } from '@carsayo/types';
import FilePreviewer from './FilePreviewer';
import useFileUpload from 'hooks/useFileUpload';
import { getFileInfo } from 'apis/authentication';

type AcceptType =
  | 'image/*'
  | 'audio/*'
  | 'video/*'
  | 'text/*'
  | 'application/pdf'
  | '.jpg'
  | '.jpeg'
  | '.png'
  | '.gif';

export interface FileInputProps
  extends Omit<
    React.InputHTMLAttributes<HTMLInputElement>,
    'accept' | 'onChange' | 'type'
  > {
  accept: AcceptType | AcceptType[];
  onUpload: (fileInfo: FileInfo) => void;

  /** 비공개 파일 여부
   * @default false
   */
  isPrivate?: boolean;

  /** 파일 삭제 메소드
   * @note 이 함수가 정의되어 있을 때에만 파일 삭제 버튼이 나타납니다.
   * @default undefined
   */
  onDelete?: () => void;

  /** 초기 파일 아이디값 */
  initialFileId?: string;
  /**
   * 파일명을 직접 설정할 수 있습니다.
   * @default 업로드 파일명
   */
  fileName?: string;
  error?: boolean;
  helperText?: string;
}

/** Turncate 처리 깔끔하게 해야함 */
export default function FileInput({
  accept,
  onUpload,
  onDelete,
  isPrivate,
  initialFileId,
  fileName,
  error,
  helperText,
  ...rest
}: FileInputProps) {
  const queryClient = useQueryClient();

  const [flag, setFlag] = useState(false);

  const { data: initialFileInfo } = useQuery({
    queryKey: ['fileInfo', initialFileId],
    queryFn: async () => {
      if (!initialFileId || flag) return null;
      return await getFileInfo({ fileId: initialFileId });
    },
  });

  useEffect(() => {
    if (!initialFileInfo) return;
    if (flag) return;
    setCurrentFileInfo(initialFileInfo);
    setFlag(true);
  }, [initialFileInfo]);

  const acceptValue = Array.isArray(accept) ? accept.join(',') : accept;
  const [currentFileInfo, setCurrentFileInfo] = useState<FileInfo | null>(null);

  const { handleFileUpload, isUploading } = useFileUpload();

  const ref = useRef<HTMLInputElement>(null);

  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  return (
    <>
      <Box
        className={rest.className}
        sx={{
          width: '100%',
          display: 'flex',
          gap: '10px',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'center',
          padding: '10px',
          paddingLeft: '16px',
          border: '1px solid',
          borderColor: error ? '#FF4D4D' : '#E5E5EC',
          borderRadius: '8px',
          boxSizing: 'border-box',
        }}
      >
        {currentFileInfo ? (
          <Typography
            className='truncate'
            variant='body1'
            onClick={() => setIsPreviewVisible(true)}
            sx={{
              cursor: 'pointer',
              textDecoration: 'underline',
              fontSize: '16px',
              lineHeight: '16px',
              fontWeight: '400',
              fontFamily: 'Pretendard',
            }}
          >
            {currentFileInfo.name}.{currentFileInfo.extension}
          </Typography>
        ) : (
          <Typography
            className='truncate'
            variant='body1'
            sx={{
              color: '#999999',
              fontSize: '16px',
              lineHeight: '16px',
              fontWeight: '400',
              fontFamily: 'Pretendard',
            }}
          >
            {rest.placeholder ? rest.placeholder : '파일 업로드'}
          </Typography>
        )}

        <input
          {...rest}
          ref={ref}
          type='file'
          accept={acceptValue}
          disabled={isUploading || rest.disabled}
          /** 안드로이드 웹뷰일 때에만 true */
          capture={isAndroid && Boolean(window.android) ? true : false}
          className='sr-only'
          onChange={(e) =>
            handleFileUpload(
              e,
              (fileInfo: FileInfo) => {
                setFlag(true);
                setCurrentFileInfo(fileInfo);
                queryClient.setQueryData(['fileInfo', fileInfo.id], fileInfo);
                onUpload(fileInfo);

                // 업로드 완료 후 input 초기화
                if (ref.current) {
                  ref.current.value = ''; // input 값 초기화
                }
              },
              {
                name: fileName ?? undefined,
                isPublic: isPrivate === true ? false : true,
              },
            )
          }
        />

        {/* isDeleteBtn == true일 때, 업로드된 파일 존재 시 */}
        {onDelete && currentFileInfo ? (
          <button
            onClick={() => {
              onDelete();
              setCurrentFileInfo(null);
            }}
            className='mr-0.5'
          >
            <svg
              width='24'
              height='24'
              viewBox='0 0 24 24'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <circle cx='12' cy='12' r='12' fill='#909396' />
              <g clipPath='url(#clip0_3399_32150)'>
                <path
                  d='M15.7128 8.28516L8.28418 15.7137'
                  stroke='white'
                  strokeWidth='1.32031'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
                <path
                  d='M8.28418 8.28516L15.7128 15.7137'
                  stroke='white'
                  strokeWidth='1.32031'
                  strokeLinecap='round'
                  strokeLinejoin='round'
                />
              </g>
              <defs>
                <clipPath id='clip0_3399_32150'>
                  <rect
                    width='13'
                    height='13'
                    fill='white'
                    transform='translate(5.5 5.5)'
                  />
                </clipPath>
              </defs>
            </svg>
          </button>
        ) : (
          // 파일선택 버튼
          <button
            onClick={(e) => {
              e.preventDefault();
              ref.current?.click();
            }}
            disabled={isUploading || rest.disabled}
            className='flex-none'
            style={{
              backgroundColor: '#F3F6F9',
              opacity: rest.disabled ? 0.5 : 1,
              height: '30px',
              display: 'flex',
              width: '61px',
              justifyContent: 'center',
              alignItems: 'center',
              fontSize: '13px',
              lineHeight: '13px',
              fontWeight: '500',
              color: '#555555',
              whiteSpace: 'nowrap',
              letterSpacing: '-0.02em',
              borderRadius: '4px',
            }}
          >
            {isUploading ? <CircularProgress size={16} /> : '파일선택'}
          </button>
        )}
      </Box>

      {error && helperText && (
        <FormHelperText
          sx={{
            color: '#FF4D4D',
            fontSize: '12px',
            lineHeight: '20px',
            letterSpacing: '0.4px',
            fontWeight: '400',
            marginX: '14px',
            marginTop: '3px !important',
          }}
        >
          {helperText}
        </FormHelperText>
      )}

      {currentFileInfo && (
        <FilePreviewer
          isOpen={isPreviewVisible}
          setIsOpen={setIsPreviewVisible}
          fileInfo={currentFileInfo}
        />
      )}
    </>
  );
}
