import DealerOrderBidSubpage from './subpage';
import DealerOrderBidDrawer from './drawer';
import { useEffect, useState } from 'react';
import {
  DealerOrderBidMode,
  DealerOrderDetailSubpageProps,
} from 'pages/v3/orderDetailTemp/type';

/**
 * 카매니저 > 견적서 서브페이지
 * @feature 입찰하기 / 입찰 수정하기 / 입찰내용 보기
 * @note open이 true가 될 때 시작합니다.
 * [Create]
 * 1. 이용료 안내 Drawer 오픈
 * 2. Drawer에서 모두 확인했어요 클릭 시 견적서 작성 서브페이지 오픈
 * [Update/View]
 * 1. 견적서 서브페이지 오픈
 */
export default function DealerOrderBid({
  /** open은 시작 역할만 합니다 */
  isOpen,
  setIsOpen,
  orderDetail,
}: DealerOrderDetailSubpageProps) {
  if (!orderDetail) return null;

  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSubpageOpen, setIsSubpageOpen] = useState(false);

  // 생성 / 수정 / 견적서 보기 전환
  const [mode, setMode] = useState<DealerOrderBidMode>(
    orderDetail.bid?.isReaded ? 'view' : 'create',
  );

  useEffect(() => {
    if (orderDetail.bid) {
      setMode('view');
    } else {
      setMode('create');
    }
  }, [orderDetail.bid, isOpen]);

  useEffect(() => {
    if (!isOpen) return;

    if (mode === 'create') {
      /** 리스, 장기렌트는 수수료 안내 Drawer를 사용하지 않습니다. */
      if (orderDetail.type === 'lease' || orderDetail.type === 'rent') {
        setIsSubpageOpen(true);
        setIsOpen(false);
        return;
      }

      setIsDrawerOpen(true);
      setIsOpen(false);
      return;
    }

    if (mode === 'update' || mode === 'view') {
      setIsSubpageOpen(true);
      setIsOpen(false);
    }
  }, [isOpen]);

  return (
    <>
      {/* 생성일 때에만 노출합니다.
        @note 수수료 안내 동의 Drawer
       */}
      {mode === 'create' && (
        <DealerOrderBidDrawer
          isOpen={isDrawerOpen}
          setIsOpen={setIsDrawerOpen}
          setSubpageOpen={setIsSubpageOpen}
          orderDetail={orderDetail}
        />
      )}
      <DealerOrderBidSubpage
        mode={mode}
        setMode={setMode}
        isOpen={isSubpageOpen}
        setIsOpen={setIsSubpageOpen}
        orderDetail={orderDetail}
      />
    </>
  );
}
