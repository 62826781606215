/* eslint-disable @typescript-eslint/no-unused-vars */
import { OrderDetail } from '@carsayo/types';
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import dayjs from 'dayjs';
import { X } from 'lucide-react';
import { cn } from 'utils';

/** 차량 시세 Drawer */
const PriceDrawer = ({
  isOpen,
  setIsOpen,
  quoteDetail,
}: {
  isOpen: boolean;
  setIsOpen: (isShowPrice: boolean) => void;
  quoteDetail: OrderDetail;
}) => {
  const carInfo = quoteDetail.selling?.usedCarInfo;
  if (!carInfo) return null;

  return (
    <Drawer open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
        <DrawerHeader className='relative'>
          <DrawerTitle>차량 시세</DrawerTitle>
          <DrawerClose asChild>
            <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
              <X className='h-7 w-7' />
            </Button>
          </DrawerClose>
        </DrawerHeader>

        <div className='flex-1 flex flex-col overflow-y-auto gap-6 p-5'>
          <div className='bg-[#F6F6FC] rounded-[10px] flex items-center py-[14px] px-4 text-[#555555] text-[15px] leading-[150%] font-[500]'>
            조회일자 : {dayjs(carInfo.created_at).format('YYYY.MM.DD')}
          </div>

          {carInfo.mileageprice?.[0] && (
            <div className='border-y border-[#E5EAEE] py-6'>
              <div className='text-[#000000] font-[600] text-[16px] leading-[100%]'>
                주행거리별 시세
              </div>
              <div className='text-[#555555] text-[15px] leading-[100%] mt-[14px] mb-5'>
                상기 가격은 실제 거래 가격과 다를 수 있습니다.
              </div>
              {carInfo.mileageprice && carInfo.mileageprice.length > 0 && (
                <div className='rounded-[10px] bg-[#F3F6F9] py-5 px-4 flex flex-col gap-5'>
                  {carInfo.mileageprice.map((item) => {
                    return (
                      <div
                        key={`mileageprice_${item.price}`}
                        className='flex justify-between text-[15px] leading-[100%] font-[500]'
                      >
                        <div className='text-[#7D848A]'>
                          {item.mileage.toLocaleString()}km
                        </div>
                        <div>{item.price.toLocaleString()}원</div>
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          )}

          {carInfo.usedprice?.[0] && (
            <div className='border-b border-[#E5EAEE] pb-6'>
              <div className='text-[#000000] font-[600] text-[16px] leading-[100%]'>
                매매 기준 시세
              </div>
              <div className='text-[#555555] text-[15px] leading-[100%] mt-[14px] mb-5'>
                동일 차량 기준 한달 평균 매매금액입니다.
              </div>
              <div className='rounded-[10px] bg-[#F3F6F9] py-5 px-4 flex flex-col gap-5'>
                <div className='flex justify-between text-[15px] leading-[100%] font-[500]'>
                  <div className='text-[#7D848A]'>
                    {dayjs(carInfo.usedprice[0].stdym).format('YYYY년 MM월')}
                  </div>
                  <div>{carInfo.usedprice[0].price.toLocaleString()}원</div>
                </div>
              </div>
            </div>
          )}

          <div className='w-full rounded-[10px] bg-[#F3F6F9] p-4'>
            <div className='border-b border-[#E5E5EC] pb-4 text-[15px] font-[500] leading-[22.5px]'>
              확인해 주세요
            </div>
            <ul className='mt-5 flex w-full list-none flex-col items-start justify-start gap-3'>
              <li
                className={cn(
                  'relative break-keep pl-5 text-[14px] leading-[21px] text-[#555555]',
                  'after:absolute after:left-[9px] after:top-[10px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
                )}
              >
                차량의 사고유무, 상태, 옵션, 색상 등이 고려되지 않은시세로
                참고만 해주세요.
              </li>
              <li
                className={cn(
                  'relative break-keep pl-5 text-[14px] leading-[21px] text-[#555555]',
                  'after:absolute after:left-[9px] after:top-[10px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
                )}
              >
                개인 및 자동차매매업자간의 거래 금액은 시세에 반영되지 않습니다.
              </li>
            </ul>
          </div>

          <div className='min-h-[54px] mt-[26px]'>
            <Button className='' onClick={() => setIsOpen(false)}>
              모두 확인했어요
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
};

export default PriceDrawer;
