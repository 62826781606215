import { Button } from 'components/ui/button';
import { cn } from 'utils';
import { useLayoutEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';

export default function EventMain() {
  const [tab, setTab] = useState(1);
  const { setTopTabbarTitle, setTopTabbarState } = useSystemStore();
  const [isEmpty, setIsEmpty] = useState(true);
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      rightElement: 'home',
      leftElement: 'back',
    });
    setTopTabbarTitle('이벤트');
    setIsEmpty(true);
  }, []);

  return (
    <div className='px-5 py-4'>
      <div className='grid grid-cols-2'>
        <div
          className={cn(
            'flex h-[52px] items-center justify-center border-b text-base text-[#767676]',
            tab === 1 && 'border-[#111111] font-semibold text-[#111111]',
          )}
          onClick={() => setTab(1)}
        >
          진행중 이벤트
        </div>
        <div
          className={cn(
            'flex h-[52px] items-center justify-center border-b text-base text-[#767676]',
            tab === 2 && 'border-[#111111] font-semibold text-[#111111]',
          )}
          onClick={() => setTab(2)}
        >
          종료된 이벤트
        </div>
      </div>
      {tab === 1 && (
        <div className='space-y-8'>
          {isEmpty ? (
            <div className='w-full min-h-[200px] flex flex-col gap-4 justify-center items-center'>
              <img
                src={'/assets/images/import/reviewWrite.svg'}
                alt='리뷰작성아이콘'
              />
              <p>진행중인 이벤트가 없습니다.</p>
            </div>
          ) : (
            <>
              <div>
                {Array.from({ length: 3 }).map((_, i) => (
                  <Link to='detail/1' key={i} className='mt-8 block'>
                    <div className='relative'>
                      <img src='/assets/images/v2/event1.png' alt='' />
                    </div>
                    <div className='mt-4 text-title'>
                      카사요의 자동차 문화 이야기 개편 EVENT{' '}
                    </div>
                    <div className='mt-1 text-desc text-[#555555]'>
                      2024.05.27 ~ 06.09
                    </div>
                  </Link>
                ))}
              </div>
              <Button variant='outline' className='mt-8 rounded-none'>
                더보기
              </Button>
            </>
          )}
        </div>
      )}
      {tab === 2 && (
        <div className='space-y-8'>
          {isEmpty ? (
            <div className='w-full min-h-[200px] flex flex-col gap-4 justify-center items-center'>
              <img
                src={'/assets/images/import/reviewWrite.svg'}
                alt='리뷰작성아이콘'
              />
              <p>종료된 이벤트가 없습니다.</p>
            </div>
          ) : (
            <>
              <div>
                {Array.from({ length: 3 }).map((_, i) => (
                  <Link to='detail/1' key={i} className='mt-8 block'>
                    <div className='relative'>
                      <img src='/assets/images/v2/event1.png' alt='' />
                    </div>
                    <div className='mt-4 text-title'>
                      카사요의 자동차 문화 이야기 개편 EVENT{' '}
                    </div>
                    <div className='mt-1 text-desc text-[#555555]'>
                      2024.05.27 ~ 06.09
                    </div>
                  </Link>
                ))}
              </div>
              <Button variant='outline' className='mt-8 rounded-none'>
                더보기
              </Button>
            </>
          )}
        </div>
      )}
    </div>
  );
}
