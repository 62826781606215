/* eslint-disable @typescript-eslint/no-unused-vars */
import { OrderCancelDTO, OrderHoldDTO } from '@carsayo/types';
import { customerCancelQuoteApi, customerHoldQuoteApi } from 'apis/quote';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import { Button } from 'components/ui/button';
import { Textarea } from 'components/ui/textarea';
import V3_Confirm from 'components_v3/dialog/Confirm';
import { X } from 'lucide-react';
import { useLayoutEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import CancelLetter from './components/CancelLetter';
import HoldLetter from 'pages/v3/orderDetailTemp/components/subpage/hold/images/HoldLetter';

const QuoteProcessReason = () => {
  // type : cancel | hold
  const { type, id } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const orderType = searchParams.get('type'); // newCar/sellCar

  const [reason, setReason] = useState({
    select: '',
    text: '',
  });
  const [isOpen, setIsOpen] = useState(false);
  const { setTopTabbarState, setIsBottomTabbar, setLoadingDim } =
    useSystemStore();

  const mainText = type === 'cancel' ? '취소' : '보류';

  const handleClickEvents = {
    // 진행 중 주문 취소
    cancel: async () => {
      if (!id) return;
      setLoadingDim(true);
      const param = {
        orderId: id,
        title: reason.select,
        reason: reason.text,
      } as OrderCancelDTO;
      try {
        await customerCancelQuoteApi(param);
        setIsOpen(true);
      } catch (error: any) {
        CarsayoToast.error(error?.message);
      }
      setLoadingDim(false);
    },
    hold: async () => {
      if (!id) return;
      setLoadingDim(true);
      const param = {
        orderId: id,
        title: reason.select,
        reason: reason.text,
      } as OrderHoldDTO;
      try {
        await customerHoldQuoteApi(param);
        setIsOpen(true);
      } catch (error: any) {
        CarsayoToast.error(error?.message);
      }
      setLoadingDim(false);
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'hidden',
      mode: 'subpage',
      title: ' ',
      leftElement: undefined,
      rightElement: undefined,
    });
    setIsBottomTabbar(false);
  }, []);

  const subText = orderType === 'sellCar' ? '판매' : '주문';

  return (
    <div className='h-full w-full flex flex-col relative justify-between'>
      <header>
        <div className='w-full h-[60px] flex items-center justify-between bg-white/50 sticky top-0 px-4'>
          <div className='w-[32px]'></div>
          <div className='text-[#222] text-center font-Pretendard text-[20px] font-semibold leading-[20px] tracking-[-0.4px]'>
            {subText} {mainText}
          </div>
          <button onClick={() => navigate(-1)}>
            <X className='stroke-[1.4px]' size={32} />
          </button>
        </div>
      </header>
      <div className='relative flex flex-col flex-auto justify-between overflow-y-auto pb-[10px] px-5 gap-3'>
        <div className='mt-[30px] flex flex-col gap-[50px]'>
          <div className='font-[500] text-[18px] leading-[160%]'>
            진행 중 어떤 문제가 있으셨나요?
            <br />
            불편함이 있다면 카사요가 도와드릴게요.
          </div>

          <div>
            <div>{mainText} 사유</div>
            <div className='mt-3 mb-[10px]'>
              <SelectV3
                value={reason.select}
                placeholder={`${mainText} 사유를 선택해 주세요.`}
                onValueChange={(value) =>
                  setReason({ ...reason, select: value })
                }
              >
                {[
                  '개인적인 사유',
                  '다른 플랫폼 또는 매매단지에 판매',
                  '카 매니저와의 갈등',
                  '기타',
                ].map((item) => {
                  return (
                    <SelectV3Item key={item} value={item}>
                      {item}
                    </SelectV3Item>
                  );
                })}
              </SelectV3>
            </div>
            <div className='relative bg-white'>
              <Textarea
                value={reason.text}
                maxLength={1000}
                onChange={(e) => {
                  setReason({ ...reason, text: e.target.value });
                }}
                className='h-[300px] border rounded-[8px] border-[#E5E5EC]'
                placeholder={`${mainText} 사유를 입력해 주세요`}
              />
              <div className='absolute bottom-4 right-2.5 text-[#767676]'>
                {reason.text.length}/1,000
              </div>
            </div>
          </div>
        </div>

        <div className='flex justify-between gap-[7px]'>
          <Button
            variant='outline'
            className='bg-white'
            onClick={() => navigate(-1)}
          >
            닫기
          </Button>
          <Button
            onClick={() => {
              if (type === 'cancel') {
                handleClickEvents.cancel();
              } else if (type === 'hold') {
                handleClickEvents.hold();
              }
            }}
            disabled={
              reason.select.trimEnd() === '' || reason.text.trimEnd() === ''
            }
          >
            판매 {mainText}
          </Button>
        </div>
      </div>

      <V3_Confirm
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        onConfirm={() => navigate(-1)}
        blockUsingHistory={true}
        disableBackdropClose={true}
        title=''
        agreeText='확인'
        isAlert={true}
      >
        <div className='flex flex-col items-center justify-center gap-5'>
          {type === 'cancel' ? <CancelLetter /> : <HoldLetter />}
          <div className='flex flex-col items-center justify-center gap-1.5'>
            <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
              {subText} {mainText} 완료
            </div>
            <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
              {type === 'cancel' && (
                <>
                  주문이 {mainText}되었습니다.
                  <br />
                </>
              )}
              카 매니저님의 노고를 감안하여
              <br />
              {orderType === 'newCar' ? '견적' : '판매'} 요청권 1회는 차감
              처리되며
              {type === 'cancel' ? (
                ''
              ) : (
                <>
                  <br />
                  주문 재개 시 {orderType === 'newCar' ? '견적' : '판매'}{' '}
                  요청권이 필요하지 않습니다.
                </>
              )}
              <br />더 나은 서비스 제공을 위해 노력하겠습니다.
            </div>
          </div>
        </div>
      </V3_Confirm>
    </div>
  );
};

export default QuoteProcessReason;
