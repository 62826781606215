import { SellingOrderDTO } from '@carsayo/types';
import CarInfoItem from './CarInfoItem';

export default function ProgressItem({
  info,
}: {
  info?: SellingOrderDTO['orderData'] | null;
}) {
  return (
    <div className='relative bg-white rounded-[8px] p-4 flex flex-col gap-4 cursor-pointer'>
      <CarInfoItem
        img={info?.usedCarInfo.carInfo.modelimage}
        modelname={info?.usedCarInfo.carInfo.modelname}
        carName={info?.usedCarInfo.carName}
      />
      <div className='font-[600] text-[14px] leading-[100%] absolute right-[14px] text-primary bottom-[30px]'>
        {info?.progress}%
      </div>
      {/* 하단 % */}
      <div className='relative h-1 bg-[#F3F6F9] rounded-[100px]'>
        <div
          className='absolute inset-0 right-auto bg-primary rounded-[100px]'
          style={{
            width: `${info?.progress}%`,
          }}
        ></div>
      </div>
    </div>
  );
}
