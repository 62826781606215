import { CarCategory } from '@carsayo/types';
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { cn } from 'utils';

export function SelectCatrgory({
  isOpen,
  setIsOpen,
  setFilter,
  filter,
  usingCategory,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  filter: {
    category: CarCategory | null;
  };
  setFilter: (value: { category: CarCategory | null }) => void;
  usingCategory: CarCategory[];
}) {
  const [innerSelectCategory, setInnerSelectCategory] =
    useState<CarCategory | null>(filter.category);
  useEffect(() => {
    if (isOpen) {
      setInnerSelectCategory(filter.category);
    }
  }, [isOpen]);

  return (
    <Drawer open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
        <DrawerHeader className='relative'>
          <DrawerTitle className='text-center text-lg font-semibold'>
            필터
          </DrawerTitle>
          <DrawerClose asChild>
            <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
              <X className='h-7 w-7' />
            </Button>
          </DrawerClose>
        </DrawerHeader>
        <div className='p-5'>
          <div className='pb-5 text-[#222] text-[15px] font-semibold leading-[15px] tracking-[-0.3px]'>
            유형
          </div>

          <div className='min-h-[150px]'>
            <div className='grid grid-cols-4 gap-1.5'>
              {usingCategory.map((category) => {
                return (
                  <div
                    key={category.id}
                    onClick={() => {
                      if (innerSelectCategory?.id === category.id) {
                        setInnerSelectCategory(null);
                      } else setInnerSelectCategory(category);
                    }}
                    className={cn(
                      'cursor-pointer border border-[#E5E5EC] rounded-[8px] py-3 px-4 flex items-center justify-center',
                      innerSelectCategory?.id === category.id &&
                        'border-[#7273F9]',
                    )}
                  >
                    {category.name}
                  </div>
                );
              })}
            </div>
          </div>

          <div className='mt-6 flex gap-2.5 bg-[#F7F7FB]'>
            <Button
              variant='outline'
              className='bg-white w-[115px]'
              onClick={() => {
                setInnerSelectCategory(null);
                setFilter({ ...filter, category: null });
                setIsOpen(false);
              }}
            >
              초기화
            </Button>
            <Button
              onClick={() => {
                setFilter({ ...filter, category: innerSelectCategory });
                setIsOpen(false);
              }}
            >
              확인
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
