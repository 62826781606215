/* eslint-disable @typescript-eslint/no-unused-vars */
import { SidoId, CarMakerId, SidoData } from '@carsayo/types';
import {
  updateDealerSalesArea,
  updateDealerSalesMaker,
  updateLeaseRentType,
} from 'apis/setting';
import { Checkbox } from 'components/ui/checkbox';
import { DomesticBrandList, ImportedBrandList } from 'mocks/carMakerList';
import { useLayoutEffect, useState } from 'react';
import useMemberStore from 'store/useMemberStore';
import useSystemStore from 'store/useSystemStore';

export default function MypageDealerRegionBrand() {
  const { setTopTabbarState, setLoadingDim } = useSystemStore();
  const { loginMember, setLoginMember } = useMemberStore();

  const [selectedSido, setSelectedSido] = useState<SidoId[]>([]);
  const [selectedCarMaker, setSelectedCarMaker] = useState<CarMakerId[]>([]);

  const handleClickEvents = {
    region: async (sidoId: SidoId) => {
      if (!selectedSido) return;
      setLoadingDim(true);

      let updatedSidoList = [...selectedSido];

      if (updatedSidoList.includes(sidoId)) {
        updatedSidoList = updatedSidoList.filter((el) => {
          return el !== sidoId;
        });
      } else {
        updatedSidoList = updatedSidoList.concat([sidoId]);
      }

      const updatedMember = await updateDealerSalesArea({
        sidoIdList: updatedSidoList,
      });
      setSelectedSido(updatedSidoList);
      setLoginMember(updatedMember);
      setLoadingDim(false);
    },
    maker: async (makerId: CarMakerId) => {
      if (!selectedSido) return;
      setLoadingDim(true);

      let updatedMakerList = [...selectedCarMaker];

      if (updatedMakerList.includes(makerId)) {
        updatedMakerList = updatedMakerList.filter((el) => {
          return el !== makerId;
        });
      } else {
        updatedMakerList = updatedMakerList.concat([makerId]);
      }

      const updatedMember = await updateDealerSalesMaker({
        makerIdList: updatedMakerList,
      });
      setSelectedCarMaker(updatedMakerList);
      setLoginMember(updatedMember);
      setLoadingDim(false);
    },

    wholeRegion: async () => {
      setLoadingDim(true);

      const currentState = selectedSido.length === SidoData.length;

      const updateRegionList = currentState
        ? []
        : SidoData.map((el) => {
            return el.id;
          });
      const updatedMember = await updateDealerSalesArea({
        sidoIdList: updateRegionList,
      });
      setSelectedSido(updateRegionList);
      setLoginMember(updatedMember);
      setLoadingDim(false);
    },
    wholeDomestic: async () => {
      setLoadingDim(true);

      const currentState =
        DomesticBrandList.filter((el) => {
          return selectedCarMaker.includes(el.id);
        }).length === DomesticBrandList.length;

      const updateMakerList = selectedCarMaker
        .filter((el) => {
          return !DomesticBrandList.map((el2) => {
            return el2.id as number;
          }).includes(el);
        })
        .concat(
          currentState
            ? []
            : DomesticBrandList.map((el) => {
                return el.id;
              }),
        );

      const updatedMember = await updateDealerSalesMaker({
        makerIdList: updateMakerList,
      });
      setSelectedCarMaker(updateMakerList);
      setLoginMember(updatedMember);
      setLoadingDim(false);
    },
    wholeImported: async () => {
      setLoadingDim(true);

      const currentState =
        ImportedBrandList.filter((el) => {
          return selectedCarMaker.includes(el.id);
        }).length === ImportedBrandList.length;

      const updateMakerList = selectedCarMaker
        .filter((el) => {
          return !ImportedBrandList.map((el2) => {
            return el2.id as number;
          }).includes(el);
        })
        .concat(
          currentState
            ? []
            : ImportedBrandList.map((el) => {
                return el.id;
              }),
        );

      const updatedMember = await updateDealerSalesMaker({
        makerIdList: updateMakerList,
      });
      setSelectedCarMaker(updateMakerList);
      setLoginMember(updatedMember);
      setLoadingDim(false);
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '카매니저 설정',
      rightElement: 'home',
      leftElement: 'back',
    });

    setSelectedSido(
      loginMember?.dealerInfo?.salesArea
        ? loginMember.dealerInfo.salesArea.map((el) => {
            return el.id;
          })
        : [],
    );
    setSelectedCarMaker(
      loginMember?.dealerInfo?.salesMaker
        ? loginMember.dealerInfo.salesMaker.map((el) => {
            return el.id;
          })
        : [],
    );

    return () => {
      setLoadingDim(false);
    };
  }, []);

  return (
    <div className='space-y-3 bg-[#F1F1F5]'>
      {loginMember?.dealerInfo?.type === 'leaseRent' && (
        <div className='bg-white px-4 pb-8 pt-6'>
          <div className='text-xl font-semibold'>리스 / 장기렌트 설정</div>
          <div className='text-gray-500 pt-1'>
            취급하고자 하는 금융 상품을 선택합니다.
          </div>
          {/* 리스렌트 선택창 */}
          <div className='flex flex-col gap-4 pt-4'>
            <label className='relative w-full border border-[#E5E5EC] transition has-[:checked]:border-primary rounded-md p-4 flex flex-row justify-start items-center gap-[6.4vw]'>
              <div className='bg-[#42e9ff33] w-[16vw] h-[16vw] p-[2.6vw] rounded-full flex justify-center items-center'>
                <img
                  src='/assets/images/v2/sellCar.svg'
                  alt='리스아이콘'
                  className='object-contain w-[80%] h-[80%]'
                />
              </div>
              <div className='text-[#505050]'>
                <p>
                  <strong className='inline-block text-[#111111] text-[18px] font-[600]'>
                    리스 매니저
                  </strong>
                </p>
              </div>
              <input
                type='checkbox'
                name='car'
                value='lease'
                checked={loginMember.dealerInfo.isLease}
                className='hidden'
                onChange={async (e) => {
                  if (!loginMember.dealerInfo) return;
                  setLoadingDim(true);

                  try {
                    const updatedMember = await updateLeaseRentType({
                      isLease: !loginMember.dealerInfo.isLease,
                    });
                    setLoginMember(updatedMember);
                  } catch {
                    /* empty */
                  }

                  setLoadingDim(false);
                }}
              />
            </label>
            <label className='relative w-full border border-[#E5E5EC] transition has-[:checked]:border-primary rounded-md p-4 flex flex-row justify-start items-center gap-[6.4vw]'>
              <div className='bg-[#f972d933] w-[16vw] h-[16vw] p-[2.6vw] rounded-full flex justify-center items-center'>
                <img
                  src='/assets/images/v2/rental.svg'
                  alt='렌트아이콘'
                  className='object-contain w-[80%] h-[80%]'
                />
              </div>
              <div className='text-[#505050]'>
                <p>
                  <strong className='inline-block text-[#111111] text-[18px] font-[600]'>
                    장기렌트 매니저
                  </strong>
                </p>
              </div>
              <input
                type='checkbox'
                name='car'
                value='rent'
                checked={loginMember.dealerInfo.isRent}
                className='hidden'
                onChange={async (e) => {
                  if (!loginMember.dealerInfo) return;
                  setLoadingDim(true);

                  try {
                    const updatedMember = await updateLeaseRentType({
                      isRent: !loginMember.dealerInfo.isRent,
                    });
                    setLoginMember(updatedMember);
                  } catch {
                    /* empty */
                  }

                  setLoadingDim(false);

                  return;
                }}
              />
            </label>
          </div>
        </div>
      )}

      <div className='bg-white px-4 pb-8 pt-6'>
        <div className='text-xl font-semibold'>지역 설정</div>
        <div className='text-gray-500 pt-1'>
          선택된 지역의 주문만 확인할 수 있으며 자유롭게 변경할 수 있습니다.
        </div>
        <div className='mt-4 flex items-center justify-end gap-2'>
          <Checkbox
            id='regionAllCheck'
            checked={selectedSido.length === SidoData.length}
            onClick={handleClickEvents.wholeRegion}
          />
          <label htmlFor='regionAllCheck'>전체 선택</label>
        </div>
        <div className='regionAllCheck mt-4 grid grid-cols-3 gap-x-[21.5px] gap-y-4'>
          {SidoData.map((sido) => (
            <label
              key={sido.name}
              className='group flex h-[42px] items-center justify-center rounded-lg border px-4 text-[#767676] has-[:checked]:border-primary'
            >
              <input
                checked={selectedSido.includes(sido.id)}
                onClick={() => handleClickEvents.region(sido.id)}
                type='checkbox'
                className='w-0 hidden'
              />
              <div className='text-sm font-semibold text-[#111111]'>
                {sido.name_short}
              </div>
            </label>
          ))}
        </div>
      </div>

      {loginMember?.dealerInfo?.type === 'usedcar' ||
      loginMember?.dealerInfo?.type === 'leaseRent' ? (
        <div className='mt-[6.15vh] space-y-2 p-4 bg-white'>
          <div className='flex flex-col gap-[2.15vh] w-full mb-10'>
            <div>
              <div className='text-xl font-semibold'>취급 유형 설정</div>
              <div className='text-gray-500 pt-1'>
                선택된 유형의 차량 주문만 확인할 수 있으며 자유롭게 변경할 수
                있습니다.
              </div>
            </div>
            <label className='relative w-full border border-[#E5E5EC] transition has-[:checked]:border-primary rounded-md p-4 flex flex-row justify-start items-center gap-[6.4vw]'>
              <div className='bg-[#FFD14233] w-[16vw] h-[16vw] p-[2.6vw] rounded-full flex justify-center items-center'>
                <img
                  src='/assets/images/v2/sellCar.svg'
                  alt='렌트카아이콘'
                  className='object-contain w-[80%] h-[80%]'
                />
              </div>
              <div className='text-[#505050]'>
                <p>
                  <strong className='inline-block text-[#111111] text-[18px] font-[600]'>
                    전체 (국산/수입차)
                  </strong>
                </p>
              </div>
              <input
                type='radio'
                name='car'
                value='whole'
                checked={
                  loginMember.dealerInfo.isDomestic &&
                  loginMember.dealerInfo.isImported
                }
                className='hidden'
                onChange={async (e) => {
                  if (!loginMember.dealerInfo) return;
                  setLoadingDim(true);

                  try {
                    const updatedMember = await updateDealerSalesMaker({
                      makerIdList: [],
                      isDomestic: true,
                      isImported: true,
                    });
                    setLoginMember(updatedMember);
                  } catch {
                    /* empty */
                  }

                  setLoadingDim(false);
                }}
              />
            </label>
            <label className='relative w-full border border-[#E5E5EC] transition has-[:checked]:border-primary rounded-md p-4 flex flex-row justify-start items-center gap-[6.4vw]'>
              <div className='bg-[#7273F933] w-[16vw] h-[16vw] p-[2.6vw] rounded-full flex justify-center items-center'>
                <img
                  src='/assets/images/v2/rental.svg'
                  alt='렌트카아이콘'
                  className='object-contain w-[80%] h-[80%]'
                />
              </div>
              <div className='text-[#505050]'>
                <p>
                  <strong className='inline-block text-[#111111] text-[18px] font-[600]'>
                    국산차
                  </strong>
                </p>
              </div>
              <input
                type='radio'
                name='car'
                value='domestic'
                checked={
                  loginMember.dealerInfo.isDomestic &&
                  !loginMember.dealerInfo.isImported
                }
                className='hidden'
                onChange={async (e) => {
                  if (!loginMember.dealerInfo) return;
                  setLoadingDim(true);

                  try {
                    const updatedMember = await updateDealerSalesMaker({
                      makerIdList: [],
                      isDomestic: true,
                      isImported: false,
                    });
                    setLoginMember(updatedMember);
                  } catch {
                    /* empty */
                  }

                  setLoadingDim(false);

                  return;
                }}
              />
            </label>
            <label className='relative w-full border border-[#E5E5EC] transition has-[:checked]:border-primary rounded-md p-4 flex flex-row justify-start items-center gap-[6.4vw]'>
              <div className='bg-[#CFEDD3] w-[16vw] h-[16vw] p-[2.6vw] rounded-full flex justify-center items-center'>
                <img
                  src='/assets/images/v2/sellCar.svg'
                  alt='렌트카아이콘'
                  className='object-contain w-[80%] h-[80%]'
                />
              </div>
              <div className='text-[#505050]'>
                <p>
                  <strong className='inline-block text-[#111111] text-[18px] font-[600]'>
                    수입차
                  </strong>
                </p>
              </div>
              <input
                type='radio'
                name='car'
                checked={
                  loginMember.dealerInfo.isImported &&
                  !loginMember.dealerInfo.isDomestic
                }
                value='imported'
                className='hidden'
                onChange={async (e) => {
                  if (!loginMember.dealerInfo) return;
                  setLoadingDim(true);

                  try {
                    const updatedMember = await updateDealerSalesMaker({
                      makerIdList: [],
                      isImported: true,
                      isDomestic: false,
                    });
                    setLoginMember(updatedMember);
                  } catch {
                    /* empty */
                  }

                  setLoadingDim(false);

                  return;
                }}
              />
            </label>
          </div>
        </div>
      ) : (
        <>
          <div className='bg-white px-4 py-6 pb-8'>
            <div className='text-xl font-semibold'>국산 브랜드</div>
            <div className='mt-4 flex items-center justify-end gap-2'>
              <Checkbox
                id='brandAllCheck'
                checked={
                  DomesticBrandList.filter((el) => {
                    return selectedCarMaker.includes(el.id);
                  }).length === DomesticBrandList.length
                }
                onClick={handleClickEvents.wholeDomestic}
              />
              <label htmlFor='brandAllCheck'>전체 선택</label>
            </div>
            <div className='brandAllCheck mt-4 grid grid-cols-3 gap-x-[21.5px] gap-y-4'>
              {DomesticBrandList.map((brand) => (
                <label
                  key={brand.name_en}
                  className='group relative h-[88px] rounded-xl border bg-[#F7F7FB] py-4 has-[:checked]:border-primary'
                >
                  <input
                    type='checkbox'
                    className='absolute h-0 w-0 hidden'
                    checked={selectedCarMaker.includes(brand.id)}
                    onClick={() => handleClickEvents.maker(brand.id)}
                  />
                  <div className='mx-auto h-9 w-9'>
                    <img
                      src={`/assets/images/v2/brand/${brand.name_en}.png`}
                      alt={brand.name_en}
                    />
                  </div>
                  <div className='mt-2 text-center text-xs font-semibold'>
                    {brand.name}
                  </div>
                </label>
              ))}
            </div>
          </div>
          <div className='bg-white px-4 py-6 pb-8'>
            <div className='text-xl font-semibold'>수입 브랜드</div>
            <div className='mt-4 flex items-center justify-end gap-2'>
              <Checkbox
                id='incBrandAllCheck'
                checked={
                  ImportedBrandList.filter((el) => {
                    return selectedCarMaker.includes(el.id);
                  }).length === ImportedBrandList.length
                }
                onClick={handleClickEvents.wholeImported}
              />
              <label htmlFor='incBrandAllCheck'>전체 선택</label>
            </div>
            <div className='incBrandAllCheck mt-4 grid grid-cols-3 gap-x-[21.5px] gap-y-4'>
              {ImportedBrandList.map((brand) => (
                <label
                  key={brand.name_en}
                  className='group relative h-[88px] rounded-xl border bg-[#F7F7FB] py-4 has-[:checked]:border-primary'
                >
                  <input
                    type='checkbox'
                    className='absolute h-0 w-0 hidden'
                    checked={selectedCarMaker.includes(brand.id)}
                    onClick={() => handleClickEvents.maker(brand.id)}
                  />
                  <div className='mx-auto h-9 w-9'>
                    <img
                      src={`/assets/images/v2/brand/${brand.name_en}.png`}
                      alt={brand.name_en}
                    />
                  </div>
                  <div className='mt-2 text-center text-xs font-semibold'>
                    {brand.name}
                  </div>
                </label>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
}
