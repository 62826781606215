import NoData from 'components/common/v3/NoData';
import { useNavigate } from 'react-router-dom';

export default function NoPay() {
  const navigate = useNavigate();
  return (
    <div className='w-full h-full flex flex-col justify-between items-center  bg-[#F3F6F9]'>
      <div className='w-full flex-1 flex justify-center items-center'>
        <NoData text='결제내역이 없어요' />
      </div>
      <div className='w-full'>
        <div className='flex flex-col justify-start items-start w-full gap-[10px]'>
          <div className='cursor-pointer relative w-full min-h-[120px] py-[20px] px-4 rounded-[10px] text-white bg-gradient-to-b from-[#5051F7] to-[#7273F9]'>
            <strong className='relative z-[1] text-[16px] leading-[16px] font-[600] inline-block mb-[6px]'>
              견적 요청권 구매하고 견적 받자!
            </strong>
            <p className='relative z-[1] text-[14px] leading-[21px] font-[500]'>
              신차 구매, 내 차 팔기 서비스
            </p>
            <img
              src='/assets/images/v2/pay_banner_img01.png'
              alt=''
              className='h-[120px] object-contain absolute right-0 bottom-0 z-0'
            />
          </div>
          <div
            onClick={() => navigate('/selling/landing')}
            className='relative cursor-pointer w-full min-h-[120px] py-[20px] px-4 rounded-[10px] text-white bg-gradient-to-b from-[#222222] to-[#444444] overflow-hidden'
          >
            <strong className='relative z-[1] text-[16px] leading-[16px] font-[600] inline-block mb-[6px]'>
              판매하실 차량이 있으신가요?
            </strong>
            <p className='relative z-[1] text-[14px] leading-[21px] font-[500]'>
              내 차 팔기 최적 견적 제공
            </p>
            <img
              src='/assets/images/v2/pay_banner_img02.png'
              alt=''
              className='h-[120px] object-contain absolute right-[-27px] bottom-0 z-0'
            />
          </div>
        </div>
      </div>
    </div>
  );
}
