/* eslint-disable @typescript-eslint/no-unused-vars */
import { SellingOrderDTO, SellingOrderUpdateDTO } from '@carsayo/types';
import { CustomCheckbox } from 'components_v3/ui/Checkbox';
import { useEffect, useState } from 'react';
import { cn } from 'utils';
import { OrderSellingForm } from '../../../form';
import { Textarea } from 'components/ui/textarea';

const OrderSelling_Option = ({
  sellingOrderData,
  form,
}: {
  sellingOrderData: SellingOrderDTO;
  form: OrderSellingForm;
}) => {
  const [isOption, setIsOption] = useState(
    form.getValues('additionalInfo.optionSelect.옵션없음') !== true ||
      (sellingOrderData?.orderData.usedCarInfo.seloptlist?.length ?? 0) > 0,
  );

  useEffect(() => {
    if (
      (sellingOrderData?.orderData.usedCarInfo.seloptlist?.length ?? 0) === 0
    ) {
      form.setValue('additionalInfo.optionSelect.옵션없음', true);
    }
  }, []);
  return (
    <div>
      <div
        className={cn(
          'flex flex-col gap-5',
          isOption && 'border-b border-[#E5EAEE]',
        )}
      >
        <div className='text-[15px] leading-[100%]'>옵션 여부</div>
        <ul className='mb-[22.5px] grid w-full cursor-pointer grid-cols-2 gap-[7px]'>
          <button
            type='button'
            className={cn(
              'flex h-[50px] w-full items-center justify-center rounded-[8px] border border-[#E5E5EC] bg-[#F3F6F9] text-[15px] font-[500] leading-[15px] text-[#555555] transition-all',
              isOption &&
                'border-[#7273F9] bg-[#F6F6FC] font-[600] text-[#7273F9]',
            )}
            onClick={() => {
              form.setValue('additionalInfo.optionSelect.옵션없음', false);
              setIsOption(true);
            }}
          >
            있음
          </button>
          <button
            type='button'
            className={cn(
              'flex h-[50px] w-full items-center justify-center rounded-[8px] border border-[#E5E5EC] bg-[#F3F6F9] text-[15px] font-[500] leading-[15px] text-[#555555] transition-all',
              !isOption &&
                'border-[#7273F9] bg-[#F6F6FC] font-[600] text-[#7273F9]',
            )}
            onClick={() => {
              if (!sellingOrderData) return;
              setIsOption(false);
              form.setValue('additionalInfo.optionSelect.optionList', []);
              form.setValue('additionalInfo.optionSelect.옵션없음', true);
              form.setValue(
                'additionalInfo.optionSelect.직접입력.selected',
                false,
              );
              form.setValue('additionalInfo.optionSelect.직접입력.content', '');
            }}
          >
            없음
          </button>
        </ul>
      </div>

      {isOption && (
        <ul className='flex flex-col gap-[10px] mt-6'>
          {sellingOrderData?.orderData.usedCarInfo.seloptlist &&
            sellingOrderData?.orderData.usedCarInfo.seloptlist.length > 0 &&
            sellingOrderData?.orderData.usedCarInfo.seloptlist.map(
              (option, index) => {
                const isChecked = form
                  .watch('additionalInfo.optionSelect.optionList')
                  ?.some(
                    (el) =>
                      String(el.optname).replaceAll(' ', '') ===
                      String(option.optname).replaceAll(' ', ''),
                  );

                return (
                  <div
                    key={`option_${index}`}
                    className='rounded-[10px] bg-[#F3F6F9] py-[13px] px-4 flex gap-[10px] items-center justify-between cursor-pointer'
                    onClick={() => {
                      let updatedOptionList =
                        form.getValues(
                          'additionalInfo.optionSelect.optionList',
                        ) || [];

                      if (!isChecked) {
                        updatedOptionList = [
                          ...updatedOptionList,
                          {
                            optname: option.optname,
                            optprice: Number(option.optprice),
                          },
                        ];
                      } else {
                        updatedOptionList = updatedOptionList.filter(
                          (el) =>
                            String(el.optname).replaceAll(' ', '') !==
                            String(option.optname).replaceAll(' ', ''),
                        );
                      }

                      form.setValue(
                        'additionalInfo.optionSelect.optionList',
                        updatedOptionList,
                      );
                      form.setValue(
                        'additionalInfo.optionSelect.옵션없음',
                        false,
                      );
                    }}
                  >
                    <span className='font-[500] text-[16px] leading-[150%]'>
                      {option.optname}
                    </span>
                    <CustomCheckbox
                      value=''
                      checked={isChecked ?? false}
                      onChange={() => {}}
                    />
                  </div>
                );
              },
            )}

          {/* 직접 입력 옵션 */}
          <div
            key='option_self'
            className='rounded-[10px] bg-[#F3F6F9] py-[13px] px-4 flex gap-[10px] items-center justify-between cursor-pointer'
            onClick={() => {
              const isSelected = form.watch(
                'additionalInfo.optionSelect.직접입력.selected',
              );
              form.setValue(
                'additionalInfo.optionSelect.직접입력.selected',
                !isSelected,
              );
              if (!isSelected) {
                form.setValue('additionalInfo.optionSelect.옵션없음', false);
              } else {
                form.setValue(
                  'additionalInfo.optionSelect.직접입력.content',
                  '',
                );
              }
            }}
          >
            <span className='font-[500] text-[16px] leading-[150%]'>
              직접 입력
            </span>
            <CustomCheckbox
              value=''
              checked={
                form.watch('additionalInfo.optionSelect.직접입력.selected') ??
                false
              }
              onChange={() => {}} // 클릭 이벤트는 li에서 처리하므로 필요 없음
            />
          </div>

          {/* 직접 입력 textarea */}
          <div className='relative'>
            <Textarea
              className='rounded-[8px]'
              placeholder='추가 옵션이 있다면 입력해 주세요. (예:외관튜닝)'
              maxLength={1000}
              value={
                form.watch('additionalInfo.optionSelect.직접입력.content') ?? ''
              }
              onChange={(e) => {
                const value = e.target.value.trim();
                form.setValue(
                  'additionalInfo.optionSelect.직접입력.content',
                  value,
                );
                form.setValue(
                  'additionalInfo.optionSelect.직접입력.selected',
                  value !== '',
                );
              }}
            ></Textarea>
            <span className='text-[#999999] font-[500] text-[14px] leading-[160%] absolute bottom-3 right-4'>
              {`${form.watch('additionalInfo.optionSelect.직접입력.content')?.length || 0}/1,000`}
            </span>
          </div>
        </ul>
      )}
    </div>
  );
};

export default OrderSelling_Option;
