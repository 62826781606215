/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from '@mui/material';
import MainFooter from 'pages/main/components/MainFooter';
import { useEffect, useState } from 'react';
import DealerProfile from './components/profile';
import DealerOrderCountPanal from './components/orderCountPanal';
import DealerBiddingOrderList from './components/biddingOrderList';
import DealerMyOrderList from './components/myOrderList';
import useOnResumeStore from 'store/useOnResumeStore';
import useInvalidateDealerSearch from './hook/invalidateDealerSearch';

export default function DealerMainPage({
  setFlag,
}: {
  setFlag: (flag: boolean) => void;
}) {
  const { setOnResume } = useOnResumeStore();

  const { InvalidateWholeDealerSearch } = useInvalidateDealerSearch();

  // Temp
  const [isOpenCloseNotice, setIsOpenCloseNotice] = useState<boolean>(true);

  // 개발용 환경에서만 보여줌
  // 로컬 제외
  const isDevelopment = process.env.REACT_APP_NODE_ENV === 'development';

  useEffect(() => {
    // 페이지 재진입시 데이터 캐시 전체 무효화
    InvalidateWholeDealerSearch();
    // 푸시나 앱 새로고침 시 데이터 캐시 전체 무효화
    setOnResume(async () => {
      InvalidateWholeDealerSearch();
    });
  }, []);

  return (
    <div className='w-full bg-[#F3F6F9] flex flex-col justify-between items-start'>
      {isOpenCloseNotice && isDevelopment && (
        <div className='absolute z-[100] top-5 right-5'>
          <div className='p-5 shadow-2xl bg-yellow-100 border border-[#E5EAEE] text-black rounded-[16px] gap-2 flex flex-col justify-center items-center'>
            <div className='py-4 text-[14px] leading-[14px] font-[600]'>
              [개발중인 화면]
            </div>

            <Button
              color='secondary'
              onClick={() => {
                setFlag(true);
              }}
            >
              [구] 카매니저 화면 보러가기
            </Button>
            <Button
              color='secondary'
              className='w-full'
              onClick={() => {
                setIsOpenCloseNotice(false);
              }}
            >
              안내창 닫기
            </Button>
          </div>
        </div>
      )}

      <div className='w-full space-y-[30px] pb-[50px]'>
        <DealerProfile />
        <DealerOrderCountPanal />
        <DealerBiddingOrderList />
        <DealerMyOrderList />
      </div>
      <MainFooter />
    </div>
  );
}
