/* eslint-disable @typescript-eslint/no-unused-vars */
import * as Sentry from '@sentry/react';
import MainPageIndex from 'pages/main';
import AuthIndex from 'pages/v2/auth';
import Login from 'pages/v2/auth/loginPage';

import SignupIndex from 'pages/v2/auth/signup';
import SignupMain from 'pages/v2/auth/signup/main';
import SignupCustomerPage from 'pages/v2/auth/signup/customer';
import SignupDealerTypeSelect from 'pages/v2/auth/signup/dealer/select';
import SignupDealerPhoneCheck from 'pages/v2/auth/signup/dealer/phoneCheck';
import SignupDealerAdditionalInfo from 'pages/v2/auth/signup/dealer/additionalInfo';
import SignupDealerUploadFile from 'pages/v2/auth/signup/dealer/authFileUpload';
import SignupDealerCompletePage from 'pages/v2/auth/signup/dealer/complete';
import SignupDealerHandleBrand from 'pages/v2/auth/signup/dealer/handledBrand';

import MypageIndex from 'pages/v2/mypage';
import MypageMain from 'pages/v2/mypage/main';
import MypageInfo from 'pages/v2/mypage/info';
import MypageSignout from 'pages/v2/mypage/signout';
import MypageAlert from 'pages/v2/mypage/alert';
import MypageDealerRegionBrand from 'pages/v2/mypage/dealerRegionBrand';
import MypageDealerSignupForm from 'pages/v2/mypage/dealerSignupForm';
import MypageTermList from 'pages/v2/mypage/termList';
import MypageTermDetail from 'pages/v2/mypage/termDetail';
import MypageTermFinanceConsumerCertificate from 'pages/v2/mypage/termFinanceConsumerCertificate';
import MypageNotice from 'pages/v2/mypage/notice';
import MypageFaq from 'pages/v2/mypage/faq';

import PurchaseIndex from 'pages/v2/purchase';
import PurchaseBrand from 'pages/v2/purchase/brand';
import PurchaseModel from 'pages/v2/purchase/model';
import PurchaseDetail from 'pages/v2/purchase/detail';
import PurchaseColor from 'pages/v2/purchase/color';
import PurchaseOption from 'pages/v2/purchase/option';
import PurchaseMethod from 'pages/v2/purchase/method';
import PurchaseEstimate from 'pages/v2/purchase/estimate';
import PurchaseComplete from 'pages/v2/purchase/complete';

import QuoteIndex from 'pages/v2/quote';
import QuoteDealerMain from 'pages/v2/quote/manager/main';
import QuoteCustomerMain from 'pages/v2/quote/customer/main';
import QuoteDealerDetail from 'pages/v2/quote/manager/detail';
import QuoteCustomerDetail from 'pages/v2/quote/customer/detail';

import CommunityIndex from 'pages/v2/community/index';
import CommunityMain from 'pages/v2/community/main';
import CommunityDetail from 'pages/v2/community/review/detail';
import CommunityReview from 'pages/v2/community/review/review';

import EventIndex from 'pages/v2/event/index';
import EventMain from 'pages/v2/event/main';
import EventDetail from 'pages/v2/event/detail';

import SellingIndex from 'pages/v2/selling';
import SellingLanding from 'pages/v2/selling/landing';
import SellingMain from 'pages/v2/selling/main';
import SellingDetail from 'pages/v2/selling/detail';
import SellingPhoto from 'pages/v2/selling/photo';
import BannedPage from 'pages/v2/bannedPage';
import SignoutDealerPage from 'pages/v2/signoutDealerPage';
import NotFoundPage from 'pages/NotFoundPage';

import InsuranceMain from 'pages/static/insurance/main';
import { createBrowserRouter } from 'react-router-dom';
import CarsayoRouter from 'router';
import MypageCommission from 'pages/v2/mypage/commission';
import Payment from '../pages/v2/purchase/payment';
import CharacterMain from 'pages/static/character/main';
import CharacterDetail from 'pages/static/character/subPage';
import CharacterIndex from 'pages/static/character';
import PromotionIndex from 'pages/static/promotion';
import PromotionMain from 'pages/static/promotion/main';

import AskMain from 'pages/v2/ask/main';
import AskIndex from 'pages/v2/ask';
import AskCarkeyMain from 'pages/v2/ask/theme/carkey';
import CarManagementMain from 'pages/v2/ask/carManagement/carMain';
import AskCargipsaMain from 'pages/v2/ask/theme/cargipsa';
import InquiryAddPage from 'pages/v2/ask/theme/inquiry/InquiryAdd';
import HospitalMainList from 'pages/v2/ask/theme/hospital/hospitalMain';
import HospitalDetailPage from 'pages/v2/ask/theme/hospital/hospitalDetail';
import LocationSettingPage from 'pages/static/setting/locationSetting';
import AskCargongMain from 'pages/v2/ask/theme/carGong';
import RepairMainList from 'pages/v2/ask/theme/repair/repairMain';
import ServiceBrandChoicePage from 'pages/v2/ask/theme/service/brandChoice';
import ServiceMainList from 'pages/v2/ask/theme/service/serviceMain';
import MyInquiryList from 'pages/v2/mypage/inquiry';
import IncidentIndex from 'pages/v2/incident';
import IncidentMain from 'pages/v2/incident/main';
import IncidentDetail from 'pages/v2/incident/detail';
import IncidentReview from 'pages/v2/incident/review';
import IncidentPay from 'pages/v2/incident/pay';
import UserPasswordChange from 'pages/v2/mypage/userPasswordChange';
import NotificationPage from 'pages/v2/mypage/notification';
import ReviewList from 'pages/v2/mypage/review';
import AccidentReportIndex from 'pages/v3/accidentReport';
import AccidentReportInitPage from 'pages/v3/accidentReport/pages/init/page';
import AccidentReportListPage from 'pages/v3/accidentReport/pages/list/page';
import AccidentReportDetailPage from 'pages/v3/accidentReport/pages/info/page';
import CarReviewAddPage from 'pages/v2/mypage/reviewAdd';
import InquiryIndex from 'pages/v2/ask/theme/inquiry';
import CarManagementAdd from 'pages/v2/ask/carManagement/carAdd';
import CarManagementEdit from 'pages/v2/ask/carManagement/carEdit';
import PositionMapSetting from 'pages/v2/ask/theme/repair/positionMap';
import AccidentReportReviewPage from 'pages/v3/accidentReport/pages/review/page';
import OrderPurchasePage from 'pages/v3/order/purchase/mainPage';
import OrderPurchaseFinishPage from 'pages/v3/order/purchase/finishPage';
import AvaliableOrderPurchasePage from 'pages/v3/order/payment/page';
import AvaliableOrderPurchaseSuccessPage from 'pages/v3/order/payment/page/success';
import AvaliableOrderPurchaseFailurePage from 'pages/v3/order/payment/page/failure';
import DealerRequestPage from 'pages/dealer/request';
import DealerQuotePage from 'pages/dealer/quote';
import OrderSellingMainPage from 'pages/v3/order/selling/sellingMainPage';
import OrderSellingPage from 'pages/v3/order/selling/sellingPage';
import OrderSellingFinishPage from 'pages/v3/order/selling/sellingFinishPage';
import OrderDetailCarManager from 'pages/v3/orderDetailTemp';
import CustomerQuoteMain from 'pages/v3/quote/customer/customerMain';
import CustomerQuoteDetail from 'pages/v3/quote/customer/customerDetail';
import QuoteImagesPage from 'pages/v3/quote/customer/imagesPage';
import DealerInfoPage from 'pages/v3/dealerInfo';
import QuoteProcessReason from 'pages/v3/quote/reason';
import QuoteReview from 'pages/v3/quote/review';

const fallbackComponent = () => {
  return (
    <div className='w-full h-auto p-2 flex flex-col justify-center pt-8'>
      <strong className='text-center mb-4 text-[24px] text-[#7273F9]'>
        Not Found
      </strong>
      <p className='flex justify-center items-center flex-col gap-2'>
        <span className='text-[18px] text-[#555555]'>
          예기치 못한 에러가 발생했습니다.
        </span>
        <span className='text-[18px] text-[#555555]'>다시 시도해주세요!</span>
      </p>
    </div>
  );
};

const defaultRoutes = [
  {
    path: '*',
    element: <CarsayoRouter />,
    children: [
      {
        path: 'main',
        element: (
          <Sentry.ErrorBoundary fallback={fallbackComponent}>
            <MainPageIndex />
          </Sentry.ErrorBoundary>
        ),
      },
      {
        path: 'auth',
        element: <AuthIndex />,
        children: [{ path: 'login', element: <Login /> }],
      },
      {
        path: 'auth/signup',
        element: <SignupIndex />,
        children: [
          { path: '', element: <SignupMain /> },
          { path: 'customer/start', element: <SignupCustomerPage /> },
          { path: 'dealer/start', element: <SignupDealerTypeSelect /> },
          { path: 'dealer/phoneCheck', element: <SignupDealerPhoneCheck /> },
          {
            path: 'dealer/additionalInfo',
            element: <SignupDealerAdditionalInfo />,
          },
          {
            path: 'dealer/fileUpload',
            element: <SignupDealerUploadFile />,
          },
          {
            path: 'dealer/handled',
            element: <SignupDealerHandleBrand />,
          },
          {
            path: 'dealer/complete',
            element: <SignupDealerCompletePage />,
          },
        ],
      },
      {
        path: 'mypage',
        element: (
          <Sentry.ErrorBoundary fallback={fallbackComponent}>
            <MypageIndex />
          </Sentry.ErrorBoundary>
        ),
        children: [
          { path: '', element: <MypageMain /> },
          { path: 'info', element: <MypageInfo /> },
          { path: 'signout', element: <MypageSignout /> },
          { path: 'alert', element: <MypageAlert /> },
          { path: 'commission', element: <MypageCommission /> },
          { path: 'dealerRegionBrand', element: <MypageDealerRegionBrand /> },
          { path: 'dealerSignupForm', element: <MypageDealerSignupForm /> },
          { path: 'termList', element: <MypageTermList /> },
          { path: 'termDetail/:id', element: <MypageTermDetail /> },
          {
            path: 'termFinanceConsumerCertificate',
            element: <MypageTermFinanceConsumerCertificate />,
          },
          { path: 'notice', element: <MypageNotice /> },
          { path: 'faq', element: <MypageFaq /> },
          { path: 'inquiry', element: <MyInquiryList /> },
          { path: 'password', element: <UserPasswordChange /> },
          { path: 'notification', element: <NotificationPage /> },
          { path: 'review', element: <ReviewList /> },
          { path: 'review/add', element: <CarReviewAddPage /> },
        ],
      },
      {
        path: 'order/purchase',
        element: <OrderPurchasePage />,
      },
      {
        path: 'order/purchase/finish',
        element: <OrderPurchaseFinishPage />,
      },
      {
        path: 'order/payment/avaliableOrder',
        element: <AvaliableOrderPurchasePage />,
      },
      {
        path: 'order/payment/success',
        element: <AvaliableOrderPurchaseSuccessPage />,
      },
      {
        path: 'order/payment/failure',
        element: <AvaliableOrderPurchaseFailurePage />,
      },

      { path: 'order/selling/main', element: <OrderSellingMainPage /> },
      {
        path: 'order/selling',
        element: <OrderSellingPage />,
      },
      {
        path: 'order/selling/finish',
        element: <OrderSellingFinishPage />,
      },

      // TODO new 견적함. 경로는 임시
      {
        path: 'quote/reason/:type/:id',
        element: <QuoteProcessReason />,
      },
      {
        path: 'quote/review/:id',
        element: <QuoteReview />,
      },

      {
        path: 'order/quote/customer',
        element: <CustomerQuoteMain />,
      },
      {
        path: 'order/quote/customer/:id',
        element: <CustomerQuoteDetail />,
      },
      {
        path: 'quote/customer/img/:id',
        element: <QuoteImagesPage />,
      },

      /** 구 신차/리스/장기렌트 구매 */
      {
        path: 'purchase',
        element: (
          <Sentry.ErrorBoundary fallback={fallbackComponent}>
            <PurchaseIndex />
          </Sentry.ErrorBoundary>
        ),
        children: [
          { path: 'brand', element: <PurchaseBrand /> },
          { path: 'model', element: <PurchaseModel /> },
          { path: 'detail', element: <PurchaseDetail /> },
          { path: 'color', element: <PurchaseColor /> },
          { path: 'option', element: <PurchaseOption /> },
          { path: 'method', element: <PurchaseMethod /> },
          { path: 'estimate', element: <PurchaseEstimate /> },
          { path: 'complete', element: <PurchaseComplete /> },
        ],
      },
      {
        path: 'payment/:id',
        element: <Payment />,
      },
      {
        path: 'quote',
        element: (
          <Sentry.ErrorBoundary fallback={fallbackComponent}>
            <QuoteIndex />
          </Sentry.ErrorBoundary>
        ),
        children: [
          { path: 'manager', element: <QuoteDealerMain /> },
          { path: 'manager/detail/:id', element: <QuoteDealerDetail /> },
          { path: 'customer', element: <QuoteCustomerMain /> },
          { path: 'customer/detail/:id', element: <QuoteCustomerDetail /> },
        ],
      },
      {
        path: 'community',
        element: <CommunityIndex />,
        children: [
          { path: '', element: <CommunityMain /> },
          { path: 'review', element: <CommunityReview /> },
          { path: 'review/detail/:id', element: <CommunityDetail /> },
        ],
      },
      {
        path: 'event',
        element: <EventIndex />,
        children: [
          { path: '', element: <EventMain /> },
          { path: 'detail/:id', element: <EventDetail /> },
        ],
      },
      {
        path: 'selling',
        element: (
          <Sentry.ErrorBoundary fallback={fallbackComponent}>
            <SellingIndex />
          </Sentry.ErrorBoundary>
        ),
        children: [
          { path: 'landing', element: <SellingLanding /> },
          { path: 'main', element: <SellingMain /> },
          { path: 'detail', element: <SellingDetail /> },
          { path: 'photo', element: <SellingPhoto /> },
        ],
      },
      { path: 'banned', element: <BannedPage /> },
      { path: 'signoutDealer', element: <SignoutDealerPage /> },
      {
        path: 'ask',
        element: <AskIndex />,
        children: [
          { path: 'main', element: <AskMain /> },
          { path: 'theme/carkey', element: <AskCarkeyMain /> },
          { path: 'theme/cargipsa', element: <AskCargipsaMain /> },
          { path: 'carManagement/main', element: <CarManagementMain /> },
          { path: 'carManagement/add', element: <CarManagementAdd /> },
          { path: 'carManagement/edit', element: <CarManagementEdit /> },
          { path: 'hospital', element: <HospitalMainList /> },
          { path: 'hospital/detail/:id', element: <HospitalDetailPage /> },
          { path: 'theme/cargong', element: <AskCargongMain /> },
          { path: 'repair', element: <RepairMainList /> },
          { path: 'repair/location', element: <PositionMapSetting /> },
          { path: 'service', element: <ServiceBrandChoicePage /> },
          { path: 'service/list', element: <ServiceMainList /> },
        ],
      },

      {
        path: 'accidentReport',
        element: <AccidentReportIndex />,
        children: [
          { path: 'init', element: <AccidentReportInitPage /> },
          { path: 'list', element: <AccidentReportListPage /> },
          { path: 'detail/:id', element: <AccidentReportDetailPage /> },
          { path: 'review/:id', element: <AccidentReportReviewPage /> },
        ],
      },

      // 다른 페이지에서도 공통으로 쓰여지게 돼서 뺐습니다.
      {
        path: 'inquiry',
        element: <InquiryIndex />,
        children: [
          { path: 'write', element: <InquiryAddPage /> },
          { path: 'update/:id', element: <InquiryAddPage /> },
        ],
      },
      {
        path: 'static/insurance',
        element: <InsuranceMain />,
      },
      {
        path: 'static/repairshop',
        element: <RepairMainList />,
      },
      {
        path: 'static/character',
        element: <CharacterIndex />,
        children: [
          { path: '', element: <CharacterMain /> },
          { path: 'detail/:id', element: <CharacterDetail /> },
        ],
      },
      {
        path: 'static/promotion',
        element: <PromotionIndex />,
        children: [{ path: '', element: <PromotionMain /> }],
      },
      {
        path: 'static/location',
        element: <LocationSettingPage />,
      },
      {
        path: 'incident',
        element: <IncidentIndex />,
        children: [
          { path: 'main', element: <IncidentMain /> },
          { path: 'detail/:id', element: <IncidentDetail /> },
          { path: 'review', element: <IncidentReview /> },
          { path: 'pay', element: <IncidentPay /> },
        ],
      },
      {
        path: 'dealerInfo/:id',
        element: <DealerInfoPage />,
      },
      { path: '*', element: <NotFoundPage /> },
    ],
  },
];
// 퍼블리싱용
const publishingRoutes = [
  {
    path: 'dealer',
    element: <CarsayoRouter />,
    children: [
      {
        path: 'request',
        element: <DealerRequestPage />,
      },
      { path: 'quote', element: <DealerQuotePage /> },

      { path: 'order/detail/:id', element: <OrderDetailCarManager /> },
    ],
  },
];

const routesList = defaultRoutes.concat(publishingRoutes);

export const routes = createBrowserRouter(routesList);
