import { cn } from 'utils';

export default function DealerOrderDetailTabSelector({
  tabs,
  tab,
  setTab,
}: {
  tabs: string[];
  tab: string;
  setTab: (tab: string) => void;
}) {
  return (
    <div className={`grid grid-cols-${tabs.length} w-full relative`}>
      {tabs.map((el, index) => {
        return (
          <div
            key={el}
            className={cn(
              'cursor-pointer flex text-center h-[50px] items-center justify-center text-[#A1A7AC] font-[500] text-[15px] leading-[100%]',
              el === tab && 'text-[#222222]',
            )}
            onClick={() => setTab(el)}
          >
            {el}
          </div>
        );
      })}
      {
        <div
          className='absolute bottom-0 h-0.5 bg-black transition-all duration-300'
          style={{
            width: `${100 / tabs.length}%`,
            left: `${tabs.findIndex((t) => t === tab) * (100 / tabs.length)}%`,
          }}
        />
      }
    </div>
  );
}
