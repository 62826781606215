import { motion, AnimatePresence } from 'framer-motion';
import { useState } from 'react';
import { Link } from 'react-router-dom';

export default function MainFooter() {
  const [isCopyright, setIsCopyright] = useState(false);

  const handleClickEvents = {
    call: (callNumber: string) => {
      const telUrl = 'tel:' + callNumber;
      window.location.href = telUrl;
    },
  };

  return (
    <div className='mt-3 bg-[#F7F7FB] px-4 pb-8 pt-6'>
      <Link
        to={'/mypage/termList'}
        className='-mx-4 flex items-center justify-center text-[#505050] text-[14px]'
      >
        <div className='px-[2vw]'>이용약관</div>
        <div className='h-3 w-[1px] bg-[#E5E5EC]'></div>
        <div className='px-[2vw]'>개인정보 처리방침</div>
        <div className='h-3 w-[1px] bg-[#E5E5EC]'></div>
        <div className='px-[2vw]'>금융소비자보호법등록증</div>
      </Link>
      <div
        className='mt-6 flex justify-center gap-1'
        onClick={() => setIsCopyright(!isCopyright)}
      >
        <img
          src='/assets/images/v2/footerCarsayo.png'
          alt='logo'
          className='h-4'
        />
        {!isCopyright ? (
          <img src='/assets/images/v2/chevronBottom.svg' alt='chevron bottom' />
        ) : (
          <img src='/assets/images/v2/chevronTop.svg' alt='chevron top' />
        )}
      </div>

      <AnimatePresence initial={false}>
        {isCopyright && (
          <motion.div
            initial='collapsed'
            animate='open'
            exit='collapsed'
            variants={{
              open: { opacity: 1, height: 'auto' },
              collapsed: { opacity: 0, height: 0 },
            }}
            className='pt-8 text-center text-[13px] text-[#767676]'
          >
            <div className='space-y-1.5'>
              <div>카사요(주) 대표 : 최용민</div>
              <div>
                <span className='text-[13px] text-[#111111]'>벤처기업</span>{' '}
                20240514030044 (혁신성장형)
              </div>
              <div>(04790) 서울특별시 성동구 아차산로 49, 1403호</div>
              <div className='flex justify-center gap-2'>
                <div>사업자등록번호 : 142-87-01847</div>
              </div>
              <div>통신판매업신고 : 제 2021-서울성동-00533</div>
              <div>제휴 · 문의 메일 : info@carsayo.net</div>
            </div>
            <div
              onClick={() => handleClickEvents.call('16681571')}
              className='mt-3 text-[#111111] underline underline-offset-4'
            >
              고객센터 : 1668-1571
            </div>
            {/* <div className='mt-[26px] flex items-center justify-center gap-4'>
            <a href='/main'>
              <img src='/assets/images/v2/naver.svg' alt='' />
            </a>
            <a href='/main'>
              <img src='/assets/images/v2/blog.svg' alt='' />
            </a>
            <a href='/main'>
              <img src='/assets/images/v2/kakao.svg' alt='' />
            </a>
            <a href='/main'>
              <img src='/assets/images/v2/instargram.svg' alt='' />
            </a>
          </div> */}
            <div className='mt-8'>
              Copyright ⓒ 카사요(주) ALL RIGHTS RESERVED.
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
