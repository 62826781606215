/* eslint-disable @typescript-eslint/no-unused-vars */
import { FileInfo, SellingOrderDTO } from '@carsayo/types';
import { useState } from 'react';
import useFileUploader from 'store/useFileUploader';
import { encodeFileToBase64 } from 'utils/convertImage';
import { cn } from 'utils';
import { X } from 'lucide-react';
import { QuoteReviewSchema } from '..';
import { UseFormReturn } from 'react-hook-form';
import { z } from 'zod';

export type UploadedFileInfo = {
  file_1_id: FileInfo | null;
  file_2_id: FileInfo | null;
  file_3_id: FileInfo | null;
  file_4_id: FileInfo | null;
};

export default function FileInput({
  fileName,
  fileKey,
  form,
  type,
}: {
  fileName: string;
  fileKey: keyof UploadedFileInfo;
  form: UseFormReturn<z.infer<typeof QuoteReviewSchema>>;
  type?: string;
}) {
  const { fileUpload } = useFileUploader();
  const [imageSrc, setImageSrc] = useState('');
  return (
    <label key={fileKey} className='relative text-[#9EA3B2]'>
      <div
        onClick={() => {
          fileUpload(
            {
              acceptFile: ['image'],
              fileType: 'usedCarImage',
              fileName: fileName,
              isPublic: true,
            },
            async (fileInfo, file) => {
              if (fileInfo.extension !== 'pdf' && file) {
                const base64String = await encodeFileToBase64(file);
                setImageSrc(base64String);
              }

              form.setValue(`${fileKey}`, fileInfo.id);
            },
          );
        }}
        className={cn(
          'flex flex-col items-center justify-center rounded-[6px] border-[2px] border-[#E5EAEE] border-dashed gap-[6px] overflow-hidden relative',
          type === 'mini' ? `h-[107px]` : 'h-[178px]',
        )}
      >
        {imageSrc ? (
          <>
            <img
              className='w-full h-full object-contain'
              src={imageSrc}
              alt=''
            />
            <button
              type='button'
              onClick={(e) => {
                e.stopPropagation();
                setImageSrc('');
                form.setValue(`${fileKey}`, null);
              }}
              className={cn(
                'absolute rounded-full bg-[#000000CC] flex items-center justify-center',
                type === 'mini'
                  ? 'w-[20px] h-[20px] top-[4px] right-[4px]'
                  : 'w-[26px] h-[26px] top-[10px] right-[10px]',
              )}
            >
              <X
                color='white'
                strokeWidth={1.3}
                width={type === 'mini' ? 12 : 15.6}
                height={type === 'mini' ? 12 : 15.6}
              />
            </button>
          </>
        ) : (
          <>
            <img
              src='/assets/images/v2/camera_light.png'
              width={32}
              height={32}
              alt=''
            />
            <span className='text-[15px] font-[500] leading-[100%] text-[#7D848A]'>
              {fileName}
            </span>
          </>
        )}
      </div>
    </label>
  );
}
