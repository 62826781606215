import { PurchaseCarInfo } from '@carsayo/types';

export default function calculateTotalOrderPrice(
  selectedCarData: PurchaseCarInfo,
) {
  if (!selectedCarData) return 0;

  let totalPrice = 0;

  totalPrice += selectedCarData?.carGrade?.price ?? 0;
  if (selectedCarData?.carColor) {
    totalPrice += selectedCarData.carColor.price;
  }
  if (selectedCarData.interiorCarColor?.price) {
    totalPrice += selectedCarData.interiorCarColor.price;
  }
  if (selectedCarData.interiorCarColor?.internalSelection?.price) {
    totalPrice += selectedCarData.interiorCarColor.internalSelection.price;
  }

  selectedCarData.carOptionList?.forEach((el) => {
    totalPrice += el.price;
  });

  return totalPrice;
}
