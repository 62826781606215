import { Button } from 'components/ui/button';
import SubPage from 'components/view/SubPage';
import dayjs from 'dayjs';
import { ChevronLeft, X } from 'lucide-react';
import { useEffect, useState } from 'react';
import { createDealerOrderMemo, deleteDealerOrderMemo_V1 } from 'apis/quote';
import { OrderMemo } from '@carsayo/types';
import { useDealerOrderMemo, useUpdateDealerOrderMemo } from './query';
import MemoPencel from '../../../../images/MemoPencel';
import { CircularProgress } from '@mui/material';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import TextArea from 'components_v3/ui/TextArea';
import V3_Button from 'components_v3/ui/Button';
import { useMutation } from '@tanstack/react-query';
import { MemoCheckbox } from '../../../../ui/MemoCheckbox';

function MemoWriteDrawer({
  open,
  setOpen,
  orderId,
  previousOrderMemoData,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
  orderId: string;
  previousOrderMemoData: OrderMemo[];
}) {
  const [value, setValue] = useState<string>('');

  const updateDealerOrderMemo = useUpdateDealerOrderMemo({
    orderId: orderId,
  });

  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      const data = await createDealerOrderMemo({
        orderId: orderId,
        content: value,
        content_html: value,
      });
      return data;
    },
    onSuccess: (data: OrderMemo) => {
      updateDealerOrderMemo({
        orderId: orderId,
        data: [data, ...previousOrderMemoData],
      });
      setValue('');
      setOpen(false);
    },
  });

  return (
    <Drawer
      open={open}
      onOpenChange={(open) => {
        if (!open) setOpen(false);
      }}
    >
      {
        <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
          <DrawerHeader className='relative'>
            <DrawerTitle>메모 작성</DrawerTitle>
            <DrawerClose asChild>
              <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
                <X className='h-7 w-7' />
              </Button>
            </DrawerClose>
          </DrawerHeader>

          <div className='pt-2.5 p-5 pb-[20px] overflow-y-auto'>
            <TextArea
              className='w-full'
              minRows={10}
              value={value}
              // eslint-disable-next-line jsx-a11y/no-autofocus
              autoFocus={true}
              onChange={(e) => {
                setValue(e.target.value);
              }}
            />
          </div>

          <div className='p-5'>
            <V3_Button
              className='w-full'
              theme='primary'
              isLoading={isPending}
              onClick={() => {
                mutate();
              }}
            >
              저장
            </V3_Button>
          </div>
        </DrawerContent>
      }
    </Drawer>
  );
}

// memo: OrderMemo
function MemoCard({
  memo,
  toggleEdit,
  selectedMemoIdList,
  setSelectedMemoIdList,
}: {
  memo: OrderMemo;
  toggleEdit: boolean;
  selectedMemoIdList: number[];
  setSelectedMemoIdList: (selectedMemoIdList: number[]) => void;
}) {
  return (
    <div className='p-4 bg-white rounded-[8px] flex gap-2.5 items-center justify-between'>
      {/* 컨텐츠 영역 */}
      <div>
        <div
          className='overflow-hidden text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px] whitespace-pre-line'
          dangerouslySetInnerHTML={{
            __html: memo.content_html,
          }}
        ></div>

        <div className='text-[#999] text-[13px] font-medium leading-[13px] tracking-[-0.26px] mt-2.5'>
          {dayjs(memo.created_at).format('YY.MM.DD HH:mm')}
        </div>
      </div>

      {/* 선택 영역 (편집모드일때에만 출력) */}
      {toggleEdit && (
        <div className='flex items-center justify-center'>
          <MemoCheckbox
            checked={selectedMemoIdList.includes(memo.id)}
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedMemoIdList([...selectedMemoIdList, memo.id]);
              } else {
                setSelectedMemoIdList(
                  selectedMemoIdList.filter((id) => id !== memo.id),
                );
              }
            }}
          />
        </div>
      )}
    </div>
  );
}

function MemoList({
  orderMemoData,
  toggleEdit,
  selectedMemoIdList,
  setSelectedMemoIdList,
}: {
  orderMemoData: OrderMemo[];
  toggleEdit: boolean;
  selectedMemoIdList: number[];
  setSelectedMemoIdList: (selectedMemoIdList: number[]) => void;
}) {
  return (
    <section className='relative flex flex-col h-full overflow-y-scroll p-5 gap-2.5 bg-[#F3F6F9]'>
      {orderMemoData.map((el) => {
        return (
          <MemoCard
            key={el.id}
            memo={el}
            toggleEdit={toggleEdit}
            selectedMemoIdList={selectedMemoIdList}
            setSelectedMemoIdList={setSelectedMemoIdList}
          />
        );
      })}
    </section>
  );
}

export default function DealerOrderDetailMemoSubpage({
  isOpen,
  orderId,
  setIsOpen,
}: {
  isOpen: boolean;
  orderId: string;
  setIsOpen: (isOpen: boolean) => void;
}) {
  const {
    data: orderMemoData,
    isLoading,
    refetch,
  } = useDealerOrderMemo({
    orderId: orderId,
  });

  const [isWriteOpen, setIsWriteOpen] = useState<boolean>(false);
  const [toggleEdit, setToggleEdit] = useState<boolean>(false);
  const [selectedMemoIdList, setSelectedMemoIdList] = useState<number[]>([]);

  const { mutate: deleteMutate, isPending: isDeletePending } = useMutation({
    mutationFn: async () => {
      await deleteDealerOrderMemo_V1({
        orderId: orderId,
        memoIds: selectedMemoIdList,
      });
    },
    onSuccess: () => {
      setToggleEdit(false);
      refetch();
    },
  });

  // 편집 모드 선택 내용 초기화
  useEffect(() => {
    setSelectedMemoIdList([]);
  }, [toggleEdit]);

  useEffect(() => {
    setToggleEdit(false);
  }, [isOpen]);

  return (
    <SubPage isOpen={isOpen} setIsOpen={setIsOpen}>
      {/* Loader */}
      {isLoading && (
        <div className='absolute h-full inset-0 flex items-center justify-center'>
          <CircularProgress />
        </div>
      )}

      <div className='flex flex-none h-14 items-center justify-between bg-white px-5'>
        <div
          className='cursor-pointer'
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <ChevronLeft className='w-8 h-8' strokeWidth={1.5} />
        </div>
        <div className='pl-4 grow text-center text-[#222] text-[20px] font-semibold leading-[20px] tracking-[-0.4px]'>
          메모
        </div>
        <div
          className='cursor-pointer'
          onClick={() => {
            setToggleEdit(!toggleEdit);
          }}
        >
          <span className='text-[#222] font-semibold text-[15px] leading-[15px] tracking-[-0.3px]'>
            {toggleEdit ? '취소' : '편집'}
          </span>
        </div>
      </div>

      {orderMemoData && (
        <>
          {orderMemoData.length > 0 ? (
            <MemoList
              orderMemoData={orderMemoData}
              toggleEdit={toggleEdit}
              selectedMemoIdList={selectedMemoIdList}
              setSelectedMemoIdList={setSelectedMemoIdList}
            />
          ) : (
            <div className='bg-[#F3F6F9] w-full h-full flex flex-col gap-4 items-center justify-center'>
              <div className='text-[#A1A7AC] text-center text-[18px] font-semibold leading-[28.8px] tracking-[-0.36px]'>
                작성된 메모가 없습니다.
              </div>
              <div className='text-[#A1A7AC] text-center text-[15px] font-normal leading-[22.5px] tracking-[-0.3px]'>
                기록할 내용을 입력해 보세요.
              </div>
            </div>
          )}

          {/* 메모 작성 기능 */}
          <>
            {/* 메모 작성 Drawer */}
            <MemoWriteDrawer
              open={isWriteOpen}
              setOpen={setIsWriteOpen}
              orderId={orderId}
              previousOrderMemoData={orderMemoData}
            />

            {/* 메모 작성 버튼 */}
            <button
              className='fixed flex justify-center items-center right-5 bottom-5 rounded-full bg-[#7273F9] drop-shadow-[0px_4px_10px_rgba(0,_0,_0,_0.15)] w-[50px] h-[50px] text-white active:bg-opacity-80 cursor-pointer'
              onClick={() => {
                setIsWriteOpen(true);
              }}
            >
              <MemoPencel />
            </button>
          </>
        </>
      )}

      {toggleEdit && (
        <div className='p-5 bg-[#F3F6F9]'>
          <V3_Button
            className='w-full'
            theme='primary'
            disabled={selectedMemoIdList.length === 0}
            isLoading={isDeletePending}
            onClick={() => {
              deleteMutate();
            }}
          >
            삭제 ({selectedMemoIdList.length})
          </V3_Button>
        </div>
      )}
    </SubPage>
  );
}
