import dayjs from 'dayjs';

type HistoryData =
  | {
      type: 'other';
      data: {
        /** 사고일자 */
        OTHER_ACCIDENT_DATE: string;
        /** 보험금 */
        OTHER_INSURANCE_BENEFIT: string;
        /** 수리비용 */
        OTHER_REPAIR_COST: string;
        /** 부품 */
        OTHER_COMPONENT: string;
        /** 공임 */
        OTHER_PAY: string;
        /** 도장 */
        OTHER_PAINTING: string;
      };
    }
  | {
      type: 'my';
      data: {
        MY_ACCIDENT_DATE: string;
        MY_INSURANCE_BENEFIT: string;
        MY_REPAIR_COST: string;
        MY_COMPONENT: string;
        MY_PAY: string;
        MY_PAINTING: string;
      };
    };

const HistoryItem = ({ data, type }: HistoryData) => {
  const keyMap = {
    accidentDate: type === 'my' ? 'MY_ACCIDENT_DATE' : 'OTHER_ACCIDENT_DATE',
    repairCost: type === 'my' ? 'MY_REPAIR_COST' : 'OTHER_REPAIR_COST',
    component: type === 'my' ? 'MY_COMPONENT' : 'OTHER_COMPONENT',
    pay: type === 'my' ? 'MY_PAY' : 'OTHER_PAY',
    painting: type === 'my' ? 'MY_PAINTING' : 'OTHER_PAINTING',
  } as const;
  return (
    <div className='py-5 px-4 rounded-[10px] bg-white'>
      <div className='pb-[17px] border-b border-[#E5EAEE] flex justify-between items-center text-[14px] leading-[150%]'>
        <div className='font-[500]'>
          수리{' '}
          {dayjs((data as any)[keyMap.accidentDate]).format('(YYYY.MM.DD)')}
        </div>
        <div className='flex gap-1 font-[600] text-[16px]'>
          {Number((data as any)[keyMap.repairCost]).toLocaleString()}
          <span className='text-[#555555]'>원</span>
        </div>
      </div>
      <div className='pt-4 flex flex-col gap-[10px]'>
        {Number((data as any)[keyMap.component]) !== 0 && (
          <div className='flex items-center justify-between text-[#555555] text-[14px] leading-[150%]'>
            <div>부품</div>
            <div className='flex gap-1'>
              <span className='font-[500]'>
                {Number((data as any)[keyMap.component]).toLocaleString()}
              </span>
              원
            </div>
          </div>
        )}
        {Number((data as any)[keyMap.pay]) !== 0 && (
          <div className='flex items-center justify-between text-[#555555] text-[14px] leading-[150%]'>
            <div>공임</div>
            <div className='flex gap-1'>
              <span className='font-[500]'>
                {Number((data as any)[keyMap.pay]).toLocaleString()}
              </span>
              원
            </div>
          </div>
        )}
        {Number((data as any)[keyMap.painting]) !== 0 && (
          <div className='flex items-center justify-between text-[#555555] text-[14px] leading-[150%]'>
            <div>도장</div>
            <div className='flex gap-1'>
              <span className='font-[500]'>
                {Number((data as any)[keyMap.painting]).toLocaleString()}
              </span>
              원
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default HistoryItem;
