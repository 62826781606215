import { ChevronRight } from 'lucide-react';
import { OrderSellingForm } from '../../../form';
import parseSigungu from 'pages/v3/order/purchase/utils/parseSigungu';
import { SigunguId } from '@carsayo/types';

const OrderSelling_SellRegion = ({
  form,
  setShowRegion,
}: {
  form: OrderSellingForm;
  setShowRegion: (isShowRegion: boolean) => void;
}) => {
  const regionId = form.watch('sellRegionId');
  const regionNm = regionId ? parseSigungu(regionId as SigunguId)?.name : '';
  return (
    <div className='flex flex-col gap-3'>
      <div>판매 지역</div>
      <div
        onClick={() => setShowRegion(true)}
        className='items-center justify-between cursor-pointer rounded-[10px] border border-[#E5E5EC] text-[#999999] flex h-[50px] px-4 font-[400] text-[16px] leading-[100%]'
      >
        {regionNm ? regionNm : '선택해 주세요.'}
        <ChevronRight strokeWidth={1.5} color='#666666' className='h-6 w-6' />
      </div>
    </div>
  );
};

export default OrderSelling_SellRegion;
