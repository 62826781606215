export const cooconUrl = () => {
  const baseUrl =
    process.env.REACT_APP_NODE_ENV === 'development'
      ? 'https://insu-dev.coocon.co.kr/bridge.act'
      : 'https://insu.coocon.net/bridge.act';

  const isApp =
    Boolean(window.android) ||
    Boolean(window?.webkit?.messageHandlers?.connectFCMWithLoginedMember);

  const orgCd = isApp ? 'RUQBBRKU' : 'VX2mMRCM';
  const browserType = isApp ? '2' : '3';

  const href = `${baseUrl}?org_cd=${orgCd}&browser_type=${browserType}`;

  return href;
};
