/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  CustomerOrderDetail,
  DealerOrderDetail,
  OrderDetail,
  PurchaseCarInfo,
} from '@carsayo/types';
import { Dot } from 'lucide-react';
import OrderPurchasePriceInfoDrawer from 'pages/v3/order/purchase/components/drawer/priceInfo';
import NoticeIcon from 'pages/v3/order/purchase/icon/notice';
import calculateTotalOrderPrice from 'pages/v3/order/purchase/utils/calculateTotalOrderPrice';
import combineCarName from 'pages/v3/order/purchase/utils/combineCarName';
import { useMemo, useState } from 'react';
import useMemberStore from 'store/useMemberStore';
import OrderDetailColorSquare from '../item/ColorSquare';

export const PurchaseCarTable = ({
  purchaseCarInfo,
}: {
  purchaseCarInfo: PurchaseCarInfo;
}) => {
  return (
    <div className='bg-[#F3F6F9] px-5 pt-[14px] pb-[50px] space-y-2.5'>
      {/* 기본 제원 */}
      <div className='bg-white rounded-[16px] divide-y divide-[#E5E5EC]'>
        {/* 브랜드 */}
        <div className='px-4 py-[14px] flex gap-2 justify-between'>
          <div className='flex-none w-[80px] flex items-center text-[#555] text-[14px] font-normal leading-[14px] tracking-[-0.28px]'>
            브랜드
          </div>
          <div className='flex-auto relative flex items-center justify-end'>
            {purchaseCarInfo.carMaker?.image_url && (
              <img
                src={purchaseCarInfo.carMaker?.image_url}
                alt='브랜드 이미지'
                className='w-[24px] h-[24px] object-cover mr-1'
              />
            )}
            <div className='text-[#222] text-right text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
              {purchaseCarInfo.carMaker?.name}
            </div>
          </div>
        </div>

        {/* 모델 */}
        <div className='px-4 py-[14px] flex gap-2 justify-between'>
          <div className='flex-none w-[80px] flex items-center text-[#555] text-[14px] font-normal leading-[14px] tracking-[-0.28px]'>
            모델
          </div>
          <div className='flex-auto relative flex items-center justify-end'>
            <div className='text-[#222] text-right text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
              {purchaseCarInfo.carModel?.name}
            </div>
          </div>
        </div>

        {/* 트림 */}
        <div className='px-4 py-[14px] flex gap-2 justify-between'>
          <div className='flex-none w-[80px] flex items-center text-[#555] text-[14px] font-normal leading-[14px] tracking-[-0.28px]'>
            트림
          </div>
          <div className='flex-auto relative flex items-center justify-end'>
            <div className='text-[#222] text-right text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
              {purchaseCarInfo.carTrim?.name}
            </div>
          </div>
        </div>

        {/* 상세등급 */}
        <div className='px-4 py-[14px] flex gap-2 justify-between'>
          <div className='flex-none w-[80px] flex items-center text-[#555] text-[14px] font-normal leading-[14px] tracking-[-0.28px]'>
            상세등급
          </div>

          <div className='flex flex-col gap-0.5 text-[#222] text-right text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
            <div>{purchaseCarInfo.carGrade?.name}</div>

            {purchaseCarInfo.carGrade?.price &&
              purchaseCarInfo.carGrade?.price !== 0 && (
                <div className='text-[#222] text-right text-[14px] font-semibold leading-[21px] tracking-[-0.28px]'>
                  {purchaseCarInfo.carGrade?.price > 0 && '+'}
                  {purchaseCarInfo.carGrade?.price?.toLocaleString()}원
                </div>
              )}
          </div>
        </div>
      </div>

      {/* 옵션 색상 */}
      <div className='bg-white rounded-[16px] divide-y divide-[#E5E5EC]'>
        {/* 외장색상 */}
        {purchaseCarInfo.carColor && (
          <div className='px-4 py-[14px] flex gap-2 justify-between'>
            <div className='flex-none w-[80px] flex items-center text-[#555] text-[14px] font-normal leading-[14px] tracking-[-0.28px]'>
              외장색상
            </div>
            <div className='flex-auto relative flex items-center justify-between'>
              <div className='mr-2'>
                <OrderDetailColorSquare color={purchaseCarInfo.carColor} />
              </div>
              <div className='flex flex-col gap-0.5 text-[#222] text-right text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
                <div>{purchaseCarInfo.carColor?.name}</div>

                {purchaseCarInfo.carColor?.price !== 0 && (
                  <div className='text-[#222] text-right text-[14px] font-semibold leading-[21px] tracking-[-0.28px]'>
                    {purchaseCarInfo.carColor?.price > 0 && '+'}
                    {purchaseCarInfo.carColor?.price.toLocaleString()}원
                  </div>
                )}
              </div>
            </div>
          </div>
        )}

        {/* 내장색상 */}
        {purchaseCarInfo.interiorCarColor && (
          <>
            <div className='px-4 py-[14px] flex gap-2 justify-between'>
              <div className='flex-none w-[80px] flex items-center text-[#555] text-[14px] font-normal leading-[14px] tracking-[-0.28px]'>
                내장색상
              </div>
              <div className='flex-auto relative flex items-center justify-between'>
                <div className='mr-2'>
                  <OrderDetailColorSquare
                    color={purchaseCarInfo.interiorCarColor}
                  />
                </div>
                <div className='flex flex-col gap-0.5 text-[#222] text-right text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
                  <div>{purchaseCarInfo.interiorCarColor?.name}</div>

                  {purchaseCarInfo.interiorCarColor?.price !== 0 && (
                    <div className='text-[#222] text-right text-[14px] font-semibold leading-[21px] tracking-[-0.28px]'>
                      {purchaseCarInfo.interiorCarColor?.price > 0 && '+'}
                      {purchaseCarInfo.interiorCarColor?.price.toLocaleString()}
                      원
                    </div>
                  )}
                </div>
              </div>
            </div>
            {purchaseCarInfo.interiorCarColor.internalSelection && (
              <div className='px-4 py-[14px] flex gap-2 justify-between'>
                <div className='flex-none w-[80px] flex items-center text-[#555] text-[14px] font-normal leading-[14px] tracking-[-0.28px]'>
                  내장 가니쉬
                </div>
                <div className='flex-auto relative flex items-center justify-between'>
                  <div className='mr-2'>
                    <OrderDetailColorSquare
                      color={purchaseCarInfo.interiorCarColor.internalSelection}
                    />
                  </div>
                  <div className='flex flex-col gap-0.5 text-[#222] text-right text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
                    <div>
                      {purchaseCarInfo.interiorCarColor.internalSelection?.name}
                    </div>

                    {purchaseCarInfo.interiorCarColor.internalSelection
                      ?.price !== 0 && (
                      <div className='text-[#222] text-right text-[14px] font-semibold leading-[21px] tracking-[-0.28px]'>
                        {purchaseCarInfo.interiorCarColor.internalSelection
                          ?.price > 0 && '+'}
                        {purchaseCarInfo.interiorCarColor.internalSelection?.price.toLocaleString()}
                        원
                      </div>
                    )}
                  </div>
                </div>
              </div>
            )}
          </>
        )}

        {/* 선택 옵션 목록 */}
        {purchaseCarInfo.carOptionList?.map((el, index) => {
          return (
            <div
              key={el.id}
              className='px-4 py-[14px] flex gap-2 justify-between'
            >
              <div className='flex-none w-[80px] flex items-center text-[#555] text-[14px] font-normal leading-[14px] tracking-[-0.28px]'>
                추가옵션 {index + 1}
              </div>

              <div className='flex flex-col gap-0.5 text-[#222] text-right text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
                <div>{el.name}</div>

                {el.price !== 0 && (
                  <div className='text-[#222] text-right text-[14px] font-semibold leading-[21px] tracking-[-0.28px]'>
                    {el.price > 0 && '+'}
                    {el.price.toLocaleString()}원
                  </div>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

/** 모델 정보 탭 */
export default function PurchaseCarInfoTabContent({
  purchaseCarInfo,
  orderDetail,
}: {
  purchaseCarInfo: PurchaseCarInfo;
  orderDetail: OrderDetail &
    Partial<CustomerOrderDetail> &
    Partial<DealerOrderDetail>;
}) {
  const { loginMember } = useMemberStore();

  const [openPriceInfoDrawer, setOpenPriceInfoDrawer] = useState(false);

  const totalPrice = useMemo(() => {
    if (!purchaseCarInfo) return 0;

    return calculateTotalOrderPrice(purchaseCarInfo);
  }, [purchaseCarInfo]);

  return (
    <div className='flex-auto'>
      {/* 모델 이미지 */}
      <div className='relative bg-[#F3F6F9] w-full h-[240px] flex items-center justify-center'>
        <img
          src={purchaseCarInfo.carModel?.image_url}
          alt='모델 이미지'
          className='h-[144px] object-cover'
        />
      </div>

      {/* 모델명 */}
      <div className='px-5 mt-7 space-y-2'>
        <div className='text-[#222] font-semibold text-[20px] leading-[20px] tracking-[-0.4px]'>
          {purchaseCarInfo.carMaker?.name} {purchaseCarInfo.carModel?.name}
        </div>

        <div className='flex flex-col'>
          <div className='text-[#555] font-medium text-[15px] leading-[24px] tracking-[-0.3px]'>
            {combineCarName({
              trimName: purchaseCarInfo.carTrim?.name,
              gradeName: purchaseCarInfo.carGrade?.name,
            })}
          </div>
        </div>
      </div>

      {/* 구매 방식
      @note 카매니저 일 때에만 노출됩니다.
       */}
      {loginMember?.dealerInfo && (
        <div className='px-5 mt-5'>
          <div className='rounded-[16px] border border-[#E5EAEE] px-4 py-6 space-y-5'>
            <div className='grid grid-cols-4'>
              <div className='text-[#555] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                유형
              </div>
              <div className='col-span-3 text-[#222] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                {orderDetail.purchase?.purchaseInfo.method}
              </div>
            </div>
            <div className='grid grid-cols-4'>
              <div className='text-[#555] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                연락처
              </div>
              {/* 낙찰자일 경우에만 노출 */}
              <div className='col-span-3 text-[#222] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                {orderDetail.bid?.state === 'accepted' ? (
                  orderDetail?.member?.phoneNumber
                ) : (
                  <span className='text-[#999]'>낙찰 후 공개</span>
                )}
              </div>
            </div>
            {/* 이메일이 있을 경우에만 노출 */}
            {orderDetail?.member?.email && (
              <div className='grid grid-cols-4'>
                <div className='text-[#555] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                  이메일
                </div>
                {/* 낙찰자일 경우에만 노출 */}
                <div className='col-span-3 text-[#222] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                  {orderDetail.bid?.state === 'accepted' ? (
                    orderDetail?.member?.email
                  ) : (
                    <span className='text-[#999]'>낙찰 후 공개</span>
                  )}
                </div>
              </div>
            )}
          </div>
        </div>
      )}

      {/* 가격 안내 */}
      <div className='px-5 mt-4 mb-5'>
        <div className='flex justify-between items-center bg-[#F3F6F9] p-[14px_16px] rounded-[16px]'>
          <div className='text-[#555] text-[14px] font-normal leading-[14px] tracking-[-0.28px]'>
            총 예상 금액
          </div>
          <button
            onClick={(e) => {
              setOpenPriceInfoDrawer(true);
            }}
            className='flex items-center gap-1'
          >
            <div className='text-[#222] text-right text-[16px] font-semibold leading-[16px] tracking-[-0.32px]'>
              {totalPrice?.toLocaleString()}원
            </div>
            <NoticeIcon />
          </button>
        </div>

        <div className='flex items-center mt-3.5 text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
          <Dot />
          자동차 할인 금액에 따라 금액은 변동될 수 있습니다.
        </div>
      </div>

      <OrderPurchasePriceInfoDrawer
        open={openPriceInfoDrawer}
        setOpen={setOpenPriceInfoDrawer}
        totalPrice={totalPrice}
        selectedCarData={purchaseCarInfo}
      />

      <PurchaseCarTable purchaseCarInfo={purchaseCarInfo} />
    </div>
  );
}
