/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import dayjs from 'dayjs';
import useSystemStore from 'store/useSystemStore';
import { getManagerCommissionList } from 'apis/commission';
import { useNavigate } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  OrderCommissionListData,
  BankCodeData,
  OrderCommissionState,
} from '@carsayo/types';
import { CircularProgress } from '@mui/material';
import NoData from 'components/common/v3/NoData';
import V3_Button from 'components_v3/ui/Button';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import useOnResumeStore from 'store/useOnResumeStore';
import { motion } from 'framer-motion';
import { ChevronDown, X } from 'lucide-react';
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerClose,
} from 'components/ui/drawer';
import { cn } from 'utils';

function SelectCatrgory({
  isOpen,
  setIsOpen,
  setFilter,
  filter,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  filter: Filter;
  setFilter: (value: Filter) => void;
}) {
  return (
    <Drawer open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
        <DrawerHeader className='relative'>
          <DrawerTitle className='text-center text-lg font-semibold'>
            필터
          </DrawerTitle>
          <DrawerClose asChild>
            <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
              <X className='h-7 w-7' />
            </Button>
          </DrawerClose>
        </DrawerHeader>
        <div className='p-5'>
          <div className='pb-5 text-[#222] text-[15px] font-semibold leading-[15px] tracking-[-0.3px]'>
            납부 상태
          </div>

          <div className='min-h-[150px]'>
            <div className='grid grid-cols-3 gap-1.5'>
              {Object.keys(FilterType).map((type) => {
                return (
                  <div
                    key={type}
                    onClick={() => {
                      setFilter({
                        ...filter,
                        type: type as FilterType[number],
                      });
                    }}
                    className={cn(
                      'cursor-pointer border border-[#E5E5EC] rounded-[8px] py-3 px-4 flex items-center justify-center',
                      filter.type === type && 'border-[#7273F9]',
                    )}
                  >
                    {FilterType[type as FilterType[number]]}
                  </div>
                );
              })}
            </div>
          </div>

          <div className='mt-6 flex gap-2.5 bg-[#F7F7FB]'>
            <Button
              variant='outline'
              className='bg-white w-[115px]'
              onClick={() => {
                setFilter({ ...filter, type: 'all' });
                setIsOpen(false);
              }}
            >
              초기화
            </Button>
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
            >
              확인
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}

const CommissionStatus = ({ status }: { status: OrderCommissionState }) => {
  if (status === 'paid')
    return <span className='font-[600] text-[#4860FF]'>납부완료</span>;
  if (status === 'sended')
    return <span className='font-[600] text-[#FF4747]'>미납부</span>;
  if (status === 'canceled')
    return <span className='font-[600] text-[#FF4747]'>취소</span>;
};

const FilterType = {
  all: '전체',
  paid: '납부완료',
  sended: '미납부',
} as const;
type FilterType = [keyof typeof FilterType];

type Filter = {
  type: FilterType[number];
  /** desc: 최신순 / asc: 오래된순 */
  orderby: 'desc' | 'asc';
};

export default function MypageCommission() {
  const navigate = useNavigate();
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();

  const { setOnResume } = useOnResumeStore();

  const [openDrawer, setOpenDrawer] = useState(false);

  const [filter, setFilter] = useState<Filter>({
    type: 'all',
    orderby: 'desc',
  });

  const useCommissionList = () => {
    return useQuery({
      queryKey: ['dealerCommissionList'],
      queryFn: async () => {
        const data: OrderCommissionListData[] =
          await getManagerCommissionList();
        return data;
      },
      gcTime: 5 * 60 * 1000, // 5 min
    });
  };
  const { data: commissionList, isPending, refetch } = useCommissionList();

  const filteredCommissionList = useMemo(() => {
    if (!commissionList) return [];

    const filteredList = commissionList.filter((el) => {
      if (filter.type === 'all') return true;
      if (filter.type === 'paid') return el.state === 'paid';
      if (filter.type === 'sended') return el.state === 'sended';
      return false;
    });

    const sortedList =
      filter.orderby === 'desc' ? filteredList : filteredList.reverse();

    return sortedList;
  }, [commissionList, filter]);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '수수료 정산',
      rightElement: undefined,
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
  }, []);

  useEffect(() => {
    // 푸시나 앱 새로고침 시 데이터 캐시 전체 무효화
    setOnResume(async () => {
      refetch();
    });
  }, []);

  return (
    <div className='relative w-full h-full bg-[#F3F6F9]'>
      {isPending && (
        <div className='flex h-full justify-center items-center'>
          <CircularProgress></CircularProgress>
        </div>
      )}

      {commissionList && commissionList.length === 0 && (
        <div className='flex h-full justify-center items-center'>
          <NoData text='정산내역이 없어요' />
        </div>
      )}

      {commissionList && commissionList.length > 0 && (
        <div className='py-[30px] px-5 space-y-5 bg-[#F3F6F9]'>
          <div className='text-[#222] text-[20px] font-semibold leading-[32px] tracking-[-0.4px] mb-1'>
            총 {commissionList.length}건의 정산내역이 있어요.
          </div>

          {/* filter 영역 */}
          <div className='bg-[#F3F6F9] flex justify-between items-center gap-1 z-10'>
            <div
              onClick={() => setOpenDrawer(!openDrawer)}
              className='cursor-pointer flex justify-center items-center bg-white rounded-full py-2.5 px-3.5 gap-[5px]'
            >
              <div className='text-[#222] text-[14px] font-semibold leading-[14px] tracking-[-0.28px]'>
                {FilterType[filter.type]}
              </div>
              <motion.div
                animate={{ rotate: openDrawer ? 180 : 0 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
              >
                <ChevronDown size={18} />
              </motion.div>
            </div>
            <div
              onClick={() => {
                setFilter({
                  ...filter,
                  orderby: filter.orderby === 'desc' ? 'asc' : 'desc',
                });
              }}
              className='cursor-pointer text-[#222] text-sm font-medium leading-none tracking-[-0.28px] flex items-center justify-center gap-1 rounded-[8px]'
            >
              최신일자
              <motion.div
                animate={{ rotate: filter.orderby === 'desc' ? 0 : 180 }}
                transition={{ duration: 0.3, ease: 'easeInOut' }}
              >
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='16'
                  height='16'
                  viewBox='0 0 16 16'
                  fill='none'
                >
                  <path
                    d='M8 3.33301L8 12.6663'
                    stroke='#7273F9'
                    strokeWidth='1.33333'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M12 8.66699L8 12.667'
                    stroke='#7273F9'
                    strokeWidth='1.33333'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M4 8.66699L8 12.667'
                    stroke='#7273F9'
                    strokeWidth='1.33333'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </svg>
              </motion.div>
            </div>
          </div>

          {/* 검색 내용 없을 때 */}
          {filteredCommissionList.length === 0 && (
            <div className='flex h-full py-10 justify-center items-center'>
              <NoData text='검색 결과가 없어요' />
            </div>
          )}

          {/* 데이터 영역 */}
          {filteredCommissionList.map((el) => {
            return (
              <div key={el.id} className='bg-white rounded-[16px] py-6 px-5'>
                <div className='overflow-hidden text-[#555] text-ellipsis text-[14px] font-medium leading-[22.4px] tracking-[-0.28px]'>
                  {el.order.type === 'lease'
                    ? '리스'
                    : el.order.type === 'rent'
                      ? '장기렌트'
                      : el.order.type === 'newCar'
                        ? '신차'
                        : '내차팔기'}
                </div>
                <div className='overflow-hidden text-[#222] text-ellipsis whitespace-nowrap text-[15px] font-semibold leading-[24px] tracking-[-0.3px]'>
                  {el.order.title}
                </div>

                {/* Divider */}
                <div className='w-full h-[1px] bg-[#EEEEEE] mt-3 mb-5'></div>

                {/* 정보 입력 */}
                <div className='mb-6'>
                  <ul className='text-[15px] leading-[15px] font-[400] flex flex-col justify-start items-start gap-[14px]'>
                    <li className='flex justify-start items-center'>
                      <span className='min-w-[75px]'>고지일자</span>
                      <span className='font-[500]'>
                        {dayjs(el.asked_at).format('YYYY.MM.DD')}
                      </span>
                    </li>

                    {el.paid_at && (
                      <li className='flex justify-start items-center'>
                        <span className='min-w-[75px]'>납부확인</span>
                        <span className='font-[500]'>
                          {dayjs(el.paid_at).format('YYYY.MM.DD')}
                        </span>
                      </li>
                    )}

                    {el.state === 'sended' && (
                      <>
                        <li className='flex justify-start items-start'>
                          <span className='min-w-[75px]'>입금계좌</span>
                          <span className='font-[500]'>
                            {
                              BankCodeData.find((el2) => {
                                return el2.id === el.bankCode;
                              })?.name
                            }{' '}
                            <span
                              className='underline'
                              onClick={() => {
                                navigator.clipboard
                                  .writeText(el.accountNumber ?? '')
                                  .then(() => {
                                    CarsayoToast.success(
                                      '클립보드에 복사되었습니다.',
                                    );
                                  })
                                  .catch((err) => {
                                    console.error('Failed to copy: ', err);
                                  });
                              }}
                            >
                              {el.accountNumber}
                            </span>
                          </span>
                        </li>
                        <li className='flex justify-start items-center'>
                          <span className='min-w-[75px]'>예금주명</span>
                          <span className='font-[500]'>{el.ownerName}</span>
                        </li>
                      </>
                    )}

                    {el.price && (
                      <li className='flex justify-start items-center'>
                        <span className='min-w-[75px]'>납부금액</span>
                        <span className='font-[500]'>
                          {el.price.toLocaleString()}원
                        </span>
                      </li>
                    )}

                    <li className='flex justify-start items-center'>
                      <span className='min-w-[75px]'>납부상태</span>
                      <CommissionStatus status={el.state} />
                    </li>
                  </ul>
                </div>

                <V3_Button
                  theme='gray'
                  onClick={() => {
                    navigate(`/dealer/order/detail/${el.order.id}`);
                  }}
                >
                  견적 상세보기
                </V3_Button>
              </div>
            );
          })}
        </div>
      )}

      <SelectCatrgory
        filter={filter}
        setFilter={setFilter}
        isOpen={openDrawer}
        setIsOpen={setOpenDrawer}
      />
    </div>
  );
}
