/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { ArrowUp, Check, ChevronLeft } from 'lucide-react';
import React, {
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { addHistoryInsuranceCooconClick } from 'apis/history';
import { useQuery } from '@tanstack/react-query';
import { getInsuranceProductsV1 } from 'apis/data';
import { CircularProgress } from '@mui/material';
import { cn } from 'utils';
import InsuranceCard from './component/card';
import { cooconUrl } from 'utils/cooconUrl';
import { InsuranceTypeTab } from './data/product';

/** 꼭 확인하세요 영역 텍스트 */
const NoticeItems: {
  key: string;
  content: React.ReactNode;
  tab: string[];
}[] = [
  {
    key: '01',
    content: (
      <>
        <span className='font-[700]'>
          법인 및 영업용 차량은 보험료 한 번에 비교하기 서비스가 제공되지
          않습니다.
        </span>
      </>
    ),
    tab: ['personal', 'business'],
  },
  {
    key: '02',
    content: (
      <>
        <span className='font-[700]'>보험료 한 번에 비교하기</span>는{' '}
        <span className='font-[700] text-[#FF257C]'>온라인&전화 가입</span>
        보다 보험료가 조금 높습니다.
      </>
    ),
    tab: ['personal', 'business'],
  },
  {
    key: '03',
    content: (
      <>
        <span className='font-[700]'>보험료 한 번에 비교하기</span>
        에서 비교만 하시고{' '}
        <span className='font-[700] text-[#FF257C]'>온라인&전화 가입</span>의
        보험사를 직접 선택하시어 국내 최저가 다이렉트 자동차 보험을 계약하세요.
      </>
    ),
    tab: ['personal', 'business'],
  },
];

const NoInsu = () => {
  return (
    <div className='flex flex-col items-center justify-center gap-6 py-[70px]'>
      <div>
        <svg
          width='81'
          height='80'
          viewBox='0 0 81 80'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <circle cx='40.5' cy='40' r='40' fill='#E5EAEE' />
          <g clipPath='url(#clip0_3178_5049)'>
            <path
              d='M52.3906 24.9958C49.0096 24.9958 45.9311 23.7856 43.5108 21.7909C42.2276 20.7337 40.3526 20.7337 39.0693 21.7909C36.6491 23.7856 33.5706 24.9958 30.1895 24.9958C30.0067 24.9958 29.8239 24.9924 29.641 24.9858C27.0944 24.8827 25 26.9838 25 29.5337V37.1668C25 50.7174 35.1896 56.1364 39.4516 57.8119C40.6351 58.2774 41.9483 58.2774 43.1318 57.8119C47.3938 56.143 57.5801 50.7407 57.5801 37.1668V29.5337C57.5801 26.9838 55.4857 24.8827 52.9391 24.9858C52.7563 24.9924 52.5734 24.9958 52.3906 24.9958Z'
              fill='#B2B7BB'
            />
            <path
              d='M36.8711 39.5806L40.355 43.0644L47.3227 36.0967'
              stroke='white'
              strokeWidth='3.48387'
              strokeLinecap='round'
              strokeLinejoin='round'
            />
          </g>
          <defs>
            <clipPath id='clip0_3178_5049'>
              <rect
                width='32.5801'
                height='37.1613'
                fill='white'
                transform='translate(25 21)'
              />
            </clipPath>
          </defs>
        </svg>
      </div>
      <div className='text-[#A1A7AC] text-center text-[18px] font-semibold leading-[18px] tracking-[-0.36px]'>
        보험 리스트가 없어요
      </div>
    </div>
  );
};

export default function InsuranceMain() {
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const initTab = searchParams.get('tab');

  /** STEP2 탭 선택 영역 */
  const [insuranceTypeTab, setInsuranceTypeTab] = useState<InsuranceTypeTab>(
    initTab === 'business' ? 'business' : 'personal',
  );

  /** STEP1 꼭 확인하세요 영역 텍스트 영역
   * @note 선택 탭에 따라 순서가 바뀝니다.
   */
  const sortedNoticeItems = useMemo(() => {
    if (insuranceTypeTab === 'business') return NoticeItems;
    else return [...NoticeItems.slice(1), NoticeItems[0]];
  }, [insuranceTypeTab]);

  const { data: InsuranceCompanyDataV1, isFetching: isFetchingV1 } = useQuery({
    queryKey: ['InsuranceCompanyDataV1'],
    queryFn: () => {
      return getInsuranceProductsV1();
    },
    gcTime: 5 * 60 * 1000,
    staleTime: 5 * 60 * 1000,
  });

  // 카사요 앱 외부에서 접근 시 : ex. insurance
  const externalAccessKey = searchParams.get('type');
  const { isBottomTabbar, setTopTabbarState, setIsBottomTabbar } =
    useSystemStore();

  const outerDivRef = useRef<HTMLDivElement>(null);
  const scrollTopRef = useRef<SVGSVGElement>(null);

  const [activeTab, setActiveTab] = useState<'online' | 'tm'>('online');

  const handleClickEvents = {
    openCooconInsurance: () => {
      addHistoryInsuranceCooconClick({
        from: `app${location.pathname}`,
        target: '쿠콘',
      });

      window.open(cooconUrl());
    },
  };

  useEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: (
        <ChevronLeft
          strokeWidth={1.5}
          onClick={() => {
            navigate(-1);
          }}
          className='h-7 w-7 cursor-pointer'
        ></ChevronLeft>
      ),
      title: '자동차 보험',
    });
  }, []);

  useEffect(() => {
    if (!outerDivRef.current) return;

    function handleScrollTopButton() {
      if (!outerDivRef.current || !scrollTopRef.current) return;
      if (outerDivRef.current.scrollTop > 2500) {
        if (scrollTopRef.current.style.display !== 'block')
          scrollTopRef.current.style.display = 'block';
      } else {
        if (scrollTopRef.current.style.display !== 'none') {
          scrollTopRef.current.style.display = 'none';
        }
      }
    }

    outerDivRef.current.addEventListener('scroll', handleScrollTopButton);
    return () => {
      if (!outerDivRef.current) return;
      outerDivRef.current.removeEventListener(
        'scrollend',
        handleScrollTopButton,
      );
    };
  }, []);

  useLayoutEffect(() => {
    setIsBottomTabbar(false);

    if (isBottomTabbar === true) {
      return () => {
        setIsBottomTabbar(true);
      };
    }
  }, []);

  return (
    <div
      ref={outerDivRef}
      className='flex flex-col h-full overflow-y-scroll bg-[#F3F6F9]'
    >
      {/* 자동차 보험 비교하기 */}
      {externalAccessKey !== 'insurance' && (
        <section className=''>
          <div className='flex items-center gap-[12px] border-b border-[#E5E5EC] px-5 py-4'>
            <div className='rounded-[8px] bg-[#E8ECF0] px-[8px] py-[7px] text-sm font-medium leading-[14px]'>
              STEP 1
            </div>
            <div className='text-lg font-[500] leading-[25.2px]'>
              보험료 조회 및 비교
            </div>
          </div>

          <div className='m-5 flex flex-col gap-4 rounded-[10px] bg-[#FFE3F0] p-4'>
            <div className='flex items-center gap-2 text-[15px] font-[600]'>
              <img
                src='/assets/images/insurance/exc_icon.png'
                className='h-6 w-6'
                alt='보험사'
              />
              꼭 확인하세요
            </div>
            <div className='flex flex-col gap-2'>
              {sortedNoticeItems.map(({ key, tab, content }) => (
                <div
                  key={key}
                  className={cn(
                    'flex items-start gap-3 text-[13px] font-[500] leading-[150%]',
                    !tab.includes(insuranceTypeTab) && 'hidden',
                  )}
                >
                  <div className='mt-[1px] h-4 w-4'>
                    <Check
                      width={16}
                      height={16}
                      strokeWidth={1.33}
                      color='#FF257C'
                    />
                  </div>
                  <div className='whitespace-pre-line'>{content}</div>
                </div>
              ))}
            </div>
          </div>

          <div className='relative m-5 flex flex-col max-h-[335px] rounded-[16px] bg-white p-5 tiny:p-4'>
            <div className='w-full flex justify-end wide:absolute wide:top-5 wide:right-5 mb-4'>
              <img
                className='w-[128px] h-[32px] wide:w-[164px] wide:h-[44px]'
                src='/assets/images/insurance/direct_insurances_1.png'
              />
            </div>
            <div className='absolute bottom-5 right-5 tiny:right-4 tiny:bottom-4'>
              <button
                type='button'
                className='rounded-[100px] bg-[#222222] px-4 py-[10px] text-sm font-semibold leading-[21px] text-white tiny:text-[13px] tiny:py-2 tiny:px-3'
                onClick={handleClickEvents.openCooconInsurance}
              >
                비교하고 나오기
              </button>
            </div>

            <div className='flex flex-col gap-2.5 wide:gap-[36px]'>
              <div className='text-[#222] text-[18px] font-semibold leading-[27px] tracking-[-0.36px]'>
                <div>다이렉트 자동차보험 7개 보험사</div>
                <div className='flex items-center justify-start gap-1'>
                  <div className='text-[#0079FC]'>한 번에 비교하기</div>
                  <div>
                    <svg
                      xmlns='http://www.w3.org/2000/svg'
                      width='20'
                      height='20'
                      viewBox='0 0 20 20'
                      fill='none'
                    >
                      <g clipPath='url(#clip0_2539_13771)'>
                        <path
                          d='M6.66406 10.8333V3.75C6.66406 3.41848 6.79576 3.10054 7.03018 2.86612C7.2646 2.6317 7.58254 2.5 7.91406 2.5C8.24558 2.5 8.56353 2.6317 8.79795 2.86612C9.03237 3.10054 9.16406 3.41848 9.16406 3.75V10'
                          stroke='#0079FC'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M9.16406 9.58366V7.91699C9.16406 7.58547 9.29576 7.26753 9.53018 7.03311C9.7646 6.79869 10.0825 6.66699 10.4141 6.66699C10.7456 6.66699 11.0635 6.79869 11.2979 7.03311C11.5324 7.26753 11.6641 7.58547 11.6641 7.91699V10.0003'
                          stroke='#0079FC'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M11.6641 8.75C11.6641 8.41848 11.7958 8.10054 12.0302 7.86612C12.2646 7.6317 12.5825 7.5 12.9141 7.5C13.2456 7.5 13.5635 7.6317 13.7979 7.86612C14.0324 8.10054 14.1641 8.41848 14.1641 8.75V10'
                          stroke='#0079FC'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M14.1658 9.58301C14.1658 9.25149 14.2975 8.93354 14.532 8.69912C14.7664 8.4647 15.0843 8.33301 15.4158 8.33301C15.7474 8.33301 16.0653 8.4647 16.2997 8.69912C16.5341 8.93354 16.6658 9.25149 16.6658 9.58301V13.333C16.6658 14.6591 16.1391 15.9309 15.2014 16.8685C14.2637 17.8062 12.9919 18.333 11.6658 18.333H9.99918H10.1725C9.34446 18.3331 8.52933 18.1276 7.80033 17.7349C7.07133 17.3422 6.45127 16.7746 5.99584 16.083L5.83251 15.833C5.57251 15.4338 4.66001 13.843 3.09418 11.0597C2.93455 10.776 2.89191 10.4411 2.97533 10.1265C3.05874 9.81179 3.26163 9.54207 3.54084 9.37467C3.83824 9.19623 4.18674 9.12227 4.53098 9.16455C4.87522 9.20682 5.19546 9.36291 5.44084 9.60801L6.66584 10.833'
                          stroke='#0079FC'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M4.16927 2.50033L3.33594 1.66699'
                          stroke='#0079FC'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M3.33333 5.83301H2.5'
                          stroke='#0079FC'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M11.6641 2.50033L12.4974 1.66699'
                          stroke='#0079FC'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                        <path
                          d='M12.5 5H13.3333'
                          stroke='#0079FC'
                          strokeWidth='1.66667'
                          strokeLinecap='round'
                          strokeLinejoin='round'
                        />
                      </g>
                      <defs>
                        <clipPath id='clip0_2539_13771'>
                          <rect width='20' height='20' fill='white' />
                        </clipPath>
                      </defs>
                    </svg>
                  </div>
                </div>
              </div>

              <div className='flex flex-col justify-between gap-[10px]'>
                <div className='flex flex-wrap items-center justify-between'>
                  <div className='text-xs font-[400] leading-[16.5px] text-[#555] tiny:text-[11px]'>
                    손해보험협회 심의필 제129622호
                    <br />
                    (2024.08.01 ~ 2025.07.31)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* 다이렉트 자동차보험 가입 */}
      {isFetchingV1 || !InsuranceCompanyDataV1 ? (
        <div className='flex-auto flex items-center justify-center'>
          <CircularProgress />
        </div>
      ) : (
        <>
          <section className='flex items-center flex-col pt-[10px]'>
            <div className='w-full bg-white'>
              <div className='flex items-center gap-[12px] border-b border-[#E5E5EC] px-5 py-4'>
                <div className='rounded-[8px] bg-[#FFF0F7] px-[8px] py-[7px] text-sm font-[500] leading-[14px] text-[#FF257C]'>
                  STEP 2
                </div>
                <div className='relative text-[18px] font-[700] leading-[140%]'>
                  <div className='relative z-[2] flex items-center gap-[6px] text-[#FF257C]'>
                    {activeTab === 'online' ? '직접' : '전화'} 가입하기
                    <span className='text-[14px]'>(국내 최저 보험료)</span>
                  </div>
                </div>
              </div>

              <div className='grid grid-cols-2 border-b border-[#E5E5EC]'>
                <div className='relative'>
                  <div
                    onClick={() => setInsuranceTypeTab('personal')}
                    className={cn(
                      'relative h-[50px] flex items-center justify-center cursor-pointer',
                      insuranceTypeTab === 'personal'
                        ? 'text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.32px]'
                        : 'text-[#999] text-center text-[16px] font-medium leading-[16px] tracking-[-0.32px]',
                    )}
                  >
                    개인용
                  </div>
                  <div
                    className={cn(
                      'absolute bottom-0 w-full h-0.5 bg-black',
                      insuranceTypeTab !== 'personal' && 'hidden',
                    )}
                  ></div>
                </div>
                <div className='relative'>
                  <div
                    onClick={() => setInsuranceTypeTab('business')}
                    className={cn(
                      'relative h-[50px] flex items-center justify-center cursor-pointer',
                      insuranceTypeTab === 'business'
                        ? 'text-[#222] text-center text-[16px] font-medium leading-[16px] tracking-[-0.32px]'
                        : 'text-[#999] text-center text-[16px] font-medium leading-[16px] tracking-[-0.32px]',
                    )}
                  >
                    법인(영업)용
                  </div>
                  <div
                    className={cn(
                      'absolute bottom-0 w-full h-0.5 bg-black',
                      insuranceTypeTab !== 'business' && 'hidden',
                    )}
                  ></div>
                </div>
              </div>
              <div className='flex items-center justify-between px-5 py-3'>
                <div className='text-[16px] font-[500] leading-[24px] text-[#222222]'>
                  다이렉트 자동차보험
                </div>

                <div className='flex rounded-[100px] bg-[#EDF1F4] p-[4px] text-[13px] font-[600] leading-[13px] text-[#999999]'>
                  <div
                    onClick={() => setActiveTab('online')}
                    className={cn(
                      'max-h-[32px] cursor-pointer rounded-[100px] p-[10px]',
                      activeTab === 'online' && 'bg-white text-[#222222]',
                    )}
                  >
                    직접 가입
                  </div>

                  <div
                    onClick={() => setActiveTab('tm')}
                    className={cn(
                      'max-h-[32px] cursor-pointer rounded-[100px] p-[10px]',
                      activeTab === 'tm' && 'bg-white text-[#222222]',
                    )}
                  >
                    전화 가입
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* 보험사별 혜택 영역 */}
          {InsuranceCompanyDataV1 && insuranceTypeTab === 'personal' && (
            <section className='px-5 grid grid-cols-1 wide:grid-cols-2 wide:gap-x-[9px] gap-5 pb-[30px] bg-white'>
              {InsuranceCompanyDataV1.default
                .filter((el) => {
                  if (activeTab === 'online') {
                    return el.type === 'online';
                  } else {
                    return el.type === 'tm';
                  }
                })
                .map((el, index) => {
                  return (
                    <InsuranceCard
                      key={el.id}
                      insuranceCompany={el}
                      option={{
                        isPhone: el.type === 'tm',
                      }}
                    />
                  );
                })}
            </section>
          )}

          {InsuranceCompanyDataV1 && insuranceTypeTab === 'business' && (
            <>
              <div className='bg-[#E8F8FF] px-5 p-4 flex items-center gap-3 justify-start'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='18'
                  height='18'
                  viewBox='0 0 18 18'
                  fill='none'
                >
                  <g clipPath='url(#clip0_3148_49209)'>
                    <path
                      d='M12.7899 8.79585V2.17355C12.7899 1.34215 12.1147 0.666992 11.2833 0.666992H2.17257C1.34117 0.666992 0.666016 1.34215 0.666016 2.17355V15.8271C0.666016 16.6585 1.34117 17.3337 2.17257 17.3337H15.8261C16.6575 17.3337 17.3327 16.6585 17.3327 15.8271V10.9698C17.3327 10.1384 16.6575 9.46329 15.8261 9.46329H13.4573C13.0889 9.46329 12.7899 9.16429 12.7899 8.79585Z'
                      fill='#61B3FF'
                    />
                    <path
                      d='M9.05408 6.25616H4.97037C4.43796 6.25616 4.00586 5.82406 4.00586 5.29165C4.00586 4.75925 4.43796 4.32715 4.97037 4.32715H9.05408C9.58649 4.32715 10.0186 4.75925 10.0186 5.29165C10.0186 5.82406 9.58649 6.25616 9.05408 6.25616Z'
                      fill='#EBF9FF'
                    />
                    <path
                      d='M9.66413 17.3332H4.35742V11.3262C4.35742 9.86017 5.54569 8.67383 7.00981 8.67383C8.47586 8.67383 9.66221 9.8621 9.66221 11.3262V17.3332H9.66413Z'
                      fill='#EBF9FF'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_3148_49209'>
                      <rect
                        width='16.6667'
                        height='16.6667'
                        fill='white'
                        transform='translate(0.666016 0.666992)'
                      />
                    </clipPath>
                  </defs>
                </svg>
                <span className='text-[#2797FF] text-[16px] font-semibold leading-[20px] tracking-[-0.5px]'>
                  법인
                </span>
              </div>
              <div className='w-full bg-white py-2.5'></div>
              <section className='px-5 grid grid-cols-1 wide:grid-cols-2 wide:gap-x-[9px] gap-5 pb-[30px] bg-white'>
                {InsuranceCompanyDataV1.corporation
                  .filter((el) => {
                    // 온라인 가입 시 모바일 가입 링크가 있는 경우만 표시
                    if (activeTab === 'online') {
                      return (
                        el.type === 'online' && el.direct.signup_url_mobile
                      );
                    } else {
                      return el.type === 'tm';
                    }
                  })
                  .map((el, index) => {
                    return (
                      <InsuranceCard
                        key={el.id}
                        insuranceCompany={el}
                        option={{
                          isPhone: el.type === 'tm',
                        }}
                      />
                    );
                  })}
                {InsuranceCompanyDataV1.corporation.filter((el) => {
                  // 온라인 가입 시 모바일 가입 링크가 있는 경우만 표시
                  if (activeTab === 'online') {
                    return el.type === 'online' && el.direct.signup_url_mobile;
                  } else {
                    return el.type === 'tm';
                  }
                }).length === 0 && <NoInsu />}
              </section>

              <div className='bg-[#EDF2FD] px-5 p-4 flex items-center gap-3 justify-start'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='20'
                  height='20'
                  viewBox='0 0 20 20'
                  fill='none'
                >
                  <g clipPath='url(#clip0_3148_49216)'>
                    <path
                      d='M13.8842 15.3048H7.37305V3.57937C7.37305 2.98413 7.85559 2.5 8.45241 2.5H18.9207C19.5159 2.5 20 2.98254 20 3.57937V14.2254C20 14.8206 19.5175 15.3048 18.9207 15.3048H17.9572'
                      fill='#9FB1FF'
                    />
                    <path
                      d='M1.42857 15.3047C0.639683 15.3047 0 14.665 0 13.8762V9.26822C0 8.77774 0.295238 7.95552 0.866667 7.10473C1.91746 5.54124 3.84603 4.63965 4.50794 4.63965H7.37302V15.3047H1.42857Z'
                      fill='#6270F4'
                    />
                    <path
                      d='M1.58594 9.86344V9.33486C1.58594 9.27455 1.59387 9.2174 1.60816 9.16026C1.66054 8.96344 1.81451 8.53804 2.18276 7.99201C2.83356 7.02217 3.98911 6.41264 4.45578 6.25867C4.52086 6.23804 4.58752 6.22852 4.65578 6.22852H5.10498C5.48118 6.22852 5.78435 6.53328 5.78435 6.90788V9.86344C5.78435 10.2396 5.47959 10.5428 5.10498 10.5428H2.2653C1.88911 10.5428 1.58594 10.238 1.58594 9.86344Z'
                      fill='white'
                    />
                    <path
                      d='M6.29177 15.3041C6.29177 16.4295 5.38065 17.3406 4.25526 17.3406C3.12986 17.3406 2.21875 16.4295 2.21875 15.3041C2.21875 14.1787 3.12986 13.2676 4.25526 13.2676C5.38065 13.2676 6.29177 14.1787 6.29177 15.3041Z'
                      fill='white'
                    />
                    <path
                      d='M4.92562 15.3036C4.92562 15.6735 4.62562 15.9735 4.25578 15.9735C3.88594 15.9735 3.58594 15.6735 3.58594 15.3036C3.58594 14.9338 3.88594 14.6338 4.25578 14.6338C4.62562 14.6338 4.92562 14.9338 4.92562 15.3036Z'
                      fill='#D0DEF7'
                    />
                    <path
                      d='M18.0711 15.3041C18.0711 16.4295 17.16 17.3406 16.0346 17.3406C14.9092 17.3406 13.998 16.4295 13.998 15.3041C13.998 14.1787 14.9092 13.2676 16.0346 13.2676C17.16 13.2676 18.0711 14.1787 18.0711 15.3041Z'
                      fill='white'
                    />
                    <path
                      d='M16.7049 15.3036C16.7049 15.6735 16.4049 15.9735 16.0351 15.9735C15.6652 15.9735 15.3652 15.6735 15.3652 15.3036C15.3652 14.9338 15.6652 14.6338 16.0351 14.6338C16.4049 14.6338 16.7049 14.9338 16.7049 15.3036Z'
                      fill='#D0DEF7'
                    />
                    <path
                      d='M17.6988 11.8929H9.85758C9.50678 11.8929 9.22266 11.6088 9.22266 11.258C9.22266 10.9072 9.50678 10.623 9.85758 10.623H17.6988C18.0496 10.623 18.3338 10.9072 18.3338 11.258C18.3338 11.6088 18.0496 11.8929 17.6988 11.8929Z'
                      fill='#D9E3FF'
                    />
                    <path
                      d='M11.7734 9.5384H9.85758C9.50678 9.5384 9.22266 9.25427 9.22266 8.90348C9.22266 8.55268 9.50678 8.26855 9.85758 8.26855H11.7734C12.1242 8.26855 12.4084 8.55268 12.4084 8.90348C12.4084 9.25427 12.1242 9.5384 11.7734 9.5384Z'
                      fill='#D9E3FF'
                    />
                  </g>
                  <defs>
                    <clipPath id='clip0_3148_49216'>
                      <rect width='20' height='20' fill='white' />
                    </clipPath>
                  </defs>
                </svg>
                <span className='text-[#494AF1] text-[16px] font-semibold leading-[20px] tracking-[-0.5px]'>
                  영업
                </span>
              </div>
              <div className='w-full bg-white py-2.5'></div>
              <section className='px-5 grid grid-cols-1 wide:grid-cols-2 wide:gap-x-[9px] gap-5 pb-[30px] bg-white'>
                {InsuranceCompanyDataV1.business
                  .filter((el) => {
                    if (activeTab === 'online') {
                      return el.type === 'online';
                    } else {
                      return el.type === 'tm';
                    }
                  })
                  .map((el, index) => {
                    return (
                      <InsuranceCard
                        key={el.id}
                        insuranceCompany={el}
                        option={{
                          isPhone: el.type === 'tm',
                        }}
                      />
                    );
                  })}
                {InsuranceCompanyDataV1.business.filter((el) => {
                  if (activeTab === 'online') {
                    return el.type === 'online';
                  } else {
                    return el.type === 'tm';
                  }
                }).length === 0 && <NoInsu />}
              </section>
            </>
          )}

          {/* 안내사항 영역 */}
          <div className='p-4 bg-white pb-10'>
            <section className='flex flex-col p-4 pb-[20px] bg-[#F8F9FA] tracking-[-0.025em] w-full rounded-[10px]'>
              <span className='text-[15px] font-[500] leading-[22.5px] tracking-[-0.02em] pb-4 mb-5 border-b border-[#F3F6F9]'>
                보험계약 시 알아두실 사항 확인해 주세요
              </span>
              <ul className='list-decimal pl-4 text-[#555555] break-all font-[400] text-[13px] leading-[19.5px]'>
                <li className='pl-0.5 mb-3'>
                  차 보험 광고(차 보험 가입하기)는 카사요(주)가 제휴 보험회사의
                  다이렉트 자동차 보험상품을 광고하는 서비스입니다. 본 광고
                  서비스를 통하여 카사요(주)는 상품 판매 및 중개에 관여하지
                  않습니다.
                </li>
                <li className='pl-0.5 mb-3'>
                  해당 보험사는 관련 상품에 대해 충분히 설명할 의무가 있으며,
                  가입자는 가입에 앞서 이에 대한 충분한 설명을 받으시길
                  바랍니다.
                </li>
                <li className='pl-0.5 mb-3'>
                  보험계약 체결 전 상품설명서 및 약관을 읽어보시길 바랍니다.
                </li>
                <li className='pl-0.5 mb-3'>
                  보험계약자가 기존에 체결했던 보험계약을 해지하고, 다른
                  보험계약을 체결할 때는 보험인수가 거절되거나 보험료가
                  인상되거나 보장 내용이 달라질 수 있습니다.
                </li>
                <li className='pl-0.5 mb-3'>
                  지급 한도, 면책 사항 등에 따라 보험금 지급이 제한될 수
                  있습니다.
                </li>
                <li className='pl-0.5 mb-3'>
                  이 보험계약은 예금자보호법에 따라 해약환급금(또는 만기 시
                  보험금)에 기타 지급금을 합한 금액이 1인당 “5천만 원까지”(본
                  보험회사의 여타 보험상품과 합산) 보호됩니다. 이와 별도로 본
                  보험회사 보호 상품의 사고 보험금을 합산한 금액이 1인당 “5천만
                  원까지”보호됩니다.
                </li>
                <li className='pl-0.5 mb-3'>
                  한 보험사의 차 보험 가입 광고로 받을 수 있는 혜택은 최대
                  30,000원입니다.
                </li>
                <li className='pl-0.5 mb-3'>
                  차 보험 가입 광고의 상세 내용은 각 보험사 페이지에서 확인할 수
                  있습니다.
                </li>
                <li className='pl-0.5 mb-3'>
                  차 보험 가입 이후 포인트 지급 관련 문의는 각 보험사에 문의해
                  주세요.
                </li>
                <li className='pl-0.5 mb-3'>
                  본 광고는 광고 심의 기준을 준수하였으며, 유효기간은 각 보험사
                  심의일로부터 1년입니다.
                </li>
              </ul>
            </section>
          </div>
        </>
      )}

      <ArrowUp
        ref={scrollTopRef}
        onClick={() => {
          outerDivRef.current?.scroll({
            top: 0,
            behavior: 'smooth',
          });
        }}
        style={{
          display: 'none',
        }}
        className='p-2.5 w-10 h-10 active:bg-[#00000080] rounded-full fixed right-5 bottom-5 bg-[#00000080] text-white'
      >
        맨위로가기
      </ArrowUp>
    </div>
  );
}
