import { SearchedUsedCarInfo } from '@carsayo/types';
import { cn } from 'utils';
import { InputValue } from '../../../util/types';

const OrderSelling_Rank = ({
  searchMoreData,
  inputValue,
  setInputValue,
}: {
  searchMoreData: SearchedUsedCarInfo | null;
  inputValue: InputValue;
  setInputValue: (value: InputValue) => void;
}) => {
  return (
    <>
      {searchMoreData?.searchDetailInfo?.modelList.map((model, index) => {
        return (
          <div
            key={`model_${index}`}
            className='bg-[#F3F6F9] rounded-[16px] px-4 pt-5 pb-4'
          >
            <div className='flex gap-[10px] justify-between mb-4 items-center'>
              <div className='font-[600] text-[16px] leading-[150%] flex flex-1'>
                {model.modelname}
              </div>
              <div className='w-[110px] h-[66px]'>
                <img
                  src={'/assets/images/v2/car/empty_car.png'}
                  alt='car_img'
                  width={110}
                  height={66}
                  className='object-cover'
                />
              </div>
            </div>

            <div className='flex flex-col gap-[10px]'>
              {model.serieslist.map((series, idx) => {
                return (
                  <div
                    key={`series_${idx}`}
                    className={cn(
                      'rounded-[8px] p-4 flex flex-col gap-[10px] bg-white border border-[#E5E5EC]',
                      series.seriesno === inputValue.selectedSeries &&
                        'bg-[#F6F6FC] border-[2px] border-[#7273F9]',
                    )}
                    onClick={() => {
                      setInputValue({
                        ...inputValue,
                        selectedSeries: series.seriesno,
                      });
                    }}
                  >
                    <div className='font-[500] text-[16px] leading-[150%]'>
                      {series.seriesname}
                    </div>
                    <div className='font-[600] text-[14px] leading-[100%]'>
                      {Number(series.seriesprice).toLocaleString()}원
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        );
      })}
    </>
  );
};

export default OrderSelling_Rank;
