import { PurchaseOrderDetailInfo } from '@carsayo/types';
import FilePreviewer from 'components/common/v3/fileInput/FilePreviewer';
import { useState } from 'react';
import { cn } from 'utils';

const RequestTab = ({
  purchaseOrderDetailInfo,
}: {
  purchaseOrderDetailInfo: PurchaseOrderDetailInfo;
}) => {
  const [isPreviewVisible, setIsPreviewVisible] = useState(false);

  return (
    <div className='flex-auto bg-[#F3F6F9]'>
      <div className='bg-[#F3F6F9] px-5 pt-[14px] pb-[50px] space-y-2.5'>
        {/* 타사 제공 내용 */}
        <div className='bg-white rounded-[16px] divide-y divide-[#E5E5EC]'>
          {/* 타사 혜택 내용 */}
          <div className='px-4 py-[14px] flex flex-col gap-3'>
            <div className='w-[100px] flex items-center text-[#555] text-[14px] font-normal leading-[14px] tracking-[-0.28px]'>
              타사 혜택 내용
            </div>
            <div
              className={cn(
                'whitespace-pre-line break-keep text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]',
                !purchaseOrderDetailInfo.otherCompanyEstimateContent
                  ? 'text-[#A1A7AC]'
                  : 'text-[#222]',
              )}
            >
              {purchaseOrderDetailInfo.otherCompanyEstimateContent ||
                '등록된 타사 혜택 내용이 없습니다.'}
            </div>
          </div>

          {/* 타사 견적서 파일 내용
            @note 슈퍼카일 경우 구매 계약서로 라벨 변경 
            */}
          <div className='px-4 py-[14px] flex flex-col gap-3'>
            <div className='w-[100px] flex items-center text-[#555] text-[14px] font-normal leading-[14px] tracking-[-0.28px]'>
              {purchaseOrderDetailInfo.isSuperCar
                ? '구매 계약서'
                : '타사 견적서'}
            </div>

            {purchaseOrderDetailInfo.otherCompanyEstimateFile ? (
              <>
                <button
                  onClick={() => {
                    setIsPreviewVisible(true);
                  }}
                  className='bg-[#F3F6F9] rounded-[8px] px-[14px] py-[18px] h-[50px] flex items-center justify-between'
                >
                  <div className='underline text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px] truncate'>
                    {purchaseOrderDetailInfo.otherCompanyEstimateFile.name}.
                    {purchaseOrderDetailInfo.otherCompanyEstimateFile.extension}
                  </div>
                </button>

                <FilePreviewer
                  isOpen={isPreviewVisible}
                  setIsOpen={setIsPreviewVisible}
                  fileInfo={purchaseOrderDetailInfo.otherCompanyEstimateFile}
                />
              </>
            ) : (
              <div className='whitespace-pre-line break-keep text-[15px] font-medium leading-[22.5px] tracking-[-0.3px] text-[#A1A7AC]'>
                첨부된 타사 견적서가 없습니다.
              </div>
            )}
          </div>
        </div>

        {/* 요청 내용 */}
        {/* { && ( */}
        <div className='bg-white rounded-[16px] divide-y divide-[#E5E5EC]'>
          <div className='px-4 py-[14px] flex flex-col gap-3'>
            <div className='w-[100px] flex items-center text-[#555] text-[14px] font-normal leading-[14px] tracking-[-0.28px]'>
              요청 내용
            </div>
            <div
              className={cn(
                'whitespace-pre-line break-keep text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]',
                purchaseOrderDetailInfo.additionalRequest
                  ? 'text-[#222]'
                  : 'text-[#A1A7AC]',
              )}
            >
              {purchaseOrderDetailInfo.additionalRequest ||
                '등록된 요청 내역이 없습니다.'}
            </div>
          </div>
        </div>
        {/* )} */}
      </div>
    </div>
  );
};

export default RequestTab;
