import { api } from 'apis';

export type TossPaymentConfirmDTO = {
  orderId: string;
  paymentKey: string;
  amount: number;
  /** 신차 이용권 구매 시 1000100, 내차 팔기 이용권 구매 시 1000200 */
  purchaseItemId: 1000100 | 1000200;
};
export type TossPaymentConfirmResponse = {
  status: 'success';
  msg: '성공';
  data: TossPaymentData;
};
export type TossPaymentData = {
  id: string;
  status: TossPaymentStatus;
  method: TossPaymentMethod;
  payment_key: string;
  order_id: string;
  amount: number;
  currency: string;
  use_escrow: boolean;
  card: string | null;
  virtual_account: string | null;
  transfer: string | null;
  cancels: string | null;
  supplied_amount: number;
  vat: number;
  receipt: string | null;
  cash_receipt: string | null;
  cash_receipts: string | null;
  buyer_id: string;
  purchase_item_id: number;
  requested_at: Date | null;
  approved_at: Date | null;
  created_at: Date;
  updated_at: Date;
  purchase_item: {
    id: number;
    title: string;
    description: string;
    price: number;
    created_at: Date;
    updated_at: Date;
    image_url: string;
  };
};

export enum TossPaymentStatus {
  READY = 'READY', // 결제 생성 시 초기 상태, 인증 전까지는 이 상태 유지
  IN_PROGRESS = 'IN_PROGRESS', // 결제수단 인증 완료 상태, 결제 승인 API 호출 필요
  WAITING_FOR_DEPOSIT = 'WAITING_FOR_DEPOSIT', // 가상계좌 결제 대기 상태
  DONE = 'DONE', // 결제 완료 상태
  CANCELED = 'CANCELED', // 결제 취소 상태
  PARTIAL_CANCELED = 'PARTIAL_CANCELED', // 부분 취소 상태
  ABORTED = 'ABORTED', // 결제 승인 실패 상태
  EXPIRED = 'EXPIRED', // 결제 유효 시간 초과로 자동 취소된 상태
}

export enum TossPaymentMethod {
  CARD = 'CARD', // 카드 결제
  VIRTUAL_ACCOUNT = 'VIRTUAL_ACCOUNT', // 가상계좌 결제
  TRANSFER = 'TRANSFER', // 계좌이체 결제
}
export const TossPaymentMethodMap = {
  [TossPaymentMethod.CARD]: '신용카드',
  [TossPaymentMethod.VIRTUAL_ACCOUNT]: '가상계좌',
  [TossPaymentMethod.TRANSFER]: '계좌이체',
};

export const tosspaymentConfirm = async (
  reqData: TossPaymentConfirmDTO,
): Promise<TossPaymentConfirmResponse> => {
  try {
    const response = await api.post('tosspayment', reqData);
    const data: TossPaymentConfirmResponse = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};
