import { cn } from 'utils';

export default function PingCircle({ className }: { className?: string }) {
  return (
    <span className={cn('flex w-1 h-1', className)}>
      <span className='animate-ping bg-[#7273F9] absolute inline-flex w-full h-full rounded-full opacity-75'></span>
      <span className='bg-[#7273F9] relative inline-flex w-1 h-1 rounded-full'></span>
    </span>
  );
}
