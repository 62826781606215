import { useMutation, useQuery } from '@tanstack/react-query';
import { BLANKCARIMAGE } from '../constants';
import { OrderPurchaseDefaultProps } from '../interface';
import { getInterestCarList, selectInterestCar } from 'apis/car';
import { Heart } from 'lucide-react';
import combineCarName from '../utils/combineCarName';

const ModelInfo = (props: OrderPurchaseDefaultProps) => {
  const { data: myInterestCarList, refetch } = useQuery({
    queryKey: ['myInterestCarList'],
    queryFn: () => getInterestCarList(),
  });

  const targetBrand = props.wholeSellingCar.find((el) => {
    return el.id === props.form.getValues('carMakerId');
  });
  const targetModel = targetBrand?.car_model?.find((el) => {
    return el.id === props.form.getValues('carModelId');
  });
  const targetTrim = targetModel?.car_trim?.find((el) => {
    return el.id === props.form.getValues('carTrimId');
  });
  const targetGrade = targetTrim?.car_grade?.find((el) => {
    return el.id === props.form.getValues('carGradeId');
  });

  const isInterest = myInterestCarList?.some((el) => {
    return el.id === targetModel?.id;
  });

  const { mutate: toggleInterestCar } = useMutation({
    mutationFn: () => {
      if (!targetModel) throw new Error('targetModel is undefined');
      if (isInterest) {
        return selectInterestCar(targetModel.id, false);
      } else {
        return selectInterestCar(targetModel.id, true);
      }
    },
    onSuccess: () => {
      refetch();
    },
  });

  if (!targetModel) return null;

  return (
    <div className='py-[18px] px-5 bg-white flex justify-between items-center gap-2 mb-2'>
      <div className='flex flex-col gap-2.5'>
        <div className='text-[#222] text-[16px] font-semibold leading-[24px] tracking-[-0.32px]'>
          {targetModel.name}
        </div>
        {!targetGrade && (targetModel.price.min || targetModel.price.max) && (
          <div className='text-[#555] font-semibold text-[14px] leading-[22.4px] tracking-[-0.28px] break-keep'>
            {targetModel.price.max !== targetModel.price.min ? (
              <>
                {Math.floor(targetModel.price.min / 10000).toLocaleString()}
                만원 ~{' '}
                {Math.floor(targetModel.price.max / 10000).toLocaleString()}
                만원
              </>
            ) : (
              <>
                {' '}
                {Math.floor(targetModel.price.min / 10000).toLocaleString()}
                만원
              </>
            )}
          </div>
        )}
        {targetTrim && targetGrade && (
          <div className='flex flex-col'>
            <div className='text-[#555] text-[14px] font-medium leading-[22.4px] tracking-[-0.28px] break-keep'>
              {combineCarName({
                trimName: targetTrim.name,
                gradeName: targetGrade.name,
              })}
            </div>
          </div>
        )}
      </div>

      <div className='relative flex-none rounded-[10px] bg-[#F3F6F9] w-[140px] h-[110px] flex items-center justify-center'>
        <img
          src={targetModel.image_url ? targetModel.image_url : BLANKCARIMAGE}
          width={110}
          alt='차량 이미지'
        />
        <div
          className='absolute cursor-pointer right-[6px] bottom-[6px]'
          onClick={() => toggleInterestCar()}
        >
          <Heart
            fill={isInterest ? '#FF6A6A' : '#B2B7BB'}
            stroke={isInterest ? '#FF6A6A' : '#B2B7BB'}
            strokeWidth={1}
            width={20}
            height={20}
          />
        </div>
      </div>
    </div>
  );
};

export default ModelInfo;
