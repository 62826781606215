import useSystemStore from 'store/useSystemStore';

import useMemberStore from 'store/useMemberStore';
import CustomerTabbarContent from './customer';
import DealerTabbarContent from './dealer';
import BottomTabbarLayout from './layout';

const BottomTabbar = () => {
  const { isBottomTabbar } = useSystemStore();
  const { loginMember } = useMemberStore();

  return (
    <BottomTabbarLayout isShowing={isBottomTabbar}>
      {loginMember?.type === 'dealer' ? (
        <DealerTabbarContent />
      ) : (
        <CustomerTabbarContent />
      )}
    </BottomTabbarLayout>
  );
};

export default BottomTabbar;
