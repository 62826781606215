import { DealerOrderDetailProps } from '../../../type';
import DealerOrderDetailTabSelector from '../components/tabSelector';
import DealerOrderDetailInfoBidder from '../components/content/bidder';
import DealerOrderDetailBidInfo from '../components/content/biddInfo';
import CarInfoTab from 'pages/v3/quote/customer/components/detail/tab/info';
import useQueryState from 'hooks/useQueryState';

const tabs = ['판매 정보', '견적 정보', '참여자'];

export default function DealerOrderDetailInfoSelling({
  orderDetail,
}: DealerOrderDetailProps) {
  const [tab, setTab] = useQueryState<string>(
    'tab_purchase',
    tabs[0],
    'string',
  );

  return (
    <>
      {/* Tab selector */}
      <DealerOrderDetailTabSelector tabs={tabs} tab={tab} setTab={setTab} />

      {/* Tab content */}
      {tab === '참여자' && (
        <DealerOrderDetailInfoBidder orderDetail={orderDetail} />
      )}

      {tab === '견적 정보' && (
        <DealerOrderDetailBidInfo orderDetail={orderDetail} />
      )}

      {tab === '판매 정보' && (
        <div className='bg-[#F3F6F9] pb-[30px]'>
          <CarInfoTab
            quoteDetail={orderDetail}
            setActiveTab={(activeTab) => {
              setTab(activeTab ?? tabs[0]);
            }}
          />
        </div>
      )}
    </>
  );
}
