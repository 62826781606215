import { CustomerOrderDetail } from '@carsayo/types';
import { InfoItem } from '../../../InfoItem';
import { cn } from 'utils';
import dayjs from 'dayjs';

const NoData = ({ text }: { text: string }) => {
  return (
    <div className='bg-white mt-4 rounded-[16px] text-[#999999] font-[400] text-[14px] leading-[100%] h-[51px] text-center flex items-center justify-center'>
      {text}
    </div>
  );
};

const CarPriceTab = ({ quoteDetail }: { quoteDetail: CustomerOrderDetail }) => {
  console.info('quoteDetail ', quoteDetail);
  return (
    <div className='flex flex-col w-full'>
      <div className='bg-[#E8ECF0] flex justify-between items-center py-4 px-5'>
        <div>조회일자</div>
        <div>
          {dayjs(quoteDetail.selling?.accidentHistory?.SEARCH_DATE).format(
            'YYYY.MM.DD',
          )}
        </div>
      </div>

      <div className='py-6 px-5 flex flex-col gap-[30px]'>
        <div>
          <div className='font-[600] text-[18px] leading-[100%] mb-2'>
            주행거리별 시세
          </div>
          <div className='text-[#555555] text-[14px] leading-[150%]'>
            상기 가격은 실제 거래 가격과 다를 수 있습니다.
          </div>

          {quoteDetail.selling?.usedCarInfo.mileageprice &&
          quoteDetail.selling?.usedCarInfo.mileageprice.length > 0 ? (
            <div className='bg-white mt-4 rounded-[16px] divide-y-[1px] divide-[#E5E5EC]'>
              {quoteDetail.selling?.usedCarInfo.mileageprice.map(
                (item, index) => {
                  return (
                    <InfoItem
                      key={`price_${index}`}
                      title={`${item.mileage.toLocaleString()}km`}
                      desc={`${item.price.toLocaleString()}원`}
                    />
                  );
                },
              )}
            </div>
          ) : (
            <NoData text='주행거리별 시세 내역이 없어요' />
          )}
        </div>

        <div>
          <div className='font-[600] text-[18px] leading-[100%] mb-2'>
            매매 기준 시세
          </div>
          <div className='text-[#555555] text-[14px] leading-[150%]'>
            동일 차량 기준 한달 평균 매매금액입니다.
          </div>

          {quoteDetail.selling?.usedCarInfo.usedprice?.[0] ? (
            <div className='bg-white rounded-[10px] flex items-center mt-4'>
              <InfoItem
                title={dayjs(
                  quoteDetail.selling?.usedCarInfo.usedprice[0].stdym,
                ).format('YYYY년 MM월')}
                desc={`${quoteDetail.selling?.usedCarInfo.usedprice[0].price.toLocaleString()}원`}
              />
            </div>
          ) : (
            <NoData text='매매 기준 시세 정보가 없어요' />
          )}
        </div>
      </div>

      <ul className='flex w-full list-none flex-col items-start justify-start gap-3 px-5'>
        <li
          className={cn(
            'relative break-keep pl-5 text-[14px] leading-[21px] text-[#555555]',
            'after:absolute after:left-[9px] after:top-[10px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
          )}
        >
          차량의 사고유무, 상태, 옵션, 색상 등이 고려되지 않은 시세로 참고만 해
          주세요.
        </li>
        <li
          className={cn(
            'relative break-keep pl-5 text-[14px] leading-[21px] text-[#555555]',
            'after:absolute after:left-[9px] after:top-[10px] after:h-[3px] after:w-[3px] after:rounded-full after:bg-[#555555] after:content-[""]',
          )}
        >
          개인 및 자동차매매업자간의 거래 금액은 시세에 반영되지 않습니다.
        </li>
      </ul>
    </div>
  );
};

export default CarPriceTab;
