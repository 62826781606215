/* eslint-disable @typescript-eslint/no-unused-vars */
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { cn } from 'utils';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import { Pagination, Autoplay } from 'swiper/modules';
import { motion } from 'framer-motion';
import { ChevronLeft, ChevronRight, X } from 'lucide-react';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { DialogHeader } from 'components/ui/dialog';

const CharacterMain = () => {
  const navigate = useNavigate();
  const [isCarkeyOpen, setIsCarkeyOpen] = useState(false);
  const [isCarGongOpen, setIsCarGongOpen] = useState(false);
  const [isCarJipsaOpen, setIsCarJipsaOpen] = useState(false);
  // // sectioin01 - Car Heroes
  // const Section01 = () => {
  //   return (
  //     <section className='m-character01'>
  //       <div>
  //         <div>
  //           <h3>
  //             <span style={{ fontFamily: 'Times New Roman' }}>Car</span>
  //             <span style={{ fontFamily: 'Times New Roman' }}>Heroes Rise</span>
  //           </h3>
  //           <span className='active'>
  //             <UnderlineSketch fill='#FFE492' width='176' height='22' />
  //           </span>
  //           <span className={'star-effect'}>
  //             <img src='/assets/images/svg/star-effect.svg' alt='별모양' />
  //           </span>
  //         </div>
  //       </div>
  //       <div>
  //         <p>
  //           <span>그리스에 머물던 카리테스의 세 여신은</span>
  //           <span>사람들에게 헹복을 가져다주는 힘을 지니고 있었다.</span>
  //           <span>이들은 그리스에서 자기 할일을 마치고 </span>
  //           <span>카스토르 별에 가서 행복한 안식을 누리고 있었다.</span>
  //         </p>
  //         <p>
  //           <span>이들의 취미는 </span>
  //           <span>쌍둥이 엔진 자동차로 드라이브를 하는 것과 지구에서 </span>
  //           <span>그리스와 비슷한 성향의 나라를 찾는 것이었는데, </span>
  //           <span>그들이 찾은 곳은 바로</span>
  //           <span>K-POP으로 전 세계 문화에 지대한 영향을 끼치고 있는</span>
  //           <span>대한민국이었다.</span>
  //         </p>
  //         <p>
  //           <span>
  //             이들은 대한민국에서 들리는 사운드에 이끌려 세모 모양의 쌍둥이 엔진
  //             수퍼카 CARSAYO를 타고
  //           </span>
  //           <span>대한민국으로 오게 되는데.. </span>
  //           <span>
  //             CARSAYO는 빛의 속도로 이동해서 지구로 올때까지 52년이 걸렸다.
  //           </span>
  //           <span>
  //             누군가 자기들을 알아볼까 싶어 이름도 외모도 조금씩 바꾼 채....
  //           </span>
  //         </p>
  //       </div>
  //       <div>
  //         <img
  //           src='/assets/images/svg/m-section01-character01.svg'
  //           alt='카집사 이미지'
  //         />
  //         <img
  //           src='/assets/images/svg/m-section01-character02.svg'
  //           alt='카공이 이미지'
  //         />
  //         <img
  //           src='/assets/images/svg/m-section01-character03.svg'
  //           alt='카키 이미지'
  //         />
  //       </div>
  //     </section>
  //   );
  // };
  // // section02 - 카키
  // const Section02 = () => {
  //   return (
  //     <section className='m-character02'>
  //       <div className='m-character-subTitle'>
  //         <h4>CHARACTER CONCEPT</h4>
  //         <span>캐릭터 컨셉</span>
  //       </div>
  //       <div className='m-character02__contents'>
  //         <div className='m-character02__contents__top'>
  //           <div className='mini-title'>#1 HEROES 2025</div>
  //           <p>카키</p>
  //         </div>

  //         <div className='m-character02__contents__middle'>
  //           <img
  //             src='/assets/images/svg/character-section02-img.svg'
  //             alt='카키 이미지'
  //           />
  //         </div>
  //         <div className='m-character02__contents__bottom'>
  //           <span className='double-quotes'>
  //             <DoubleQuote />
  //           </span>
  //           <p>
  //             <span>실수가 없고 깔끔한 일처리를 자랑하지만</span>
  //             <span>그만큼 예민하기도 하고</span>
  //             <span>엄격한 말투와 모습을 보이기도 한다.</span>
  //             <span>특히 싫어하는 것은 계획이 틀어지는 것.</span>
  //             <span>하지만 말투와 다르게 카키의 마음은 의외로 연약하다!</span>
  //             <span>본격적으로 계산에 들어가기 전</span>
  //             <span>안경을 치켜세우는 버릇이 있다.</span>
  //           </p>
  //         </div>
  //       </div>
  //       <Expression
  //         className='mb'
  //         textColor='#fff'
  //         clickEvent={() => {
  //           navigate('detail/01');
  //         }}
  //       />
  //     </section>
  //   );
  // };
  // // section03 - 카집사
  // const Section03 = () => {
  //   return (
  //     <section className='m-character03'>
  //       <div className='m-character-subTitle'>
  //         <h4>CHARACTER CONCEPT</h4>
  //         <span>캐릭터 컨셉</span>
  //       </div>
  //       <div className='m-character03__contents'>
  //         <div className='m-character03__contents__top'>
  //           <div className='mini-title'>#2 HEROES 2025</div>
  //           <p>카집사</p>
  //         </div>

  //         <div className='m-character03__contents__middle'>
  //           <img
  //             src='/assets/images/svg/character-section03-img.svg'
  //             alt='카집사 이미지'
  //           />
  //         </div>
  //         <div className='m-character03__contents__bottom'>
  //           <span className='double-quotes'>
  //             <DoubleQuote fill={'#FF5B79'} />
  //           </span>
  //           <p>
  //             <span>방치되었던 자동차에 가장 먼저 반응을 한 것은</span>
  //             <span>카집사라고 한다.</span>
  //             <span>경청을 굉장히 잘하며 차집사의 예의바른 언행과</span>
  //             <span>살가운 태도에 몇번 만나지 않은 사람도 금새 편안해져 </span>
  //             <span>상담을 요청하게 된다. 항상 존대말을 사용하며 깔끔하게</span>
  //             <span>관리된 머리와 수염을 유지하는데</span>
  //             <span>이는 자신을 마주하는 사람들에 대한 예의라고 한다.</span>
  //           </p>
  //         </div>
  //       </div>
  //       <Expression
  //         className='mb'
  //         textColor='#fff'
  //         fill='#ff5b79'
  //         clickEvent={() => {
  //           navigate('detail/02');
  //         }}
  //       />
  //     </section>
  //   );
  // };
  // // section04 - 카공이
  // const Section04 = () => {
  //   return (
  //     <section className='m-character04'>
  //       <div className='m-character-subTitle'>
  //         <h4>CHARACTER CONCEPT</h4>
  //         <span>캐릭터 컨셉</span>
  //       </div>
  //       <div className='m-character04__contents'>
  //         <div className='m-character04__contents__top'>
  //           <div className='mini-title'>#3 HEROES 2025</div>
  //           <p>카공이</p>
  //         </div>

  //         <div className='m-character04__contents__middle'>
  //           <img
  //             src='/assets/images/svg/character-section04-img.svg'
  //             alt='카공이 이미지'
  //           />
  //         </div>
  //         <div className='m-character04__contents__bottom'>
  //           <span className='double-quotes'>
  //             <DoubleQuote fill={'#A7DE93'} />
  //           </span>
  //           <p>
  //             <span>차와 대화가 가능해</span>
  //             <span>문제가 무엇인지 금방 파악할 수 있다고 한다.</span>
  //             <span>차량 관리에 부담을 느끼는 고객들의 부담감을 덜어주는</span>
  //             <span>듬직하고 큰 덩치를 가지고 있으며 시원하고</span>
  //             <span>털털한 성격으로 수다 떠는 것을 좋아하는데,</span>
  //             <span>
  //               카공이 앞에서 섣불리 엔진 등에 대한 이야기를 꺼냈다가는
  //             </span>
  //             <span>날밤을 샐 수도 있다고 하니 조심!</span>
  //           </p>
  //         </div>
  //       </div>
  //       <Expression
  //         className='mb'
  //         textColor='#303056'
  //         fill='#A7DE93'
  //         clickEvent={() => {
  //           navigate('detail/03');
  //         }}
  //       />
  //     </section>
  //   );
  // };
  // //   section05 - Character Application
  // const Section05 = () => {
  //   return (
  //     <section className='m-character05'>
  //       <div className='m-character-subTitle'>
  //         <h4>CHARACTER APPLICATION</h4>
  //         <span>캐릭터 모음</span>
  //       </div>
  //       <ul>
  //         <li>
  //           <img
  //             src='/assets/images/svg/character_section05_img01.svg'
  //             alt='카사요히어로즈이미지1'
  //           />
  //         </li>
  //         <li>
  //           <img
  //             src='/assets/images/svg/character_section05_img02.svg'
  //             alt='카사요히어로즈이미지2'
  //           />
  //         </li>
  //         <li>
  //           <img
  //             src='/assets/images/svg/character_section05_img03.svg'
  //             alt='카사요히어로즈이미지3'
  //           />
  //         </li>
  //       </ul>
  //     </section>
  //   );
  // };
  const Section01 = () => {
    const [layoutLoad, setLayoutLoad] = useState<boolean>(false);

    useEffect(() => {
      if (location.pathname) {
        setLayoutLoad(true);
      }
    }, [location]);
    return (
      <section className='w-full bg-[#E5E6FF] flex flex-col justify-start items-start'>
        <div className='relative w-full min-h-[448px] aspect-[580/448] tiny:min-h-[400px] flex justify-center items-start overflow-hidden bg-white pb-[50px] mt-[30px]'>
          <div className='w-full h-[50px] bg-[#E5E6FF]'></div>
          <img
            src='/assets/images/png/character/m_character_section01_bg.png'
            alt='카사요히어로즈 이미지'
            className={cn(
              'h-full w-full object-cover absolute left-0 right-0 mx-auto bottom-[30px] z-[1] transition-opacity duration-500',
              layoutLoad ? 'opacity-100' : 'opacity-20',
            )}
          />
        </div>
        <div className='w-full pt-[5px] px-[20px] flex flex-col justify-center items-center gap-[20px] bg-white pb-[50px] mt-[-2px]'>
          <strong
            className={cn(
              'text-[24px] leading-[36px] font-[700] text-[#3336BD] font-[Montserrat] transition-transform duration-500',
              layoutLoad ? 'translate-y-0' : 'translate-y-[-10px]',
            )}
          >
            CAR HEROES
          </strong>
          <p
            className={cn(
              'text-[16px] leading-[25.6px] text-center max-w-[290px] break-keep transition-transform duration-500',
              layoutLoad ? 'translate-y-0' : 'translate-y-[-10px]',
            )}
          >
            카사요를 대한민국을 넘어
            <br />
            세계 최고의 카 쇼핑 플랫폼으로 성장시키기 위한 그들의 노력은 오늘도
            계속된다.
          </p>
        </div>
      </section>
    );
  };
  const Section02 = ({
    setIsOpen,
  }: {
    setIsOpen: (state: boolean) => void;
  }) => {
    const [page, setPage] = useState<number>(1);
    const [swiper, setSwiper] = useState<SwiperCore | null>(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const characterList = [
      '/assets/images/png/character/carkey_turn01.png',
      '/assets/images/png/character/carkey_turn02.png',
      '/assets/images/png/character/carkey_turn03.png',
      '/assets/images/png/character/carkey_turn04.png',
      '/assets/images/png/character/carkey_turn05.png',
    ];
    return (
      <section className='w-full px-[20px] mb-[50px]'>
        <div className='w-full overflow-hidden relative bg-gradient-to-b from-[#44A375] to-[#337053] px-[20px] pt-[30px] pb-[40px] flex flex-col justify-start items-start shadow-[0px_32.72px_65.43px_#1111110D] z-[1]'>
          {/* top */}
          <div className='w-full relative text-white'>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0, duration: 0.5 },
              }}
              className='flex justify-between items-center mb-[19px]'
            >
              <div className='flex justify-start items-center gap-2'>
                <strong className='text-[24px] leading-[24px] font-[700]'>
                  카키
                </strong>
                <span className='text-[14px] leading-[14px] font-[600] h-[30px] inline-flex justify-center items-center bg-[#1D5D3E] rounded-[100px] py-[10px] px-[12px]'>
                  딜러/경영자
                </span>
              </div>
              <span className='inline-block text-[14px] leading-[14px] font-[700]'>
                #1 HERO
              </span>
            </motion.div>
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.1, duration: 0.5 },
              }}
              className='text-[15px] leading-[24px] font-[400] break-keep'
            >
              차를 사는 사람의 마음을 가장 잘 이해하는 딜러이자 경영자! 고객이
              원하는 최적의 조건을 신속하고 정확하게 제시하며 소비자중심경영,
              품질경영, 환경경영 등 고객에게 신뢰를 안겨주기 위한 철저한 준비로
              늘 분주해요.
            </motion.p>
          </div>
          {/* bottom */}
          <div className='relative w-full flex flex-col justify-between items-center'>
            <motion.img
              initial={{ opacity: 0 }}
              whileInView={{
                opacity: 1,
                transition: { delay: 0, duration: 0.7 },
              }}
              src='/assets/images/png/character/character_carkey_img.png'
              alt='카키 이미지'
              className='w-[calc(100%-35px)] max-w-[260px] object-contain z-[1] pt-[40px] pb-[44px]'
            />
            <button
              onClick={() => setIsOpen(true)}
              className='flex justify-center !cursor-pointer items-center py-[22px] px-[20px] w-full h-[60px] rounded-[10px] bg-[#222] relative z-[1]'
            >
              <span className='text-[16px] leading-[16px] text-white font-[500] !cursor-pointer'>
                카키의 다양한 모습들
              </span>
            </button>
            <div className='absolute w-[900px] h-[753px] rounded-[100%] z-0  -bottom-[580px] left-[50%] -translate-x-[50%]  bg-gradient-to-b from-[#5DA783] to-[#1F7249]'></div>
            <div className='absolute w-[132px] h-[38px] rounded-[100%] bg-[#45926D] z-0 bottom-[90px] left-0 right-[15px] mx-auto'></div>
          </div>
        </div>
        <div className='relative'>
          <div className='check-gradient-background w-full h-[calc((100dvw-40px)*1.3)] overflow-hidden flex flex-col justify-start items-start gap-[90px]  border-l border-[#F4F4F4] relative'>
            <span className='absolute top-[30px] left-[20px] text-[16px] leading-[16px] font-[700] z-[1]'>
              TURN AROUND
            </span>
            <Swiper
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop={true}
              speed={1000}
              slidesPerView={1}
              className='characterSwiper w-full  h-[calc((100vw-40px)*1.3)]  py-[30px] px-[20px]'
              spaceBetween={10}
              onTransitionStart={() => {
                setIsAnimating(true);
              }}
              onTransitionEnd={() => {
                setIsAnimating(false);
              }}
              onSlideChange={(swiper: SwiperClass) => {
                setPage(Math.ceil(swiper.activeIndex + 1));
              }}
              onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
              modules={[Pagination, Autoplay]}
              centeredSlides={true}
            >
              {characterList.map((character, index) => (
                <SwiperSlide
                  key={`characterSwiper_slide_${index}`}
                  className='w-full !h-auto'
                >
                  <img
                    src={character}
                    alt={`카키_이미지`}
                    className={cn(
                      'h-[38%] object-contain relative left-[50%] -translate-x-[50%] z-[1] -bottom-[calc(((100vw-40px)/10)*4.1)]',
                    )}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <img
              src='/assets/images/png/character/m_character_section02_bg02.png'
              alt=''
              className='w-full h-[2px] absolute left-0 bottom-[calc(((100vw-40px)/10)*4)] z-0'
            />
          </div>
          <div
            onClick={() => swiper?.slidePrev()}
            className={cn(
              'absolute left-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
              isAnimating && 'hidden',
            )}
          >
            <ChevronLeft
              className='-ml-[2px]'
              width={26}
              height={26}
              strokeWidth={2}
              stroke={'#fff'}
            />
          </div>
          <div
            onClick={() => swiper?.slideNext()}
            className={cn(
              'absolute right-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
              isAnimating && 'hidden',
            )}
          >
            <ChevronRight
              className='-mr-[2px]'
              width={26}
              height={26}
              strokeWidth={2}
              stroke={'#fff'}
            />
          </div>
        </div>
      </section>
    );
  };
  const Section03 = ({
    setIsOpen,
  }: {
    setIsOpen: (state: boolean) => void;
  }) => {
    const [page, setPage] = useState<number>(1);
    const [swiper, setSwiper] = useState<SwiperCore | null>(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const characterList = [
      '/assets/images/png/character/carGong_turn01.png',
      '/assets/images/png/character/carGong_turn02.png',
      '/assets/images/png/character/carGong_turn03.png',
      '/assets/images/png/character/carGong_turn04.png',
      '/assets/images/png/character/carGong_turn05.png',
    ];
    return (
      <section className='w-full px-[20px] mb-[50px]'>
        <div className='w-full overflow-hidden relative bg-gradient-to-b from-[#8B755E] to-[#605041] px-[20px] pt-[30px] pb-[40px] flex flex-col justify-start items-start shadow-[0px_32.72px_65.43px_#1111110D] z-[1]'>
          {/* top */}
          <div className='w-full relative text-white'>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0, duration: 0.5 },
              }}
              className='flex justify-between items-center mb-[19px]'
            >
              <div className='flex justify-start items-center gap-2'>
                <strong className='text-[24px] leading-[24px] font-[700]'>
                  카공이
                </strong>
                <span className='text-[14px] leading-[14px] font-[600] h-[30px] inline-flex justify-center items-center bg-[#463A2F] rounded-[100px] py-[10px] px-[12px]'>
                  정비사
                </span>
              </div>
              <span className='inline-block text-[14px] leading-[14px] font-[700]'>
                #2 HERO
              </span>
            </motion.div>
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.1, duration: 0.5 },
              }}
              className='text-[15px] leading-[24px] font-[400] break-keep'
            >
              눈 뜨면 잠들 때까지 차와 함께하는 차 박사! 자동차 수리 견적만큼은
              고객님이 손해 보지 않도록, 같은 불편이 두 번 다시 생기지 않도록
              꼼꼼히 정비하는 카사요의 1급 자동차 정비사!
            </motion.p>
          </div>
          {/* bottom */}
          <div className='relative w-full flex flex-col justify-between items-center'>
            <motion.img
              initial={{ opacity: 0 }}
              whileInView={{
                opacity: 1,
                transition: { delay: 0, duration: 0.7 },
              }}
              src='/assets/images/png/character/character_carGong_img.png'
              alt='카공이 이미지'
              className='w-[calc(100%-35px)] max-w-[240px] object-contain z-[1] pt-[19px] pb-[44px]'
            />
            <button
              onClick={() => setIsOpen(true)}
              className='flex justify-center !cursor-pointer items-center py-[22px] px-[20px] w-full h-[60px] rounded-[10px] bg-[#222] relative z-[1]'
            >
              <span className='text-[16px] leading-[16px] text-white font-[500] !cursor-pointer'>
                카공이의 다양한 모습들
              </span>
            </button>
            <div className='absolute w-[900px] h-[753px] rounded-[100%] z-0  -bottom-[580px] left-[50%] -translate-x-[50%]  bg-gradient-to-b from-[#907E6D] to-[#75624F]'></div>
            <div className='absolute w-[212px] h-[38px] rounded-[100%] bg-[#83705C] z-0 bottom-[90px] left-0 right-[15px] mx-auto'></div>
          </div>
        </div>
        <div className='relative'>
          <div className='check-gradient-background w-full h-[calc((100dvw-40px)*1.3)] overflow-hidden flex flex-col justify-start items-start gap-[90px] border-l border-[#F4F4F4] relative'>
            <span className='absolute top-[30px] left-[20px] text-[16px] leading-[16px] font-[700] z-[1]'>
              TURN AROUND
            </span>
            <Swiper
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop={true}
              speed={1000}
              slidesPerView={1}
              className='characterSwiper w-full h-[calc((100vw-40px)*1.3)]  py-[30px] px-[20px]'
              spaceBetween={10}
              onTransitionStart={() => {
                setIsAnimating(true);
              }}
              onTransitionEnd={() => {
                setIsAnimating(false);
              }}
              onSlideChange={(swiper: SwiperClass) => {
                setPage(Math.ceil(swiper.activeIndex + 1));
              }}
              onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
              modules={[Pagination, Autoplay]}
              centeredSlides={true}
            >
              {characterList.map((character, index) => (
                <SwiperSlide
                  key={`characterSwiper_slide_${index}`}
                  className='w-full !h-auto'
                >
                  <img
                    src={character}
                    alt={`카공이_이미지`}
                    className={cn(
                      'h-[43%] object-contain relative left-[50%] -translate-x-[50%] z-[1] -bottom-[calc(((100vw-40px)/10)*3.4)]',
                    )}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <img
              src='/assets/images/png/character/m_character_section02_bg02.png'
              alt=''
              className='w-full h-[2px] absolute left-0 bottom-[calc(((100vw-40px)/10)*4)] z-0'
            />
          </div>
          <div
            onClick={() => swiper?.slidePrev()}
            className={cn(
              'absolute left-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
              isAnimating && 'hidden',
            )}
          >
            <ChevronLeft
              className='-ml-[2px]'
              width={26}
              height={26}
              strokeWidth={2}
              stroke={'#fff'}
            />
          </div>
          <div
            onClick={() => swiper?.slideNext()}
            className={cn(
              'absolute right-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
              isAnimating && 'hidden',
            )}
          >
            <ChevronRight
              className='-mr-[2px]'
              width={26}
              height={26}
              strokeWidth={2}
              stroke={'#fff'}
            />
          </div>
        </div>
      </section>
    );
  };
  const Section04 = ({
    setIsOpen,
  }: {
    setIsOpen: (state: boolean) => void;
  }) => {
    const [page, setPage] = useState<number>(1);
    const [swiper, setSwiper] = useState<SwiperCore | null>(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const characterList = [
      '/assets/images/png/character/carjipsa_turn01.png',
      '/assets/images/png/character/carjipsa_turn02.png',
      '/assets/images/png/character/carjipsa_turn03.png',
      '/assets/images/png/character/carjipsa_turn04.png',
      '/assets/images/png/character/carjipsa_turn05.png',
    ];
    return (
      <section className='w-full px-[20px] mb-[50px]'>
        <div className='w-full overflow-hidden relative bg-gradient-to-b from-[#6F8FEC] to-[#375ECD] px-[20px] pt-[30px] pb-[40px] flex flex-col justify-start items-start shadow-[0px_32px_65px_#1111110D] z-[1]'>
          {/* top */}
          <div className='w-full relative text-white'>
            <motion.div
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0, duration: 0.5 },
              }}
              className='flex justify-between items-center mb-[19px]'
            >
              <div className='flex justify-start items-center gap-2'>
                <strong className='text-[24px] leading-[24px] font-[700]'>
                  카집사
                </strong>
                <span className='text-[14px] leading-[14px] font-[600] h-[30px] inline-flex justify-center items-center bg-[#463A2F] rounded-[100px] py-[10px] px-[12px]'>
                  고객 상담사
                </span>
              </div>
              <span className='inline-block text-[14px] leading-[14px] font-[700]'>
                #3 HERO
              </span>
            </motion.div>
            <motion.p
              initial={{ opacity: 0, y: -20 }}
              whileInView={{
                opacity: 1,
                y: 0,
                transition: { delay: 0.1, duration: 0.5 },
              }}
              className='text-[15px] leading-[24px] font-[400] break-keep'
            >
              고객님의 표정과 음성만 듣고도 무엇을 원하는지 캐치하는 능력의
              소유자. 고객의 질문에 친절히 답하고 그들이 힘들어할 때 곁에서
              공감해 주는 카사요의 진정한 고객 지킴이에요. 고객을 최우선으로
              생각하는 마인드만큼은 세계 최고!
            </motion.p>
          </div>
          {/* bottom */}
          <div className='relative w-full flex flex-col justify-between items-center'>
            <motion.img
              initial={{ opacity: 0 }}
              whileInView={{
                opacity: 1,
                transition: { delay: 0, duration: 0.7 },
              }}
              src='/assets/images/png/character/character_carJipsa_img.png'
              alt='카집사 이미지'
              className='w-[calc(100%-180px)] min-w-[70px]  max-w-[103px] object-contain z-[1] pt-[25px] pb-[44px]'
            />
            <button
              onClick={() => setIsOpen(true)}
              className='flex justify-center !cursor-pointer items-center py-[22px] px-[20px] w-full h-[60px] rounded-[10px] bg-[#222] relative z-[1]'
            >
              <span className='text-[16px] leading-[16px] text-white font-[500] !cursor-pointer'>
                카집사의 다양한 모습들
              </span>
            </button>
            <div className='absolute w-[900px] h-[753px] rounded-[100%] z-0  -bottom-[580px] left-[50%] -translate-x-[50%]  bg-gradient-to-b from-[#658BF9] to-[#5074DB]'></div>
            <div className='absolute w-[108px] h-[32px] rounded-[100%] bg-[#567DEB] z-0 bottom-[95px] left-0 right-0 mx-auto'></div>
          </div>
        </div>
        <div className='relative'>
          <div className='check-gradient-background w-full h-[calc((100dvw-40px)*1.3)] overflow-hidden flex flex-col justify-start items-start gap-[90px]  border-l border-[#F4F4F4] relative'>
            <span className='absolute top-[30px] left-[20px] text-[16px] leading-[16px] font-[700] z-[1]'>
              TURN AROUND
            </span>
            <Swiper
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              loop={true}
              speed={1000}
              slidesPerView={1}
              className='characterSwiper w-full h-[calc((100vw-40px)*1.3)]  py-[30px] px-[20px]'
              spaceBetween={10}
              onTransitionStart={() => {
                setIsAnimating(true);
              }}
              onTransitionEnd={() => {
                setIsAnimating(false);
              }}
              onSlideChange={(swiper: SwiperClass) => {
                setPage(Math.ceil(swiper.activeIndex + 1));
              }}
              onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
              modules={[Pagination, Autoplay]}
              centeredSlides={true}
            >
              {characterList.map((character, index) => (
                <SwiperSlide
                  key={`characterSwiper_slide_${index}`}
                  className='w-full !h-auto'
                >
                  <img
                    src={character}
                    alt={`카집사_이미지`}
                    className={cn(
                      'h-[45%] object-contain relative left-[50%] -translate-x-[50%] z-[1] -bottom-[calc(((100vw-40px)/10)*3.15)]',
                    )}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
            <img
              src='/assets/images/png/character/m_character_section02_bg02.png'
              alt=''
              className='w-full h-[2px] absolute left-0 bottom-[calc(((100vw-40px)/10)*4)] z-0'
            />
          </div>
          <div
            onClick={() => swiper?.slidePrev()}
            className={cn(
              'absolute left-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
              isAnimating && 'hidden',
            )}
          >
            <ChevronLeft
              className='-ml-[2px]'
              width={26}
              height={26}
              strokeWidth={2}
              stroke={'#fff'}
            />
          </div>
          <div
            onClick={() => swiper?.slideNext()}
            className={cn(
              'absolute right-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
              isAnimating && 'hidden',
            )}
          >
            <ChevronRight
              className='-mr-[2px]'
              width={26}
              height={26}
              strokeWidth={2}
              stroke={'#fff'}
            />
          </div>
        </div>
      </section>
    );
  };
  const CarkeyPopup = ({
    isOpen,
    setIsOpen,
  }: {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
  }) => {
    const [page, setPage] = useState<number>(1);
    const [swiper, setSwiper] = useState<SwiperCore | null>(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const characterList = [
      '/assets/images/png/character/carkey01.png',
      '/assets/images/png/character/carkey02.png',
      '/assets/images/png/character/carkey03.png',
      '/assets/images/png/character/carkey04.png',
      '/assets/images/png/character/carkey05.png',
      '/assets/images/png/character/carkey06.png',
    ];
    return (
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '16px',
            width: '90dvw',
            margin: '0px',
            maxWidth: '400px',
          },
        }}
      >
        <DialogContent
          className='w-full rounded-[16px] bg-white'
          sx={{
            padding: '0',
          }}
        >
          <DialogHeader className='relative pt-[50px]'>
            <DialogTitle
              sx={{
                padding: '0px 0px 30px',
                fontFamily: 'Pretendard',
              }}
            >
              <span className='block text-center font-[700] text-[20px] leading-[32px]'>
                딜러이자 경영자,
                <br />
                카키의 다양한 모습이에요.
              </span>
            </DialogTitle>
            <button
              onClick={() => setIsOpen(false)}
              className='absolute right-3 top-1'
            >
              <X width={30} height={30} strokeWidth={1} />
            </button>
          </DialogHeader>
          <div className='relative w-full overflow-hidden rounded-b-[16px]'>
            <Swiper
              loop={true}
              speed={1000}
              slidesPerView={1}
              className='characterSwiper w-full'
              onTransitionStart={() => {
                setIsAnimating(true);
              }}
              onTransitionEnd={() => {
                setIsAnimating(false);
              }}
              onSlideChange={(swiper: SwiperClass) => {
                setPage(Math.ceil(swiper.activeIndex + 1));
              }}
              onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
              modules={[Pagination]}
              centeredSlides={true}
            >
              {characterList.map((character, index) => (
                <SwiperSlide
                  key={`characterSwiper_slide_${index}`}
                  className='w-full !h-auto'
                >
                  <div className='bg-[#EAF6F0] h-[280px] w-full'>
                    <img
                      src={character}
                      alt={`카키_이미지`}
                      className={cn(
                        'h-[48%] object-contain relative top-[48%] -translate-y-[50%] left-[50%] -translate-x-[50%]',
                        index === 5 && 'h-[58%]',
                        index === 2 && 'h-[55%]',
                      )}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div
              onClick={() => swiper?.slidePrev()}
              className={cn(
                'absolute left-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
                isAnimating && 'hidden',
              )}
            >
              <ChevronLeft
                className='-ml-[2px]'
                width={26}
                height={26}
                strokeWidth={2}
                stroke={'#fff'}
              />
            </div>
            <div
              onClick={() => swiper?.slideNext()}
              className={cn(
                'absolute right-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
                isAnimating && 'hidden',
              )}
            >
              <ChevronRight
                className='-mr-[2px]'
                width={26}
                height={26}
                strokeWidth={2}
                stroke={'#fff'}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };
  const CarGongPopup = ({
    isOpen,
    setIsOpen,
  }: {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
  }) => {
    const [page, setPage] = useState<number>(1);
    const [swiper, setSwiper] = useState<SwiperCore | null>(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const characterList = [
      '/assets/images/png/character/carGong01.png',
      '/assets/images/png/character/carGong02.png',
      '/assets/images/png/character/carGong03.png',
      '/assets/images/png/character/carGong04.png',
      '/assets/images/png/character/carGong05.png',
      '/assets/images/png/character/carGong06.png',
    ];
    return (
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '16px',
            width: '90dvw',
            margin: '0px',
            maxWidth: '400px',
          },
        }}
      >
        <DialogContent
          className='w-full rounded-[16px] bg-white'
          sx={{
            padding: '0',
          }}
        >
          <DialogHeader className='relative pt-[50px]'>
            <DialogTitle
              sx={{
                padding: '0px 0px 30px',
                fontFamily: 'Pretendard',
              }}
            >
              <span className='block text-center font-[700] text-[20px] leading-[32px]'>
                정비사, <br />
                카공이의 다양한 모습이에요.
              </span>
            </DialogTitle>
            <button
              onClick={() => setIsOpen(false)}
              className='absolute right-3 top-1'
            >
              <X width={30} height={30} strokeWidth={1} />
            </button>
          </DialogHeader>
          <div className='relative w-full overflow-hidden rounded-b-[16px]'>
            <Swiper
              loop={true}
              speed={1000}
              slidesPerView={1}
              className='characterSwiper w-full'
              onTransitionStart={() => {
                setIsAnimating(true);
              }}
              onTransitionEnd={() => {
                setIsAnimating(false);
              }}
              onSlideChange={(swiper: SwiperClass) => {
                setPage(Math.ceil(swiper.activeIndex + 1));
              }}
              onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
              modules={[Pagination]}
              centeredSlides={true}
            >
              {characterList.map((character, index) => (
                <SwiperSlide
                  key={`characterSwiper_slide_${index}`}
                  className='w-full !h-auto'
                >
                  <div className='bg-[#F2EDE9] h-[280px] w-full'>
                    <img
                      src={character}
                      alt={`카공이_이미지`}
                      className={cn(
                        'h-[48%] object-contain relative top-[48%] -translate-y-[50%] left-[50%] -translate-x-[50%]',
                      )}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div
              onClick={() => swiper?.slidePrev()}
              className={cn(
                'absolute left-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
                isAnimating && 'hidden',
              )}
            >
              <ChevronLeft
                className='-ml-[2px]'
                width={26}
                height={26}
                strokeWidth={2}
                stroke={'#fff'}
              />
            </div>
            <div
              onClick={() => swiper?.slideNext()}
              className={cn(
                'absolute right-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
                isAnimating && 'hidden',
              )}
            >
              <ChevronRight
                className='-mr-[2px]'
                width={26}
                height={26}
                strokeWidth={2}
                stroke={'#fff'}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };
  const CarJipsaPopup = ({
    isOpen,
    setIsOpen,
  }: {
    isOpen: boolean;
    setIsOpen: (state: boolean) => void;
  }) => {
    const [page, setPage] = useState<number>(1);
    const [swiper, setSwiper] = useState<SwiperCore | null>(null);
    const [isAnimating, setIsAnimating] = useState(false);
    const characterList = [
      '/assets/images/png/character/carjipsa01.png',
      '/assets/images/png/character/carjipsa02.png',
      '/assets/images/png/character/carjipsa03.png',
      '/assets/images/png/character/carjipsa04.png',
      '/assets/images/png/character/carjipsa05.png',
      '/assets/images/png/character/carjipsa06.png',
    ];
    return (
      <Dialog
        open={isOpen}
        onClose={() => setIsOpen(false)}
        sx={{
          '& .MuiPaper-root': {
            borderRadius: '16px',
            width: '90dvw',
            margin: '0px',
            maxWidth: '400px',
          },
        }}
      >
        <DialogContent
          className='w-full rounded-[16px] bg-white'
          sx={{
            padding: '0',
          }}
        >
          <DialogHeader className='relative pt-[50px]'>
            <DialogTitle
              sx={{
                padding: '0px 0px 30px',
                fontFamily: 'Pretendard',
              }}
            >
              <span className='block text-center font-[700] text-[20px] leading-[32px]'>
                고객 상담사,
                <br />
                카집사의 다양한 모습이에요.
              </span>
            </DialogTitle>
            <button
              onClick={() => setIsOpen(false)}
              className='absolute right-3 top-1'
            >
              <X width={30} height={30} strokeWidth={1} />
            </button>
          </DialogHeader>
          <div className='relative w-full overflow-hidden rounded-b-[16px]'>
            <Swiper
              loop={true}
              speed={1000}
              slidesPerView={1}
              className='characterSwiper w-full'
              onTransitionStart={() => {
                setIsAnimating(true);
              }}
              onTransitionEnd={() => {
                setIsAnimating(false);
              }}
              onSlideChange={(swiper: SwiperClass) => {
                setPage(Math.ceil(swiper.activeIndex + 1));
              }}
              onSwiper={(swiper: SwiperClass) => setSwiper(swiper)}
              modules={[Pagination]}
              centeredSlides={true}
            >
              {characterList.map((character, index) => (
                <SwiperSlide
                  key={`characterSwiper_slide_${index}`}
                  className='w-full !h-auto'
                >
                  <div className='bg-[#EEF2FF] h-[280px] w-full'>
                    <img
                      src={character}
                      alt={`카집사_이미지`}
                      className={cn(
                        'h-[48%] object-contain relative top-[45%] -translate-y-[50%] left-[50%] -translate-x-[50%]',
                      )}
                    />
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
            <div
              onClick={() => swiper?.slidePrev()}
              className={cn(
                'absolute left-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
                isAnimating && 'hidden',
              )}
            >
              <ChevronLeft
                className='-ml-[2px]'
                width={26}
                height={26}
                strokeWidth={2}
                stroke={'#fff'}
              />
            </div>
            <div
              onClick={() => swiper?.slideNext()}
              className={cn(
                'absolute right-[10px] top-[calc(50%-25px)] flex justify-center items-center w-[50px] h-[50px] bg-[#00000080] rounded-full z-[5]',
                isAnimating && 'hidden',
              )}
            >
              <ChevronRight
                className='-mr-[2px]'
                width={26}
                height={26}
                strokeWidth={2}
                stroke={'#fff'}
              />
            </div>
          </div>
        </DialogContent>
      </Dialog>
    );
  };

  return (
    <div className='m-content'>
      <Section01 />
      <Section02 setIsOpen={setIsCarkeyOpen} />
      <Section03 setIsOpen={setIsCarGongOpen} />
      <Section04 setIsOpen={setIsCarJipsaOpen} />
      <CarkeyPopup isOpen={isCarkeyOpen} setIsOpen={setIsCarkeyOpen} />
      <CarGongPopup isOpen={isCarGongOpen} setIsOpen={setIsCarGongOpen} />
      <CarJipsaPopup isOpen={isCarJipsaOpen} setIsOpen={setIsCarJipsaOpen} />
    </div>
  );
};
export default CharacterMain;
