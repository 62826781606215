import { Button } from 'components/ui/button';
import { useNavigate } from 'react-router-dom';
import usePurchaseStore from './store';
import { useEffect, useLayoutEffect } from 'react';
import useSystemStore from 'store/useSystemStore';
import { removePurchasingOrder } from 'apis/purchase';
import getPrice_PurchaseOrderDTO from 'utils/price/getPrice_PurchaseOrderDTO';
import combineCarName from 'pages/v3/order/purchase/utils/combineCarName';

export default function Complete() {
  const navigate = useNavigate();

  const { setTopTabbarTitle } = useSystemStore();
  const { purchaseOrderDTO, setPurchaseOrderDTO } = usePurchaseStore();

  const setData = async () => {
    if (!purchaseOrderDTO) return;
    await setPurchaseOrderDTO({
      id: purchaseOrderDTO.id,
      type: purchaseOrderDTO.type,
      update: {
        progress: 100,
        currentPath: '/purchase/complete',
      },
    });
  };

  const goMain = () => {
    navigate('/main', { replace: true });
  };

  const goQuote = () => {
    navigate('/quote/customer', { replace: true });
  };

  useEffect(() => {
    window.native.onBackPressed = goMain;
  }, []);

  useLayoutEffect(() => {
    setTopTabbarTitle('견적 신청 완료');
    setData();

    return () => {
      removePurchasingOrder();
    };
  }, []);

  return (
    <div className='flex flex-col h-full '>
      <div className='px-4 py-8 flex-auto overflow-y-scroll'>
        <div className='text-center'>
          <img
            src='/assets/images/v2/customer.svg'
            alt=''
            className='inline-block'
          />
        </div>
        <div className='mt-6 text-center text-base font-semibold'>
          견적 신청이 완료되었습니다!
        </div>
        <div className='mt-3 whitespace-pre-line text-center text-[#767676]'>{`48시간 동안 카 매니저들의 견적서를 받아보세요.
내 견적함에서 확인할 수 있습니다.`}</div>
        <div className='mt-6 rounded-md border bg-[#F7F7FB] px-4 py-6'>
          <div className='border-b border-[#F1F1F5] pb-4'>
            <div className='text-desc'>신청 모델</div>
            <div className='mt-2 flex gap-4'>
              <div className='text-[#555555]'>
                {purchaseOrderDTO?.orderData?.carMaker?.name}{' '}
                {purchaseOrderDTO?.orderData?.carModel?.name}
              </div>
            </div>
          </div>
          <div className='mt-4 border-b border-[#F1F1F5] pb-4'>
            <div className='text-desc'>상세 등급</div>
            <div className='mt-2 flex justify-between gap-4'>
              <div className='text-[#555555]'>
                {combineCarName({
                  trimName: purchaseOrderDTO?.orderData?.carTrim?.name ?? '',
                  gradeName: purchaseOrderDTO?.orderData?.carGrade?.name ?? '',
                })}
              </div>
              <div className='flex-none shrink-0'>
                {purchaseOrderDTO?.orderData?.carGrade?.price.toLocaleString()}
                원
              </div>
            </div>
          </div>
          <div className='mt-4 border-b border-[#F1F1F5] pb-4'>
            <div className='text-desc'>색상</div>
            {purchaseOrderDTO?.orderData?.carColor ? (
              <div className='mt-2 space-y-2'>
                <div className='flex justify-between'>
                  <div className='text-[#555555]'>
                    {purchaseOrderDTO?.orderData?.carColor?.name}
                  </div>
                  {purchaseOrderDTO?.orderData?.carColor?.price &&
                  purchaseOrderDTO.orderData.carColor.price > 0 ? (
                    <div className='text-desc font-semibold'>
                      +{' '}
                      {purchaseOrderDTO?.orderData?.carColor?.price.toLocaleString()}
                      원
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              <div className='mt-2 text-center'>색상은 상담 시 논의합니다.</div>
            )}
          </div>

          <div className='mt-4 border-b border-[#F1F1F5] pb-4'>
            <div className='text-desc'>추가 옵션</div>
            {purchaseOrderDTO?.orderData?.carOptionList &&
            purchaseOrderDTO?.orderData?.carOptionList.length > 0 ? (
              <div className='mt-2 space-y-2'>
                {purchaseOrderDTO?.orderData?.carOptionList.map((el) => {
                  return (
                    <div className='flex justify-between'>
                      <div className='text-[##555555]'>{el.name}</div>
                      <div className='text-desc font-semibold'>
                        + {el.price.toLocaleString()}원
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className='mt-2 text-center'>추가 옵션이 없습니다.</div>
            )}
          </div>

          <div className='flex items-center justify-between py-4'>
            <div className='text-primary'>총 예상 금액</div>
            <div className='font-semibold text-primary'>
              {purchaseOrderDTO && (
                <div className='font-semibold text-primary'>
                  {getPrice_PurchaseOrderDTO(purchaseOrderDTO).toLocaleString()}
                  원
                </div>
              )}
            </div>
          </div>
          <div className='mt-4 text-[#767676]'>
            · 선택 차량에 대한 옵션은 상담 시 추가/변경 가능합니다. <br />·
            실거래가는 제조사 및 딜러사의 정책에 따리 달라질 수 있다는 점 참고
            부탁드립니다.
          </div>
        </div>
      </div>

      <div></div>

      <div className='flex-none grid grid-cols-2 bg-[#F7F7FB] px-4 py-6'>
        <Button
          variant='outline'
          className='rounded-r-none bg-white'
          onClick={goMain}
        >
          홈으로
        </Button>
        <Button className='rounded-l-none' onClick={goQuote}>
          내 견적함
        </Button>
      </div>
    </div>
  );
}
