/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from 'components/ui/form';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from 'components/ui/select';
import { Textarea } from 'components/ui/textarea';
import { Checkbox } from 'components/ui/checkbox';
import { Button } from 'components/ui/button';
import { useLayoutEffect, useState } from 'react';
import Confirm from 'components/ui/confirm';
import useSystemStore from 'store/useSystemStore';
import { customerSignOut, dealerSignOut } from 'apis/setting';
import { useNavigate } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { CustomCheckbox } from 'components_v3/ui/Checkbox';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';

const formSchema = z.object({
  reason: z.string(),
  detailReason: z.string(),
});

export default function Signout() {
  const navigate = useNavigate();

  const [isOpen, setIsOpen] = useState(false);
  const form = useForm<z.infer<typeof formSchema>>({
    resolver: zodResolver(formSchema),
    defaultValues: {
      reason: '',
      detailReason: '',
    },
  });

  const { setTopTabbarState, setLoadingDim } = useSystemStore();
  const { loginMember, setLoginMember, resetLoginMember } = useMemberStore();

  const [directInputActivate, setDirectInputActivate] =
    useState<boolean>(false);

  const [isCheckReason, setIsCheckedReason] = useState<boolean>(false);
  const [isAgree, setIsAgree] = useState<boolean>(false);

  const signout = async () => {
    const formData = form.getValues();

    if (formData.reason === 'directInput' && !formData.detailReason) {
      form.setError('detailReason', { message: '탈퇴 사유를 입력해 주세요.' });
      return;
    }
    setLoadingDim(true);

    try {
      if (loginMember?.type === 'customer') {
        await customerSignOut({
          reason: formData.detailReason
            ? formData.detailReason
            : formData.reason,
        });
        resetLoginMember();
      } else {
        const updatedMember = await dealerSignOut({
          reason: formData.detailReason
            ? formData.detailReason
            : formData.reason,
        });
        setLoginMember(updatedMember);
      }
      setTimeout(() => {
        navigate('/main');
      }, 2000);
      return;
    } catch (e: any) {
      console.error(e);
      CarsayoToast.error(e);
    }

    setLoadingDim(false);
    return;
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      title: '회원 탈퇴',
      rightElement: undefined,
      leftElement: 'back',
    });

    return () => {
      setLoadingDim(false);
    };
  }, []);

  return (
    <div className='pt-[30px] px-[20px] pb-[10px] text-[15px] leading-[22px]'>
      {loginMember && (
        <>
          <div className='text-[18px] leading-[28.8px] font-[500] max-w-[196px] break-keep'>
            탈퇴 사유를 알려주시면 개선을 위해 노력하겠습니다.
          </div>
          <Form {...form}>
            <form id='signupForm' className='mt-[50px] space-y-2.5'>
              <FormField
                control={form.control}
                name='reason'
                render={({ field }) => (
                  <FormItem>
                    <FormLabel className='text-[15px] leading-[15px] font-[400] text-[#222] mb-[4px]'>
                      탈퇴 사유
                    </FormLabel>
                    <FormControl>
                      {/* <Select
                        onValueChange={(value) => {
                          if (!isCheckReason) setIsCheckedReason(true);
                          if (value === 'directInput') {
                            setDirectInputActivate(true);
                          } else {
                            setDirectInputActivate(false);
                            form.setValue('detailReason', '');
                          }
                          field.onChange(value);
                        }}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder='사유 선택' />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value='서비스 미이용'>
                            서비스 미이용
                          </SelectItem>
                          <SelectItem value='directInput'>직접 입력</SelectItem>
                        </SelectContent>
                      </Select> */}
                      <SelectV3
                        value={field.value}
                        onValueChange={(value) => {
                          if (!isCheckReason) setIsCheckedReason(true);
                          if (value === 'directInput') {
                            setDirectInputActivate(true);
                          } else {
                            setDirectInputActivate(false);
                            form.setValue('detailReason', '');
                          }
                          field.onChange(value);
                        }}
                        placeholder='사유 선택'
                      >
                        <SelectV3Item value='서비스 미이용'>
                          서비스 미이용
                        </SelectV3Item>
                        <SelectV3Item value='directInput'>
                          직접 입력
                        </SelectV3Item>
                      </SelectV3>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name='detailReason'
                render={({ field }) => (
                  <FormItem>
                    <FormControl className=''>
                      <div className='pb-[54px] px-4 pt-3 relative border border-[#E5E5EC] rounded-[8px] overflow-hidden'>
                        <Textarea
                          disabled={!directInputActivate}
                          placeholder='내용을 입력해주세요'
                          className='min-h-[234px] border-none rounded-[8px] p-0 text-[16px] leading-[25.6px] font-[400] placeholder:text-[#999999]'
                          {...field}
                        />
                        <span className='text-[14px] leading-[22.4px] text-[#999] font-[500] absolute right-4 bottom-3'>
                          {field.value.length ?? 0}/1,000
                        </span>
                      </div>
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
            </form>
          </Form>
          <div className='mt-[30px] w-full rounded-[10px] bg-[#F3F6F9] p-4 pb-[20px] flex flex-col justify-start items-start gap-[20px]'>
            <h5 className='w-full text-[15px] leading-[22.5px] text-[#111] font-[500] pb-4 border-b border-[#E5E5EC]'>
              확인해 주세요
            </h5>
            {loginMember?.type === 'customer' ? (
              <ul className='text-[#555555] flex flex-col justify-start items-start gap-[12px]'>
                <li className='flex justify-start items-center relative text-[13px] break-keep leading-[19.5px] pl-[22px] after:content-["•"] after:absolute after:left-1.5 after:top-0 after:bottom-0 after:my-auto'>
                  회원탈퇴 시, 30일 이내 재가입이 불가능합니다.
                </li>
                <li className='flex justify-start items-center relative text-[13px] break-keep leading-[19.5px] pl-[22px] after:content-["•"] after:absolute after:left-1.5 after:top-0 after:bottom-0 after:my-auto'>
                  탈퇴 즉시 회원 정보 및 서비스 이용 기록(견적 임시저장, 견적함,
                  게시물 등)은 삭제되며, 재가입 하더라도 복구가 불가능합니다.
                </li>
                <li className='flex justify-start items-center relative text-[13px] break-keep leading-[19.5px] pl-[22px] after:content-["•"] after:absolute after:left-1.5 after:top-0 after:bottom-0 after:my-auto'>
                  전자상거래 등 소비자 보호에 관한 법률에 의거하여 거래 정보는
                  탈퇴 후 5년간 보관됩니다.
                </li>
              </ul>
            ) : (
              <ul className='text-[#555555] flex flex-col justify-start items-start gap-[12px]'>
                <li className='flex justify-start items-center relative text-[13px] break-keep leading-[19.5px] pl-[22px] after:content-["•"] after:absolute after:left-1.5 after:top-0 after:bottom-0 after:my-auto'>
                  카매니저는 회원탈퇴 신청 후 승인이 되어야 탈퇴 처리됩니다.
                </li>
                <li className='flex justify-start items-center relative text-[13px] break-keep leading-[19.5px] pl-[22px] after:content-["•"] after:absolute after:left-1.5 after:top-0 after:bottom-0 after:my-auto'>
                  회원탈퇴 승인시, 즉시 탈퇴 처리되며 재가입은 탈퇴일로 부터
                  30일 이후 가능합니다.
                </li>
                <li className='flex justify-start items-center relative text-[13px] break-keep leading-[19.5px] pl-[22px] after:content-["•"] after:absolute after:left-1.5 after:top-0 after:bottom-0 after:my-auto'>
                  거래중 인 판매 또는 미확정 건이 있는 경우 거래 종료 후 탈퇴
                  신청이 가능합니다.
                </li>
                <li className='flex justify-start items-center relative text-[13px] break-keep leading-[19.5px] pl-[22px] after:content-["•"] after:absolute after:left-1.5 after:top-0 after:bottom-0 after:my-auto'>
                  이용정지 회원의 경우 해제 후 탈퇴가 가능합니다.
                </li>
                <li className='flex justify-start items-center relative text-[13px] break-keep leading-[19.5px] pl-[22px] after:content-["•"] after:absolute after:left-1.5 after:top-0 after:bottom-0 after:my-auto'>
                  전자 상거래 등에서의 소비자 보호에 관한 법률 제6조(거래기록의
                  보전 등)에 의거 거래 정보는 회원 탈퇴 후 5년 간 보존됩니다.
                </li>
              </ul>
            )}
          </div>
          {/* <div className='mt-6'>탈퇴 주의 사항</div>
          {loginMember?.type === 'customer' ? (
            <ul className='mt-2 list-outside list-decimal pl-4 text-[#767676]'>
              <li>
                정상회원 또는 거래중인 상품이 없는 경우 즉시 탈퇴 가능합니다.
              </li>
              <li>
                회원탈퇴 시, 즉시 탈퇴 처리되며 재가입은 탈퇴일로 부터 30일 이후
                가능합니다.
              </li>
              <li>
                거래중인 판매 또는 미확정 건이 있는 경우 거래 종료 후 탈퇴가
                가능합니다.
              </li>
              <li>이용정지 회원의 경우 해제 후 탈퇴가 가능합니다.</li>
              <li>
                전자 상거래 등에서의 소비자 보호에 관한 법률 제6조(거래기록의
                보전 등)에 의거 거래 정보는 회원 탈퇴 후 5년 간 보존됩니다.
              </li>
            </ul>
          ) : (
            <ul className='mt-2 list-outside list-decimal pl-4 text-[#767676]'>
              <li>
                카매니저는 회원탈퇴 신청 후 승인이 되어야 탈퇴 처리됩니다.
              </li>
              <li>
                회원탈퇴 승인시, 즉시 탈퇴 처리되며 재가입은 탈퇴일로 부터 30일
                이후 가능합니다.
              </li>
              <li>
                거래중 인 판매 또는 미확정 건이 있는 경우 거래 종료 후 탈퇴
                신청이 가능합니다.
              </li>
              <li>이용정지 회원의 경우 해제 후 탈퇴가 가능합니다.</li>
              <li>
                전자 상거래 등에서의 소비자 보호에 관한 법률 제6조(거래기록의
                보전 등)에 의거 거래 정보는 회원 탈퇴 후 5년 간 보존됩니다.
              </li>
            </ul>
          )} */}

          <div className='mt-[30px] flex gap-2'>
            <CustomCheckbox
              value={isAgree ? 'true' : 'false'}
              label='위 내용을 모두 확인하였습니다.'
              onClick={() => {
                setIsAgree(!isAgree);
              }}
            />
            {/* <Checkbox
              checked={isAgree}
              id='check'
              onClick={() => {
                setIsAgree(!isAgree);
              }}
            />
            <label htmlFor='check'>상기 사항을 모두 확인하였습니다.</label> */}
          </div>
          <div className='flex mt-[50px] justify-start items-center gap-[10px]'>
            <Button
              type='button'
              variant={'outline'}
              className='w-[85px] h-[54px] rounded-[10px] shrink-0'
              onClick={() => navigate(-1)}
            >
              취소
            </Button>
            <Button
              disabled={!isAgree || !isCheckReason}
              className='flex-1 w-full'
              onClick={() => setIsOpen(true)}
            >
              탈퇴하기
            </Button>
          </div>

          <Confirm
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            isCloseButton={true}
            onConfirm={signout}
            title={
              loginMember?.type === 'customer'
                ? '카사요 탈퇴하기'
                : '카매니저 탈퇴신청'
            }
            desc={`카사요 탈퇴를 진행 하시겠습니까?`}
          ></Confirm>
        </>
      )}
    </div>
  );
}
