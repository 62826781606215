import React from 'react';
import { cn } from 'utils';

export const InfoItem = ({
  title,
  desc,
  subDesc,
  onClick,
  className,
  titleClassName,
  descClassName,
  subClassName,
}: {
  title: string;
  desc: React.ReactNode;
  subDesc?: string;
  onClick?: () => void;
  className?: string;
  titleClassName?: string;
  descClassName?: string;
  subClassName?: string;
}) => {
  return (
    <div
      className={cn(
        'flex items-center text-[15px] font-[500] px-4 py-[14px] w-full',
        className,
      )}
      onClick={onClick}
    >
      <div
        className={cn('w-[90px] text-[#7D848A] leading-[100%]', titleClassName)}
      >
        {title}
      </div>
      <div className='flex flex-col gap-1 items-center flex-1'>
        <div className={cn('leading-[150%] text-right w-full', descClassName)}>
          {desc}
        </div>
        {subDesc && (
          <div
            className={cn(
              'text-[#555555] text-[13px] leading-[100%] text-right w-full',
              subClassName,
            )}
          >
            {subDesc}
          </div>
        )}
      </div>
    </div>
  );
};
