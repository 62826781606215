/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import { useNavigate } from 'react-router-dom';
import { cn } from 'utils';
import signupStore from '../store';
import useFileUploader from 'store/useFileUploader';
import { Dispatch, SetStateAction, useState } from 'react';
import { encodeFileToBase64 } from 'utils/convertImage';
import useSystemStore from 'store/useSystemStore';
import {
  SignupLeaseRentDealer,
  SignupNewCarDealer,
  SignupUsedCarDealer,
} from 'apis/authentication';
import useMemberStore from 'store/useMemberStore';
import Confirm from 'components/ui/confirm';
import { dateTranslator } from 'utils/date';
import { ga4_event } from 'utils/ga4';
import { FileInfo } from '@carsayo/types';

type UploadedAuthFileInfo = {
  file_loanSalesCertificate: FileInfo | null;
  file_idCard: FileInfo | null;
  file_nameCard: FileInfo | null;
  file_employeeCard: FileInfo | null;
  file_employCertificate: FileInfo | null;
  file_businessCertificate: FileInfo | null;
};
type AuthFileInfo = {
  title: string;
  key: keyof UploadedAuthFileInfo;
  isEssential: boolean;
};
const authFileData: {
  leaseRent: AuthFileInfo[];
  newCar: AuthFileInfo[];
  usedCar: AuthFileInfo[];
} = {
  leaseRent: [
    { title: '신분증', key: 'file_idCard', isEssential: true },
    {
      title: '대출성 금융상품 대리 중개업자 등록증',
      key: 'file_loanSalesCertificate',
      isEssential: true,
    },
  ],
  newCar: [
    { title: '신분증', key: 'file_idCard', isEssential: true },
    { title: '명함', key: 'file_nameCard', isEssential: true },
    { title: '사원증', key: 'file_employeeCard', isEssential: false },
    {
      title: '재직증명서',
      key: 'file_employCertificate',
      isEssential: false,
    },
  ],
  usedCar: [
    { title: '신분증', key: 'file_idCard', isEssential: true },
    { title: '명함', key: 'file_nameCard', isEssential: true },
    { title: '매매종사원증', key: 'file_employeeCard', isEssential: true },
    {
      title: '소속 매매상사 사업자 등록증',
      key: 'file_businessCertificate',
      isEssential: true,
    },
  ],
};

function FileInput({
  index,
  item,
  uploadedAuthFileInfo,
  setUploadedAuthFileInfo,
}: {
  index: number;
  item: AuthFileInfo;
  uploadedAuthFileInfo: UploadedAuthFileInfo;
  setUploadedAuthFileInfo: Dispatch<SetStateAction<UploadedAuthFileInfo>>;
}) {
  const [imageSrc, setImageSrc] = useState<string>();
  const { fileUpload } = useFileUploader();

  return (
    <div key={`authFileInput_${index}`}>
      <div className='mb-2 inline-flex text-[#767676] leading-[20px] peer-disabled:cursor-not-allowed peer-disabled:opacity-70'>
        {item.title}
      </div>
      <div className='flex items-center gap-4 border px-4 mb-6'>
        <div className='h-9 w-9 overflow-hidden'>
          <img
            className='w-full h-full'
            src={
              imageSrc
                ? imageSrc
                : uploadedAuthFileInfo[item.key] &&
                    uploadedAuthFileInfo[item.key]?.extension !== 'pdf'
                  ? uploadedAuthFileInfo[item.key]?.url
                  : uploadedAuthFileInfo[item.key]?.extension === 'pdf'
                    ? '/assets/images/v2/document.svg'
                    : '/assets/images/v2/file.svg'
            }
            alt=''
          />
        </div>
        <div className='flex h-[68px] items-center'>
          <div className='inline-flex h-[18px] w-2.5 items-center justify-center'>
            <span
              className={cn(
                'h-0.5 w-0.5 rounded-full bg-[#767676]',
                item.isEssential &&
                  !uploadedAuthFileInfo[item.key] &&
                  'bg-destructive',
              )}
            ></span>
          </div>
          <div
            className={cn(
              item.isEssential &&
                !uploadedAuthFileInfo[item.key] &&
                'text-destructive',
            )}
          >
            {item.isEssential ? '필수사항입니다' : '선택사항입니다'}
          </div>
        </div>
        <input id={`file-${item}`} type='file' className='w-0 hidden' />

        {!uploadedAuthFileInfo[item.key] ? (
          <Button
            onClick={() => {
              fileUpload(
                {
                  acceptFile: ['image', 'pdf'],
                  fileType: 'dealerAuthFile',
                  isPublic: false,
                },
                async (fileInfo, file) => {
                  if (fileInfo.extension !== 'pdf' && file) {
                    const base64String = await encodeFileToBase64(file);
                    setImageSrc(base64String);
                  }

                  setUploadedAuthFileInfo({
                    ...uploadedAuthFileInfo,
                    [item.key]: fileInfo,
                  });
                },
              );
            }}
            size='sm'
            variant='outline'
            className='ml-auto w-auto border-[#111111] px-3'
          >
            <label htmlFor='file'>업로드</label>
          </Button>
        ) : (
          <Button
            onClick={() => {
              setUploadedAuthFileInfo({
                ...uploadedAuthFileInfo,
                [item.key]: null,
              });
              setImageSrc('');
            }}
            size='sm'
            variant='outline'
            className='ml-auto w-auto border-[#111111] px-3'
          >
            <label htmlFor='file'>제거</label>
          </Button>
        )}
      </div>
    </div>
  );
}

export default function DealerAuthFileUpload() {
  const navigate = useNavigate();

  const { setLoginMember } = useMemberStore();
  const { setLoadingDim } = useSystemStore();

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [confirmMessage, setConfirmMessage] = useState<{
    title: string;
    desc: string;
  }>({ title: '', desc: '' });

  const [uploadedAuthFileInfo, setUploadedAuthFileInfo] =
    useState<UploadedAuthFileInfo>({
      file_loanSalesCertificate: null,
      file_idCard: null,
      file_nameCard: null,
      file_employeeCard: null,
      file_employCertificate: null,
      file_businessCertificate: null,
    });

  const { signupInfo, setSignupStore } = signupStore();

  const signup = async () => {
    if (
      !signupInfo?.dealer ||
      !signupInfo.phoneNumber ||
      !signupInfo.dealer.email ||
      !signupInfo?.agreeTermList ||
      !signupInfo?.niceAuthToken ||
      !signupInfo.dealer.organization ||
      !signupInfo.dealer.experenceYear ||
      !signupInfo?.dealer?.selectedCarMakerList
    ) {
      navigate('/main');
      return;
    }

    setLoadingDim(true);

    if (
      signupInfo.dealer.type === 'leaseRent' &&
      uploadedAuthFileInfo.file_idCard &&
      uploadedAuthFileInfo.file_loanSalesCertificate &&
      signupInfo.dealer.workingSido &&
      signupInfo.dealer.leaseRentType &&
      signupInfo.dealer.selectedCarFrom
    ) {
      await SignupLeaseRentDealer({
        identityVerifyToken: signupInfo.niceAuthToken,
        isCarsayoEmployee: false,
        phoneNumber: signupInfo.phoneNumber,
        email: signupInfo.dealer.email,
        experienceYears: signupInfo.dealer.experenceYear,
        organization: signupInfo.dealer.organization,
        file_idCardId: uploadedAuthFileInfo.file_idCard.id,
        file_loanSalesCertificateId:
          uploadedAuthFileInfo.file_loanSalesCertificate.id,
        '20100': signupInfo.agreeTermList.includes(20100),
        '20200': signupInfo.agreeTermList.includes(20200),
        '26090': signupInfo.agreeTermList.includes(26090),
        '26100': signupInfo.agreeTermList.includes(26100),
        '94100': signupInfo.agreeTermList.includes(94100),
        '95100': signupInfo.agreeTermList.includes(95100),
        '95200': signupInfo.agreeTermList.includes(95200),
        '95300': signupInfo.agreeTermList.includes(95300),
        isDomestic:
          signupInfo.dealer.selectedCarFrom === 'domestic' ||
          signupInfo.dealer.selectedCarFrom === 'whole'
            ? true
            : false,
        isImported:
          signupInfo.dealer.selectedCarFrom === 'imported' ||
          signupInfo.dealer.selectedCarFrom === 'whole'
            ? true
            : false,
        workingSido: signupInfo.dealer.workingSido,
        joinReason: signupInfo.dealer.joinReason,
        leaseRentType: signupInfo.dealer.leaseRentType,
      })
        .then((data) => {
          if (data.member) {
            ga4_event({
              category: 'signup_dealer',
              action: '리스렌트 카매니저 회원가입 신청',
              label: data.member.id,
            });
            setLoginMember(data.member);
            navigate('/auth/signup/dealer/complete');
          } else {
            setConfirmMessage({
              title: '회원 가입 실패',
              desc: '회원가입에 실패했습니다.\n잠시 후 다시 시도해 주세요.',
            });
            setIsConfirmOpen(true);
          }
          setLoadingDim(false);
        })
        .catch((e: any) => {
          setConfirmMessage({
            title: '회원 가입 실패',
            desc: e?.response?.data?.message
              ? e?.response?.data?.message
              : '회원가입에 실패했습니다.\n잠시 후 다시 시도해 주세요.',
          });
          setIsConfirmOpen(true);
          setLoadingDim(false);
        });
    } else if (
      signupInfo.dealer.type === 'newcar' &&
      signupInfo.dealer.isCarsayoEmployee !== undefined &&
      uploadedAuthFileInfo.file_idCard &&
      uploadedAuthFileInfo.file_nameCard &&
      signupInfo.dealer.workingSido
    ) {
      await SignupNewCarDealer({
        identityVerifyToken: signupInfo.niceAuthToken,
        phoneNumber: signupInfo.phoneNumber,
        isCarsayoEmployee: signupInfo.dealer.isCarsayoEmployee,
        email: signupInfo.dealer.email,
        experienceYears: signupInfo.dealer.experenceYear,
        organization: signupInfo.dealer.organization,
        file_idCardId: uploadedAuthFileInfo.file_idCard.id,
        file_nameCardId: uploadedAuthFileInfo.file_nameCard.id,
        file_employCertificateId: uploadedAuthFileInfo.file_employCertificate
          ? uploadedAuthFileInfo.file_employCertificate.id
          : undefined,
        file_employeeCardId: uploadedAuthFileInfo.file_employeeCard
          ? uploadedAuthFileInfo.file_employeeCard.id
          : undefined,
        '20100': signupInfo.agreeTermList.includes(20100),
        '20200': signupInfo.agreeTermList.includes(20200),
        '24090': signupInfo.agreeTermList.includes(24090),
        '24100': signupInfo.agreeTermList.includes(24100),
        '94100': signupInfo.agreeTermList.includes(94100),
        '95100': signupInfo.agreeTermList.includes(95100),
        '95200': signupInfo.agreeTermList.includes(95200),
        '95300': signupInfo.agreeTermList.includes(95300),
        selectedCarMakerList: signupInfo.dealer.selectedCarMakerList,
        workingSido: signupInfo.dealer.workingSido,
        joinReason: signupInfo.dealer.joinReason,
      })
        .then((data) => {
          if (data.member) {
            ga4_event({
              category: 'signup_dealer',
              action: '신차 카매니저 회원가입 신청',
              label: data.member.id,
            });
            setLoginMember(data.member);
            navigate('/auth/signup/dealer/complete');
          } else {
            setConfirmMessage({
              title: '회원 가입 실패',
              desc: '회원가입에 실패했습니다.\n잠시 후 다시 시도해 주세요.',
            });
            setIsConfirmOpen(true);
          }
          setLoadingDim(false);
        })
        .catch((e: any) => {
          setConfirmMessage({
            title: '회원 가입 실패',
            desc: e?.response?.data?.message
              ? e?.response?.data?.message
              : '회원가입에 실패했습니다.\n잠시 후 다시 시도해 주세요.',
          });
          setIsConfirmOpen(true);
          setLoadingDim(false);
        });
    } else if (
      signupInfo.dealer.type === 'usedcar' &&
      signupInfo.dealer.isCarsayoEmployee !== undefined &&
      signupInfo.dealer.tradingCertificate_term_finish &&
      signupInfo.dealer.tradingCertificate_term_start &&
      uploadedAuthFileInfo.file_idCard &&
      uploadedAuthFileInfo.file_nameCard &&
      uploadedAuthFileInfo.file_employeeCard &&
      uploadedAuthFileInfo.file_businessCertificate &&
      signupInfo.dealer.workingSido &&
      signupInfo.dealer.selectedCarFrom
    ) {
      await SignupUsedCarDealer({
        identityVerifyToken: signupInfo.niceAuthToken,
        phoneNumber: signupInfo.phoneNumber,
        email: signupInfo.dealer.email,
        experienceYears: signupInfo.dealer.experenceYear,
        organization: signupInfo.dealer.organization,
        tradingCertificate_term_start: dateTranslator(
          signupInfo.dealer.tradingCertificate_term_start,
        ),
        tradingCertificate_term_finish: dateTranslator(
          signupInfo.dealer.tradingCertificate_term_finish,
        ),
        file_idCardId: uploadedAuthFileInfo.file_idCard.id,
        file_nameCardId: uploadedAuthFileInfo.file_nameCard.id,
        file_businessCertificateId:
          uploadedAuthFileInfo.file_businessCertificate.id,
        file_employeeCardId: uploadedAuthFileInfo.file_employeeCard.id,
        isCarsayoEmployee: signupInfo.dealer.isCarsayoEmployee,
        '20100': signupInfo.agreeTermList.includes(20100),
        '20200': signupInfo.agreeTermList.includes(20200),
        '25090': signupInfo.agreeTermList.includes(25090),
        '25100': signupInfo.agreeTermList.includes(25100),
        '94100': signupInfo.agreeTermList.includes(94100),
        '95100': signupInfo.agreeTermList.includes(95100),
        '95200': signupInfo.agreeTermList.includes(95200),
        '95300': signupInfo.agreeTermList.includes(95300),
        isDomestic:
          signupInfo.dealer.selectedCarFrom === 'domestic' ||
          signupInfo.dealer.selectedCarFrom === 'whole'
            ? true
            : false,
        isImported:
          signupInfo.dealer.selectedCarFrom === 'imported' ||
          signupInfo.dealer.selectedCarFrom === 'whole'
            ? true
            : false,
        workingSido: signupInfo.dealer.workingSido,
        joinReason: signupInfo.dealer.joinReason,
      })
        .then((data) => {
          if (data.member) {
            ga4_event({
              category: 'signup_dealer',
              action: '중고차 카매니저 회원가입 신청',
              label: data.member.id,
            });
            setLoginMember(data.member);
            navigate('/auth/signup/dealer/complete');
          } else {
            setConfirmMessage({
              title: '회원 가입 실패',
              desc: '회원가입에 실패했습니다.\n잠시 후 다시 시도해 주세요.',
            });
            setIsConfirmOpen(true);
          }
          setLoadingDim(false);
        })
        .catch((e: any) => {
          setConfirmMessage({
            title: '회원 가입 실패',
            desc: e?.response?.data?.message
              ? e?.response?.data?.message
              : '회원가입에 실패했습니다.\n잠시 후 다시 시도해 주세요.',
          });
          setIsConfirmOpen(true);
          setLoadingDim(false);
        });
    } else {
      setConfirmMessage({
        title: '회원 가입 실패',
        desc: '필수 항목이 모두 입력되지 않았습니다',
      });
      setIsConfirmOpen(true);
      setLoadingDim(false);
    }

    return;
  };

  return (
    <div className='flex min-h-full grow flex-col p-4 pb-10'>
      <div className='text-lg font-semibold'>
        카 매니저님의
        <br />
        <span className='text-primary'>소속증명파일</span>을 입력해주세요.
      </div>
      <div className='my-6'>
        {signupInfo?.dealer?.type === 'leaseRent' &&
          authFileData.leaseRent.map((item, index) => {
            return FileInput({
              index: index,
              item: item,
              uploadedAuthFileInfo,
              setUploadedAuthFileInfo,
            });
          })}

        {signupInfo?.dealer?.type === 'newcar' &&
          authFileData.newCar.map((item, index) => {
            return FileInput({
              index: index,
              item: item,
              uploadedAuthFileInfo,
              setUploadedAuthFileInfo,
            });
          })}

        {signupInfo?.dealer?.type === 'usedcar' &&
          authFileData.usedCar.map((item, index) => {
            return FileInput({
              index: index,
              item: item,
              uploadedAuthFileInfo,
              setUploadedAuthFileInfo,
            });
          })}
      </div>

      <Button
        className='mt-auto'
        type='submit'
        form='signupForm'
        onClick={signup}
      >
        가입신청
      </Button>

      <Confirm
        title={confirmMessage.title}
        desc={confirmMessage.desc}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
      />
    </div>
  );
}
