export default function TabbarPen() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='32'
      height='32'
      viewBox='0 0 32 32'
      fill='none'
    >
      <g clipPath='url(#clip0_3497_49964)'>
        <path
          d='M5.33398 26.6664H10.6673L24.6673 12.6664C25.0175 12.3162 25.2953 11.9005 25.4848 11.4429C25.6743 10.9854 25.7719 10.495 25.7719 9.99975C25.7719 9.50451 25.6743 9.01411 25.4848 8.55656C25.2953 8.09901 25.0175 7.68328 24.6673 7.33309C24.3171 6.98289 23.9014 6.70511 23.4438 6.51558C22.9863 6.32606 22.4959 6.22852 22.0007 6.22852C21.5054 6.22852 21.015 6.32606 20.5575 6.51558C20.0999 6.70511 19.6842 6.98289 19.334 7.33309L5.33398 21.3331V26.6664Z'
          stroke='#222222'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
        <path
          d='M18 8.66602L23.3333 13.9993'
          stroke='#222222'
          strokeWidth='2'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_3497_49964'>
          <rect width='32' height='32' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
