import ConfirmV2 from 'components/common/v3/confirm';
import Star from 'components/svg/Star';
import { Button } from 'components/ui/button';
import { useLayoutEffect, useState } from 'react';
import useSystemStore from 'store/useSystemStore';

export default function IncidentReview() {
  const [hoveredRating, setHoveredRating] = useState(0);
  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [reviewContent, setReviewContent] = useState('');
  const { setTopTabbarState } = useSystemStore();
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      rightElement: undefined,
      leftElement: 'back',
      title: '리뷰 작성',
    });
  }, []);
  return (
    <div className='w-full mb-[10px]'>
      <div className='py-[20px] mb-[10px]'>
        <div className='px-[20px] flex justify-start items-stretch gap-[14px] pb-[30px] border-b-[8px] border-[#F3F6F9] mb-[50px]'>
          <div className='w-[74px] h-[74px] my-auto flex justify-center items-center rounded-[6px] overflow-hidden'>
            <img
              src='/assets/images/v2/exampleImg.png'
              alt='예시 이미지'
              className='w-full h-full object-cover'
            />
          </div>
          <div className='flex flex-col justify-between items-start gap-[12px] my-auto'>
            <div className='flex flex-col justify-start items-start gap-1'>
              <strong className='text-[15px] leading-[21px] font-[500]'>
                제일 1급 정비 공업사
              </strong>
              <p className='text-[13px] leading-[13px] font-[400] text-[#555]'>
                서울 성동구 아차산로 21 101호
              </p>
            </div>
            <strong className='text-[15px] leading-[15px] font-[600]'>
              1,365,000원
            </strong>
          </div>
        </div>
        <div className='px-[20px]'>
          <div className='flex flex-col justify-start items-center gap-6 mb-[40px]'>
            <p className='max-w-[205px] text-center break-keep text-[20px] leading-[30px] font-[600]'>
              자동차 수리부터 <br />
              출고 완료까지 어떠셨나요?
            </p>
            <div className='flex items-center justify-center gap-[2px]'>
              {[...Array(5)].map((_, index) => (
                <div
                  key={index}
                  onClick={async () => {
                    setHoveredRating(index + 1);
                  }}
                >
                  {hoveredRating ? (
                    <Star
                      fill={index < hoveredRating ? '#FFC93E' : '#DDDDDD'}
                      width={'32'}
                      height={'32'}
                      // stroke={index < hoveredRating ? '#FFC93E' : '#DDDDDD'}
                    />
                  ) : (
                    <Star
                      fill={index < 0 ? '#FFC93E' : '#DDDDDD'}
                      width={'32'}
                      height={'32'}
                      // stroke={index < 0 ? '#FFC93E' : '#DDDDDD'}
                    />
                  )}
                </div>
              ))}
            </div>
          </div>
          <div className='w-full flex flex-col justify-start items-start gap-[12px]'>
            <strong className='text-[15px] leading-[15px] font-[400]'>
              내용
            </strong>
            <div className='relative pb-[44px] pt-[12px] px-4 border border-[#E5E5EC] rounded-[8px] h-[300px] overflow-hidden w-full'>
              <textarea
                name=''
                id=''
                value={reviewContent}
                onChange={(e) => setReviewContent(e.target.value)}
                className='w-full text-[16px] leading-[25.6px] font-[400] h-[234px] placeholder:text-[16px] placeholder:font-[400] placeholder:text-[#999] overflow-y-auto resize-none focus:outline-none focus:ring-0 focus:border-none'
                placeholder='10자 이상 내용을 입력해 주세요.'
              ></textarea>
              <span className='text-[14px] leading-[22.4px] font-[500] text-[#999999] absolute right-4 bottom-[12px]'>
                {reviewContent.length ?? 0}/1,000
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className='mb-[50px] text-[13px] leading-[16.9px] font-[400] text-[#555555] bg-[#F3F6F9] flex justify-start items-center min-h-[45px] break-keep py-[14px] px-[20px]'>
        리뷰 작성 정책과 맞지 않는 글 작성 시 별도 고지 없이 제재됩니다.
      </div>
      <Button
        onClick={() => setIsConfirmOpen(!isConfirmOpen)}
        className='w-[calc(100%-40px)] rounded-[10px] mx-auto flex justify-center items-center h-[54px] p-[19px] text-[16px] leading-[16px] font-[500]'
      >
        등록하기
      </Button>
      <ConfirmV2
        title={'리뷰 작성 완료'}
        desc={'소중한 리뷰 작성 감사합니다.'}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
      />
    </div>
  );
}
