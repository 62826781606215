/** 견적서 보기 컨텐츠 */

import V3_Button from 'components_v3/ui/Button';
import { X } from 'lucide-react';
import {
  DealerOrderBidMode,
  DealerOrderDetailSubpageProps,
} from 'pages/v3/orderDetailTemp/type';
import { useMemo } from 'react';
import { cn } from 'utils';
import download from 'utils/download';

/**
 * 견적서 상세보기 컨텐츠
 * @note 고객이 확인하지 않았을 경우 삭제&수정 가능
 * @note 수정시 mode를 update로 변경하면 됩니다.
 */
export default function DealerOrderBidSubpage_VIEW({
  mode,
  setMode,
  isOpen,
  setIsOpen,
  orderDetail,
}: DealerOrderDetailSubpageProps & {
  mode: DealerOrderBidMode;
  setMode: (mode: DealerOrderBidMode) => void;
}) {
  const isCustomerChecked: boolean = useMemo(() => {
    return orderDetail.bid?.isReaded ?? false;
  }, [orderDetail.bid?.isReaded]);

  return (
    <>
      <div className='flex flex-none h-14 items-center justify-between bg-white px-5'>
        <div className='pl-4 grow text-center text-[#222] text-[20px] font-semibold leading-[20px] tracking-[-0.4px]'>
          견적서
        </div>
        <div
          className='cursor-pointer'
          onClick={() => {
            setIsOpen(false);
          }}
        >
          <X className='w-8 h-8' strokeWidth={1.5} />
        </div>
      </div>

      {/* 고객 확인 여부 */}
      <div
        className={cn(
          'flex-none flex px-5 h-[40px] items-center justify-between bg-[#E8ECF0]',
          isCustomerChecked && 'bg-[#DEEBFF]',
        )}
      >
        <div className='text-[#222] text-[14px] font-medium leading-[21px] tracking-[-0.28px]'>
          고객 확인
        </div>
        {isCustomerChecked ? (
          <div className='text-[#005CE6] text-[14px] font-medium leading-[21px] tracking-[-0.28px]'>
            확인완료
          </div>
        ) : (
          <div className='text-[#999] text-[14px] font-medium leading-[21px] tracking-[-0.28px]'>
            미확인
          </div>
        )}
      </div>

      {/* 견적서 내용 */}
      <div className='mt-1 flex-auto flex flex-col gap-6 py-6 px-5 overflow-y-auto'>
        {/* 예상 판매 가격 */}
        {orderDetail.bid?.purchasePrice && (
          <div className='flex items-center justify-between space-y-3 border-b-2 pb-5 border-[#222]'>
            <div className='text-[#222] font-normal text-[15px] leading-[15px] tracking-[-0.3px]'>
              예상 판매 가격
            </div>
            <div className='space-x-1.5'>
              <span className='text-[#222] text-right text-[18px] font-medium leading-[18px] tracking-[-0.36px]'>
                {orderDetail.bid.purchasePrice.toLocaleString()}
              </span>
              <span className='text-[#7D848A] text-right text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
                원
              </span>
            </div>
          </div>
        )}

        {/* 텍스트 내용 */}
        {orderDetail.bid?.content && (
          <div className='space-y-3'>
            <div className='text-[#222] font-normal text-[15px] leading-[15px] tracking-[-0.3px]'>
              전달사항
            </div>
            <div className='bg-[#F3F6F9] rounded-[8px] py-3 px-4 text-[#222] font-normal text-[16px] leading-[25.6px] tracking-[-0.32px] whitespace-pre-line'>
              {orderDetail.bid?.content}
            </div>
          </div>
        )}

        {/* 견적서 파일 */}
        {!!orderDetail.bid?.estimateFile && (
          <div className='space-y-3'>
            <div className='text-[#222] font-normal text-[15px] leading-[15px] tracking-[-0.3px]'>
              견적서
            </div>
            <div
              onClick={() => {
                if (!orderDetail.bid?.estimateFile) return;
                download(orderDetail.bid.estimateFile);
              }}
              className='flex items-center gap-2 bg-[#F3F6F9] rounded-[8px] py-3 px-4'
            >
              {/* 견적서 아이콘 */}
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='24'
                height='24'
                viewBox='0 0 24 24'
                fill='none'
                className='flex-none'
              >
                <path
                  d='M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z'
                  fill='#E5EAEE'
                />
                <path
                  d='M12.2003 17.6593C11.5003 17.6593 10.7903 17.3893 10.2603 16.8593C9.19031 15.7893 9.19031 14.0393 10.2603 12.9693L11.6703 11.5593C11.9603 11.2693 12.4403 11.2693 12.7303 11.5593C13.0203 11.8493 13.0203 12.3293 12.7303 12.6193L11.3203 14.0293C10.8303 14.5193 10.8303 15.3093 11.3203 15.7993C11.8103 16.2893 12.6003 16.2893 13.0903 15.7993L15.3103 13.5793C15.9203 12.9693 16.2603 12.1493 16.2603 11.2793C16.2603 10.4093 15.9203 9.5993 15.3103 8.9793C14.0803 7.7493 11.9403 7.7493 10.7103 8.9793L8.29031 11.3993C7.25031 12.4393 7.25031 14.1393 8.29031 15.1893C8.58031 15.4793 8.58031 15.9593 8.29031 16.2493C8.00031 16.5393 7.52031 16.5393 7.23031 16.2493C5.60031 14.6193 5.60031 11.9693 7.23031 10.3393L9.65031 7.9193C10.5503 7.0193 11.7403 6.5293 13.0103 6.5293C14.2803 6.5293 15.4703 7.0193 16.3703 7.9193C17.2703 8.8193 17.7603 10.0093 17.7603 11.2793C17.7603 12.5493 17.2703 13.7393 16.3703 14.6393L14.1503 16.8593C13.6103 17.3893 12.9103 17.6593 12.2003 17.6593Z'
                  fill='#B2B7BB'
                />
              </svg>
              <span className='text-[#555] font-medium text-[14px] leading-[14px] tracking-[-0.28px] whitespace-pre-line'>
                {orderDetail.bid.estimateFile.name}.
                {orderDetail.bid.estimateFile.extension}
              </span>
            </div>
          </div>
        )}
      </div>

      {/* 하단 버튼영역 */}
      <div className='sticky bottom-0 bg-white p-5 flex flex-none gap-[7px]'>
        <V3_Button
          className='w-full'
          onClick={() => {
            setIsOpen(false);
          }}
        >
          닫기
        </V3_Button>

        {/* 수정 기능은 고객이 견적서를 확인하지 않았을 때에만 가능 */}
        {!isCustomerChecked && (
          <V3_Button
            className='w-full'
            theme='primary'
            onClick={() => {
              setMode('update');
            }}
          >
            수정
          </V3_Button>
        )}
      </div>
    </>
  );
}
