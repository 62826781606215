import dayjs from 'dayjs';

export const formatTimeRemaining = (bidEndedAt: string | null) => {
  if (!bidEndedAt) return '';
  const now = dayjs();
  const endDate = dayjs(bidEndedAt);
  const diff = endDate.diff(now, 'millisecond');

  if (diff <= 0) return '0일 00:00';

  const days = endDate.diff(now, 'day');
  const hours = endDate.diff(now, 'hour') % 24;
  const minutes = endDate.diff(now, 'minute') % 60;

  return `${days}일 ${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}`;
};
