import { Link, useLocation } from 'react-router-dom';
import HomeIcon from 'components/svg/Home';
import MyIcon from 'components/svg/My';
import PurchaseIcon from 'components/svg/Purchase';
import QuoteIcon from 'components/svg/Quote';
import AskIcon from 'components/svg/Ask';

export default function CustomerTabbarContent() {
  const location = useLocation();

  return (
    <ul className='flex justify-center items-center w-full'>
      <li className='w-[20%] h-full p-[5px] pb-[4px] box-border'>
        <Link
          to={'/main'}
          className='w-full h-full flex flex-col items-center gap-[1px]'
        >
          <HomeIcon
            width='30'
            height='30'
            fill={location.pathname.startsWith('/main') ? '#7273F9' : '#B2B7BB'}
          />
          <span
            className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname.startsWith('/main') ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
          >
            홈
          </span>
        </Link>
      </li>
      <li className='w-[20%] p-[5px] pb-[4px]'>
        <Link
          to={
            process.env.REACT_APP_NODE_ENV !== 'development'
              ? '/purchase/brand'
              : '/order/purchase?mode=rent'
          }
          className='w-full h-full flex flex-col items-center gap-[1px]'
        >
          <PurchaseIcon
            width='30'
            height='30'
            fill={
              location.pathname.startsWith('/purchase') ? '#7273F9' : '#B2B7BB'
            }
          />
          <span
            className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname.startsWith('/purchase') ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
          >
            신차 구매
          </span>
        </Link>
      </li>
      <li className='w-[20%] p-[5px] pb-[4px]'>
        <Link
          to={'/quote'}
          className='w-full h-full flex flex-col items-center gap-[1px]'
        >
          <QuoteIcon
            width='30'
            height='30'
            fill={
              location.pathname.startsWith('/quote') ? '#7273F9' : '#B2B7BB'
            }
          />
          <span
            className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname.startsWith('/quote') ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
          >
            견적함
          </span>
        </Link>
      </li>
      <li className='w-[20%] p-[5px] pb-[4px]'>
        <Link
          to={'/ask/main'}
          className='w-full h-full flex flex-col items-center gap-[1px]'
        >
          <AskIcon
            width='30'
            height='30'
            fill={location.pathname.startsWith('/ask') ? '#7273F9' : '#B2B7BB'}
          />
          <span
            className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname.startsWith('/ask') ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
          >
            물어보기
          </span>
        </Link>
      </li>
      <li className='w-[20%] p-[5px] pb-[4px]'>
        <Link
          to={'/mypage'}
          className='w-full h-full flex flex-col items-center gap-[1px]'
        >
          <MyIcon
            width='30'
            height='30'
            fill={
              location.pathname.startsWith('/mypage') ? '#7273F9' : '#B2B7BB'
            }
          />
          <span
            className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname.startsWith('/mypage') ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
          >
            마이
          </span>
        </Link>
      </li>
    </ul>
  );
}
