import { Button } from 'components/ui/button';
import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import signupStore from '../store';
import RightArrow from 'components/common/global/svg/RightArrow';
import { Dialog, DialogContent, DialogFooter } from 'components/ui/dialog';
import { MemberDealerType } from '@carsayo/types';

export default function DealerTypeSelectPage() {
  const navigate = useNavigate();

  const { setTopTabbarTitle, setTopTabbarState } = useSystemStore();
  const { signupInfo, setSignupStore } = signupStore();
  const [isWarningPopupOpen, setWarningPopupOpen] = useState(false);

  const [radio, setRadio] = useState(
    signupInfo?.dealer?.type ? signupInfo.dealer.type : '',
  );

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: 'back',
    });
    setTopTabbarTitle('카매니저 가입');
  }, []);

  return (
    <div className='flex min-h-full grow flex-col p-4'>
      <div className='text-lg font-semibold mb-6'>
        카 매니저님의
        <br />
        <span className='text-primary'>가입 유형</span>을 선택하세요
      </div>
      <div className='p-4 border border-[#E5E5EC]'>
        <p className='font-[600] mb-[12px]'>꼭 읽어주세요!</p>
        <ul className='text-[13px] font-[400] mb-[12px]'>
          <li className='mb-2 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
            국내차 카매니저는 대리점 소속 직원만, 수입차 카매니저는 공식 딜러
            소속 직원만 회원 가입을 받습니다.
          </li>
          <li className='mb-2 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
            중고차 카매니저는 자동차매매사업조합연합회 소속된 종사원만 회원
            가입을 받습니다.
          </li>
          <li className='mb-2 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
            리스 및 렌트 금융 카매니저는 대출성 상품 판매대리 중개업자 자격자만
            가입을 받습니다.
          </li>
        </ul>
        <button
          className='border-y-[1px] border-[#F1F1F5] bg-[#F7F7FB] p-2 w-full flex justify-between items-center'
          onClick={() => setWarningPopupOpen(!isWarningPopupOpen)}
        >
          <div className='flex justify-start items-center gap-[12px]'>
            <img
              src={'/assets/images/import/exclamation_red.svg'}
              alt='빨간색 느낌표 아이콘'
            />
            <span className='font-[600] text-[13px]'>
              이용 방법 및 거래 주의 사항
            </span>
          </div>
          <RightArrow stroke='#111111' />
        </button>
      </div>
      <div className='mt-[6.15vh] space-y-6'>
        <div className='flex flex-col gap-[6.15vh] w-full mb-10'>
          <label className='relative w-full border border-[#E5E5EC] transition has-[:checked]:border-primary rounded-md p-4 flex flex-row justify-start items-center gap-[6.4vw]'>
            <div className='bg-[#7273F933] w-[16vw] h-[16vw] p-[2.6vw] rounded-full flex justify-center items-center'>
              <img
                src='/assets/images/v2/rental.svg'
                alt='렌트카아이콘'
                className='object-contain w-[80%] h-[80%]'
              />
            </div>
            <div className='text-[#505050]'>
              <p>
                금융 카매니저
                <br />
                <strong className='inline-block mt-[12px] text-[#111111] text-[18px] font-[600] mr-[5px]'>
                  리스 및 장기렌트
                </strong>
              </p>
            </div>
            <input
              type='radio'
              name='car'
              value='leaseRent'
              checked={radio === 'leaseRent'}
              className='hidden'
              onChange={(e) => setRadio(e.target.value)}
            />
            <span className='text-transparent indent-[-9999px] absolute top-[-25px] right-[6.6vw]'>
              <img
                src={'/assets/images/import/carmanagerLeaseRent.svg'}
                alt='파란 유니폼 아이콘'
              />
            </span>
          </label>
          <label className='relative w-full border border-[#E5E5EC] transition has-[:checked]:border-primary rounded-md p-4 flex flex-row justify-start items-center gap-[6.4vw]'>
            <div className='bg-[#CFEDD3] w-[16vw] h-[16vw] p-[2.6vw] rounded-full flex justify-center items-center'>
              <img
                src='/assets/images/v2/sellCar.svg'
                alt='렌트카아이콘'
                className='object-contain w-[80%] h-[80%]'
              />
            </div>
            <div className='text-[#505050]'>
              <p>
                카매니저
                <br />
                <strong className='inline-block mt-[12px] text-[#111111] text-[18px] font-[600] mr-[5px]'>
                  중고차
                </strong>
              </p>
            </div>
            <input
              type='radio'
              name='car'
              checked={radio === 'usedcar'}
              value='usedcar'
              className='hidden'
              onChange={(e) => setRadio(e.target.value)}
            />
            <span className='text-transparent indent-[-9999px] absolute top-[-25px] right-[6.6vw]'>
              <img
                src={'/assets/images/import/carmanagerUsedCar.svg'}
                alt='녹색 유니폼 아이콘'
              />
            </span>
          </label>
          <label className='relative w-full border border-[#E5E5EC] transition has-[:checked]:border-primary rounded-md p-4 flex flex-row justify-start items-center gap-[6.4vw]'>
            <div className='bg-[#FFD14233] w-[16vw] h-[16vw] p-[2.6vw] rounded-full flex justify-center items-center'>
              <img
                src='/assets/images/v2/newCar.svg'
                alt=''
                className='object-contain w-[80%] h-[80%]'
              />
            </div>
            <div className='text-[#505050]'>
              <p>
                카매니저
                <br />
                <strong className='inline-block mt-[12px] text-[#111111] text-[18px] font-[600] mr-[5px]'>
                  신차
                </strong>
              </p>
            </div>
            <input
              type='radio'
              name='car'
              value='newcar'
              checked={radio === 'newcar'}
              className='hidden'
              onChange={(e) => setRadio(e.target.value)}
            />
            <span className='text-transparent indent-[-9999px] absolute top-[-25px] right-[6.6vw]'>
              <img
                src={'/assets/images/import/carmanagerNewCar.svg'}
                alt='노란 유니폼 아이콘'
              />
            </span>
          </label>
        </div>
      </div>
      <Button
        className='mt-auto'
        disabled={!radio}
        onClick={() => {
          if (!radio) return;
          setSignupStore({
            dealer: {
              type: radio as MemberDealerType,
            },
          });
          navigate('/auth/signup/dealer/phoneCheck');
        }}
      >
        다음
      </Button>
      {/* 이용 방법 및 거래 주의 사항 팝업 */}
      <Dialog
        open={isWarningPopupOpen}
        onOpenChange={() => setWarningPopupOpen(!isWarningPopupOpen)}
      >
        <DialogContent className='w-[311px] gap-0 border-0 p-0'>
          <div className='flex h-14 items-center justify-center text-center text-xl font-semibold'>
            이용 방법 및 주의 사항
          </div>
          <div className='px-4 pt-2 pb-6 flex flex-col justify-start items-start text-[12px] max-h-[70vh] overflow-y-auto'>
            {/* 이용 안내 */}
            <div className='mb-7 pb-6 border-b-[1px] border-[#F1F1F5]'>
              <h4 className='text-[15px] font-medium mb-1'>이용 안내</h4>
              <ul className='pt-2 text-[13px] list-disc pl-5'>
                <li className='mb-2'>
                  고객님의 견적은 푸시, 알림함, 견적함에서 확인하신 후 참여하실
                  수 있습니다.
                </li>
                <li className='mb-2'>
                  로그아웃하거나 설정에서 푸시 알림을 꺼두시면 고객 견적 요청
                  푸시 알림이 발송되지 않으니, 이 점 유의해 주세요.
                </li>
                <li className='mb-2'>
                  푸시 수신에 동의하셨음에도 계속 알림이 확인되지 않으신다면,
                  고객센터 이메일(info@carsayo.net)로 문의 부탁드립니다.
                </li>
              </ul>
            </div>
            {/* 이용료 안내 */}
            <div className='mb-7 pb-6 border-b-[1px] border-[#F1F1F5]'>
              <h4 className='text-[15px] font-medium mb-1'>이용료 안내</h4>
              <ul className='pt-2 text-[13px] list-disc pl-5'>
                <li className='mb-2'>
                  카사요는 가입비나 월 이용료가 없습니다. 고객 계약이 성사될
                  때에만 계약 체결 이용료(수수료)를 받고 있습니다.
                </li>
                <li className='mb-2'>
                  계약이 완료된 후 즉시(24시간) 계약서를 업로드하시고 카사요(주)
                  계좌로 계약 정보 제공 수수료를 카사요로 입금해 주세요.(차량
                  세금계산서 기준)
                </li>
                <li className='mb-2'>
                  계약이 취소되었을 경우에는 계약 체결 이용료(수수료)를 환불해
                  드립니다. 증빙 자료 및 본인 명의의 계좌번호를 고객센터
                  이메일(info@carsayo.net)로 보내주시면 검토 후 순차적으로
                  환불해 드립니다.
                </li>
              </ul>
            </div>
            {/* 이용 TIP */}
            <div>
              <h4 className='text-[15px] font-medium mb-1'>이용 TIP</h4>
              <ul className='pt-2 text-[13px] list-disc pl-5'>
                <li className='mb-2'>
                  취급 차량(국내차, 수입차)과 지역 설정을 정확하게 해주세요.
                  푸시 알람이 선택하신 차량 브랜드와 지역에 맞춰 발송됩니다.
                </li>
                <li className='mb-2'>
                  견적서에는 차량 할인, 서비스·현금 지원, 금리, 견적 설명 등
                  상세히 적어 보내주세요.
                </li>
                <li className='mb-2'>
                  카 매니저님들을 보호하기 위해 고객님들께는 닉네임으로만
                  보입니다. 낙찰 전 개인 정보가 노출되지 않도록 주의해 주세요.
                </li>
                <li className='mb-2'>
                  최종 낙찰된 카 매니저님에게만 고객님의 연락처가 공개됩니다.
                  확인된 고객님의 연락처로 먼저 연락하시어 계약 상담을 진행해
                  주세요.
                </li>
              </ul>
            </div>
          </div>
          <DialogFooter className='w-full flex flex-row justify-stretch items-center'>
            <Button
              className='h-[48px] rounded-none border-none  bg-[#111111]'
              onClick={() => setWarningPopupOpen(!isWarningPopupOpen)}
            >
              확인
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </div>
  );
}
