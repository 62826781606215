import { useLayoutEffect, useState } from 'react';
import useMemberStore from 'store/useMemberStore';
import useSystemStore from 'store/useSystemStore';
import CustomerMainPage from './customer';
import Old_DealerMainPage from './deprecated/dealer';
import DealerMainPage from './dealer';
import { setFcmToken } from 'apis/authentication';

export default function MainPage() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();

  const { loginMember } = useMemberStore();

  // Temp
  const [flag, setFlag] = useState<boolean>(false);
  // 개발용 모드 여부
  // 로컬, dev.fe.carsayo.net일 때
  const isDevelopmentORlocal = process.env.REACT_APP_NODE_ENV !== 'production';

  useLayoutEffect(() => {
    if (!loginMember?.type) return;

    setFcmToken();

    if (loginMember.type === 'dealer') {
      if (isDevelopmentORlocal) {
        setTopTabbarState({
          mode: 'main',
          state: 'fixed',
        });
        setIsBottomTabbar(true);
        return;
      }

      setTopTabbarState({
        mode: 'mainType_2',
        state: 'fixed',
      });
      setIsBottomTabbar(false);
      return;
    } else {
      setTopTabbarState({
        mode: 'main',
        state: 'fixed',
      });
      setIsBottomTabbar(true);
    }
  }, [loginMember?.type]);

  if (!loginMember) return null;

  if (loginMember.type === 'customer') return <CustomerMainPage />;
  else if (loginMember.type === 'dealer') {
    // Temp
    if (flag === false && isDevelopmentORlocal)
      return <DealerMainPage setFlag={setFlag} />;
    else return <Old_DealerMainPage />;
  } else return <CustomerMainPage />;
}
