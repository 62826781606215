/* eslint-disable @typescript-eslint/no-unused-vars */
import { DealerOrderListOptionDTO } from '@carsayo/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getManagerQuoteListApi } from 'apis/quote';
import { ChevronDown } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { cn } from 'utils';
import { CircularProgress } from '@mui/material';
import { DealerMainpageOrderTake } from 'pages/main/dealer/constants';
import OrderCard from 'pages/main/dealer/components/orderCard';
import NoBid from 'pages/main/dealer/image/Nobid';
import useQueryState from 'hooks/useQueryState';
import usePullToRefresh from 'hooks/usePullToRefresh';
import { MyOrderListTab } from 'pages/main/dealer/components/myOrderList';

export default function DealerQuoteContent() {
  const [tab, setTab] = useQueryState<keyof typeof MyOrderListTab>(
    'tab_myOrder',
    'my_whole',
    'string',
  );
  const searchOption: DealerOrderListOptionDTO = useMemo(() => {
    return MyOrderListTab[tab].searchOption;
  }, [tab]);

  const {
    data: orderList,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['orderList', tab],
    queryFn: (params) =>
      getManagerQuoteListApi({
        take: DealerMainpageOrderTake,
        skip: params.pageParam * DealerMainpageOrderTake,
        option: searchOption,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages.length;
      const currentItemCount = allPages
        .map((el) => {
          return el.data.length;
        })
        .reduce((el1, el2) => {
          return el1 + el2;
        });

      if (lastPage.totalCount <= currentItemCount) return null;
      return nextPage;
    },
    gcTime: 60 * 1000, // 1min
    staleTime: 20 * 1000, // 20sec
  });

  const showingOrderList = useMemo(() => {
    return (
      orderList?.pages
        .flat()
        .map((el) => el.data)
        .flat() ?? []
    );
  }, [orderList]);

  const [isRefetching, setIsRefetching] = useState(false);
  const mainPageRef = useRef<HTMLDivElement>(null);
  usePullToRefresh(mainPageRef, async () => {
    setIsRefetching(true);
    await refetch();
    await new Promise((resolve) => setTimeout(resolve, 300));
    setIsRefetching(false);
  });

  const tabSelecteRef = useRef<{
    [key: string]: HTMLDivElement | HTMLButtonElement | null;
  }>({});

  useEffect(() => {
    // 탭 선택시 스크롤 포커스
    tabSelecteRef.current[tab]?.scrollIntoView({
      inline: 'center',
      block: 'nearest',
      behavior: 'smooth',
    });
  }, [tab]);

  return (
    <>
      {isRefetching && (
        <div className='fixed top-0 left-0 w-full h-full text-white bg-black/40 z-[100] flex items-center justify-center'>
          <CircularProgress size={32} color='inherit' />
        </div>
      )}
      <div ref={mainPageRef}>
        {/* 탭 선택 */}
        <div className='sticky top-0 bg-[#F3F6F9] z-10 py-5 scroll-hidden overflow-x-scroll px-5 flex items-center gap-1.5'>
          {Object.entries(MyOrderListTab).map(([key, value]) => {
            return (
              <button
                key={key}
                onClick={() => {
                  setTab(key as keyof typeof MyOrderListTab);
                }}
                ref={(el) => (tabSelecteRef.current[key] = el)}
                className={cn(
                  'shrink-0 flex gap-1 items-center justify-center rounded-full border py-2.5 px-3.5',
                  tab === key
                    ? 'border-[#7273F9] bg-[#E6E6F6]'
                    : 'bg-white border-white',
                )}
              >
                <span
                  className={cn(
                    tab === key
                      ? 'text-[#494AF1] text-[14px] font-semibold leading-none tracking-[-0.28px]'
                      : 'text-[#222] text-[14px] font-normal leading-none tracking-[-0.28px]',
                  )}
                >
                  {value.title}
                </span>

                {tab === key && orderList && (
                  <div className='text-[#494AF1] text-[14px] font-semibold leading-none tracking-[-0.28px]'>
                    {orderList?.pages[0].totalCount}
                  </div>
                )}
              </button>
            );
          })}
        </div>

        <div className='px-5 space-y-4'>
          {/* 카드 영역 */}
          {showingOrderList.map((el) => {
            return (
              <OrderCard key={el.id} order={el} refresh={() => refetch()} />
            );
          })}
          {showingOrderList.length === 0 && (
            <div className='flex items-center justify-center h-[240px] bg-[#E5EAEE] rounded-[16px]'>
              {/* 로딩중일 때 */}
              {isFetching && (
                <div>
                  <CircularProgress size={24} />
                </div>
              )}

              {/* 데이터가 없을 때 */}
              {!isFetching && (
                <div className='flex flex-col items-center gap-6'>
                  <div className='flex items-center justify-center w-[80px] h-[80px] rounded-full bg-white'>
                    <NoBid />
                  </div>
                  <div className='text-[#A1A7AC] text-center text-[18px] font-semibold leading-[27px] tracking-[-0.36px]'>
                    견적 요청 내역이 없어요
                  </div>
                </div>
              )}
            </div>
          )}

          {/* 더보기 버튼 (페이징 추가검색) */}
          {orderList && hasNextPage && (
            <button
              onClick={() => {
                fetchNextPage();
              }}
              disabled={isFetchingNextPage}
              className='w-full h-[54px] rounded-[8px] bg-white py-5 flex items-center justify-center gap-1.5'
            >
              {isFetchingNextPage ? (
                <CircularProgress size={20} />
              ) : (
                <>
                  <span className='text-[#222] text-[16px] font-medium leading-[16px] tracking-[-0.32px]'>
                    더보기 ({orderList.pages.length}/
                    {Math.ceil(
                      orderList.pages[0].totalCount / DealerMainpageOrderTake,
                    )}
                    )
                  </span>
                  <ChevronDown color='#666' strokeWidth={1.5} />
                </>
              )}
            </button>
          )}
        </div>
      </div>
    </>
  );
}
