import React, { useEffect } from 'react';

function usePullToRefresh(
  ref: React.RefObject<HTMLDivElement>,
  onTrigger: () => void | Promise<void>,
) {
  useEffect(() => {
    const el = ref.current;
    if (!el) return;

    // attach the event listener
    el.addEventListener('touchstart', handleTouchStart);

    let lastMoveTime = 0;

    function handleTouchStart(startEvent: TouchEvent) {
      const el = ref.current;
      if (!el) return;

      // ✅ 하위에 #subPage가 있다면 Pull-to-Refresh 비활성화
      if (el.querySelector('#subPage')) {
        return;
      }

      const now = Date.now();
      if (now - lastMoveTime < 16) return; // update at most 60fps (every 16ms)
      lastMoveTime = now;

      // get the initial Y position
      const initialY = startEvent.touches[0].clientY;

      const viewPointElement = document.querySelector('#viewPoint');
      if (!viewPointElement) return;
      if (viewPointElement.scrollTop > 10) return;

      el.addEventListener('touchmove', handleTouchMove);
      el.addEventListener('touchend', handleTouchEnd);

      const parentEl = el.parentNode as HTMLDivElement;
      const indicatorElement = createPullIndicator(parentEl);
      const childImg = indicatorElement.querySelector('.icon') as HTMLElement;

      function handleTouchMove(moveEvent: TouchEvent) {
        const el = ref.current;
        if (!el) return;

        // get the current Y position
        const currentY = moveEvent.touches[0].clientY;

        // get the difference
        let dy = currentY - initialY;

        if (dy < 0) return;
        if (dy > MaxHeight) dy = MaxHeight;

        if (dy === MaxHeight) return;

        setPullIndicator({
          el: indicatorElement,
          imgEl: childImg,
          transformY: dy,
        });
      }

      const MaxHeight = 200;
      const TRIGGER_THRESHOLD = 100;

      function createPullIndicator(el: HTMLDivElement) {
        const pullIndicator = document.createElement('div') as HTMLDivElement;
        pullIndicator.className = 'pull-indicator';
        pullIndicator.innerHTML =
          '<div><img class="icon" src="/assets/images/v2/Refresh.svg"></div>';
        el.appendChild(pullIndicator);

        return pullIndicator;
      }

      function setPullIndicator(params: {
        el: HTMLDivElement;
        imgEl: HTMLElement;
        transformY: number;
      }) {
        const percentage = params.transformY / MaxHeight;

        // Batch the style updates to avoid layout thrashing
        params.el.style.cssText = `
          opacity: ${percentage};
          transform: translateX(-50%) translateY(${params.transformY}px);
        `;

        // Use rotation transform for smoother rotation
        params.imgEl.style.transform = `rotate(${320 * percentage}deg)`;
      }

      // function setPullIndicator(params: {
      //   el: HTMLDivElement;
      //   imgEl: HTMLElement;
      //   transformY: number;
      // }) {
      //   const percentage = params.transformY / MaxHeight;

      //   params.el.style.opacity = `${percentage}`;
      //   params.el.style.transform = `translateX(-50%) translateY(${params.transformY}px)`;

      //   params.imgEl.style.rotate = `${320 * percentage}deg`;
      // }

      function removePullIndicator(el: HTMLDivElement) {
        const pullIndicator = el.querySelector('.pull-indicator');
        if (pullIndicator) {
          pullIndicator.remove();
        }
      }

      function handleTouchEnd(endEvent: TouchEvent) {
        const el = ref.current;
        if (!el) return;

        // return the element to its initial position
        el.style.transform = 'translateY(0)';
        removePullIndicator(el.parentNode as HTMLDivElement);

        // add transition
        el.style.transition = 'transform 0.2s';

        // run the callback
        const y = endEvent.changedTouches[0].clientY;
        const dy = y - initialY;
        if (dy > TRIGGER_THRESHOLD) {
          onTrigger();
        }

        // listen for transition end event
        el.addEventListener('transitionend', onTransitionEnd);

        // cleanup
        el.removeEventListener('touchmove', handleTouchMove);
        el.removeEventListener('touchend', handleTouchEnd);
      }

      function onTransitionEnd() {
        const el = ref.current;
        if (!el) return;

        // 🔥 transform / transition 스타일 완전히 제거
        el.style.transition = '';
        el.style.transform = '';

        // cleanup
        el.removeEventListener('transitionend', onTransitionEnd);
      }
    }

    return () => {
      // let's not forget to cleanup
      el.removeEventListener('touchstart', handleTouchStart);
    };
  }, [ref.current]);
}

export default usePullToRefresh;
