import { DealerOrderListOptionDTO } from '@carsayo/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getManagerQuoteListApi } from 'apis/quote';
import { ChevronDown, ChevronRight } from 'lucide-react';
import { useEffect, useMemo, useRef, useState } from 'react';
import useMemberStore from 'store/useMemberStore';
import { DealerMainpageOrderTake } from '../constants';
import RefreshButton from '../ui/refreshButton';
import { cn } from 'utils';
import { CircularProgress } from '@mui/material';
import OrderCard from './orderCard';
import NoOrder from '../image/NoOrder';
import useQueryState from 'hooks/useQueryState';
import { useNavigate } from 'react-router-dom';

export const MyOrderListTab: Record<
  | 'my_whole'
  | 'my_bidding'
  | 'my_progressing'
  | 'my_finished'
  | 'my_holded'
  | 'my_canceled'
  | 'my_failBid',
  {
    title: string;
    searchOption: DealerOrderListOptionDTO;
  }
> = {
  my_whole: {
    title: '전체',
    searchOption: {
      isBidding: true,
    },
  },
  my_bidding: {
    title: '입찰 중',
    searchOption: {
      orderStateList: ['submitted'],
      isBidding: true,
    },
  },
  my_progressing: {
    title: '진행 중',
    searchOption: {
      orderStateList: ['progressing'],
      isAccepted: true,
    },
  },
  my_finished: {
    title: '계약 완료',
    searchOption: {
      orderStateList: ['finished'],
      isAccepted: true,
    },
  },
  my_holded: {
    title: '보류',
    searchOption: {
      orderStateList: ['holded'],
      isAccepted: true,
    },
  },
  my_canceled: {
    title: '취소',
    searchOption: {
      orderStateList: ['canceled'],
      isAccepted: true,
    },
  },
  my_failBid: {
    title: '유찰',
    searchOption: {
      orderStateList: ['failBid'],
    },
  },
};

export default function DealerMyOrderList() {
  const { loginMember } = useMemberStore();
  const navigate = useNavigate();

  if (!loginMember) return null;

  const [tab, setTab] = useQueryState<keyof typeof MyOrderListTab>(
    'tab_myOrder',
    'my_whole',
    'string',
  );
  const searchOption: DealerOrderListOptionDTO = useMemo(() => {
    return MyOrderListTab[tab].searchOption;
  }, [tab]);

  // 새로고침 버튼 클릭시에만 새로고침 버튼이 회전하도록
  const [isClickRefresh, setIsClickRefresh] = useState(false);

  const {
    data: orderList,
    isFetching,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    refetch,
  } = useInfiniteQuery({
    queryKey: ['orderList', tab],
    queryFn: (params) =>
      getManagerQuoteListApi({
        take: DealerMainpageOrderTake,
        skip: params.pageParam * DealerMainpageOrderTake,
        option: searchOption,
      }),
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      const nextPage = allPages.length;
      const currentItemCount = allPages
        .map((el) => {
          return el.data.length;
        })
        .reduce((el1, el2) => {
          return el1 + el2;
        });

      if (lastPage.totalCount <= currentItemCount) return null;
      return nextPage;
    },
    gcTime: 60 * 1000, // 1min
    staleTime: 20 * 1000, // 20sec
  });

  // setIsClickRefresh 초기화
  useEffect(() => {
    setIsClickRefresh(false);
  }, [orderList]);

  const showingOrderList = useMemo(() => {
    return (
      orderList?.pages
        .flat()
        .map((el) => el.data)
        .flat() ?? []
    );
  }, [orderList]);

  const tabSelecteRef = useRef<{
    [key: string]: HTMLDivElement | HTMLButtonElement | null;
  }>({});

  return (
    <div className='space-y-4'>
      {/* 제목 영역 */}
      <div className='px-5 flex justify-between items-center'>
        <div className='flex items-center gap-2'>
          <div className='text-[#222] text-[18px] font-semibold leading-[27px] tracking-[-0.36px]'>
            내 견적함
          </div>
          <RefreshButton
            onLoading={isClickRefresh && isFetching}
            onClick={() => {
              setIsClickRefresh(true);
              refetch();
            }}
          />
        </div>
        <button
          onClick={() => {
            navigate('/dealer/quote');
          }}
        >
          <ChevronRight width={24} height={24} color='#666' strokeWidth={1.5} />
        </button>
      </div>

      {/* 탭 선택 */}
      <div className='scroll-hidden overflow-x-scroll px-5 flex items-center gap-1.5 pb-1'>
        {Object.entries(MyOrderListTab).map(([key, value]) => {
          return (
            <button
              key={key}
              onClick={() => {
                // 탭 선택시 스크롤 포커스
                tabSelecteRef.current[key]?.scrollIntoView({
                  inline: 'center',
                  block: 'nearest',
                  behavior: 'smooth',
                });
                setTab(key as keyof typeof MyOrderListTab);
              }}
              ref={(el) => (tabSelecteRef.current[key] = el)}
              className={cn(
                'shrink-0 flex gap-1 items-center justify-center rounded-full border py-2.5 px-3.5',
                tab === key
                  ? 'border-[#7273F9] bg-[#E6E6F6]'
                  : 'bg-white border-white',
              )}
            >
              <span
                className={cn(
                  tab === key
                    ? 'text-[#494AF1] text-[14px] font-semibold leading-none tracking-[-0.28px]'
                    : 'text-[#222] text-[14px] font-normal leading-none tracking-[-0.28px]',
                )}
              >
                {value.title}
              </span>

              {tab === key && orderList && (
                <div className='text-[#494AF1] text-[14px] font-semibold leading-none tracking-[-0.28px]'>
                  {orderList?.pages[0].totalCount}
                </div>
              )}
            </button>
          );
        })}
      </div>

      <div className='px-5 space-y-4'>
        {/* 카드 영역 */}
        {showingOrderList.map((el) => {
          return (
            <OrderCard
              key={el.id}
              order={el}
              disableAction={false}
              refresh={() => refetch()}
            />
          );
        })}
        {showingOrderList.length === 0 && (
          <div className='flex items-center justify-center h-[240px] bg-[#E5EAEE] rounded-[16px]'>
            {/* 로딩중일 때 */}
            {isFetching && (
              <div>
                <CircularProgress size={24} />
              </div>
            )}

            {/* 데이터가 없을 때 */}
            {!isFetching && (
              <div className='flex flex-col items-center gap-6'>
                <div className='flex items-center justify-center w-[80px] h-[80px] rounded-full bg-white'>
                  <NoOrder />
                </div>
                <div className='text-[#A1A7AC] text-center text-[18px] font-semibold leading-[27px] tracking-[-0.36px]'>
                  견적 내역이 없어요
                </div>
              </div>
            )}
          </div>
        )}
        {/* 더보기 버튼 (페이징 추가검색) */}
        {orderList && hasNextPage && (
          <button
            onClick={() => {
              fetchNextPage();
            }}
            disabled={isFetchingNextPage}
            className='w-full h-[54px] rounded-[8px] bg-white py-5 flex items-center justify-center gap-1.5'
          >
            {isFetchingNextPage ? (
              <CircularProgress size={20} />
            ) : (
              <>
                <span className='text-[#222] text-[16px] font-medium leading-[16px] tracking-[-0.32px]'>
                  더보기 ({orderList.pages.length}/
                  {Math.ceil(
                    orderList.pages[0].totalCount / DealerMainpageOrderTake,
                  )}
                  )
                </span>
                <ChevronDown color='#666' strokeWidth={1.5} />
              </>
            )}
          </button>
        )}
      </div>
    </div>
  );
}
