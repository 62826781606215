import { SellingOrderDTO } from '@carsayo/types';
import { Dispatch, SetStateAction } from 'react';
import { useNavigate } from 'react-router-dom';
import { OrderSellingForm } from '../../../form';
import { OrderSellingTab } from '../../../hooks/useTabHandler';
import ProgressItem from '../../ProgressItem';

const OrderSelling_process = ({
  sellingOrderDTOList,
  setSellingOrderData,
  form,
}: {
  sellingOrderDTOList: SellingOrderDTO[];
  setSellingOrderData: Dispatch<SetStateAction<SellingOrderDTO>>;
  form: OrderSellingForm;
}) => {
  const navigate = useNavigate();

  const getTabKeyByProgress = (
    progressValue: number,
  ): OrderSellingTab | undefined => {
    for (const [key, value] of Object.entries(OrderSellingTab)) {
      if (progressValue === 10) {
        return 'drivenDistance';
      }
      if (value.progress === progressValue) {
        return key as OrderSellingTab;
      }
    }
    return 'drivenDistance';
  };
  return (
    <div className='flex flex-col gap-6 px-5'>
      <div className='font-[600] text-[20px] leading-[160%]'>
        진행 중인 주문을 선택해 주세요.
      </div>
      <div className='flex flex-col gap-4'>
        {sellingOrderDTOList.map((order, index) => (
          <div
            className='flex flex-col gap-4'
            key={`order_${index}`}
            onClick={() => {
              setSellingOrderData(order);

              form.reset({
                progress: order.orderData.progress || 0,
                currentStep: order.orderData.currentStep || 1,
                drivenDistance: order.orderData.drivenDistance || 0,
                sellRegionId: order.orderData.sellRegionId,
                boughtMethod: order.orderData.boughtMethod,
                additionalRequest: order.orderData.additionalRequest,
                additionalInfo: order.orderData.additionalInfo,
                file_1_id: order.orderData.file.file_1?.id || null,
                file_2_id: order.orderData.file.file_2?.id || null,
                file_3_id: order.orderData.file.file_3?.id || null,
                file_4_id: order.orderData.file.file_4?.id || null,
                file_5_id: order.orderData.file.file_5?.id || null,
                file_6_id: order.orderData.file.file_6?.id || null,
              });

              const tabName = getTabKeyByProgress(order.orderData.progress);

              navigate(`/order/selling?tab=${tabName}`);
            }}
          >
            <ProgressItem
              info={order.orderData as SellingOrderDTO['orderData']}
            />
          </div>
        ))}
      </div>
    </div>
  );
};

export default OrderSelling_process;
