/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react';
import { SelectColorProps } from '../type';
import { ChevronDown, X } from 'lucide-react';
import { motion } from 'framer-motion';
import ColorSquare from './ColorSquare';
import SelectInternalSelectionDrawer from '../drawer/SelectInternalSelectionDrawer';
import { useNavigate } from 'react-router-dom';

export default function SelectInternalSelection({
  form,
  wholeSellingCar,
  selectedExteriorColor,
  selectedInteriorColor,
  sortedCarColor,
  cachedCarOptions,
}: SelectColorProps) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const selectedItem = form.getValues('interiorCarColor.internalSelection');

  /** Drawer 안드로이드 백버튼으로 닫히도록 */
  useEffect(() => {
    if (!window.native) return;

    if (isOpen) {
      window.native.onBackPressed = () => {
        setIsOpen(false);
      };
    } else {
      window.native.onBackPressed = () => {
        navigate(-1);
      };
    }

    return () => {
      if (window.native) {
        window.native.onBackPressed = () => {
          navigate(-1);
        };
      }
    };
  }, [isOpen]);

  return (
    <>
      <div>
        {/* 색상 선택 Drawer 여는 영역 */}
        <div
          className='cursor-pointer w-full flex h-[74px] gap-4 justify-between border border-[#E5E5EC] rounded-[8px] items-center pl-4 py-4 pr-[12px] text-sm text-[#555555]'
          onClick={() => {
            setIsOpen(true);
          }}
        >
          <div className='flex items-center gap-4'>
            <ColorSquare imageUrl={selectedItem?.imageUrl ?? undefined} />
            <div className='flex flex-col gap-1.5 h-[44px] items-start justify-center'>
              <div className='text-[#222] text-[16px] font-semibold leading-[18px] tracking-[-0.32px] text-left'>
                {selectedItem?.name ?? '미선택'}
              </div>
              {selectedItem && selectedItem?.price !== 0 && (
                <div className='text-[#222] text-[14px] font-medium leading-[14px] tracking-[-0.28px]'>
                  {`${selectedItem.price > 0 ? '+' : ''}${selectedItem.price.toLocaleString()}`}
                  원
                </div>
              )}
            </div>
          </div>

          <motion.div
            animate={{ rotate: isOpen ? 180 : 0 }}
            transition={{ duration: 0.3, ease: 'easeInOut' }}
            className='ml-auto'
          >
            <ChevronDown
              width={24}
              height={24}
              strokeWidth={1.5}
              color={'#666666'}
              className='ml-auto'
            />
          </motion.div>
        </div>
      </div>

      <SelectInternalSelectionDrawer
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        form={form}
        wholeSellingCar={wholeSellingCar}
        selectedExteriorColor={selectedExteriorColor}
        selectedInteriorColor={selectedInteriorColor}
        sortedCarColor={sortedCarColor}
        cachedCarOptions={cachedCarOptions}
      />
    </>
  );
}
