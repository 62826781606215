import { PostList } from '@carsayo/types';
import { useInfiniteQuery } from '@tanstack/react-query';
import { getPostList } from 'apis/board';
import { getReviewList, ReviewListProps, ReviewProps } from 'apis/review';

const defaultTake = 10;

export const useGetPostList = (type: 'notice' | 'faq') => {
  return useInfiniteQuery({
    queryKey: [`${type}List`],
    queryFn: async ({ pageParam = 1 }) => {
      const searchedPostList: PostList = await getPostList({
        boardKey: type,
        skip: pageParam ? pageParam * defaultTake : 0,
        take: defaultTake,
        option: {},
        search: null,
      });

      return searchedPostList;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      const currentItemCount = allPages.reduce(
        (sum, page) => sum + page.searchedPost.length,
        0,
      );

      if (
        currentItemCount <
        lastPage.totalCount - lastPage.importantPost.length
      ) {
        return allPages.length;
      }

      return null;
    },
    placeholderData: (previousData, previousQuery) => previousData,
    gcTime: 60 * 1000,
    staleTime: 20 * 1000,
  });
};

export const useGetReviewList = ({
  status,
  filter,
  skip,
  take,
}: ReviewProps) => {
  return useInfiniteQuery({
    queryKey: ['reviewList', status, filter, skip, take],
    queryFn: async ({ pageParam = 1 }) => {
      const reviewList: ReviewListProps = await getReviewList({
        status,
        filter,
        skip: pageParam * (take || defaultTake),
        take: take || defaultTake,
      });

      return reviewList;
    },
    initialPageParam: 0,
    getNextPageParam: (lastPage, allPages) => {
      const totalFetchedItems = allPages.flatMap((page) => page.orders).length;
      if (totalFetchedItems < lastPage.totalCnt) {
        return allPages.length;
      }

      return undefined;
    },
    placeholderData: (previousData, previousQuery) => previousData,
    gcTime: 60 * 1000,
    staleTime: 20 * 1000,
  });
};
