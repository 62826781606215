/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { useQuery } from '@tanstack/react-query';
import { getCustomerQuoteDetailApi } from 'apis/quote';
import { cn } from 'utils';
import TopInfo from './components/detail/TopInfo';
import QutoesTab from './components/detail/tab/quotes';
import {
  CustomerOrderDetail,
  PurchaseCarInfo,
  PurchaseOrderDetailInfo,
} from '@carsayo/types';
import CarInfoTab from './components/detail/tab/info';
import ProcessTab from './components/detail/tab/process';
import CarPriceTab from './components/detail/tab/price';
import InsuranceHistTab from './components/detail/tab/history';
import NewCarTopInfo from './components/detail/NewCarTopInfo';
import { PurchaseCarTable } from 'pages/v3/orderDetailTemp/components/info/components/content/purchaseCarInfo';
import PurchaseTab from './components/detail/tab/purchase';
import RequestTab from './components/detail/tab/purchase/request';
import useOnResumeStore from 'store/useOnResumeStore';
import useQueryState from 'hooks/useQueryState';

const renderTabComponent = (
  activeTab: string | null,
  quoteDetail: CustomerOrderDetail,
  refetch: () => void,
  setActiveTab: (activeTab: string | null) => void,
) => {
  switch (activeTab) {
    case '받은 견적':
      return <QutoesTab quoteDetail={quoteDetail} refetch={refetch} />;
    case '견적 진행':
      return <ProcessTab quoteDetail={quoteDetail} />;
    case '차량 정보':
      return (
        <CarInfoTab quoteDetail={quoteDetail} setActiveTab={setActiveTab} />
      );
    case '시세 조회':
      return <CarPriceTab quoteDetail={quoteDetail} />;
    case '보험 이력':
      return <InsuranceHistTab quoteDetail={quoteDetail} />;

    case '모델 정보':
      return (
        <div className='w-full'>
          <PurchaseCarTable
            purchaseCarInfo={
              quoteDetail.purchase?.selectedCar as PurchaseCarInfo
            }
          />
        </div>
      );

    case '구매 정보':
      return (
        <PurchaseTab
          purchaseOrderDetailInfo={
            quoteDetail.purchase as PurchaseOrderDetailInfo
          }
        />
      );

    case '추가 요청':
      return (
        <RequestTab
          purchaseOrderDetailInfo={
            quoteDetail.purchase as PurchaseOrderDetailInfo
          }
        />
      );
    default:
      return null;
  }
};

const tabs = [
  '받은 견적',
  '견적 진행',
  '차량 정보',
  '시세 조회',
  '보험 이력',
  '모델 정보',
  '구매 정보',
  '추가 요청',
];

const CustomerQuoteDetail = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const active = searchParams.get('active');

  const { setTopTabbarState, setIsBottomTabbar, setLoadingDim } =
    useSystemStore();
  const { setOnResume } = useOnResumeStore();

  const [activeTab, setActiveTab] = useQueryState<string | null>(
    'active',
    active || null,
    'string',
  );
  // const [activeTab, setActiveTab] = useState<string | null>(active || null);

  const {
    data: quoteDetail,
    refetch,
    isFetching,
  } = useQuery({
    queryKey: ['quoteDetail', id],
    queryFn: () => getCustomerQuoteDetailApi(id!),
    enabled: !!id,
    staleTime: 1000 * 60 * 10,
    gcTime: 1000 * 60 * 10,
  });

  useEffect(() => {
    refetch();

    // 푸시나 앱 새로고침 시 데이터 재조회
    setOnResume(async () => {
      refetch();
    });
  }, []);

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
  }, []);

  const tabs = [
    ['submitted', 'nonAccepted'].includes(quoteDetail?.state as string)
      ? '받은 견적'
      : '견적 진행',
    '차량 정보',
    '시세 조회',
    '보험 이력',
  ];

  const newTabs = [
    ['submitted', 'nonAccepted'].includes(quoteDetail?.state as string)
      ? '받은 견적'
      : '견적 진행',
    '모델 정보',
    '구매 정보',
    '추가 요청',
  ];

  const activeIndex = (
    quoteDetail?.type !== 'sellCar' ? newTabs : tabs
  ).indexOf(activeTab!);

  useEffect(() => {
    console.info('quoteDetail : ', quoteDetail);

    if (active || !quoteDetail) return;

    setActiveTab(
      ['submitted', 'nonAccepted'].includes(quoteDetail?.state)
        ? '받은 견적'
        : '견적 진행',
    );
  }, [quoteDetail]);

  if (isFetching) return;
  return (
    <>
      <div className='py-5 h-full flex flex-col'>
        {/* 상단 정보 */}
        {quoteDetail && quoteDetail.type === 'sellCar' && (
          <TopInfo quoteDetail={quoteDetail} refetch={refetch} />
        )}
        {quoteDetail && quoteDetail.type !== 'sellCar' && (
          <NewCarTopInfo quoteDetail={quoteDetail} refetch={refetch} />
        )}

        <div className='bg-[#F3F6F9] h-2'></div>

        {/* 하위 상세 정보 */}
        <div className='flex-1 border-t-[8px] border-[#F3F6F9]'>
          <div className='grid grid-cols-4 w-full relative'>
            {(quoteDetail?.type !== 'sellCar' ? newTabs : tabs).map(
              (tab, index) => {
                return (
                  <div
                    key={tab}
                    className={cn(
                      'cursor-pointer flex text-center h-[50px] items-center justify-center text-[#A1A7AC] font-[500] text-[15px] leading-[100%]',
                      activeTab === tab && 'text-[#222222]',
                    )}
                    onClick={() => setActiveTab(tab)}
                  >
                    {tab}
                  </div>
                );
              },
            )}
            {activeIndex !== -1 && (
              <div
                className='absolute bottom-0 h-0.5 bg-black transition-all duration-300'
                style={{ width: '25%', left: `${activeIndex * 25}%` }}
              />
            )}
          </div>
          <div className='bg-[#F3F6F9] flex h-full min-h-[350px]'>
            {quoteDetail &&
              renderTabComponent(activeTab, quoteDetail, refetch, setActiveTab)}
          </div>
        </div>
      </div>
    </>
  );
};

export default CustomerQuoteDetail;
