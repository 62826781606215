import { cn } from 'utils';

const CarInfoItem = ({
  img,
  modelname,
  carName,
  type,
  setShowPrice,
}: {
  img?: string;
  modelname?: string;
  carName?: string;
  type?: string;
  setShowPrice?: (isShowPrice: boolean) => void;
}) => {
  return (
    <div
      className={cn('flex gap-4 relative', type === 'center' && 'items-center')}
    >
      <div className='bg-[#F3F6F9] rounded-[10px] py-[18px] px-[15px]'>
        <img
          src={img}
          alt='car'
          width={90}
          height={54}
          className='object-cover'
        />
      </div>
      <div className='flex flex-col gap-[2px]'>
        <div className='text-[#555555] font-[500] text-[14px] leading-[160%]'>
          {modelname}
        </div>
        <div className='font-[600] text-[16px] leading-[160%]'>{carName}</div>
      </div>
      {setShowPrice && (
        <button
          onClick={() => setShowPrice(true)}
          className='absolute right-1 bottom-0 rounded-[8px] border border-[#E5E5EC] p-2 font-[500] text-[12px] leading-[100%]'
        >
          예상 시세
        </button>
      )}
    </div>
  );
};

export default CarInfoItem;
