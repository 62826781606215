import React, { useEffect, useLayoutEffect, useState } from 'react';
import useSystemStore from 'store/useSystemStore';

import classNames from 'classnames';
// 스와이퍼관련

import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { useParams } from 'react-router-dom';
import { getCustomerQuoteDetailApi, selectManagerApi } from 'apis/quote';
import {
  convertDealerType,
  convertOrderStatus,
  dateToStringHHMM,
  dateToStringMMDD,
  slicePrice,
} from 'utils/convert';
import { parseOrderProcessState, parseSigunguId } from 'utils/typeConvert';
import Confirm from 'components/ui/confirm';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import Cancel from 'components/view/quotation/cancel';
import download from 'utils/download';
import ReactHtmlParser from 'react-html-parser';
import SellingCarEstimatePrice from 'pages/v2/selling/detail/price';
import useOnResumeStore from 'store/useOnResumeStore';
import { cn } from 'utils';
import { CustomerOrderDetail } from '@carsayo/types';
import { Customer_EstimateFile } from './_components/estimateFile';
import OrderInfo from '../components/orderInfo';
import CarPriceInfoDrawer from '../components/carPriceInfoDrawer';

const Star = ({ fill }: { fill: string }) => {
  return (
    <svg
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M6.14809 2.11375L7.02064 4.928H9.8424C9.99449 4.928 10.0585 5.13444 9.93445 5.22712L7.65303 6.96707L8.52557 9.78132C8.5736 9.93299 8.4055 10.0636 8.28142 9.96669L6 8.22674L3.71858 9.96669C3.5945 10.0594 3.42639 9.93299 3.47442 9.78132L4.34697 6.96707L2.06554 5.22712C1.94147 5.13444 2.00551 4.928 2.1576 4.928H4.97936L5.85191 2.11375C5.89994 1.96208 6.10006 1.96208 6.14809 2.11375Z'
        fill={fill}
        stroke={fill}
        strokeLinejoin='round'
      />
    </svg>
  );
};
const StarRating = ({ rating }: { rating: number }) => {
  return (
    <div className='flex'>
      {[...Array(5)].map((_, index) => (
        <Star key={index} fill={index < rating ? '#111111' : '#E5E5EC'} />
      ))}
    </div>
  );
};

const QuoteBoxDetail = () => {
  const { id } = useParams();
  const { setTopTabbarTitle, setTopTabbarState } = useSystemStore();
  const { setOnResume } = useOnResumeStore();
  const [isQuoteBoxOpen, setQuoteBoxOpen] = useState(true);
  const [isChoiceOpen, setChoiceOpen] = useState(false);
  const [selectManager, setSelectManager] = useState<string>();
  const [isCancel, setIsCancel] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [isExpectedOpen, setExpectedOpen] = useState(false);

  // 견적상세
  const [quoteDetail, setQuoteDetail] = useState<CustomerOrderDetail>();

  const handleVoidEvents = {
    getQuoteDetail: async () => {
      try {
        const result = await getCustomerQuoteDetailApi(id as string);
        if (result) {
          setQuoteDetail(result);
          console.info(result);
        }
      } catch (error: any) {
        console.error(error);
      }
    },
  };

  const handleClickEvents = {
    openTooltip: () => {
      setTooltipVisible(!isTooltipVisible);
    },
    quoteBoxOpen: () => {
      setQuoteBoxOpen(!isQuoteBoxOpen);
    },
    choiceManager: async () => {
      if (selectManager && id) {
        const reqData = {
          orderId: id,
          managerId: selectManager,
        };
        try {
          const result = await selectManagerApi(reqData);
          if (result) {
            CarsayoToast.text(`${result.manager?.name_nick}님이 선택됐습니다.`);
            setQuoteDetail(result);
            handleVoidEvents.getQuoteDetail();
          }
        } catch (error: any) {
          console.error(error);
        }
      }
    },
    fileOpen: (e: React.MouseEvent<HTMLDivElement>, url: string | null) => {
      if (url) {
        e.preventDefault();
        window.open(url, '_blank');
      } else {
        return;
      }
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: 'back',
    });
    setTopTabbarTitle('상세보기');
    if (id) {
      handleVoidEvents.getQuoteDetail();
    }
  }, []);

  useEffect(() => {
    setOnResume(async () => {
      try {
        const result = await getCustomerQuoteDetailApi(id as string);
        if (result) {
          setQuoteDetail(result);
          console.info(result);
        }
      } catch (error: any) {
        console.error(error);
      }
    });
  }, []);

  return (
    <>
      <div className='bg-white px-4'>
        {/* 상단 매니저 정보, 기본 정보 */}
        <div className='w-full pt-8'>
          {quoteDetail?.manager ? (
            <div className='w-full flex flex-row justify-between items-stretch border-b-[1px] border-[#E5E5EC] pb-4'>
              {/* 상단 낙찰 매니저 정보 */}
              <div className='w-full flex flex-row gap-6 justify-start items-stretch'>
                <img
                  src={
                    quoteDetail?.manager?.profileImage_url
                      ? quoteDetail.manager.profileImage_url
                      : '/assets/images/import/profileImage_default_1.svg'
                  }
                  alt='프로필 이미지'
                  className='w-[12.8vw] h-[12.8vw] object-fill rounded-full'
                />
                <div className='flex flex-col justify-start items-start gap-[8px]'>
                  <p className='text-[#767676]'>
                    {quoteDetail?.manager?.name_nick ? (
                      quoteDetail.manager.name_nick
                    ) : (
                      <span className='text-[#999999]'>닉네임 없음</span>
                    )}
                  </p>
                  <p className='text-[#111111] font-[600]'>
                    <span>
                      {quoteDetail?.manager?.name_real
                        ? quoteDetail.manager.name_real === '비공개'
                          ? '비공개'
                          : `${quoteDetail.manager.name_real}님`
                        : '이름 없음'}
                    </span>
                  </p>
                </div>
              </div>
              <div className='py-[14px] min-w-[80px] max-h-[50px] max-w-[90px] px-[16px] w-[20vw] flex justify-center items-center bg-[#0075FF] rounded-[6px] text-white font-[500]'>
                {quoteDetail?.state
                  ? convertOrderStatus[quoteDetail.state]
                  : '없음'}
              </div>
            </div>
          ) : (
            <div className='w-full flex justify-between items-center border-b-[1px] border-[#E5E5EC] pb-4'>
              {/* 상단 낙찰 전 상태값 */}
              <div className='w-full flex flex-row gap-6 justify-start items-center'>
                <img
                  src='https://cdn.carsayo.net/resource/image/profileImage_default_1.svg'
                  alt='샘플 프로필 이미지'
                  className='w-[12.8vw] h-[12.8vw] object-fill rounded-full'
                />
                <div className='flex flex-col justify-start items-start gap-[8px]'>
                  <p className='text-[#767676] text-[15px]'>
                    담당 매니저가 없습니다
                  </p>
                </div>
              </div>
              <div className='py-[14px] min-w-[82px] px-[16px] w-[20vw] flex justify-center items-center bg-[#0075FF] rounded-[6px] text-white font-[500]'>
                {quoteDetail?.state
                  ? convertOrderStatus[quoteDetail?.state]
                  : '낙찰 전'}
              </div>
            </div>
          )}
          {/* 구매유형 박스 */}
          {quoteDetail?.type !== 'sellCar' && (
            <div className='w-full pt-4 pb-0'>
              <ul className='w-full flex flex-col justify-start items-start'>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>주문번호</p>
                  <p className='text-[#111111]'>{quoteDetail?.code}</p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>유형</p>
                  <p className='text-[#111111]'>
                    {quoteDetail ? (
                      (quoteDetail?.purchase &&
                        quoteDetail.purchase?.purchaseInfo?.method &&
                        quoteDetail.purchase?.purchaseInfo.method) ??
                      '없음'
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                {quoteDetail?.purchase?.purchaseInfo.method &&
                  quoteDetail.purchase.purchaseInfo.method !== '일시불' && (
                    <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                      <p className='text-[#767676] min-w-[23vw]'>
                        {quoteDetail.purchase.purchaseInfo?.할부
                          ? '할부기간'
                          : '계약기간'}
                      </p>
                      <p className='text-[#111111]'>
                        {quoteDetail.purchase.purchaseInfo?.리스 ? (
                          `${quoteDetail.purchase.purchaseInfo.리스.contractTerm}개월`
                        ) : quoteDetail.purchase.purchaseInfo?.렌트 ? (
                          `${quoteDetail.purchase.purchaseInfo.렌트.contractTerm}개월`
                        ) : quoteDetail.purchase.purchaseInfo?.할부 ? (
                          `${quoteDetail.purchase.purchaseInfo.할부.installmentTerm}개월`
                        ) : (
                          <span className='text-[#999999]'>없음</span>
                        )}
                      </p>
                    </li>
                  )}
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>차량가격</p>
                  <div className='text-[#111111] flex justify-between items-center gap-[10px] w-full pr-2'>
                    <span>
                      {quoteDetail?.price ? (
                        slicePrice(quoteDetail.price)
                      ) : (
                        <span className='text-[#999999]'>구매 계약서 확인</span>
                      )}
                    </span>
                    <div
                      className={cn(
                        'relative',
                        !quoteDetail?.price && 'hidden',
                      )}
                    >
                      <div
                        className='w-[20px] h-[20px] cursor-pointer'
                        onClick={() => setExpectedOpen(true)}
                      >
                        <img
                          src={'/assets/images/import/nofiIcon.svg'}
                          alt='알림아이콘'
                          className='object-contain w-[20px] h-[20px]'
                        />
                      </div>
                    </div>
                  </div>
                </li>
                {(quoteDetail?.purchase?.purchaseInfo.method === '일시불' ||
                  quoteDetail?.purchase?.purchaseInfo.method === '할부') && (
                  <>
                    <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                      <p className='text-[#767676] min-w-[23vw]'>거주지역</p>
                      <p className='text-[#111111]'>
                        {quoteDetail.purchase.purchaseInfo.method === '일시불'
                          ? quoteDetail.purchase.purchaseInfo.일시불
                              ?.residenceRegion
                            ? `${
                                parseSigunguId(
                                  quoteDetail.purchase.purchaseInfo.일시불
                                    .residenceRegion,
                                )?.sido.name ?? ''
                              } ${
                                parseSigunguId(
                                  quoteDetail.purchase.purchaseInfo.일시불
                                    .residenceRegion,
                                )?.name ?? ''
                              }`
                            : '없음'
                          : quoteDetail.purchase.purchaseInfo.method ===
                                '할부' &&
                              quoteDetail.purchase.purchaseInfo.할부
                                ?.residenceRegion
                            ? `${
                                parseSigunguId(
                                  quoteDetail.purchase.purchaseInfo.할부
                                    .residenceRegion,
                                )?.sido.name ?? ''
                              } ${
                                parseSigunguId(
                                  quoteDetail.purchase.purchaseInfo.할부
                                    .residenceRegion,
                                )?.name ?? ''
                              }`
                            : '없음'}
                      </p>
                    </li>
                    <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                      <p className='text-[#767676] min-w-[23vw]'>인수지역</p>
                      <p className='text-[#111111]'>
                        {quoteDetail.purchase.purchaseInfo.method === '일시불'
                          ? quoteDetail.purchase.purchaseInfo.일시불
                              ?.deliveryRegion
                            ? `${
                                parseSigunguId(
                                  quoteDetail.purchase.purchaseInfo.일시불
                                    .deliveryRegion,
                                )?.sido.name ?? ''
                              } ${
                                parseSigunguId(
                                  quoteDetail.purchase.purchaseInfo.일시불
                                    .deliveryRegion,
                                )?.name ?? ''
                              }`
                            : '없음'
                          : quoteDetail.purchase.purchaseInfo.method ===
                                '할부' &&
                              quoteDetail.purchase.purchaseInfo.할부
                                ?.deliveryRegion
                            ? `${
                                parseSigunguId(
                                  quoteDetail.purchase.purchaseInfo.할부
                                    .deliveryRegion,
                                )?.sido.name ?? ''
                              } ${
                                parseSigunguId(
                                  quoteDetail.purchase.purchaseInfo.할부
                                    .deliveryRegion,
                                )?.name ?? ''
                              }`
                            : '없음'}
                      </p>
                    </li>
                  </>
                )}
              </ul>
            </div>
          )}
          {/* 팔기유형 박스 */}
          {quoteDetail?.type === 'sellCar' && (
            <div className='w-full pt-4 pb-0'>
              <ul className='w-full flex flex-col justify-start items-start'>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>주문번호</p>
                  <p className='text-[#111111]'>{quoteDetail?.code}</p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>유형</p>
                  <p className='text-[#111111]'>
                    {quoteDetail?.type ? (
                      convertDealerType[quoteDetail.type]
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>시세조회</p>
                  <div className='text-[#111111] flex justify-between items-center gap-[10px] w-full pr-2'>
                    <span>
                      {quoteDetail?.price ? (
                        slicePrice(quoteDetail.price)
                      ) : (
                        <span className='text-[#999999]'>없음</span>
                      )}
                    </span>
                    <div className='relative'>
                      <div
                        className='w-[20px] h-[20px] cursor-pointer'
                        onClick={handleClickEvents.openTooltip}
                      >
                        <img
                          src={'/assets/images/import/nofiIcon.svg'}
                          alt='알림아이콘'
                          className='object-contain w-[20px] h-[20px]'
                        />
                      </div>
                      {/* 차량 가격 확인하기 */}
                      {quoteDetail.selling && (
                        <SellingCarEstimatePrice
                          isOpen={isTooltipVisible}
                          setIsOpen={setTooltipVisible}
                          sellingOrderInfo={quoteDetail.selling}
                          mode={'info'}
                        />
                      )}
                    </div>
                  </div>
                </li>
                <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                  <p className='text-[#767676] min-w-[23vw]'>판매지역</p>
                  <p className='text-[#111111]'>
                    {quoteDetail?.selling?.sellRegionId ? (
                      `${
                        parseSigunguId(quoteDetail.selling.sellRegionId)?.sido
                          .name ?? ''
                      } ${
                        parseSigunguId(quoteDetail.selling.sellRegionId)
                          ?.name ?? ''
                      }`
                    ) : (
                      <span className='text-[#999999]'>없음</span>
                    )}
                  </p>
                </li>
              </ul>
            </div>
          )}
        </div>

        {/* 견적 상태 타임라인 */}
        <div className='w-full py-4'>
          {/* 견적 타임라인 */}
          <div
            className={classNames(
              'transition-all',
              isQuoteBoxOpen
                ? 'h-auto opacity-100 translate-y-0 z-[0] pointer-events-auto'
                : 'h-0 opacity-0 translate-y-[50px] z-[-100] pointer-events-none fixed',
            )}
          >
            <div className='w-full py-4'>
              <ul className='w-full flex flex-col gap-4 justify-start items-start'>
                {/* 카드 박스 - 낙찰 된 후 타임라인 */}
                {quoteDetail?.processCardList &&
                  quoteDetail.processCardList.length > 0 &&
                  quoteDetail.processCardList.map((item, index) => {
                    return (
                      <li
                        key={`processCard_${index}`}
                        className='w-full flex flex-col justify-start items-start gap-4 text-[#111111]'
                      >
                        {/* 카드 상단 */}
                        <div className='w-full flex flex-row justify-between items-center'>
                          <div className='flex flex-row items-center gap-[5px]'>
                            <span className='bg-[#111111] py-[2px] px-1 text-white text-[11px] leading-[16px]'>
                              {/* {index + 1 < 10 ? `0${index + 1}` : index + 1} */}
                              {quoteDetail.processCardList.length - index < 10
                                ? `0${quoteDetail.processCardList.length - index}`
                                : quoteDetail.processCardList.length - index}
                            </span>
                            <p className='text-[15px]'>{item.title}</p>
                          </div>
                          <div className='flex flex-row items-center gap-1'>
                            <span
                              className={classNames(
                                'min-w-[27px] py-[2px] px-[4px] border text-[11px] tracking-[-0.28px]',
                                item.state &&
                                  `!border-[${parseOrderProcessState(item.state).color}] !text-[${parseOrderProcessState(item.state).color}]`,
                              )}
                            >
                              {item.state && item.created_at
                                ? `${parseOrderProcessState(item.state).text} ${dateToStringMMDD(item.created_at)} ${dateToStringHHMM(item.created_at)}`
                                : '상태없음'}
                            </span>
                          </div>
                        </div>
                        {/* 카드 중앙 */}
                        <div className='w-full bg-[#F7F7FB] p-4 flex flex-col gap-[12px] '>
                          {(item.file_1 || item.file_2 || item.file_3) && (
                            <ul>
                              {item.file_1 && (
                                <li className='w-full'>
                                  <div
                                    className='mt-3 flex h-12 items-center justify-center border border-[#111111] bg-white'
                                    onClick={() => {
                                      item.file_1 !== null
                                        ? download(item.file_1)
                                        : null;
                                    }}
                                  >
                                    {item.file_1.name}
                                  </div>
                                </li>
                              )}
                              {item.file_2 && (
                                <li className='w-full'>
                                  <div
                                    className='mt-3 flex h-12 items-center justify-center border border-[#111111] bg-white'
                                    onClick={() => {
                                      item.file_2 !== null
                                        ? download(item.file_2)
                                        : null;
                                    }}
                                  >
                                    {item.file_2.name}
                                  </div>
                                </li>
                              )}
                              {item.file_3 && (
                                <li className='w-full'>
                                  <div
                                    className='mt-3 flex h-12 items-center justify-center border border-[#111111] bg-white'
                                    onClick={() => {
                                      item.file_3 !== null
                                        ? download(item.file_3)
                                        : null;
                                    }}
                                  >
                                    {item.file_3.name}
                                  </div>
                                </li>
                              )}
                            </ul>
                          )}
                          {(item.additionalInfo_1 ||
                            item.additionalInfo_2 ||
                            item.additionalInfo_3) && (
                            <ul>
                              {item.additionalInfo_1 && (
                                <li>
                                  <div className='border font-[500] border-[#111111] bg-[#F1F1F5] py-[14px] px-[16px] flex justify-between items-stretch'>
                                    <span className='text-[#767676] max-w-[50%]'>
                                      {item.additionalInfo_1.title}
                                    </span>
                                    <strong className='max-w-[50%] whitespace-pre-wrap'>
                                      {item.additionalInfo_1.content
                                        ? ReactHtmlParser(
                                            item.additionalInfo_1.content,
                                          )
                                        : '없음'}
                                    </strong>
                                  </div>
                                </li>
                              )}
                              {item.additionalInfo_2 && (
                                <li>
                                  <div className='border font-[500] border-[#111111] bg-[#F1F1F5] py-[14px] px-[16px] flex justify-between items-center'>
                                    <span className='text-[#767676] max-w-[50%]'>
                                      {item.additionalInfo_2.title}
                                    </span>
                                    <strong className='max-w-[50%] whitespace-pre-wrap'>
                                      {item.additionalInfo_2.content
                                        ? ReactHtmlParser(
                                            item.additionalInfo_2.content,
                                          )
                                        : '없음'}
                                    </strong>
                                  </div>
                                </li>
                              )}
                              {item.additionalInfo_3 && (
                                <li>
                                  <div className='border font-[500] border-[#111111] bg-[#F1F1F5] py-[14px] px-[16px] flex justify-between items-center'>
                                    <span className='text-[#767676] max-w-[50%]'>
                                      {item.additionalInfo_3.title}
                                    </span>
                                    <strong className='max-w-[50%] whitespace-pre-wrap'>
                                      {item.additionalInfo_3.content
                                        ? ReactHtmlParser(
                                            item.additionalInfo_3.content,
                                          )
                                        : '없음'}
                                    </strong>
                                  </div>
                                </li>
                              )}
                            </ul>
                          )}
                          <p className='whitespace-pre-wrap w-full text-left text-[15px] leading-[22px]'>
                            {item.content
                              ? ReactHtmlParser(item.content)
                              : '견적 내용 없음'}
                          </p>
                        </div>
                        {/* 카드 하단 */}
                        {/* {item.state === 'accepted' && (
                          <div className='w-full text-[#111111] border border-[#E5E5EC] py-4 px-2 flex flex-row justify-start items-center gap-2'>
                            <span className='w-[16px] h-[16px] flex justify-center items-center mr-1'>
                              <SoundIcon />
                            </span>
                            {quoteDetail.type === 'sellCar'
                              ? '미팅 날짜가 잡히면 다음 단계로 진행됩니다.'
                              : '계약서가 준비되면 곧 업데이트됩니다.'}
                          </div>
                        )}
                        {item.state === 'progressing' && (
                          <div className='w-full flex gap-[33px]'>
                            <div
                              className='flex h-9 grow items-center justify-center border text-desc'
                              onClick={() => setIsCancel(true)}
                            >
                              취소
                            </div>
                            <div
                              className='flex h-9 grow items-center justify-center border text-desc'
                              onClick={() => setIsCancel(true)}
                            >
                              보류
                            </div>
                          </div>
                        )}
                        {item.state === 'holded' && (
                          <div className='w-full flex gap-[33px]'>
                            <div
                              className='flex h-9 grow items-center justify-center border text-desc'
                              onClick={() => setIsCancel(true)}
                            >
                              재계약
                            </div>
                          </div>
                        )} */}
                      </li>
                    );
                  })}
                {/* 견적서 있을 때 타임라인(낙찰 전 입찰리스트) */}
                {quoteDetail?.bidList &&
                  quoteDetail?.bidList.length > 0 &&
                  quoteDetail?.processCardList.length === 0 && (
                    <div className='w-full'>
                      <p className='text-[18px] font-[600]'>참여 카 매니저</p>
                      {quoteDetail.bidList.map((item, index) => {
                        return (
                          <div
                            className='w-full bg-white px-0 py-6'
                            key={`bidderData_${index}`}
                          >
                            <div className='flex gap-2 border-b pb-4'>
                              <div className='flex h-[50px] w-[50px] items-center justify-center overflow-hidden rounded-full'>
                                {item.dealer.profileImage_url ? (
                                  <img
                                    src={item.dealer.profileImage_url}
                                    alt={item.dealer.name_nick}
                                  />
                                ) : (
                                  <img
                                    src='/assets/images/v2/Ellipse.png'
                                    alt='딜러프로필샘플이미지'
                                  />
                                )}
                              </div>
                              <div className='flex-1'>
                                <div className='flex items-center justify-between'>
                                  <div className='text-desc text-[#767676]'>
                                    카 매니저
                                  </div>
                                  <div className='flex items-center gap-1'>
                                    <div className='flex items-center'>
                                      <StarRating rating={4} />
                                    </div>
                                    <div className='text-desc'>4.0</div>
                                  </div>
                                </div>
                                <div className='mt-2.5'>
                                  {item.dealer.name_nick ?? '닉네임 없음'}
                                </div>
                              </div>
                            </div>
                            <div className='flex justify-between items-center gap-1 py-4 text-title'>
                              <div className='flex flex-row items-center gap-[5px]'>
                                <div className='flex h-[22px] w-5 items-center justify-center bg-[#111111] text-[11px] text-white'>
                                  {/* {index + 1 < 10 ? `0${index + 1}` : index + 1} */}
                                  {quoteDetail.bidList.length - index < 10
                                    ? `0${quoteDetail.bidList.length - index}`
                                    : quoteDetail.bidList.length - index}
                                </div>
                                <div className='text-[#767676]'>견적서</div>
                              </div>
                              <div className='flex flex-row items-center gap-1'>
                                <span
                                  className={classNames(
                                    'min-w-[27px] py-[2px] px-[4px] border text-[11px] tracking-[-0.28px]',
                                    item.state &&
                                      `!border-[${parseOrderProcessState(item.state).color}] !text-[${parseOrderProcessState(item.state).color}]`,
                                  )}
                                >
                                  {item.state && item.created_at
                                    ? `${parseOrderProcessState(item.state).text} ${dateToStringMMDD(item.created_at)} ${dateToStringHHMM(item.created_at)}`
                                    : '상태없음'}
                                </span>
                              </div>
                            </div>
                            <div className='bg-[#F7F7FB] p-4 text-title'>
                              {item.estimateFile && (
                                <div
                                  className='mb-3 flex h-12 items-center justify-center border border-[#111111] bg-white'
                                  onClick={() => {
                                    item.estimateFile !== null
                                      ? download(item.estimateFile)
                                      : null;
                                  }}
                                >
                                  견적서 보기
                                </div>
                              )}
                              {item.purchasePrice && (
                                <div className='mb-3 flex h-12 items-center justify-between border border-[#E5E5EC] bg-white px-4 text-[#999999]'>
                                  <p className='text-[#767676] min-w-[12vw]'>
                                    매입가격
                                  </p>
                                  <p className='text-[#111111]'>
                                    {item.purchasePrice ? (
                                      `${item.purchasePrice.toLocaleString()}원`
                                    ) : (
                                      <span className='text-[#999999]'>
                                        없음
                                      </span>
                                    )}
                                  </p>
                                </div>
                              )}
                              <div className='whitespace-pre-wrap w-full mb-3'>
                                {item.content
                                  ? ReactHtmlParser(item.content)
                                  : '내용 없음'}
                              </div>
                              <div
                                className='flex h-12 items-center justify-center bg-[#7273F9] font-medium text-white'
                                onClick={() => {
                                  setSelectManager(item.dealer.id);
                                  setChoiceOpen(!isChoiceOpen);
                                }}
                              >
                                이 카매니저와 진행하고 싶어요
                              </div>
                              <Confirm
                                isOpen={isChoiceOpen}
                                setIsOpen={setChoiceOpen}
                                isCloseButton={true}
                                onConfirm={handleClickEvents.choiceManager}
                                title='담당 카 매니저 결정'
                                desc={`선택하신 카 매니저분과 진행을 하시겠습니까?`}
                              ></Confirm>
                            </div>
                          </div>
                        );
                      })}
                    </div>
                  )}
                {/* 등록된 입찰이 없을 때 타임라인 */}
                {(quoteDetail?.processCardList === undefined ||
                  quoteDetail?.processCardList.length === 0) &&
                  (quoteDetail?.bidList === undefined ||
                    quoteDetail?.bidList.length === 0) && (
                    <li className='w-full flex flex-col justify-start items-start gap-4 text-[#111111]'>
                      <div className='w-full text-center bg-[#F7F7FB] p-4 text-[15px] text-[#767676]'>
                        등록된 입찰이 없습니다.
                      </div>
                    </li>
                  )}
              </ul>
            </div>
          </div>
          {/* 견적 타임라인 여 닫는 버튼 */}
          <div className='w-full border-t-[1px] mt-4 border-[#E5E5EC] relative'>
            <button
              className='shadow-[0px_4px_10px_#0000000f] absolute left-0 right-0 mx-auto top-[-20px] p-[10px] rounded-full border border-[#E5E5EC] w-[40px] inline-flex justify-center items-center bg-white'
              onClick={handleClickEvents.quoteBoxOpen}
            >
              <img
                src={'/assets/images/import/arrowTop.svg'}
                alt='닫기버튼'
                className={classNames(
                  'transition-all',
                  isQuoteBoxOpen ? 'rotate-[0deg]' : 'rotate-[180deg]',
                )}
              />
            </button>
          </div>
        </div>

        {/* 추가 요청사항 있을 시 추가 요청사항 */}
        {quoteDetail?.purchase?.additionalRequest && (
          <div className='w-full flex flex-col gap-[5.5vw] justify-start items-start py-2'>
            <p className='text-[#767676] min-w-[23vw]'>추가 요청사항</p>
            <div className='text-[#111111] pb-4 w-full'>
              <div className='whitespace-pre-wrap w-full text-[#111111] bg-[#F7F7FB] p-4 flex flex-col gap-[12px] justify-start items-start'>
                {ReactHtmlParser(quoteDetail.purchase.additionalRequest)}
              </div>
            </div>
          </div>
        )}
        {/* 견적서 파일 */}
        {quoteDetail?.purchase && (
          <Customer_EstimateFile
            purchase={quoteDetail.purchase}
          ></Customer_EstimateFile>
        )}

        {/* 차량 정보 및 구매 정보 */}
        {quoteDetail && <OrderInfo quoteDetail={quoteDetail} />}
      </div>

      {/* 신차, 리스/렌트 차량가격 안내 팝업 */}
      {quoteDetail && (
        <CarPriceInfoDrawer
          isExpectedOpen={isExpectedOpen}
          setExpectedOpen={setExpectedOpen}
          quoteDetail={quoteDetail}
        />
      )}

      <Cancel isOpen={isCancel} setIsOpen={setIsCancel} />
    </>
  );
};
export default QuoteBoxDetail;
