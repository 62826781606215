/* eslint-disable @typescript-eslint/no-unused-vars */
import dayjs from 'dayjs';
import { ChevronRight, X } from 'lucide-react';
import {
  Fragment,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import useMemberStore from 'store/useMemberStore';
import { cn } from 'utils';
import { parseDealerType } from 'utils/textConvert';
import { Link, NavigateFunction, useNavigate } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import MainFooter from '../components/footer';
import InfiniteScroll from 'react-infinite-scroll-component';
import {
  useGetDealerBiddingOrderList,
  useGetDealerOrderList,
  useUpdateDealerBiddingOrderList,
  useUpdateDealerOrderList,
} from '../query';
import CarsayoPagination from 'components/common/v2/CarsayoPagination';
import useSystemStore from 'store/useSystemStore';
import useDealerMainStateStore from 'pages/main/useDealerMainStateStore';
import SubPage from 'components/view/SubPage';
import { setDealerBookMark } from 'apis/quote';
import { OrderListCard } from '../components/orderCard';
import React from 'react';
import useRestore from 'hooks/restore/useRestore';
import { setFcmToken } from 'apis/authentication';
import SwiperCore from 'swiper';
import SwiperClass from 'swiper/types/swiper-class';
import {
  DealerInfo,
  DealerOrderSearch,
  Member,
  OrderState,
} from '@carsayo/types';
import HtmlParser from 'react-html-parser';

import {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import useOnResumeStore from 'store/useOnResumeStore';
import CarsayoButton from 'components/common/CarsayoButton';
import usePullToRefresh from 'hooks/usePullToRefresh';

const Devider = () => {
  return <div className='w-full h-3 bg-[#F1F1F5]'></div>;
};

const SignupStateTag = ({ dealerInfo }: { dealerInfo: DealerInfo }) => {
  return dealerInfo.signupState === 'accepted' ? (
    <div className='ml-auto bg-white h-5 items-center border border-green-700 px-1 text-[11px] text-green-700'>
      승인{' '}
      {dealerInfo.accepted_at && dayjs(dealerInfo.accepted_at).format('MM.DD')}
    </div>
  ) : dealerInfo.signupState === 'rejected' ? (
    <div className='ml-auto bg-white h-5 items-center border border-red-700 px-1 text-[11px] text-red-700'>
      거부{' '}
      {dealerInfo.rejected_at && dayjs(dealerInfo.rejected_at).format('MM.DD')}
    </div>
  ) : (
    <div className='ml-auto bg-white h-5 items-center border border-gray-600 px-1 text-[11px] text-gray-600'>
      승인대기{' '}
      {dealerInfo.submitted_at &&
        dayjs(dealerInfo.submitted_at).format('MM.DD')}
    </div>
  );
};

const CarManagerCard = ({
  member,
  navigate,
}: {
  member: Member;
  navigate: NavigateFunction;
}) => {
  const defaultDescription =
    '절대로 변함 없는 고객님과의 약속을 지키기 위해 항시 노력하고 있으니 언제든지 불러 주세요.';
  return (
    <div className='min-h-[180px] relative rounded-t-xl bg-dealer-main-bg bg-no-repeat bg-center bg-cover px-4 py-6'>
      <div className='flex h-6 items-center'>
        <div className='flex items-center'>
          {member.dealerInfo?.signupState === 'accepted' ? (
            <>
              <div className='font-semibold text-[#7273F9]'>카사요 인증</div>
              <img
                className='w-[20px] h-[20px]'
                src='/assets/images/v2/starCollar.svg'
                alt=''
              />
            </>
          ) : member.dealerInfo?.signupState === 'rejected' ? (
            <>
              <span className='font-semibold text-red-600'>가입신청 거부</span>
              <img
                className='w-[16px] h-[16px] ml-1'
                src='/assets/images/v2/purchaseProcess.svg'
                alt=''
              />
            </>
          ) : (
            <>
              <span className='font-semibold text-red-600'>카사요 미인증</span>
              <img
                className='w-[16px] h-[16px] ml-1'
                src='/assets/images/v2/purchaseProcess.svg'
                alt=''
              />
            </>
          )}
        </div>
        {member.dealerInfo && (
          <SignupStateTag dealerInfo={member.dealerInfo}></SignupStateTag>
        )}
      </div>
      <div className='flex items-center gap-2'>
        <div className='text-[#7756]'>
          {member.dealerInfo?.type && parseDealerType(member.dealerInfo?.type)}
        </div>
        <div>{member.name_real}님</div>
      </div>
      {/* 가입신청 거부 시 거부사유 및 재가입신청 페이지 리다이렉트 버튼 */}
      {member.dealerInfo?.signupState === 'rejected' ? (
        <>
          <div className='mt-4 whitespace-pre-wrap'>
            {HtmlParser(member.dealerInfo.signupRejectMessage ?? ' ')}
          </div>
          <div className='pt-4 w-full flex'>
            <CarsayoButton
              onClick={() => {
                navigate('/mypage/dealerSignupForm');
              }}
              disabled={false}
              className='w-full bg-red-600'
            >
              재가입신청
            </CarsayoButton>
          </div>
        </>
      ) : (
        // 정상회원은 자기소개 및 프로필 이미지 출력
        <>
          <div className='mt-4 whitespace-pre-wrap'>
            {HtmlParser(member.description ?? '')}
          </div>
          <div className='absolute bottom-[-14px] ml-2 h-10 w-10 overflow-hidden rounded-full'>
            <img src={member.profileImage_url} alt='' />
          </div>
        </>
      )}
    </div>
  );
};

const BiddingOrder = ({
  dealerOrderListData,
  setIsSideMenu,
}: {
  dealerOrderListData: InfiniteData<DealerOrderSearch, unknown> | undefined;
  setIsSideMenu: (value: boolean) => any;
}) => {
  const { loginMember } = useMemberStore();

  /** 유사한 코드가 이 파일 내에 존재합니다 */
  const updateDealerBiddingOrderList = useUpdateDealerBiddingOrderList();
  const [, updateState] = React.useState<{}>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const toggleBookmark = async (params: { orderId: string }) => {
    const updatedData = await setDealerBookMark({
      orderId: params.orderId,
      isBookmarked: !dealerOrderListData?.pages[0].data.find((el) => {
        return el.id === params.orderId;
      })?.isBookmarked,
    });
    updateDealerBiddingOrderList({
      data: updatedData,
      orderId: updatedData.id,
      page: 0,
    });
    forceUpdate();
  };
  const {
    biddingSwiperPage,
    setBiddingSwiperPage,
    isBiddingSidePageOpen,
    setBiddingSidePageOpen,
  } = useDealerMainStateStore();
  const [biddingSwiper, setBiddingSwiper] = useState<SwiperCore | null>(null);

  useLayoutEffect(() => {
    if (biddingSwiper) {
      biddingSwiper.slideTo(biddingSwiperPage);
    }
  }, [biddingSwiperPage]);

  return (
    <div className='px-4 py-6'>
      {/* {isFetching === false && ( */}
      <>
        <div className='flex w-full items-center pb-6'>
          <div className='flex w-full items-center justify-between gap-1'>
            <div className='text-xl font-semibold'>
              {loginMember?.dealerInfo?.type === 'usedcar'
                ? '내차팔기 신청서'
                : '구매 신청서'}
            </div>
            {dealerOrderListData?.pages[0]?.totalCount ? (
              <div className='text-xl font-semibold text-[#6100FF]'>
                {dealerOrderListData.pages[0].totalCount}
              </div>
            ) : (
              <></>
            )}
            <div className='flex-auto'></div>
            {dealerOrderListData?.pages[0].totalCount ? (
              <div
                className='flex flex-none items-center'
                onClick={() => {
                  setIsSideMenu(true);
                }}
              >
                <div>전체보기</div>
                <ChevronRight className='h-4 w-4' strokeWidth='1.5' />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>

        {dealerOrderListData?.pages[0] &&
        dealerOrderListData.pages[0].data.length > 0 ? (
          <Swiper
            initialSlide={biddingSwiperPage}
            breakpoints={{
              700: {
                slidesPerView: 2,
              },
              1050: {
                slidesPerView: 3,
              },
            }}
            slidesPerView={1}
            slidesPerGroup={1}
            spaceBetween={16}
            onRealIndexChange={(swiper: SwiperClass) =>
              setBiddingSwiperPage(swiper.activeIndex)
            }
            onSwiper={(swiper: SwiperClass) => setBiddingSwiper(swiper)}
          >
            {dealerOrderListData.pages[0].data.map((el) => {
              return (
                <SwiperSlide key={el.id}>
                  {OrderListCard({
                    el: el,
                    option: { bidEndTime: true },
                    toggleBookmark: () => {
                      toggleBookmark({
                        orderId: el.id,
                      });
                    },
                  })}
                </SwiperSlide>
              );
            })}
          </Swiper>
        ) : (
          <div className='fe'>
            <div
              className='flex h-[236px] shrink-0 flex-col rounded-lg text-white'
              style={{
                background:
                  'linear-gradient(107.38deg, #2D60FF 2.61%, #539BFF 101.2%)',
              }}
            >
              <div className='flex grow items-center justify-center p-4'>
                <img
                  src='/assets/images/v2/ico-casayo.svg'
                  alt=''
                  className='h-20 w-20'
                />
              </div>
              <div className='flex h-[50px] items-center bg-gradient-to-b from-white/15 to-white/0 px-4'>
                <div className='grow text-center'>
                  현재 입찰중인 주문이 없습니다
                </div>
              </div>
            </div>
          </div>
        )}
      </>
      {/* )} */}
    </div>
  );
};

const Tabs: {
  title: string;
  key: OrderState | 'whole' | 'bidding' | 'marking';
}[] = [
  { title: '전체보기', key: 'whole' },
  { title: '북마크', key: 'marking' },
  { title: '입찰중', key: 'bidding' },
  { title: '진행중', key: 'progressing' },
  { title: '보류', key: 'holded' },
  { title: '계약완료', key: 'finished' },
  { title: '취소', key: 'canceled' },
  { title: '유찰 ', key: 'failBid' },
];
const MyOrderList = ({
  searchedMyOrderList,
}: {
  searchedMyOrderList: DealerOrderSearch | undefined;
}) => {
  const { orderTab, setOrderTab } = useDealerMainStateStore();
  const { viewPointRef } = useSystemStore();
  const take = 4;

  const containerRef = useRef<null | HTMLDivElement>(null);
  const tabSelecteRef = useRef<null[] | HTMLDivElement[]>([]);

  const [tab, setTab] = useState(
    orderTab.type ? Tabs.findIndex((tab) => tab.key === orderTab.type) : 0,
  );

  const totalPage = useMemo(() => {
    if (!searchedMyOrderList) return 0;
    else return Math.ceil(searchedMyOrderList.totalCount / take);
  }, [searchedMyOrderList]);

  useEffect(() => {
    if (tab === 0 && orderTab.type === null) return;
    if (orderTab.type === Tabs[tab].key) return;
    setOrderTab({
      ...orderTab,
      type: Tabs[tab].key,
      page: 0,
      isTabSlide: false,
    });
    tabSelecteRef.current[tab]?.scrollIntoView({
      inline: 'center',
      block: 'nearest',
      behavior: 'smooth',
    });
  }, [tab]);

  useEffect(() => {
    if (orderTab.type === null) return;
    if (orderTab && containerRef) {
      const currentTab = Tabs.findIndex((tab) => tab.key === orderTab.type);
      setTab(currentTab);
      if (orderTab.isTabSlide === true) {
        viewPointRef?.scrollTo({
          top: (containerRef.current?.offsetTop ?? 0) - 120,
          behavior: 'smooth',
        });
        setOrderTab({
          ...orderTab,
          isTabSlide: false,
        });
      }
      containerRef.current?.scrollTo({
        left: tabSelecteRef.current[currentTab]?.offsetLeft,
        behavior: 'smooth',
      });
    }
  }, [orderTab]);

  /** 유사한 코드가 이 파일 내에 존재합니다 */
  const updateDealerOrderList = useUpdateDealerOrderList();
  const [, updateState] = React.useState<{}>();
  const toggleBookmark = async (params: { orderId: string }) => {
    if (!searchedMyOrderList) return;
    // const newData = { ...searchedMyOrderList };
    const updatedData = await setDealerBookMark({
      orderId: params.orderId,
      isBookmarked: !searchedMyOrderList.data.find((el, index) => {
        return el.id === params.orderId;
      })?.isBookmarked,
    });

    // newData.data.find((el, index) => {
    //   newData.data[index] = updatedData;
    // });

    const newData: DealerOrderSearch = {
      ...searchedMyOrderList,
      data: searchedMyOrderList.data.map((el) =>
        el.id === params.orderId ? updatedData : el,
      ),
    };

    updateDealerOrderList({
      data: newData,
      type: orderTab.type ?? 'whole',
      take: take,
      page: orderTab.page,
    });
  };

  return (
    <div className='py-6'>
      <div className='px-4 flex items-center justify-between pb-4'>
        <div className='flex items-center gap-1'>
          <div className='text-xl font-semibold'>나의 주문 리스트</div>
        </div>
      </div>
      <div
        className='scroll-hidden flex h-[52px] scroll-pl-4 items-center overflow-x-scroll w-full cursor-pointer'
        ref={containerRef}
      >
        {Tabs.map((item, index) => (
          <div
            ref={(el) => (tabSelecteRef.current[index] = el)}
            key={item.key}
            className={cn(
              'flex h-[52px] shrink-0 items-center justify-center border-b border-[#E5E5EC] px-4 text-[#7756] transition',
              item === Tabs[tab] &&
                'border-[#111111] font-medium text-[#111111]',
            )}
            onClick={() => setTab(Tabs.indexOf(item))}
          >
            {item.title}
          </div>
        ))}
      </div>
      <div className='px-4 flex flex-col gap-4 mt-4'>
        {searchedMyOrderList && searchedMyOrderList.data.length > 0 ? (
          searchedMyOrderList.data.map((el) => {
            return (
              <Fragment key={el.id}>
                {OrderListCard({
                  el: el,
                  className: cn(
                    el.type !== 'sellCar' && 'bg-none bg-[#091242]',
                  ),
                  option: {
                    bidEndTime: el.state === 'submitted' ? true : undefined,
                  },
                  toggleBookmark() {
                    toggleBookmark({
                      orderId: el.id,
                    });
                  },
                })}
              </Fragment>
            );
          })
        ) : (
          <div className='fe'>
            <div className='flex h-[236px] shrink-0 flex-col rounded-lg text-white bg-[#091242]'>
              <div className='flex grow items-center justify-center p-4'>
                <img
                  src='/assets/images/v2/ico-casayo.svg'
                  alt=''
                  className='h-20 w-20'
                />
              </div>
              <div className='flex h-[50px] items-center bg-gradient-to-b from-white/15 to-white/0 px-4'>
                <div className='grow text-center'>
                  주문 정보가 존재하지 않습니다
                </div>
              </div>
            </div>
          </div>
        )}
      </div>

      {totalPage > 1 && (
        <div className='flex w-full justify-center items-center pt-1'>
          <div className='w-full h-auto mt-[32px]'>
            <CarsayoPagination
              totalPage={totalPage}
              page={orderTab.page}
              setPage={(page: number) => {
                setOrderTab({
                  ...orderTab,
                  page: page,
                });
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
};

const SideMenu_BiddingOrder = ({
  isSideMenu,
  setIsSideMenu,
  dealerOrderListData,
  hasNextPage,
  fetchNextPage,
}: {
  isSideMenu: boolean;
  setIsSideMenu: (value: boolean) => any;
  dealerOrderListData: InfiniteData<DealerOrderSearch, unknown> | undefined;
  hasNextPage: boolean;
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<
    InfiniteQueryObserverResult<InfiniteData<DealerOrderSearch, unknown>, Error>
  >;
}) => {
  /** 유사한 코드가 이 파일 내에 존재합니다 */
  const updateDealerBiddingOrderList = useUpdateDealerBiddingOrderList();
  const [, updateState] = React.useState<{}>();
  const forceUpdate = React.useCallback(() => updateState({}), []);
  const toggleBookmark = async (params: { orderId: string; page: number }) => {
    const updatedData = await setDealerBookMark({
      orderId: params.orderId,
      isBookmarked: !dealerOrderListData?.pages[params.page].data.find((el) => {
        return el.id === params.orderId;
      })?.isBookmarked,
    });
    updateDealerBiddingOrderList({
      data: updatedData,
      orderId: updatedData.id,
      page: 0,
    });
    forceUpdate();
  };

  return (
    <SubPage isOpen={isSideMenu} setIsOpen={setIsSideMenu}>
      <>
        <div className='flex flex-none h-14 items-center justify-between bg-white px-4'>
          <div className='pl-4 grow text-center text-xl font-semibold'>
            입찰중인 주문
          </div>
          <div
            className='cursor-pointer'
            onClick={() => {
              setIsSideMenu(false);
            }}
          >
            <X></X>
          </div>
        </div>

        {dealerOrderListData && (
          <div id='scrollableDiv_k492' className='px-4 overflow-auto'>
            <InfiniteScroll
              next={fetchNextPage}
              hasMore={hasNextPage}
              loader={
                <div className='font-semibold text-center pb-4'>
                  정보를 불러오는 중입니다..
                </div>
              }
              dataLength={dealerOrderListData.pages[0].totalCount}
              scrollableTarget='scrollableDiv_k492'
            >
              {dealerOrderListData.pages.map((el1, index1) => {
                return el1.data.map((el2) => {
                  return (
                    <div key={el2.id} className='pb-4'>
                      {OrderListCard({
                        el: el2,
                        option: { bidEndTime: true },
                        toggleBookmark: () => {
                          toggleBookmark({
                            orderId: el2.id,
                            page: index1,
                          });
                        },
                      })}
                    </div>
                  );
                });
              })}
            </InfiniteScroll>
          </div>
        )}
      </>
    </SubPage>
  );
};

const Old_DealerMainPage = () => {
  const navigate = useNavigate();
  const { loginMember, refreshLoginMember } = useMemberStore();
  const { setOnResume } = useOnResumeStore();
  const [isSideMenu, setIsSideMenu] = useState<boolean>(false);
  const {
    orderTab,
    biddingSwiperPage,
    setOrderTab,
    setBiddingSwiperPage,
    resetOrder,
  } = useDealerMainStateStore();

  const take = 4;
  const { data: searchedMyOrderList, refetch: refetch_dealerOrderList } =
    useGetDealerOrderList({
      take: take,
      type: orderTab.type ?? 'whole',
      page: orderTab.page,
    });

  const {
    data: dealerOrderListData,
    hasNextPage,
    fetchNextPage,
    refetch: refetch_dealerBiddingOrderList,
  } = useGetDealerBiddingOrderList();

  const { getRestore, setRestore } = useRestore();
  const { setLoadingDim } = useSystemStore();

  const refreshData = async () => {
    try {
      await refreshLoginMember();
      await refetch_dealerOrderList();
      await refetch_dealerBiddingOrderList();
    } catch (e) {
      CarsayoToast.error('잠시 후 다시 시도해 주세요.');
    }
    return;
  };
  const refreshDataWithDim = async () => {
    setLoadingDim(true);
    await refreshData();
    setTimeout(() => {
      setLoadingDim(false);
    }, 500);
    return;
  };

  setRestore([
    { data: orderTab, setData: setOrderTab },
    { data: biddingSwiperPage, setData: setBiddingSwiperPage },
  ]);

  useEffect(() => {
    refreshLoginMember();
    setOnResume(refreshData);
    if (loginMember) setFcmToken();
  }, []);

  useLayoutEffect(() => {
    getRestore();
    return () => {
      resetOrder();
    };
  }, []);

  const mainPageRef = useRef<HTMLDivElement>(null);
  usePullToRefresh(mainPageRef, refreshDataWithDim);

  return (
    loginMember && (
      <>
        <div ref={mainPageRef}>
          <div className='p-4 pb-6'>
            {CarManagerCard({ member: loginMember, navigate: navigate })}

            {/* 수수료 파트 */}
            {loginMember.dealerInfo?.signupState === 'accepted' && (
              <div className='mt-8 flex h-11 items-center justify-center rounded bg-[#f1f1f5] px-4 gap-2'>
                {loginMember.dealerInfo.amount.nonPaidCommission ? (
                  <>
                    <img
                      src='/assets/images/v2/sound-black.svg'
                      className='mr-1'
                      alt=''
                    />
                    <div className='text-[#7756]'>미입금 수수료</div>
                    <div>
                      {loginMember.dealerInfo.amount.nonPaidCommission.toLocaleString()}
                      원
                    </div>
                    <Link
                      to={'/mypage/commission'}
                      className='ml-auto flex items-center'
                    >
                      <div>확인하기</div>
                      <ChevronRight className='h-4 w-4' strokeWidth='1.5' />
                    </Link>
                  </>
                ) : (
                  <>
                    <div className='text-[13px] font-medium text-center'>
                      현재 납부하실 수수료가 없습니다
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <Devider />
          <BiddingOrder
            setIsSideMenu={setIsSideMenu}
            dealerOrderListData={dealerOrderListData}
          />
          <Devider />
          <MyOrderList searchedMyOrderList={searchedMyOrderList} />
          <MainFooter />
        </div>

        <SideMenu_BiddingOrder
          isSideMenu={isSideMenu}
          setIsSideMenu={setIsSideMenu}
          dealerOrderListData={dealerOrderListData}
          hasNextPage={hasNextPage}
          fetchNextPage={fetchNextPage}
        />
      </>
    )
  );
};

export default Old_DealerMainPage;
