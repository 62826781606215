import { CustomerOrderDetail } from '@carsayo/types';
import { ChevronDown } from 'lucide-react';
import { useState } from 'react';
import { cn } from 'utils';
import ReactHtmlParser from 'react-html-parser';
import { Button } from 'components/ui/button';
import dayjs from 'dayjs';
import download from 'utils/download';

const ProcessTab = ({ quoteDetail }: { quoteDetail: CustomerOrderDetail }) => {
  const estimateFile = quoteDetail.bidList.find(
    (item) => item.dealer.id === quoteDetail.manager?.id,
  )?.estimateFile;

  return (
    <div className='flex flex-col w-full px-5 pt-6'>
      <ul className='flex flex-col gap-5'>
        {quoteDetail?.processCardList &&
          quoteDetail.processCardList.length > 0 &&
          quoteDetail.processCardList.map((item, index) => {
            const [isShow, setIsShow] = useState(false); // 내용 더보기 여부
            const memberType = (item as any).created_member_id?.includes(
              'customer',
            )
              ? quoteDetail.type === 'sellCar'
                ? '판매'
                : '주문'
              : quoteDetail.type === 'sellCar'
                ? '매입'
                : '주문';

            const status = item.state === 'holded' ? '보류' : '취소';

            const result = `${memberType} ${status}`;
            return (
              <li
                key={`process_${index}`}
                className='bg-white p-4 rounded-[16px] shadow-[0px 6px 20px 0px #0000000D] flex flex-col gap-5'
              >
                <div className='border-b flex gap-[10px] pb-4 items-center'>
                  <div className='rounded-full bg-[#E3E7EA] flex items-center justify-center font-[600] text-[14px] leading-[100%] w-[26px] h-[26px]'>
                    {quoteDetail.processCardList.length - index < 10
                      ? `${quoteDetail.processCardList.length - index}`
                      : quoteDetail.processCardList.length - index}
                  </div>
                  <div className='flex gap-[6px] items-center'>
                    <span className='font-[600] text-[16px] leading-[100%] flex items-center gap-2'>
                      {['canceled', 'holded', 'resumed'].includes(
                        item.state as string,
                      ) ? (
                        (item as any).created_member_id?.includes(
                          'customer',
                        ) ? (
                          <div className='flex gap-1 items-center border-r border-[#E5EAEE] pr-2'>
                            <img
                              src='/assets/images/png/customer.png'
                              alt='고객'
                              className='w-5 h-5'
                            />
                            고객
                          </div>
                        ) : (
                          <div className='flex gap-1 items-center border-r border-[#E5EAEE] pr-2'>
                            <img
                              src='/assets/images/png/car_manager.png'
                              alt='카 매니저'
                              className='w-5 h-5'
                            />
                            카 매니저
                          </div>
                        )
                      ) : null}

                      {['canceled', 'holded'].includes(item.state as string)
                        ? result
                        : item.title}
                    </span>
                    {item.state === 'accepted' && (
                      <span
                        className={cn(
                          'font-[500] text-[13px] leading-[150%] text-[#009E59] bg-[#E0F3EB] rounded-[6px] py-[2px] px-[6px]',
                        )}
                      >
                        낙찰
                      </span>
                    )}
                  </div>
                </div>

                <div>
                  <div className='flex flex-col gap-6'>
                    {['accepted', 'canceled', 'holded'].includes(
                      item.state as string,
                    ) && (
                      <div>
                        <div
                          className={cn(
                            'whitespace-pre-wrap w-full mb-3 overflow-hidden transition-all',
                            !isShow &&
                              ((item.content.match(/\n/g) || []).length > 2 ||
                                item.content.length > 100) &&
                              'line-clamp-3',
                          )}
                        >
                          {item.state === 'accepted' ? (
                            item.content ? (
                              ReactHtmlParser(
                                item.content.replaceAll('\n', '<br/>'),
                              )
                            ) : (
                              '내용 없음'
                            )
                          ) : ['canceled', 'holded'].includes(
                              item.state as string,
                            ) ? (
                            <>
                              [{item.title}]<br />
                              {ReactHtmlParser(
                                item.content.replaceAll('\n', '<br/>'),
                              )}
                            </>
                          ) : (
                            ''
                          )}
                        </div>
                        <div className='font-[500] text-[14px] leading-[100%] flex justify-between items-center'>
                          <div className='text-[#494AF1]'>
                            {dayjs(item.created_at).format('YY.MM.DD HH:mm')}
                          </div>
                          {((item.content.match(/\n/g) || []).length > 2 ||
                            item.content.length > 100) && (
                            <button
                              className='flex gap-[3px] items-center text-[#666666]'
                              onClick={() => setIsShow(!isShow)}
                            >
                              {isShow ? '접기' : '더보기'}
                              <ChevronDown
                                strokeWidth={1.2}
                                color='#666666'
                                className={cn('w-4 h-4 transition-transform', {
                                  'rotate-180': isShow,
                                })}
                              />
                            </button>
                          )}
                        </div>
                      </div>
                    )}

                    {item.state === 'accepted' && (
                      <div className='flex flex-col gap-3'>
                        {quoteDetail.type === 'sellCar' && (
                          <div className='border border-[#E5E5EC] rounded-[10px] flex justify-between items-center py-[15px] px-4 leading-[150%]'>
                            <div className='font-[500] text-[14px]'>
                              매입 가격
                            </div>
                            <div className='font-[600] text-[16px] flex gap-1 items-center'>
                              {item.additionalInfo_1?.content}
                              {/* {item.purchasePrice?.toLocaleString()}
                            <span className='text-[#555555] text-[14px]'>
                              원
                            </span> */}
                            </div>
                          </div>
                        )}

                        {estimateFile && (
                          <Button
                            className='bg-[#F3F6F9] text-[#222222]'
                            onClick={() => {
                              estimateFile !== null
                                ? download(estimateFile)
                                : null;
                            }}
                          >
                            견적서 보기
                          </Button>
                        )}
                      </div>
                    )}
                  </div>

                  {!['accepted', 'canceled', 'holded'].includes(
                    item.state as string,
                  ) && (
                    <div className='flex flex-col gap-[13px]'>
                      {item.state === 'setMeeting' ? (
                        <div className='font-[500] leading-[150%]'>
                          <span className='text-[#009E59] font-[600]'>
                            {dayjs(item.content.split('%%')[1]).format(
                              'YYYY.MM.DD(dd)',
                            )}{' '}
                          </span>
                          로 미팅 일정이{' '}
                          {item.content.includes('변경') ? '변경' : '결정'}
                          되었습니다.
                        </div>
                      ) : (
                        <div
                          className='font-[500] leading-[150%]'
                          dangerouslySetInnerHTML={{
                            __html: item.content.replaceAll('\n', '<br/>'),
                          }}
                        ></div>
                      )}

                      <div className='text-[#494AF1] font-[500] text-[14px] leading-[100%]'>
                        {dayjs(item.created_at).format('YY.MM.DD HH:mm')}
                      </div>
                    </div>
                  )}

                  {/* 주문 취소 */}
                  {item.state === 'canceled'}
                </div>
              </li>
            );
          })}
      </ul>
    </div>
  );
};

export default ProcessTab;
