// 추후 일반회원 견적함과 통합해야 합니다.

import { useEffect, useLayoutEffect } from 'react';
import { useParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { useQuery } from '@tanstack/react-query';
import { getManagerQuoteDetailApi } from 'apis/quote';
import { CircularProgress } from '@mui/material';
import useOnResumeStore from 'store/useOnResumeStore';
import DealerOrderDetailHeader from './components/header';
import DealerOrderDetailInfo from './components/info';
import DealerOrderDetailMemo from './components/features/memo';
import DealerOrderDetailProgressEllipsis from './components/features/progress_ellipsis';

export default function DealerOrderDetailCarManager() {
  const { id } = useParams();

  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const { setOnResume } = useOnResumeStore();

  // 주문 상세 데이터 조회
  const {
    data: orderDetail,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ['orderDetail', id],
    queryFn: () => getManagerQuoteDetailApi(id?.toString() ?? ''),
    staleTime: 0,
    gcTime: 5 * 60 * 1000,
    enabled: !!id,
  });

  // 상하단 설정
  useLayoutEffect(() => {
    if (!id) return;

    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: 'back',
      title: '',
      /**
       * @note 진행중인 주문은 ...버튼이 추가됩니다. (주문 보류/취소 기능)
       */
      rightElement: (
        <div className='flex items-center gap-2.5'>
          <DealerOrderDetailMemo orderId={id} />
          {orderDetail?.state === 'progressing' && (
            <DealerOrderDetailProgressEllipsis orderDetail={orderDetail} />
          )}
        </div>
      ),
    });
    setIsBottomTabbar(false);
  }, [orderDetail]);

  // 데이터

  useEffect(() => {
    // 푸시나 앱 새로고침 시 데이터 재조회
    setOnResume(async () => {
      refetch();
    });
  }, []);

  return (
    <div className='relative flex flex-col min-h-full'>
      {/* Loader */}
      {isLoading && (
        <div className='absolute h-full inset-0 flex items-center justify-center'>
          <CircularProgress />
        </div>
      )}

      {orderDetail && (
        <>
          {/* 상단 동작 영역 */}
          <DealerOrderDetailHeader orderDetail={orderDetail} />

          {/* Divider */}
          <div className='w-full h-2 bg-[#F3F6F9]'></div>

          {/* 하단 주문 정보 영역 */}
          <DealerOrderDetailInfo orderDetail={orderDetail} />
        </>
      )}
    </div>
  );
}
