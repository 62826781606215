/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { SidoData, SidoId, SigunguData, SigunguId } from '@carsayo/types';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import SubPage from 'components/view/SubPage';
import Button from 'components_v3/ui/Button';
import { ChevronLeft, Loader2, LocateFixed } from 'lucide-react';
import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { RepairShopListFilter } from './repairMain';
import useKakaoMap_V3 from 'hooks/useKakaoMap';
import V3_Button from 'components_v3/ui/Button';

type props = {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  filter: RepairShopListFilter;
  setFilter: (value: RepairShopListFilter) => void;
};

function SelectCurrentLocation({
  isOpen,
  setIsOpen,
  filter,
  setFilter,
}: props) {
  const navigate = useNavigate();

  const mapRef = useRef<HTMLDivElement>(null);
  const kakaoMap = useKakaoMap_V3({
    containerRef: mapRef,
  });

  useEffect(() => {
    if (kakaoMap.currentPosition) {
      setFilter({
        ...filter,
        location: {
          address:
            kakaoMap.currentPosition?.address?.address?.address_name ?? '',
          lat: kakaoMap.currentPosition.lat,
          lng: kakaoMap.currentPosition.lng,
        },
      });
    }
  }, [kakaoMap.currentPosition]);

  return (
    <div className='pb-2.5 flex-auto flex flex-col justify-between items-center'>
      <div className='relative h-full flex flex-auto items-center justify-center w-full border-t border-[#E5E5EC]'>
        {kakaoMap.isLoading && (
          <div className='absolute top-0 left-0 w-full h-full z-10 bg-black/40 flex items-center justify-center'>
            <Loader2 className='w-14 h-14 text-white animate-spin' />
          </div>
        )}
        <div
          className='kakaoMapContainer w-full h-full pointer-events-auto touch-auto'
          ref={mapRef}
        ></div>
        {!kakaoMap.isLoading && (
          <div
            onClick={() => kakaoMap.moveToLocationPosition()}
            className='absolute right-4 bottom-4 rounded-full z-10 bg-white p-2 cursor-pointer'
          >
            {kakaoMap.geoState === 'loading' ? (
              <Loader2 className='animate-spin' />
            ) : (
              <LocateFixed />
            )}
          </div>
        )}
      </div>

      <div className='relative w-full bg-white px-5 pt-6 pb-2.5 gap-[20px] flex flex-col justify-between items-start min-h-[190px] shadow-[0px_0px_15px_#1111111A] z-10 '>
        <div>
          <div className='text-[#222] text-[18px] font-semibold leading-[28.8px] tracking-[-0.36px]'>
            {kakaoMap.currentPosition?.address?.road_address?.address_name ??
              kakaoMap.currentPosition?.address?.address?.address_name}
          </div>
          <div className='text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px] mt-1'>
            {kakaoMap.currentPosition?.address?.road_address?.building_name}
          </div>
        </div>
        <div className='flex w-full justify-between items-center gap-[7px]'>
          <V3_Button
            className='text-[16px] leading-[16px] font-[500]'
            onClick={() => {
              navigate(-1);
            }}
          >
            설정
          </V3_Button>
        </div>
      </div>
    </div>
  );
}

export default function SelectLocationSubpage({
  isOpen,
  setIsOpen,
  filter,
  setFilter,
}: props) {
  const positionList = [
    {
      id: 'direct',
      label: '직접설정',
      text: filter.location.address ?? '미선택',
    },
    {
      id: 'current',
      label: '현재위치',
      text: '위치 설정 ',
    },
  ];
  const [currentState, setCurrentState] = useState('direct');
  const [selected, setSelected] = useState<SidoId | null>(null);
  const [selected02, setSelected02] = useState<SigunguId | null>(null);
  const navigate = useNavigate();

  const handleResidenceRegionChange = (value: SigunguId) => {
    setSelected02(value);
  };

  const handleSelected = (item: { sigunguId: SigunguId } | SidoId) => {
    if (typeof item === 'number') {
      setSelected(item);

      if (item === 8) {
        // 세종시
        handleResidenceRegionChange(76);
        setFilter({
          ...filter,
          location: {
            lat: null,
            lng: null,
            address: '세종',
          },
        });
        return;
      }

      const sido = SidoData.find((el) => el.id === item);
      setFilter({
        ...filter,
        location: {
          lat: null,
          lng: null,
          address: sido?.name_short ?? '',
        },
      });
    } else {
      const sido = SidoData.find((el) => el.id === selected);
      const sigungu = SigunguData.find((el) => el.id === item.sigunguId);
      setFilter({
        ...filter,
        location: {
          lat: null,
          lng: null,
          address: sido?.name_short + ' ' + sigungu?.name,
        },
      });

      handleResidenceRegionChange(item.sigunguId);
    }
  };

  const resetSelected = () => {
    setSelected(null);
    setSelected02(null);
    setFilter({
      ...filter,
      location: {
        lat: null,
        lng: null,
        address: null,
      },
    });
  };

  useEffect(() => {
    if (!isOpen) {
      setCurrentState('direct');
      setSelected(null);
      setSelected02(null);
    }
  }, [isOpen]);

  return (
    <SubPage isOpen={isOpen} setIsOpen={setIsOpen}>
      <div className='flex flex-none h-[60px] items-center justify-between px-4'>
        <a onClick={() => navigate(-1)}>
          <ChevronLeft
            width={32}
            height={32}
            strokeWidth={1.5}
            className='w-[32px] object-contain'
          />
        </a>
        <div className='text-[20px] leading-[20px] font-[600]'>위치 설정</div>
        <div className='min-w-5'></div>
      </div>

      {/* 위치정보 타입 설정 */}
      <div className='flex-none mb-[24px] px-5 pt-5'>
        <SelectV3
          onValueChange={(value) => {
            setCurrentState(value);
            resetSelected();
          }}
          value={
            positionList.find((item) => item.id === currentState)?.id ??
            'direct'
          }
          renderValue={() => (
            <div className='flex justify-start items-center gap-[10px]'>
              {currentState === 'direct' ? (
                <span className='text-[13px] leading-[13px] font-[600] text-[#222] inline-flex justify-center items-center py-1.5 px-2.5 min-w-[65px] h-[25px] bg-[#E6ECF2] rounded-full'>
                  직접설정
                </span>
              ) : (
                <span className='text-[13px] leading-[13px] font-[600] text-[#484AF5] inline-flex justify-center items-center py-1.5 px-2.5 min-w-[65px] h-[25px] bg-[#F6F6FC] rounded-full'>
                  현재위치
                </span>
              )}

              <span className='text-[16px] leading-[16px] font-[400]'>
                {positionList.find((item) => item.id === currentState)?.text ??
                  'direct'}
              </span>
            </div>
          )}
        >
          {positionList.map((item) => (
            <SelectV3Item value={item.id} key={item.id}>
              <div className='flex justify-start items-center gap-[10px]'>
                {item.id === 'direct' ? (
                  <span className='text-[13px] leading-[13px] font-[600] text-[#222] inline-flex justify-center items-center py-1.5 px-2.5 min-w-[65px] h-[25px] bg-[#E6ECF2] rounded-full'>
                    직접설정
                  </span>
                ) : (
                  <span className='text-[13px] leading-[13px] font-[600] text-[#484AF5] inline-flex justify-center items-center py-1.5 px-2.5 min-w-[65px] h-[25px] bg-[#F6F6FC] rounded-full'>
                    현재위치
                  </span>
                )}
                <span className='text-[16px] leading-[16px] font-[400]'>
                  {item?.text ?? ''}
                </span>
              </div>
            </SelectV3Item>
          ))}
        </SelectV3>
      </div>

      {currentState === 'direct' && (
        <div className='px-[20px] overflow-y-auto pt-[20px] pb-[10px] flex-auto flex justify-between items-start flex-col'>
          {/* 직접선택 박스 */}
          <div className='w-full'>
            {selected && (
              <div className='flex h-[54px] items-center bg-[#F3F6F9] py-[13px] px-4 mb-6 rounded-[10px]'>
                <div
                  onClick={() => {
                    resetSelected();
                  }}
                  className='flex justify-start items-center gap-[6px] text-[16px] leading-[24px] font-[600] text-[#222]'
                >
                  {
                    SidoData.find((el) => {
                      return el.id === selected;
                    })?.name
                  }
                </div>
              </div>
            )}
            <div className=''>
              <div className='grid grid-cols-3 gap-[7px] overflow-y-auto'>
                {selected
                  ? SigunguData.filter((el) => {
                      return el.sidoId === selected && selected !== 8;
                    }).map((item, index) => (
                      <label
                        key={index}
                        className='relative flex items-center justify-center rounded-[9px] border py-3 px-4 text-[14px] leading-[14px] min-h-[50px] font-[500] has-[:checked]:border-primary has-[:checked]:text-[#484AF5] has-[:checked]:bg-[#F6F6FC]'
                        onClick={() => handleSelected({ sigunguId: item.id })}
                      >
                        <input
                          type='radio'
                          name='region'
                          value={index}
                          className='w-0 appearance-none'
                        />
                        {item.name}
                      </label>
                    ))
                  : SidoData.map((item, index) => (
                      <label
                        key={item.name}
                        className='relative flex items-center justify-center rounded-[9px] border py-3 px-4 text-[14px] leading-[14px] min-h-[50px] font-[500] has-[:checked]:border-primary has-[:checked]:text-[#484AF5] has-[:checked]:bg-[#F6F6FC]'
                        onClick={() => handleSelected(item.id)}
                      >
                        <input
                          type='radio'
                          name='region'
                          value={item.name}
                          className='w-0 appearance-none'
                        />
                        {item.name}
                      </label>
                    ))}
              </div>
            </div>
          </div>

          <div className='w-full pt-8 flex justify-center items-center gap-2.5'>
            <Button
              disabled={selected === null}
              className='!w-[85px] !min-w-[85px] whitespace-nowrap disabled:!bg-white disabled:!text-[#B2B7BB] disabled:!border-[#E5E5EC]'
              onClick={() => {
                resetSelected();
              }}
            >
              초기화
            </Button>
            <Button
              // 현재 기능이 없어서 뒤로가도록 처리했습니다.
              onClick={() => navigate(-1)}
              theme='primary'
              disabled={selected === null || selected02 === null}
            >
              선택
            </Button>
          </div>
        </div>
      )}

      {currentState === 'current' && (
        <SelectCurrentLocation
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          filter={filter}
          setFilter={setFilter}
        />
      )}
    </SubPage>
  );
}
