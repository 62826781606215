/* eslint-disable @typescript-eslint/no-unused-vars */
import { CustomerOrderDetail, FileInfo } from '@carsayo/types';
import { Button } from 'components/ui/button';
import { cn } from 'utils';
import ReactHtmlParser from 'react-html-parser';
import { ChevronDown } from 'lucide-react';
import { useState } from 'react';
import useSystemStore from 'store/useSystemStore';
import { selectManagerApi } from 'apis/quote';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import ConfirmV2 from 'components/common/v3/confirm';
import dayjs from 'dayjs';
import download from 'utils/download';
import { useNavigate } from 'react-router-dom';

const QutoesTab = ({
  quoteDetail,
  refetch,
}: {
  quoteDetail: CustomerOrderDetail;
  refetch: () => void;
}) => {
  const navigate = useNavigate();
  const { setLoadingDim } = useSystemStore();

  const [selectMgId, setSelectMgId] = useState<string | null>(null);
  const [isSelectConfirm, setIsSelectConfirm] = useState(false);

  const estimateFile = quoteDetail.bidList.find(
    (item) => item.dealer.id === quoteDetail.manager?.id,
  )?.estimateFile;

  const handleClickEvents = {
    selectManager: async () => {
      if (!quoteDetail || !quoteDetail.id || !selectMgId) return;
      setLoadingDim(true);
      try {
        const params = {
          orderId: quoteDetail.id,
          managerId: selectMgId,
        };

        const result = await selectManagerApi(params);

        if (result) {
          CarsayoToast.text(`${result.manager?.name_nick}님이 선택됐습니다.`);
          refetch();
          navigate(
            {
              search: '',
            },
            {
              replace: true,
            },
          );
          setIsSelectConfirm(false);
        }
      } catch (error: any) {
        CarsayoToast.error(error?.message);
      }
      setLoadingDim(false);
    },
  };
  return (
    <div className='flex flex-col gap-6 w-full px-5 pt-6'>
      <div className='text-[#222] text-[18px] font-[600] leading-[100%] flex gap-2'>
        받은 견적
        <span
          className={cn(
            quoteDetail.bidList.length === 0
              ? 'text-[#999999]'
              : 'text-[#494AF1]',
          )}
        >
          {quoteDetail.bidList.length}건
        </span>
      </div>

      <div className={cn('flex-1 flex flex-col')}>
        {quoteDetail.bidList.length === 0 && (
          <div className='flex flex-col gap-4 text-[#A1A7AC] items-center m-auto'>
            <div className='font-[600] text-[18px] leading-[160%]'>
              견적서를 작성하고 있어요.
            </div>
            <div className='font-[400] text-[15px] leading-[150%] text-center'>
              조금만 기다리시면 카사요가 인증한
              <br />
              전국 카 매니저님들의 견적을 확인할 수 있습니다.
            </div>
          </div>
        )}

        {quoteDetail.bidList.length > 0 && (
          <div className='flex flex-col gap-5'>
            {quoteDetail.bidList.map((item, index) => {
              const [isShow, setIsShow] = useState(false); // 내용 더보기 여부

              return (
                <div
                  key={`bid_${item.dealer}_${index}`}
                  className='bg-white rounded-[16px] py-5 px-4'
                >
                  <div
                    className='mb-4 flex gap-4 items-center'
                    onClick={() => navigate(`/dealerInfo/${item.dealer.id}`)}
                  >
                    <div className='min-w-14 h-14 rounded-full overflow-hidden'>
                      <img
                        src={
                          item?.dealer?.profileImage_url ||
                          '/assets/images/v2/Ellipse.png'
                        }
                        alt='프로필'
                        width={56}
                        height={56}
                        className='object-contain'
                      />
                    </div>
                    <div className='flex flex-col gap-2 overflow-hidden'>
                      <div className='font-[600] text-[16px] leading-[100%] overflow-hidden text-ellipsis whitespace-nowrap'>
                        {item.dealer.name_nick}
                      </div>
                      <div className='flex gap-1 items-center text-[#555555] text-[14px] font-[500] leading-[100%]'>
                        <div className='flex gap-[2px] items-center'>
                          <img
                            src='/assets/images/png/star.png'
                            alt='star'
                            width={12}
                            height={12}
                          />
                          {item.dealer.dealerInfo?.rating}
                        </div>
                        <span className='text-[#D5DADF]'>•</span>
                        {quoteDetail.type === 'sellCar'
                          ? '중고차 매니저'
                          : '신차 매니저'}
                      </div>
                    </div>
                  </div>

                  {/* 견적 설명 */}
                  <div className='mb-6'>
                    <div
                      className={cn(
                        'whitespace-pre-wrap w-full mb-3 overflow-hidden transition-all',
                        !isShow &&
                          (item.content?.match(/\n/g) || []).length > 2 &&
                          'line-clamp-3',
                      )}
                    >
                      {item.content
                        ? ReactHtmlParser(item.content)
                        : '내용 없음'}
                    </div>
                    <div className='font-[500] text-[14px] leading-[100%] flex justify-between items-center'>
                      <div className='text-[#494AF1]'>
                        {dayjs(item.created_at).format('YY.MM.DD HH:mm')}
                      </div>
                      {item.content &&
                        ((item.content.match(/\n/g) || []).length > 2 ||
                          item.content.length > 100) && (
                          <button
                            className='flex gap-[3px] items-center text-[#666666]'
                            onClick={() => setIsShow(!isShow)}
                          >
                            {isShow ? '접기' : '더보기'}
                            <ChevronDown
                              strokeWidth={1.2}
                              color='#666666'
                              className={cn('w-4 h-4 transition-transform', {
                                'rotate-180': isShow,
                              })}
                            />
                          </button>
                        )}
                    </div>
                  </div>

                  <div className='flex flex-col gap-3'>
                    {quoteDetail.type === 'sellCar' && (
                      <div className='border border-[#E5E5EC] rounded-[10px] flex justify-between items-center py-[15px] px-4 leading-[150%]'>
                        <div className='font-[500] text-[14px]'>매입 가격</div>
                        <div className='font-[600] text-[16px] flex gap-1 items-center'>
                          {item.purchasePrice?.toLocaleString()}
                          <span className='text-[#555555] text-[14px]'>원</span>
                        </div>
                      </div>
                    )}

                    <div className='flex gap-[7px]'>
                      {quoteDetail.type !== 'sellCar' && item.estimateFile && (
                        <Button
                          variant='secondary'
                          onClick={() => {
                            item.estimateFile !== null
                              ? download(item.estimateFile as FileInfo)
                              : null;
                          }}
                        >
                          견적서 보기
                        </Button>
                      )}
                      <Button
                        onClick={() => {
                          setIsSelectConfirm(true);
                          setSelectMgId(item.dealer.id);
                        }}
                      >
                        카 매니저 선택
                      </Button>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
      </div>

      <ConfirmV2
        title={'담당 카 매니저 결정'}
        desc={`선택하신 카 매니저분${quoteDetail.type === 'sellCar' ? '에게' : '과'}
${quoteDetail.type === 'sellCar' ? '판매 ' : ''}진행 하시겠습니까?`}
        confirmText={'진행'}
        cancelText={'취소'}
        confirmColor={
          'text-[#484AF5] text-center text-[15px] font-[500] leading-[100%] tracking-[-0.3px]'
        }
        isCloseButton={true}
        isOpen={isSelectConfirm}
        setIsOpen={setIsSelectConfirm}
        onConfirm={handleClickEvents.selectManager}
      />
    </div>
  );
};

export default QutoesTab;
