export const TestCustomerLogin =
  process.env.REACT_APP_NODE_ENV === 'development' ||
  process.env.REACT_APP_NODE_ENV === 'localhost'
    ? {
        '11111111': `${process.env.REACT_APP_API_URL}/member/test/customerlogin`,
        '11111112': `${process.env.REACT_APP_API_URL}/member/test/customerlogin?key=10`,
        '11111113': `${process.env.REACT_APP_API_URL}/member/test/customerlogin?key=20`,
        '11111114': `${process.env.REACT_APP_API_URL}/member/test/customerlogin?key=30`,
        '11111115': `${process.env.REACT_APP_API_URL}/member/test/customerlogin?key=40`,
        '11111116': `${process.env.REACT_APP_API_URL}/member/test/customerlogin?key=50`,
        '11111117': `${process.env.REACT_APP_API_URL}/member/test/customerlogin?key=60`,
        '11111118': `${process.env.REACT_APP_API_URL}/member/test/customerlogin?key=70`,
        '11111119': `${process.env.REACT_APP_API_URL}/member/test/customerlogin?key=80`,
      }
    : process.env.REACT_APP_NODE_ENV === 'production'
      ? {
          '01015661571': 'https://api.carsayo.net/member/test/carsayo/customer',
          '0803308877': 'https://api.carsayo.net/member/test/apple',
          '0807882570': 'https://api.carsayo.net/member/test/google',
        }
      : {};

export const TestDealerLogin =
  process.env.REACT_APP_NODE_ENV === 'development' ||
  process.env.REACT_APP_NODE_ENV === 'localhost'
    ? {
        '11111111': `${process.env.REACT_APP_API_URL}/member/test/dealerlogin/newcar`,
        '11111112': `${process.env.REACT_APP_API_URL}/member/test/dealerlogin/newcar?key=10`,
        '11111113': `${process.env.REACT_APP_API_URL}/member/test/dealerlogin/newcar?key=20`,
        '22222221': `${process.env.REACT_APP_API_URL}/member/test/dealerlogin/usedcar`,
        '22222222': `${process.env.REACT_APP_API_URL}/member/test/dealerlogin/usedcar?key=10`,
        '22222223': `${process.env.REACT_APP_API_URL}/member/test/dealerlogin/usedcar?key=20`,
        '33333331': `${process.env.REACT_APP_API_URL}/member/test/dealerlogin/leaseRent`,
        '33333332': `${process.env.REACT_APP_API_URL}/member/test/dealerlogin/leaseRent?key=10`,
        '33333333': `${process.env.REACT_APP_API_URL}/member/test/dealerlogin/leaseRent?key=20`,
        '33333334': `${process.env.REACT_APP_API_URL}/member/test/dealerlogin/leaseRent?key=30`,
        '33333335': `${process.env.REACT_APP_API_URL}/member/test/dealerlogin/leaseRent?key=40`,
        '33333336': `${process.env.REACT_APP_API_URL}/member/test/dealerlogin/leaseRent?key=50`,
      }
    : process.env.REACT_APP_NODE_ENV === 'production'
      ? {
          '0803308877': 'https://api.carsayo.net/member/test/apple',
          '0807882570': 'https://api.carsayo.net/member/test/google',
          '01015661571':
            'https://api.carsayo.net/member/test/carsayo/dealer/newcar',
          '01015661572':
            'https://api.carsayo.net/member/test/carsayo/dealer/usedcar',
          '01015661573':
            'https://api.carsayo.net/member/test/carsayo/dealer/leaseRent',
        }
      : {};
