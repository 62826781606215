import { DealerShort } from '@carsayo/types';
import { DealerOrderDetailProps } from 'pages/v3/orderDetailTemp/type';
import useMemberStore from 'store/useMemberStore';
import Star from '../../images/Star';
import NoDataTab from '../item/NoDataTab';
import { useNavigate } from 'react-router-dom';

const OrderBidderCard = ({
  bidder,
  isMyBid,
}: {
  bidder: DealerShort;
  isMyBid?: boolean;
}) => {
  const navigate = useNavigate();

  const bidderType: '신차 매니저' | '중고차 매니저' | '리스/렌트 매니저' =
    bidder.dealerInfo?.type === 'newcar'
      ? '신차 매니저'
      : bidder.dealerInfo?.type === 'usedcar'
        ? '중고차 매니저'
        : '리스/렌트 매니저';

  return (
    <div
      onClick={() => {
        navigate(`/dealerInfo/${bidder.id}`);
      }}
      className='rounded-[16px] bg-white shadow-[0px_6px_20px_0px_rgba(0,_0,_0,_0.05)] px-4 py-5 flex gap-4'
    >
      <div className='w-[56px] h-[56px] rounded-full bg-gradient-to-b from-[#F3F6F9] to-[#FFFFFF]'>
        <img
          src={bidder.profileImage_url}
          alt='profile'
          className='w-full h-full object-cover rounded-full'
        />
      </div>
      <div className='flex flex-col gap-2 items-start justify-center'>
        <div className='relative text-[#222] font-semibold text-[16px] leading-[16px] tracking-[-0.32px]'>
          {bidder.name_nick}
          {isMyBid && (
            <div className='absolute top-[-3px] right-[-44px]'>
              <div className='px-3 py-1 rounded-[6px] bg-[#F0F0FD] text-[#494AF1] text-center text-[13px] font-semibold leading-[13px] tracking-[-0.26px]'>
                나
              </div>
            </div>
          )}
        </div>
        <div className='flex items-center'>
          <Star />
          <div className='text-[#555] text-center text-[14px] font-medium leading-[14px] tracking-[-0.28px] ml-0.5'>
            {Number(bidder.dealerInfo?.rating).toFixed(1)}
          </div>

          {/* Dot */}
          <div className='flex flex-col w-4 items-center justify-center'>
            <div className='w-[3px] h-[3px] bg-[#D5DADF] rounded-full'></div>
          </div>

          <div className='overflow-hidden text-[#555] text-[14px] font-medium leading-[14px] tracking-[-0.28px] truncate'>
            {bidderType}
          </div>
        </div>
      </div>
    </div>
  );
};

/** 참여자 탭 */
export default function DealerOrderDetailInfoBidder({
  orderDetail,
}: DealerOrderDetailProps) {
  const { loginMember } = useMemberStore();

  return (
    <div className='relative flex-auto bg-[#F3F6F9] px-5 pt-6 pb-[50px] space-y-6'>
      {/* 입찰자가 없을 떄 */}
      {orderDetail.processCardList.length === 0 && (
        <div className='absolute top-0 left-0 py-5 w-full h-full flex items-center justify-center'>
          <NoDataTab
            title='아직 입찰자가 없어요.'
            content='낙찰되면 계약을 진행할 수 있어요.'
          />
        </div>
      )}

      {/* 입찰자가 있을 떄 */}
      {orderDetail.processCardList.length > 0 && (
        <>
          {/* 참여자 수 */}
          <div className='text-[#222] font-semibold text-[18px] leading-[18px] tracking-[-0.36px]'>
            참여자
            <span className='text-[#494AF1] ml-2'>
              {orderDetail.bidNumber}명
            </span>
          </div>

          <div className='relative flex flex-col gap-3'>
            {/* 내 입찰 내역 */}
            {loginMember && orderDetail.bid && (
              <OrderBidderCard bidder={loginMember} isMyBid={true} />
            )}

            {/* 다른 참여자 내역 (블러처리) */}
            {orderDetail.bidderList.length > 0 && (
              <div className='relative'>
                {orderDetail.bidderList.map((bidder) => (
                  <OrderBidderCard key={bidder.id} bidder={bidder} />
                ))}
                <div className='flex items-center justify-center absolute top-0 left-0 w-full h-full rounded-[16px] bg-[rgba(255,_255,_255,_0.50)] backdrop-blur-[15px] z-10'>
                  <div className='text-[#999] font-semibold text-[16px] leading-[24px] tracking-[-0.32px]'>
                    다른 카 매니저도 참여했어요.
                  </div>
                </div>
              </div>
            )}

            {/* 유찰일경우 전체 덮음 */}
            {orderDetail.state === 'failBid' && (
              <div className='flex items-center justify-center absolute top-0 left-0 w-full h-full rounded-[16px] bg-[rgba(255,_255,_255,_0.50)] backdrop-blur-[15px] z-10'>
                <div className='text-[#999] font-semibold text-[16px] leading-[24px] tracking-[-0.32px]'>
                  유찰(종료)되었습니다.
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </div>
  );
}
