import { OrderPurchaseForm } from 'pages/v3/order/purchase/form';
import React, { useEffect, useState } from 'react';
import { ControllerFieldState } from 'react-hook-form';
import { cn } from 'utils';

const OrderPurchaseNumberInput = ({
  field,
  form,
  fieldState,
  disabled,
}: {
  field: any;
  form: OrderPurchaseForm;
  fieldState: ControllerFieldState;
  disabled?: boolean;
}) => {
  const [displayValue, setDisplayValue] = useState<string>('');

  useEffect(() => {
    if (field.value !== null && field.value !== undefined) {
      setDisplayValue(
        new Intl.NumberFormat('ko-KR').format(field.value) + '원',
      );
    } else {
      setDisplayValue('');
    }
  }, [field.value]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // 숫자만 입력 가능하도록 처리
    const rawValue = e.target.value.replace(/[,\s원]/g, ''); // 콤마, 공백, '원' 제거
    const numericValue = Number(rawValue);

    if (!isNaN(numericValue)) {
      form.setValue(field.name, numericValue, { shouldValidate: true });
      setDisplayValue(new Intl.NumberFormat('ko-KR').format(numericValue));
    } else {
      setDisplayValue('');
    }
  };

  return (
    <input
      type='text'
      inputMode='numeric' // 모바일 키패드 숫자 전용
      pattern='[0-9]*' // 숫자만 입력 가능
      placeholder='입력해 주세요.'
      value={displayValue}
      disabled={field.value === null}
      className={cn(
        'w-full flex gap-2 h-[50px] appearance-none border border-[#E5E5EC] rounded-[8px] items-center justify-start px-4 pr-[12px] text-[#222] font-medium text-[16px] leading-[16px] tracking-[-0.32px]',
        fieldState.error &&
          'border-[#FF4D4D] focus:border-[#FF4D4D] border-solid border',
      )}
      onChange={handleChange}
    />
  );
};

export default OrderPurchaseNumberInput;
