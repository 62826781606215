import { ArrowUp, ChevronDown, X } from 'lucide-react';
import PayCard from './PayCard';
import NoPay from './NoPay';
import useSystemStore from 'store/useSystemStore';
import { useLayoutEffect, useMemo, useState } from 'react';
import { cn } from 'utils';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { Button } from 'components/ui/button';
import { api } from 'apis';
import { useQuery } from '@tanstack/react-query';
import { CircularProgress } from '@mui/material';
import {
  TossPaymentMethod,
  TossPaymentStatus,
} from 'pages/v3/order/payment/api/tosspayment';

export type TossPaymentListData = {
  status: TossPaymentStatus;
  method: TossPaymentMethod;
  payment_key: string;
  order_id: string;
  amount: number;
  card: string | null;
  virtual_account: string | null;
  transfer: string | null;
  cancels: string | null;
  receipt: string | null;
  cash_receipt: string | null;
  cash_receipts: string | null;
  purchase_item: {
    id: number;
    title: string;
    description: string;
    price: number;
    created_at: Date;
    updated_at: Date;
    image_url: string;
  };
  created_at: string; // ISO8601 날짜 문자열
  updated_at: string; // ISO8601 날짜 문자열
  requested_at: string; // ISO8601 날짜 문자열
  approved_at: string; // ISO8601 날짜 문자열
  member_customer_avaliableOrder: { used: boolean }[];
};

export const getMyPagePaymentList = async (): Promise<
  TossPaymentListData[]
> => {
  try {
    const response = await api.get(`/tosspayment/member`);
    return response?.data?.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

const FILTER_LIST = [
  {
    label: '전체',
    value: 'all',
  },
  {
    label: '결제완료',
    value: TossPaymentStatus.DONE,
  },
  {
    label: '입금 대기',
    value: TossPaymentStatus.WAITING_FOR_DEPOSIT,
  },
  {
    label: '결제취소',
    value: TossPaymentStatus.CANCELED,
  },
] as const;

export default function IncidentPay() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  const [sort, setSort] = useState('desc');
  const [filter, setFilter] =
    useState<(typeof FILTER_LIST)[number]['value']>('all');
  const [isOpen, setIsOpen] = useState(false);

  const { data: paymentListData, isFetching } = useQuery({
    queryKey: ['myPaymentList'],
    queryFn: async () => {
      const response = await getMyPagePaymentList();
      return response;
    },
  });

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      rightElement: undefined,
      leftElement: 'back',
      title: '결제내역',
    });

    setIsBottomTabbar(false);
  }, []);

  const filteredPaymentList = useMemo(() => {
    if (filter === 'all') {
      return paymentListData;
    }
    return paymentListData?.filter((item) => item.status === filter);
  }, [filter, paymentListData]);

  const sortedPaymentList = useMemo(() => {
    if (!filteredPaymentList) return [];

    return [...filteredPaymentList].sort((a, b) =>
      sort === 'asc'
        ? new Date(a.created_at).getTime() - new Date(b.created_at).getTime()
        : new Date(b.created_at).getTime() - new Date(a.created_at).getTime(),
    );
  }, [sort, filteredPaymentList]);

  return (
    <div className='flex flex-col w-full h-full bg-[#F3F6F9] px-[20px] py-[30px] overflow-y-hidden'>
      {isFetching && (
        <div className='w-full h-full flex justify-center items-center'>
          <CircularProgress />
        </div>
      )}

      {!isFetching && sortedPaymentList && (
        <>
          <strong className='text-[20px] leading-[32px] font-[600] inline-block mb-6'>
            {sortedPaymentList.length}건의 결제내역이 있어요.
          </strong>
          <div className='w-full flex justify-between items-center'>
            <div className='flex gap-[20px] justify-between'>
              <button
                className='flex min-w-[78px] shrink-0 items-center justify-center gap-[4px] rounded-[100px] bg-white py-[10px] pl-[14px] pr-[8px]'
                onClick={() => setIsOpen(true)}
              >
                <span className='text-[14px] font-[600] leading-[14px]'>
                  {FILTER_LIST.find((el) => el.value === filter)?.label ||
                    '전체'}
                </span>
                <ChevronDown color='#666666' width={18} height={18} />
              </button>
            </div>
            <button
              onClick={() => {
                setSort(sort === 'asc' ? 'desc' : 'asc');
              }}
              className='flex justify-center items-center gap-1'
            >
              <span className='text-[14px] leading-[14px] font-[600] text-[#555]'>
                최신일자
              </span>
              <ArrowUp
                width={16}
                height={16}
                color='#7273F9'
                className={cn(
                  'transition-all',
                  sort === 'asc' ? 'rotate-0' : 'rotate-180',
                )}
              />
            </button>
          </div>
          {sortedPaymentList.length > 0 ? (
            <>
              <div className='w-full h-full overflow-y-hidden'>
                <div className='w-full h-full mt-2' id='paymentScroll'>
                  <ul className='w-full h-full overflow-y-scroll flex flex-col justify-start items-start gap-[20px]'>
                    {sortedPaymentList.map((item, index) => (
                      <li key={index} className='w-full'>
                        <PayCard item={item} />
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </>
          ) : (
            <NoPay />
          )}
        </>
      )}

      <Drawer open={isOpen} onOpenChange={setIsOpen}>
        <DrawerContent className='flex flex-col'>
          <DrawerHeader className='relative'>
            <DrawerTitle>필터</DrawerTitle>
            <DrawerClose asChild>
              <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
                <X className='h-7 w-7' onClick={() => setIsOpen(false)} />
              </Button>
            </DrawerClose>
          </DrawerHeader>
          <div className='p-[20px]'>
            <div className='text-[#222222] text-[15px] font-[600] mb-[16px]'>
              결제 상태
            </div>
            <ul className='flex gap-[6px] mb-[80px]'>
              {FILTER_LIST.map((el, index) => (
                <li
                  key={`filter_${index}`}
                  className={cn(
                    'py-[13px] rounded-[18px] border w-[79px] text-center',
                    filter === el.value
                      ? 'border-[#7273F9]'
                      : 'border-[#E5E5EC]',
                  )}
                >
                  <button type='button' onClick={() => setFilter(el.value)}>
                    {el.label}
                  </button>
                </li>
              ))}
            </ul>
            <div className='flex gap-[10px]'>
              <Button
                type='button'
                className='w-[85px] border border-[#E5E5EC] text-[#222222] bg-white'
                onClick={() => {
                  setFilter('all');
                }}
              >
                초기화
              </Button>
              <Button
                type='button'
                className='w-full'
                onClick={() => {
                  setIsOpen(false);
                }}
              >
                적용
              </Button>
            </div>
          </div>
        </DrawerContent>
      </Drawer>
    </div>
  );
}
