/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import {
  CarCategory,
  CarCategoryData,
  CarCategoryId,
  WholeSellingCar,
} from '@carsayo/types';
import { OrderPurchaseDefaultProps } from '../../../interface';
import { useNavigate } from 'react-router-dom';
import { cn } from 'utils';
import useMemberStore from 'store/useMemberStore';
import React, { useEffect, useMemo, useState } from 'react';
import { ChevronDown } from 'lucide-react';
import { motion } from 'framer-motion';
import { SelectCatrgory } from './drawer/selectCategory';
import { BLANKCARIMAGE } from '../../../constants';
import { OrderPurchaseForm } from '../../../form';
import WhatIsBidCouponSubpage from './subpage/WhatIsBidCoupon';

interface OrderPurchaseModelProps extends OrderPurchaseDefaultProps {}

const BrandPart = ({ targetBrand }: { targetBrand: WholeSellingCar }) => {
  const navigate = useNavigate();

  const { loginMember } = useMemberStore();

  const whiteLogo = `/assets/images/v2/brand/whiteLogo/${targetBrand.id}.png`;
  /** 흰색 로고 이미지 존재여부, 이 경우 배경색이 어두워집니다. */
  const [isWhiteLogo, setIsWhiteLogo] = useState<boolean>(
    targetBrand.type === 'domestic' || targetBrand.isSuperCar,
  );

  /** 견적 이용권 안내 서브페이지 오픈 상태 */
  const [isWhatIsBidCouponOpen, setIsWhatIsBidCouponOpen] =
    useState<boolean>(false);

  return (
    <div className='flex-none pt-2'>
      <div
        className={cn(
          'bg-[#222] w-full flex flex-col justify-start items-start px-5 pt-2.5 pb-[30px] text-white',
          !isWhiteLogo && 'bg-[#F3F6F9] text-[#222]',
        )}
      >
        <div
          className={cn(
            'w-full flex justify-between items-center gap-4 pb-2.5 border-b border-[#FFFFFF80]',
            !isWhiteLogo && 'border-[#E5EAEE]',
          )}
        >
          <div className='w-[80px] h-[80px] flex justify-center items-center'>
            <img
              src={whiteLogo}
              alt='로고 이미지'
              className='w-[80px] object-contain'
              onError={(e) => {
                e.currentTarget.src = targetBrand.image_url; // 대체 이미지 설정
                setIsWhiteLogo(false);
              }}
            />
          </div>

          <span
            className={cn(
              'text-[15px] leading-[15px] font-[500] pl-2.5 border-l-[2px] border-white',
              !isWhiteLogo && 'border-[#222]',
            )}
          >
            모델 {targetBrand.car_model.length}종
          </span>
        </div>
        <div className='pt-4 w-full flex flex-col justify-start items-start gap-[14px]'>
          <div className='w-full flex justify-between items-center gap-4'>
            <span
              className={cn(
                'text-white text-[16px] leading-[16px] tracking-[-0.32px]',
                !isWhiteLogo && 'text-[#222]',
              )}
            >
              견적 요청 가능 횟수
              <strong className='font-[600] ml-1.5 inline-block'>
                {loginMember?.customerInfo?.count.avaliableOrder.purchase}회
              </strong>
            </span>
            <button
              onClick={() => {
                navigate('/order/payment/avaliableOrder?type=purchase');
              }}
              className={cn(
                'text-[14px] leading-[14px] font-[500] py-2.5 px-2 border border-white rounded-full flex justify-center items-center min-w-[106px] h-[34px]',
                !isWhiteLogo && 'border-[#222]',
              )}
            >
              견적 요청권 구매
            </button>
          </div>
          <div
            onClick={() => {
              setIsWhatIsBidCouponOpen(true);
            }}
            className={cn(
              'cursor-pointer bg-[#000000] py-[8px] pl-[12px] pr-[18px] w-full flex justify-between items-center rounded-[8px]',
              !isWhiteLogo && 'bg-[#E5EAEE]',
            )}
          >
            <span className='text-[14px] leading-[14px] font-[400]'>
              견적 요청권이란?
            </span>
            {!isWhiteLogo ? (
              <img
                src='/assets/images/png/info_gray.png'
                alt='정보아이콘'
                className='w-6 object-contain'
              />
            ) : (
              <img
                src='/assets/images/png/info.png'
                alt='정보아이콘'
                className='w-6 object-contain'
              />
            )}
          </div>
        </div>
      </div>
      <WhatIsBidCouponSubpage
        open={isWhatIsBidCouponOpen}
        setOpen={setIsWhatIsBidCouponOpen}
      />
    </div>
  );
};

const ModelType = ['normal', 'eletric'] as const;
type ModelType = (typeof ModelType)[number];

const TabSelector = ({
  currentTab,
  setCurrentTab,
  modelCount,
}: {
  currentTab: ModelType;
  setCurrentTab: React.Dispatch<React.SetStateAction<ModelType>>;
  modelCount: {
    normal: number;
    eletric: number;
  };
}) => {
  const [isHidden, setIsHidden] = useState<boolean>(false);

  useEffect(() => {
    if (modelCount.normal === 0) {
      setCurrentTab('eletric');
      setIsHidden(true);
    } else if (modelCount.eletric === 0) {
      setCurrentTab('normal');
      setIsHidden(true);
    }
  }, [modelCount]);

  return (
    <div
      className={cn(
        'flex-none h-[50px] flex border-b border-[#E5E5EC] text-[16px] font-medium leading-[16px] tracking-[-0.32px] text-[#999] bg-white',
        isHidden && 'hidden',
      )}
    >
      <button
        className={cn(
          'flex-auto',
          currentTab === 'normal' &&
            'text-[#222] pt-0.5 border-b-2 border-[#222]',
        )}
        onClick={() => setCurrentTab('normal')}
      >
        일반차
      </button>
      <button
        className={cn(
          'flex-auto',
          currentTab === 'eletric' &&
            'text-[#222] pt-0.5 border-b-2 border-[#222]',
        )}
        onClick={() => setCurrentTab('eletric')}
      >
        전기차
      </button>
    </div>
  );
};

const ModelList = ({
  form,
  currentTab,
  targetBrand,
}: {
  form: OrderPurchaseForm;
  currentTab: ModelType;
  targetBrand: WholeSellingCar;
}) => {
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [viewType, setViewType] = useState<'list' | 'grid'>('list');

  const [filter, setFilter] = useState<{
    category: CarCategory | null;
  }>({
    category: null,
  });

  useEffect(() => {
    setFilter({ ...filter, category: null });
  }, [currentTab]);

  /** 페이지 첫 진입 시 모델 선택 내역 초기화
   * @temp 나중에 이거 다 중앙화할거임 > 안하면 관리못함
   */
  useEffect(() => {
    form.setValue('carModelId', undefined);
    form.setValue('carTrimId', undefined);
    form.setValue('carGradeId', undefined);
    form.setValue('carColorId', undefined);
    form.setValue('interiorCarColor', undefined);
    form.setValue('optionList', []);
  }, []);

  /** 전기차 선택 필터 */
  const eletricCarFilteredModel = useMemo(() => {
    return targetBrand.car_model.filter((el) => {
      return el.isEletric === (currentTab === 'eletric');
    });
  }, [targetBrand, currentTab]);

  const usingCategory: CarCategory[] = useMemo(() => {
    const set = new Set<CarCategoryId>();
    for (let i = 0; i < eletricCarFilteredModel.length; i++) {
      if (eletricCarFilteredModel[i].car_category?.id) {
        set.add(eletricCarFilteredModel[i].car_category?.id as CarCategoryId);
      }
    }

    const usingCategoryId = Array.from(set);

    const returnData: CarCategory[] = [];
    for (let i = 0; i < usingCategoryId.length; i++) {
      const data = CarCategoryData.find((category) => {
        return category.id === usingCategoryId[i];
      });

      if (data) {
        returnData.push({
          id: data.id,
          name: data.name,
          option: {
            allow_lease: data.allow_lease,
            allow_new: data.allow_new,
            allow_rent: data.allow_rent,
          },
        });
      }
    }

    return returnData;
  }, [eletricCarFilteredModel]);

  /** 그 외 필터링 */
  const filteredModel = useMemo(() => {
    if (!filter.category) {
      return eletricCarFilteredModel;
    }

    return eletricCarFilteredModel.filter((el) => {
      return el.car_category?.id === filter.category?.id;
    });
  }, [eletricCarFilteredModel, filter]);

  return (
    <>
      <div className='relative flex-auto'>
        <div className='sticky top-0 bg-[#F3F6F9] px-5 flex justify-between items-center py-5 gap-1 z-10'>
          <div
            onClick={() => setOpenDrawer(!openDrawer)}
            className='cursor-pointer flex justify-center items-center bg-white rounded-full py-2.5 px-3.5 gap-[5px]'
          >
            <div className='text-[#222] text-[14px] font-semibold leading-[14px] tracking-[-0.28px]'></div>
            {filter.category ? filter.category.name : '유형'}
            <motion.div
              animate={{ rotate: openDrawer ? 180 : 0 }}
              transition={{ duration: 0.3, ease: 'easeInOut' }}
            >
              <ChevronDown size={18} />
            </motion.div>
          </div>
          <div
            onClick={() => setViewType(viewType === 'list' ? 'grid' : 'list')}
            className='cursor-pointer text-[#222] text-sm font-medium leading-none tracking-[-0.28px] bg-[#E5EBF2] p-2.5 flex items-center justify-center gap-1 rounded-[8px]'
          >
            {/* 아이콘 영역 */}
            {viewType === 'list' ? (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
              >
                <g clipPath='url(#clip0_1704_52239)'>
                  <path
                    d='M2.6665 2.6665H6.6665V6.6665H2.6665V2.6665Z'
                    stroke='black'
                    strokeWidth='1.2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M9.3335 2.6665H13.3335V6.6665H9.3335V2.6665Z'
                    stroke='black'
                    strokeWidth='1.2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M2.6665 9.3335H6.6665V13.3335H2.6665V9.3335Z'
                    stroke='black'
                    strokeWidth='1.2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M9.3335 11.3335C9.3335 11.8639 9.54421 12.3726 9.91928 12.7477C10.2944 13.1228 10.8031 13.3335 11.3335 13.3335C11.8639 13.3335 12.3726 13.1228 12.7477 12.7477C13.1228 12.3726 13.3335 11.8639 13.3335 11.3335C13.3335 10.8031 13.1228 10.2944 12.7477 9.91928C12.3726 9.54421 11.8639 9.3335 11.3335 9.3335C10.8031 9.3335 10.2944 9.54421 9.91928 9.91928C9.54421 10.2944 9.3335 10.8031 9.3335 11.3335Z'
                    stroke='black'
                    strokeWidth='1.2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_1704_52239'>
                    <rect width='16' height='16' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            ) : (
              <svg
                xmlns='http://www.w3.org/2000/svg'
                width='16'
                height='16'
                viewBox='0 0 16 16'
                fill='none'
              >
                <g clipPath='url(#clip0_1704_52228)'>
                  <path
                    d='M6 4H13.3333'
                    stroke='#222222'
                    strokeWidth='1.2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M6 8H13.3333'
                    stroke='#222222'
                    strokeWidth='1.2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M6 12H13.3333'
                    stroke='#222222'
                    strokeWidth='1.2'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M3.3335 4V4.00667'
                    stroke='#222222'
                    strokeWidth='1.4'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M3.3335 8V8.00667'
                    stroke='#222222'
                    strokeWidth='1.4'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                  <path
                    d='M3.3335 12V12.0067'
                    stroke='#222222'
                    strokeWidth='1.4'
                    strokeLinecap='round'
                    strokeLinejoin='round'
                  />
                </g>
                <defs>
                  <clipPath id='clip0_1704_52228'>
                    <rect width='16' height='16' fill='white' />
                  </clipPath>
                </defs>
              </svg>
            )}
            {viewType === 'list' ? '모아보기' : '펼쳐보기'}
          </div>
        </div>
        <div className=''>
          {viewType === 'list' ? (
            <div className='flex flex-col gap-4 px-5'>
              {filteredModel.map((el) => {
                return (
                  <div
                    key={el.id}
                    onClick={() => {
                      form.getValues('carModelId') === el.id
                        ? form.setValue('carModelId', undefined)
                        : form.setValue('carModelId', el.id);
                    }}
                    className={cn(
                      'flex justify-between items-center gap-4',
                      'cursor-pointer px-4 py-5 rounded-[16px] bg-white shadow-[0px_6px_20px_0px_rgba(0,_0,_0,_0.05)]',
                      el.id === form.watch('carModelId') &&
                        'border-[2px] border-[#7273F9]',
                    )}
                  >
                    <div className='flex-auto space-y-1'>
                      {/* 사전예약 딱지 */}
                      {el.isPreorder && (
                        <div className='bg-gradient-to-r from-[#00BCD8] to-[#00A287] bg-clip-text text-transparent text-[13px] font-semibold leading-[13px] tracking-[-0.26px]'>
                          사전예약
                        </div>
                      )}
                      {/* 모델명 */}
                      <div className='text-[#222] font-semibold text-[16px] leading-[24px] tracking-[-0.32px] break-keep'>
                        {el.name}
                      </div>
                      {/* 가격 */}
                      {(el.price.min || el.price.max) && (
                        <div className='text-[#555] text-[14px] font-medium leading-[22.4px] tracking-[-0.28px] break-keep'>
                          {el.price.max !== el.price.min ? (
                            <>
                              {Math.floor(
                                el.price.min / 10000,
                              ).toLocaleString()}
                              만원 ~{' '}
                              {Math.floor(
                                el.price.max / 10000,
                              ).toLocaleString()}
                              만원
                            </>
                          ) : (
                            <>
                              {' '}
                              {Math.floor(
                                el.price.min / 10000,
                              ).toLocaleString()}
                              만원
                            </>
                          )}
                        </div>
                      )}
                    </div>
                    <div className='flex-none'>
                      <img
                        src={el.image_url ? el.image_url : BLANKCARIMAGE}
                        alt='carImage'
                        className='h-[66px] w-auto'
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          ) : (
            <div className='grid grid-cols-2 gap-2 px-5'>
              {filteredModel.map((el) => {
                return (
                  <div
                    key={el.id}
                    onClick={() => {
                      form.getValues('carModelId') === el.id
                        ? form.setValue('carModelId', undefined)
                        : form.setValue('carModelId', el.id);
                    }}
                    className={cn(
                      'relative flex flex-col justify-center items-center gap-4',
                      'cursor-pointer px-4 pt-[30px] pb-4 rounded-[16px] bg-white shadow-[0px_6px_20px_0px_rgba(0,_0,_0,_0.05)]',
                      el.id === form.watch('carModelId') &&
                        'border-[2px] border-[#7273F9]',
                    )}
                  >
                    {/* 사전예약 딱지 */}
                    {el.isPreorder && (
                      <div className='absolute py-1.5 px-2.5 top-0 left-0 rounded-tl-[16px] rounded-br-[16px] bg-gradient-to-r from-[#00BCD8] to-[#00A287]'>
                        <span className='text-white text-[12px] font-semibold leading-[12px] tracking-[-0.24px]'>
                          사전 예약
                        </span>
                      </div>
                    )}

                    <div>
                      <img
                        src={el.image_url ? el.image_url : BLANKCARIMAGE}
                        alt='carImage'
                        className='h-[66px] w-auto'
                      />
                    </div>
                    <div className='py-2.5 text-center text-[#222] text-[14px] font-semibold leading-[21px] tracking-[-0.28px] break-keep'>
                      {el.name}
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
      <SelectCatrgory
        filter={filter}
        setFilter={setFilter}
        isOpen={openDrawer}
        setIsOpen={setOpenDrawer}
        usingCategory={usingCategory}
      />
    </>
  );
};

const OrderPurchase_Model = ({
  form,
  wholeSellingCar,
}: OrderPurchaseModelProps) => {
  const targetBrand = wholeSellingCar.find((el) => {
    return el.id === form.getValues('carMakerId');
  });
  const targetModel = targetBrand?.car_model?.find((el) => {
    return el.id === form.getValues('carModelId');
  });

  const [currentTab, setCurrentTab] = useState<ModelType>(
    targetModel?.isEletric === true ? 'eletric' : 'normal',
  );

  const modelCount: {
    normal: number;
    eletric: number;
  } = useMemo(() => {
    if (!targetBrand) return { normal: 0, eletric: 0 };

    return targetBrand.car_model.reduce(
      (acc, el) => {
        if (el.isEletric) {
          acc.eletric++;
        } else {
          acc.normal++;
        }
        return acc;
      },
      { normal: 0, eletric: 0 },
    );
  }, [targetBrand]);

  return (
    <>
      {targetBrand && <BrandPart targetBrand={targetBrand} />}
      <TabSelector
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        modelCount={modelCount}
      />
      {targetBrand && (
        <ModelList
          currentTab={currentTab}
          form={form}
          targetBrand={targetBrand}
        />
      )}
    </>
  );
};

export default OrderPurchase_Model;
