/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import useSystemStore from 'store/useSystemStore';
import classNames from 'classnames';
import { Button } from 'components/ui/button';
import { Dialog, DialogContent, DialogFooter } from 'components/ui/dialog';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { Textarea } from 'components/ui/textarea';
import { NotepadText, PhoneCall, X } from 'lucide-react';
import CarsayoFloatingNavMenu from 'components/common/CarsayoFloatingNavMenu';
import CalendarIcon from 'components/common/global/svg/CalendarIcon';
import TrashCanIcon from 'components/common/global/svg/TrashCanIcon';
import PencilIcon from 'components/common/global/svg/PencilIcon';
import CalculatorIcon from 'components/common/global/svg/CalculatorIcon';
// 스와이퍼관련
import SwiperCore from 'swiper';
import { Navigation } from 'swiper/modules';
import { NavigationOptions } from 'swiper/types/modules/navigation';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Link, useParams } from 'react-router-dom';
import {
  cancelQuoteApi,
  deleteQuoteApi_V2,
  doneQuoteApi,
  holdQuoteApi,
  reStartQuoteApi,
  selectedMeetingApi,
  sendQuoteApi,
} from 'apis/quote';
import {
  convertDealerType,
  convertOrderStatus,
  dateToStringHHMM,
  dateToStringMMDD,
  slicePrice,
} from 'utils/convert';
import { parseSigunguId } from 'utils/typeConvert';
import SellingAccidentHistoryDetail from 'pages/v2/selling/detail/insurance';
import dayjs from 'dayjs';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import useFileUploader from 'store/useFileUploader';
import Confirm from 'components/ui/confirm';
import { parseOrderProcessState } from 'utils/typeConvert';
import download from 'utils/download';
import ReactHtmlParser from 'react-html-parser';
import { NumericFormat } from 'react-number-format';
import DatePicker from 'react-mobile-datepicker-ts';
import CarsayoBottomSheet from 'components/common/CarsayoBottomSheet';
import CarsayoInput from 'components/common/CarsayoInput';
import SellingCarEstimatePrice from 'pages/v2/selling/detail/price';
import useOnResumeStore from 'store/useOnResumeStore';
import { cn } from 'utils';
import { useDealerOrderDetail, useUpdateDealerOrderDetail } from './query';
import CarsayoImgList from 'components/common/v2/CarsayoImgList';
import useMemberStore from 'store/useMemberStore';
import DealerMemoSubpage from './subpage/dealerMemo';
import {
  OrderBidDTO,
  OrderFinishDTO,
  OrderMeetingDateDTO,
  OrderHoldDTO,
  OrderCancelDTO,
  CarsayoErrorResponse,
} from '@carsayo/types';
import { getFileInfo } from 'apis/authentication';
import { toastify } from 'utils/Toastify';
import { Dealer_EstimateFile } from './_components/estimateFile';
import OrderInfo from '../components/orderInfo';
import CarPriceInfoDrawer from '../components/carPriceInfoDrawer';

const QuoteBoxDetail = () => {
  const { loginMember } = useMemberStore();
  const { id } = useParams();
  const { setTopTabbarTitle, setTopTabbarState, setLoadingDim } =
    useSystemStore();
  const { setOnResume } = useOnResumeStore();
  const [isQuoteBoxOpen, setQuoteBoxOpen] = useState(true);
  const [currentInfo, setCurrentInfo] = useState<'model' | 'buy'>('model');
  const [noColor, setNoColor] = useState(true);
  const [isFloatingOpen, setFloatingOpen] = useState(false);
  const [isUsedCarPopupOpen, setUsedCarPopupOpen] = useState(false);
  SwiperCore.use([Navigation]);
  const { fileUpload } = useFileUploader();
  const [isCommissionOpen, setCommissionOpen] = useState(false);
  const [isTooltipVisible, setTooltipVisible] = useState(false);
  const [isExpectedOpen, setExpectedOpen] = useState(false);

  // 이미지리스트관련 state
  const [imgUrlArray, setImgUrlArray] = useState<string[]>([]);
  const [isImgListOpen, setImgListOpen] = useState(false);

  // 낙찰받았습니다 버튼 클릭
  const [isProgressOrderOpen, setProgressOrderOpen] = useState(false);
  // 견적상세
  const { data: quoteDetail, refetch } = useDealerOrderDetail({
    id: id as string,
  });
  const updateDealerOrderDetail = useUpdateDealerOrderDetail();

  // 견적 발송
  const [isQuoteSendingOpen, setQuoteSendingOpen] = useState(false);
  const [quoteContent, setQuoteContent] = useState<string>();
  const [quoteEstimateFile, setQuoteEstimateFile] = useState<string>();
  const [quotePurchasePrice, setQuotePurchasePrice] = useState<number>();
  // 견적 입찰 삭제(취소)
  const [isQuoteDeleteOpen, setQuoteDeleteOpen] = useState(false);
  // 견적 입찰 삭제(취소)
  const [isQuoteSendConfirmOpen, setQuoteSendConfirmOpen] = useState(false);
  // 계약서 작성
  const [isContractOpen, setContractOpen] = useState(false);
  const [contractContent, setContractContent] = useState<string>();
  const [quoteContractFile, setQuoteContractFile] = useState<string>();

  // 미팅일정
  const [isMeetingOpen, setMeetingOpen] = useState(false);
  const [meetingDate, setMeetingDate] = useState<Date>(new Date());
  const [meetingDatePicker, setMeetingDatePicker] = useState(false);

  // 주문 보류
  const [isHoldOpen, setHoldOpen] = useState(false);
  const [holdReason, setHoldReason] = useState<string>();

  // 주문 재개
  const [isRestartOpen, setRestartOpen] = useState(false);

  // 주문 취소
  const [isCancelOpen, setCancelOpen] = useState(false);
  const [cancelReason, setCancelReason] = useState<string>();

  // 계약 완료
  const [isQuoteDoneOpen, setQuoteDoneOpen] = useState(false);
  const [salesDocumentFile, setSalesDocumentFile] = useState<string>();
  const [transferCertificateFile, setTransferCertificateFile] =
    useState<string>();

  /** 메모 서브페이지 열기/닫기 */
  const [isOpenMemo, setIsOpenMemo] = useState<boolean>(false);

  // 구매 플로팅 메뉴
  const actions = [
    {
      label: '메모 작성',
      icon: <NotepadText className='w-5 h-5' />,
      clickAction: () => {
        setIsOpenMemo(true);
      },
    },
  ];

  if (quoteDetail?.state === 'progressing' && quoteDetail.isAccepted === true) {
    actions.push({
      label: '계약 완료',
      icon: <CalendarIcon />,
      clickAction: () => {
        setContractOpen(!isContractOpen);
      },
    });
  }
  if (
    (quoteDetail?.state === 'progressing' || quoteDetail?.state === 'holded') &&
    quoteDetail.isAccepted === true
  ) {
    actions.push({
      label: '주문 취소',
      icon: <TrashCanIcon />,
      clickAction: () => {
        setCancelOpen(!isCancelOpen);
      },
    });
  }
  if (quoteDetail?.state === 'progressing' && quoteDetail.isAccepted === true) {
    actions.push({
      label: '주문 보류',
      icon: <CalendarIcon />,
      clickAction: () => {
        setHoldOpen(!isHoldOpen);
      },
    });
  } else if (
    quoteDetail?.state === 'holded' &&
    quoteDetail.isAccepted === true
  ) {
    actions.push({
      label: '주문 재개',
      icon: <CalendarIcon />,
      clickAction: () => {
        setRestartOpen(!isRestartOpen);
      },
    });
  }
  if (
    quoteDetail?.bid &&
    quoteDetail?.bid.isReaded === false &&
    quoteDetail?.state === 'submitted'
  ) {
    actions.push({
      label: '견적 삭제',
      icon: <TrashCanIcon />,
      clickAction: () => {
        setQuoteDeleteOpen(!isQuoteDeleteOpen);
      },
    });
    actions.push({
      label: '견적 수정',
      icon: <PencilIcon />,
      clickAction: () => {
        setQuoteSendingOpen(!isQuoteSendingOpen);
      },
    });
  }
  if (
    (quoteDetail?.bid === undefined || quoteDetail?.bid === null) &&
    quoteDetail?.state === 'submitted'
  ) {
    actions.push({
      label: '견적 발송',
      icon: <CalculatorIcon />,
      clickAction: () => {
        loginMember?.dealerInfo?.type === 'newcar'
          ? setCommissionOpen(!isCommissionOpen)
          : setQuoteSendingOpen(!isQuoteSendingOpen);
      },
    });
  }

  // 내차팔기 플로팅 메뉴
  const sellCaractions = [];
  if (
    quoteDetail?.member.phoneNumber &&
    quoteDetail?.member.phoneNumber !== '비공개' &&
    (quoteDetail?.state === 'progressing' || quoteDetail?.state === 'holded')
  ) {
    sellCaractions.push({
      icon: (
        <a
          className='flex w-full space-x-2 items-center'
          href={`tel:${quoteDetail.member.phoneNumber}`}
        >
          <PhoneCall width={16} height={16} />
          <span className='pl-0.5'>전화 걸기</span>
        </a>
      ),
    });
  }
  if (
    quoteDetail?.state === 'progressing' &&
    quoteDetail.isAccepted === true &&
    quoteDetail.meeting_date
  ) {
    sellCaractions.push({
      label: '계약 완료',
      icon: <CalendarIcon />,
      clickAction: () => {
        setQuoteDoneOpen(!isQuoteDoneOpen);
      },
    });
  }
  if (
    (quoteDetail?.state === 'progressing' || quoteDetail?.state === 'holded') &&
    quoteDetail.isAccepted === true
  ) {
    sellCaractions.push({
      label: '주문 취소',
      icon: <TrashCanIcon />,
      clickAction: () => {
        setCancelOpen(!isCancelOpen);
      },
    });
  }
  if (quoteDetail?.state === 'progressing' && quoteDetail.isAccepted === true) {
    sellCaractions.push({
      label: '주문 보류',
      icon: <CalendarIcon />,
      clickAction: () => {
        setHoldOpen(!isHoldOpen);
      },
    });
  } else if (
    quoteDetail?.state === 'holded' &&
    quoteDetail.isAccepted === true
  ) {
    sellCaractions.push({
      label: '주문 재개',
      icon: <CalendarIcon />,
      clickAction: () => {
        setRestartOpen(!isRestartOpen);
      },
    });
  }
  if (quoteDetail?.state === 'progressing') {
    if (
      quoteDetail.processCardList &&
      quoteDetail.processCardList.some((card) => card.state === 'nonAccepted')
    ) {
      null;
    } else {
      sellCaractions.push({
        label: '미팅 일정',
        icon: <CalendarIcon />,
        clickAction: () => {
          setMeetingOpen(!isMeetingOpen);
        },
      });
    }
  }
  if (quoteDetail?.bid && quoteDetail?.bid.isReaded === false) {
    sellCaractions.push({
      label: '견적 삭제',
      icon: <TrashCanIcon />,
      clickAction: () => {
        setQuoteDeleteOpen(!isQuoteDeleteOpen);
      },
    });
    sellCaractions.push({
      label: '견적 수정',
      icon: <PencilIcon />,
      clickAction: () => {
        setQuoteSendingOpen(!isQuoteSendingOpen);
      },
    });
  }
  if (
    (quoteDetail?.bid === undefined || quoteDetail?.bid === null) &&
    quoteDetail?.state === 'submitted'
  ) {
    sellCaractions.push({
      label: '견적 발송',
      icon: <CalculatorIcon />,
      clickAction: () => {
        loginMember?.dealerInfo?.type === 'usedcar'
          ? setCommissionOpen(!isCommissionOpen)
          : setQuoteSendingOpen(!isQuoteSendingOpen);
      },
    });
  }

  const navigationOptions: NavigationOptions | boolean = useMemo(() => {
    return {
      prevEl: '.mySwiper-prev',
      nextEl: '.mySwiper-next',
      disabledClass: '!bg-[#00000033] !cursor-default',
    };
  }, []);

  const handleChangeEvents = {
    selectDate: (time: Date) => {
      setMeetingDate(time);
    },
    changeMeetingDate: (e: React.ChangeEvent<HTMLInputElement>) => {
      console.info(e.target.value);
      const inputValue = e.target.value;
      const parsedDate = dayjs(inputValue, 'YYYY-MM-DD', true); // 입력된 텍스트를 날짜로 파싱

      if (parsedDate.isValid()) {
        setMeetingDate(parsedDate.toDate()); // 유효한 날짜인 경우 상태를 업데이트
      } else {
        console.error('입력된 값이 유효한 날짜 형식이 아닙니다.');
        setMeetingDate(new Date());
      }
    },
    getPurchasePrice: (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target.value) {
        setQuotePurchasePrice(Number(e.target.value.replace(/,|원/g, '')));
      }
    },
  };
  const handleClickEvents = {
    openImgList: (imgs: Object) => () => {
      if (!imgs) return;
      const imgArray: Array<string> = [];
      Object.entries(imgs).forEach(([key, value]) => {
        if (key !== null && value !== null) {
          imgArray.push(value.url);
        }
      });
      setImgUrlArray(imgArray);
      setImgListOpen(!isImgListOpen);
    },
    openTooltip: () => {
      setTooltipVisible(!isTooltipVisible);
    },
    quoteBoxOpen: () => {
      setQuoteBoxOpen(!isQuoteBoxOpen);
    },
    fileOpen: (e: React.MouseEvent<HTMLDivElement>, url: string | null) => {
      if (url) {
        e.preventDefault();
        window.open(url, '_blank');
      } else {
        return;
      }
    },
    quoteCheck: (): boolean => {
      if (quoteDetail?.type === 'sellCar') {
        if (!quotePurchasePrice || quotePurchasePrice < 10000) {
          CarsayoToast.error('예상 구매 가격을 입력해주세요!');
          return false;
        }
        return true;
      }

      if (!id || !quoteContent) {
        CarsayoToast.error('항목을 입력해주세요!');
        return false;
      }
      return true;
    },
    quoteSend: async () => {
      setLoadingDim(true);
      setQuoteSendConfirmOpen(false);
      setQuoteSendingOpen(false);
      setFloatingOpen(false);
      if (id && quoteContent) {
        const reqData: OrderBidDTO = {
          orderId: id,
          content: quoteContent,
          purchasePrice: quotePurchasePrice,
          estimateFileId: quoteEstimateFile,
        };
        try {
          const result = await sendQuoteApi(reqData);
          if (result) {
            CarsayoToast.success('견적참여 완료했습니다.');
          }
        } catch (error: any) {
          CarsayoToast.error(error?.message);
        }
      } else {
        CarsayoToast.error('항목을 입력해주세요');
      }
      setLoadingDim(false);
      refetch();
    },
    quoteEstimateFileUpload: () => {
      fileUpload(
        {
          acceptFile: ['image', 'pdf'],
          fileType: 'dealerQuoteFile',
          fileName: '견적서',
          isPublic: false,
        },
        (fileInfo) => {
          setQuoteEstimateFile(fileInfo.id);
        },
      );
    },
    quoteContractFileUpload: () => {
      fileUpload(
        {
          acceptFile: ['image', 'pdf', 'zip'],
          fileType: 'dealerContractFile',
          fileName: '계약서',
          isPublic: false,
        },
        (fileInfo) => {
          setQuoteContractFile(fileInfo.id);
        },
      );
    },
    quoteDelete: async () => {
      setFloatingOpen(false);
      if (id) {
        setLoadingDim(true);
        await deleteQuoteApi_V2({ orderId: id })
          .then((result) => {
            setQuoteContent('');
            setQuoteEstimateFile(undefined);
            setQuotePurchasePrice(undefined);
            CarsayoToast.success('견적삭제를 완료했습니다.');
            setQuoteDeleteOpen(!isQuoteDeleteOpen);
          })
          .catch((data: CarsayoErrorResponse) => {
            CarsayoToast.error(data.message);
            setQuoteDeleteOpen(!isQuoteDeleteOpen);
          });
        setLoadingDim(false);
        refetch();
      } else {
        CarsayoToast.error('아이디값이 없습니다.');
        setQuoteDeleteOpen(!isQuoteDeleteOpen);
      }
    },
    quoteDone: async () => {
      if (quoteDetail?.type === 'sellCar') {
        if (
          /** 240624 이전된 등록증만, 필수로 받게 수정되었습니다. */
          // salesDocumentFile === undefined ||
          // salesDocumentFile === null ||
          transferCertificateFile === undefined ||
          transferCertificateFile === null
        ) {
          CarsayoToast.error('이전된 등록증을 업로드 해 주세요.');
          return false;
        }
      }
      if (quoteDetail?.type !== 'sellCar') {
        if (quoteContractFile === undefined || quoteContractFile === null) {
          CarsayoToast.error('계약서를 업로드 해 주세요.');
          return false;
        }
      }
      if (id) {
        setLoadingDim(true);
        setQuoteDoneOpen(false);
        setContractOpen(false);
        setFloatingOpen(false);

        const reqData: OrderFinishDTO = {
          orderId: id,
          salesDocument_fileId:
            quoteDetail?.type === 'sellCar' ? salesDocumentFile : undefined,
          transferCertificate_fileId:
            quoteDetail?.type === 'sellCar'
              ? transferCertificateFile
              : undefined,
          contract_fileId:
            quoteDetail?.type !== 'sellCar' ? quoteContractFile : undefined,
        };
        try {
          const result = await doneQuoteApi(reqData);
          if (result) {
            CarsayoToast.success('계약을 완료했습니다.');
          }
        } catch (error: any) {
          CarsayoToast.error(error);
        }
      } else {
        CarsayoToast.error('아이디값이 없습니다.');
      }

      refetch();
      setLoadingDim(false);
    },
    selectMeetingDate: async () => {
      setLoadingDim(true);
      setMeetingOpen(false);
      setFloatingOpen(false);
      if (id) {
        const reqData: OrderMeetingDateDTO = {
          orderId: id,
          meeting_date: meetingDate ?? undefined,
        };
        try {
          const result = await selectedMeetingApi(reqData);
          if (result) {
            CarsayoToast.success('미팅일정을 등록했습니다.');
          }
        } catch (error: any) {
          CarsayoToast.error(error);
        }
      } else {
        CarsayoToast.error('아이디값이 없습니다.');
      }
      setLoadingDim(false);
      refetch();
    },
    downloadFileByFileId: async (fileId: string) => {
      setLoadingDim(true);
      try {
        const fileInfo = await getFileInfo({
          fileId: fileId,
        });
        download(fileInfo);
      } catch (error) {
        toastify.error(
          '파일 다운로드에 실패했습니다. 잠시 후 다시 시도해 주세요.',
        );
      }
      setLoadingDim(false);
    },
    salesDocumentFileUpload: () => {
      fileUpload(
        {
          acceptFile: ['image', 'pdf'],
          fileType: 'salesDocumentFile',
          fileName: '매매서류',
          isPublic: false,
        },
        (fileInfo) => {
          setSalesDocumentFile(fileInfo.id);
        },
      );
    },
    transferCertificateFileUpload: () => {
      fileUpload(
        {
          acceptFile: ['image', 'pdf'],
          fileType: 'transferCertificateFile',
          fileName: '이전된 등록증',
          isPublic: false,
        },
        (fileInfo) => {
          setTransferCertificateFile(fileInfo.id);
        },
      );
    },
    quoteHold: async () => {
      if (id && holdReason) {
        setLoadingDim(true);
        setHoldOpen(false);
        setFloatingOpen(false);
        const reqData: OrderHoldDTO = {
          orderId: id,
          reason: holdReason,
          title: '주문보류',
        };
        try {
          const result = await holdQuoteApi(reqData);
          if (result) {
            CarsayoToast.success('주문을 보류처리했습니다.');
          }
        } catch (error: any) {
          CarsayoToast.error(error);
        }
      } else {
        CarsayoToast.error('항목을 입력해주세요!');
      }
      setLoadingDim(false);
      refetch();
    },
    quoteReStart: async () => {
      if (id) {
        setLoadingDim(true);
        setRestartOpen(false);
        setFloatingOpen(false);
        try {
          const result = await reStartQuoteApi(id);
          if (result) {
            CarsayoToast.success('주문을 재개합니다.');
          }
        } catch (error: any) {
          console.error(error);
          CarsayoToast.error(error);
        }
      } else {
        CarsayoToast.error('아이디값이 없습니다.');
      }
      setLoadingDim(false);
      refetch();
    },
    quoteCancel: async () => {
      if (id && cancelReason) {
        setLoadingDim(true);
        setCancelOpen(false);
        setFloatingOpen(false);
        const reqData: OrderCancelDTO = {
          orderId: id,
          title: '주문취소',
          reason: cancelReason,
        };
        try {
          const result = await cancelQuoteApi(reqData);
          if (result) {
            CarsayoToast.success('주문을 취소했습니다.');
          }
        } catch (error: any) {
          CarsayoToast.error(error);
        }
      } else {
        CarsayoToast.error('항목을 입력해주세요!');
      }
      refetch();
      setLoadingDim(false);
    },
    agreeCommission: () => {
      setCommissionOpen(!isCommissionOpen);
      setQuoteSendingOpen(!isQuoteSendingOpen);
    },
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: 'back',
    });
    setTopTabbarTitle('상세보기');
  }, []);

  useEffect(() => {
    setOnResume(async () => {
      refetch();
    });
  }, []);

  useEffect(() => {
    if (quoteDetail?.bid) {
      setQuoteContent(quoteDetail.bid?.content ?? '');
      setQuoteEstimateFile(quoteDetail.bid?.estimateFile?.id ?? undefined);
      setQuotePurchasePrice(quoteDetail.bid?.purchasePrice ?? undefined);
    }
  }, [quoteDetail]);

  return (
    <>
      {quoteDetail && (
        <div className='bg-white px-4'>
          {/* 상단 고객정보 */}
          <div className='w-full pt-8'>
            <div className='w-full flex flex-row justify-between items-stretch border-b-[1px] border-[#E5E5EC] pb-4'>
              <div className='w-full flex flex-row gap-6 justify-start items-stretch'>
                <img
                  src={
                    quoteDetail?.member.profileImage_url
                      ? quoteDetail.member.profileImage_url
                      : 'https://cdn.carsayo.net/resource/image/profileImage_default_1.svg'
                  }
                  alt='프로필 이미지'
                  className='w-[12.8vw] h-[12.8vw] max-w-[80px] max-h-[80px] object-fill rounded-full'
                />
                <div className='flex flex-col justify-center items-start gap-[8px]'>
                  <p className='text-[#767676]'>
                    {quoteDetail?.member.name_nick ? (
                      quoteDetail.member.name_nick
                    ) : (
                      <span className='text-[#999999]'>닉네임 없음</span>
                    )}
                  </p>
                  {/* <p className='text-[#111111] font-[600]'>
                  <span>
                    {quoteDetail?.member.name_real
                      ? quoteDetail.member.name_real === '비공개'
                        ? '비공개'
                        : `${quoteDetail.member.name_real}님`
                      : '이름 없음'}
                  </span>
                </p> */}
                </div>
              </div>
              <div
                className={classNames(
                  'py-[14px] min-w-[80px] px-[16px] w-[20vw] max-h-[50px] max-w-[90px] flex justify-center items-center bg-[#0075FF] rounded-[6px] text-white font-[500]',
                  quoteDetail?.state && quoteDetail.state === 'submitted'
                    ? 'bg-[#04B014]'
                    : quoteDetail?.state && quoteDetail.state === 'progressing'
                      ? 'bg-[#0075FF]'
                      : quoteDetail?.state && quoteDetail.state === 'holded'
                        ? 'bg-[#FFAA00]'
                        : quoteDetail?.state && quoteDetail.state === 'canceled'
                          ? 'bg-[#B71C1C]'
                          : quoteDetail?.state &&
                              quoteDetail.state === 'deleted'
                            ? 'bg-[#B71C1C]'
                            : quoteDetail?.state &&
                                quoteDetail.state === 'finished'
                              ? 'bg-[#2F66BA]'
                              : 'bg-[#111111]',
                )}
              >
                {quoteDetail?.state
                  ? convertOrderStatus[quoteDetail.state]
                  : '상태없음'}
              </div>
            </div>
            {/* 구매유형 박스 */}
            {quoteDetail?.type !== 'sellCar' && (
              <div className='w-full pt-4 pb-0'>
                <ul className='w-full flex flex-col justify-start items-start'>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                    <p className='text-[#767676] min-w-[23vw]'>주문번호</p>
                    <p className='text-[#111111]'>{quoteDetail?.code}</p>
                  </li>{' '}
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                    <p className='text-[#767676] min-w-[23vw]'>유형</p>
                    <p className='text-[#111111]'>
                      {quoteDetail ? (
                        (quoteDetail?.purchase &&
                          quoteDetail.purchase?.purchaseInfo?.method &&
                          quoteDetail.purchase?.purchaseInfo.method) ??
                        '없음'
                      ) : (
                        <span className='text-[#999999]'>없음</span>
                      )}
                    </p>
                  </li>
                  {quoteDetail?.purchase?.purchaseInfo.method &&
                    quoteDetail.purchase.purchaseInfo.method !== '일시불' && (
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                        <p className='text-[#767676] min-w-[23vw]'>
                          {quoteDetail.purchase.purchaseInfo?.할부
                            ? '할부기간'
                            : '계약기간'}
                        </p>
                        <p className='text-[#111111]'>
                          {quoteDetail.purchase.purchaseInfo?.리스 ? (
                            `${quoteDetail.purchase.purchaseInfo.리스.contractTerm}개월`
                          ) : quoteDetail.purchase.purchaseInfo?.렌트 ? (
                            `${quoteDetail.purchase.purchaseInfo.렌트.contractTerm}개월`
                          ) : quoteDetail.purchase.purchaseInfo?.할부 ? (
                            `${quoteDetail.purchase.purchaseInfo.할부.installmentTerm}개월`
                          ) : (
                            <span className='text-[#999999]'>없음</span>
                          )}
                        </p>
                      </li>
                    )}
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                    <p className='text-[#767676] min-w-[23vw]'>차량가격</p>
                    <div className='text-[#111111] flex justify-between items-center gap-[10px] w-full pr-2'>
                      <span>
                        {quoteDetail?.price ? (
                          slicePrice(quoteDetail.price)
                        ) : (
                          <span className='text-[#999999]'>
                            구매 계약서 확인
                          </span>
                        )}
                      </span>
                      <div
                        className={cn(
                          'relative',
                          !quoteDetail?.price && 'hidden',
                        )}
                      >
                        <div
                          className='w-[20px] h-[20px] cursor-pointer'
                          onClick={() => setExpectedOpen(true)}
                        >
                          <img
                            src={'/assets/images/import/nofiIcon.svg'}
                            alt='알림아이콘'
                            className='object-contain w-[20px] h-[20px]'
                          />
                        </div>
                      </div>
                    </div>
                  </li>
                  {(quoteDetail?.purchase?.purchaseInfo.method === '일시불' ||
                    quoteDetail?.purchase?.purchaseInfo.method === '할부') && (
                    <>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                        <p className='text-[#767676] min-w-[23vw]'>거주지역</p>
                        <p className='text-[#111111]'>
                          {quoteDetail.purchase.purchaseInfo.method === '일시불'
                            ? quoteDetail.purchase.purchaseInfo.일시불
                                ?.residenceRegion
                              ? `${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.일시불
                                      .residenceRegion,
                                  )?.sido.name ?? ''
                                } ${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.일시불
                                      .residenceRegion,
                                  )?.name ?? ''
                                }`
                              : '없음'
                            : quoteDetail.purchase.purchaseInfo.method ===
                                  '할부' &&
                                quoteDetail.purchase.purchaseInfo.할부
                                  ?.residenceRegion
                              ? `${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.할부
                                      .residenceRegion,
                                  )?.sido.name ?? ''
                                } ${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.할부
                                      .residenceRegion,
                                  )?.name ?? ''
                                }`
                              : '없음'}
                        </p>
                      </li>
                      <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                        <p className='text-[#767676] min-w-[23vw]'>인수지역</p>
                        <p className='text-[#111111]'>
                          {quoteDetail.purchase.purchaseInfo.method === '일시불'
                            ? quoteDetail.purchase.purchaseInfo.일시불
                                ?.deliveryRegion
                              ? `${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.일시불
                                      .deliveryRegion,
                                  )?.sido.name ?? ''
                                } ${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.일시불
                                      .deliveryRegion,
                                  )?.name ?? ''
                                }`
                              : '없음'
                            : quoteDetail.purchase.purchaseInfo.method ===
                                  '할부' &&
                                quoteDetail.purchase.purchaseInfo.할부
                                  ?.deliveryRegion
                              ? `${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.할부
                                      .deliveryRegion,
                                  )?.sido.name ?? ''
                                } ${
                                  parseSigunguId(
                                    quoteDetail.purchase.purchaseInfo.할부
                                      .deliveryRegion,
                                  )?.name ?? ''
                                }`
                              : '없음'}
                        </p>
                      </li>
                    </>
                  )}
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>연락처</p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      <span>
                        {quoteDetail?.member.phoneNumber ? (
                          quoteDetail?.member.phoneNumber === '비공개' ? (
                            '비공개'
                          ) : (
                            <a
                              className='text-[#7273F9] underline font-medium'
                              href={`tel:${quoteDetail.member.phoneNumber}`}
                            >
                              {quoteDetail?.member.phoneNumber}
                            </a>
                          )
                        ) : (
                          <span className='text-[#999999]'>없음</span>
                        )}
                      </span>
                      {/* {quoteDetail?.member.phoneNumber &&
                      quoteDetail.member.phoneNumber !== '비공개' && (
                        <a
                          className='w-[20px] h-[20px] flex justify-center items-center'
                          href={`tel:${quoteDetail.member.phoneNumber}`}
                        >
                          <TelIcon />
                        </a>
                      )} */}
                    </p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>이메일</p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      <span>
                        {quoteDetail?.member.email ? (
                          quoteDetail?.member.email === '비공개' ? (
                            '비공개'
                          ) : (
                            '공개'
                          )
                        ) : (
                          <span className='text-[#999999]'>없음</span>
                        )}
                      </span>
                    </p>
                  </li>
                </ul>
              </div>
            )}
            {/* 팔기유형 박스 */}
            {quoteDetail?.type === 'sellCar' && (
              <div className='w-full pt-4 pb-0'>
                <ul className='w-full flex flex-col justify-start items-start'>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                    <p className='text-[#767676] min-w-[23vw]'>주문번호</p>
                    <p className='text-[#111111]'>{quoteDetail?.code}</p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                    <p className='text-[#767676] min-w-[23vw]'>유형</p>
                    <p className='text-[#111111]'>
                      {quoteDetail?.type ? (
                        convertDealerType[quoteDetail.type]
                      ) : (
                        <span className='text-[#999999]'>없음</span>
                      )}
                    </p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                    <p className='text-[#767676] min-w-[23vw]'>시세조회</p>
                    <div className='text-[#111111] flex justify-between items-center gap-[10px] w-full pr-2'>
                      <span>
                        {quoteDetail?.price ? (
                          slicePrice(quoteDetail.price)
                        ) : (
                          <span className='text-[#999999]'>없음</span>
                        )}
                      </span>
                      <div className='relative'>
                        <div
                          className='w-[20px] h-[20px] cursor-pointer'
                          onClick={handleClickEvents.openTooltip}
                        >
                          <img
                            src={'/assets/images/import/nofiIcon.svg'}
                            alt='알림아이콘'
                            className='object-contain w-[20px] h-[20px]'
                          />
                        </div>
                        {/* 차량 가격 확인하기 */}
                        {quoteDetail.selling && (
                          <SellingCarEstimatePrice
                            isOpen={isTooltipVisible}
                            setIsOpen={setTooltipVisible}
                            sellingOrderInfo={quoteDetail.selling}
                            mode={'info'}
                          />
                        )}
                        {/* <div
                        id='price_tooltip'
                        className={classNames(
                          isTooltipVisible
                            ? 'flex justify-start translate-x-[0px] items-center opacity-1 !text-[12px] z-[40] !rounded-[10px] !bg-[#111111] !border-[#111111]'
                            : 'opacity-0 translate-x-[50px] !text-transparent z-[-999]',
                          'transition-all !duration-300 w-[120px] h-auto min-h-[60px] absolute p-2 top-0 bottom-0 my-auto right-[25px] text-[#fff]  shadow-sm',
                        )}
                      >
                        예상시세는 실제가격과 달라질 수 있습니다.
                      </div> */}
                      </div>
                    </div>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2'>
                    <p className='text-[#767676] min-w-[23vw]'>판매지역</p>
                    <p className='text-[#111111]'>
                      {quoteDetail?.selling?.sellRegionId ? (
                        `${
                          parseSigunguId(quoteDetail.selling.sellRegionId)?.sido
                            .name ?? ''
                        } ${
                          parseSigunguId(quoteDetail.selling.sellRegionId)
                            ?.name ?? ''
                        }`
                      ) : (
                        <span className='text-[#999999]'>없음</span>
                      )}
                    </p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>연락처</p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      <span>
                        {quoteDetail?.member.phoneNumber ? (
                          quoteDetail?.member.phoneNumber === '비공개' ? (
                            '비공개'
                          ) : (
                            <a
                              className='text-[#7273F9] underline font-medium'
                              href={`tel:${quoteDetail.member.phoneNumber}`}
                            >
                              {quoteDetail?.member.phoneNumber}
                            </a>
                          )
                        ) : (
                          <span className='text-[#999999]'>없음</span>
                        )}
                      </span>
                      {/* {quoteDetail?.member.phoneNumber &&
                      quoteDetail.member.phoneNumber !== '비공개' && (
                        <a
                          className='w-[20px] h-[20px] flex justify-center items-center'
                          href={`tel:${quoteDetail.member.phoneNumber}`}
                        >
                          <TelIcon />
                        </a>
                      )} */}
                    </p>
                  </li>
                  <li className='w-full flex flex-row gap-[5.5vw] justify-start items-center py-2 pr-2'>
                    <p className='text-[#767676] min-w-[23vw]'>이메일</p>
                    <p className='text-[#111111] flex justify-between items-center gap-[10px] w-full'>
                      <span>
                        {quoteDetail?.member.email ? (
                          quoteDetail?.member.email === '비공개' ? (
                            '비공개'
                          ) : (
                            '공개'
                          )
                        ) : (
                          <span className='text-[#999999]'>없음</span>
                        )}
                      </span>
                      {/* {quoteDetail?.member.email &&
                      quoteDetail.member.email !== '비공개' && (
                        <a
                          className='w-[20px] h-[20px] flex justify-center items-center'
                          href={`mailto:${quoteDetail.member.email}`}
                        >
                          <MailIcon />
                        </a>
                      )} */}
                    </p>
                  </li>
                </ul>
              </div>
            )}
          </div>

          {/* 카드 박스 - 주문 진행 안내사항 */}
          {quoteDetail?.state === 'progressing' &&
            quoteDetail.bid?.state === 'accepted' &&
            quoteDetail?.member?.phoneNumber && (
              <div className='text-[13px] mt-2 p-4 border-[1px] border-[#F1F1F5] bg-[#F7F7FB]'>
                <div className='text-[13px] flex justify-start items-center gap-[12px]'>
                  <img
                    src={'/assets/images/v2/sound.svg'}
                    alt='확성기아이콘'
                    className='w-4 h-4 object-contain'
                  />
                  <p>
                    카매니저님께서 낙찰 받았습니다.
                    <br />
                    고객님과 계약을 진행해 주세요.
                  </p>
                </div>
                <Button
                  onClick={() => setProgressOrderOpen(true)}
                  className='mt-4'
                >
                  계약 진행하기
                </Button>
              </div>
            )}

          {/* 견적 상태 타임라인 */}
          <div className='w-full py-4'>
            {/* 견적 타임라인 */}
            <div
              className={classNames(
                'transition-all',
                isQuoteBoxOpen
                  ? 'h-auto opacity-100 translate-y-0 z-[0] pointer-events-auto'
                  : 'h-0 opacity-0 translate-y-[50px] z-[-100] pointer-events-none fixed',
              )}
            >
              <div className='w-full py-4'>
                <ul className='w-full flex flex-col gap-4 justify-start items-start'>
                  {/* 카드 박스 - 내 견적으로 낙찰 된 후 */}
                  {quoteDetail?.processCardList &&
                    quoteDetail.processCardList.length > 0 &&
                    quoteDetail.processCardList.map((item, index) => {
                      return (
                        <li
                          key={`processCard_${index}`}
                          className='w-full flex flex-col justify-start items-start gap-4 text-[#111111]'
                        >
                          {/* 카드 상단 */}
                          <div className='w-full flex flex-row justify-between items-center'>
                            <div className='flex flex-row items-center gap-[5px]'>
                              <span className='bg-[#111111] py-[2px] px-1 text-white text-[11px] leading-[16px]'>
                                {/* {index + 1 < 10 ? `0${index + 1}` : index + 1} */}
                                {quoteDetail.processCardList.length - index < 10
                                  ? `0${quoteDetail.processCardList.length - index}`
                                  : quoteDetail.processCardList.length - index}
                              </span>
                              <p className='text-[15px]'>{item.title}</p>
                            </div>
                            <div className='flex flex-row items-center gap-1'>
                              <span
                                className={classNames(
                                  'min-w-[27px] py-[2px] px-[4px] border text-[11px] tracking-[-0.28px]',
                                  item.state &&
                                    `!border-[${parseOrderProcessState(item.state).color}] 
                                  !text-[${parseOrderProcessState(item.state).color}]`,
                                )}
                              >
                                {item.state
                                  ? `${parseOrderProcessState(item.state).text} ${dateToStringMMDD(item.created_at)} ${dateToStringHHMM(item.created_at)}`
                                  : '상태없음'}
                              </span>
                            </div>
                          </div>
                          {/* 카드 중앙 */}
                          <div className='w-full bg-[#F7F7FB] p-4 flex flex-col gap-[12px] '>
                            {(item.file_1 || item.file_2 || item.file_3) && (
                              <ul>
                                {item.file_1 && (
                                  <li className='w-full'>
                                    <div
                                      className='mt-3 flex h-12 items-center justify-center border border-[#111111] bg-white'
                                      onClick={() => {
                                        item.file_1 !== null
                                          ? download(item.file_1)
                                          : null;
                                      }}
                                    >
                                      {item.file_1.name}
                                    </div>
                                  </li>
                                )}
                                {item.file_2 && (
                                  <li className='w-full'>
                                    <div
                                      className='mt-3 flex h-12 items-center justify-center border border-[#111111] bg-white'
                                      onClick={() => {
                                        item.file_2 !== null
                                          ? download(item.file_2)
                                          : null;
                                      }}
                                    >
                                      {item.file_2.name}
                                    </div>
                                  </li>
                                )}
                                {item.file_3 && (
                                  <li className='w-full'>
                                    <div
                                      className='mt-3 flex h-12 items-center justify-center border border-[#111111] bg-white'
                                      onClick={() => {
                                        item.file_3 !== null
                                          ? download(item.file_3)
                                          : null;
                                      }}
                                    >
                                      {item.file_3.name}
                                    </div>
                                  </li>
                                )}
                              </ul>
                            )}
                            {(item.additionalInfo_1 ||
                              item.additionalInfo_2 ||
                              item.additionalInfo_3) && (
                              <ul>
                                {item.additionalInfo_1 && (
                                  <li>
                                    <div className='border font-[500] border-[#111111] bg-[#F1F1F5] py-[14px] px-[16px] flex justify-between items-stretch'>
                                      <span className='text-[#767676] max-w-[50%]'>
                                        {item.additionalInfo_1.title}
                                      </span>
                                      <strong className='max-w-[50%] whitespace-pre-wrap'>
                                        {item.additionalInfo_1.content
                                          ? ReactHtmlParser(
                                              item.additionalInfo_1.content,
                                            )
                                          : '없음'}
                                      </strong>
                                    </div>
                                  </li>
                                )}
                                {item.additionalInfo_2 && (
                                  <li>
                                    <div className='border font-[500] border-[#111111] bg-[#F1F1F5] py-[14px] px-[16px] flex justify-between items-center'>
                                      <span className='text-[#767676] max-w-[50%]'>
                                        {item.additionalInfo_2.title}
                                      </span>
                                      <strong className='max-w-[50%] whitespace-pre-wrap'>
                                        {item.additionalInfo_2.content
                                          ? ReactHtmlParser(
                                              item.additionalInfo_2.content,
                                            )
                                          : '없음'}
                                      </strong>
                                    </div>
                                  </li>
                                )}
                                {item.additionalInfo_3 && (
                                  <li>
                                    <div className='border font-[500] border-[#111111] bg-[#F1F1F5] py-[14px] px-[16px] flex justify-between items-center'>
                                      <span className='text-[#767676] max-w-[50%]'>
                                        {item.additionalInfo_3.title}
                                      </span>
                                      <strong className='max-w-[50%] whitespace-pre-wrap'>
                                        {item.additionalInfo_3.content
                                          ? ReactHtmlParser(
                                              item.additionalInfo_3.content,
                                            )
                                          : '없음'}
                                      </strong>
                                    </div>
                                  </li>
                                )}
                              </ul>
                            )}
                            <p className='w-full text-left text-[15px] leading-[22px] whitespace-pre-wrap'>
                              {item.content
                                ? ReactHtmlParser(item.content)
                                : '견적 내용 없음'}
                            </p>
                            {quoteDetail.isAccepted === true &&
                              item.state === 'progressing' && (
                                <p className='w-full text-left text-[15px] leading-[22px]'>
                                  {quoteDetail.isAccepted === true}
                                </p>
                              )}
                          </div>
                          {/* 카드 하단 */}
                          {/* <div className='w-full text-center bg-[#F7F7FB] p-4 text-[13px]'>
                          05.28 열람 되었습니다.
                        </div> */}
                          {/* 카드 하단 */}
                          {/* <div className='w-full'>
                          <p className='text-[#767676] text-[13px] mb-4'>
                            미열람 상태에서만 견적 수정 및 취소가 가능합니다.
                          </p>
                        </div> */}
                        </li>
                      );
                    })}
                  {/* 카드 박스 - 내가 입찰한 견적이 있지만 낙찰 전일 때 */}
                  {(quoteDetail?.processCardList === undefined ||
                    quoteDetail?.processCardList.length === 0) &&
                    quoteDetail?.bid && (
                      <li className='w-full flex flex-col justify-start items-start gap-4 text-[#111111]'>
                        {/* 카드 상단 */}
                        <div className='w-full flex flex-row justify-between items-center'>
                          <div className='flex flex-row items-center gap-[5px]'>
                            <span className='bg-[#111111] py-[2px] px-1 text-white text-[11px] leading-[16px]'>
                              01
                            </span>
                            <p className='text-[15px]'>{'내 입찰 견적'}</p>
                          </div>
                          <div className='flex flex-row items-center gap-1'>
                            <span
                              className={classNames(
                                'min-w-[27px] py-[2px] px-[4px] border text-[11px] tracking-[-0.28px]',
                                quoteDetail?.bid.state &&
                                  `!border-[${parseOrderProcessState(quoteDetail?.bid.state).color}] !text-[${parseOrderProcessState(quoteDetail?.bid.state).color}]`,
                              )}
                            >
                              {quoteDetail?.bid.state &&
                              quoteDetail?.bid.created_at
                                ? `${parseOrderProcessState(quoteDetail?.bid.state).text} ${dateToStringMMDD(quoteDetail?.bid.created_at)} ${dateToStringHHMM(quoteDetail?.bid.created_at)}`
                                : '상태없음'}
                            </span>
                          </div>
                        </div>
                        {/* 카드 중앙 */}
                        <div className='w-full bg-[#F7F7FB] p-4 flex flex-col gap-[12px] '>
                          {quoteDetail?.bid.estimateFile && (
                            <ul>
                              {quoteDetail?.bid.estimateFile ? (
                                <li className='w-full'>
                                  <div
                                    className='mt-3 flex h-12 items-center justify-center border border-[#111111] bg-white'
                                    onClick={
                                      quoteDetail.bid &&
                                      quoteDetail.bid?.estimateFile
                                        ? () => {
                                            quoteDetail.bid !== null &&
                                            quoteDetail.bid.estimateFile !==
                                              null
                                              ? download(
                                                  quoteDetail.bid.estimateFile,
                                                )
                                              : null;
                                          }
                                        : () => {}
                                    }
                                  >
                                    {quoteDetail.bid.estimateFile.name}
                                  </div>
                                </li>
                              ) : null}
                            </ul>
                          )}
                          {quoteDetail.type === 'sellCar' && (
                            <ul>
                              <li>
                                <div className='border font-[500] border-[#111111] bg-[#F1F1F5] py-[14px] px-[16px] flex justify-between items-stretch'>
                                  <span className='text-[#767676] max-w-[50%]'>
                                    매입가격
                                  </span>
                                  <strong className='max-w-[50%] '>
                                    {quoteDetail.bid.purchasePrice
                                      ? slicePrice(
                                          quoteDetail.bid.purchasePrice,
                                        )
                                      : '없음'}
                                  </strong>
                                </div>
                              </li>
                            </ul>
                          )}
                          <p className='w-full text-left text-[15px] leading-[22px] whitespace-pre-wrap '>
                            {quoteDetail?.bid.content
                              ? ReactHtmlParser(quoteDetail.bid.content)
                              : '견적내용 없음'}
                          </p>
                        </div>
                        {/* 카드 하단 */}
                        {/* <div className='w-full text-center bg-[#F7F7FB] p-4 text-[13px]'>
                          05.28 열람 되었습니다.
                        </div> */}
                        {/* 카드 하단 */}
                        {/* <div className='w-full'>
                          <p className='text-[#767676] text-[13px] mb-4'>
                            미열람 상태에서만 견적 수정 및 취소가 가능합니다.
                          </p>
                        </div> */}
                      </li>
                    )}
                  {/* 카드 박스 - 입찰 중일 경우 */}
                  {quoteDetail?.state === 'submitted' &&
                    (quoteDetail?.processCardList === undefined ||
                      quoteDetail?.processCardList.length === 0) && (
                      <div className='w-full text-[13px] mt-2 p-4 border-[1px] border-[#F1F1F5] bg-[#F7F7FB]'>
                        <div className='text-[13px] flex justify-start items-center gap-[12px]'>
                          <img
                            src={'/assets/images/v2/sound.svg'}
                            alt='확성기아이콘'
                            className='w-4 h-4 object-contain'
                          />
                          <p>
                            고객님께서 입찰중인 주문입니다.<br></br>입찰에
                            참여하시려면 입찰 버튼을 눌러 주세요.
                          </p>
                        </div>
                        <Button
                          onClick={() => {
                            if (loginMember?.dealerInfo?.type === 'usedcar') {
                              setCommissionOpen(!isCommissionOpen);
                            } else if (
                              loginMember?.dealerInfo?.type === 'newcar'
                            ) {
                              setCommissionOpen(!isCommissionOpen);
                            } else {
                              setQuoteSendingOpen(!isQuoteSendingOpen);
                            }
                          }}
                          className='mt-4'
                        >
                          입찰 참여하기
                        </Button>
                      </div>
                    )}

                  {/* {(quoteDetail?.processCardList === undefined ||
                  quoteDetail?.processCardList.length === 0) &&
                  quoteDetail?.bid === (undefined || null) && (
                    <li className='w-full flex flex-col justify-start items-start gap-4 text-[#111111]'>
                      <div className='w-full text-center bg-[#F7F7FB] p-4 text-[15px] text-[#767676]'>
                        {quoteDetail.state === 'canceled'
                          ? '입찰이 종료됐습니다.'
                          : '+ 버튼으로 견적에 참여해 주세요'}
                      </div>
                    </li>
                  )} */}
                </ul>
              </div>
            </div>
            {/* 견적 타임라인 여 닫는 버튼 */}
            {quoteDetail.processCardList.length > 0 && (
              <div className='w-full border-t-[1px] mt-4 border-[#E5E5EC] relative'>
                <button
                  className='shadow-[0px_4px_10px_#0000000f] absolute left-0 right-0 mx-auto top-[-20px] p-[10px] rounded-full border border-[#E5E5EC] w-[40px] inline-flex justify-center items-center bg-white'
                  onClick={handleClickEvents.quoteBoxOpen}
                >
                  <img
                    src={'/assets/images/import/arrowTop.svg'}
                    alt='닫기버튼'
                    className={classNames(
                      'transition-all',
                      isQuoteBoxOpen ? 'rotate-[0deg]' : 'rotate-[180deg]',
                    )}
                  />
                </button>
              </div>
            )}
          </div>
          {/* 추가 요청사항 있을 시 추가 요청사항 */}
          {quoteDetail?.purchase?.additionalRequest && (
            <div className='w-full flex flex-col gap-[3vw] justify-start items-start py-2'>
              <p className='text-[#767676] min-w-[23vw]'>추가 요청사항</p>
              <div className='text-[#111111] pb-4 w-full'>
                <div className='whitespace-pre-wrap w-full text-[#111111] bg-[#F7F7FB] p-4 flex flex-col gap-[12px] justify-start items-start'>
                  {ReactHtmlParser(quoteDetail.purchase.additionalRequest)}
                </div>
              </div>
            </div>
          )}

          {/* 계약서, 견적서 혹은 타사 견적 내용 */}
          {(quoteDetail?.purchase?.otherCompanyEstimateFile ||
            quoteDetail?.purchase?.otherCompanyEstimateContent) && (
            <Dealer_EstimateFile
              state={quoteDetail.state}
              purchase={quoteDetail.purchase}
            ></Dealer_EstimateFile>
          )}

          {/* 차량 정보 및 구매 정보 */}
          {quoteDetail && <OrderInfo quoteDetail={quoteDetail} />}
        </div>
      )}
      {/* 플로팅 메뉴네비바 */}
      {quoteDetail &&
        quoteDetail.state !== 'canceled' &&
        quoteDetail.state !== 'failBid' &&
        quoteDetail.state !== 'finished' &&
        ((quoteDetail.type === 'sellCar' && sellCaractions.length > 0) ||
          (quoteDetail.type !== 'sellCar' && actions.length > 0)) && (
          <CarsayoFloatingNavMenu
            isOpen={isFloatingOpen}
            onAction={() => setFloatingOpen(!isFloatingOpen)}
            actions={quoteDetail?.type === 'sellCar' ? sellCaractions : actions}
          />
        )}

      {/* 내차팔기 팝업 */}
      {quoteDetail?.selling?.accidentHistory && (
        <SellingAccidentHistoryDetail
          isOpen={isUsedCarPopupOpen}
          setIsOpen={setUsedCarPopupOpen}
          accidentHistory={quoteDetail.selling.accidentHistory}
        />
      )}

      {/* 신차, 중고차 딜러 수수료 이용료 안내 팝업 */}
      <Drawer open={isCommissionOpen}>
        <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col !pt-4'>
          <DrawerHeader className='relative flex justify-between items-center'>
            <DrawerTitle className='text-center text-2xl leading-[34px] font-semibold grow'>
              {quoteDetail
                ? quoteDetail.type === 'sellCar'
                  ? '중고차 이용료 안내'
                  : '신차 이용료 안내'
                : '제목'}
            </DrawerTitle>
            <Button
              onClick={() => setCommissionOpen(!isCommissionOpen)}
              className='absolute right-5 top-0 bottom-[3px] my-auto h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'
            >
              <X className='h-7 w-7' />
            </Button>
          </DrawerHeader>
          <div className='px-4 pb-8 mt-2'>
            <div className='pt-2 min-h-[240px] max-h-[65vh] mb-2 overflow-y-auto'>
              {quoteDetail && quoteDetail.type === 'sellCar' ? (
                <>
                  <div className='p-4 border border-[#E5E5EC] text-[13px] mb-6'>
                    <h4 className='text-[14px] font-[600] text-[#111111] mb-[12px]'>
                      안내사항
                    </h4>
                    <ul className='text-[#767676] font-[400] mb-[12px]'>
                      <li className='mb-1 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                        가입비와 월 이용료는 없습니다.
                      </li>
                      <li className='mb-1 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                        계약 체결 시 계약 체결 이용료만 받습니다.
                      </li>
                      <li className='mb-1 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                        계약 체결 이용료는 최초 작성하신 견적 금액 기준입니다.
                      </li>
                      <li className='mb-1 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                        계약 파기 시 계약 체결 이용료는 환불해 드립니다.
                      </li>
                    </ul>
                    <div className='text-[13px] text-[#767676] p-2 border-y-[1px] border-[#F1F1F5] bg-[#F7F7FB] flex justify-start items-center gap-[12px]'>
                      <img
                        src={'/assets/images/v2/sound.svg'}
                        alt='확성기아이콘'
                        className='w-4 h-4 object-contain'
                      />
                      <p>
                        거래 체결 후 24시간 이내에 계약서를 업로드해야 하며,
                        수수료 입금 안내 메시지를 받은 즉시 카사요(주) 계좌로
                        계약 체결 이용료(수수료)를 입금해야 합니다.
                      </p>
                    </div>
                  </div>
                  <div className='mb-2'>
                    <h4 className='text-[18px] font-[600] leading-[26px] flex justify-start items-center gap-[5px] mb-4'>
                      중고차 계약 체결 이용료
                      <span className='text-[13px] text-[#767676] font-[400]'>
                        (최초 견적 금액 기준)
                      </span>
                    </h4>
                    <div>
                      <ul className='flex flex-col border-y-[1px] border-[#555555]'>
                        <li className='p-4 bg-[#F7F7FB] flex justify-start items-center'>
                          <span className='grow text-center'>구분</span>
                          <span className='min-w-[26vw] text-center'>
                            금액 (VAT포함)
                          </span>
                        </li>
                        <li className='p-4 flex justify-start items-center border-b-[1px] border-[#F1F1F5]'>
                          <span className='grow text-center'>200만원 미만</span>
                          <span className='min-w-[26vw] text-center'>
                            55,000원
                          </span>
                        </li>
                        <li className='p-4 flex justify-start items-center border-b-[1px] border-[#F1F1F5]'>
                          <span className='grow text-center'>
                            200만원 ~ 3,000만원 미만
                          </span>
                          <span className='min-w-[26vw] text-center'>
                            143,000원
                          </span>
                        </li>
                        <li className='p-4 flex justify-start items-center'>
                          <span className='grow text-center'>
                            3,000만원 이상
                          </span>
                          <span className='min-w-[26vw] text-center'>
                            209,000원
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className='p-4 border border-[#E5E5EC] text-[13px] mb-6'>
                    <h4 className='text-[14px] font-[600] text-[#111111] mb-[12px]'>
                      안내사항
                    </h4>
                    <ul className='text-[#767676] font-[400] mb-[12px]'>
                      <li className='mb-1 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                        가입비와 월 이용료는 없습니다.
                      </li>
                      <li className='mb-1 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                        계약 체결 시 계약 체결 이용료만 받습니다.
                      </li>
                      <li className='mb-1 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                        계약 체결 이용료는 출고 기준이 아닌 계약서 작성(입력)
                        기준입니다.
                      </li>
                      <li className='mb-1 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                        계약 파기 시 계약 체결 이용료는 환불해 드립니다.
                      </li>
                    </ul>
                    <div className='text-[13px] text-[#767676] p-2 border-y-[1px] border-[#F1F1F5] bg-[#F7F7FB] flex justify-start items-center gap-[12px]'>
                      <img
                        src={'/assets/images/v2/sound.svg'}
                        alt='확성기아이콘'
                        className='w-4 h-4 object-contain'
                      />
                      <p>
                        거래 체결 후 24시간 이내에 계약서를 업로드해야 하며,
                        수수료 입금 안내 메시지를 받은 즉시 카사요(주) 계좌로
                        계약 체결 이용료(수수료)를 입금해야 합니다.
                      </p>
                    </div>
                  </div>
                  <div className='mb-6'>
                    <h4 className='text-[18px] font-[600] leading-[26px] flex justify-start items-center gap-[5px] mb-4'>
                      신차 이용료
                    </h4>
                    <div>
                      <p className='mb-2'>
                        신차 계약 정보제공 수수료 (계약 체결 시)
                      </p>
                      <ul className='text-[#767676] font-[400] mb-[12px]'>
                        <li className='mb-2 relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                          신차 이용료 지불은 출고 기준이 아닌 계약서 작성(입력)
                          기준
                        </li>
                        <li className='relative pl-[10px] after:content-["•"] after:absolute after:left-0 after:top-0 after:bottom-0 after:my-auto'>
                          해약 시 카사요에서 고객에게 내용 확인 후 반환
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='mb-6'>
                    <h4 className='text-[18px] font-[600] leading-[26px] flex justify-start items-center gap-[5px] mb-4'>
                      현대(제네시스), 기아자동차 계약 체결 이용료
                    </h4>
                    <div>
                      <ul className='flex flex-col border-y-[1px] border-[#555555]'>
                        <li className='p-4 bg-[#F7F7FB] flex justify-start items-center'>
                          <span className='grow text-center'>구분</span>
                          <span className='min-w-[26vw] text-center'>
                            금액 (VAT포함)
                          </span>
                        </li>
                        <li className='p-4 flex justify-start items-center border-b-[1px] border-[#F1F1F5]'>
                          <span className='grow text-center'>2천만원 미만</span>
                          <span className='min-w-[26vw] text-center'>
                            77,000원
                          </span>
                        </li>
                        <li className='p-4 flex justify-start items-center border-b-[1px] border-[#F1F1F5]'>
                          <span className='grow text-center'>
                            2천만원 이상 ~ 3천만원 미만
                          </span>
                          <span className='min-w-[26vw] text-center'>
                            110,000원
                          </span>
                        </li>
                        <li className='p-4 flex justify-start items-center border-b-[1px] border-[#F1F1F5]'>
                          <span className='grow text-center'>
                            3천만원 이상 ~ 4천만원 미만
                          </span>
                          <span className='min-w-[26vw] text-center'>
                            132,000원
                          </span>
                        </li>
                        <li className='p-4 flex justify-start items-center border-b-[1px] border-[#F1F1F5]'>
                          <span className='grow text-center'>
                            4천만원 이상 ~ 5천 5백만원 미만
                          </span>
                          <span className='min-w-[26vw] text-center'>
                            154,000원
                          </span>
                        </li>
                        <li className='p-4 flex justify-start items-center border-b-[1px] border-[#F1F1F5]'>
                          <span className='grow text-center'>
                            5천 5백만원 이상 ~ 7천만원 미만
                          </span>
                          <span className='min-w-[26vw] text-center'>
                            176,000원
                          </span>
                        </li>
                        <li className='p-4 flex justify-start items-center border-b-[1px] border-[#F1F1F5]'>
                          <span className='grow text-center'>
                            7천만원 이상 ~ 9천만원 미만
                          </span>
                          <span className='min-w-[26vw] text-center'>
                            198,000원
                          </span>
                        </li>
                        <li className='p-4 flex justify-start items-center'>
                          <span className='grow text-center'>9천만원 이상</span>
                          <span className='min-w-[26vw] text-center'>
                            220,000원
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='mb-4'>
                    <h4 className='text-[18px] font-[600] leading-[26px] flex justify-start items-center gap-[5px] mb-4'>
                      르노삼성, 쌍용, 쉐보레 계약 체결 이용료
                    </h4>
                    <div>
                      <ul className='flex flex-col border-y-[1px] border-[#555555]'>
                        <li className='p-4 bg-[#F7F7FB] flex justify-start items-center'>
                          <span className='grow text-center'>구분</span>
                          <span className='min-w-[30vw] text-center'>
                            금액 (VAT포함)
                          </span>
                        </li>
                        <li className='p-4 flex justify-start items-center border-b-[1px] border-[#F1F1F5]'>
                          <span className='grow text-center'>
                            르노코리아(삼성)
                          </span>
                          <span className='min-w-[30vw] text-center'>
                            143,000원
                          </span>
                        </li>
                        <li className='p-4 flex justify-start items-center border-b-[1px] border-[#F1F1F5]'>
                          <span className='grow text-center'>
                            르노코리아 XM3
                          </span>
                          <span className='min-w-[30vw] text-center'>
                            99,000원
                          </span>
                        </li>
                        <li className='p-4 flex justify-start items-center border-b-[1px] border-[#F1F1F5]'>
                          <span className='grow text-center'>
                            KG모빌리티(쌍용)
                          </span>
                          <span className='min-w-[30vw] text-center'>
                            143,000원
                          </span>
                        </li>
                        <li className='p-4 flex justify-start items-center'>
                          <span className='grow text-center'>쉐보레</span>
                          <span className='min-w-[30vw] text-center'>
                            143,000원
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='mb-6'>
                    <h4 className='text-[18px] font-[600] leading-[26px] flex justify-start items-center gap-[5px] mb-4'>
                      수입차 계약 체결 이용료
                    </h4>
                    <div>
                      <p className='mb-2'>
                        차량가(세금계산서)의 0.4%{' '}
                        <span className='text-red-600'>(VAT 미포함)</span>
                      </p>
                    </div>
                  </div>
                </>
              )}
            </div>
            <p className='text-[13px] text-[#767676]'>
              확인 버튼을 선택하신 후 입찰해 주세요!
            </p>
            <button
              onClick={handleClickEvents.agreeCommission}
              className='w-full py-4 px-7 mt-6 flex justify-center items-center bg-[#111111] text-[#fff] rounded-[6px] disabled:cursor-not-allowed disabled:opacity-50 disabled:pointer-events-none'
            >
              모두 확인했습니다
            </button>
          </div>
        </DrawerContent>
      </Drawer>

      {/* 주문 진행 도움 팝업 */}
      <Drawer
        open={isProgressOrderOpen}
        onOpenChange={(open) => setProgressOrderOpen(open)}
      >
        <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col !pt-4'>
          <DrawerHeader className='relative flex justify-between items-center'>
            <DrawerTitle className='text-center text-xl leading-[34px] font-semibold grow'>
              계약 진행하기
            </DrawerTitle>
            <Button
              onClick={() => setProgressOrderOpen(!isProgressOrderOpen)}
              className='absolute right-5 top-0 bottom-[3px] my-auto h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'
            >
              <X className='h-7 w-7' />
            </Button>
          </DrawerHeader>
          <div className='px-4 pb-8 mt-2'>
            <div className='min-h-[240px] max-h-[65vh] mb-2 overflow-y-auto'>
              {quoteDetail?.member.phoneNumber && (
                <div>
                  <span className='text-base font-semibold'>
                    고객님과 연락하기
                  </span>
                  <div>
                    고객님께 즉시(24시간 이내) 연락해 주시고, 계약을 진행해
                    주세요.
                    <br />
                    통화 상담 시에는 정확한 내용을 기반으로 친절하게 상담해
                    주세요.
                  </div>
                  <a href={`tel:${quoteDetail.member.phoneNumber}`}>
                    <Button className='mt-2'>고객님과 통화하기</Button>
                  </a>
                </div>
              )}
              {quoteDetail?.type === 'sellCar' &&
                quoteDetail?.state === 'progressing' && (
                  <div className='pt-6'>
                    <span className='text-base font-semibold'>
                      미팅 날짜 설정하기
                    </span>
                    <div>
                      중고차 매입은 고객님과 만나는 날짜를 정해야 해요.
                      <br />
                      고객님과 만나는 날짜가 정해지면 날짜를 등록해 주세요.
                    </div>
                    <Button
                      onClick={() => {
                        setMeetingOpen(!isMeetingOpen);
                      }}
                      className='mt-3'
                    >
                      미팅 날짜 설정
                    </Button>
                  </div>
                )}
              {/* {quoteDetail?.type !== 'sellCar' &&
                quoteDetail?.state === 'progressing' && (
                  <div className='pt-6'>
                    <span className='text-base font-semibold'>계약하기</span>
                    <div>
                      계약서를 작성하여 계약을 진행해야 합니다.
                      <br />
                      고객님과 작성한 계약서 사본을 업로드 해 주세요.
                    </div>
                    <Button
                      onClick={() => {
                        setContractOpen(!isContractOpen);
                        setProgressOrderOpen(false);
                      }}
                      className='mt-3'
                    >
                      계약하기
                    </Button>
                  </div>
                )} */}

              {/* 중고차 주문 종료 */}
              {quoteDetail?.type === 'sellCar' &&
                quoteDetail?.state === 'progressing' &&
                quoteDetail.isAccepted === true &&
                quoteDetail.meeting_date && (
                  <div className='pt-6'>
                    <span className='text-base font-semibold'>
                      이전된 등록증 제출하기
                    </span>
                    <div>
                      거래 체결 후 48시간 이내에 이전된 등록증을 업로드 해야
                      합니다.
                      <br />
                      명의가 이전된 등록증을 업로드 하여 계약을 완료해 주세요.
                    </div>
                    <Button
                      onClick={() => {
                        setQuoteDoneOpen(!isQuoteDoneOpen);
                        setProgressOrderOpen(false);
                      }}
                      className='mt-3'
                    >
                      이전된 등록증 업로드하기
                    </Button>
                  </div>
                )}

              {/* 신차, 리스렌트 주문 종료 */}
              {quoteDetail?.type === 'newCar' &&
                quoteDetail?.state === 'progressing' &&
                quoteDetail.isAccepted === true && (
                  <div className='pt-6'>
                    <span className='text-base font-semibold'>
                      계약서 제출하기
                    </span>
                    <div>
                      거래 체결 후 24시간 이내에 계약서를 업로드 해야 합니다.
                      <br />
                      고객님과 작성한 계약서를 업로드하여 계약을 완료해 주세요.
                    </div>
                    <Button
                      onClick={() => {
                        setContractOpen(!isContractOpen);
                        setProgressOrderOpen(false);
                      }}
                      className='mt-3'
                    >
                      계약서 업로드하기
                    </Button>
                  </div>
                )}

              {(quoteDetail?.type === 'lease' ||
                quoteDetail?.type === 'rent') &&
                quoteDetail?.state === 'progressing' &&
                quoteDetail.isAccepted === true && (
                  <div className='pt-6'>
                    <span className='text-base font-semibold'>
                      거래 완료하기
                    </span>
                    <div>
                      계약 완료 또는 차량 인도 후 계약이 정상적으로 처리되었음을
                      증빙할 수 있는 서류를 업로드 해 주세요.
                      <br />
                      리스 및 장기렌트 계약서와 같은 서류를 통해 증빙
                      가능합니다.
                    </div>
                    <Button
                      onClick={() => {
                        setContractOpen(!isContractOpen);
                        setProgressOrderOpen(false);
                      }}
                      className='mt-3'
                    >
                      서류 업로드하기
                    </Button>
                  </div>
                )}
            </div>
            <p className='text-[13px] text-[#767676] pt-6'>
              카매니저님은 설명을 확인하여 주문을 진행해 주세요.
            </p>
          </div>
        </DrawerContent>
      </Drawer>

      {/* 견적 발송 팝업 */}
      <Dialog
        open={isQuoteSendingOpen}
        onOpenChange={() => {
          setQuoteSendingOpen(!isQuoteSendingOpen);
          setQuotePurchasePrice(undefined);
        }}
      >
        <DialogContent className='w-[311px] gap-0 border-0 p-0'>
          <div className='flex h-14 items-center justify-center text-center text-xl font-semibold'>
            {quoteDetail?.bid ? '견적 수정' : '견적 참여'}
          </div>
          <div className='px-4 py-2 pb-6'>
            {quoteDetail?.type === 'sellCar' && (
              <div className='w-full'>
                <NumericFormat
                  placeholder='예상 구매 가격을 입력해주세요.'
                  onChange={handleChangeEvents.getPurchasePrice}
                  thousandSeparator=','
                  suffix=' 원'
                  defaultValue={''}
                  className='w-full mt-5 border border-[#E5E5EC] p-3'
                  autoComplete='off'
                ></NumericFormat>
              </div>
            )}
            <div className='relative mt-6 border bg-white'>
              <Textarea
                className='h-[158px] border-0 p-4 focus:border-0'
                placeholder='고객 확인용 전달사항 입력'
                value={quoteContent}
                onChange={(e) => setQuoteContent(e.target.value)}
                maxLength={1000}
              ></Textarea>
              <div className='absolute bottom-4 right-2.5 text-[#767676]'>
                {quoteContent ? quoteContent.length : 0}/1000
              </div>
            </div>
            {quoteDetail?.type !== 'sellCar' && (
              <>
                <div className='mt-6 h-[1px] bg-[#F1F1F5]'></div>
                <div className='mt-3 flex h-[66px] items-center gap-4 border bg-white px-4'>
                  <div className='h-4 w-4'>
                    <img src='/assets/images/v2/link.svg' alt='' />
                  </div>
                  <div
                    className={cn(
                      quoteEstimateFile &&
                        'font-medium text-blue-600 underline',
                    )}
                    onClick={() => {
                      if (!quoteEstimateFile) return;
                      handleClickEvents.downloadFileByFileId(quoteEstimateFile);
                    }}
                  >
                    견적서
                  </div>
                  <div className='ml-auto'>
                    <Button
                      variant='outline'
                      size='xs'
                      className='w-auto'
                      onClick={handleClickEvents.quoteEstimateFileUpload}
                    >
                      {quoteEstimateFile ? '재업로드' : '업로드'}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
          <DialogFooter className='w-full flex flex-row justify-stretch items-center'>
            <Button
              className='h-[48px] rounded-none border-none bg-[#F7F7FB] text-[#111111]'
              onClick={() => {
                setQuoteSendingOpen(!isQuoteSendingOpen);
                setQuotePurchasePrice(undefined);
              }}
            >
              취소
            </Button>
            <Button
              className='h-[48px] rounded-none border-none  bg-[#111111]'
              onClick={() => {
                if (handleClickEvents.quoteCheck() === false) return;
                setQuoteSendConfirmOpen(true);
              }}
            >
              확인
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* 견적 발송 확인 팝업 */}
      <Dialog
        open={isQuoteSendConfirmOpen}
        onOpenChange={() => setQuoteSendConfirmOpen(!isQuoteSendConfirmOpen)}
      >
        <DialogContent className='w-[311px] gap-0 border-0 p-0'>
          <div className='flex h-14 items-center justify-center text-center text-xl font-semibold'>
            견적 발송
          </div>

          <div className='px-4 py-2 pb-6'>
            <div className='text-[12px] p-2 border-[1px] border-[#F1F1F5] bg-[#F7F7FB] flex justify-start items-center gap-[12px]'>
              <img
                src={'/assets/images/v2/sound.svg'}
                alt='확성기아이콘'
                className='w-4 h-4 object-contain'
              />
              <p>
                고객님께 제시한 견적 내용과 실제 내용이 다를 경우 불이익을 받을
                수 있습니다.
              </p>
            </div>

            <div className='mt-4 bg-white'>
              <div className='w-full'>
                발송한 견적은 고객님이 확인하신 후 수정할 수 없습니다.
                <p>
                  견적 발송은{' '}
                  <Link to={'/mypage/termList'} className='text-[#7273F9]'>
                    경고 및 계정정지 정책
                  </Link>
                  에 동의한 것으로 간주됩니다.
                </p>
              </div>
            </div>
          </div>
          <DialogFooter className='w-full flex flex-row justify-stretch items-center'>
            <Button
              className='h-[48px] rounded-none border-none bg-[#F7F7FB] text-[#111111]'
              onClick={() => setQuoteSendConfirmOpen(!isQuoteSendConfirmOpen)}
            >
              취소
            </Button>
            <Button
              className='h-[48px] rounded-none border-none  bg-[#111111]'
              onClick={handleClickEvents.quoteSend}
            >
              견적 발송
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* 견적 입찰 삭제 팝업 */}
      <Confirm
        isOpen={isQuoteDeleteOpen}
        setIsOpen={setQuoteDeleteOpen}
        isCloseButton={true}
        onConfirm={handleClickEvents.quoteDelete}
        title='견적 삭제'
        desc={`미열람된 견적서를 삭제합니다.
        진행하시겠습니까?`}
      ></Confirm>

      {/* 신차, 리스렌트 계약 진행 완료 팝업 */}
      <Dialog
        open={isContractOpen}
        onOpenChange={() => setContractOpen(!isContractOpen)}
      >
        <DialogContent className='w-[311px] gap-0 border-0 p-0'>
          <div className='flex h-14 items-center justify-center text-center text-xl font-semibold'>
            계약 완료
          </div>
          <div className='px-4 py-2 pb-6'>
            {quoteDetail?.type === 'lease' || quoteDetail?.type === 'rent' ? (
              <div className='w-full'>
                계약을 완료하시려면 계약이 정상적으로 처리되었음을 증빙할 수
                있는 서류를 업로드 하여야 합니다.
              </div>
            ) : (
              <div className='w-full'>
                계약을 완료하시겠습니까?
                <br />
                계약을 완료하시려면 계약서를 업로드 하여야 합니다.
              </div>
            )}
            <div className='mt-6 h-[1px] bg-[#F1F1F5]'></div>
            <div className='mt-3 flex h-[66px] items-center gap-4 border bg-white px-4'>
              <div className='h-4 w-4'>
                <img src='/assets/images/v2/link.svg' alt='' />
              </div>
              <div
                className={cn(
                  quoteContractFile && 'font-medium text-blue-600 underline',
                )}
                onClick={() => {
                  if (!quoteContractFile) return;
                  handleClickEvents.downloadFileByFileId(quoteContractFile);
                }}
              >
                {quoteDetail?.type === 'lease' || quoteDetail?.type === 'rent'
                  ? '증빙서류'
                  : '계약서'}
              </div>
              <div className='ml-auto'>
                <Button
                  variant='outline'
                  size='xs'
                  className='w-auto'
                  onClick={handleClickEvents.quoteContractFileUpload}
                >
                  {quoteContractFile ? '재업로드' : '업로드'}
                </Button>
              </div>
            </div>
          </div>
          <DialogFooter className='w-full flex flex-row justify-stretch items-center'>
            <Button
              className='h-[48px] rounded-none border-none bg-[#F7F7FB] text-[#111111]'
              onClick={() => setContractOpen(!isContractOpen)}
            >
              취소
            </Button>
            <Button
              className='h-[48px] rounded-none border-none  bg-[#111111]'
              onClick={handleClickEvents.quoteDone}
            >
              확인
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* 미팅 일정 등록 팝업 */}
      <Dialog
        open={isMeetingOpen}
        onOpenChange={() => setMeetingOpen(!isMeetingOpen)}
      >
        <DialogContent className='w-[311px] gap-0 border-0 p-0'>
          <div className='flex h-14 items-center justify-center text-center text-xl font-semibold'>
            미팅 일정 등록
          </div>
          <div className='px-4 py-2 pb-6'>
            <div className='mt-6 h-[1px] pb-14'>
              {/* <input
                className='border border-[#E5E5EC] px-4 py-3 w-full'
                placeholder='YYYYMMDD 형식으로 날짜를 입력하세요'
                type='text'
                onChange={handleChangeEvents.changeMeetingDate}
              /> */}
              {/* <CarsayoDatePicker2
                value={meetingDate ?? dayjs()}
                isOpen={meetingDatePicker}
                onOpen={() => setMeetingDatePicker(true)}
                onCancel={() => setMeetingDatePicker(false)}
                onChange={setMeetingDate}
              /> */}
              <CarsayoInput
                value={
                  meetingDate
                    ? dayjs(meetingDate).format('YYYY-MM-DD')
                    : undefined
                }
                onClick={(e) => {
                  setMeetingOpen(false);
                  setProgressOrderOpen(false);
                  setMeetingDatePicker(true);
                }}
                style={{
                  borderColor: '#f0f0f4',
                  borderRadius: '0 ',
                }}
                readOnly
              />
            </div>
          </div>
          <DialogFooter className='w-full flex flex-row justify-stretch items-center'>
            <Button
              className='h-[48px] rounded-none border-none bg-[#F7F7FB] text-[#111111]'
              onClick={() => setMeetingOpen(!isMeetingOpen)}
            >
              취소
            </Button>
            <Button
              className='h-[48px] rounded-none border-none  bg-[#111111]'
              onClick={handleClickEvents.selectMeetingDate}
            >
              확인
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* 주문 보류 팝업 */}
      <Dialog open={isHoldOpen} onOpenChange={() => setHoldOpen(!isHoldOpen)}>
        <DialogContent className='w-[311px] gap-0 border-0 p-0'>
          <div className='flex h-14 items-center justify-center text-center text-xl font-semibold'>
            주문 보류
          </div>
          <div className='px-4 py-2 pb-6'>
            <div className='w-full'>주문을 보류하시겠습니까?</div>
            <div className='relative mt-4 border bg-white'>
              <Textarea
                className='h-[158px] border-0 p-4 focus:border-0'
                placeholder='고객 확인용 전달사항 입력'
                value={holdReason}
                onChange={(e) => setHoldReason(e.target.value)}
                maxLength={1000}
              ></Textarea>
              <div className='absolute bottom-4 right-2.5 text-[#767676]'>
                {holdReason ? holdReason.length : 0}/1000
              </div>
            </div>
          </div>
          <DialogFooter className='w-full flex flex-row justify-stretch items-center'>
            <Button
              className='h-[48px] rounded-none border-none bg-[#F7F7FB] text-[#111111]'
              onClick={() => setHoldOpen(!isHoldOpen)}
            >
              취소
            </Button>
            <Button
              className='h-[48px] rounded-none border-none  bg-[#111111]'
              onClick={handleClickEvents.quoteHold}
            >
              확인
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* 주문 재개 팝업 */}
      <Confirm
        isOpen={isRestartOpen}
        setIsOpen={setRestartOpen}
        isCloseButton={true}
        onConfirm={handleClickEvents.quoteReStart}
        title='주문 재개'
        desc={`보류한 주문을 재개하시겠습니까?`}
      ></Confirm>

      {/* 계약서 취소 팝업 */}
      <Dialog
        open={isCancelOpen}
        onOpenChange={() => setCancelOpen(!isCancelOpen)}
      >
        <DialogContent className='w-[311px] gap-0 border-0 p-0'>
          <div className='flex h-14 items-center justify-center text-center text-xl font-semibold'>
            계약 취소
          </div>
          <div className='px-4 py-2 pb-6'>
            <div className='w-full'>계약을 취소하시겠습니까?</div>
            <div className='relative mt-4 border bg-white'>
              <Textarea
                className='h-[158px] border-0 p-4 focus:border-0'
                placeholder='고객 확인용 전달사항 입력'
                value={cancelReason}
                onChange={(e) => setCancelReason(e.target.value)}
                maxLength={1000}
              ></Textarea>
              <div className='absolute bottom-4 right-2.5 text-[#767676]'>
                {cancelReason ? cancelReason.length : 0}/1000
              </div>
            </div>
          </div>
          <DialogFooter className='w-full flex flex-row justify-stretch items-center'>
            <Button
              className='h-[48px] rounded-none border-none bg-[#F7F7FB] text-[#111111]'
              onClick={() => setCancelOpen(!isCancelOpen)}
            >
              취소
            </Button>
            <Button
              className='h-[48px] rounded-none border-none  bg-[#111111]'
              onClick={handleClickEvents.quoteCancel}
            >
              확인
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* 계약서 완료 팝업 */}
      <Dialog
        open={isQuoteDoneOpen}
        onOpenChange={() => setQuoteDoneOpen(!isQuoteDoneOpen)}
      >
        <DialogContent className='w-[311px] gap-0 border-0 p-0'>
          <div className='flex h-14 items-center justify-center text-center text-xl font-semibold'>
            계약 완료
          </div>
          <div className='px-4 py-2 pb-6'>
            <div className='w-full'>계약을 완료하시겠습니까?</div>
            {quoteDetail?.type === 'sellCar' && (
              <>
                <div className='w-full break-keep'>
                  계약을 완료하시려면 이전된 등록증을 업로드 하여야 합니다.
                </div>
                {/* 매매서류는 받지 않습니다 - 240624 */}
                <div className='mt-3 flex h-[66px] items-center gap-4 border bg-white px-4'>
                  <div className='h-4 w-4'>
                    <img src='/assets/images/v2/link.svg' alt='' />
                  </div>
                  <div
                    className={cn(
                      transferCertificateFile &&
                        'font-medium text-blue-600 underline',
                    )}
                    onClick={() => {
                      if (!transferCertificateFile) return;
                      handleClickEvents.downloadFileByFileId(
                        transferCertificateFile,
                      );
                    }}
                  >
                    이전된 등록증
                  </div>
                  <div className='ml-auto'>
                    <Button
                      variant='outline'
                      size='xs'
                      className='w-auto'
                      onClick={handleClickEvents.transferCertificateFileUpload}
                    >
                      {transferCertificateFile ? '재업로드' : '업로드'}
                    </Button>
                  </div>
                </div>
              </>
            )}
          </div>
          <DialogFooter className='w-full flex flex-row justify-stretch items-center'>
            <Button
              className='h-[48px] rounded-none border-none bg-[#F7F7FB] text-[#111111]'
              onClick={() => setQuoteDoneOpen(!isQuoteDoneOpen)}
            >
              취소
            </Button>
            <Button
              className='h-[48px] rounded-none border-none  bg-[#111111]'
              onClick={handleClickEvents.quoteDone}
            >
              확인
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* 신차, 리스/렌트 차량가격 안내 팝업 */}
      {quoteDetail && (
        <CarPriceInfoDrawer
          isExpectedOpen={isExpectedOpen}
          setExpectedOpen={setExpectedOpen}
          quoteDetail={quoteDetail}
        />
      )}

      {/* 달력 */}
      <CarsayoBottomSheet
        isOpen={meetingDatePicker}
        onClose={() => {
          setMeetingDatePicker(false);
          setMeetingOpen(true);
        }}
      >
        <DatePicker
          isOpen={meetingDatePicker}
          min={dayjs().subtract(20, 'year').startOf('day').toDate()}
          max={dayjs().add(20, 'year').endOf('day').toDate()}
          onChange={handleChangeEvents.selectDate}
          showHeader={false}
          showFooter={false}
          value={meetingDate || new Date()}
        />
      </CarsayoBottomSheet>

      {/* {isImgListOpen && (
        <motion.div
          initial={{ opacity: 0, y: -20 }}
          whileInView={{
            opacity: 1,
            y: 0,
            transition: { delay: 0.1, duration: 0.3 },
          }}
          className='relative w-full h-full bg-gray-800 top-0 left-0 z-[6000] flex justify-center items-center'
        >
          <div className='w-full h-[70%]'>
            <TransformWrapper
              initialScale={1}
              minScale={0.5}
              maxScale={5}
              pinch={{
                step: 1,
              }}
            >
              <TransformComponent>
                <img
                  src={imgUrlArray[0]}
                  alt={`slide_01`}
                  className='w-full max-w-none'
                />
              </TransformComponent>
            </TransformWrapper>
          </div>
        </motion.div>
      )} */}

      {/* 이미지 리스트 SubPage */}
      <CarsayoImgList
        imgArray={imgUrlArray}
        isSideMenu={isImgListOpen}
        setIsSideMenu={setImgListOpen}
      />

      {/* <Drawer open={meetingDatePicker}>
        <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col !pt-4 min-h-[35.6vh]'>
          <DrawerHeader className='relative flex justify-between items-center'>
            <Button
              onClick={() => setMeetingDatePicker(false)}
              className='absolute right-5 top-0 bottom-[3px] my-auto h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'
            >
              <X className='h-7 w-7' />
            </Button>
          </DrawerHeader>
          <DatePicker
            isOpen={meetingDatePicker}
            min={dayjs().subtract(20, 'year').startOf('day').toDate()}
            max={dayjs().add(20, 'year').endOf('day').toDate()}
            onSelect={handleChangeEvents.selectDate}
            showHeader={false}
            showFooter={false}
            value={meetingDate || new Date()}
          />
        </DrawerContent>
      </Drawer> */}
      {id && (
        <DealerMemoSubpage
          // isOpen={isOpenMemo}
          isOpen={isOpenMemo}
          setIsOpen={setIsOpenMemo}
          orderId={id}
        />
      )}
    </>
  );
};
export default QuoteBoxDetail;
