import { cn } from 'utils';

const OrderDealerCommissionPriceData = {
  '현대, 기아 자동차': [
    { title: '2천만원 미만', price: 70000 },
    { title: '2천만원 이상 ~ 3천만원 미만', price: 100000 },
    { title: '3천만원 이상 ~ 4천만원 미만', price: 130000 },
    { title: '4천만원 이상 ~ 5천 5백만원 미만', price: 150000 },
    { title: '5천 5백만원 이상 ~ 7천만원 미만', price: 170000 },
    { title: '7천만원 이상 ~ 9천만원 미만', price: 190000 },
    { title: '9천만원 이상', price: 210000 },
  ],
  '르노삼성, 쌍용, 쉐보레': [
    {
      title: '르노삼성',
      price: (
        <div>
          <div className='font-semibold'>140,000원</div>
          <div className='font-medium mt-1.5'>(XM3 9만원)</div>
        </div>
      ),
    },
    { title: '쌍용 자동차', price: 140000 },
    { title: '쉐보레', price: 140000 },
  ],
};

const PriceTable = ({
  content,
}: {
  content: keyof typeof OrderDealerCommissionPriceData;
}) => {
  return (
    <div className='rounded-[8px] border border-[#E5E5EC] divide-y divide-[#E5E5EC]'>
      {OrderDealerCommissionPriceData[content].map((el, index) => {
        return (
          <div className='grid grid-cols-[2fr_1fr]' key={index}>
            <div
              className={cn(
                'flex items-center p-[11px_10px] bg-[#F3F6F9]',
                index === 0 && 'rounded-tl-[8px]',
                index === OrderDealerCommissionPriceData[content].length - 1 &&
                  'rounded-bl-[8px]',
              )}
            >
              <span className='text-[#222] font-medium text-[13px] leading-[1.4] tracking-[-0.26px]'>
                {el.title}
              </span>
            </div>
            <div className='flex items-center justify-end p-[11px_10px]'>
              <span className='text-[#222] text-right text-[13px] font-semibold leading-[13px] tracking-[-0.26px]'>
                {typeof el.price === 'number'
                  ? el.price.toLocaleString() + '원'
                  : el.price}
              </span>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default function DealerOrderBidNotice_NewCar() {
  return (
    <div className='flex flex-col gap-5 whitespace-pre-line break-keep'>
      {/* 신차 계약 체결 이용료 */}
      <div>
        <div className='text-[#222] text-[15px] font-semibold leading-[24px] tracking-[-0.3px]'>
          신차 계약 체결 이용료
        </div>
        <div className='overflow-hidden text-[#555] text-[14px] font-medium leading-[22.4px] tracking-[-0.28px]'>
          정보제공 수수료는 출고 기준이 아닌 계약서 작성(입력) 기준입니다.
        </div>
      </div>

      {/* 현대, 기아 자동차 */}
      <div>
        <div className='text-[#222] text-[15px] font-semibold leading-[24px] tracking-[-0.3px]'>
          현대, 기아 자동차
        </div>
        {/* Divider */}
        <div className='w-full h-[2px] bg-[#222] mt-4 mb-5' />

        {/* 가격표 */}
        <PriceTable content='현대, 기아 자동차' />
      </div>

      {/* 르노삼성, 쌍용, 쉐보레 */}
      <div className='mt-1'>
        <div className='text-[#222] text-[15px] font-semibold leading-[24px] tracking-[-0.3px]'>
          르노삼성, 쌍용, 쉐보레
        </div>
        {/* Divider */}
        <div className='w-full h-[2px] bg-[#222] mt-4 mb-5' />

        {/* 가격표 */}

        <PriceTable content='르노삼성, 쌍용, 쉐보레' />
      </div>

      {/* 수입차 */}
      <div className='mt-1'>
        <div className='text-[#222] text-[15px] font-semibold leading-[24px] tracking-[-0.3px]'>
          수입차
        </div>
        {/* Divider */}
        <div className='w-full h-[2px] bg-[#222] mt-4 mb-5' />

        {/* 가격표 */}
        <div className='flex h-[42px] items-center justify-center rounded-[8px] border border-[#E5E5EC] bg-white'>
          <span className='text-[#222] text-center text-[14px] font-semibold leading-[14px] tracking-[-0.28px]'>
            차량가 (세금 계산서)의 0.4%
          </span>
        </div>
      </div>

      {/* 안내사항 영역 */}
      <div className='mt-1'>
        <section className='flex flex-col p-4 pb-[20px] bg-[#F8F9FA] tracking-[-0.025em] w-full rounded-[10px]'>
          <ul className='list-disc pl-4 text-[#555] font-normal text-[14px] leading-[21px] tracking-[-0.28px] space-y-3'>
            <li className='pl-0.5'>카사요 가입비 또는 월 이용료는 없습니다.</li>
            <li className='pl-0.5'>
              계약 체결 시 계약 정보제공 수수료만 받습니다.
            </li>
            <li className='pl-0.5'>
              계약 파기 시 카사요에서 고객에게 내용 확인 후 반환해 드립니다.
            </li>
            <li className='pl-0.5'>
              거래 체결 후 24시간 이내 계약서를 업로드해야 하며, 수수료 입금
              안내 메시지를 받은 즉시 카사요(주) 계좌로 계약 체결
              이용료(수수료)를 입금해야 합니다.
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
}
