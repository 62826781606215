export default function NoOrder() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='43'
      height='43'
      viewBox='0 0 43 43'
      fill='none'
    >
      <g clipPath='url(#clip0_3418_62566)'>
        <path
          d='M25.5577 1.99102C23.1243 -0.442318 19.1494 -0.442318 16.716 1.99102L0.166016 18.166L20.3743 35.7993L42.0993 18.166L25.5577 1.99102Z'
          fill='#BAC3D6'
        />
        <path
          d='M29.4777 5.16602H12.521C9.14932 5.16602 6.41602 7.89932 6.41602 11.271V28.2277C6.41602 31.5994 9.14932 34.3327 12.521 34.3327H29.4777C32.8494 34.3327 35.5827 31.5994 35.5827 28.2277V11.271C35.5827 7.89932 32.8494 5.16602 29.4777 5.16602Z'
          fill='white'
        />
        <path
          d='M25.1858 19.7047L22.8872 17.455L25.1858 15.0585C25.7035 14.5178 25.6689 13.67 25.1098 13.1693C24.5507 12.6686 23.6741 12.702 23.1564 13.2427L20.9338 15.5592L18.7802 13.4497C18.2418 12.9223 17.372 12.9223 16.8267 13.4363C16.2814 13.957 16.2814 14.7981 16.8129 15.3255L19.0563 17.5151L16.9234 19.7381C16.4057 20.2788 16.4402 21.1266 16.9993 21.6273C17.2685 21.8676 17.5998 21.9811 17.9381 21.9811C18.3108 21.9811 18.6766 21.8409 18.9527 21.5539L21.0028 19.4177L23.2185 21.5873C23.4877 21.8543 23.8467 21.9811 24.1987 21.9811C24.5507 21.9811 24.9028 21.8543 25.172 21.5939C25.7173 21.0732 25.7173 20.2321 25.1858 19.7047Z'
          fill='#BAC3D6'
        />
        <path
          d='M24.6577 29.7827C22.241 31.116 19.841 31.116 17.4327 29.7827L0.166016 18.166V35.8577C0.166016 39.266 2.95768 42.066 6.37435 42.066H35.891C39.3077 42.066 42.0993 39.2743 42.0993 35.8577V18.166L24.6577 29.7827Z'
          fill='#CED5E2'
        />
        <path
          d='M3.32617 39.3002C3.1845 39.3002 3.04283 39.2252 2.96783 39.1002C2.85117 38.9002 2.9095 38.6502 3.1095 38.5252L15.0512 31.3585C15.2512 31.2418 15.5012 31.3085 15.6262 31.5002C15.7428 31.7002 15.6845 31.9502 15.4845 32.0752L3.54283 39.2418C3.47617 39.2835 3.40117 39.3002 3.32617 39.3002Z'
          fill='#BAC3D6'
        />
        <path
          d='M38.7151 39.3001C38.6401 39.3001 38.5651 39.2835 38.4984 39.2418L26.5568 32.0751C26.3568 31.9585 26.2984 31.7001 26.4151 31.5001C26.5318 31.3001 26.7901 31.2418 26.9901 31.3585L38.9318 38.5251C39.1318 38.6418 39.1901 38.9001 39.0734 39.1001C38.9984 39.2335 38.8568 39.3001 38.7151 39.3001Z'
          fill='#BAC3D6'
        />
      </g>
      <defs>
        <clipPath id='clip0_3418_62566'>
          <rect
            width='41.9333'
            height='41.9'
            fill='white'
            transform='translate(0.166016 0.166016)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
