import { DealerOrderDetailProps } from '../../type';
import DealerOrderDetailInfoPurchase from './purchase';
import DealerOrderDetailInfoSelling from './selling';

/**
 * @case 신차&리스&렌트 (purchase)
 * @case 내차팔기 (selling)
 */
export default function DealerOrderDetailInfo({
  orderDetail,
}: DealerOrderDetailProps) {
  const type: 'purchase' | 'selling' =
    orderDetail.type === 'sellCar' ? 'selling' : 'purchase';

  if (type === 'purchase') {
    return <DealerOrderDetailInfoPurchase orderDetail={orderDetail} />;
  }

  if (type === 'selling') {
    return <DealerOrderDetailInfoSelling orderDetail={orderDetail} />;
  }
}
