export default function CancelLetter() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='51'
      height='51'
      viewBox='0 0 51 51'
      fill='none'
    >
      <g clipPath='url(#clip0_3399_32237)'>
        <path
          d='M30.97 2.19C28.05 -0.73 23.28 -0.73 20.36 2.19L0.5 21.6L24.75 42.76L50.82 21.6L30.97 2.19Z'
          fill='#F6CECE'
        />
        <path
          d='M35.674 6H15.326C11.28 6 8 9.27997 8 13.326V33.674C8 37.72 11.28 41 15.326 41H35.674C39.72 41 43 37.72 43 33.674V13.326C43 9.27997 39.72 6 35.674 6Z'
          fill='#FFF8F8'
        />
        <path
          d='M30.5237 23.448L27.7654 20.7484L30.5237 17.8725C31.1449 17.2237 31.1035 16.2063 30.4326 15.6055C29.7617 15.0047 28.7097 15.0447 28.0885 15.6936L25.4213 18.4733L22.837 15.9419C22.1909 15.3091 21.1472 15.3091 20.4929 15.9259C19.8385 16.5508 19.8385 17.5601 20.4763 18.193L23.1683 20.8205L20.6088 23.4881C19.9876 24.1369 20.029 25.1543 20.6999 25.7551C21.023 26.0435 21.4206 26.1797 21.8264 26.1797C22.2737 26.1797 22.7127 26.0115 23.0441 25.667L25.5041 23.1036L28.163 25.7071C28.4861 26.0275 28.9168 26.1797 29.3392 26.1797C29.7617 26.1797 30.1841 26.0275 30.5071 25.7151C31.1615 25.0902 31.1615 24.0809 30.5237 23.448Z'
          fill='#F26166'
        />
        <path
          d='M29.89 35.5396C26.99 37.1396 24.11 37.1396 21.22 35.5396L0.5 21.5996V42.8296C0.5 46.9196 3.85 50.2796 7.95 50.2796H43.37C47.47 50.2796 50.82 46.9296 50.82 42.8296V21.5996L29.89 35.5396Z'
          fill='#FDE8E8'
        />
        <path
          d='M4.28964 46.9606C4.11964 46.9606 3.94964 46.8706 3.85964 46.7206C3.71964 46.4806 3.78964 46.1806 4.02964 46.0306L18.3596 37.4306C18.5996 37.2906 18.8996 37.3706 19.0496 37.6006C19.1896 37.8406 19.1196 38.1406 18.8796 38.2906L4.54964 46.8906C4.46964 46.9406 4.37964 46.9606 4.28964 46.9606Z'
          fill='#F6C2C2'
        />
        <path
          d='M46.7603 46.9609C46.6703 46.9609 46.5803 46.9409 46.5003 46.8909L32.1703 38.2909C31.9303 38.1509 31.8603 37.8409 32.0003 37.6009C32.1403 37.3609 32.4503 37.2909 32.6903 37.4309L47.0203 46.0309C47.2603 46.1709 47.3303 46.4809 47.1903 46.7209C47.1003 46.8809 46.9303 46.9609 46.7603 46.9609Z'
          fill='#F6C2C2'
        />
      </g>
      <defs>
        <clipPath id='clip0_3399_32237'>
          <rect
            width='50.32'
            height='50.28'
            fill='white'
            transform='translate(0.5)'
          />
        </clipPath>
      </defs>
    </svg>
  );
}
