import { LinearProgress } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { OrderSellingTab } from '../hooks/useTabHandler';
import { X } from 'lucide-react';

const OrderHeader = ({
  currentTab,
}: {
  currentTab: keyof typeof OrderSellingTab;
}) => {
  const navigate = useNavigate();
  return (
    <header>
      <div className='w-full h-[60px] flex items-center justify-between bg-white/50 sticky top-0 px-4'>
        <div className='w-[32px]'></div>
        <div className='text-[#222] text-center font-Pretendard text-[20px] font-semibold leading-[20px] tracking-[-0.4px]'>
          내 차 팔기
        </div>
        <button onClick={() => navigate('/main')}>
          <X className='stroke-[1.4px]' size={32} />
        </button>
      </div>

      {currentTab !== 'selling' && (
        <div className='px-5'>
          <LinearProgress
            value={OrderSellingTab[currentTab].progress}
            variant='determinate'
            className='!h-0.5 !bg-[#E5E5EC] [&>*]:!bg-[#7273F9]'
          />
        </div>
      )}
    </header>
  );
};

export default OrderHeader;
