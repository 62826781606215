import { Link, useLocation } from 'react-router-dom';
import HomeIcon from 'components/svg/Home';
import MyIcon from 'components/svg/My';
import QuoteIcon from 'components/svg/Quote';
import SettlementIcon from 'components/svg/Settlement';

export default function DealerTabbarContent() {
  const location = useLocation();

  return (
    <ul className='flex justify-center items-center w-full'>
      <li className='w-[25%] h-full p-[5px] pb-[4px] box-border'>
        <Link
          to={'/main'}
          className='w-full h-full flex flex-col items-center gap-[1px]'
        >
          <HomeIcon
            width='30'
            height='30'
            fill={location.pathname.startsWith('/main') ? '#7273F9' : '#B2B7BB'}
          />
          <span
            className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname.startsWith('/main') ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
          >
            홈
          </span>
        </Link>
      </li>
      <li className='w-[25%] p-[5px] pb-[4px]'>
        <Link
          to='/dealer/request'
          className='w-full h-full flex flex-col items-center gap-[1px]'
        >
          <SettlementIcon
            width='30'
            height='30'
            fill={
              location.pathname.startsWith('/dealer/request')
                ? '#7273F9'
                : '#B2B7BB'
            }
          />
          <span
            className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname.startsWith('/dealer/request') ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
          >
            받은 요청
          </span>
        </Link>
      </li>
      <li className='w-[25%] p-[5px] pb-[4px]'>
        <Link
          to={'/dealer/quote'}
          className='w-full h-full flex flex-col items-center gap-[1px]'
        >
          <QuoteIcon
            width='30'
            height='30'
            fill={
              location.pathname.startsWith('/dealer/quote')
                ? '#7273F9'
                : '#B2B7BB'
            }
          />
          <span
            className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname.startsWith('/dealer/quote') ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
          >
            견적함
          </span>
        </Link>
      </li>
      <li className='w-[25%] p-[5px] pb-[4px]'>
        <Link
          to={'/mypage'}
          className='w-full h-full flex flex-col items-center gap-[1px]'
        >
          <MyIcon
            width='30'
            height='30'
            fill={
              location.pathname.startsWith('/mypage') ? '#7273F9' : '#B2B7BB'
            }
          />
          <span
            className={`w-full text-center text-[11px] leading-[11px] font-[600] ${location.pathname.startsWith('/mypage') ? 'text-[#7273F9]' : 'text-[#A1A7AC]'}`}
          >
            마이
          </span>
        </Link>
      </li>
    </ul>
  );
}
