import { ModalDatePickerProps } from 'react-mobile-datepicker-ts';
import DatePicker from 'react-mobile-datepicker-ts';
import CarsayoBottomSheet from './CarsayoBottomSheet';
import dayjs from 'dayjs';
import CarsayoInput from './CarsayoInput';
import { useState } from 'react';

interface CarsayoDatePicker2Interface
  extends Omit<
    ModalDatePickerProps,
    'isOpen' | 'onCancel' | 'onReset' | 'showHeader' | 'showFooter' | 'onChange'
  > {
  value: Date | undefined;
  isOpen: boolean;
  onOpen: () => void;
  onCancel: () => void;
  onChange: (date: Date) => void;
  className?: string;
  placeholder?: string;
  disabled?: boolean;
}

const CarsayoDatePicker2 = ({
  value,
  className,
  isOpen,
  onOpen,
  onCancel,
  onChange,
  placeholder,
  disabled,
  ...rest
}: CarsayoDatePicker2Interface) => {
  const minDate = dayjs().subtract(20, 'year').startOf('day').toDate();
  const maxDate = dayjs().add(20, 'year').endOf('day').toDate();
  const [currentDate, setCurrentDate] = useState<Date>(new Date());

  return (
    <>
      <CarsayoInput
        value={value ? dayjs(value).format('YYYY-MM-DD') : undefined}
        onClick={onOpen}
        postFix={<img src={'/assets/images/import/calendarIcon.svg'} alt='' />}
        style={{
          borderColor: '#f0f0f4',
          borderRadius: '0 ',
        }}
        className={`${className && className}`}
        placeholder={placeholder}
        readOnly
        disabled={disabled}
      />
      <CarsayoBottomSheet isOpen={isOpen} onClose={onCancel}>
        <div className={`w-full relative min-h-[100px]`}>
          <DatePicker
            value={currentDate}
            onChange={(selectedDate: Date) => {
              setCurrentDate(selectedDate);
            }}
            isOpen={isOpen}
            min={minDate}
            max={maxDate}
            showHeader={false}
            showFooter={false}
            {...rest}
          />
          <button
            onClick={() => onChange(currentDate ?? new Date())}
            className='max-h-[50px] w-full py-[14px] px-4 bg-[#7273F9] text-white rounded-[6px] absolute bottom-0'
          >
            확인
          </button>
        </div>
      </CarsayoBottomSheet>
    </>
  );
};

export default CarsayoDatePicker2;
