/* eslint-disable @typescript-eslint/no-unused-vars */
import { cn } from 'utils';
import { OrderSellingForm } from '../../../form';

const boughtMethods = [
  { key: 'cash', label: '현금 또는 할부' },
  { key: 'operatingLease', label: '운용 리스' },
  { key: 'capitalLease', label: '금융 리스' },
];
const OrderSelling_PurchaseMethod = ({ form }: { form: OrderSellingForm }) => {
  return (
    <div className='flex flex-col gap-[18px]'>
      <div>구매 방식</div>
      <div className='flex flex-col gap-[10px]'>
        {boughtMethods.map(({ key, label }) => (
          <label
            key={key}
            className='flex items-between gap-[10px] px-4 py-[13px] bg-[#F3F6F9] rounded-[10px] items-center'
          >
            <div className='text-[15px] leading-[100%] font-[500] flex flex-1'>
              {label}
            </div>
            <div className='relative h-[21px] w-[21px]'>
              <input
                type='radio'
                name='boughtMethod'
                onChange={(e) => {
                  form.setValue(
                    'boughtMethod',
                    key as 'cash' | 'operatingLease' | 'capitalLease',
                  );
                }}
                checked={form.watch('boughtMethod') === key}
                className={cn(
                  'flex h-6 w-6 appearance-none items-center justify-center rounded-full bg-[#B2B7BB]',
                  form.watch('boughtMethod') === key && 'bg-[#7273F9]',
                )}
              />
              <div className='absolute left-3 top-3 transform -translate-x-1/2 -translate-y-1/2 h-[10px] w-[10px] bg-white rounded-full'></div>
            </div>
          </label>
        ))}
      </div>
    </div>
  );
};

export default OrderSelling_PurchaseMethod;
