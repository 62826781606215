import dayjs from 'dayjs';

export default (): string => {
  const dayJS = dayjs();

  const lastFourTextMillisecond = dayJS
    .valueOf()
    .toString()
    .slice(-4)
    .padStart(4, '0');
  const twoLengthRandomString = Math.floor(Math.random() * 100)
    .toString()
    .padStart(2, '0');

  return `${dayJS.format('YYYYMMDD')}${lastFourTextMillisecond}${twoLengthRandomString}`;
};
