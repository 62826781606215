import useInvalidateDealerSearch from 'pages/main/dealer/hook/invalidateDealerSearch';
import DealerRequestContent from './components/requestContent';
import { useEffect, useLayoutEffect } from 'react';
import useSystemStore from 'store/useSystemStore';
import useOnResumeStore from 'store/useOnResumeStore';

/** 카매니저 > 받은 요청 페이지 */
export default function DealerRequestPage() {
  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();

  const { setOnResume } = useOnResumeStore();
  const { InvalidateBiddingOrderList } = useInvalidateDealerSearch();

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'my',
      title: '견적 요청',
    });
    setIsBottomTabbar(true);
  }, []);

  useEffect(() => {
    // 페이지 재진입시 데이터 캐시 전체 무효화
    InvalidateBiddingOrderList();
    // 푸시나 앱 새로고침 시 데이터 캐시 전체 무효화
    setOnResume(async () => {
      InvalidateBiddingOrderList();
    });
  }, []);

  return (
    <div className='min-h-full w-full bg-[#F3F6F9] flex flex-col justify-between items-start'>
      <div className='w-full pb-[50px]'>
        <DealerRequestContent />
      </div>
    </div>
  );
}
