export default function Star() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='12'
      height='12'
      viewBox='0 0 12 12'
      fill='none'
    >
      <g clipPath='url(#clip0_3418_68733)'>
        <path
          d='M6.13913 8.94761C6.05168 8.90153 5.94715 8.90149 5.85966 8.94748L3.4515 10.2136C3.23141 10.3293 2.97417 10.1424 3.01621 9.89735L3.47624 7.21558C3.49295 7.11819 3.46062 7.01882 3.38981 6.94989L1.4391 5.05107C1.26082 4.87753 1.3591 4.57488 1.60532 4.5392L4.29677 4.14913C4.39453 4.13496 4.47905 4.07358 4.52276 3.985L5.72691 1.5451C5.83695 1.32213 6.15491 1.32213 6.26495 1.5451L7.4691 3.985C7.51281 4.07358 7.59733 4.13496 7.69509 4.14913L10.3865 4.5392C10.6328 4.57488 10.731 4.87753 10.5528 5.05107L8.60205 6.94989C8.53124 7.01882 8.49891 7.11819 8.51562 7.21558L8.97553 9.89664C9.01759 10.1418 8.76009 10.3287 8.53999 10.2128L6.13913 8.94761Z'
          fill='#A6ADB4'
          stroke='#A6ADB4'
          strokeWidth='1.125'
          strokeLinecap='round'
          strokeLinejoin='round'
        />
      </g>
      <defs>
        <clipPath id='clip0_3418_68733'>
          <rect width='12' height='12' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
