/* eslint-disable @typescript-eslint/no-unused-vars */
import { CustomerOrderDetail } from '@carsayo/types';
import { customerReStartQuoteApi } from 'apis/quote';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import ConfirmV2 from 'components/common/v3/confirm';
import dayjs from 'dayjs';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';

type openType = 'restart';

export const StatusItem = ({
  quote,
  quoteCount,
  refetch,
}: {
  quote: CustomerOrderDetail;
  quoteCount?: number;
  refetch: () => void;
}) => {
  const navigate = useNavigate();
  const { loginMember } = useMemberStore();

  const { setLoadingDim } = useSystemStore();
  const [isConfirmOpen, setIsConfirmOpen] = useState({
    restart: false,
  });

  const handleOpen = (type: openType) => {
    setIsConfirmOpen((prevState) => ({
      ...prevState,
      [type]: !prevState[type],
    }));
  };

  const handleClickEvents = {
    restart: async () => {
      if (!quote || !quote.id) return;
      setLoadingDim(true);
      try {
        await customerReStartQuoteApi(quote.id);
        CarsayoToast.success('주문이 재개되었습니다.');
        setIsConfirmOpen({ ...isConfirmOpen, restart: false });
        refetch();
      } catch (error: any) {
        CarsayoToast.error(error?.message);
      }
      setLoadingDim(false);
    },
  };

  return (
    <div className='flex flex-col gap-[10px]'>
      {quote.state === 'submitted' && (
        <>
          {/* 1. 입찰 중 */}
          {quoteCount === 0 ? (
            <div className='bg-[#F3F6F9] rounded-[8px] py-[14px] text-center text-[0.9375rem] font-[400]'>
              카 매니저가 견적서를 작성하고 있어요.
            </div>
          ) : (
            <div className='leading-[100%] bg-[#F3F6F9] rounded-[8px] py-[14px] text-center text-[0.9375rem] font-[400]'>
              <span className='text-[#484AF5] font-[600]'>{quoteCount}개</span>
              의 견적서를 받았어요.
            </div>
          )}
        </>
      )}

      {quote.state === 'progressing' && (
        <>
          {/* 2. 진행 중 */}
          {quote.type === 'sellCar' ? (
            <div className='leading-[100%] bg-[#F3F6F9] rounded-[8px] py-[14px] text-center text-[0.9375rem] font-[400]'>
              {quote.meeting_date ? (
                <span>
                  미팅 일정 :{' '}
                  {dayjs(quote.meeting_date).format('YYYY.MM.DD(dd)')}
                </span>
              ) : (
                <>
                  <span className='text-[#484AF5] font-[600]'>1개</span>의
                  견적서를 낙찰했어요!
                </>
              )}
            </div>
          ) : (
            <div className='bg-[#F3F6F9] rounded-[8px] py-[14px] text-center text-[0.9375rem] font-[400]'>
              담당 딜러와 연락 및 계약을 진행합니다.
            </div>
          )}
        </>
      )}

      {/* 3. 주문 완료 */}
      {quote.state === 'finished' && (
        <div
          className={cn(
            'bg-[#F3F6F9] rounded-[8px] text-[0.9375rem] leading-[150%] font-[500] relative',
            quote.type !== 'sellCar' && 'grid grid-cols-2',
          )}
        >
          {quote.review === null ? (
            <button
              className={cn(
                'py-[14px] text-[#494AF1] w-full',
                quote.type === 'sellCar' && 'bg-[#F0F0FD] rounded-[8px]',
              )}
              onClick={() => navigate(`/quote/review/${quote.id}`)}
            >
              리뷰 작성
            </button>
          ) : (
            <button
              className='py-[14px] w-full'
              onClick={() =>
                navigate(
                  `/mypage/review?status=written&filter=${quote.type === 'sellCar' ? 'sell' : 'new'}`,
                )
              }
            >
              {quote.type === 'sellCar' && '작성한 '}리뷰 보기
            </button>
          )}
          {quote.type !== 'sellCar' && (
            <>
              <button
                className='py-[14px]'
                onClick={() => navigate(`/order/quote/customer/${quote.id}`)}
              >
                상세 보기
              </button>
              <div className='w-[1px] h-5 bg-[#CACCCF] absolute top-[1rem] right-1/2'></div>
            </>
          )}
        </div>
      )}

      {/* 4. 유찰 */}
      {['nonAccepted', 'failBid'].includes(quote.state) && (
        <div className='bg-[#F3F6F9] rounded-[8px] py-[14px] text-center text-[0.9375rem] font-[400]'>
          매칭된 견적 또는 입찰 내역이 존재하지 않습니다.
        </div>
      )}

      {/* 5. 보류 : 내 차팔기만 */}
      {quote.state === 'holded' &&
        quote.lastUpdateMemberId === loginMember?.id && (
          <div className='grid grid-cols-2 bg-[#F3F6F9] rounded-[8px] py-[14px] text-[0.9375rem] leading-[150%] font-[500] relative'>
            <button onClick={() => handleOpen('restart')}>
              재{quote?.type === 'sellCar' ? '진행' : '주문'}
            </button>
            <button
              onClick={() => navigate(`/quote/reason/cancel/${quote.id}`)}
            >
              {quote?.type === 'sellCar' ? '판매' : '주문'} 취소
              {/* TODO 상세에서는 판매 취소인데 어떤게 맞나 */}
            </button>
            <div className='w-[1px] h-5 bg-[#CACCCF] absolute top-[1rem] right-1/2'></div>
          </div>
        )}

      <ConfirmV2
        title={'재진행'}
        desc={`다시 돌아오셨군요!
      이어서 진행하시겠습니까?
      진행 시 카 매니저분이 다시 연락드릴 예정입니다.`}
        confirmText={'진행'}
        cancelText={'취소'}
        confirmColor={
          'text-[#484AF5] text-center text-[15px] font-[500] leading-[100%] tracking-[-0.3px]'
        }
        isCloseButton={true}
        isOpen={isConfirmOpen.restart}
        setIsOpen={() => handleOpen('restart')}
        onConfirm={handleClickEvents.restart}
      />
    </div>
  );
};
