import { UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import { OrderPurchaseMethodList } from './interface';
import { BuyerType, DutyFreeCondition, SigunguData } from '@carsayo/types';
import { KEY_INTERIOR_CAR_DIRECTINPUT } from './constants';

/** 구매 주문 정보 */
export const OrderPurchaseSchema = z
  .object({
    carMakerId: z.number().optional(),
    /** 슈퍼카 여부 */
    isSuperCar: z.boolean().optional(),
    carModelId: z.number().optional(),
    carTrimId: z.number().optional(),
    carGradeId: z.number().optional(),

    /** 색상은 미선책 가능
     * @note 내장색상
     */
    carColorId: z.number().optional(),

    /** 내장색상 추가 입력 정보 */
    interiorCarColor: z
      .object({
        id: z.number().default(KEY_INTERIOR_CAR_DIRECTINPUT),
        name: z.string().default(''),
        price: z.number().default(0),
        imageUrl: z.string().optional(),
        category: z.string().optional(),
        color_code_main: z.string().optional(),
        color_code_sub: z.string().optional(),
        warning_text: z.string().optional(),
        /** CarColorRestrictions */
        restrictions: z
          .object({
            incompatible: z
              .object({
                color: z.array(z.string()).optional(),
                option: z.array(z.string()).optional(),
              })
              .optional(),
            nessary: z
              .object({
                option: z.array(z.string()).optional(),
              })
              .optional(),
          })
          .nullable(),

        /** 하위 선택 항목
         * @note 현재 내장색상에서만 정상동작합니다.
         * @note 제네시스 내장재 선택에서 주로 사용합니다.
         */
        internalSelection: z
          .object({
            /** 색상 하위 선택항목 이름
             * @example 애쉬 우드 메탈 G-Matrix 패턴
             */
            name: z.string(),
            /** 색상 하위 선택항목 가격 */
            price: z.number(),
            /** 색상 하위 선택항목 이미지 URL */
            imageUrl: z.string().nullable(),
          })
          .nullable(),
      })
      .optional(),

    /** 옵션 선택 정보 */
    optionList: z.array(z.number()),
    /** 구매 방법 */
    purchaseMethod: z.enum(
      Object.keys(OrderPurchaseMethodList) as [
        keyof typeof OrderPurchaseMethodList,
      ],
    ),
    /** 구매 관련 정보 */
    purchaseInfo: z.object({
      /** 장기렌트 */
      장기렌트: z.object({
        /** 구입주체 */
        buyerType: z.enum(Object.keys(BuyerType) as [keyof typeof BuyerType], {
          message: '구입주체를 선택해주세요.',
        }),
        /** 계약기간 */
        contractTerm: z.number({
          message: '계약기간을 선택해주세요.',
        }),
        /** 보증금 */
        price_deposit: z.number({
          message: '보증금을 선택해주세요.',
        }),
        /** 선납금 */
        price_advancedPayment: z.number({
          message: '선납금을 선택해주세요.',
        }),
        /** 잔존가치 */
        price_residual: z.number({
          message: '잔존가치를 선택해주세요.',
        }),
        /** 약정거리 */
        agreeDistace: z.number({
          message: '약정거리를 선택해주세요.',
        }),
        /** 인수(탁송)지역 */
        deliveryRegion: z.enum(
          SigunguData.map((el) => el.id.toString()) as [string, ...string[]],
          {
            message: '인수(탁송) 지역을 선택해주세요.',
          },
        ),
        /** 정비 */
        maintenancePlan: z.enum(['일반형', '고급형', '최고급형'], {
          message: '정비 방법을 선택해주세요.',
        }),
        /** 운전자 연령
         * @note 21: 만 21세 이상
         * @note 26: 만 26세 이상
         */
        driverMiniumAge: z.number({
          message: '운전자 연령을 선택해주세요.',
        }),
        /** 면책금 */
        indemnityAmount: z.number({
          message: '면책금을 선택해주세요.',
        }),
        /** 보험담보 (자손) */
        personalInjuryCoverage: z.number({
          message: '보험담보(자손)을 선택해주세요.',
        }),
        /** 보험담보 (대물) */
        propertyDamageCoverage: z.number({
          message: '보험담보(대물)을 선택해주세요.',
        }),
      }),

      /** 리스 */
      리스: z.object({
        /** 구입주체 */
        buyerType: z.enum(Object.keys(BuyerType) as [keyof typeof BuyerType], {
          message: '구입주체를 선택해주세요.',
        }),
        /** 계약기간 */
        contractTerm: z.number({
          message: '계약기간을 선택해주세요.',
        }),
        /** 보증금 */
        price_deposit: z.number({
          message: '보증금을 선택해주세요.',
        }),
        /** 선납금 */
        price_advancedPayment: z.number({
          message: '선납금을 선택해주세요.',
        }),
        /** 잔존가치 */
        price_residual: z.number({
          message: '잔존가치를 선택해주세요.',
        }),
        /** 인수(탁송)지역 */
        deliveryRegion: z.enum(
          SigunguData.map((el) => el.id.toString()) as [string, ...string[]],
          {
            message: '인수(탁송) 지역을 선택해주세요.',
          },
        ),
        /** 약정거리 */
        agreeDistace: z.number({
          message: '약정거리를 선택해주세요.',
        }),
        /** 자동차세 */
        isIncludingCarTax: z.boolean({
          message: '자동차세 포함 여부를 선택해주세요.',
        }),
      }),

      /** 할부 */
      할부: z.object({
        /** 구입주체 */
        buyerType: z.enum(Object.keys(BuyerType) as [keyof typeof BuyerType], {
          message: '구입주체를 선택해주세요.',
        }),
        /** 할부기간 */
        installmentTerm: z.number({
          message: '할부기간을 선택해주세요.',
        }),
        /** 인수(탁송)지역 */
        deliveryRegion: z.enum(
          SigunguData.map((el) => el.id.toString()) as [string, ...string[]],
          {
            message: '인수(탁송) 지역을 선택해주세요.',
          },
        ),
        /** 거주(탁송)지역 */
        residenceRegion: z.enum(
          SigunguData.map((el) => el.id.toString()) as [string, ...string[]],
          {
            message: '거주(등록) 지역을 선택해주세요.',
          },
        ),
        /** 면세조건 */
        dutyFreeCondition: z.enum(
          Object.keys(DutyFreeCondition) as [keyof typeof DutyFreeCondition],
          {
            message: '면세조건을 선택해주세요.',
          },
        ),
        /** 구입시기 (일자)
         * @description YYYYMMDD형식입니다
         * @notice null일 경우 "1개월 이내" 입니다.
         */
        purchaseDate: z
          .string({ required_error: '구입시기를 선택해 주세요.' }) // undefined일 때 에러 메시지 지정
          .regex(/^\d{8}$/, '구입시기를 선택해 주세요.') // YYYYMMDD 체크
          .nullable(), // null 허용
        /** 할부원금
         * @description 원 단위입니다
         * @notice null일 경우 "생략" 입니다.
         */
        installmentPrincipal: z
          .number({
            message: '할부원금을 입력해주세요.',
          })
          .min(1, '할부원금을 입력해주세요.')
          .nullable(),
        /** 선수금 (인도금)
         * @description 원 단위입니다
         */
        prepaidPrincipalAmount: z
          .number({
            message: '선수금을 입력해주세요.',
          })
          .min(1, '선수금을 입력해주세요.'),
        /** 등록비 포함/미포함 */
        isRegistrationFee: z.boolean({
          message: '등록비 포함 여부를 선택해주세요.',
        }),
      }),

      /** 일시불 */
      일시불: z.object({
        /** 구입주체 */
        buyerType: z.enum(Object.keys(BuyerType) as [keyof typeof BuyerType], {
          message: '구입주체를 선택해주세요.',
        }),
        /** 인수(탁송)지역 */
        deliveryRegion: z.enum(
          SigunguData.map((el) => el.id.toString()) as [string, ...string[]],
          {
            message: '인수(탁송) 지역을 선택해주세요.',
          },
        ),
        /** 거주(탁송)지역 */
        residenceRegion: z.enum(
          SigunguData.map((el) => el.id.toString()) as [string, ...string[]],
          {
            message: '거주(등록) 지역을 선택해주세요.',
          },
        ),
        /** 면세조건 */
        dutyFreeCondition: z.enum(
          Object.keys(DutyFreeCondition) as [keyof typeof DutyFreeCondition],
          {
            message: '면세조건을 선택해주세요.',
          },
        ),
        /** 구입시기 (일자)
         * @description YYYYMMDD형식입니다
         * @notice null일 경우 "1개월 이내" 입니다.
         */
        purchaseDate: z
          .string({ required_error: '구입시기를 선택해 주세요.' }) // undefined일 때 에러 메시지 지정
          .regex(/^\d{8}$/, '구입시기를 선택해 주세요.') // YYYYMMDD 체크
          .nullable(), // null 허용
      }),
    }),
    /** 추가 정보 */
    additionalInfo: z.object({
      /** 타사 혜택 내용 */
      otherCompanyEstimateContent: z.string().optional(),
      /** 추가 요청내용 */
      additionalRequest: z.string().optional(),
      /** 견적서 / 구매 계약서
       * @note 슈퍼카: 구매 계약서
       * @note 비슈퍼카: 견적서
       * @condition 슈퍼카 주문일 경우 구매 계약서 업로드 필수
       * @condition 비슈퍼카 주문일 경우, 할부/일시불일 때에만 견적서 업로드 필수
       * @note 유효성 검사는 함수 안에서 합니다.
       */
      otherCompanyEstimateFileId: z.string().optional(),
    }),
  })
  .refine((data) => data.carModelId !== undefined, {
    message: 'carModelId는 필수값입니다.',
    path: ['carModelId'],
  })
  .refine((data) => data.carMakerId !== undefined, {
    message: 'carMakerId는 필수값입니다.',
    path: ['carMakerId'],
  })
  .refine((data) => data.carTrimId !== undefined, {
    message: 'carTrimId는 필수값입니다.',
    path: ['carTrimId'],
  })
  .refine((data) => data.carGradeId !== undefined, {
    message: 'carGradeId는 필수값입니다.',
    path: ['carGradeId'],
  });

/** 구매 주문 정보 */
export type OrderPurchaseFormData = z.infer<typeof OrderPurchaseSchema>;
export type OrderPurchaseForm = UseFormReturn<OrderPurchaseFormData>;
