/* eslint-disable @typescript-eslint/no-unused-vars */
import { zodResolver } from '@hookform/resolvers/zod';
import ImageUploadBox from 'components/common/v3/fileUploadBox';
import Star from 'components/svg/Star';
import TextArea from 'components_v3/ui/TextArea';
import { X } from 'lucide-react';
import { useLayoutEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import { cn } from 'utils';
import { z } from 'zod';
import CarInfoItem from '../customer/components/list/CarInfoItem';
import V3_Button from 'components_v3/ui/Button';
import DealerInfoCard from '../customer/components/DealerInfoCard';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import V3_Input from 'components_v3/ui/Input';
import FileInput from './component/FileInput';
import { FileInfo, ReviewOrderDTO } from '@carsayo/types';
import { reviewOrder } from 'apis/order';
import MultiImageViewer from 'components_v3/dialog/MultiImageViewer';
import V3_Confirm from 'components_v3/dialog/Confirm';
import { useQuery } from '@tanstack/react-query';
import { getCustomerQuoteDetailApi } from 'apis/quote';
import { convertDealerType } from 'utils/convert';

export const QuoteReviewSchema = z.object({
  orderId: z.string(),
  rating: z.number().max(5, { message: '별점은 5점까지 가능합니다.' }).min(1, {
    message: '별점은 1점 이상이어야 합니다.',
  }),
  title: z.string().min(1, { message: '리뷰 제목을 입력해 주세요' }),
  content: z
    .string()
    .min(10, { message: '리뷰 내용을 10자 이상 입력해 주세요' }),
  file_1_id: z.string().nullable(),
  file_2_id: z.string().nullable(),
  file_3_id: z.string().nullable(),
  file_4_id: z.string().nullable(),
});

export type QuoteReviewForm = z.infer<typeof QuoteReviewSchema>;

const QuoteReview = () => {
  const { id } = useParams();
  const navigate = useNavigate();

  const { setTopTabbarState, setIsBottomTabbar, setLoadingDim } =
    useSystemStore();

  const [subImages, setSubImages] = useState<FileInfo[]>([]);
  const [subImagePage, setSubImagePage] = useState(1);
  const [isSubImagesPopup, setSubImagesPopup] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const form = useForm<QuoteReviewForm>({
    resolver: zodResolver(QuoteReviewSchema),
    defaultValues: {
      orderId: id,
      rating: 0,
      title: '',
      content: '',
      file_1_id: null,
      file_2_id: null,
      file_3_id: null,
      file_4_id: null,
    },
  });

  const { data: quoteDetail } = useQuery({
    queryKey: ['quoteDetail', id],
    queryFn: () => getCustomerQuoteDetailApi(id!),
    enabled: !!id,
    staleTime: 1000 * 60 * 10,
    gcTime: 1000 * 60 * 10,
  });

  const handleClickEvents = {
    review: async () => {
      if (form.watch('rating') === 0) {
        CarsayoToast.error('평점을 선택해 주세요.');
        return;
      }
      setLoadingDim(true);
      const params = { ...form.getValues() };

      params.file_2_id = subImages[0] ? subImages[0].id : null;
      params.file_3_id = subImages[1] ? subImages[1].id : null;
      params.file_4_id = subImages[2] ? subImages[2].id : null;

      try {
        await reviewOrder(params as ReviewOrderDTO);
        setIsOpen(true);
      } catch (error: any) {
        CarsayoToast.error(error?.message);
      }
      setLoadingDim(false);
    },
  };

  const openPopupAtSlide = (index: number) => {
    setSubImagePage(index + 1);
    setSubImagesPopup(true);
  };

  const handleImageUpload = (item: FileInfo) => {
    setSubImages((prev) => [...prev, item]);
  };

  const handleRemoveImage = (index: number) => {
    setSubImages(subImages.filter((_, i) => i !== index));
  };

  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'hidden',
      mode: 'subpage',
      title: ' ',
      leftElement: undefined,
      rightElement: undefined,
    });
    setIsBottomTabbar(false);
  }, []);

  const mainText = quoteDetail?.type === 'sellCar' ? '판매' : '구매';

  return (
    <div className='h-full w-full flex flex-col relative justify-between'>
      <header>
        <div className='w-full h-[60px] flex items-center justify-between bg-white/50 sticky top-0 px-4'>
          <div className='w-[32px]'></div>
          <div className='text-[#222] text-center font-Pretendard text-[20px] font-semibold leading-[20px] tracking-[-0.4px]'>
            리뷰 작성
          </div>
          <button onClick={() => navigate(-1)}>
            <X className='stroke-[1.4px]' size={32} />
          </button>
        </div>
      </header>

      <div className='relative flex flex-col flex-auto justify-between overflow-y-auto gap-3'>
        <div className='flex flex-col gap-5 p-5 border-b-[8px] border-[#F3F6F9] mb-[50px]'>
          {/* src={'/assets/images/v2/exampleImg.png'} */}

          <CarInfoItem
            img={
              quoteDetail?.type === 'sellCar' && quoteDetail.imageUrl
                ? quoteDetail.imageUrl[0]
                : (quoteDetail?.imageUrl as string)
            }
            typename={
              quoteDetail?.type === 'sellCar'
                ? '내 차 팔기'
                : quoteDetail?.type === 'newCar'
                  ? quoteDetail?.purchase?.purchaseInfo.method
                  : convertDealerType[quoteDetail?.type ?? 'undefined']
            }
            trim={`${quoteDetail?.brand ? `${quoteDetail.brand} ` : ''}${quoteDetail?.title}`}
            rank={
              quoteDetail?.type === 'sellCar'
                ? quoteDetail.selling?.usedCarInfo.carName
                : quoteDetail?.purchase?.selectedCar.carTrim?.name ?? ''
            }
            type={quoteDetail?.type}
          />

          <hr className='border-[#E5EAEE]' />

          <DealerInfoCard
            imgUrl={quoteDetail?.manager?.profileImage_url as string}
            desc={
              quoteDetail?.manager?.description || '등록된 소개글이 없습니다.'
            }
            rating={quoteDetail?.manager?.dealerInfo?.rating ?? 0}
            nickname={
              quoteDetail?.type === 'sellCar' ? '중고차 매니저' : '신차 매니저'
            }
            memberId={quoteDetail?.manager?.id as string}
          />
        </div>

        <div className='flex flex-col justify-start items-center gap-6 mb-[40px]'>
          <p className='max-w-[205px] text-center break-keep text-[20px] leading-[30px] font-[600]'>
            상담부터 {mainText}까지
            <br />
            원활하게 진행되었나요?
          </p>
          <div className='flex items-center justify-center gap-[2px]'>
            {[...Array(5)].map((_, index) => (
              <div
                key={index}
                onClick={async () => {
                  form.setValue('rating', index + 1, {
                    shouldValidate: true,
                  });
                }}
              >
                <Star
                  fill={index < form.watch('rating') ? '#FFC93E' : '#DDDDDD'}
                  width={'32'}
                  height={'32'}
                />
              </div>
            ))}
          </div>
        </div>

        <div className='flex flex-col gap-6 px-5 pb-[10px]'>
          <div className='w-full flex flex-col justify-start items-start gap-[12px]'>
            <strong className='text-[15px] leading-[15px] font-[400]'>
              {mainText} 후기
            </strong>
            <V3_Input
              type='text'
              {...form.register('title')}
              placeholder='제목을 입력해 주세요.'
              error={!!form.formState.errors.title}
              helperText={form.formState.errors.title?.message}
            />
          </div>

          <div className='w-full flex flex-col justify-start items-start'>
            <strong className='text-[15px] leading-[15px] font-[400] mb-3'>
              {mainText} 후기 내용
            </strong>
            <TextArea
              className='w-full'
              maxLength={1000}
              minRows={10}
              {...form.register('content')}
              placeholder='10자 이상 내용을 입력해 주세요.'
              error={!!form.formState.errors.content}
              helperText={form.formState.errors.content?.message}
            />
          </div>

          <div className='flex flex-col gap-4'>
            <strong className=' inline-block text-[15px] leading-[15px] font-[400]'>
              사진 (선택)
            </strong>
            <FileInput fileName={'0/1'} fileKey={'file_1_id'} form={form} />
          </div>

          <div className='flex flex-col gap-4'>
            <div
              className={cn(
                'flex w-full items-center justify-between',
                subImages.length > 0
                  ? 'mb-[9px] mt-[17px]'
                  : 'mb-[16px] mt-[24px]',
              )}
            >
              <strong className='inline-block text-[15px] leading-[15px] font-[400]'>
                추가 사진
              </strong>
              {subImages.length > 0 && (
                <button
                  type='button'
                  onClick={() => setSubImagesPopup(true)}
                  className='rounded-[8px] bg-[#F3F6F9] border border-[#E5EAEE] p-2 text-[13px] font-[500] leading-[13px]'
                >
                  전체보기
                </button>
              )}
            </div>
            <div className='w-full overflow-x-auto'>
              <div className='flex items-center justify-start gap-[6px]'>
                {subImages.length < 3 && (
                  <ImageUploadBox
                    id='file_id'
                    onUpload={handleImageUpload}
                    type='dashed'
                    width={'107px'}
                    height={'107px'}
                    maxCount={3}
                    currentCount={subImages.length}
                  />
                )}
                <ul className='flex items-center justify-start gap-[7px]'>
                  {subImages &&
                    subImages.map((item, index) => (
                      <li
                        className='w-full h-full flex justify-center items-center'
                        key={`${item.name}_${index}`}
                      >
                        <div
                          className='relative h-[107px] w-[107px] overflow-hidden rounded-[6px]'
                          onClick={(e) => {
                            e.preventDefault();
                            openPopupAtSlide(index);
                          }}
                        >
                          <img
                            src={item.url}
                            alt={item.name}
                            className='h-full w-full object-cover'
                          />
                          <button
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveImage(index);
                            }}
                            className='absolute right-1 top-1 z-10 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-[#000000CC]'
                          >
                            <X width={12} height={12} color='#fff' />
                          </button>
                        </div>
                      </li>
                    ))}
                </ul>
              </div>
            </div>
          </div>

          <div className='w-full mt-[26px]'>
            <V3_Button
              theme='primary'
              // onClick={handleClickEvents.review}
              onClick={form.handleSubmit(handleClickEvents.review)}
            >
              등록하기
            </V3_Button>
          </div>
        </div>
      </div>

      {subImages && subImages.length > 0 && (
        <MultiImageViewer
          isOpen={isSubImagesPopup}
          setIsOpen={setSubImagesPopup}
          initialIndex={subImagePage - 1}
          fileUrl={subImages.map((image) => image.url)}
        />
      )}

      <V3_Confirm
        isOpen={isOpen}
        setIsOpen={() => {}}
        onConfirm={() => navigate(-1)}
        blockUsingHistory={true}
        disableBackdropClose={true}
        title=''
        agreeText='확인'
        isAlert={true}
      >
        <div className='flex flex-col items-center justify-center gap-5'>
          <img
            alt='리뷰'
            width={60}
            height={60}
            src='/assets/images/png/review_icon.png'
          />
          <div className='flex flex-col items-center justify-center gap-1.5'>
            <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
              리뷰 작성 완료
            </div>
            <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
              작성해 주셔서 감사합니다.
              <br />
              해당 리뷰는 같은 고민이신 분들께
              <br />큰 도움이 될 예정이에요.
            </div>
          </div>
        </div>
      </V3_Confirm>
    </div>
  );
};

export default QuoteReview;
