/* eslint-disable @typescript-eslint/no-unused-vars */
import SubPage from 'components/view/SubPage';
import { ChevronLeft } from 'lucide-react';
import Button from 'components_v3/ui/Button';
import { useNavigate } from 'react-router-dom';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';

export default function WhatIsBidCouponSubpage({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (open: boolean) => void;
}) {
  const navigate = useNavigate();

  return (
    <SubPage isOpen={open} setIsOpen={setOpen}>
      <div className='h-full flex flex-col justify-between overflow-y-auto'>
        <div>
          <div className='flex flex-none h-14 items-center justify-between bg-white px-4'>
            <div
              className='cursor-pointer'
              onClick={() => {
                navigate(-1);
              }}
            >
              <ChevronLeft className='w-8 h-8' strokeWidth={1.5} />
            </div>
            <div className='pr-8 grow text-center text-xl font-semibold'>
              견적 요청권이란?
            </div>
          </div>

          {/* 견적 요청권 안내 */}
          <div className='flex justify-start items-center gap-4 px-5 mt-[30px]'>
            <div className='flex justify-center items-center p-2.5 bg-[#F3F6F9] rounded-[10px]'>
              <img
                src='/assets/images/png/carsayo_card.png'
                alt='카사요 기프트카드 이미지'
                className='w-[60px] object-contain'
              />
            </div>
            <div className='flex flex-col justify-start items-start gap-[14px]'>
              <div className='flex flex-col justify-start items-start gap-0.5'>
                <strong className='text-[15px] leading-[24px] font-[600]'>
                  견적 요청권
                </strong>
                <p className='text-[14px] leading-[22.4px] font-[500] text-[#555555]'>
                  신차 구매, 내 차 팔기
                </p>
              </div>
              <strong className='text-[16px] leading-[16px] font-[600]'>
                10,000원
              </strong>
            </div>
          </div>

          {/* Divider */}
          <div className='w-full h-[8px] bg-[#F3F6F9] my-[30px]'></div>

          <div className='px-5'>
            <div className='text-[#222] text-[16px] font-medium leading-[25.6px] tracking-[-0.32px] text-[#222] text-[16px] font-medium leading-[25.6px] tracking-[-0.32px]'>
              무분별한 구매·판매 요청으로 각 2대로 제한하며, <br />
              2대 초과 요청은 유료임을 알려 드립니다.
            </div>

            <div className='w-full flex-auto my-6 p-4 bg-[#F3F6F9] rounded-[10px] whitespace-pre-line break-keep'>
              <ul className='list-disc pl-5 space-y-3'>
                <li className='text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
                  무분별한 구매 또는 판매 요청으로 실 고객들과 실 딜러들의 피해
                  방지를 위함을 알립니다.
                </li>
                <li className='text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
                  구매 또는 판매 요청은 각 2대로 제한합니다. 단 카사요(주)에서
                  신차 주문 또는 중고차 판매가 성사되신 고객 회원은 각 2대까지는
                  구매 또는 판매 요청을 할 수 있습니다.
                </li>
                <li className='text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
                  1대씩 추가 요청할 때마다, 정보 이용료 1만원을 지불합니다. 지불
                  방법은 &lsquo;회사&rsquo;가 지정한 카사요(주) 계좌로
                  입금합니다.
                </li>
                <li className='text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
                  정보 이용료를 입금하지 않을 시, 발생하게 되는 불이익은 전부
                  &lsquo;일반 고객 회원&lsquo;이 부담하며{' '}
                  <span className='whitespace-nowrap'>민/형사상의</span> 모든
                  책임을 부담합니다.
                </li>
                <li className='text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
                  깨끗하고 투명한 자동차 문화를 위해 노력하는 카사요가
                  되겠습니다.
                </li>
              </ul>
            </div>
          </div>
        </div>

        <div className='px-5 flex justify-center items-center gap-[7px] pb-[10px]'>
          <Button onClick={() => navigate(-1)}>확인</Button>
          <Button
            onClick={() => {
              navigate('/order/payment/avaliableOrder?type=purchase', {
                replace: true,
              });
            }}
            theme='primary'
          >
            견적 요청권 구매
          </Button>
        </div>
      </div>
    </SubPage>
  );
}
