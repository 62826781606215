/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { useEffect, useMemo, useState } from 'react';
import {
  OrderPurchaseDefaultProps,
  OrderPurchaseMethodList,
  OrderPurchaseMode,
} from '../../../interface';
import { cn } from 'utils';
import NoticeIcon from '../../../icon/notice';
import OrderPurchase_Method_Rent from './rent';
import OrderPurchase_Method_Lease from './lease';
import OrderPurchase_Method_Halboo from './halboo';
import OrderPurchase_Method_Cash from './cash';
import OrderPurchasePriceInfoDrawer from '../../drawer/priceInfo';
import { SelectedCarData } from '@carsayo/types';

interface OrderPurchaseMethodProps extends OrderPurchaseDefaultProps {
  orderPurchaseMode: OrderPurchaseMode;
  selectedCarData: SelectedCarData | null;
  totalPrice: number;
}

const OrderPurchase_Method = ({
  form,
  totalPrice,
  selectedCarData,
  wholeSellingCar,
  orderPurchaseMode,
}: OrderPurchaseMethodProps) => {
  const currentGradeId = form.getValues('carGradeId');
  if (!currentGradeId) return null;

  /** 가격 정보 Drawer 열림 여부 */
  const [open, setOpen] = useState(false);

  /** 선택 가능 구매 방법 목록 */
  const AvaliablePurchaseMethodList: OrderPurchaseMethodList[] = useMemo(() => {
    if (orderPurchaseMode === 'newcar') {
      return [
        OrderPurchaseMethodList['할부'],
        OrderPurchaseMethodList['일시불'],
      ];
    }

    if (form.getValues('isSuperCar')) {
      return [
        OrderPurchaseMethodList['리스'],
        OrderPurchaseMethodList['장기렌트'],
      ];
    }

    if (orderPurchaseMode === 'lease') {
      return [
        OrderPurchaseMethodList['리스'],
        OrderPurchaseMethodList['장기렌트'],
        OrderPurchaseMethodList['할부'],
        OrderPurchaseMethodList['일시불'],
      ];
    }

    if (orderPurchaseMode === 'rent') {
      return [
        OrderPurchaseMethodList['리스'],
        OrderPurchaseMethodList['장기렌트'],
        OrderPurchaseMethodList['할부'],
        OrderPurchaseMethodList['일시불'],
      ];
    }

    return [];
  }, [orderPurchaseMode]);

  return (
    <>
      <div className='p-5 pb-0'>
        <div className='text-[#000] text-[16px] font-semibold leading-[16px] tracking-[-0.32px]'>
          구매 방법
        </div>

        {/* Form영역 */}
        <div className='mt-6'>
          {/* 결제 방식 선택 */}
          <>
            <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
              결제방식
            </div>
            <div className='mt-[18px] grid grid-cols-2 gap-2'>
              {AvaliablePurchaseMethodList.map((el) => (
                <button
                  key={el.name}
                  onClick={() => {
                    form.setValue('purchaseMethod', el.name);
                  }}
                  className={cn(
                    'bg-[#F3F6F9] border border-[#F3F6F9] rounded-[8px] p-2.5 flex justify-start items-center gap-3 cursor-pointer',
                    form.watch('purchaseMethod') === el.name &&
                      'border-[#7273F9]',
                  )}
                >
                  <img
                    src={el.imgSrc}
                    alt={el.name}
                    className='w-[34px] h-[34px]'
                  />
                  <div
                    className={cn(
                      'text-[#222] text-[14px] font-medium leading-[14px] tracking-[-0.28px]',
                      form.watch('purchaseMethod') === el.name &&
                        'text-[#484AF5] font-semibold',
                    )}
                  >
                    {el.name}
                  </div>
                </button>
              ))}
            </div>
          </>
        </div>

        {/* Divider */}
        <div className='h-[1px] w-full bg-[#E5EAEE] mt-6'></div>

        {/* 추가 선택 사항 입력 */}
        <div className='py-6 flex-auto'>
          {form.watch('purchaseMethod') === '장기렌트' && (
            <OrderPurchase_Method_Rent
              form={form}
              wholeSellingCar={wholeSellingCar}
              totalPrice={totalPrice}
            />
          )}
          {form.watch('purchaseMethod') === '리스' && (
            <OrderPurchase_Method_Lease
              form={form}
              wholeSellingCar={wholeSellingCar}
              totalPrice={totalPrice}
            />
          )}
          {form.watch('purchaseMethod') === '할부' && (
            <OrderPurchase_Method_Halboo
              form={form}
              wholeSellingCar={wholeSellingCar}
              totalPrice={totalPrice}
            />
          )}
          {form.watch('purchaseMethod') === '일시불' && (
            <OrderPurchase_Method_Cash
              form={form}
              wholeSellingCar={wholeSellingCar}
              totalPrice={totalPrice}
            />
          )}
        </div>
      </div>

      {/* 총 예상금액 */}
      <div className='flex flex-col flex-auto justify-end'>
        {/* Divivder */}
        <div className='bg-[#F3F6F9] h-2 w-full'></div>

        <div className='mt-[30px] px-5 flex justify-between items-center'>
          <div className='text-[#555] text-[15px] font-medium leading-[15px] tracking-[-0.3px]'>
            총 예상 금액
          </div>
          <button
            className='flex-auto mt-[1.5px] flex justify-end items-center gap-1'
            onClick={(e) => {
              setOpen(true);
            }}
          >
            <span className='text-[#222] text-right text-[18px] font-semibold leading-[18px] tracking-[-0.36px]'>
              {totalPrice.toLocaleString()}원
            </span>
            <NoticeIcon />
          </button>
        </div>

        <div className='mt-6 mb-2 bg-[#F3F6F9] rounded-[10px] p-4 mx-5 text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
          자동차 할인 금액에 따라 금액은 변동될 수 있습니다.
        </div>
      </div>

      {selectedCarData && (
        <OrderPurchasePriceInfoDrawer
          open={open}
          setOpen={setOpen}
          totalPrice={totalPrice}
          selectedCarData={selectedCarData}
        />
      )}
    </>
  );
};

export default OrderPurchase_Method;
