import dayjs from 'dayjs';
import RedClock from '../image/RedClock';
import getBidEndDiff from '../utils/getBidEndDiff';

export default function RemainTime({ bid_ended_at }: { bid_ended_at: Date }) {
  return (
    <div className='h-[28px] flex items-center gap-1 rounded-[6px] bg-[#FFE7E7] py-[5px] px-[6px]'>
      <RedClock />
      <div className='text-[#FF4747] text-ellipsis text-[13px] font-semibold leading-[13px] tracking-[-0.26px]'>
        {getBidEndDiff(dayjs(bid_ended_at))}
      </div>
    </div>
  );
}
