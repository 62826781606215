import { DealerOrderDetailProps } from '../../../type';
import DealerOrderDetailTabSelector from '../components/tabSelector';
import DealerOrderDetailInfoBidder from '../components/content/bidder';
import PurchaseCarInfoTabContent from '../components/content/purchaseCarInfo';
import PurchaseAdditionalInfoContent from '../components/content/purchaseAdditionalInfo';
import DealerOrderDetailBidInfo from '../components/content/biddInfo';
import useQueryState from 'hooks/useQueryState';

const tabs = ['견적 정보', '모델 정보', '구매 정보', '참여자'];

export default function DealerOrderDetailInfoPurchase({
  orderDetail,
}: DealerOrderDetailProps) {
  const [tab, setTab] = useQueryState<string>(
    'tab_purchase',
    tabs[0],
    'string',
  );

  return (
    <>
      {/* Tab selector */}
      <DealerOrderDetailTabSelector tabs={tabs} tab={tab} setTab={setTab} />

      {/* Tab content */}
      {tab === '참여자' && (
        <DealerOrderDetailInfoBidder orderDetail={orderDetail} />
      )}
      {tab === '모델 정보' && orderDetail.purchase && (
        <PurchaseCarInfoTabContent
          orderDetail={orderDetail}
          purchaseCarInfo={orderDetail.purchase.selectedCar}
        />
      )}
      {tab === '구매 정보' && orderDetail.purchase && (
        <PurchaseAdditionalInfoContent
          purchaseOrderDetailInfo={orderDetail.purchase}
        />
      )}

      {tab === '견적 정보' && (
        <DealerOrderDetailBidInfo orderDetail={orderDetail} />
      )}
    </>
  );
}
