/* eslint-disable @typescript-eslint/no-unused-vars */
import { Checkbox, CheckboxProps, FormControlLabel } from '@mui/material';
import { styled } from '@mui/material/styles';
import React from 'react';

type CheckboxType = 'sub' | 'main' | 'radio';

const selectImage: Record<
  CheckboxType,
  {
    checked: string;
    unchecked: string;
  }
> = {
  sub: {
    checked: '/assets/images/png/check_sub.png',
    unchecked: '/assets/images/png/unCheck_sub.png',
  },
  main: {
    checked: '/assets/images/png/check.png',
    unchecked: '/assets/images/png/unCheck.png',
  },
  radio: {
    checked: '/assets/images/png/check_radio.png',
    unchecked: '/assets/images/png/uncheck_radio.png',
  },
};

interface StyledCheckProps extends CheckboxProps {
  type: CheckboxType;
}

const StyledCheck = styled(Checkbox, {
  shouldForwardProp: (prop) => prop !== 'type',
})<StyledCheckProps>(({ type }) => ({
  width: 28,
  height: 28,
  borderRadius: '50%',
  marginRight: '4px',
  border: '1px solid #E5E5EC',
  '&.Mui-checked': {
    borderColor: 'transparent',
    color: 'transparent',
    backgroundImage: `url(${selectImage[type].checked})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  '&.MuiCheckbox-root:not(.Mui-checked)': {
    borderColor: 'transparent',
    color: 'transparent',
    backgroundImage: `url(${selectImage[type].unchecked})`,
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  '&.Mui-disabled': {
    opacity: 0.5,
    cursor: 'not-allowed',
  },
}));

interface CustomCheckProps extends CheckboxProps {
  type?: CheckboxType;
  className?: string;
}

const OptionCheckInput: React.FC<CustomCheckProps> = ({
  type,
  className,
  ...props
}) => {
  return (
    <FormControlLabel
      sx={{
        marginRight: '0px',
        marginLeft: '0px',
      }}
      control={<StyledCheck type={type ? type : 'main'} {...props} />}
      label={''}
    />
  );
};
export { OptionCheckInput };
