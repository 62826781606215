/** 차량명을 조립해주는 함수
 * @note 중복 제거 기능 (ex. 쏘나타 디 엣지 하이브리드 + 하이브리드 + 익스클루시브 => 쏘나타 디 엣지 하이브리드 익스클루시브)
 */
export default function combineCarName({
  modelName,
  trimName,
  gradeName,
}: {
  modelName?: string;
  trimName?: string;
  gradeName?: string;
}) {
  const names = [modelName, trimName, gradeName]
    .filter(Boolean)
    .flatMap((name) => name?.split(' '));
  const uniqueNames = Array.from(new Set(names));
  return uniqueNames.join(' ');
}
