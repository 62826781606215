export default function NoDataTab({
  title,
  content,
}: {
  title: string;
  content: string;
}) {
  return (
    <div className='flex flex-col gap-4'>
      <div className='text-[#A1A7AC] text-center text-[18px] font-semibold leading-[28.8px] tracking-[-0.36px]'>
        {title}
      </div>
      <div className='text-[#A1A7AC] text-center text-[15px] font-normal leading-[22.5px] tracking-[-0.3px]'>
        {content}
      </div>
    </div>
  );
}
