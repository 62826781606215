import { PurchaseCarInfo } from '@carsayo/types';
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { X } from 'lucide-react';
import combineCarName from 'pages/v3/order/purchase/utils/combineCarName';
import getPrice_PurchaseCarInfo from 'utils/price/getPrice_PurchaseCarInfo';

export function PriceDetail({
  isOpen,
  setIsOpen,
  purchaseCarInfo,
}: {
  isOpen: boolean;
  setIsOpen: (value: boolean) => void;
  purchaseCarInfo: PurchaseCarInfo;
}) {
  return (
    <Drawer open={isOpen} onOpenChange={(open) => setIsOpen(open)}>
      <DrawerContent className='flex flex-col'>
        <DrawerHeader className='relative'>
          <DrawerTitle>상세 금액</DrawerTitle>
          <DrawerClose asChild>
            <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
              <X className='h-7 w-7' />
            </Button>
          </DrawerClose>
        </DrawerHeader>
        <div className='grow overflow-y-auto p-4'>
          <div className='mt-2 border-b py-4'>
            <div className='text-desc'>신청 모델</div>
            <div className='mt-2 space-y-2'>
              <div className='flex justify-between'>
                <div className='text-[##555555]'>
                  {purchaseCarInfo?.carMaker?.name}{' '}
                  {purchaseCarInfo?.carModel?.name}
                </div>
                <div className='text-[##555555]'></div>
              </div>
            </div>
          </div>
          <div className='mt-2 border-b py-4'>
            <div className='text-desc'>상세 등급</div>
            <div className='mt-2 space-y-2'>
              <div className='flex justify-between'>
                <div className='text-[##555555]'>
                  {combineCarName({
                    trimName: purchaseCarInfo?.carTrim?.name ?? '',
                    gradeName: purchaseCarInfo?.carGrade?.name ?? '',
                  })}
                </div>
                <div className='text-desc font-semibold'>
                  {purchaseCarInfo?.carGrade?.price.toLocaleString()}원
                </div>
              </div>
            </div>
          </div>
          <div className='mt-2 border-b py-4'>
            <div className='text-desc'>색상</div>
            {purchaseCarInfo?.carColor ? (
              <div className='mt-2 space-y-2'>
                <div className='flex justify-between'>
                  <div className='text-[##555555]'>
                    {purchaseCarInfo?.carColor?.name}
                  </div>
                  {purchaseCarInfo?.carColor?.price &&
                  purchaseCarInfo.carColor.price > 0 ? (
                    <div className='text-desc font-semibold'>
                      + {purchaseCarInfo?.carColor?.price.toLocaleString()}원
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </div>
            ) : (
              <div className='mt-2 text-center'>색상은 상담 시 논의합니다.</div>
            )}
          </div>
          <div className='mt-2 border-b py-4'>
            <div className='text-desc'>추가 옵션</div>
            {purchaseCarInfo?.carOptionList &&
            purchaseCarInfo?.carOptionList.length > 0 ? (
              <div className='mt-2 space-y-2'>
                {purchaseCarInfo?.carOptionList.map((el) => {
                  return (
                    <div key={el.id} className='flex justify-between gap-2'>
                      <div className='text-[##555555]'>{el.name}</div>
                      <div className='text-desc font-semibold flex-none'>
                        + {el.price.toLocaleString()}원
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className='mt-2 text-center'>추가 옵션이 없습니다.</div>
            )}
          </div>
          <div className='flex items-center justify-between py-4'>
            <div className='text-primary'>총 예상 금액</div>
            <div className='font-semibold text-primary'>
              {getPrice_PurchaseCarInfo(purchaseCarInfo).toLocaleString()}원
            </div>
          </div>
          <div className='bg-[#F7F7FB] p-4 text-desc text-[#767676]'>
            · 선택 차량에 대한 옵션은 상담 시 추가/변경 가능합니다. <br />·
            실거래가는 제조사 및 딜러사의 정책에 따리 달라질 수 있다는 점 참고
            부탁드립니다.
          </div>
          <div className='mt-10 pb-6'>
            <Button
              onClick={() => {
                setIsOpen(false);
              }}
            >
              확인
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
