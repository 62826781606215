/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { WholeSellingCar } from '@carsayo/types';
import { useEffect, useMemo, useRef, useState } from 'react';
import { cn } from 'utils';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import { OrderPurchaseDefaultProps, OrderPurchaseMode } from '../../interface';
import { OrderPurchaseForm } from '../../form';

const BrandType = ['domestic', 'imported', 'supercar'] as const;
type BrandType = (typeof BrandType)[number];

const BrandCard = ({
  brand,
  form,
}: {
  brand: WholeSellingCar;
  form: OrderPurchaseForm;
}) => {
  const randomCarModel = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * brand.car_model.length);
    // 모델 이미지가 있을 경우에 리턴
    if (brand.car_model[randomIndex].image_url)
      return brand.car_model[randomIndex];
    else return brand.car_model[0];
  }, []);

  const isSelected = useMemo(() => {
    return form.getValues('carMakerId') === brand.id;
  }, [form.watch('carMakerId')]);

  return (
    <div
      className={cn(
        'cursor-pointer w-full h-[170px] relative border border-[#E5E5EC] bg-gradient-to-b from-[#FFFFFF] to-[#F3F6F9] rounded-[10px] p-4',
        isSelected && 'border-[#7273F9] border-2',
      )}
      onClick={() => {
        if (isSelected === false) {
          form.setValue('carMakerId', brand.id);
          form.setValue('isSuperCar', brand.isSuperCar);
        } else {
          form.setValue('carMakerId', undefined);
          form.setValue('isSuperCar', undefined);
        }
      }}
    >
      <div className='flex flex-col justify-start items-start gap-1.5'>
        <img
          src={brand.image_url}
          alt={`${brand.name}_logo`}
          className='w-[40px] h-[40px] object-contain'
        />
        <span className='text-[16px] leading-[16px] font-[600]'>
          {brand.name}
        </span>
      </div>
      <div className='absolute right-0 bottom-2.5 h-[90px] tiny:w-[95px]'>
        <img
          src={randomCarModel.image_url}
          alt='대표 차량 이미지 이미지'
          className='h-[90px] object-cover object-left w-[110px] tiny:w-[95px]'
        />
      </div>
    </div>
  );
};

interface OrderPurchaseBrandProps extends OrderPurchaseDefaultProps {
  orderPurchaseMode: OrderPurchaseMode;
}
const OrderPurchase_Brand = ({
  form,
  wholeSellingCar,
  orderPurchaseMode,
}: OrderPurchaseBrandProps) => {
  const targetBrand = wholeSellingCar.find((el) => {
    return el.id === form.getValues('carMakerId');
  });
  const [currentTab, setCurrentTab] = useState<BrandType>(
    targetBrand?.type === 'domestic'
      ? 'domestic'
      : targetBrand?.type === 'imported' && targetBrand.isSuperCar === false
        ? 'imported'
        : targetBrand?.type === 'imported' && targetBrand.isSuperCar === true
          ? 'supercar'
          : 'domestic',
  );

  const swiperRef = useRef<any>(null);

  const domesticBrand: WholeSellingCar[] = useMemo(() => {
    return wholeSellingCar.filter((el) => {
      return el.type === 'domestic';
    });
  }, [wholeSellingCar]);
  const importedBrand: WholeSellingCar[] = useMemo(() => {
    return wholeSellingCar.filter((el) => {
      return el.type === 'imported' && el.isSuperCar === false;
    });
  }, [wholeSellingCar]);
  const superCarBrand: WholeSellingCar[] = useMemo(() => {
    return wholeSellingCar.filter((el) => {
      return el.type === 'imported' && el.isSuperCar === true;
    });
  }, [wholeSellingCar]);

  const handleSlideChange = (swiper: any) => {
    setCurrentTab(BrandType[swiper.activeIndex]);
  };

  const getTabIndex = (tab: BrandType) => {
    return BrandType.findIndex((el) => el === tab);
  };

  // `currentTab`이 변경될 때 Swiper 이동
  useEffect(() => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideTo(getTabIndex(currentTab));
    }
  }, [currentTab]);

  return (
    <div className='flex flex-col h-full w-full'>
      <div className='bg-white pt-[18px] px-5 pb-5'>
        <div className='flex justify-center items-center rounded-full bg-[#E5EAEE] bg-opacity-[80%] backdrop-blur-[50px] p-1.5'>
          <button
            onClick={() => setCurrentTab('domestic')}
            className={cn(
              'py-2.5 px-3 flex-auto h-[40px] inline-flex justify-center items-center text-[16px] leading-[16px] font-[600] text-[#666] transition-all',
              currentTab === 'domestic' && 'bg-white rounded-full text-[#222]',
            )}
          >
            국산
          </button>
          <button
            onClick={() => setCurrentTab('imported')}
            className={cn(
              'py-2.5 px-3 flex-auto h-[40px] inline-flex justify-center items-center text-[16px] leading-[16px] font-[600] text-[#666] transition-all',
              currentTab === 'imported' && 'bg-white rounded-full text-[#222]',
            )}
          >
            수입
          </button>
          {(orderPurchaseMode === 'lease' || orderPurchaseMode === 'rent') && (
            <button
              onClick={() => setCurrentTab('supercar')}
              className={cn(
                'py-2.5 px-3 flex-auto h-[40px] inline-flex justify-center items-center text-[16px] leading-[16px] font-[600] text-[#666] transition-all',
                currentTab === 'supercar' &&
                  'bg-white rounded-full text-[#222]',
              )}
            >
              슈퍼카
            </button>
          )}
        </div>
      </div>

      <Swiper
        ref={swiperRef}
        className='h-full w-full relative overflow-hidden'
        spaceBetween={10}
        slidesPerView={1}
        initialSlide={getTabIndex(currentTab)}
        onSlideChange={handleSlideChange}
      >
        <SwiperSlide>
          <div className='h-full overflow-y-scroll'>
            <div className='grid grid-cols-2 gap-[7px] px-5 py-[10px]'>
              {domesticBrand.map((brand) => (
                <BrandCard key={brand.id} brand={brand} form={form} />
              ))}
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className='h-full overflow-y-scroll'>
            <div className='grid grid-cols-2 gap-[7px] px-5 py-[10px]'>
              {importedBrand.map((brand) => (
                <BrandCard key={brand.id} brand={brand} form={form} />
              ))}
            </div>
          </div>
        </SwiperSlide>

        {(orderPurchaseMode === 'lease' || orderPurchaseMode === 'rent') && (
          <SwiperSlide>
            <div className='h-full overflow-y-scroll'>
              <div className='grid grid-cols-2 gap-[7px] px-5 py-[10px]'>
                {superCarBrand.map((brand) => (
                  <BrandCard key={brand.id} brand={brand} form={form} />
                ))}
              </div>
            </div>
          </SwiperSlide>
        )}
      </Swiper>
    </div>
  );
};

export default OrderPurchase_Brand;
