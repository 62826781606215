import { WholeSellingCar } from '@carsayo/types';
import { OrderPurchaseForm } from './form';

export const OrderPurchaseMode = ['newcar', 'lease', 'rent'] as const;
export type OrderPurchaseMode = (typeof OrderPurchaseMode)[number];
export const isValidOrderPurchaseMode = (
  value: any,
): value is OrderPurchaseMode => {
  return OrderPurchaseMode.includes(value);
};

export interface OrderPurchaseDefaultProps {
  form: OrderPurchaseForm;
  wholeSellingCar: WholeSellingCar[];
}

export const OrderPurchaseMethodList = {
  리스: {
    name: '리스',
    imgSrc: '/assets/images/v2/order/icon/lease_1.png',
  },
  장기렌트: {
    name: '장기렌트',
    imgSrc: '/assets/images/v2/order/icon/rent_1.png',
  },
  일시불: {
    name: '일시불',
    imgSrc: '/assets/images/v2/order/icon/cash_1.png',
  },
  할부: {
    name: '할부',
    imgSrc: '/assets/images/v2/order/icon/halboo_1.png',
  },
} as const;
export type OrderPurchaseMethodList =
  (typeof OrderPurchaseMethodList)[keyof typeof OrderPurchaseMethodList];

export type OrderPurchaseMethod = keyof typeof OrderPurchaseMethodList;
