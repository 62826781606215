export default function NoBid() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='50'
      height='50'
      viewBox='0 0 50 50'
      fill='none'
    >
      <path
        d='M44.0135 7.22461H5.96354C5.04307 7.22461 4.29688 7.9708 4.29688 8.89128V45.9746C4.29688 46.8951 5.04307 47.6413 5.96354 47.6413H44.0135C44.934 47.6413 45.6802 46.8951 45.6802 45.9746V8.89128C45.6802 7.9708 44.934 7.22461 44.0135 7.22461Z'
        fill='#CED5E2'
      />
      <path
        d='M6.90625 10.8737L6.90625 43.9987C6.90625 44.4589 7.27935 44.832 7.73958 44.832H42.2479C42.7082 44.832 43.0813 44.4589 43.0813 43.9987V10.8737C43.0813 10.4135 42.7082 10.0404 42.2479 10.0404H7.73958C7.27935 10.0404 6.90625 10.4135 6.90625 10.8737Z'
        fill='white'
      />
      <path
        d='M29.797 2H20.1803C19.2599 2 18.5137 2.74619 18.5137 3.66667V6.11667C18.5137 7.03714 19.2599 7.78334 20.1803 7.78334H29.797C30.7175 7.78334 31.4637 7.03714 31.4637 6.11667V3.66667C31.4637 2.74619 30.7175 2 29.797 2Z'
        fill='#8D9DB5'
      />
      <path
        d='M31.663 5.32422H18.313C17.0714 5.32422 16.0547 6.34088 16.0547 7.58255V10.3742C16.0547 10.6826 16.3047 10.9409 16.6214 10.9409H33.363C33.6714 10.9409 33.9297 10.6909 33.9297 10.3742V7.58255C33.9297 6.34088 32.913 5.32422 31.6714 5.32422H31.663Z'
        fill='#8D9DB5'
      />
      <path
        d='M32.6784 39.9258H9.59505C9.13481 39.9258 8.76172 40.2989 8.76172 40.7591V42.2341C8.76172 42.6944 9.13481 43.0675 9.59505 43.0675H32.6784C33.1386 43.0675 33.5117 42.6944 33.5117 42.2341V40.7591C33.5117 40.2989 33.1386 39.9258 32.6784 39.9258Z'
        fill='#CED5E2'
      />
      <path
        d='M40.371 39.9258H36.1126C35.6524 39.9258 35.2793 40.2989 35.2793 40.7591V42.2341C35.2793 42.6944 35.6524 43.0675 36.1126 43.0675H40.371C40.8312 43.0675 41.2043 42.6944 41.2043 42.2341V40.7591C41.2043 40.2989 40.8312 39.9258 40.371 39.9258Z'
        fill='#CED5E2'
      />
      <path
        d='M29.6115 5.87435H20.3698C20.1365 5.87435 19.9531 5.69102 19.9531 5.45768C19.9531 5.22435 20.1365 5.04102 20.3698 5.04102H29.6115C29.8448 5.04102 30.0281 5.22435 30.0281 5.45768C30.0281 5.69102 29.8448 5.87435 29.6115 5.87435Z'
        fill='#798CA8'
      />
      <path
        d='M15.9521 30.4414H14.6354C13.6459 30.4414 12.8438 31.2436 12.8438 32.2331V35.9581C12.8438 36.9476 13.6459 37.7498 14.6354 37.7498H15.9521C16.9416 37.7498 17.7437 36.9476 17.7437 35.9581V32.2331C17.7437 31.2436 16.9416 30.4414 15.9521 30.4414Z'
        fill='#333331'
      />
      <path
        d='M33.5263 30.4414H32.2096C31.2201 30.4414 30.418 31.2436 30.418 32.2331V35.9581C30.418 36.9476 31.2201 37.7498 32.2096 37.7498H33.5263C34.5158 37.7498 35.318 36.9476 35.318 35.9581V32.2331C35.318 31.2436 34.5158 30.4414 33.5263 30.4414Z'
        fill='#333331'
      />
      <path
        d='M36.8189 24.7909C37.6022 24.7909 38.2355 24.1576 38.2355 23.3743C38.2355 22.591 37.6022 21.9576 36.8189 21.9493H35.5605C35.0022 21.9493 34.5189 22.2743 34.2939 22.7493C34.2439 22.6576 34.1855 22.5659 34.1355 22.4743C33.7772 21.8659 33.1272 18.816 33.1272 18.816C33.1272 18.816 32.7605 14.8493 28.5272 14.5826C26.9689 14.4826 25.9439 14.466 24.0855 14.4743C22.2272 14.4576 21.2022 14.4743 19.6439 14.5826C15.4189 14.8493 15.0439 18.816 15.0439 18.816C15.0439 18.816 14.3855 21.8659 14.0355 22.4743C13.9855 22.5659 13.9272 22.6576 13.8772 22.7493C13.6439 22.2826 13.1689 21.9493 12.6105 21.9493H11.3522C10.5689 21.9493 9.93555 22.5826 9.93555 23.366C9.93555 24.1493 10.5689 24.7826 11.3522 24.7909H11.8355C10.9355 25.7576 10.9105 27.116 10.9105 27.116C10.9105 27.116 10.6105 28.6243 10.9105 31.5076C11.2272 34.4659 15.0022 34.2993 15.0022 34.2993H33.1689C33.1689 34.2993 36.9439 34.4659 37.2605 31.5076C37.5689 28.6159 37.2605 27.116 37.2605 27.116C37.2605 27.116 37.2355 25.7576 36.3355 24.7909H36.8105H36.8189Z'
        fill='#CED5E2'
      />
      <path
        d='M30.8104 20.3572C30.8104 20.3572 30.3021 17.0739 27.3854 16.9406C26.2271 16.8822 25.4604 16.8406 24.0771 16.8572C22.6938 16.8489 21.9271 16.8489 20.7688 16.9406C17.8188 17.1906 17.3438 20.3572 17.3438 20.3572C17.3438 20.3572 17.0104 21.8072 16.7604 22.8489C16.6688 23.2489 16.9938 23.2989 16.9938 23.2989C16.9938 23.2989 17.6021 23.3739 24.1604 23.3739C30.7188 23.3739 31.2021 23.2989 31.2021 23.2989C31.2021 23.2989 31.5021 23.2989 31.4021 22.8572C31.1938 21.9739 30.8188 20.3572 30.8188 20.3572H30.8104Z'
        fill='white'
      />
      <path
        d='M13.3421 30.4846C12.9671 30.4846 12.6255 30.2429 12.5255 29.8679L12.1838 28.6596C12.0588 28.2096 12.3171 27.7346 12.7671 27.6096C13.2171 27.4846 13.6921 27.7429 13.8171 28.1929L14.1588 29.4012C14.2838 29.8512 14.0255 30.3263 13.5755 30.4513C13.5005 30.4763 13.4171 30.4846 13.3421 30.4846Z'
        fill='white'
      />
      <path
        d='M34.8159 30.4836C34.7409 30.4836 34.6576 30.4753 34.5826 30.4503C34.1326 30.3253 33.8659 29.8503 33.9993 29.4003L34.3409 28.192C34.4659 27.742 34.9409 27.4753 35.3909 27.6086C35.8409 27.7336 36.1076 28.2086 35.9743 28.6586L35.6326 29.867C35.5243 30.242 35.1826 30.4836 34.8159 30.4836Z'
        fill='white'
      />
      <path
        d='M27.969 30.1328H20.194C19.6877 30.1328 19.2773 30.5432 19.2773 31.0495V31.7245C19.2773 32.2307 19.6877 32.6412 20.194 32.6412H27.969C28.4753 32.6412 28.8857 32.2307 28.8857 31.7245V31.0495C28.8857 30.5432 28.4753 30.1328 27.969 30.1328Z'
        fill='#BAC3D6'
      />
    </svg>
  );
}
