import { api } from 'apis';
import axios from 'axios';
import { splitFileNameAndExtension } from 'utils/textConvert';
import { blobToFile } from 'utils/convertImage';
import SentryErrorReturns from 'utils/sentryErrorReturns';
import {
  NiceapiGetIdentityClientKey,
  VerifyResponseDTO,
  VerifyTokenPhoneNumberDTO,
  IsLoginResponse,
  Member,
  MemberSignupCustomerDTO,
  SignupResult,
  MemberDealerLeaseRentSignupDTO,
  MemberDealerNewcarSignupDTO,
  MemberDealerUsedcarSignupDTO,
  IsMemberResponse,
  MemberLoginByIdentifyTokenDTO,
  PhoneAuthLoginConfirmDTO,
  GetFileUploadUrlDTO,
  GetFileUploadUrlResponseDTO,
  FileIdDTO,
  FileInfo,
  MemberPhoneNumberDTO,
  MemberDealerSignupForm,
  ReSubmitSignupFormDTO,
  IdentifyTokenPhoneNumber,
  MemberType,
} from '@carsayo/types';

export const niceKeyApi = async (returnUrl?: string) => {
  const calculateReturnUrl = () => {
    if (returnUrl) return `niceapi/identity/key?returnUrl=${returnUrl}`;
    return `niceapi/identity/key`;
  };
  try {
    const { data } =
      await api.get<NiceapiGetIdentityClientKey>(calculateReturnUrl());
    return data;
  } catch (error: any) {
    console.error(error);
    throw new Error(error);
  }
};

export const niceIdentity = async (id: string): Promise<VerifyResponseDTO> => {
  try {
    const response = await api.get<any>(
      `niceapi/identity/verify/token?identity_id=${id}`,
    );
    return response.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(error);
  }
};

export const niceTokenPhoneCheck = async (
  reqData: VerifyTokenPhoneNumberDTO,
): Promise<IdentifyTokenPhoneNumber> => {
  try {
    const response = await api.post<IdentifyTokenPhoneNumber>(
      `niceapi/identity/check/phoneNumber`,
      reqData,
    );
    return response.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(error);
  }
};

export const nicePopup = async () => {
  try {
    const response = await api.get<any>(
      `https://nice.checkplus.co.kr/CheckPlusSafeModel/service.cb`,
    );
    return response;
  } catch (error: any) {
    console.error(error);
    throw new Error(error);
  }
};

export const logoutApi = async () => {
  try {
    const response = await api.post<any>(`/member/logout`);
    return response;
  } catch (error: any) {
    console.error(error);
    throw new Error(error);
  }
};

export async function* niceAuthRoutine(): AsyncGenerator<any, any, unknown> {
  try {
    const data = await niceKeyApi();
    yield data;
    if (data?.id) {
      const niceIdentityData = await niceIdentity(data?.id);
      yield niceIdentityData;
    }
  } catch (error: any) {
    return;
  }
}

export const isLogin = async () => {
  try {
    const response = await api.get<IsLoginResponse>('/member/isLogin');
    return response;
  } catch (error: any) {
    if (error.response?.status === 403) {
      console.error('로그인 유저가 아닙니다', error);
    } else {
      console.error('로그인 상태 체크 중 오류입니다', error);
    }
    throw new Error(error);
  }
};

export const getMember = async (): Promise<Member> => {
  try {
    const response = await api.get<Member>('/member');
    return response.data;
  } catch (error: any) {
    // 정지 회원일 경우 밴 페이지로 갑니다
    if (error?.response?.data?.error === 'E-LG7KTEPSRS') {
      if (window.location.pathname === '/banned') throw new Error(error);
      else {
        window.location.replace('/banned');
        throw new Error(error);
      }
    }
    // 탈퇴 처리된 회원일 경우 로그아웃처리합니다.
    else if (error?.response?.data?.error === 'E-LG8KTEPSRS') {
      await logoutApi();
      window.location.replace('/main');
    }
    SentryErrorReturns(error.response);
    throw new Error(error);
  }
};

export const selfAuthenticationProcessingApi = async (
  identityVerifyToken: string,
) => {
  try {
    const { data } = await api.post('/member/customer/identify', {
      identityVerifyToken,
    });
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const SignupCustomer = async (reqData: MemberSignupCustomerDTO) => {
  try {
    const response = await api.post('/member/customer/signup', reqData);
    const data: SignupResult = response.data;
    return data;
  } catch (error: any) {
    console.error('사용자 회원가입 api 실행 중 에러: ', error);
    throw error;
  }
};

export const SignupLeaseRentDealer = async (
  reqData: MemberDealerLeaseRentSignupDTO,
) => {
  try {
    const response = await api.post('/member/dealer/leaseRent/signup', reqData);
    const data: SignupResult = response.data;
    return data;
  } catch (error: any) {
    console.error('사용자 회원가입 api 실행 중 에러: ', error);
    throw error;
  }
};

export const SignupNewCarDealer = async (
  reqData: MemberDealerNewcarSignupDTO,
) => {
  try {
    const response = await api.post('/member/dealer/newcar/signup', reqData);
    const data: SignupResult = response.data;
    return data;
  } catch (error: any) {
    console.error('사용자 회원가입 api 실행 중 에러: ', error);
    throw error;
  }
};

export const SignupUsedCarDealer = async (
  reqData: MemberDealerUsedcarSignupDTO,
) => {
  try {
    const response = await api.post('/member/dealer/usedcar/signup', reqData);
    const data: SignupResult = response.data;
    return data;
  } catch (error: any) {
    console.error('사용자 회원가입 api 실행 중 에러: ', error);
    throw error;
  }
};

/** 딜러 기존회원 존재여부 확인 */
export const checkDealerIdentify = async (
  identityVerifyToken: string,
): Promise<IsMemberResponse> => {
  try {
    const response = await api.get('/member/isExist/identity', {
      params: {
        identityVerifyToken,
      },
    });
    return response.data;
  } catch (error: any) {
    console.error('딜러 기존회원 여부 확인 api 실행 중 에러: ', error);
    throw new Error(error);
  }
};

/**  딜러 로그인(본인인증) */
export const loginDealerApi = async (
  memberLoginInfo: MemberLoginByIdentifyTokenDTO,
) => {
  try {
    const response = await api.post(
      '/member/signin/identityToken',
      memberLoginInfo,
    );
    return response.data;
  } catch (error: any) {
    if (error.response?.status === 404) {
      return error.response?.status;
    } else {
      console.error('딜러 본인인증 로그인 api 실행 중 에러: ', error);
      throw new Error(error);
    }
  }
};

export const checkPhone = async (number: string, type: MemberType) => {
  try {
    const response = await api.post('/member/signin/phone/start', {
      phoneNumber: number,
      type: type,
    });
    return response.data;
  } catch (error: any) {
    console.error('휴대폰번호 인증 api 실행 중 에러: ', error);
    throw new Error(error);
  }
};

/** 휴대전화 인증번호 확인 */
export const checkPhoneAuth = async (
  reqData: PhoneAuthLoginConfirmDTO,
): Promise<Member> => {
  try {
    const response = await api.post('/member/signin/phone/confirm', reqData);
    return response.data;
  } catch (error: any) {
    console.error('휴대폰번호 인증 api 실행 중 에러: ', error);
    throw new Error(error);
  }
};

/** S3로 파일 업로드 URL 가져오기 */
export const uploadFileToS3 = async (
  file: File,
  type: string,
): Promise<any> => {
  const fileName = splitFileNameAndExtension(file).fileNameOnly;
  const fileExtension = splitFileNameAndExtension(file).fileExtension;
  const fileType = type;

  try {
    const response = await api.get<GetFileUploadUrlDTO>('/file/uploadUrl', {
      params: {
        fileName: fileName,
        extension: fileExtension,
        isPublic: false,
        fileType: fileType,
      },
    });
    return response;
  } catch (error: any) {
    console.error(error);
    throw new Error(error);
  }
};
/** S3로 파일 업로드 */
export const saveUploadFileToS3 = async (
  url: string,
  file: File,
): Promise<any> => {
  try {
    await axios.put(url, file, {
      headers: {
        'Content-Type': file.type,
      },
    });
  } catch (error: any) {
    console.error(error);
    throw new Error(error);
  }
};

/** 이게 진짜임 */
export const uploadFileToS33 = async ({
  file,
  fileType,
  isPublic,
  fileName,
}: {
  file: File;
  fileType: string;
  isPublic?: boolean;
  fileName?: string;
}): Promise<GetFileUploadUrlResponseDTO> => {
  try {
    console.info('file', file);
    console.info(
      'splitFileNameAndExtension(file)',
      splitFileNameAndExtension(file),
    );
    const { data } = await api.get<GetFileUploadUrlResponseDTO>(
      '/file/uploadUrl',
      {
        params: {
          fileName: fileName
            ? fileName
            : splitFileNameAndExtension(file).fileNameOnly,
          extension: splitFileNameAndExtension(file).fileExtension,
          fileType,
          isPublic: isPublic ? isPublic : false,
        },
      },
    );
    console.info('file', file);
    console.info('url', data.presignedUrl);
    await saveFileToS33({ url: data.presignedUrl, file });

    return data;
  } catch (error: any) {
    console.error(error);
    throw new Error(error);
  }
};
/** 이게 진짜임 */
export const saveFileToS33 = async ({
  url,
  file,
}: {
  url: string;
  file: File;
}): Promise<void> => {
  try {
    const options = {
      headers: {
        'Content-Type': file.type,
      },
    };
    await axios.put(url, file, options);
  } catch (error: any) {
    console.error(error);
    throw new Error(error);
  }
};

export const fileUploadInit = async (
  reqData: GetFileUploadUrlDTO,
): Promise<GetFileUploadUrlResponseDTO> => {
  try {
    const response = await api.get<GetFileUploadUrlResponseDTO>(
      '/file/uploadUrl',
      {
        params: reqData,
      },
    );
    return response.data;
  } catch (error: any) {
    throw new Error(error);
  }
};

export const getFileInfo = async (reqData: FileIdDTO): Promise<FileInfo> => {
  try {
    const response = await api.get(`/file/info?fileId=${reqData.fileId}`);
    const data: FileInfo = response.data;
    return data;
  } catch (error: any) {
    throw new Error(error);
  }
};

/**
 * @case E-4-efkdha2: 존재하지 않는 파일
 * @case E-4-efkdha8: 이미 제거된 파일
 * @case E-4-efkdha4: Private한 파일을 불러오려고 하는데, 로그인 상태가 아닙니다
 * @case E-4-efkdha6: 해당 파일에 대한 접근 권한이 없습니다
 */
type GetFileError =
  | 'E-4-efkdha2'
  | 'E-4-efkdha8'
  | 'E-4-efkdha4'
  | 'E-4-efkdha6';

export const getS3FileApi = async ({
  fileId,
}: {
  fileId: string;
}): Promise<{ file: File; fileUrl: string }> => {
  try {
    const response = await api.get<Blob>(`/file?fileId=${fileId}`, {
      withCredentials: true,
      responseType: 'blob',
    });

    const fileName: string | undefined = response.headers['x-amz-meta-name'];
    let parsedFileName: string = fileId;
    try {
      if (fileName) parsedFileName = decodeURIComponent(escape(atob(fileName)));
    } catch (error: any) {
      console.error(
        'S3 내에 파일명이 제대로 정의되지 않았을 경우 간혹 발생할 수 있습니다.',
      );
      throw new Error(error);
    }
    const file = await blobToFile({
      blob: response.data,
      fileName: parsedFileName,
    });
    console.info('file', file);
    return { file, fileUrl: `/file?fileId=${fileId}` };
  } catch (e1: any) {
    try {
      const errorMessage: {
        error: GetFileError;
        message: string;
        statusCode: number;
      } = JSON.parse(await e1.response.data.text());
      throw errorMessage;
    } catch (e2) {
      throw 'E493281';
    }
  }
};

/**
 * @description 로그인 상태에서 해당 기기의 FCM_TOKEN을 등록하는 메소드입니다. FCM_TOKEN이 등록되어야 푸시 메시지를 받을 수 있습니다.
 * @description 해당 메소드 실행시 네이티브단에서 PostFcmToken([Token])을 실행하여 토큰을 서버로 전송하여 등록처리합니다.
 * @requires PostFcmToken
 */
export const setFcmToken = () => {
  // If Android App
  if (window?.android?.connectFCMWithLoginedMember) {
    window.native.postFcmToken = postFcmToken;
    window.android.connectFCMWithLoginedMember();
  }
  // iOS App
  else if (
    window?.webkit?.messageHandlers?.connectFCMWithLoginedMember?.postMessage
  ) {
    window.native.postFcmToken = postFcmToken;
    window.webkit.messageHandlers.connectFCMWithLoginedMember.postMessage('');
  }
  return;
};

/**
 * @param text Native단에서 입력하는 해당 기기의 FCM TOKEN입니다
 * @returns 처리 결과 메시지
 */
export const postFcmToken = async (text: string) => {
  if (text.length === 0) return;
  try {
    const response = await api.post('/firebase-message/setToken', {
      fcm_token: text,
    });
    return response;
  } catch (error: any) {
    console.error('FCM TOKEN 등록 실패: ', error);
    throw new Error(error);
  }
};

export const checkExistPhoneNumber = async (
  reqData: MemberPhoneNumberDTO,
): Promise<IsMemberResponse> => {
  try {
    const response = await api.get(
      `/member/isExist/phone?phoneNumber=${reqData.phoneNumber}`,
    );
    return response.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(error);
  }
};

export const getMyDealerSignupForm =
  async (): Promise<MemberDealerSignupForm> => {
    try {
      const response = await api.get(`/member/dealer/signupForm`);
      return response.data;
    } catch (error: any) {
      console.error(error);
      throw new Error(error);
    }
  };

export const reSubmitDealerSignupForm = async (
  reqData: ReSubmitSignupFormDTO,
): Promise<MemberDealerSignupForm> => {
  try {
    const response = await api.post(
      `/member/dealer/submit/signupForm`,
      reqData,
    );
    return response.data;
  } catch (error: any) {
    console.error(error);
    throw new Error(error);
  }
};
