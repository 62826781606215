import dayjs from 'dayjs';
import { AnimatePresence, motion } from 'framer-motion';
import { useMemo, useState } from 'react';
import { cn } from 'utils';
import RefundInfo from './RefundInfo';
import V3_Confirm from 'components_v3/dialog/Confirm';
import { TossPaymentMethodMap } from 'pages/v3/order/payment/api/tosspayment';
import { banks } from 'pages/v3/order/payment/constants';
import { TossPaymentListData } from './pay';

export default function PayCard({ item }: { item: TossPaymentListData }) {
  const virtualAccountInfo: {
    accountNumber: string;
    bankName: string;
    customerName: string;
    dueDate: Date;
  } | null = useMemo(() => {
    if (!item.virtual_account) {
      return null;
    }
    const virtualAccount = JSON.parse(item.virtual_account);
    return {
      accountNumber: virtualAccount.accountNumber,
      bankName:
        banks.find((bank) => bank.code === virtualAccount.bankCode)?.bank || '',
      customerName: virtualAccount.customerName,
      dueDate: virtualAccount.dueDate,
    };
  }, [item]);

  const isCancelable = useMemo(() => {
    return (
      (item.status === 'WAITING_FOR_DEPOSIT' || item.status === 'DONE') &&
      // 7일 이내
      dayjs().diff(dayjs(item.created_at), 'day') <= 7 &&
      // 결제에 사용하지 않았을 경우
      item.member_customer_avaliableOrder.every((order) => !order.used)
    );
  }, [item]);

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);
  const [isRefundInfoOpen, setIsRefundInfoOpen] = useState(false);

  const containerVariants = {
    hidden: { opacity: 0, x: -100 },
    show: {
      opacity: 1,
      x: 0,
      transition: {
        staggerChildren: 0.2,
      },
    },
  };

  const handleCancelPaid = () => {
    setIsRefundInfoOpen(true);
  };

  return (
    <div className='w-full py-6 px-[20px] bg-white rounded-[16px]'>
      <div className='pb-[20px] mb-[20px] border-b border-[#E5EAEE] flex justify-start items-center gap-4'>
        <div className='w-[60px] h-[60px] shrink-0 rounded-[6.7px] bg-[#F3F6F9] flex justify-center items-center'>
          <img
            src={'/assets/images/v2/carsayo_card.png'}
            alt='카사요 카드 이미지'
            className='w-[46px] object-contain'
          />
        </div>
        <div className='w-full flex flex-col justify-start items-start gap-[2px]'>
          <strong className='w-full flex justify-start items-center text-[15px] leading-[24px] font-[600]'>
            {item.purchase_item.title}
          </strong>
          <p className='w-full flex justify-start items-center text-[14px] leading-[22.4px] font-[500] text-[#555] break-keep'>
            {item.purchase_item.description}
          </p>
        </div>
      </div>
      <ul className='flex flex-col items-start justify-start gap-[14px] text-[15px] font-[400] leading-[15px]'>
        <li className='flex items-start justify-start'>
          <span className='min-w-[75px]'>결제금액</span>
          <strong className='font-[500]'>{`${item.amount.toLocaleString()}원`}</strong>
        </li>
        {/* {item.status === 'CANCELED' && (
          <li className='flex items-start justify-start'>
            <span className='min-w-[75px]'>취소일시</span>
            <strong className='font-[500]'>
              {dayjs(item._canceled_at).format('YYYY.MM.DD HH:mm')}
            </strong>
          </li>
        )} */}
        <li className='flex items-start justify-start'>
          <span className='min-w-[75px]'>결제일시</span>
          <strong className='font-[500]'>
            {dayjs(item.created_at).format('YYYY.MM.DD HH:mm')}
          </strong>
        </li>

        {item.method === 'VIRTUAL_ACCOUNT' && virtualAccountInfo ? (
          <li className='flex items-start justify-start'>
            <span className='min-w-[75px]'>결제방법</span>
            <div className='mt-[-3.5px] flex flex-col justify-start items-start gap-1 text-[15px] leading-[22.5px] font-[500]'>
              {item.status === 'DONE' ? (
                <span>가상계좌</span>
              ) : (
                <>
                  <span>가상계좌 : {virtualAccountInfo.bankName}</span>{' '}
                  {virtualAccountInfo?.accountNumber}
                  <span>
                    입금기한 :{' '}
                    {dayjs(virtualAccountInfo?.dueDate).format('YYYY.MM.DD')} (
                    {dayjs(virtualAccountInfo?.dueDate).diff(dayjs(), 'day') +
                      1}{' '}
                    일)
                  </span>
                  <span>예금주 : 카사요(주)</span>
                </>
              )}
            </div>
          </li>
        ) : (
          <li className='flex items-start justify-start'>
            <span className='min-w-[75px]'>결제방법</span>
            <strong className='font-[500]'>
              {TossPaymentMethodMap[item.method]}
            </strong>
          </li>
        )}
        <li className='flex items-start justify-start'>
          <span className='min-w-[75px]'>결제상태</span>
          <strong
            className={cn(
              'font-[600]',
              item.status === 'DONE'
                ? 'text-[#4860FF]'
                : item.status === 'WAITING_FOR_DEPOSIT'
                  ? 'text-[#CE8D00]'
                  : item.status === 'CANCELED'
                    ? 'text-[#FF3B3B]'
                    : 'text-[#222]',
            )}
          >
            {item.status === 'DONE'
              ? '결제완료'
              : item.status === 'WAITING_FOR_DEPOSIT'
                ? '입금 대기중'
                : item.status === 'CANCELED'
                  ? '결제취소'
                  : '결제실패'}
          </strong>
        </li>
      </ul>
      {/* 우선 히든 */}
      {isCancelable && (
        <div className='flex justify-end items-center mt-[20px]'>
          <button
            className='flex justify-center items-center text-[16px] leading-[16px] font-[500] h-[44px] py-[14px] px-4 rounded-[10px] border border-[#E5E5EC]'
            type='button'
            onClick={() => setIsConfirmOpen(true)}
          >
            결제 취소
          </button>
        </div>
      )}
      <AnimatePresence>
        {isRefundInfoOpen && (
          <motion.div
            key={'refundInfo'}
            variants={containerVariants}
            initial='hidden'
            animate='show'
            exit='hidden'
            className='fixed left-0 top-0 w-full z-[3]'
          >
            <RefundInfo setIsOpen={setIsRefundInfoOpen} item={item} />
          </motion.div>
        )}
      </AnimatePresence>

      <V3_Confirm
        onClose={() => {
          setIsConfirmOpen(!isConfirmOpen);
        }}
        onConfirm={() => {
          handleCancelPaid();
          setIsConfirmOpen(!isConfirmOpen);
        }}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
        disableBackdropClose={true}
        title={''}
        agreeText={'결제취소'}
        className='!pb-[30px] !pt-[10px]'
      >
        <div className='flex flex-col items-center justify-center gap-5'>
          <div className='flex flex-col items-center justify-center gap-1.5'>
            <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
              결제 취소
            </div>
            <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
              {`결제를 취소 하시겠습니까?
가상계좌의 경우 입력하신 환불계좌로
영업일 기준 3~5일 이내로 입금됩니다.`}
            </div>
          </div>
        </div>
      </V3_Confirm>
    </div>
  );
}
