import { useNavigate } from 'react-router-dom';

export default function MainNavSection() {
  const navigate = useNavigate();
  return (
    <div className='flex flex-col justify-start items-start gap-6 pb-[50px]'>
      <strong className='text-[22px] leading-[33px] font-[600]'>
        자동차 쇼핑 통합 플랫폼
      </strong>
      <div className='w-full grid grid-cols-2 gap-[11px]'>
        <div
          onClick={() => {
            if (process.env.REACT_APP_NODE_ENV !== 'development') {
              navigate('/purchase/brand?purchaseType=lease');
            } else navigate('/order/purchase?mode=lease');
          }}
          className='bg-gradient-to-t from-[#222222] to-[#333333] text-white py-6 px-4 relative min-h-[211px] rounded-[10px] overflow-hidden'
        >
          <div className='flex flex-col justify-start items-start gap-[10px]'>
            <strong className='text-[18px] leading-[18px] font-[600]'>
              리스
            </strong>
            <p className='text-[14px] leading-[21px] font-[400]'>
              26년간 금융 무사고
            </p>
          </div>
          <img
            src='/assets/images/png/main_navSection_icon01.png'
            alt=''
            className='w-[80px] object-contain absolute right-0 bottom-0'
          />
        </div>
        <div className='w-full flex flex-col justify-start items-start gap-[11px]'>
          <div
            onClick={() => {
              if (process.env.REACT_APP_NODE_ENV !== 'development') {
                navigate('/purchase/brand?purchaseType=rent');
              } else navigate('/order/purchase?mode=rent');
            }}
            className='w-full h-full bg-[#F3F6F9] rounded-[10px] py-6 px-4 relative min-h-[100px]'
          >
            <div>
              <strong className='text-[18px] leading-[18px] font-[600]'>
                장기렌트
              </strong>
            </div>
            <img
              src='/assets/images/png/main_navSection_icon02.png'
              alt=''
              className='w-[50px] object-contain absolute right-[10px] bottom-[6px]'
            />
          </div>
          <div
            onClick={() => {
              if (process.env.REACT_APP_NODE_ENV !== 'development') {
                navigate('/purchase/brand');
              } else navigate('/order/purchase?mode=newcar');
            }}
            className='w-full h-full bg-[#F3F6F9] rounded-[10px] py-6 px-4 relative min-h-[100px]'
          >
            <div>
              <strong className='text-[18px] leading-[18px] font-[600]'>
                신차 구매
              </strong>
            </div>
            <img
              src='/assets/images/png/main_navSection_icon03.png'
              alt=''
              className='w-[50px] object-contain absolute right-[10px] bottom-[6px]'
            />
          </div>
        </div>
        <div
          onClick={() => navigate('/order/selling')}
          // onClick={() => navigate('/selling/landing')}
          className='col-span-2 bg-[#F0F0FD] py-6 px-4 rounded-[10px] relative'
        >
          <div className='flex flex-col justify-start items-start gap-[7px]'>
            <strong className='text-[18px] leading-[18px] font-[600]'>
              내 차 팔기
            </strong>
            <p className='text-[14px] leading-[21px] font-[400] text-[#555555]'>
              지금 바로 확인하는 실시간 시세정보
            </p>
          </div>
          <img
            src='/assets/images/png/main_navSection_icon04.png'
            alt=''
            className='w-[50px] object-contain right-[10px] bottom-[10px] absolute'
          />
        </div>
      </div>
    </div>
  );
}
