/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useLayoutEffect } from 'react';
import useSystemStore from 'store/useSystemStore';
import { AnimatePresence, motion } from 'framer-motion';
import OrderPurchaseFinishIcon from './icon/finish';
import { Button } from 'components/ui/button';
import { X } from 'lucide-react';
import { useNavigate, useSearchParams } from 'react-router-dom';

// max-w-[425px]
const Notice = () => {
  return (
    <div className='mt-6 mb-5 p-4 bg-[#F3F6F9] rounded-[10px] max-w-[425px]'>
      <div className='text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
        안내사항
      </div>
      {/* Divider */}
      <div className='w-full h-[1px] bg-[#E5E5EC] my-4'></div>
      <ul className='list-disc pl-5'>
        <li className='text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
          선택 차량의 옵션은 상담 시 추가/변경 가능합니다.
        </li>
        <li className='text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
          실거래가는 제조사 및 딜러사의 정책에 따라 달라질 수 있다는 점 참고
          부탁드립니다.
        </li>
      </ul>
    </div>
  );
};

/** key: init으로 들어오지 않을 경우 메인페이지로 리다이렉트 */
const OrderPurchaseFinishPage = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const key: 'init' | string | null = searchParams.get('key');

  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();

  /** 상단, 하단 숨김 */
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'hidden',
      mode: 'subpage',
      title: '',
      rightElement: undefined,
      leftElement: 'back',
    });
    setIsBottomTabbar(false);
  }, []);

  useEffect(() => {
    if (key !== 'init') {
      return navigate('/main');
    } else {
      navigate('/order/purchase/finish', { replace: true });
    }
  }, []);

  return (
    <div className='h-full w-full flex flex-col relative justify-between'>
      {/* Header Part */}
      <div>
        <div className='w-full h-[60px] flex items-center justify-between bg-white sticky top-0 px-4'>
          <div className='w-[32px]'></div>
          <div className='text-[#222] text-center font-Pretendard text-[20px] font-semibold leading-[20px] tracking-[-0.4px]'></div>
          <button
            onClick={() => {
              navigate('/main');
            }}
          >
            <X className='stroke-[1.4px]' size={32} />
          </button>
        </div>
      </div>

      {/* Content Part */}
      <div className='relative flex flex-col flex-auto justify-start overflow-y-auto'>
        <AnimatePresence>
          <motion.div
            className='flex-auto bg-white flex flex-col'
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0, visibility: 'visible' }}
            transition={{ duration: 0.3 }}
          >
            <div className='flex flex-col items-center justify-center '>
              <div className='mt-[30px] mb-[26px]'>
                <OrderPurchaseFinishIcon />
              </div>
              <div className='text-[#222] text-center text-[22px] font-semibold leading-[33px] tracking-[-0.44px]'>
                견적 요청이 완료되었습니다.
              </div>
              <div className='mt-1.5 text-[#222] text-center text-[16px] font-medium leading-[25.6px] tracking-[-0.32px]'>
                견적함에서 견적서를 확인해 보세요.
              </div>

              <div className='px-5'>
                <Notice />
              </div>

              {/* max-w-[425px] */}
              <button
                className='px-5'
                onClick={() => {
                  navigate('/selling/landing');
                }}
              >
                <img
                  src={'/assets/images/v2/car/sell_car_banner_1.png'}
                  alt='차량 이미지'
                  className='w-full max-w-[425px]'
                />
              </button>
            </div>
          </motion.div>
        </AnimatePresence>
      </div>

      <div className='w-full bg-white px-5 pb-2.5 pt-5 flex items-center justify-center gap-[7px]'>
        <Button
          variant='outline'
          className='bg-white'
          onClick={() => {
            navigate('/main');
          }}
        >
          홈으로 이동
        </Button>
        <Button
          onClick={() => {
            navigate('/quote/customer');
          }}
        >
          견적함
        </Button>
      </div>
    </div>
  );
};

export default OrderPurchaseFinishPage;
