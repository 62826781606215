import { CarOption } from '@carsayo/types';

/**
 * 옵션 정렬 (카테고리별)
 *
 * 필수 조건을 최상단에 고정합니다.
 * 같은 함수를 앱과 관리자 클라이언트에서 공유하고 있습니다.
 * @update 250324 필수 카테고리 정렬 기준 추가
 */
export default function sortOption<T extends CarOption>(
  carOptionList: T[],
  essentialCategory: string[],
): Record<string, T[]> {
  const returnData: Record<string, T[]> = { '': [] };
  const essentialCategoryData: Record<string, T[]> = {};

  if (!carOptionList) return { '': [] };

  const essentialOptionList: T[] = [];

  for (let i = 0; i < carOptionList.length; i++) {
    const option = carOptionList[i];
    if (option.isEssential) {
      essentialOptionList.push(option);
      continue;
    }

    if (option.category && essentialCategory.includes(option.category)) {
      if (!essentialCategoryData[option.category]) {
        essentialCategoryData[option.category] = [];
      }
      essentialCategoryData[option.category].push(option);
      continue;
    }

    returnData[''].push(option);
  }

  return {
    ...{ essential: essentialOptionList },
    ...essentialCategoryData,
    ...returnData,
  };
}
