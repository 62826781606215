/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import { OrderPurchaseDefaultProps } from '../../../interface';
import { CircularProgress } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { CustomCheckbox } from 'components_v3/ui/Checkbox';
import { CarColor, CarOption } from '@carsayo/types';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { useQueryClient } from '@tanstack/react-query';
import sortColor from '../../../utils/sortColor';
import { SelectColorProps } from './type';
import SelectColorPart from './components/SelectColorPart';
import { Plus } from 'lucide-react';
import SelectInternalSelection from './components/SelectInternalSelection';
import { OrderPurchaseFormData } from '../../../form';
import {
  KEY_INTERIOR_CAR_DIRECTINPUT,
  KEY_INTERIOR_CAR_UNINPUT,
} from '../../../constants';
import { Controller } from 'react-hook-form';
import { cn } from 'utils';

interface OrderPurchaseModelProps extends OrderPurchaseDefaultProps {
  carColor: CarColor[];
  isFetching: boolean;
}

const ExteriorColorSelect = ({
  form,
  wholeSellingCar,
  selectedExteriorColor,
  selectedInteriorColor,
  sortedCarColor,
  cachedCarOptions,
}: SelectColorProps) => {
  return (
    <>
      <div className='space-y-4'>
        <div className='text-[#000] text-[16px] font-semibold leading-[16px] tracking-[-0.32px] mb-2'>
          외장 색상
        </div>

        <SelectColorPart
          form={form}
          mode='exterior'
          wholeSellingCar={wholeSellingCar}
          selectedExteriorColor={selectedExteriorColor}
          selectedInteriorColor={selectedInteriorColor}
          sortedCarColor={sortedCarColor}
          cachedCarOptions={cachedCarOptions}
        />
      </div>
    </>
  );
};

const InteriorColorSelect = ({
  form,
  wholeSellingCar,
  selectedExteriorColor,
  selectedInteriorColor,
  sortedCarColor,
  cachedCarOptions,
}: SelectColorProps) => {
  return (
    // 내장 색상 영역
    <div className='pt-2.5'>
      <div className='space-y-4'>
        <div className='text-[#000] text-[16px] font-semibold leading-[16px] tracking-[-0.32px] mb-2'>
          내장 색상
        </div>

        <div className='space-y-3'>
          <SelectColorPart
            form={form}
            mode='interior'
            wholeSellingCar={wholeSellingCar}
            selectedExteriorColor={selectedExteriorColor}
            selectedInteriorColor={selectedInteriorColor}
            sortedCarColor={sortedCarColor}
            cachedCarOptions={cachedCarOptions}
          />

          {/* 직접 입력 선택시에만 노출 */}
          {form.watch('interiorCarColor.id') ===
            KEY_INTERIOR_CAR_DIRECTINPUT && (
            <Controller
              control={form.control}
              name='interiorCarColor.name'
              render={({ field, fieldState, formState }) => (
                <div>
                  <input
                    {...field}
                    value={field.value ?? ''}
                    type='text'
                    placeholder='내장 색상을 입력해 주세요.'
                    className={cn(
                      'w-full flex gap-2 h-[50px] appearance-none border border-[#E5E5EC] rounded-[8px] items-center justify-start px-4 pr-[12px] text-[#222] font-medium text-[16px] leading-[16px] tracking-[-0.32px]',
                      fieldState.error &&
                        'border-[#FF4D4D] focus:border-[#FF4D4D] border-solid border',
                    )}
                  />
                </div>
              )}
            />
          )}
        </div>
      </div>

      {/* 내장 가니쉬 선택 파트 */}
      {selectedInteriorColor?.internalSelection &&
        selectedInteriorColor?.internalSelection.length > 0 && (
          <div className='mt-5 space-y-3'>
            {/* 내장 가니쉬 선택 말풍선 */}
            <div className='flex w-fit items-center gap-2 bg-[#EEF] rounded-[10px] p-2.5 rounded-bl-none'>
              <div className='rounded-full flex items-center justify-center bg-[#7273F9] w-5 h-5'>
                <Plus size={12} color='white' />
              </div>
              <span className='text-[#000] text-[14px] font-medium leading-[14px] tracking-[-0.28px]'>
                내장 가니쉬도 선택해 주세요
              </span>
            </div>
            <SelectInternalSelection
              form={form}
              wholeSellingCar={wholeSellingCar}
              selectedExteriorColor={selectedExteriorColor}
              selectedInteriorColor={selectedInteriorColor}
              sortedCarColor={sortedCarColor}
              cachedCarOptions={cachedCarOptions}
            />
          </div>
        )}
    </div>
  );
};

const OrderPurchase_Color = ({
  form,
  wholeSellingCar,
  carColor,
  isFetching,
}: OrderPurchaseModelProps) => {
  const queryClient = useQueryClient();
  const currentGradeId = form.getValues('carGradeId');

  if (!currentGradeId) return null;

  /** 나중에 선택하세요 선택 여부 */
  const [isChecked, setIsChecked] = useState(false);

  /** 현재 선택된 외장 색상 */
  const selectedColor = useMemo(() => {
    return carColor?.find((el) => el.id === form.watch('carColorId'));
  }, [carColor, carColor, form.watch('carColorId')]);

  /** 현재 선택된 내장 색상 */
  const selectedInteriorColor: CarColor | undefined = useMemo(() => {
    const targetInteriorColor = form.getValues('interiorCarColor');
    const targetColor = carColor?.find(
      (el) => el.id === targetInteriorColor?.id,
    );

    if (
      targetInteriorColor?.id === KEY_INTERIOR_CAR_DIRECTINPUT ||
      targetInteriorColor?.id === KEY_INTERIOR_CAR_UNINPUT
    ) {
      return {
        ...targetInteriorColor,
        name_short: '',
        category: null,
        image_url: '',
        is_interior: true,
        color_code_main: null,
        color_code_sub: null,
        warning_text: null,
        restrictions: null,
        internalSelection: null,
        isHidden: false,
      };
    }

    return targetColor;
  }, [carColor, form.watch('interiorCarColor')]);

  /** 현재 조회된 옵션 데이터 리스트 */
  const cachedCarOptions: CarOption[] | undefined = queryClient.getQueryData([
    'carOption',
    currentGradeId,
  ]);

  /** 제약 조건 변경 시 현재 선택되어있는 항목 체크
   * @note 제약조건 충돌시 선택항목 강제 선택 해제 및 알림 출력
   */
  useEffect(() => {
    const totalColorRestriction = [
      ...(selectedInteriorColor?.restrictions?.incompatible?.color ?? []),
      ...(selectedColor?.restrictions?.incompatible?.color ?? []),
    ];

    if (totalColorRestriction.length === 0) return;

    const selectInteriorColor = form.watch('interiorCarColor');
    const selectExteriorColor = carColor.find(
      (el) => el.id === form.watch('carColorId'),
    );

    if (selectInteriorColor) {
      if (totalColorRestriction.includes(selectInteriorColor.name)) {
        CarsayoToast.info(
          `[내장] ${selectInteriorColor.name} 색상이 선택 해제되었습니다.`,
        );
        return form.setValue('interiorCarColor', undefined);
      }
    }
    if (selectExteriorColor) {
      if (totalColorRestriction.includes(selectExteriorColor.name)) {
        CarsayoToast.info(
          `[외장] ${selectExteriorColor.name} 색상이 선택 해제되었습니다.`,
        );
        return form.setValue('carColorId', undefined);
      }
    }

    return;
  }, [selectedColor, selectedInteriorColor]);

  /** 색상 선택 시, "필수 옵션 선택" 조건이 걸려 있는 색상이 선택될 경우
   * @note 해당 옵션을 "필수 선택 옵션"으로 강제로 업데이트칩니다.
   */
  useEffect(() => {
    const totalOptionRestrictions_nessary = new Set<string>();
    const totalOptionRestrictions_incompatible = new Set<string>();

    const selectInteriorColor = form.getValues('interiorCarColor');
    const selectExteriorColor = carColor.find(
      (el) => el.id === form.getValues('carColorId'),
    );

    // totalOptionRestrictions_nessary, totalOptionRestrictions_incompatible에 필수 옵션과 호환 불가능한 옵션을 추가합니다.
    if (selectInteriorColor) {
      selectInteriorColor.restrictions?.nessary?.option?.forEach((el) => {
        totalOptionRestrictions_nessary.add(el);
      });

      selectInteriorColor.restrictions?.incompatible?.option?.forEach((el) => {
        totalOptionRestrictions_incompatible.add(el);
      });
    }

    if (selectExteriorColor) {
      selectExteriorColor.restrictions?.nessary?.option?.forEach((el) => {
        totalOptionRestrictions_nessary.add(el);
      });

      selectExteriorColor.restrictions?.incompatible?.option?.forEach((el) => {
        totalOptionRestrictions_incompatible.add(el);
      });
    }

    // 옵션 제약 조건이 없고, 옵션 데이터가 없을 경우 릴리즈
    if (
      totalOptionRestrictions_nessary.size === 0 &&
      totalOptionRestrictions_incompatible.size === 0
    ) {
      queryClient.setQueryData<
        (CarOption & {
          /** 초기 데이터 백업용 */
          origin?: CarOption;
        })[]
      >(['carOption', currentGradeId], (old) => {
        return old?.map((el) => {
          return el.origin ? el.origin : { ...el, origin: undefined };
        });
      });
      return;
    }

    queryClient.setQueryData<
      (CarOption & {
        /** 초기 데이터 백업용 */
        origin?: CarOption;
      })[]
    >(['carOption', currentGradeId], (old) => {
      return old?.map((el) => {
        const originData = el.origin ? el.origin : el;
        if (totalOptionRestrictions_incompatible.has(el.name)) {
          return { ...originData, isHidden: true, origin: originData };
        }

        if (totalOptionRestrictions_nessary.has(el.name)) {
          return { ...originData, isEssential: true, origin: originData };
        }
        return originData;
      });
    });
  }, [form.watch('interiorCarColor'), form.watch('carColorId')]);

  /** 정렬된 색상 목록 */
  const sortedCarColor: {
    exterior: Record<string, CarColor[]>;
    interior: Record<string, CarColor[]>;
  } = useMemo(() => {
    const exterior = sortColor(carColor.filter((el) => !el.is_interior));
    const interior = sortColor(carColor.filter((el) => el.is_interior));

    return { exterior, interior };
  }, [carColor]);

  /** 값 입력 시 상담 시 논의할게요 선택 해제 처리 */
  useEffect(() => {
    if (form.watch('carColorId') || form.watch('interiorCarColor')) {
      setIsChecked(false);
    }
  }, [form.watch('carColorId'), form.watch('interiorCarColor')]);

  return (
    <>
      {isFetching && (
        <div className='flex w-full h-full items-center justify-center'>
          <CircularProgress />
        </div>
      )}

      <div className='p-5 space-y-[30px] pb-0'>
        <div className='flex justify-between items-center'>
          <div className='text-[#222] text-[18px] font-semibold leading-[18px] tracking-[-0.36px]'>
            색상 선택
          </div>

          <CustomCheckbox
            value={''}
            checked={isChecked}
            onChange={(e) => {
              setIsChecked(e.target.checked);
              if (e.target.checked) {
                form.setValue('carColorId', undefined);
                form.setValue('interiorCarColor', undefined);
              }
            }}
            label='상담 시 논의할게요'
            className='!text-[14px]'
          />
        </div>

        <ExteriorColorSelect
          form={form}
          wholeSellingCar={wholeSellingCar}
          selectedExteriorColor={selectedColor}
          selectedInteriorColor={selectedInteriorColor}
          sortedCarColor={sortedCarColor}
          cachedCarOptions={cachedCarOptions}
        />

        <InteriorColorSelect
          form={form}
          wholeSellingCar={wholeSellingCar}
          selectedExteriorColor={selectedColor}
          selectedInteriorColor={selectedInteriorColor}
          sortedCarColor={sortedCarColor}
          cachedCarOptions={cachedCarOptions}
        />
      </div>

      {/* 안내 파트 */}
      <div className='px-5 mt-6'>
        <ul className='space-y-3 list-disc bg-[#F3F6F9] rounded-[10px] px-5 pl-8 py-4 text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px] break-keep'>
          <li>차량 선택 후에도 상담 시 변경가능하니 너무 고민하지 마세요.</li>
          <li>
            선택 색상에 대한 차량 이미지는 이해를 위한 참고용으로 제공되며, 실제
            차량과 외관 및 색상이 상이할 수 있습니다.
          </li>
        </ul>
      </div>
    </>
  );
};

export default OrderPurchase_Color;
