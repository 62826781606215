import { CustomerOrderDetail, DealerOrderDetail } from '@carsayo/types';
import {
  Drawer,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
  DrawerClose,
} from 'components/ui/drawer';
import { Button } from 'components/ui/button';
import combineCarName from 'pages/v3/order/purchase/utils/combineCarName';
import { X } from 'lucide-react';
import getPurchasePrice from 'utils/price/getPurchasePrice';

export default function CarPriceInfoDrawer({
  isExpectedOpen,
  setExpectedOpen,
  quoteDetail,
}: {
  isExpectedOpen: boolean;
  setExpectedOpen: (open: boolean) => void;
  quoteDetail: CustomerOrderDetail | DealerOrderDetail;
}) {
  return (
    <Drawer
      open={isExpectedOpen}
      onOpenChange={(open) => setExpectedOpen(open)}
    >
      <DrawerContent className='flex flex-col'>
        <DrawerHeader className='relative'>
          <DrawerTitle>차량 가격</DrawerTitle>
          <DrawerClose asChild>
            <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
              <X className='h-7 w-7' />
            </Button>
          </DrawerClose>
        </DrawerHeader>
        <div className='grow overflow-y-auto p-4'>
          <div className='mt-2 border-b py-4'>
            <div className='text-desc'>신청 모델</div>
            <div className='mt-2 space-y-2'>
              <div className='flex justify-between'>
                <div className='text-[##555555]'>
                  {quoteDetail?.purchase?.selectedCar.carModel?.name ?? ''}{' '}
                  {quoteDetail?.purchase?.selectedCar.carTrim?.name ?? ''}
                </div>
                <div className='text-[##555555]'></div>
              </div>
            </div>
          </div>
          <div className='mt-2 border-b py-4'>
            <div className='text-desc'>상세 등급</div>
            <div className='mt-2 space-y-2'>
              <div className='flex justify-between'>
                <div className='text-[##555555]'>
                  {combineCarName({
                    trimName:
                      quoteDetail?.purchase?.selectedCar.carTrim?.name ?? '',
                    gradeName:
                      quoteDetail?.purchase?.selectedCar.carGrade?.name ?? '',
                  })}
                </div>
                <div className='text-desc font-semibold'>
                  {quoteDetail?.purchase?.selectedCar.carGrade?.price
                    ? quoteDetail.purchase.selectedCar.carGrade.price.toLocaleString()
                    : 0}
                  원
                </div>
              </div>
            </div>
          </div>
          <div className='mt-2 border-b py-4'>
            <div className='text-desc'>색상</div>
            {quoteDetail?.purchase?.selectedCar.carColor ||
            quoteDetail?.purchase?.selectedCar.interiorCarColor ? (
              <div className='mt-2 space-y-2'>
                {quoteDetail?.purchase?.selectedCar.carColor && (
                  <div className='flex justify-between'>
                    <div className='text-[##555555]'>
                      {quoteDetail?.purchase?.selectedCar.carColor?.name}
                    </div>
                    {quoteDetail?.purchase?.selectedCar.carColor?.price &&
                    quoteDetail?.purchase?.selectedCar.carColor.price > 0 ? (
                      <div className='text-desc font-semibold'>
                        +{' '}
                        {quoteDetail?.purchase?.selectedCar.carColor?.price.toLocaleString()}
                        원
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )}
                {quoteDetail?.purchase?.selectedCar.interiorCarColor && (
                  <div className='flex justify-between'>
                    <div className='text-[##555555]'>
                      [내장]{' '}
                      {quoteDetail.purchase.selectedCar.interiorCarColor.name}
                    </div>
                    {quoteDetail.purchase.selectedCar.interiorCarColor.price &&
                    quoteDetail.purchase.selectedCar.interiorCarColor.price >
                      0 ? (
                      <div className='text-desc font-semibold'>
                        +{' '}
                        {quoteDetail.purchase.selectedCar.interiorCarColor.price.toLocaleString()}
                        원
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )}
                {quoteDetail?.purchase?.selectedCar.interiorCarColor
                  ?.internalSelection && (
                  <div className='flex justify-between'>
                    <div className='text-[##555555]'>
                      [내장가니쉬]{' '}
                      {
                        quoteDetail.purchase.selectedCar.interiorCarColor
                          .internalSelection.name
                      }
                    </div>
                    {quoteDetail.purchase.selectedCar.interiorCarColor
                      .internalSelection.price &&
                    quoteDetail.purchase.selectedCar.interiorCarColor
                      .internalSelection.price > 0 ? (
                      <div className='text-desc font-semibold'>
                        +{' '}
                        {quoteDetail.purchase.selectedCar.interiorCarColor.internalSelection.price.toLocaleString()}
                        원
                      </div>
                    ) : (
                      ''
                    )}
                  </div>
                )}
              </div>
            ) : (
              <div className='mt-2 text-center'>색상은 상담 시 논의합니다.</div>
            )}
          </div>
          <div className='mt-2 border-b py-4'>
            <div className='text-desc'>추가 옵션</div>
            {quoteDetail?.purchase?.selectedCar.carOptionList &&
            quoteDetail?.purchase?.selectedCar.carOptionList.length > 0 ? (
              <div className='mt-2 space-y-2'>
                {quoteDetail?.purchase?.selectedCar.carOptionList.map((el) => {
                  return (
                    <div key={el.id} className='flex justify-between gap-2'>
                      <div className='text-[##555555]'>{el.name}</div>
                      <div className='text-desc font-semibold flex-none'>
                        + {el.price.toLocaleString()}원
                      </div>
                    </div>
                  );
                })}
              </div>
            ) : (
              <div className='mt-2 text-center'>추가 옵션이 없습니다.</div>
            )}
          </div>
          <div className='flex items-center justify-between py-4'>
            <div className='text-primary'>총 예상 금액</div>
            {quoteDetail?.purchase?.selectedCar && (
              <div className='font-semibold text-primary'>
                {getPurchasePrice(
                  quoteDetail.purchase.selectedCar,
                ).toLocaleString()}
                원
              </div>
            )}
          </div>
          <div className='bg-[#F7F7FB] p-4 text-desc text-[#767676]'>
            · 선택 차량에 대한 옵션은 상담 시 추가/변경 가능합니다. <br />·
            실거래가는 제조사 및 딜러사의 정책에 따리 달라질 수 있다는 점 참고
            부탁드립니다.
          </div>
          <div className='mt-10 pb-6'>
            <Button
              onClick={() => {
                setExpectedOpen(false);
              }}
            >
              확인
            </Button>
          </div>
        </div>
      </DrawerContent>
    </Drawer>
  );
}
