import Box from '@mui/material/Box';
import CircularProgress from '@mui/material/CircularProgress';
import { useNavigate } from 'react-router-dom';
import useMemberStore from '../../../store/useMemberStore';
import { useEffect } from 'react';
import useDeviceStore from 'store/useDeviceStore';

/**
 * @deprecated
 * Nicepay 결제는 사용되지 않습니다.
 */
export default function Payment() {
  const navigate = useNavigate();

  const { loginMember } = useMemberStore();
  const { isIOS } = useDeviceStore();

  useEffect(() => {
    if (isIOS) {
      window.addEventListener('pageshow', (event) => {
        if (event.persisted) {
          // bfcache로 페이지가 복원되었을 때 실행해야하는 로직을 넣어준다.
          navigate(-1);
        } else {
          // persisted가 true가 아닌 경우는 정상적으로 페이지가 로드된 경우다.
          if (!loginMember) {
            navigate(-1);
          }
        }
      });
    }

    if (!loginMember) {
      navigate(-1);
    }
  }, []);

  return (
    <div className='w-screen h-screen flex flex-col text-center align-center justify-center'>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          marginBottom: '20px',
        }}
      >
        <CircularProgress />
      </Box>
    </div>
  );
}
