/* eslint-disable @typescript-eslint/no-unused-vars */
import { CustomerOrderDetail } from '@carsayo/types';
import dayjs from 'dayjs';
import { cn } from 'utils';
import HistoryItem from '../../../HistoryItem';
import { InfoItem } from '../../../InfoItem';

const NoData = () => {
  return (
    <div className='bg-white rounded-[16px] text-[#999999] font-[400] text-[14px] leading-[100%] h-[51px] text-center flex items-center justify-center'>
      보험 처리 내역이 없어요
    </div>
  );
};

const InsuranceHistTab = ({
  quoteDetail,
}: {
  quoteDetail: CustomerOrderDetail;
}) => {
  const accidentHist = quoteDetail.selling?.accidentHistory;
  return (
    <div className='flex flex-col w-full'>
      <div className='bg-[#E8ECF0] flex justify-between items-center py-4 px-5'>
        <div>조회일자</div>
        <div>{dayjs(accidentHist?.SEARCH_DATE).format('YYYY.MM.DD')}</div>
      </div>

      <div className='py-6 px-5 flex flex-col gap-[30px]'>
        <div className='flex flex-col gap-6'>
          <div className='flex justify-between items-center font-[600] leading-[100%]'>
            <div className='text-[18px]'>내 차 피해 보험 처리</div>
            <div
              className={cn(
                'text-[16px]',
                Number(accidentHist?.MY_COUNT ?? 0) > 0
                  ? 'text-[#FFAA00]'
                  : 'text-[#999999]',
              )}
            >
              {Number(accidentHist?.MY_COUNT ?? 0) > 0
                ? `${Number(accidentHist?.MY_COUNT ?? 0)}회`
                : '없음'}
            </div>
          </div>

          {accidentHist && Number(accidentHist.MY_COUNT) > 0 ? (
            <div className='flex flex-col gap-3'>
              {accidentHist.RESP_MY_DATA.map((my) => {
                return (
                  <HistoryItem
                    type='my'
                    key={`my_${my.MY_ACCIDENT_DATE}`}
                    data={my}
                  />
                );
              })}
            </div>
          ) : (
            <NoData />
          )}
        </div>

        <div className='flex flex-col gap-6'>
          <div className='flex justify-between items-center font-[600] leading-[100%]'>
            <div className='text-[18px]'>상대차 피해 보험 처리</div>
            <div
              className={cn(
                'text-[16px]',
                Number(accidentHist?.OTHER_COUNT ?? 0) > 0
                  ? 'text-[#FF4747]'
                  : 'text-[#999999]',
              )}
            >
              {Number(accidentHist?.OTHER_COUNT ?? 0) > 0
                ? `${Number(accidentHist?.OTHER_COUNT ?? 0)}회`
                : '없음'}
            </div>
          </div>

          {accidentHist && Number(accidentHist.OTHER_COUNT) > 0 ? (
            <div className='flex flex-col gap-3'>
              {accidentHist.RESP_OTHER_DATA.map((my) => {
                return (
                  <HistoryItem
                    type='other'
                    key={`other_${my.OTHER_ACCIDENT_DATE}`}
                    data={my}
                  />
                );
              })}
            </div>
          ) : (
            <NoData />
          )}
        </div>

        <div>
          <div className='font-[600] text-[18px] leading-[100%] mb-2'>
            특수 용도 이력
          </div>

          {accidentHist && (
            <div className='bg-white rounded-[10px] flex flex-col items-center mt-4 divide-y-[1px] divide-[#E5E5EC]'>
              <InfoItem
                title={'대여 이력 (렌터카)'}
                titleClassName='w-[140px]'
                desc={accidentHist?.RENT_CAR_HISTORY ?? ''}
                descClassName={
                  ['', '없음'].includes(accidentHist?.RENT_CAR_HISTORY ?? '')
                    ? '!text-[#999999]'
                    : ''
                }
              />
              <InfoItem
                title={'영업 이력 (택시, 화물 등)'}
                titleClassName='w-[140px]'
                desc={accidentHist?.BUSINESS_HISTORY ?? ''}
                descClassName={
                  ['', '없음'].includes(accidentHist?.BUSINESS_HISTORY ?? '')
                    ? '!text-[#999999]'
                    : ''
                }
              />
              <InfoItem
                title={'관용 이력'}
                titleClassName='w-[140px]'
                desc={accidentHist?.COMMON_USE_HISTORY ?? ''}
                descClassName={
                  ['', '없음'].includes(accidentHist?.COMMON_USE_HISTORY ?? '')
                    ? '!text-[#999999]'
                    : ''
                }
              />
            </div>
          )}
        </div>

        <div>
          <div className='font-[600] text-[18px] leading-[100%] mb-2'>
            소유자 변경 이력
          </div>

          {accidentHist && accidentHist.RESP_MY_CHANGE_DATA.length > 0 && (
            <div className='bg-white rounded-[10px] flex flex-col items-center mt-4 divide-y-[1px] divide-[#E5E5EC]'>
              {accidentHist.RESP_MY_CHANGE_DATA.map((chg) => {
                return (
                  <InfoItem
                    key={`chagne_${chg.MY_CHANGE_CHANGE_NAME}`}
                    title={chg.MY_CHANGE_CHANGE_NAME}
                    titleClassName='w-[140px]'
                    desc={dayjs(chg.MY_CHANGE_CHANGE_DATE).format('YYYY.MM.DD')}
                  />
                );
              })}
            </div>
          )}
        </div>

        <div>
          <div className='font-[600] text-[18px] leading-[100%] mb-2'>
            특수 사고 이력
          </div>

          {accidentHist && (
            <div className='bg-white rounded-[10px] flex flex-col items-center mt-4 divide-y-[1px] divide-[#E5E5EC]'>
              <InfoItem
                title={'전손 사고'}
                titleClassName='w-[140px]'
                desc={
                  Number(accidentHist.TOTAL_LOSS_HISTORY) > 0
                    ? `${Number(accidentHist.TOTAL_LOSS_HISTORY)}회`
                    : '없음'
                }
                descClassName={
                  Number(accidentHist.TOTAL_LOSS_HISTORY) > 0
                    ? ''
                    : '!text-[#999999]'
                }
              />
              <InfoItem
                title={'침수 사고 (전손)'}
                titleClassName='w-[140px]'
                desc={
                  Number(accidentHist.FLOODING_TOTAL_LOSS_HISTORY) > 0
                    ? `${Number(accidentHist.FLOODING_TOTAL_LOSS_HISTORY)}회`
                    : '없음'
                }
                descClassName={
                  Number(accidentHist.FLOODING_TOTAL_LOSS_HISTORY) > 0
                    ? ''
                    : '!text-[#999999]'
                }
              />
              <InfoItem
                title={'침수 사고 (분손)'}
                titleClassName='w-[140px]'
                desc={
                  Number(accidentHist.FLOODING_PARTIAL_LOSS_HISTORY) > 0
                    ? `${Number(accidentHist.FLOODING_PARTIAL_LOSS_HISTORY)}회`
                    : '없음'
                }
                descClassName={
                  Number(accidentHist.FLOODING_PARTIAL_LOSS_HISTORY) > 0
                    ? ''
                    : '!text-[#999999]'
                }
              />
              <InfoItem
                title={'도난 사고'}
                titleClassName='w-[140px]'
                desc={
                  Number(accidentHist.ROBBERY_HISTORY) > 0
                    ? `${Number(accidentHist.ROBBERY_HISTORY)}회`
                    : '없음'
                }
                descClassName={
                  Number(accidentHist.ROBBERY_HISTORY) > 0
                    ? ''
                    : '!text-[#999999]'
                }
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InsuranceHistTab;
