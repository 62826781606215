/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useLayoutEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';
import MainTab from './components/list/MainTab';
import { useQuery } from '@tanstack/react-query';
import { getCustomerQuoteListApi } from 'apis/quote';
import ListItem from './components/list/ListItem';
import NoData from 'components/common/v3/NoData';
import useOnResumeStore from 'store/useOnResumeStore';
import useQueryState from 'hooks/useQueryState';

const tabs = ['newCar', 'sellCar'];
const CustomerQuoteMain = () => {
  const [searchParams] = useSearchParams();
  const type = searchParams.get('type');

  const { setTopTabbarState, setIsBottomTabbar, setLoadingDim } =
    useSystemStore();
  const { setOnResume } = useOnResumeStore();

  const [currentType, setCurrentType] = useQueryState<string>(
    'type',
    type || tabs[0],
    'string',
  );

  const {
    data: quoteList,
    isPending,
    refetch,
  } = useQuery({
    queryKey: ['quoteList'],
    queryFn: getCustomerQuoteListApi,
    staleTime: 1000 * 60 * 10,
    gcTime: 1000 * 60 * 10,
  });

  const filteredQuoteList =
    quoteList?.filter((item) => {
      if (currentType === 'sellCar') {
        return item.type === 'sellCar';
      } else if (currentType === 'newCar') {
        return item.type !== 'sellCar';
      }
      return true;
    }) || [];

  useEffect(() => {
    setLoadingDim(isPending);
  }, [isPending]);

  useEffect(() => {
    refetch();
    // 푸시나 앱 새로고침 시 데이터 재조회
    setOnResume(async () => {
      refetch();
    });
  }, []);

  /** 상단, 하단 숨김 */
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'my',
      title: '견적함',
      rightElement: 'home',
    });
    setIsBottomTabbar(true);
  }, []);

  return (
    <div className='bg-[#F3F6F9] pb-[50px] min-h-[calc(100dvh-110px)] flex flex-col'>
      <MainTab currentType={currentType} setCurrentType={setCurrentType} />

      <div className='flex flex-col p-5 gap-5 pt-[70px] flex-1'>
        {filteredQuoteList.map((quote, index) => {
          return (
            <ListItem
              key={`${currentType}_${quote.id}`}
              quote={quote}
              refetch={refetch}
            />
          );
        })}
        {filteredQuoteList.length === 0 && (
          <div className='flex-1 flex flex-col items-center justify-center'>
            <NoData text='견적함에 데이터가 없어요' />
          </div>
        )}
      </div>
    </div>
  );
};

export default CustomerQuoteMain;
