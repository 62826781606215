// 쿼리스트링에 상태를 저장합니다.
// 앞으로가기 & 뒤로가기 시 상태를 복구합니다.

// hooks/useQueryState.ts
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

type Parser<T> = {
  parse: (value: string | null) => T;
  stringify: (value: T) => string;
};

// 기본 파서들
const parsers = {
  string: {
    parse: (v: string | null) => v ?? '',
    stringify: (v: string) => v,
  },
  number: {
    parse: (v: string | null) => (v !== null ? Number(v) : 0),
    stringify: (v: number) => String(v),
  },
  boolean: {
    parse: (v: string | null) => v === 'true',
    stringify: (v: boolean) => String(v),
  },
  json: {
    parse: (v: string | null) => {
      try {
        return v ? JSON.parse(v) : null;
      } catch {
        return null;
      }
    },
    stringify: (v: unknown) => JSON.stringify(v),
  },
} as const;

type ParserKey = keyof typeof parsers;

/**
 * useQueryState 훅
 * @param key 쿼리스트링 key
 * @param defaultValue 기본값
 * @param type 'string' | 'number' | 'boolean' | 'json'
 */
function useQueryState<T>(
  key: string,
  defaultValue: T,
  type: ParserKey = 'string',
): [T, (value: T) => void] {
  const location = useLocation();
  const navigate = useNavigate();

  const parser: Parser<T> = parsers[type] as Parser<T>;

  const getQueryValue = useCallback((): T => {
    const params = new URLSearchParams(location.search);
    const value = params.get(key);
    return value ? parser.parse(value) : defaultValue;
  }, [location.search, key, parser, defaultValue]);

  const [state, setState] = useState<T>(getQueryValue);

  useEffect(() => {
    setState(getQueryValue());
  }, [location.search, getQueryValue]);

  const updateQuery = (value: T) => {
    const params = new URLSearchParams(location.search);
    params.set(key, parser.stringify(value));
    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  };

  return [state, updateQuery];
}

export default useQueryState;
