/** 견적 요청권 가격 */
export const AVALIABLE_ORDER_PRICE = 10000;

export type Bank = {
  bank: string;
  officialCode: string;
  code: string;
  koreanCode: string;
  englishCode: string;
};

export const banks: Bank[] = [
  {
    bank: '경남은행',
    officialCode: '039',
    code: '39',
    koreanCode: '경남',
    englishCode: 'KYONGNAMBANK',
  },
  {
    bank: '광주은행',
    officialCode: '034',
    code: '34',
    koreanCode: '광주',
    englishCode: 'GWANGJUBANK',
  },
  {
    bank: '단위농협(지역농축협)',
    officialCode: '012',
    code: '12',
    koreanCode: '단위농협',
    englishCode: 'LOCALNONGHYEOP',
  },
  {
    bank: '부산은행',
    officialCode: '032',
    code: '32',
    koreanCode: '부산',
    englishCode: 'BUSANBANK',
  },
  {
    bank: '새마을금고',
    officialCode: '045',
    code: '45',
    koreanCode: '새마을',
    englishCode: 'SAEMAUL',
  },
  {
    bank: '산림조합',
    officialCode: '064',
    code: '64',
    koreanCode: '산림',
    englishCode: 'SANLIM',
  },
  {
    bank: '신한은행',
    officialCode: '088',
    code: '88',
    koreanCode: '신한',
    englishCode: 'SHINHAN',
  },
  {
    bank: '신협',
    officialCode: '048',
    code: '48',
    koreanCode: '신협',
    englishCode: 'SHINHYEOP',
  },
  {
    bank: '씨티은행',
    officialCode: '027',
    code: '27',
    koreanCode: '씨티',
    englishCode: 'CITI',
  },
  {
    bank: '우리은행',
    officialCode: '020',
    code: '20',
    koreanCode: '우리',
    englishCode: 'WOORI',
  },
  {
    bank: '우체국예금보험',
    officialCode: '071',
    code: '71',
    koreanCode: '우체국',
    englishCode: 'POST',
  },
  {
    bank: '저축은행중앙회',
    officialCode: '050',
    code: '50',
    koreanCode: '저축',
    englishCode: 'SAVINGBANK',
  },
  {
    bank: '전북은행',
    officialCode: '037',
    code: '37',
    koreanCode: '전북',
    englishCode: 'JEONBUKBANK',
  },
  {
    bank: '제주은행',
    officialCode: '035',
    code: '35',
    koreanCode: '제주',
    englishCode: 'JEJUBANK',
  },
  {
    bank: '카카오뱅크',
    officialCode: '090',
    code: '90',
    koreanCode: '카카오',
    englishCode: 'KAKAOBANK',
  },
  {
    bank: '케이뱅크',
    officialCode: '089',
    code: '89',
    koreanCode: '케이',
    englishCode: 'KBANK',
  },
  {
    bank: '토스뱅크',
    officialCode: '092',
    code: '92',
    koreanCode: '토스',
    englishCode: 'TOSSBANK',
  },
  {
    bank: '하나은행',
    officialCode: '081',
    code: '81',
    koreanCode: '하나',
    englishCode: 'HANA',
  },
  {
    bank: '홍콩상하이은행',
    officialCode: '054',
    code: '54',
    koreanCode: '-',
    englishCode: 'HSBC',
  },
  {
    bank: 'IBK기업은행',
    officialCode: '003',
    code: '03',
    koreanCode: '기업',
    englishCode: 'IBK',
  },
  {
    bank: 'KB국민은행',
    officialCode: '004',
    code: '06',
    koreanCode: '국민',
    englishCode: 'KOOKMIN',
  },
  {
    bank: 'DGB대구은행',
    officialCode: '031',
    code: '31',
    koreanCode: '대구',
    englishCode: 'DAEGUBANK',
  },
  {
    bank: 'KDB산업은행',
    officialCode: '002',
    code: '02',
    koreanCode: '산업',
    englishCode: 'KDBBANK',
  },
  {
    bank: 'NH농협은행',
    officialCode: '011',
    code: '11',
    koreanCode: '농협',
    englishCode: 'NONGHYEOP',
  },
  {
    bank: 'SC제일은행',
    officialCode: '023',
    code: '23',
    koreanCode: 'SC제일',
    englishCode: 'SC',
  },
  {
    bank: 'Sh수협은행',
    officialCode: '007',
    code: '07',
    koreanCode: '수협',
    englishCode: 'SUHYEOP',
  },
];
