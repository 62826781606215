import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteQuoteApi_V2, sendQuoteApi } from 'apis/quote';
import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import FileInput from 'components/common/v3/fileInput';
import V3_Confirm from 'components_v3/dialog/Confirm';
import V3_Button from 'components_v3/ui/Button';
import TextArea from 'components_v3/ui/TextArea';
import { X } from 'lucide-react';
import {
  DealerOrderBidMode,
  DealerOrderDetailSubpageProps,
} from 'pages/v3/orderDetailTemp/type';
import OrderDetailPriceInput from 'pages/v3/orderDetailTemp/ui/PriceInput';
import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';

const MESSAGE = {
  success: {
    create: '견적서가 정상적으로 고객님께 전달되었습니다.',
    update: '견적서가 정상적으로 수정되었습니다.',
  },
  error: {
    create: '잠시 후 다시 시도해주세요.',
    delete: '잠시 후 다시 시도해주세요.',
    EDFLKD1442: '고객님이 견적서를 확인한 이후에는 수정할 수 없습니다.',
    EDFOKD1443: '고객님이 견적서를 확인한 이후에는 삭제할 수 없습니다.',
  },
};

type DealerOrderBidSubpageProps = {
  mode: DealerOrderBidMode;
  setMode: (mode: DealerOrderBidMode) => void;
} & Omit<DealerOrderDetailSubpageProps, 'mode'>;

/**
 * 견적서 CREATE & UPDATE 컨텐츠
 * @note 내차팔기: 가격 0원 이상
 * @note 신차, 리스렌트: 전달사항 입력 or 견적서 파일 제출
 */
export default function DealerOrderBidSubpage_UPSERT({
  mode,
  setMode,
  isOpen,
  setIsOpen,
  orderDetail,
}: DealerOrderBidSubpageProps) {
  if (mode === 'view') return null;
  const queryClient = useQueryClient();

  const form = useForm({
    defaultValues: {
      content: orderDetail.bid?.content ?? '',
      estimateFileId: orderDetail.bid?.estimateFile?.id ?? null,
      purchasePrice: orderDetail.bid?.purchasePrice ?? 0,
    },
  });

  useEffect(() => {
    form.reset();
  }, [isOpen]);

  /** 유효성 검사 */
  const validity = useMemo(() => {
    const formValue = form.getValues();

    if (orderDetail.type === 'sellCar') {
      if (formValue.purchasePrice > 0) {
        return true;
      } else return false;
    }

    if (!formValue.content && !formValue.estimateFileId) return false;
    return true;
  }, [form.watch()]);

  const { mutate: upsertMutate, isPending: upsertPending } = useMutation({
    mutationFn: async () => {
      await sendQuoteApi({
        orderId: orderDetail.id,
        content: form.getValues('content') ? form.getValues('content') : '',
        estimateFileId: form.getValues('estimateFileId')
          ? form.getValues('estimateFileId')
          : null,
        purchasePrice: form.getValues('purchasePrice')
          ? form.getValues('purchasePrice')
          : undefined,
      });
    },
    onSuccess: async () => {
      if (mode === 'create') {
        CarsayoToast.success(MESSAGE.success.create);
      } else if (mode === 'update') {
        CarsayoToast.success(MESSAGE.success.update);
      }
      queryClient.invalidateQueries({
        queryKey: ['orderDetail', orderDetail.id],
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      goBack();
    },
    onError: async (error: any) => {
      const errorCode: string = error?.error ?? '';

      /** 고객이 이미 확인해서 수정이 불가능한 경우 */
      if (errorCode.includes('EDFLKD1442')) {
        CarsayoToast.error(MESSAGE.error.EDFLKD1442);
        queryClient.invalidateQueries({
          queryKey: ['orderDetail', orderDetail.id],
        });
        await new Promise((resolve) => setTimeout(resolve, 1000));
        goBack();
      } else CarsayoToast.error(MESSAGE.error.create);
    },
  });

  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  const { mutate: deleteMutate, isPending: deletePending } = useMutation({
    mutationFn: async () => {
      await deleteQuoteApi_V2({ orderId: orderDetail.id });
    },
    onSuccess: async () => {
      CarsayoToast.success('전달된 견적서가 삭제되었습니다.');
      queryClient.invalidateQueries({
        queryKey: ['orderDetail', orderDetail.id],
      });
      await new Promise((resolve) => setTimeout(resolve, 1000));
      goBack();
    },
    onError: (error: any) => {
      const errorCode: string = error?.error ?? '';

      /** 고객이 이미 확인해서 제거가 불가능한 경우 */
      if (errorCode.includes('EDFOKD1443')) {
        CarsayoToast.error(MESSAGE.error.EDFOKD1443);
      } else {
        CarsayoToast.error(MESSAGE.error.delete);
      }

      queryClient.invalidateQueries({
        queryKey: ['orderDetail', orderDetail.id],
      });
    },
  });

  const goBack = () => {
    if (mode === 'update') {
      setMode('view');
    } else {
      setIsOpen(false);
    }
  };

  return (
    <>
      {/* Header */}
      <div className='flex flex-none h-14 items-center justify-between bg-white px-5'>
        <div className='pl-4 grow text-center text-[#222] text-[20px] font-semibold leading-[20px] tracking-[-0.4px]'>
          {mode === 'create'
            ? '견적서 작성'
            : mode === 'update'
              ? '견적서 수정'
              : '견적서 내용 보기'}
        </div>
        <div
          className='cursor-pointer'
          onClick={() => {
            goBack();
          }}
        >
          <X className='w-8 h-8' strokeWidth={1.5} />
        </div>
      </div>

      <div className='flex flex-auto flex-col gap-[10px] justify-between overflow-y-scroll'>
        {/* Form영역 */}
        <div className='px-5 h-auto space-y-6'>
          <div className='py-[30px] text-[#222] text-[18px] font-medium leading-[28.8px] tracking-[-0.36px]'>
            작성한 내용은
            <br />
            바로 고객님께 전달됩니다.
          </div>

          {/* 중고차일 경우 예상 판매 가격 입력 */}
          {orderDetail.type === 'sellCar' && (
            <div className='mt-6'>
              <div className='space-y-3'>
                <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
                  예상 판매 가격
                </div>

                <div className='flex flex-col gap-3'>
                  <Controller
                    control={form.control}
                    name='purchasePrice'
                    rules={{ required: true }}
                    render={({ field, fieldState, formState }) => {
                      return (
                        <div className='flex-auto'>
                          <div
                            /** 에러 발생시 포커스 영역 처리 */
                            role='button'
                            ref={(ref) => {
                              if (fieldState.error && ref) {
                                ref.focus();
                              }
                            }}
                            tabIndex={0}
                          >
                            <OrderDetailPriceInput
                              disabled={field.value === null}
                              field={field}
                              setValue={(value) => {
                                form.setValue('purchasePrice', value, {
                                  shouldValidate: true,
                                });
                              }}
                              fieldState={fieldState}
                            />
                          </div>
                          {fieldState.error && fieldState.error.message && (
                            <div className='text-[12px] leading-[12px] p-1 font-[400] text-red-500'>
                              {fieldState.error.message}
                            </div>
                          )}
                        </div>
                      );
                    }}
                  />
                </div>
              </div>
            </div>
          )}

          <div className='space-y-3'>
            <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
              전달사항
            </div>

            {/* 전달사항 */}
            <Controller
              control={form.control}
              name='content'
              rules={{ required: true }}
              render={({ field, fieldState, formState }) => (
                <div
                  /** 에러 발생시 포커스 영역 처리 */
                  role='button'
                  ref={(ref) => {
                    if (fieldState.error && ref) {
                      ref.focus();
                    }
                  }}
                  tabIndex={0}
                >
                  <TextArea
                    className='w-full'
                    maxLength={1000}
                    minRows={8}
                    placeholder='내용을 입력해 주세요.'
                    value={field.value}
                    onChange={(e) => {
                      form.setValue(field.name, e.target.value);
                    }}
                  />
                </div>
              )}
            />
          </div>

          {orderDetail.type !== 'sellCar' && (
            <div className='space-y-3'>
              <div className='text-[#222] text-[15px] font-normal leading-[15px] tracking-[-0.3px]'>
                견적서
              </div>

              <Controller
                control={form.control}
                name='estimateFileId'
                rules={{ required: true }}
                render={({ field, fieldState, formState }) => (
                  <div>
                    <FileInput
                      accept='image/*'
                      isPrivate={true}
                      fileName='견적서 파일'
                      error={!!fieldState.error}
                      initialFileId={field.value ?? undefined}
                      onDelete={() => {
                        form.setValue(field.name, null, {
                          shouldValidate: true,
                        });
                      }}
                      onUpload={(fileInfo) => {
                        form.setValue(field.name, fileInfo.id, {
                          shouldValidate: true,
                        });
                      }}
                    />
                  </div>
                )}
              />
            </div>
          )}

          <div className='my-6 p-4 bg-[#F3F6F9] rounded-[10px] whitespace-pre-line'>
            <div className='text-[#222] text-[15px] font-medium leading-[22.5px] tracking-[-0.3px]'>
              확인해 주세요
            </div>
            {/* Divider */}
            <div className='w-full h-[1px] bg-[#E5E5EC] my-4'></div>
            <ul className='list-disc pl-5'>
              <li className='text-[#555] text-[14px] font-normal leading-[21px] tracking-[-0.28px]'>
                고객님께 제시한 견적 내용과 실제 내용이 다를 경우 불이익을 받을
                수 있습니다.
              </li>
            </ul>
          </div>
        </div>

        <div className='sticky bottom-0 bg-white p-5 flex flex-none gap-[7px]'>
          {mode === 'create' && (
            <V3_Button
              className='w-full'
              onClick={() => {
                goBack();
              }}
            >
              닫기
            </V3_Button>
          )}
          {mode === 'update' && (
            <V3_Button
              className='w-full'
              isLoading={deletePending}
              onClick={() => {
                setIsDeleteConfirmOpen(true);
              }}
            >
              삭제
            </V3_Button>
          )}
          <V3_Button
            className='w-full'
            theme='primary'
            disabled={!validity}
            isLoading={upsertPending}
            onClick={() => {
              upsertMutate();
            }}
          >
            {mode === 'create' && '견적서 전달'}
            {mode === 'update' && '수정'}
          </V3_Button>
        </div>

        <V3_Confirm
          isOpen={isDeleteConfirmOpen}
          setIsOpen={setIsDeleteConfirmOpen}
          onConfirm={() => {
            deleteMutate();
          }}
          title=''
          agreeText='삭제'
        >
          <div className='flex flex-col items-center justify-center gap-5'>
            <div className='flex flex-col items-center justify-center gap-1.5'>
              <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
                견적서 삭제
              </div>
              <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
                작성하신 견적서를 삭제하시겠습니까?
                <br />
                삭제 후 복구 불가능합니다.
              </div>
            </div>
          </div>
        </V3_Confirm>
      </div>
    </>
  );
}
