/* eslint-disable no-constant-condition */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { Button } from 'components/ui/button';
import {
  Drawer,
  DrawerClose,
  DrawerContent,
  DrawerHeader,
  DrawerTitle,
} from 'components/ui/drawer';
import { X } from 'lucide-react';
import { SelectColorProps } from '../type';
import { Fragment, useMemo } from 'react';
import { CarColor } from '@carsayo/types';
import ColorSquare from '../components/ColorSquare';
import { cn } from 'utils';
import {
  KEY_INTERIOR_CAR_DIRECTINPUT,
  KEY_INTERIOR_CAR_UNINPUT,
} from 'pages/v3/order/purchase/constants';
import NoColorSelectable from '../components/NoColorSelectable';

interface SelectColorDrawerProps extends SelectColorProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  mode: 'exterior' | 'interior';
}

export default function SelectColorDrawer({
  form,
  mode,
  wholeSellingCar,
  selectedExteriorColor,
  selectedInteriorColor,
  sortedCarColor,
  isOpen,
  setIsOpen,
}: SelectColorDrawerProps) {
  const colorList: Record<string, CarColor[]> = useMemo(() => {
    if (mode === 'exterior') {
      return sortedCarColor.exterior;
    }
    return sortedCarColor.interior;
  }, [sortedCarColor, mode]);

  /**
   * 선택 조건
   * 1. 현재 선택 색상의 제약조건
   * 2. 현재 선택 색상을 선택하지 못하게 하는 색상
   * 3. 현재 색상 목록 내에 같은 색상명이 있는 값
   */
  const targetRestrictions = useMemo(() => {
    const currentColor =
      mode === 'exterior' ? selectedInteriorColor : selectedExteriorColor;

    if (!currentColor) return [];

    // 현재 선택 색상의 제약조건
    const currentColorRestrictions =
      currentColor.restrictions?.incompatible?.color ?? [];

    // 현재 선택 색상을 선택하지 못하게 하는 색상
    const currentColorInCompatible: string[] = [];

    const targetColorList =
      mode === 'exterior'
        ? Object.values(sortedCarColor.exterior).flat()
        : Object.values(sortedCarColor.interior).flat();

    targetColorList.forEach((color) => {
      if (!color.restrictions?.incompatible?.color) {
        return;
      }

      if (color.restrictions?.incompatible?.color.includes(currentColor.name)) {
        currentColorInCompatible.push(color.name);
      }
    });

    const set = new Set([
      ...currentColorRestrictions,
      ...currentColorInCompatible,
    ]);

    // 현재 선택 색상 목록 내에 같은 색상명이 있는 값
    const filteredColorList = Array.from(set).filter((colorName) => {
      return targetColorList.some((color) => {
        return color.name === colorName;
      });
    });

    return filteredColorList;
  }, [selectedInteriorColor, selectedExteriorColor, mode]);

  const totalColorCount = useMemo(() => {
    let totalLength = 0;

    Object.keys(colorList).forEach((key) => {
      totalLength += colorList[key].length;
    });

    return totalLength;
  }, [colorList]);

  return (
    <Drawer
      open={isOpen}
      onOpenChange={(open) => {
        if (!open) setIsOpen(false);
      }}
    >
      {
        <DrawerContent className='mx-auto flex max-h-[90vh] w-full max-w-sm flex-col'>
          <DrawerHeader className='relative h-[60px]'>
            <DrawerTitle>
              {/* 외장 색상 선택 / 내장 색상 선택 */}
              {mode === 'exterior' ? '외장 색상' : '내장 색상'} 선택
            </DrawerTitle>
            <DrawerClose asChild>
              <Button className='absolute right-5 top-4 h-7 w-7 border-0 bg-transparent p-0 text-[#111111]'>
                <X className='h-7 w-7' />
              </Button>
            </DrawerClose>
          </DrawerHeader>

          <div className='px-5 pb-5 overflow-y-auto'>
            {/* 내장색상일 경우에만 직접입력 추가 */}
            {mode === 'interior' && (
              <div
                className={cn(
                  'flex gap-4 items-center justify-start',
                  'px-4 py-[15px] rounded-[8px]',
                  selectedInteriorColor?.id === KEY_INTERIOR_CAR_DIRECTINPUT
                    ? 'bg-[#F3F6F9]'
                    : 'bg-white',
                  'cursor-pointer',
                )}
                onClick={() => {
                  form.setValue('interiorCarColor', {
                    id: KEY_INTERIOR_CAR_DIRECTINPUT,
                    color_code_main: undefined,
                    color_code_sub: undefined,
                    category: undefined,
                    imageUrl: undefined,
                    name: '',
                    price: 0,
                    warning_text: undefined,
                    restrictions: null,
                    internalSelection: null,
                  });

                  setIsOpen(false);
                }}
              >
                <div className='flex-none w-10 h-10 flex items-center justify-center rounded-lg border border-[#E5E5EC] border-dashed'>
                  {/* 펜 아이콘 */}
                  <svg
                    xmlns='http://www.w3.org/2000/svg'
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                  >
                    <g clipPath='url(#clip0_3324_36360)'>
                      <path
                        d='M4 19.9998H8L18.5 9.49981C18.7626 9.23717 18.971 8.92537 19.1131 8.58221C19.2553 8.23905 19.3284 7.87125 19.3284 7.49981C19.3284 7.12838 19.2553 6.76058 19.1131 6.41742C18.971 6.07426 18.7626 5.76246 18.5 5.49981C18.2374 5.23717 17.9256 5.02883 17.5824 4.88669C17.2392 4.74455 16.8714 4.67139 16.5 4.67139C16.1286 4.67139 15.7608 4.74455 15.4176 4.88669C15.0744 5.02883 14.7626 5.23717 14.5 5.49981L4 15.9998V19.9998Z'
                        fill='#B2B7BB'
                        stroke='#B2B7BB'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                      <path
                        d='M12 5L18.5 11.5'
                        stroke='white'
                        strokeWidth='2'
                        strokeLinecap='round'
                        strokeLinejoin='round'
                      />
                    </g>
                    <defs>
                      <clipPath id='clip0_3324_36360'>
                        <rect width='24' height='24' fill='white' />
                      </clipPath>
                    </defs>
                  </svg>
                  {/* 펜 아이콘 */}
                </div>
                <div className='flex flex-col gap-1.5 h-[44px] items-start justify-center'>
                  <div className='text-[#222] text-[16px] font-semibold leading-[18px] tracking-[-0.32px] text-left'>
                    직접 입력
                  </div>
                </div>
              </div>
            )}

            {mode === 'exterior' && totalColorCount === 0 && (
              <NoColorSelectable />
            )}
            {Object.keys(colorList).map((el) => {
              return (
                <Fragment key={el}>
                  {/* 카테고리 구분선(''는 제외) */}
                  {!!el && (
                    <div className='pb-2.5 my-2.5 border-b border-[#E5E5EC] text-[#555] text-[13px] font-semibold leading-[19.5px] tracking-[-0.26px]'>
                      {el}
                    </div>
                  )}
                  {/* 카테고리 내 색상 목록 */}
                  {colorList[el].map((color) => {
                    const isSelected =
                      mode === 'exterior'
                        ? color.id === selectedExteriorColor?.id
                        : color.id === selectedInteriorColor?.id;

                    return (
                      <button
                        key={color.id}
                        disabled={targetRestrictions.some((restriction) => {
                          return restriction === color.name;
                        })}
                        className={cn(
                          'flex w-full gap-4 items-center justify-start',
                          'px-4 py-[15px] rounded-[8px]',
                          isSelected ? 'bg-[#F3F6F9]' : 'bg-white',
                          'disabled:opacity-50',
                        )}
                        onClick={() => {
                          if (mode === 'exterior') {
                            form.setValue('carColorId', color.id);
                          } else {
                            form.setValue('interiorCarColor', {
                              id: color.id,
                              name: color.name,
                              price: color.price,
                              restrictions: color.restrictions,
                              warning_text: color.warning_text || undefined,
                              internalSelection: color.internalSelection
                                ? color.internalSelection[0]
                                : null,
                              color_code_main:
                                color.color_code_main || undefined,
                              color_code_sub: color.color_code_sub || undefined,
                            });
                          }

                          setIsOpen(false);
                        }}
                      >
                        <ColorSquare color={color} />
                        <div className='flex flex-col gap-1.5 h-[44px] items-start justify-center'>
                          <div className='text-[#222] text-[16px] font-semibold leading-[18px] tracking-[-0.32px] text-left'>
                            {color.name}
                          </div>
                          {color.price !== 0 && (
                            <div className='text-[#222] text-[14px] font-medium leading-[14px] tracking-[-0.28px]'>
                              {`${color.price > 0 ? '+' : ''}${color.price.toLocaleString()}`}
                              원
                            </div>
                          )}
                        </div>
                      </button>
                    );
                  })}
                </Fragment>
              );
            })}
            {/* Category divider */}
            <div className='flex flex-col px-5 mt-2.5'></div>
          </div>
        </DrawerContent>
      }
    </Drawer>
  );
}
