/* eslint-disable @typescript-eslint/no-unused-vars */
import { useMutation, useQuery } from '@tanstack/react-query';
import {
  createAccidentReportReview,
  getMemberAccidentReport,
  getTotalMemberAccidentReport,
} from 'apis/accidentReport';
import { useLayoutEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import useSystemStore from 'store/useSystemStore';

import ConfirmV2 from 'components/common/v3/confirm';
import Star from 'components/svg/Star';
import V3_Button from 'components_v3/ui/Button';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';
import {
  AccidentReportDataReviewForm,
  AccidentReportDataReviewSchema,
} from './_forms/review';
import TextArea from 'components_v3/ui/TextArea';
import { toast } from 'sonner';
import { X } from 'lucide-react';
import { cn } from 'utils';
import { Pagination } from 'swiper/modules';
import { Swiper, SwiperClass, SwiperSlide } from 'swiper/react';
import ImageUploadBox from 'components/common/v3/fileUploadBox';
import { FileInfo } from '@carsayo/types';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

export default function AccidentReportReviewPage() {
  const [subImages, setSubImages] = useState<FileInfo[]>([]);
  const [subImagePage, setSubImagePage] = useState(1);
  const [isSubImagesPopup, setSubImagesPopup] = useState(false);

  const { id } = useParams();
  if (!id) return null;

  const navigate = useNavigate();

  const { data: targetAccidentReport, refetch } = useQuery({
    queryKey: ['accidentReport', id],
    queryFn: async () => {
      return await getMemberAccidentReport({
        id,
      });
    },
  });

  const form = useForm<AccidentReportDataReviewForm>({
    resolver: zodResolver(AccidentReportDataReviewSchema),
    defaultValues: {
      rating: 0,
      content: '',
    },
  });

  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      console.info('id ::: ', id);
      console.info('subImages ::: ', subImages);
      console.info('::: ', {
        id: id.toString(),
        rating: form.getValues('rating'),
        content: form.getValues('content'),
        file_1_id: subImages[0]?.id || undefined,
        file_2_id: subImages[1]?.id || undefined,
        file_3_id: subImages[2]?.id || undefined,
      });
      return await createAccidentReportReview({
        id: id.toString(),
        rating: form.getValues('rating'),
        content: form.getValues('content'),
        file_1_id: subImages[0]?.id || undefined,
        file_2_id: subImages[1]?.id || undefined,
        file_3_id: subImages[2]?.id || undefined,
      });
    },
    onSuccess: () => {
      setIsConfirmOpen(true);
    },
    onError: (e) => {
      console.info('e', e);
      toast.error('잠시 후 다시 시도해 주세요.');
    },
  });

  const [isConfirmOpen, setIsConfirmOpen] = useState(false);

  const { setTopTabbarState, setIsBottomTabbar } = useSystemStore();
  useLayoutEffect(() => {
    setTopTabbarState({
      state: 'fixed',
      mode: 'subpage',
      leftElement: 'back',
      title: '리뷰 작성',
    });
    setIsBottomTabbar(false);
  }, []);

  const { data: accidentReportList, isLoading } = useQuery({
    queryKey: ['accidentReportList'],
    queryFn: async () => {
      return await getTotalMemberAccidentReport();
    },
  });

  if (!targetAccidentReport) return null;

  const handleImageUpload = (item: FileInfo) => {
    setSubImages((prev) => [...prev, item]);
  };

  const handleRemoveImage = (index: number) => {
    setSubImages(subImages.filter((_, i) => i !== index));
  };

  const openPopupAtSlide = (index: number) => {
    setSubImagePage(index + 1);
    setSubImagesPopup(true);
  };

  console.info('target > ', targetAccidentReport);

  return (
    <div className='w-full mb-[10px]'>
      <div className='py-[20px] mb-[10px]'>
        <div className='px-[20px] flex justify-start items-stretch gap-[14px] pb-[30px] border-b-[8px] border-[#F3F6F9] mb-[50px]'>
          <div className='w-[74px] h-[74px] my-auto flex justify-center items-center rounded-[6px] overflow-hidden'>
            <img
              src={
                targetAccidentReport.info_repair_finish?.pictures?.[0] ??
                '/assets/images/v2/exampleImg.png'
              }
              alt='예시 이미지'
              className='w-full h-full object-cover'
            />
          </div>
          <div className='flex flex-col justify-between items-start gap-[12px] my-auto'>
            <div className='flex flex-col justify-start items-start gap-1'>
              <strong className='text-[15px] leading-[21px] font-[500]'>
                {targetAccidentReport.repairShop?.name}
              </strong>
              <p className='text-[13px] leading-[13px] font-[400] text-[#555]'>
                {targetAccidentReport.repairShop?.address.main
                  .split(']')[1]
                  .trim()}
              </p>
            </div>
            <strong className='text-[15px] leading-[15px] font-[600]'>
              {targetAccidentReport.info_repair_finish?.price.claimAmount.total.toLocaleString()}
              원
            </strong>
          </div>
        </div>
        <div className='px-[20px]'>
          <div className='flex flex-col justify-start items-center gap-6 mb-[40px]'>
            <p className='max-w-[205px] text-center break-keep text-[20px] leading-[30px] font-[600]'>
              자동차 수리부터 <br />
              출고 완료까지 어떠셨나요?
            </p>
            <div className='flex items-center justify-center gap-[2px]'>
              {[...Array(5)].map((_, index) => (
                <div
                  key={index}
                  onClick={async () => {
                    form.setValue('rating', index + 1, {
                      shouldValidate: true,
                    });
                  }}
                >
                  <Star
                    fill={index < form.watch('rating') ? '#FFC93E' : '#DDDDDD'}
                    width={'32'}
                    height={'32'}
                  />
                </div>
              ))}
            </div>
          </div>
          <div className='w-full flex flex-col justify-start items-start gap-[12px]'>
            <strong className='text-[15px] leading-[15px] font-[400]'>
              내용
            </strong>
            <TextArea
              className='w-full'
              maxLength={1000}
              minRows={10}
              {...form.register('content')}
              placeholder='10자 이상 내용을 입력해 주세요.'
            />
          </div>
          <div
            className={cn(
              'flex w-full items-center justify-between',
              subImages.length > 0
                ? 'mb-[9px] mt-[17px]'
                : 'mb-[16px] mt-[24px]',
            )}
          >
            <strong className=' inline-block text-[15px] leading-[15px] font-[400]'>
              추가 사진
            </strong>
            {subImages.length > 0 && (
              <button
                type='button'
                onClick={() => setSubImagesPopup(true)}
                className='rounded-[8px] bg-[#F3F6F9] border border-[#E5EAEE] p-2 text-[13px] font-[500] leading-[13px]'
              >
                전체보기
              </button>
            )}
          </div>
          <div className='w-full overflow-x-auto'>
            <div className='flex items-center justify-start gap-[6px]'>
              {subImages.length < 3 && (
                <ImageUploadBox
                  id='file_id'
                  onUpload={handleImageUpload}
                  type='dashed'
                  width={'107px'}
                  height={'107px'}
                  maxCount={3}
                  currentCount={subImages.length}
                />
              )}
              <ul className='flex items-center justify-start gap-[7px]'>
                {subImages &&
                  subImages.map((item, index) => (
                    <li
                      className='w-full h-full flex justify-center items-center'
                      key={`${item.name}_${index}`}
                    >
                      <div
                        className='relative h-[107px] w-[107px] overflow-hidden rounded-[6px]'
                        onClick={(e) => {
                          e.preventDefault();
                          openPopupAtSlide(index);
                        }}
                      >
                        <img
                          src={item.url}
                          alt={item.name}
                          className='h-full w-full object-cover'
                        />
                        <button
                          onClick={(e) => {
                            e.stopPropagation();
                            handleRemoveImage(index);
                          }}
                          className='absolute right-1 top-1 z-10 flex h-[20px] w-[20px] items-center justify-center rounded-full bg-[#000000CC]'
                        >
                          <X width={12} height={12} color='#fff' />
                        </button>
                      </div>
                    </li>
                  ))}
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className='mb-[50px] text-[13px] leading-[16.9px] font-[400] text-[#555555] bg-[#F3F6F9] flex justify-start items-center min-h-[45px] break-keep py-[14px] px-[20px]'>
        리뷰 작성 정책과 맞지 않는 글 작성 시 별도 고지 없이 제재됩니다.
      </div>
      <div className='px-5'>
        <V3_Button
          theme='primary'
          disabled={!form.formState.isValid}
          isLoading={isPending}
          onClick={() => mutate()}
          className='w-[calc(100%-40px)] rounded-[10px] mx-auto flex justify-center items-center h-[54px] p-[19px] text-[16px] leading-[16px] font-[500]'
        >
          등록하기
        </V3_Button>
      </div>
      <ConfirmV2
        title={'리뷰 작성 완료'}
        desc={'소중한 리뷰 작성 감사합니다.'}
        isOpen={isConfirmOpen}
        setIsOpen={setIsConfirmOpen}
        onConfirm={() => {
          navigate(-1);
        }}
      />
      {subImages && subImages.length > 0 && (
        <Dialog
          sx={{
            '& .MuiDialog-paper': {
              height: '100dvh',
              width: '100dvw',
              maxWidth: 'none',
              margin: 0,
              padding: 0,
            },
            '& .MuiPaper-root': {
              height: '100dvh',
              width: '100dvw',
              maxHeight: '100dvh',
            },
          }}
          open={isSubImagesPopup}
          onClose={() => {
            setSubImagesPopup(false);
            setSubImagePage(1);
          }}
          fullWidth
          maxWidth={'lg'}
          className='bg-[#111111]'
        >
          <DialogTitle className='!relative !flex !min-h-[56px] !items-center !justify-center !bg-[#111] !px-[48px] !py-[12px] !text-[16px] !font-[500] !leading-[16px] text-white'>
            <span>
              {subImagePage}/{subImages.length}
            </span>
            <button
              onClick={() => {
                setSubImagesPopup(false);
                setSubImagePage(1);
              }}
              className='!absolute !bottom-0 !right-4 !top-0 !my-auto !flex !h-8 !w-8 !items-center !justify-center'
            >
              <X width={28} height={28} color='#fff' strokeWidth='1.5' />
            </button>
          </DialogTitle>
          <DialogContent className='!flex !w-full scale-100 !items-center !justify-center bg-[#111111] !p-0'>
            <Swiper
              slidesPerView={1}
              className='w-full'
              spaceBetween={10}
              onSlideChange={(swiper: SwiperClass) => {
                setSubImagePage(Math.ceil(swiper.activeIndex + 1));
              }}
              modules={[Pagination]}
              centeredSlides={true}
              initialSlide={subImagePage - 1}
            >
              {subImages.map((item, index) => (
                <SwiperSlide key={`slide_${index}`} className='!h-auto w-full'>
                  <img
                    src={item.url}
                    alt={item.name}
                    className={cn('relative h-full w-full object-contain')}
                  />
                </SwiperSlide>
              ))}
            </Swiper>
          </DialogContent>
        </Dialog>
      )}
    </div>
  );
}
