import { CooconAccidentHistoryInfo } from '@carsayo/types';
import { ChevronLeft } from 'lucide-react';
import HistoryItem from './components/HistoryItem';
import dayjs from 'dayjs';
import { cn } from 'utils';
import SubPage from 'components/view/SubPage';

const UsedCarList = ({
  isShowList,
  setShowList,
  accidentHistory,
}: {
  isShowList: boolean;
  setShowList: (isShowList: boolean) => void;
  accidentHistory: CooconAccidentHistoryInfo;
}) => {
  return (
    <SubPage
      isOpen={isShowList}
      setIsOpen={(isOpen) => {
        setShowList(isOpen);
      }}
    >
      {/* absolute min-h-[100dvh] */}
      <div className='bg-white w-full overflow-hidden flex flex-col'>
        <header>
          <div className='w-full h-[60px] flex items-center justify-between bg-white/50 sticky top-0 px-4'>
            <button onClick={() => setShowList(false)}>
              <ChevronLeft className='stroke-[1.4px]' size={32} />
            </button>
            <div className='text-[#222] text-center font-Pretendard text-[20px] font-semibold leading-[20px] tracking-[-0.4px]'>
              보험 처리 내역
            </div>
            <div className='w-[32px]'></div>
          </div>
        </header>

        <div className='flex-1 flex flex-col overflow-y-auto gap-6 p-5'>
          <div className='border-b border-[#E5EAEE] pb-6'>
            <div className='bg-[#F6F6FC] rounded-[10px] px-4 py-[14px] font-[500] text-[15px] leading-[150%] text-[#555555]'>
              조회일자 :{' '}
              {dayjs(accidentHistory.SEARCH_DATE).format('YYYY.MM.DD')}
            </div>
          </div>

          <div className='border-b border-[#E5EAEE] pb-6 flex flex-col gap-6'>
            <div className='flex justify-between items-center font-[600] leading-[100%]'>
              <div className='text-[16px]'>내 차 피해 보험 처리</div>
              <div
                className={cn(
                  'text-[14px]',
                  Number(accidentHistory.MY_COUNT) > 0
                    ? 'text-[#FFAA00]'
                    : 'text-[#999999]',
                )}
              >
                {Number(accidentHistory.MY_COUNT) > 0
                  ? `${Number(accidentHistory.MY_COUNT)}회`
                  : '없음'}
              </div>
            </div>

            {Number(accidentHistory.MY_COUNT) > 0 && (
              <div className='flex flex-col gap-3'>
                {accidentHistory.RESP_MY_DATA.map((my) => {
                  return (
                    <HistoryItem
                      type='my'
                      key={`my_${my.MY_ACCIDENT_DATE}`}
                      data={my}
                    />
                  );
                })}
              </div>
            )}
          </div>

          <div className='border-b border-[#E5EAEE] pb-6 flex flex-col gap-6'>
            <div className='flex justify-between items-center font-[600] leading-[100%]'>
              <div className='text-[16px]'>상대차 가해 보험 처리</div>
              <div
                className={cn(
                  'text-[14px]',
                  Number(accidentHistory.OTHER_COUNT) > 0
                    ? 'text-[#FF4747]'
                    : 'text-[#999999]',
                )}
              >
                {Number(accidentHistory.OTHER_COUNT) > 0
                  ? `${Number(accidentHistory.OTHER_COUNT)}회`
                  : '없음'}
              </div>
            </div>

            {Number(accidentHistory.OTHER_COUNT) > 0 && (
              <div className='flex flex-col gap-3'>
                {accidentHistory.RESP_OTHER_DATA.map((other) => {
                  return (
                    <HistoryItem
                      type='other'
                      key={`other_${other.OTHER_ACCIDENT_DATE}`}
                      data={other}
                    />
                  );
                })}
              </div>
            )}
          </div>

          <div className='border-b border-[#E5EAEE] pb-6  flex flex-col gap-6'>
            <div className='text-[16px] font-[600] leading-[100%]'>
              특수 용도 이력
            </div>
            <div className='py-5 px-4 rounded-[10px] bg-[#F3F6F9] text-[#555555] text-[14px] leading-[150%] flex flex-col gap-[10px]'>
              <div className='flex items-center justify-between'>
                <div>대여 이력 (렌터카)</div>
                <div className='flex gap-1'>
                  {accidentHistory.RENT_CAR_HISTORY}
                </div>
              </div>
              <div className='flex items-center justify-between'>
                <div>영업 이력 (택시, 화물 등)</div>
                <div className='flex gap-1'>
                  {accidentHistory.BUSINESS_HISTORY}
                </div>
              </div>
              <div className='flex items-center justify-between'>
                <div>관용 이력</div>
                <div className='flex gap-1'>
                  {accidentHistory.COMMON_USE_HISTORY}
                </div>
              </div>
            </div>
          </div>

          <div className='border-b border-[#E5EAEE] pb-6  flex flex-col gap-6'>
            <div className='text-[16px] font-[600] leading-[100%]'>
              소유자 변경 이력
            </div>
            {accidentHistory.RESP_MY_CHANGE_DATA.length > 0 && (
              <div className='flex flex-col gap-3'>
                {accidentHistory.RESP_MY_CHANGE_DATA.map((chg) => {
                  return (
                    <div
                      key={`chagne_${chg.MY_CHANGE_CHANGE_NAME}`}
                      className='py-5 px-4 rounded-[10px] bg-[#F3F6F9] text-[#555555] flex items-center justify-between'
                    >
                      <div>{chg.MY_CHANGE_CHANGE_NAME}</div>
                      <div className='flex gap-1'>
                        {dayjs(chg.MY_CHANGE_CHANGE_DATE).format('YYYY.MM.DD')}
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>

          <div className='pb-6  flex flex-col gap-6'>
            <div className='text-[16px] font-[600] leading-[100%]'>
              특수 사고 이력
            </div>
            <div className='py-5 px-4 rounded-[10px] bg-[#F3F6F9] text-[#555555] text-[14px] leading-[150%] flex flex-col gap-[10px]'>
              <div className='flex items-center justify-between'>
                <div>전손 사고</div>
                <div className='flex gap-1'>
                  {' '}
                  {Number(accidentHistory.TOTAL_LOSS_HISTORY) > 0
                    ? `${Number(accidentHistory.TOTAL_LOSS_HISTORY)}회`
                    : '없음'}
                </div>
              </div>
              <div className='flex items-center justify-between'>
                <div>침수 사고 (전손)</div>
                <div className='flex gap-1'>
                  {Number(accidentHistory.FLOODING_TOTAL_LOSS_HISTORY) > 0
                    ? `${Number(accidentHistory.FLOODING_TOTAL_LOSS_HISTORY)}회`
                    : '없음'}
                </div>
              </div>
              <div className='flex items-center justify-between'>
                <div>침수 사고 (분손)</div>
                <div className='flex gap-1'>
                  {Number(accidentHistory.FLOODING_PARTIAL_LOSS_HISTORY) > 0
                    ? `${Number(accidentHistory.FLOODING_PARTIAL_LOSS_HISTORY)}회`
                    : '없음'}
                </div>
              </div>
              <div className='flex items-center justify-between'>
                <div>도난 사고</div>
                <div className='flex gap-1'>
                  {Number(accidentHistory.ROBBERY_HISTORY) > 0
                    ? `${Number(accidentHistory.ROBBERY_HISTORY)}회`
                    : '없음'}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </SubPage>
  );
};

export default UsedCarList;
