import { cn } from 'utils';

const CarInfoItem = ({
  img,
  typename,
  trim,
  rank,
  type,
}: {
  img?: string;
  typename?: string;
  trim?: string;
  rank?: string;
  type?: string;
}) => {
  return (
    <div className={cn('flex gap-4')}>
      <div
        className={cn(
          'bg-[#F3F6F9] rounded-[10px] flex items-center justify-center overflow-hidden min-w-[120px] h-[90px]',
        )}
      >
        <img
          src={img || '/assets/images/import/Carsayo.svg'}
          alt='car'
          width={type === 'sellCar' ? 120 : 90}
          height={type === 'sellCar' ? 90 : 54}
          className='object-contain'
        />
      </div>
      <div className='flex flex-col gap-[2px] justify-center flex-1 overflow-hidden'>
        <div className='text-[#555555] font-[500] text-[14px] leading-[160%]'>
          {typename}
        </div>
        <div className='font-[600] text-[16px] leading-[160%] overflow-hidden text-ellipsis whitespace-nowrap'>
          {trim}
        </div>
        <div className='text-[#555555] font-[500] text-[13px] leading-[160%]'>
          {rank}
        </div>
      </div>
    </div>
  );
};

export default CarInfoItem;
