import React from 'react';
import { motion } from 'framer-motion';

interface ActionsType {
  label?: string;
  icon: React.ReactNode;
  clickAction?: () => void;
}

interface FloatingNavInterface {
  isOpen: boolean;
  onAction: () => void;
  actions: ActionsType[];
}

const FloatingNav: React.FC<FloatingNavInterface> = ({
  isOpen,
  onAction,
  actions,
}) => {
  return (
    <div className='fixed bottom-[18vh] right-0'>
      {isOpen && (
        <motion.div
          className={`fixed w-[calc(100%+1px)] inset-0 bg-[#11111199]  ${isOpen ? 'z-50 pointer-events-auto' : 'z-[-999] pointer-events-none'}`}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          onClick={onAction}
        />
      )}
      <motion.div
        initial={false}
        animate={isOpen ? 'open' : 'closed'}
        className={`flex flex-col gap-[20px] ${isOpen ? 'z-50 pointer-events-auto' : 'z-[-999] pointer-events-none'} items-center absolute bottom-[15px] right-[15px]`}
      >
        {actions.map((action, index) => (
          <motion.button
            key={index}
            className={`bg-white min-w-[113px] py-[12px] px-[16px] text-[14px] text-[#7273F9] rounded-full flex items-center space-x-2 `}
            whileHover={{ scale: 1.1 }}
            variants={{
              open: {
                opacity: 1,
                y: 0,
                zIndex: 50,
                transition: { delay: index * 0.1 },
              },
              closed: { opacity: 0, y: 20, zIndex: -999 },
            }}
            onClick={action.clickAction ? action.clickAction : () => {}}
          >
            {action.icon}
            {action.label && <span>{action.label}</span>}
          </motion.button>
        ))}
      </motion.div>
      <motion.button
        className='p-3 rounded-full text-white custom-mix-shadow !z-[50] bg-[#2196F3] w-[56px] h-[56px] flex justify-center items-center absolute right-[10px]'
        // style={{
        //   background: 'linear-gradient(135deg, #3b82f6, #6366f1)',
        // }}
        onClick={onAction}
        whileHover={{ scale: 1.1 }}
      >
        <img src={'/assets/images/import/add.svg'} alt='더하기아이콘' />
      </motion.button>
    </div>
  );
};

export default FloatingNav;
