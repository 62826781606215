/* eslint-disable @typescript-eslint/no-unused-vars */
import { Member } from '@carsayo/types';
import { AlignJustify, ChevronLeft } from 'lucide-react';
import { Link, useNavigate } from 'react-router-dom';
import useMemberStore from 'store/useMemberStore';
import useSystemStore, { TopTabberState } from 'store/useSystemStore';
import { cn } from 'utils';

const AlertIcon = ({ loginMember }: { loginMember: Member | null }) => {
  const navigate = useNavigate();

  return (
    <div
      className='relative cursor-pointer'
      onClick={() => {
        if (loginMember) {
          navigate('/mypage/notification');
        } else {
          navigate('/auth/signup', { replace: true });
        }
      }}
    >
      {loginMember && loginMember.count.notification.unreadCount > 0 && (
        <span className='absolute top-[2px] right-[2px] flex w-1 h-1'>
          <span className='animate-ping bg-[#7273F9] absolute inline-flex w-full h-full rounded-full opacity-75'></span>
          <span className='bg-[#7273F9] relative inline-flex w-1 h-1 rounded-full'></span>
        </span>
      )}
      <img src={'/assets/images/import/noti.svg'} />
    </div>
  );
};

function LeftIcon({ topTabberState }: { topTabberState: TopTabberState }) {
  const navigate = useNavigate();

  return (
    <div className='min-w-5'>
      {topTabberState.leftElement === 'back' ? (
        <a onClick={() => navigate(-1)}>
          <ChevronLeft
            width={32}
            height={32}
            strokeWidth={1.5}
            className='w-[32px] object-contain'
          />
        </a>
      ) : (
        topTabberState.leftElement
      )}
    </div>
  );
}

function RightIcon({ topTabberState }: { topTabberState: TopTabberState }) {
  return (
    <div className='min-w-5'>
      {topTabberState.rightElement === 'home' ? (
        <Link to='/main'>
          <img src={'/assets/images/import/home.svg'} alt='carsayoIcon' />
        </Link>
      ) : (
        topTabberState.rightElement
      )}
    </div>
  );
}

export default function TopTabbarContents() {
  const { topTabberState, isSideMenu, setIsSideMenu } = useSystemStore();
  const { loginMember } = useMemberStore();
  const navigate = useNavigate();

  return {
    main: () => {
      return (
        <div
          className={cn(
            'flex h-[60px] items-center justify-between px-5',
            isSideMenu && 'hidden',
            topTabberState.state === 'hidden' && 'hidden',
          )}
        >
          <Link to='/main'>
            <img src={'/assets/images/v2/carsayo.png'} className='w-[96px]' />
          </Link>
          <AlertIcon loginMember={loginMember} />
        </div>
      );
    },
    my: () => {
      return (
        <div
          className={cn(
            'flex h-[60px] items-center justify-between px-5',
            isSideMenu && 'hidden',
            topTabberState.state === 'hidden' && 'hidden',
          )}
        >
          {topTabberState.title && (
            <div className='text-[24px] leading-[24px] font-[600]'>
              {topTabberState.title}
            </div>
          )}

          <div className='flex items-center gap-1'>
            <AlertIcon loginMember={loginMember} />
          </div>
        </div>
      );
    },
    mainType_2: () => {
      return (
        <div
          className={cn(
            'flex h-[60px] items-center justify-between px-5',
            topTabberState.state === 'hidden' && 'hidden',
          )}
        >
          <div className='flex items-center gap-3'>
            <AlignJustify
              className='w-6 h-6 cursor-pointer'
              onClick={() => {
                navigate({ search: 'subpage' });
                setIsSideMenu(!isSideMenu);
              }}
            />
            <img src={'/assets/images/v2/carsayo.png'} className='w-[96px]' />
          </div>
          <AlertIcon loginMember={loginMember} />
        </div>
      );
    },
    subpage: () => {
      return (
        <div
          className={cn(
            'flex h-[60px] items-center justify-between px-5',
            isSideMenu && 'hidden',
            topTabberState.state === 'hidden' && 'hidden',
          )}
        >
          <LeftIcon topTabberState={topTabberState} />
          {topTabberState.title && (
            <div className='text-[20px] leading-[20px] font-[600]'>
              {topTabberState.title}
            </div>
          )}
          <RightIcon topTabberState={topTabberState} />
        </div>
      );
    },
  };
}
