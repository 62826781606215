import { SellingOrderDTO } from '@carsayo/types';
import SelectV3, { SelectV3Item } from 'components/common/v3/selectv3';
import { Textarea } from 'components/ui/textarea';
import { ChevronRight } from 'lucide-react';
import { OrderSellingForm } from '../../../form';
import { useEffect, useState } from 'react';

const OrderSelling_Status = ({
  sellingOrderData,
  form,
  setShowList,
}: {
  sellingOrderData: SellingOrderDTO;
  form: OrderSellingForm;
  setShowList: (isShowList: boolean) => void;
}) => {
  const [text_정비수리, setText_정비수리] = useState(
    form.getValues('additionalInfo.carState.정비수리필요.additional') ?? '',
  );
  useEffect(() => {
    form.setValue('additionalInfo.carState.차량장단점.selected', false);
  }, []);
  return (
    <div className='flex flex-col gap-[30px]'>
      <div
        className='items-center justify-between cursor-pointer rounded-[10px] bg-[#222222] text-white flex p-[13px] font-[500] text-[15px] leading-[150%]'
        onClick={() => setShowList(true)}
      >
        보험 처리 내역
        <ChevronRight strokeWidth={1.5} color='white' className='h-6 w-6' />
      </div>

      <div className='flex flex-col gap-6'>
        <div className='flex flex-col'>
          <div>타이어 교체 필요 여부</div>
          <div className='mt-3'>
            <SelectV3
              placeholder='선택해 주세요.'
              value={
                form.watch('additionalInfo.carState.타이어교체.content') || ''
              }
              onValueChange={(value) => {
                form.setValue(
                  'additionalInfo.carState.타이어교체.selected',
                  true,
                );
                form.setValue(
                  'additionalInfo.carState.타이어교체.content',
                  value,
                );
              }}
            >
              {['필요없음', '앞 타이어 교체 필요', '뒤 타이어 교체 필요'].map(
                (item) => {
                  return (
                    <SelectV3Item key={item} value={item}>
                      {item}
                    </SelectV3Item>
                  );
                },
              )}
            </SelectV3>
          </div>
          <div className='mt-[10px] bg-[#F3F6F9] rounded-[10px] flex items-center py-[14px] px-4 text-[#555555] text-[14px] leading-[150%]'>
            일반적인 교체 주기는 3~4만 km 입니다.
          </div>
        </div>

        <div className='flex flex-col gap-3'>
          <div>외판 스크래치 여부</div>
          <div>
            <SelectV3
              placeholder='선택해 주세요.'
              value={
                form.watch('additionalInfo.carState.외관스크래치.content') || ''
              }
              onValueChange={(value) => {
                form.setValue(
                  'additionalInfo.carState.외관스크래치.selected',
                  true,
                );
                form.setValue(
                  'additionalInfo.carState.외관스크래치.content',
                  value,
                );
              }}
            >
              {['없음', '1~2판', '3~4판', '5~6판'].map((item) => {
                return (
                  <SelectV3Item key={item} value={item}>
                    {item}
                  </SelectV3Item>
                );
              })}
            </SelectV3>
          </div>
        </div>

        <div className='flex flex-col gap-3'>
          <div>정비 및 수리 필요사항 여부</div>
          <div>
            <SelectV3
              placeholder='선택해 주세요.'
              value={
                form.watch('additionalInfo.carState.정비수리필요.content') || ''
              }
              onValueChange={(value) => {
                form.setValue(
                  'additionalInfo.carState.정비수리필요.selected',
                  true,
                );
                form.setValue(
                  'additionalInfo.carState.정비수리필요.content',
                  value,
                );
              }}
            >
              {['필요없음', '정비/수리 필요'].map((item) => {
                return (
                  <SelectV3Item key={item} value={item}>
                    {item}
                  </SelectV3Item>
                );
              })}
            </SelectV3>
          </div>

          {form.watch('additionalInfo.carState.정비수리필요.content') ===
            '정비/수리 필요' && (
            <div className='relative'>
              <Textarea
                className='rounded-[8px]'
                placeholder='내용을 입력해 주세요.'
                maxLength={1000}
                onChange={(e) => {
                  setText_정비수리(e.target.value);
                }}
                value={text_정비수리}
                onBlur={(e) => {
                  form.setValue(
                    'additionalInfo.carState.정비수리필요.additional',
                    e.target.value,
                  );
                }}
              ></Textarea>
              <span className='text-[#999999] font-[500] text-[14px] leading-[160%] absolute bottom-3 right-4'>{`${text_정비수리?.length}/1,000`}</span>
            </div>
          )}
        </div>

        <div className='flex flex-col gap-3'>
          <div>내 차의 장/단점(선택)</div>
          <div className='relative'>
            <Textarea
              className='rounded-[8px]'
              placeholder='내용을 입력해 주세요.'
              maxLength={1000}
              value={
                form.watch('additionalInfo.carState.차량장단점.content') ?? ''
              }
              onChange={(e) => {
                const value = e.target.value;
                form.setValue(
                  'additionalInfo.carState.차량장단점.content',
                  value,
                );
                form.setValue(
                  'additionalInfo.carState.차량장단점.selected',
                  Boolean(value.length),
                );
              }}
            ></Textarea>
            <span className='text-[#999999] font-[500] text-[14px] leading-[160%] absolute bottom-3 right-4'>{`${form.getValues('additionalInfo.carState.차량장단점.content')?.length ?? 0}/1,000`}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderSelling_Status;
