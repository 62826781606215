import { CarsayoToast } from 'components/common/v2/CarsayoToast';
import { OrderCardProps } from './orderCard';
import { cn } from 'utils';
import React, { useState } from 'react';
import useMemberStore from 'store/useMemberStore';
import DealerOrderDetailCancelSubpage from 'pages/v3/orderDetailTemp/components/subpage/cancel';
import V3_Confirm from 'components_v3/dialog/Confirm';
import { useMutation } from '@tanstack/react-query';
import { reStartQuoteApi } from 'apis/quote';
import useInvalidateDealerSearch from '../hook/invalidateDealerSearch';
import { CircularProgress } from '@mui/material';
import DealerOrderDetailReviewSubpage from 'pages/v3/orderDetailTemp/components/subpage/review';

function Container({ children }: { children: React.ReactNode }) {
  return (
    <div className='w-full flex items-center justify-between p-4 mt-[-20px]'>
      <div
        className={cn(
          'w-full h-[44px] bg-[#F3F6F9] rounded-[8px] flex items-center justify-center',
          'text-[#222] text-[15px] font-medium leading-[15px] tracking-[-0.3px]',
        )}
      >
        {children}
      </div>
    </div>
  );
}

/**
 * 동작 영역
 * disableAction이 true일 경우 숨김처리
 * @case 보류 > 재주문 / 주문취소
 * @case 주문완료 & 리뷰존재 > 받은 리뷰 보기
 */
export default function OrderCardFeature({
  order,
  disableAction,
}: OrderCardProps) {
  if (disableAction) return null;

  const { loginMember } = useMemberStore();
  const [isCancelSubpageOpen, setIsCancelSubpageOpen] = useState(false);

  const { InvalidateMyOrderList } = useInvalidateDealerSearch();
  const [isReStartConfirmOpen, setIsReStartConfirmOpen] = useState(false);

  const [isReviewSubpageOpen, setIsReviewSubpageOpen] = useState(false);

  // 재주문 기능 (재진행과 동일)
  const { mutate, isPending } = useMutation({
    mutationFn: async () => {
      await reStartQuoteApi(order.id);
    },
    onSuccess: async () => {
      CarsayoToast.success('주문이 재개되었습니다.');
      InvalidateMyOrderList();
    },
    onError: async (error: any) => {
      const errorMessage: string =
        error?.data?.message ?? '잠시 후 다시 시도해 주세요.';

      return CarsayoToast.error(errorMessage);
    },
  });

  // 보류
  if (
    order.state === 'holded' &&
    // 본인이 보류한 주문일 경우에만 보여집니다.
    order.lastUpdateMemberId === loginMember?.id
  ) {
    return (
      <>
        <Container>
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsReStartConfirmOpen(true);
            }}
            className='w-full h-full flex items-center justify-center'
          >
            재주문
            {isPending && <CircularProgress />}
          </button>
          <div className='w-[1px] h-[18.5px] bg-[#CACCCF]' />
          <button
            onClick={(e) => {
              e.stopPropagation();
              setIsCancelSubpageOpen(true);
            }}
            className='w-full h-full flex items-center justify-center'
          >
            주문취소
          </button>
        </Container>

        {/* 재주문(재개) */}
        <V3_Confirm
          isOpen={isReStartConfirmOpen}
          setIsOpen={setIsReStartConfirmOpen}
          onConfirm={() => {
            mutate();
          }}
          onClose={() => {
            setIsReStartConfirmOpen(false);
          }}
          title=''
          agreeText='재주문'
        >
          <div className='flex flex-col items-center justify-center gap-5'>
            <div className='flex flex-col items-center justify-center gap-1.5'>
              <div className='text-[#222] text-center font-[700] text-[16px] leading-[25.6px] tracking-[-0.32px] [font-feature-settings: "liga" off, "clig" off]'>
                주문 재개
              </div>
              <div className='text-[#222] text-center font-[500] text-[15px] leading-[24px] tracking-[-0.3px] [font-feature-settings: "liga" off, "clig" off]'>
                주문을 재개하시겠습니까?
              </div>
            </div>
          </div>
        </V3_Confirm>

        {/* 주문취소 서브페이지 */}
        <DealerOrderDetailCancelSubpage
          orderListData={order}
          isOpen={isCancelSubpageOpen}
          setIsOpen={setIsCancelSubpageOpen}
        />
      </>
    );
  }

  // 주문완료 && 리뷰존재
  if (order.state === 'finished' && order.review) {
    return (
      <Container>
        <button
          onClick={(e) => {
            e.stopPropagation();
            setIsReviewSubpageOpen(true);
          }}
          className='w-full h-full flex items-center justify-center'
        >
          받은 리뷰 보기
        </button>

        <DealerOrderDetailReviewSubpage
          orderListData={order}
          isOpen={isReviewSubpageOpen}
          setIsOpen={setIsReviewSubpageOpen}
        />
      </Container>
    );
  }

  return null;
}
