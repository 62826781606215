export default function MemoPencel() {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      width='20'
      height='20'
      viewBox='0 0 20 20'
      fill='none'
    >
      <g clipPath='url(#clip0_3497_50149)'>
        <path
          d='M1.59766 18.4063H5.83814L16.9694 7.27497C17.2479 6.99654 17.4687 6.66599 17.6194 6.30219C17.7701 5.9384 17.8477 5.54849 17.8477 5.15473C17.8477 4.76096 17.7701 4.37105 17.6194 4.00726C17.4687 3.64347 17.2479 3.31292 16.9694 3.03448C16.691 2.75605 16.3604 2.53518 15.9966 2.3845C15.6329 2.23381 15.2429 2.15625 14.8492 2.15625C14.4554 2.15625 14.0655 2.23381 13.7017 2.3845C13.3379 2.53518 13.0074 2.75605 12.7289 3.03448L1.59766 14.1658V18.4063Z'
          fill='white'
          stroke='white'
          strokeWidth='1.25'
          strokeMiterlimit='10'
        />
        <path d='M10.6953 3.125L16.877 9.30298L10.6953 3.125Z' fill='#222222' />
        <path
          d='M10.6953 3.125L16.877 9.30298'
          stroke='#7273F9'
          strokeWidth='1.25'
          strokeMiterlimit='10'
        />
      </g>
      <defs>
        <clipPath id='clip0_3497_50149'>
          <rect width='20' height='20' fill='white' />
        </clipPath>
      </defs>
    </svg>
  );
}
