import CarInfoItem from './CarInfoItem';
import {
  convertDealerType,
  convertOrderStatus,
  convertOrderStatusStyle,
  statusDateMap,
} from 'pages/v3/quote/utils/convert';
import { cn } from 'utils';
import { StatusItem } from './StatusItem';
import dayjs from 'dayjs';
import { useNavigate } from 'react-router-dom';
import RemainTime from '../RemainTime';
import { CustomerOrderDetail } from '@carsayo/types';

const ListItem = ({
  quote,
  refetch,
}: {
  quote: CustomerOrderDetail;
  refetch: () => void;
}) => {
  const navigate = useNavigate();
  const dateType = statusDateMap[quote.state];

  const typenameText =
    quote?.type === 'sellCar'
      ? '내 차 팔기'
      : quote?.type === 'newCar'
        ? (quote as any)?.newCar?.installmentTerm
          ? '할부'
          : '일시불'
        : convertDealerType[quote?.type ?? '타입없음'];

  const rankText =
    quote?.type === 'sellCar'
      ? (quote as any)?.coocon_carInfo?.carName
      : quote?.type === 'newCar'
        ? `${(quote as any)?.detailNewCar?.car_trim.name_changed || (quote as any)?.detailNewCar?.car_trim.name} ${(quote as any)?.detailNewCar?.car_grade.name_changed || (quote as any)?.detailNewCar?.car_grade.name}`
        : `${(quote as any)?.detailLeaseRentCar?.car_trim.name_changed || (quote as any)?.detailLeaseRentCar?.car_trim.name} ${(quote as any)?.detailLeaseRentCar?.car_grade.name_changed || (quote as any)?.detailLeaseRentCar?.car_grade.name}`;
  return (
    <div className='bg-white border border-[#E5EAEE] rounded-[16px] cursor-pointer'>
      <div className='px-4 border-b flex items-center justify-between h-12'>
        <div className='flex items-center gap-[10px] font-[600] leading-[100%]'>
          <span
            className={cn(
              convertOrderStatusStyle[quote.state],
              'text-[16px] !bg-white',
            )}
          >
            {quote.type === 'sellCar'
              ? convertOrderStatus[quote.state]
              : convertOrderStatus[quote.state] === '판매 완료'
                ? '계약 완료'
                : convertOrderStatus[quote.state]}
          </span>
          {quote.state === 'submitted' && quote.bid_ended_at && (
            <RemainTime
              // time={quote.bid_ended_at}
              time={
                quote.type === 'sellCar'
                  ? new Date(quote.bid_ended_at)
                  : dayjs(quote?.bid_ended_at).isBefore(dayjs())
                    ? dayjs(quote?.bid_ended_at).add(2, 'day').toDate()
                    : new Date(quote?.bid_ended_at)
              }
            />
          )}
        </div>
        <div className='text-[#999999] font-[500] text-[13px] leading-[150%]'>
          {quote.state === 'progressing' && (
            <span className='text-[#009E59]'>(낙찰) </span>
          )}
          {dayjs(
            quote[dateType as keyof CustomerOrderDetail]?.toString(),
          ).format('YY.MM.DD HH:mm')}
        </div>
      </div>
      <div
        className={cn('flex flex-col p-4 gap-5')}
        onClick={() => navigate(`/order/quote/customer/${quote.id}`)}
      >
        <CarInfoItem
          img={
            quote.type === 'sellCar' && quote.imageUrl
              ? quote.imageUrl[0]
              : (quote.imageUrl as string)
          }
          typename={typenameText}
          // trim={`${quote.brand ? `${quote.brand} ` : ''}${quote.title}${(quote as any)?.coocon_carInfo?.year ? ` ${(quote as any)?.coocon_carInfo?.year}년식` : ''}`}
          trim={`${quote.brand ? `${quote.brand} ` : ''}${quote.title}`}
          rank={rankText}
          type={quote.type}
        />
      </div>
      {/* 취소, 입찰 종료 제외 */}
      {!['canceled'].includes(quote.state) && (
        <div className='p-4 pt-0'>
          <StatusItem
            quote={quote}
            quoteCount={quote?.bidList?.length || 0}
            refetch={refetch}
          />
        </div>
      )}
    </div>
  );
};

export default ListItem;
