// Swiper
import 'swiper/css';

import React from 'react';
import { Pagination, Autoplay } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore from 'swiper';
// import { PaginationOptions } from 'swiper/types/modules/pagination';
import SigninLogo from 'components/common/global/svg/SigninLogo';
import { cn } from 'utils';

interface SwiperProps {
  img: string;
  bgColor: string;
  subColor: string;
  logoImg: React.ReactNode;
  title: string;
  text: string;
  strong?: string;
}

const slides: Array<SwiperProps> = [
  {
    img: '/assets/images/import/signinSwiperImg1.svg',
    bgColor: 'after:bg-signinSwiper-Bg01',
    subColor: 'bg-signinSwiper-ImgBg01',
    logoImg: <SigninLogo />,
    title: '자동차 생활의 프레임을 바꾸다',
    text: '더 쉽고, 더 안전하게',
    strong: '카사요',
  },
  {
    img: '/assets/images/import/signinSwiperImg2.svg',
    bgColor: 'after:bg-signinSwiper-Bg02',
    subColor: 'bg-signinSwiper-ImgBg02',
    logoImg: <SigninLogo />,
    title: '자동차 쇼핑 통합 플랫폼',
    text: '고민없는 구매의 시작,',
    strong: '카사요',
  },
];

const SwiperSignin = () => {
  SwiperCore.use([Pagination, Autoplay]);
  const extendedSlides = slides.length === 2 ? [...slides, ...slides] : slides;
  return (
    <>
      <Swiper
        pagination={{
          el: '.mySwiper-pagination',
          type: 'bullets',
          clickable: true,
          bulletClass:
            'w-bullet-default h-bullet-default bg-bullet-default rounded-full transition-all',
          bulletActiveClass:
            '!w-bullet-active !bg-bullet-active !rounded-[1.3vw]',
        }}
        className='relative h-full overflow-hidden'
        autoplay={{ delay: 3000, disableOnInteraction: false }}
        loop={slides.length > 1 && true}
        centeredSlides={true}
        spaceBetween={20}
      >
        {extendedSlides.map((slide, index) => {
          return (
            <SwiperSlide key={`slide_${index}`}>
              <div
                className={cn(
                  'w-full h-full flex flex-col gap-[1.97vh] items-center pt-[5.41vh] relative',
                  `after:content-[""] after:absolute after:w-full after:h-[65%] ${slide.bgColor} after:z-[-1]`,
                  'after:top-0 after:left-0 after:right-0 after:mx-auto',
                )}
              >
                <h2 className='w-full py-[1.84vh] h-auto flex justify-center items-center'>
                  {slide.logoImg}
                </h2>
                <div
                  className={cn(
                    'w-[calc(100%-80px)] relative min-h-[36.3vh] px-[20.4vw] py-[9.42vh] box-border mx-auto flex justify-center items-center',
                    `${slide.subColor} rounded-[24px]`,
                  )}
                >
                  <img
                    src={slide.img}
                    alt='슬라이드 이미지'
                    className='w-[37.8vw] h-[37.8vw] object-contain'
                  />
                  <div className='absolute -bottom-[10px] left-0 right-0 mx-auto w-[56px] h-[20px] rounded-[20px] bg-[#00000099] z-30 flex justify-center items-center'>
                    <div className='text-[#FFFFFF99]'>
                      <span className='text-[#fff]'>
                        {slides.length === 2
                          ? index > 1
                            ? index - 1
                            : index + 1
                          : index + 1}
                      </span>
                      <span
                        className={cn(
                          index === slides.length - 1
                            ? 'text-[#fff]'
                            : 'text-[#FFFFFF99]',
                        )}
                      >
                        /{slides.length}
                      </span>
                    </div>
                  </div>
                </div>
                <div className='w-full mt-[1.84vh]'>
                  <h3 className='text-[#111111] text-[24px] font-[600] leading-[34px] mb-[1.47vh] text-center'>
                    {slide.title}
                  </h3>
                  <p className='text-[14px] leading-[20px] text-center'>
                    {slide.text}
                    {slide.strong && (
                      <strong className='text-[#7273F9] font-[400] text-[14px] ml-[5px]'>
                        {slide.strong}
                      </strong>
                    )}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </>
  );
};

export default SwiperSignin;
